import styled, { css } from 'styled-components';
import { Box } from '../Core';
import { Icon } from '../Icons';

export enum TagVariants {
  Default,
  Primary,
  Positive,
  Negative,
  Warning,
}

export enum TagSizes {
  Tiny,
  Small,
  Default,
  Large,
}

const background = css<{ variant?: TagVariants }>`
  background: ${({ theme, variant }) => {
    switch (variant) {
      case TagVariants.Primary:
        return theme.backgroundPrimaryButton;
      case TagVariants.Positive:
        return theme.backgroundPositiveButton;
      case TagVariants.Negative:
        return theme.backgroundNegativeButton;
      default:
        return theme.backgroundPriorityButton;
    }
  }};
  border: solid 1px
    ${({ theme, variant }) => {
      switch (variant) {
        case TagVariants.Primary:
          return theme.borderColorPrimaryButton;
        case TagVariants.Positive:
          return theme.borderColorPositiveButton;
        case TagVariants.Negative:
          return theme.borderColorNegativeButton;
        default:
          return theme.borderColorPriorityButton;
      }
    }};
`;

const foreground = css<{ variant?: TagVariants }>`
  color: ${({ theme, variant }) => {
    switch (variant) {
      case TagVariants.Primary:
        return theme.colorTextPrimaryButton;
      case TagVariants.Positive:
        return theme.colorTextPositiveButton;
      case TagVariants.Negative:
        return theme.colorTextNegativeButton;
      default:
        return theme.colorTextPriorityButton;
    }
  }};
`;

const tiny = css`
  font-size: ${({ theme }) => theme.fontSizeTiny}rem;
  padding: ${({ theme }) => `${theme.spacingTiny - 1}px ${theme.spacingDefault}px`};

  ${Icon} {
    font-size: ${({ theme }) => theme.fontSizeTiny}rem;
  }

  > span + ${Icon}, > ${Icon} + span {
    margin-left: ${({ theme }) => theme.spacingSmall}px;
  }
`;

const small = css`
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  padding: ${({ theme }) => `${theme.spacingSmall - 1}px ${theme.spacingDefault}px`};

  ${Icon} {
    font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  }

  > span + ${Icon}, > ${Icon} + span {
    margin-left: ${({ theme }) => theme.spacingSmall}px;
  }
`;

const large = css`
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  padding: ${({ theme }) => `${theme.spacingDefault - 1}px ${theme.spacingMedium}px`};

  ${Icon} {
    font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  }

  > span + ${Icon}, > ${Icon} + span {
    margin-left: ${({ theme }) => theme.spacingSmall}px;
  }
`;

export const Wrapper = styled(Box)<{ size?: TagSizes; variant?: TagVariants }>`
  ${background};
  ${foreground};
  display: inline-flex;
  align-items: center;
  border-radius: 999em;
  line-height: 1;

  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  padding: ${({ theme }) => `${theme.spacingSmall - 1}px ${theme.spacingDefault}px`};

  ${Icon} {
    font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  }

  > span + ${Icon}, > ${Icon} + span {
    margin-left: ${({ theme }) => theme.spacingSmall}px;
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${({ size }) => size === TagSizes.Tiny && tiny};
  ${({ size }) => size === TagSizes.Small && small};
  ${({ size }) => size === TagSizes.Large && large};
`;

export const Title = styled.span<{ variant?: TagVariants }>`
  text-transform: uppercase;
  white-space: nowrap;
  letter-spacing: 0.05em;
  margin-right: ${({ theme }) => theme.spacingSmall}px;
`;
