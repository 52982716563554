import styled from 'styled-components';
import { Tab } from './Tab';
import type { TabProps } from './types';

export type TabButtonProps = Pick<
  TabProps,
  'label' | 'prefix' | 'suffix' | 'onClick' | 'appearance' | 'size' | 'isSelected'
>;

export const TabButton = styled(({ label, onClick, ...props }: TabButtonProps) => (
  <Tab label={label} onClick={onClick} {...props} />
))`
  padding: 0 ${({ theme }) => theme.spacingDefault}px;
`;
