import type { Colors, DefaultTheme } from 'styled-components';
import { darkColors, mapDarkTheme } from './DarkTheme';

const colors: Colors = {
  ...darkColors,

  primary: {
    default: '#2015cc',
    lighten: '#554beb',
    dim: 'rgba(32, 21, 204, 0.4)',
    mute: 'rgba(32, 21, 204, 0.1)',
  },
};

export const DarkBinalokaTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'Dark',
});
