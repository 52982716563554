import { useCallback } from 'react';
import { useWLCustomerMarketAccountContext } from '../providers/WLCustomerMarketAccountsProvider';
import { useWLOrgConfigContext } from '../providers/WLOrgConfigProvider';

export const useGetCustomerMarketAccountLabel = () => {
  const { config: customerConfig } = useWLOrgConfigContext();
  const { customerMarketAccountsBySourceAccountID } = useWLCustomerMarketAccountContext();

  return useCallback(
    (sourceAccountID: string) => {
      if (!customerConfig.showMarketAccountDisplayName) {
        return sourceAccountID;
      }
      return customerMarketAccountsBySourceAccountID?.get(sourceAccountID)?.DisplayName ?? sourceAccountID;
    },
    [customerConfig.showMarketAccountDisplayName, customerMarketAccountsBySourceAccountID]
  );
};
