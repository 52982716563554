import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0 }
  to { opacity: 1 }
`;

export const EmptyMessage = styled.div`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray['060']};
  height: 159px;
  padding-left: ${({ theme }) => theme.spacingHuge}px;
  padding-right: ${({ theme }) => theme.spacingHuge}px;

  animation: ${fadeIn} 200ms;
  animation-timing-function: step-end;

  i {
    margin-right: ${({ theme }) => theme.spacingDefault}px;
  }
`;
