import { useEffect } from 'react';
import { distinctUntilChanged, map, type Observable } from 'rxjs';
import { useRefRateContext, type RefRate } from '../contexts';
import { useObservable } from './useObservable';

/**
 * Hook to subscribe to the latest ref rate for a security.
 *
 * @param security Security name to subscribe to
 * @returns Observable of RefRate for the given symbol
 */
export function useRefRate(security: string): Observable<RefRate | undefined> {
  const { refRatesBySymbol, registerSubscription, unregisterSubscription } = useRefRateContext();

  useEffect(() => {
    registerSubscription(security);
    return () => {
      unregisterSubscription(security);
    };
  }, [registerSubscription, security, unregisterSubscription]);

  const refRatesObservable = useObservable<RefRate | undefined>(
    () => refRatesBySymbol.pipe(map((rates: Map<string, RefRate | undefined>) => rates.get(security))),
    [refRatesBySymbol, security]
  );
  return refRatesObservable;
}

/**
 * Hook to subscribe to the latest ref rate for multiple securities.
 *
 * @param securities Array of security names to subscribe for
 * @returns Observable map of SecurityName => RefRate
 */
export function useRefRates(securities: string[]): Observable<Map<string, RefRate | undefined>> {
  const { refRatesBySymbol, registerSubscriptions, unregisterSubscriptions } = useRefRateContext();

  useEffect(() => {
    registerSubscriptions(securities);
    return () => {
      unregisterSubscriptions(securities);
    };
  }, [registerSubscriptions, securities, unregisterSubscriptions]);

  const refRatesObservable = useObservable<Map<string, RefRate | undefined>>(
    () =>
      refRatesBySymbol.pipe(
        map(rates => new Map(securities.map(symbol => [symbol, rates.get(symbol)]))),
        distinctUntilChanged()
      ),
    [refRatesBySymbol, securities]
  );
  return refRatesObservable;
}
