import { isNumber } from 'lodash';
import styled, { type DefaultTheme } from 'styled-components';
import { Z_INDEX, modeColor } from '../../styles';
import { AccordionContent, ContentWrapper } from '../Accordion/styles';
import { Box, Flex, HStack } from '../Core';
import { SidebarItem } from './SidebarItem';

export const ENV_COLORS = {
  prod: '',
  local: 'gray.080',
  staging: 'green.lighten',
  uat: 'blue.lighten',
  alpha: 'red.lighten',
  sandbox: 'yellow.lighten',
  develop: '#804AD8',
  release: '#FF9900',
};

function getExpandedWidth(theme: DefaultTheme, isExpanded: boolean, expandedWidth?: string | number) {
  if (isExpanded) {
    if (expandedWidth == null) {
      return 'auto';
    }
    if (isNumber(expandedWidth)) {
      return `${expandedWidth}px`;
    }
    return expandedWidth;
  }
  return `${theme.baseSize * 3 + theme.spacingSmall / 2}px`;
}

export const SidebarWrapper = styled(Box)<{ isPinned: boolean; expandedWidth?: string | number }>`
  color: ${({ theme }) => theme.colorTextDefault};
  width: ${({ theme, isPinned, expandedWidth }) => getExpandedWidth(theme, isPinned, expandedWidth)};
  min-width: ${({ expandedWidth, isPinned }) => (isPinned ? expandedWidth : 0)}px;
  transition: width 200ms ease, min-width 200ms ease;
  overflow: visible;
  height: 100%;
  flex-shrink: 0;

  @media print {
    display: none;
  }

  ${({ position }) =>
    (position === 'absolute' || position === 'fixed') &&
    `
    left: 0;
    top: 0;
    bottom: 0;
  `};

  a {
    border: none;
  }
`;
SidebarWrapper.defaultProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
};

export const SideBarChildrenWrapper = styled(Box)`
  width: 100%;
  height: fit-content;
  min-height: 100%;
`;

SideBarChildrenWrapper.defaultProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: 'spacingDefault',
  pl: 'spacingDefault',
  pb: 'spacingDefault',
};

export const SidebarContent = styled(Flex)<{
  isPinned: boolean;
  isExpanded: boolean;
  expandedWidth?: string | number;
}>`
  min-width: ${({ theme, isExpanded, isPinned, expandedWidth }) =>
    isExpanded || isPinned ? getExpandedWidth(theme, isExpanded, expandedWidth) : 0};
  background: ${({ theme }) => theme.backgroundSidebar};
  backdrop-filter: ${({ theme }) => theme.backdropFilterSidebar};
  transition: width 200ms ease, min-width 200ms ease;
  flex: 1;
  z-index: ${Z_INDEX.drawer};
  overflow: hidden;
  ${({ isExpanded }) => isExpanded && `overflow-y: auto;`}
  ${({ theme }) =>
    theme.borderColorSidebar !== theme.backgroundContent ? `border-right: solid 1px ${theme.borderColorSidebar};` : ''};

  ${({ theme, isPinned, isExpanded }) =>
    !isPinned &&
    isExpanded &&
    `box-shadow: 24px 0px 40px ${modeColor(theme, theme.colors.black.mute, theme.colors.black.dim)};`};
  ${({ isExpanded }) => isExpanded && `overflow-y: auto;`}
`;
SidebarContent.defaultProps = {
  pr: 'spacingDefault',
};

export const SidebarSection = styled(Flex)<{ isBordered?: boolean }>`
  ${({ isBordered, theme }) =>
    isBordered &&
    `
    border-top: 1px solid ${theme.colors.gray['070']};
    border-bottom: 1px solid ${theme.colors.gray['070']};
  `};
  & + & {
    border-top: 0;
  }
  width: 100%;

  a {
    text-decoration: none;
  }
`;
SidebarSection.defaultProps = {
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: 'spacingDefault',
};

export const SidebarHeaderWrapper = styled(HStack)`
  cursor: pointer;
`;
SidebarHeaderWrapper.defaultProps = {
  h: '40px',
  p: 'spacingSmall',
  justifyContent: 'space-between',
};

export const SidebarGroupWrapper = styled.div`
  ${AccordionContent} {
    background: transparent;
  }
  ${ContentWrapper} {
    padding: 0;
    padding-left: ${({ theme }) => theme.spacingBig}px;
  }

  gap: ${({ theme }) => theme.spacingDefault}px;
`;

export const SidebarGroupItem = styled(SidebarItem)`
  line-height: 1;
  ${({ theme, isActive }) =>
    isActive &&
    `
    &, &:hover {
      background: ${theme.colors.white.mute};
      color: ${theme.colors.gray['100']};
    }
  `};
`;
