import { abbreviate, type AbbreviateOptions } from '../../utils';
import { HStack } from '../Core';
import { Text } from '../Text';

export function AbbrLimit({
  value,
  currency,
  options,
}: {
  value: string | undefined;
  currency?: string;
  options?: AbbreviateOptions;
}) {
  return (
    <HStack gap="spacingSmall">
      <Text>{value !== undefined ? abbreviate(value, options) : '-'}</Text>
      {currency && <Text color="colorTextSubtle">{currency}</Text>}
    </HStack>
  );
}
