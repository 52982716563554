import { darken, hsl } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { mapDarkTheme } from './DarkTheme';

const colors: Colors = {
  black: {
    default: '#000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    main: hsl(216, 0.5, 0.098),

    '000': hsl(216, 0.5, 0.05),
    '010': hsl(216, 0.5, 0.12),
    '020': hsl(216, 0.5, 0.15),
    '030': hsl(216, 0.5, 0.18),
    '040': hsl(216, 0.5, 0.22),
    '050': hsl(216, 0.5, 0.25),
    '060': hsl(216, 0.5, 0.28),
    '070': '#53545A',
    '080': '#808186',
    '090': '#A3A4A7',
    '100': '#E5E5E6',
  },

  primary: {
    default: '#6636c4',
    lighten: '#6636c4',
    dim: setAlpha(0.4, '#6610f2'),
    mute: setAlpha(0.1, '#6610f2'),
  },

  green: {
    default: darken(0.1, '#21db99'),
    lighten: darken(0.1, '#21db99'),
    dim: 'rgba(51, 126, 90, 0.4)',
    mute: 'rgba(51, 126, 90, 0.1)',
  },

  red: {
    default: darken(0.05, '#fa4877'),
    lighten: darken(0.05, '#fa4877'),
    dim: 'rgba(146, 46, 67, 0.4)',
    mute: 'rgba(146, 46, 67, 0.1)',
  },

  yellow: {
    default: '#ffa519',
    lighten: '#ffa519',
    dim: 'rgba(204, 172, 62, 0.4)',
    mute: 'rgba(204, 172, 62, 0.1)',
  },

  blue: {
    mute: 'rgba(69, 140, 193, 0.1)',
    dim: 'rgba(69, 140, 193, 0.4)',
    default: '#6678fa',
    lighten: '#6678fa',
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: {
    default: '#8B4ED9',
    lighten: '#A45FFF',
  },
};

export const DarkAbraTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkAB',

  fontFamily: "'Nunito', sans-serif",
  fontUrl: 'https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',
  borderRadiusDefault: 8,
  borderRadiusContent: 8,
  spacingLayout: 8,
});
