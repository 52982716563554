import React, { createContext, useMemo } from 'react';
import { useSocketClient } from '../../WebSocketClientProvider';
import { useWLOrderFormStore } from '../WLOrderFormStore';
import { OrderService } from './OrderService';

type OrderServiceContextProps = OrderService;
export const OrderServiceContext = createContext<OrderServiceContextProps | undefined>(undefined);

export function OrderServiceProvider(props: React.PropsWithChildren) {
  const store = useWLOrderFormStore();
  const webSocketClient = useSocketClient();
  const service = useMemo(
    () =>
      new OrderService(
        store,
        webSocketClient,
        import.meta.env.VITE_IS_MOBILE ? { dismissesActionAfterTimeout: false } : undefined
      ),
    [store, webSocketClient]
  );

  return <OrderServiceContext.Provider value={service}>{props.children}</OrderServiceContext.Provider>;
}

export const useOrderServiceContext = () => {
  const context = React.useContext(OrderServiceContext);
  if (context === undefined) {
    throw new Error('Missing OrderServiceContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};
