import { createContext, useCallback, useContext, useEffect, useMemo, useState, type PropsWithChildren } from 'react';

interface AccordionGroupContextOutput {
  registerAccordion: (id: string, open?: boolean) => void;
  unregisterAccordion: (id: string) => void;
  accordionsMap: Map<string, boolean>;
  openAccordion: (id: string) => void;
  closeAccordion: (id: string) => void;
  toggleAccordion: (id: string) => void;
  toggleAllToOpen: boolean;
  closeAllAccordions: () => void;
  openAllAccordions: () => void;
  toggleAllAccordions: () => void;
}

export const AccordionGroupContext = createContext<AccordionGroupContextOutput | undefined>(undefined);
AccordionGroupContext.displayName = 'AccordionGroupContext';

export function useAccordionGroupContext() {
  const context = useContext(AccordionGroupContext);
  if (context === undefined) {
    throw new Error('Missing AccordionGroupContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const AccordionGroup = ({ children }: React.PropsWithChildren) => {
  const [accordionsMap, setAccordionsMap] = useState<Map<string, boolean>>(new Map());
  const [toggleAllToOpen, setToggleAllToOpen] = useState(true); // seems like a reasonable default

  useEffect(() => {
    setToggleAllToOpen(() => {
      // if there is any closed accordion, we should always say "toggle all to open"
      for (const open of accordionsMap.values()) {
        if (!open) {
          return true;
        }
      }

      return false;
    });
  }, [accordionsMap]);

  const registerAccordion = useCallback((id: string, open = false) => {
    setAccordionsMap(curr => {
      if (!curr.has(id)) {
        return new Map(curr).set(id, open);
      }

      return curr;
    });
  }, []);

  const unregisterAccordion = useCallback((id: string) => {
    setAccordionsMap(curr => {
      curr.delete(id);
      return new Map(curr);
    });
  }, []);

  const changeAccordionOpenState = useCallback((id: string, open: boolean) => {
    setAccordionsMap(curr => {
      return new Map(curr).set(id, open);
    });
  }, []);

  const openAccordion = useCallback(
    (id: string) => {
      changeAccordionOpenState(id, true);
    },
    [changeAccordionOpenState]
  );

  const closeAccordion = useCallback(
    (id: string) => {
      changeAccordionOpenState(id, false);
    },
    [changeAccordionOpenState]
  );

  const toggleAccordion = useCallback((id: string) => {
    setAccordionsMap(curr => {
      const isOpen = curr.get(id);
      if (isOpen == null) {
        return curr;
      }
      return new Map(curr).set(id, !isOpen);
    });
  }, []);

  const changeAllOpenStates = useCallback((newOpen: boolean) => {
    setAccordionsMap(curr => {
      const newMap = new Map();
      curr.forEach((_, key) => {
        newMap.set(key, newOpen);
      });
      return newMap;
    });
  }, []);

  const closeAllAccordions = useCallback(() => {
    changeAllOpenStates(false);
  }, [changeAllOpenStates]);

  const openAllAccordions = useCallback(() => {
    changeAllOpenStates(true);
  }, [changeAllOpenStates]);

  const toggleAllAccordions = useCallback(() => {
    changeAllOpenStates(toggleAllToOpen);
  }, [toggleAllToOpen, changeAllOpenStates]);

  const value = useMemo(() => {
    return {
      registerAccordion,
      unregisterAccordion,
      accordionsMap,
      openAccordion,
      closeAccordion,
      toggleAccordion,
      toggleAllToOpen,
      closeAllAccordions,
      openAllAccordions,
      toggleAllAccordions,
    };
  }, [
    registerAccordion,
    unregisterAccordion,
    accordionsMap,
    openAccordion,
    closeAccordion,
    toggleAccordion,
    toggleAllToOpen,
    closeAllAccordions,
    openAllAccordions,
    toggleAllAccordions,
  ]);

  return <AccordionGroupContext.Provider value={value}>{children}</AccordionGroupContext.Provider>;
};

export const withAccordionGroup =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: PropsWithChildren<P>) =>
    (
      <AccordionGroup>
        <Component {...props} />
      </AccordionGroup>
    );
