import { hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { LightTheme, lightColors, mapLightTheme } from './LightTheme';
import { getInputStylesAppliedToSelect } from './utils';

const colors: Colors = {
  black: {
    default: '#000000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#FFFFFF',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.1)',
  },

  gray: {
    main: '#FFFFFF',
    '000': '#F1F1F1',
    '010': '#F8F8F8',
    '020': '#ececec',
    '030': hsla(240, 0.12, 0.91, 1),
    '040': hsla(240, 0.06, 0.85, 1),
    '050': hsla(240, 0.06, 0.81, 1),
    '060': hsla(240, 0.06, 0.77, 1),
    '070': hsla(240, 0.06, 0.73, 1),
    '080': '#193843',
    '090': '#031B23',
    '100': '#031B23',
  },

  primary: {
    mute: setAlpha(0.1, '#A3EC26'),
    dim: setAlpha(0.4, '#A3EC26'),
    default: '#92D422',
    lighten: '#A3EC26',
  },

  green: {
    mute: setAlpha(0.1, '#26c964'),
    dim: setAlpha(0.4, '#26c964'),
    default: '#08970E',
    lighten: '#26c964',
  },

  red: {
    mute: setAlpha(0.1, '#F5475B'),
    dim: setAlpha(0.4, '#F5475B'),
    default: '#C7232A',
    lighten: '#F5475B',
  },

  yellow: {
    mute: setAlpha(0.1, '#F3D900'),
    dim: setAlpha(0.4, '#F3D900'),
    default: '#F3D900',
    lighten: '#F3D900',
  },

  blue: {
    mute: setAlpha(0.1, '#306fb8'),
    dim: setAlpha(0.4, '#306fb8'),
    default: '#306fb8',
    lighten: '#4898F4',
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: lightColors.purple,
};

export const LightWintermuteTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightWM',

  spacingLayout: 8,

  backgroundBody: `${colors.gray['000']} url(/customers/wintermute-background.svg) top center`,

  backgroundHeader: setAlpha(0.75, colors.gray.main),
  borderColorHeader: 'transparent',

  backgroundContent: setAlpha(0.75, colors.gray.main),

  backgroundChart: 'rgba(0, 0, 0, 0)',

  backgroundInput: colors.gray['000'],

  ...getInputStylesAppliedToSelect(LightTheme),
  backgroundSelect: colors.gray['000'],
  backgroundSelectHover: colors.gray['000'],
  backgroundSelectFocus: colors.gray['000'],
  backgroundSelectInvalid: colors.gray['000'],
  backgroundSelectDisabled: colors.gray['000'],
  backgroundSelectReadOnly: colors.gray['000'],

  backgroundPositiveButton: colors.primary.default,
  backgroundPositiveButtonHover: colors.primary.lighten,
  backgroundPositiveButtonFocus: colors.primary.lighten,
  backgroundPositiveButtonActive: colors.primary.default,
  backgroundPositiveButtonDisabled: setAlpha(0.5, colors.primary.default),

  backgroundNegativeButton: colors.primary.default,
  backgroundNegativeButtonHover: colors.primary.lighten,
  backgroundNegativeButtonFocus: colors.primary.lighten,
  backgroundNegativeButtonActive: colors.primary.default,
  backgroundNegativeButtonDisabled: setAlpha(0.5, colors.primary.default),

  colorTextPrimaryButton: colors.black.default,
  colorTextPrimaryButtonHover: colors.black.default,
  colorTextPrimaryButtonFocus: colors.black.default,
  colorTextPrimaryButtonActive: colors.black.default,
  colorTextPrimaryButtonDisabled: setAlpha(0.5, colors.black.default),

  colorTextPositiveButton: colors.black.default,
  colorTextPositiveButtonHover: colors.black.default,
  colorTextPositiveButtonFocus: colors.black.default,
  colorTextPositiveButtonActive: colors.black.default,
  colorTextPositiveButtonDisabled: setAlpha(0.5, colors.black.default),

  colorTextNegativeButton: colors.black.default,
  colorTextNegativeButtonHover: colors.black.default,
  colorTextNegativeButtonFocus: colors.black.default,
  colorTextNegativeButtonActive: colors.black.default,
  colorTextNegativeButtonDisabled: setAlpha(0.5, colors.black.default),

  backgroundVolumeLadderBidSpread: setAlpha(0.75, colors.green.default),
  backgroundVolumeLadderOfferSpread: setAlpha(0.75, colors.red.default),
});
