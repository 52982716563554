import { useMemo } from 'react';
import { getAgGridColId } from '../components/BlotterTable/columns/getAgGridColId';
import type { Column, ColumnDef } from '../components/BlotterTable/columns/types';
import { useDefaultColumns } from '../components/BlotterTable/useDefaultColumns';
import { EMPTY_ARRAY } from '../utils/empty';
import type { TermSheetRecipient } from './LendingRelationship';
import type { LoanQuoteStatusEnum, LoanQuoteTerminationTypeEnum } from './types';

export class LoanQuote {
  QuoteStatus!: LoanQuoteStatusEnum;

  Timestamp!: string;

  NegotiationID!: string;

  Rate!: string;

  ReqID!: string;

  SubmitTime!: string;

  Lender!: string;

  Borrower!: string;

  User!: string;

  Owner!: string;

  PrincipalCurrency!: string;

  CollateralCurrency?: string;

  Qty!: string;

  CollateralRatio?: string;

  ValidationError?: string;

  Text?: string;

  EndTime?: string;

  TerminationTime?: string;

  TerminationType?: LoanQuoteTerminationTypeEnum;

  Comments?: string;

  MinQty?: string;

  OrigQuoteReqID!: string;

  QuoteReqID!: string;

  Revision!: number;

  AmendmentCount!: number;

  TermSheetRecipients?: TermSheetRecipient[];

  EnabledCollateralCurrencies?: { Currency: string }[];

  constructor(data: LoanQuote) {
    Object.assign(this, data);
  }
}

interface UseLoanQuoteColumns {
  defaultColumns?: (keyof LoanQuote | Partial<Column>)[];
}

export function useLoanQuoteColumns({ defaultColumns = EMPTY_ARRAY }: UseLoanQuoteColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            {
              field: 'QuoteStatus',
              type: 'loanQuoteStatus',
            },
            {
              field: 'Timestamp',
              type: 'date',
              sortable: true,
              sort: '-',
            },
            {
              field: 'NegotiationID',
              type: 'text',
            },
            {
              field: 'Rate',
              type: 'percent',
            },
            {
              field: 'ReqID',
              type: 'text',
            },
            {
              field: 'SubmitTime',
              type: 'date',
              sortable: true,
            },
            {
              field: 'Lender',
              type: 'loanParticipant',
            },
            {
              field: 'Borrower',
              type: 'loanParticipant',
            },
            {
              field: 'User',
              type: 'user',
            },
            {
              field: 'Owner',
              type: 'user',
            },
            {
              field: 'PrincipalCurrency',
              type: 'currency',
            },
            {
              field: 'CollateralCurrency',
              type: 'currency',
            },
            {
              field: 'Qty',
              type: 'size',
              params: { currencyField: 'PrincipalCurrency' },
            },
            {
              field: 'CollateralRatio',
              type: 'text',
            },
            {
              field: 'ValidationError',
              type: 'text',
            },
            {
              field: 'Text',
              type: 'text',
            },
            {
              field: 'EndTime',
              type: 'date',
            },
            {
              field: 'TerminationTime',
              type: 'date',
            },
            {
              field: 'TerminationType',
              type: 'text',
            },
            {
              field: 'Comments',
              type: 'text',
            },
            {
              field: 'MinQty',
              type: 'text',
            },
            {
              field: 'OrigQuoteReqID',
              type: 'id',
            },
            {
              field: 'QuoteReqID',
              type: 'id',
            },
            {
              field: 'Revision',
              type: 'text',
            },
            {
              field: 'AmendmentCount',
              type: 'text',
            },
            {
              field: 'TermSheetRecipients',
              type: 'text',
            },
            {
              field: 'EnabledCollateralCurrencies',
              type: 'text',
            },
          ] satisfies ColumnDef<LoanQuote>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );
  const columnDefinitions = useMemo(() => {
    return new Map(
      ([...defaultVisibleColumns.values()] satisfies ColumnDef<LoanQuote>[] as Column[]).map(c => [
        getAgGridColId(c),
        c,
      ])
    );
  }, [defaultVisibleColumns]);
  return useDefaultColumns(defaultColumns, columnDefinitions);
}
