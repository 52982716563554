import Big from 'big.js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { NumberVariants } from '../components/FormattedNumber';
import { Direction } from '../types/Direction';

export const useFlashSentiment = (symbol: string | undefined, positiveDirection: Direction, value?: string) => {
  const lastValue = useRef<string>();
  const [sentiment, setSentiment] = useState<NumberVariants>();

  const getSentiment = useCallback(
    (price: string, last: string): NumberVariants | undefined => {
      const lastPrice = last || price;
      if (positiveDirection === Direction.Asc) {
        if (Big(lastPrice).lt(price)) {
          return NumberVariants.Positive;
        } else if (Big(lastPrice).gt(price)) {
          return NumberVariants.Negative;
        }
      } else {
        if (Big(lastPrice).gt(price)) {
          return NumberVariants.Negative;
        } else if (Big(lastPrice).lt(price)) {
          return NumberVariants.Negative;
        }
      }
      return undefined;
    },
    [positiveDirection]
  );

  useEffect(() => {
    if (value != null) {
      if (lastValue.current != null) {
        setSentiment(getSentiment(value, lastValue.current));
      }
      lastValue.current = value;
      const timer = setTimeout(() => setSentiment(undefined), 300);
      return () => clearTimeout(timer);
    }
  }, [value, getSentiment]);

  useEffect(() => {
    lastValue.current = undefined;
    setSentiment(undefined);
  }, [symbol]);

  return sentiment;
};
