import { FormSection, OrderFormSides } from '@talos/kyoko';
import styled from 'styled-components';

export const OrderFormsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow-x: hidden;
`;

export const OrderFormHeader = styled(FormSection)`
  height: ${({ theme }) => theme.baseSize * 4}px;
  border-bottom: solid 1px ${({ theme }) => theme.borderColorDivider};
`;

export const ModifyOrderID = styled.div`
  font-size: ${({ theme }) => theme.fontSizeTiny}rem;
`;

export const ModifyWrapper = styled.div<{ side?: OrderFormSides | null }>`
  text-align: right;
  margin-left: auto;
  color: ${({ theme, side }) =>
    side === OrderFormSides.Buy
      ? theme.colorTextPositive
      : side === OrderFormSides.Sell
      ? theme.colorTextNegative
      : theme.colorTextPrimary};

  ${ModifyOrderID} {
    color: ${({ theme, side }) =>
      side === OrderFormSides.Buy
        ? theme.colorTextPositiveSubtle
        : side === OrderFormSides.Sell
        ? theme.colorTextNegativeSubtle
        : theme.colorTextPrimary};
  }
`;

export const SymbolWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const SymbolDescription = styled.div`
  color: ${({ theme }) => theme.colorTextSubtle};
  font-size: ${({ theme }) => theme.fontSizeTiny}rem;
  line-height: 1;
`;

export const Symbol = styled.div`
  padding: ${({ theme }) => `0 ${theme.spacingDefault}px`};
  color: ${({ theme }) => theme.colorTextImportant};
`;

export const Overlay = styled.div`
  background: ${({ theme }) => theme.backgroundOverlay};
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const FeedbackMessage = styled.div`
  margin: ${({ theme }) => theme.spacingMedium}px;
  text-align: center;
`;

export const ToastWrapper = styled.div`
  padding: ${({ theme }) => theme.spacingMedium}px;

  // Place above RFQ form buttons
  bottom: ${({ theme }) => theme.spacingMedium + theme.baseSize * 2}px;
  position: absolute;
  z-index: 2;
  width: 100%;
`;
