import { useMemo } from 'react';
import { getAgGridColId } from '../components/BlotterTable/columns/getAgGridColId';
import type { Column, ColumnDef } from '../components/BlotterTable/columns/types';
import { useDefaultColumns } from '../components/BlotterTable/useDefaultColumns';
import { EMPTY_ARRAY } from '../utils/empty';
import { CustomerBalanceTransactionStatusEnum, type CustomerBalanceTransactionTypeEnum } from './types';

export class CustomerBalance {
  Counterparty!: string;

  Currency!: string;

  Amount!: string;

  AvailableAmount!: string;

  OutstandingBuy!: string;

  OutstandingSell!: string;

  PendingWithdrawal!: string;

  Timestamp!: string;

  MarketAccount!: string;

  Equivalent?: {
    Amount: string;
    Currency: string;
  };

  UnsettledAmount?: string;

  // Custom for unique Row ID
  get rowID() {
    return getCustomerBalanceRowID(this);
  }

  constructor(data: CustomerBalance) {
    Object.assign(this, data);
  }
}

export const getCustomerBalanceRowID = (
  customerBalance: Pick<CustomerBalance, 'Counterparty' | 'MarketAccount' | 'Currency'>
) => {
  return `${customerBalance.Counterparty}-${customerBalance.MarketAccount}-${customerBalance.Currency}`;
};

interface UseCustomerBalanceColumns {
  defaultColumns?: (keyof CustomerBalance | Partial<Column>)[];
}

export function useCustomerBalanceColumns({ defaultColumns = EMPTY_ARRAY }: UseCustomerBalanceColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            {
              field: 'Counterparty',
              type: 'customer',
            },
            {
              field: 'Currency',
              type: 'text',
            },
            {
              field: 'Amount',
              type: 'size',
            },
            {
              field: 'AvailableAmount',
              type: 'size',
            },
            {
              field: 'OutstandingBuy',
              type: 'size',
            },
            {
              field: 'OutstandingSell',
              type: 'size',
            },
            {
              field: 'PendingWithdrawal',
              type: 'size',
            },
            {
              field: 'Timestamp',
              type: 'date',
            },
            {
              field: 'MarketAccount',
              type: 'marketAccount',
            },
            {
              field: 'rowID',
              type: 'text',
            },
          ] satisfies ColumnDef<CustomerBalance>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );
  const columnDefinitions = useMemo(() => {
    return new Map(
      ([...defaultVisibleColumns.values()] satisfies ColumnDef<CustomerBalance>[] as Column[]).map(c => [
        getAgGridColId(c),
        c,
      ])
    );
  }, [defaultVisibleColumns]);
  return useDefaultColumns(defaultColumns, columnDefinitions);
}

export class CustomerBalanceTransaction {
  Type!: string;

  Counterparty!: string;

  Currency!: string;

  Amount!: string;

  Status!: CustomerBalanceTransactionStatusEnum;

  Revision!: string;

  Timestamp!: string;

  TradeID!: string;

  ExternalID!: string;

  Comments!: string;

  ExternalComments?: string;

  MarketAccount!: string;

  TxHash!: string;

  TransactionID!: string;

  get rowID() {
    return `${this.MarketAccount}-${this.Currency}-${this.Revision}`;
  }

  constructor(data: CustomerBalanceTransaction) {
    Object.assign(this, data);
  }
}

export function isCustomerBalanceTransactionActionable(transactionStatusEnum: CustomerBalanceTransactionStatusEnum) {
  return transactionStatusEnum === CustomerBalanceTransactionStatusEnum.PendingApproval;
}

export function isCustomerBalanceTransactionTerminal(transactionStatusEnum: CustomerBalanceTransactionStatusEnum) {
  return (
    transactionStatusEnum === CustomerBalanceTransactionStatusEnum.Completed ||
    transactionStatusEnum === CustomerBalanceTransactionStatusEnum.Rejected ||
    transactionStatusEnum === CustomerBalanceTransactionStatusEnum.Canceled
  );
}

interface UseCustomerBalanceTransactionColumns {
  defaultColumns?: (keyof CustomerBalanceTransaction | Partial<Column>)[];
}

export function useCustomerBalanceTransactionColumns({
  defaultColumns = EMPTY_ARRAY,
}: UseCustomerBalanceTransactionColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            {
              field: 'Type',
              type: 'text',
            },
            {
              field: 'Counterparty',
              type: 'customer',
            },
            {
              field: 'Currency',
              type: 'currency',
            },
            {
              field: 'Amount',
              type: 'size',
            },
            {
              field: 'Status',
              type: 'transferStatus',
            },
            {
              field: 'Revision',
              type: 'text',
            },
            {
              field: 'Timestamp',
              type: 'date',
            },
            {
              field: 'TradeID',
              type: 'id',
            },
            {
              field: 'ExternalID',
              type: 'text',
            },
            {
              field: 'Comments',
              type: 'text',
            },
            {
              field: 'MarketAccount',
              type: 'marketAccount',
            },
            { field: 'TxHash', type: 'text' },
          ] satisfies ColumnDef<CustomerBalanceTransaction>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );
  const columnDefinitions = useMemo(() => {
    return new Map(
      ([...defaultVisibleColumns.values()] satisfies ColumnDef<CustomerBalanceTransaction>[] as Column[]).map(c => [
        getAgGridColId(c),
        c,
      ])
    );
  }, [defaultVisibleColumns]);
  return useDefaultColumns(defaultColumns, columnDefinitions);
}

export class CustomerTransaction {
  TransactionID!: string;
  Timestamp!: string;
  ClReqID!: string;
  Revision!: number;
  TransactionType!: CustomerBalanceTransactionTypeEnum;
  Quantity!: number;
  Currency!: string;
  CustomerAddressID!: string;
  CustomerUser!: string;
  Counterparty!: string;
  MarketAccount!: string;
  DealerUser?: string;
  TreasuryUser?: string;
  TransferID?: string;
  TxHash?: string;
  Status!: CustomerBalanceTransactionStatusEnum;
  RejectReason?: string;
  RejectText?: string;
  Comments?: string;
  ExternalComments?: string;
  Initiator?: 'Dealer' | 'Customer';
  InitiatorUser?: string;

  constructor(data: CustomerTransaction) {
    Object.assign(this, data);
  }
}

interface UseCustomerTransactionColumns {
  defaultColumns?: (keyof CustomerTransaction | Partial<Column>)[];
}

export function useCustomerTransactionColumns({
  defaultColumns = EMPTY_ARRAY,
}: UseCustomerTransactionColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            {
              field: 'TransactionID',
              type: 'text',
            },
            {
              field: 'Timestamp',
              type: 'text',
            },
            {
              field: 'ClReqID',
              type: 'text',
            },
            {
              field: 'Revision',
              type: 'text',
            },
            {
              field: 'TransactionType',
              type: 'text',
            },
            {
              field: 'Quantity',
              type: 'text',
            },
            {
              field: 'Currency',
              type: 'text',
            },
            {
              field: 'CustomerAddressID',
              type: 'text',
            },
            {
              field: 'CustomerUser',
              type: 'text',
            },
            {
              field: 'Counterparty',
              type: 'text',
            },
            {
              field: 'MarketAccount',
              type: 'text',
            },
            {
              field: 'DealerUser',
              type: 'text',
            },
            {
              field: 'TreasuryUser',
              type: 'text',
            },
            {
              field: 'TransferID',
              type: 'text',
            },
            {
              field: 'TxHash',
              type: 'text',
            },
            {
              field: 'Status',
              type: 'text',
            },
            {
              field: 'RejectReason',
              type: 'text',
            },
            {
              field: 'RejectText',
              type: 'text',
            },
            {
              field: 'Comments',
              type: 'text',
            },
            {
              field: 'Initiator',
              type: 'text',
            },
            {
              field: 'InitiatorUser',
              type: 'text',
            },
          ] satisfies ColumnDef<CustomerTransaction>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );
  const columnDefinitions = useMemo(() => {
    return new Map(
      ([...defaultVisibleColumns.values()] satisfies ColumnDef<CustomerTransaction>[] as Column[]).map(c => [
        getAgGridColId(c),
        c,
      ])
    );
  }, [defaultVisibleColumns]);
  return useDefaultColumns(defaultColumns, columnDefinitions);
}
