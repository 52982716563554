export const Logo = ({ fill }) => (
  <>
    <path
      d="M158.215 76C157.16 75.9967 156.122 76.2576 155.194 76.7589C154.267 77.2603 153.479 77.9861 152.905 78.8701L102.755 155.87C102.473 156.307 102.323 156.815 102.323 157.335C102.323 157.855 102.473 158.363 102.755 158.8L115.095 177.96C115.385 178.412 115.785 178.784 116.257 179.041C116.728 179.299 117.257 179.434 117.795 179.434C118.332 179.434 118.861 179.299 119.333 179.041C119.804 178.784 120.204 178.412 120.495 177.96L186.785 76.1L158.215 76Z"
      fill={fill}
      fillOpacity="0.5"
    />
    <path
      d="M97.0546 104.4L72.7046 104.32C72.2175 104.314 71.7379 104.441 71.3179 104.688C70.8978 104.934 70.5533 105.291 70.3215 105.72C70.0898 106.148 69.9796 106.632 70.0031 107.118C70.0266 107.605 70.1827 108.076 70.4546 108.48L82.2946 127.05L97.0546 104.4Z"
      fill={fill}
    />
    <path
      d="M100.455 154.97L143.245 89.1901L113.455 89.1001C112.4 89.0959 111.361 89.3564 110.434 89.8578C109.506 90.3593 108.719 91.0856 108.145 91.9701L84.7446 127.81C84.4626 128.247 84.3126 128.755 84.3126 129.275C84.3126 129.795 84.4626 130.304 84.7446 130.74L100.455 154.97Z"
      fill={fill}
      fillOpacity="0.75"
    />
  </>
);
