// Command - Borrower
import type { IParticipantTransferConfig, LoanQuoteRequestTypeEnum, LoanQuoteTerminationTypeEnum } from './types';

export type LoanQuoteRequest = {
  Timestamp: string; // datetime
  QuoteReqID: string;
  PrincipalCurrency: string; // currency
  CollateralCurrency?: string; // currency
  Qty: string; // decimal
  Rate?: string; // decimal
  CollateralRatio?: string; // decimal
  TerminationTime?: string; // datetime
  Lenders: string[]; // counterparty array
  Comments?: string;
  RequestType: LoanQuoteRequestTypeEnum;
};

export enum LoanSides {
  Borrow = 'borrow',
  Lend = 'lend',
}

// Command - Borrower & Lender
export type LoanQuoteUpdate = {
  Timestamp: string; // datetime
  QuoteReqID: string;
  NegotiationID: string;
  TargetRevision: number;
  CollateralCurrency?: string; // currency
  Qty: string; // decimal
  Rate?: string; // decimal
  TerminationType: LoanQuoteTerminationTypeEnum;
  CollateralRatio?: string; // decimal
  TerminationTime?: string; // datetime
  Comments?: string;
};

// Command - Borrower
export type LoanQuoteAccept = {
  Timestamp: string; // datetime
  QuoteReqID: string;
  NegotiationID: string;
  TargetRevision: number;
};

// Command - Borrower
export type LoanQuoteCancel = {
  Timestamp: string; // datetime
  QuoteReqID: string;
  NegotiationID: string;
  Comments?: string;
};

// Command - Lender
export type LoanQuoteReject = {
  Timestamp: string; // datetime
  QuoteReqID: string;
  NegotiationID: string;
  Comments?: string;
};

// Command - Admin
export type LoanQuoteAdminRequest = {
  Timestamp: string; // datetime
  QuoteReqID: string;
  NegotiationID: string;
  Action: string; // LoanQuoteAdminAction
};

export type ParticipantTransferConfig = IParticipantTransferConfig;
