import { addMinutes, startOfDay } from 'date-fns';

/**
 * Given a number x representing the minutes between each selection (eg: 5, 30, 120),
 * returns a list of Dates with times for each interval
 * @param x amount of minutes between each date
 * @returns a list of dates
 */
export function getDatesEveryXMinutes(x: number): Date[] {
  const dates: Date[] = [];
  for (let i = 0; i < 24 * 60; i += x) {
    dates.push(addMinutes(startOfDay(new Date()), i));
  }

  return dates;
}
