import { useCallback, useEffect, useMemo, useState } from 'react';
import { IconName } from '../Icons';
import {
  Details,
  DropDownIconButton,
  IconContainer,
  RowContainer,
  TextAndIconContainer,
  TitleText,
  TreeContainer,
  TreeLevelContainer,
  VerticalLevelLine,
  VerticalLevelLineContainer,
} from './styles';
import type { Node, NodeType, Tree, TreeViewState, TreeViewStateProps } from './types';

export interface TreeViewProps<T> {
  tree: Tree<T>;
  width: string;
  height: string;
  renderNode?: (node: Node<T>, rootType: NodeType) => JSX.Element | string;
  highlighted?: string[];
  sortNodeChildren?: (a: Node<T>, b: Node<T>) => number;
}

export const TreeView = function TreeView<T>({
  tree,
  width,
  renderNode,
  toggleNode,
  openNodes,
  highlighted,
  height,
  sortNodeChildren,
  scrollToId,
  setScrollToId,
}: TreeViewProps<T> & TreeViewState<T>) {
  const handleScroll = useCallback(
    (id: string) => {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        setScrollToId(null);
      }
    },
    [setScrollToId]
  );

  useEffect(() => {
    if (scrollToId && openNodes.has(scrollToId)) {
      handleScroll(scrollToId);
    }
  }, [scrollToId, handleScroll, openNodes]);

  const getNodeType = (node: Node<T>): NodeType => {
    if (node.children.length === 0) {
      return 'leaf';
    }
    if (tree.rootIds.includes(node.id)) {
      return 'root';
    }
    return 'child';
  };

  const treeWithSortedNodes = useMemo(() => {
    const sortNodeAB = (a: string, b: string) => {
      const nodeA = tree.nodes.get(a);
      const nodeB = tree.nodes.get(b);
      if (nodeA && nodeB) {
        return typeof sortNodeChildren === 'function' ? sortNodeChildren(nodeA, nodeB) : 0;
      }
      return 0;
    };
    const sortedTree: Tree<T> = {
      rootIds: tree.rootIds.sort((a: string, b: string) => {
        return sortNodeAB(a, b);
      }),
      nodes: new Map(),
    };
    const sortAndInsertChildren = (node: Node<T>) => {
      sortedTree.nodes.set(node.id, {
        ...node,
        children: node.children.sort((a: string, b: string) => sortNodeAB(a, b)),
      });
      for (const child of node.children) {
        const treeNode = tree.nodes.get(child);
        if (treeNode) {
          sortAndInsertChildren(treeNode);
        }
      }
    };
    for (const root of sortedTree.rootIds) {
      const rootNode = tree.nodes.get(root);
      if (rootNode) {
        sortAndInsertChildren(rootNode);
      }
    }
    return sortedTree;
  }, [tree, sortNodeChildren]);

  const getNodeDetail = (node: Node<T>, highlightedLine: boolean, isRoot?: boolean) => {
    const nodeType = getNodeType(node);
    return (
      <TreeLevelContainer key={node.id} id={node.id} data-testid={`tree-${node.id}`}>
        {!isRoot && (
          <VerticalLevelLineContainer>
            <VerticalLevelLine highlighted={highlightedLine} />
          </VerticalLevelLineContainer>
        )}
        <TextAndIconContainer flexDirection="column" justifyContent="center" alignItems="flex-start">
          {nodeType === 'leaf' ? (
            <RowContainer>
              <TitleText highlighted={highlighted?.includes(node.id) || false}>
                {typeof renderNode === 'function' ? renderNode(node, nodeType) : node.name}
              </TitleText>
            </RowContainer>
          ) : (
            <RowContainer>
              <IconContainer>
                <DropDownIconButton
                  highlighted={highlighted?.includes(node.id) || false}
                  ghost
                  onClick={() => toggleNode(node.id)}
                  icon={openNodes.has(node.id) ? IconName.TriangleDown : IconName.TriangleRight}
                />
              </IconContainer>
              <TitleText highlighted={highlighted?.includes(node.id) || false}>
                {typeof renderNode === 'function' ? renderNode(node, nodeType) : node.name}
              </TitleText>
            </RowContainer>
          )}
          <Details open={openNodes.has(node.id)}>
            {node.children.map(child => {
              const treeNode = treeWithSortedNodes.nodes.get(child);
              if (treeNode) {
                return getNodeDetail(treeNode, highlighted?.includes(node.id) || false);
              } else {
                return null;
              }
            })}
          </Details>
        </TextAndIconContainer>
      </TreeLevelContainer>
    );
  };

  return (
    <TreeLevelContainer w={width} maxHeight={height}>
      <TreeContainer>
        {treeWithSortedNodes.rootIds.map(root => {
          const rootNode = treeWithSortedNodes.nodes.get(root);
          if (rootNode) {
            return getNodeDetail(rootNode, highlighted?.includes(rootNode.id) || false, true);
          } else {
            return null;
          }
        })}
      </TreeContainer>
    </TreeLevelContainer>
  );
};

export function useTreeview<T>({ nodes, tree }: TreeViewStateProps<T>): TreeViewState<T> {
  const [openNodes, setOpenNodes] = useState<Set<string>>(nodes || new Set());
  const [scrollToId, setScrollToId] = useState<string | null>(null);
  const openNode = useCallback((id: string) => {
    setOpenNodes(nodes => {
      nodes.add(id);
      return new Set(nodes);
    });
  }, []);

  const closeNode = useCallback((id: string) => {
    setOpenNodes(nodes => {
      nodes.delete(id);
      return new Set(nodes);
    });
  }, []);

  const closeAll = useCallback(() => {
    setOpenNodes(new Set());
  }, []);

  const scrollToNode = useCallback((id: string) => {
    setScrollToId(id);
  }, []);

  const openToNode = useCallback(
    (id: string) => {
      setOpenNodes(nodes => {
        //recursively open parents of node to fully open a tree down to a specific node
        nodes.clear();
        function open(id: string) {
          if (nodes.has(id)) {
            return;
          }
          nodes.add(id);
          //open all parents
          const parents = tree.nodes.get(id)?.parents;
          if (parents) {
            for (const parent of parents) {
              open(parent);
            }
          }
        }
        open(id);
        return new Set(nodes);
      });
    },
    [tree.nodes]
  );

  const toggleNode = useCallback((id: string) => {
    setOpenNodes(nodes => {
      if (nodes.has(id)) {
        nodes.delete(id);
      } else {
        nodes.add(id);
      }
      return new Set(nodes);
    });
  }, []);

  return useMemo(
    () => ({
      openNode,
      closeNode,
      closeAll,
      openToNode,
      toggleNode,
      openNodes,
      tree,
      scrollToNode,
      scrollToId,
      setScrollToId,
    }),
    [openNode, closeNode, closeAll, openToNode, toggleNode, openNodes, tree, scrollToNode, scrollToId, setScrollToId]
  );
}
