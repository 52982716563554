import { hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { LightTheme, lightColors, mapLightTheme } from './LightTheme';
import { getInputStylesAppliedToSelect } from './utils';

const colors: Colors = {
  gray: {
    main: hsla(210, 1.0, 1.0, 1),
    '000': hsla(210, 0.1, 0.92, 1),
    '010': setAlpha(0.1, '#8d9ea5'),
    '020': setAlpha(0.11, '#8d9ea5'),
    '030': setAlpha(0.14, '#8d9ea5'),
    '040': setAlpha(0.17, '#8d9ea5'),
    '050': setAlpha(0.21, '#8d9ea5'),
    '060': setAlpha(0.26, '#8d9ea5'),
    '070': setAlpha(0.31, '#8d9ea5'),
    '080': setAlpha(0.37, '#8d9ea5'),
    '090': '#8d9ea5',
    '100': '#0f151e',
  },

  black: {
    default: '#000000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#FFFFFF',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.1)',
  },

  primary: {
    mute: 'rgba(15, 166, 223, 0.1)',
    dim: 'rgba(15, 166, 223, 0.4)',
    default: '#0fa6df',
    lighten: '#73c5e5',
  },

  green: {
    mute: 'rgba(25, 165, 89, 0.1)',
    dim: 'rgba(25, 165, 89, 0.4)',
    default: '#19a559',
    lighten: '#69b790',
  },

  red: {
    default: '#922E43',
    lighten: '#D23557',
    dim: 'rgba(146, 46, 67, 0.4)',
    mute: 'rgba(146, 46, 67, 0.1)',
  },

  yellow: {
    default: '#CCAC3E',
    lighten: '#F2D264',
    dim: 'rgba(204, 172, 62, 0.4)',
    mute: 'rgba(204, 172, 62, 0.1)',
  },

  blue: {
    default: '#458CC1',
    lighten: '#53AFF8',
    dim: 'rgba(69, 140, 193, 0.4)',
    mute: 'rgba(69, 140, 193, 0.1)',
  },

  orange: {
    default: '#E65200',
    lighten: '#FF6B00',
    dim: 'rgba(230, 82, 0, 0.4)',
    mute: 'rgba(230, 82, 0, 0.1)',
  },

  purple: lightColors.purple,
};

export const LightISPTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightISP',

  backgroundBody: colors.gray['010'],

  backgroundDefaultButton: setAlpha(0.52, '#8d9ea5'),
  backgroundDefaultButtonHover: setAlpha(0.42, '#8d9ea5'),
  backgroundDefaultButtonFocus: setAlpha(0.42, '#8d9ea5'),
  backgroundDefaultButtonActive: setAlpha(0.52, '#8d9ea5'),

  backgroundPriorityButton: setAlpha(0.52, '#8d9ea5'),
  backgroundPriorityButtonHover: setAlpha(0.42, '#8d9ea5'),
  backgroundPriorityButtonFocus: setAlpha(0.42, '#8d9ea5'),
  backgroundPriorityButtonActive: setAlpha(0.52, '#8d9ea5'),

  ...getInputStylesAppliedToSelect(LightTheme),

  colorTextDefault: '#8d9ea5',

  backgroundVolumeLadderBidSpread: setAlpha(0.6, colors.primary.default),
  backgroundVolumeLadderOfferSpread: setAlpha(0.6, colors.primary.default),
});
