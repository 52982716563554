import { datadogRum } from '@datadog/browser-rum';
import * as Sentry from '@sentry/browser';
import type { ScopeContext } from '@sentry/types';

type LogContext = Partial<Pick<ScopeContext, 'contexts' | 'extra' | 'tags'>>;

function error(error: Error, context?: LogContext) {
  if (import.meta.env.DEV) {
    console.error(error);
    return {};
  }

  const sentryId = Sentry.captureException(error, context);
  datadogRum.addError(error, context);

  return { sentryId };
}

function warn(message: string, context?: LogContext) {
  if (import.meta.env.DEV) {
    console.warn(message);
    return;
  }

  const sentryId = Sentry.captureMessage(message, {
    ...context,
    level: 'warning',
  });

  return { sentryId };
}

function info(message: string, context?: LogContext) {
  if (import.meta.env.DEV) {
    console.info(message);
    return;
  }

  const sentryId = Sentry.captureMessage(message, {
    ...context,
    level: 'info',
  });

  return { sentryId };
}

function debug(message: string, context?: LogContext) {
  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    console.debug(message);
    return;
  }

  const sentryId = Sentry.captureMessage(message, {
    ...context,
    level: 'debug',
  });

  return { sentryId };
}

export const logger = {
  error,
  warn,
  info,
  debug,
};
