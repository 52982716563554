import { useRef, type PropsWithChildren } from 'react';

import { Flex } from '../Core';
import { CenterSlot, Header, LeftSlot, RightSlot, SubHead } from './styles';
import type { MarketDataCardHeaderProps } from './types';

export const MarketDataCardHeader = ({
  actionItems,
  activeOrder,
  children,
  className,
  isDraggable,
  renderClickToTradeConfirmation,
  security,
  slotFlexRule,
  bottomCenterItems,
  topCenterItems,
  topSubheader,
  topLeftItems,
  topRightItems,
}: PropsWithChildren<MarketDataCardHeaderProps>) => {
  const actionItemsRef = useRef<HTMLDivElement | null>(null);
  const bottomCenterItemsRef = useRef<HTMLDivElement | null>(null);
  const topCenterItemsRef = useRef<HTMLDivElement | null>(null);
  const topLeftItemsRef = useRef<HTMLDivElement | null>(null);
  const topRightItemsRef = useRef<HTMLDivElement | null>(null);
  return (
    <>
      <Header className={className} isDraggable={!!isDraggable}>
        {topLeftItems || slotFlexRule ? (
          <LeftSlot ref={topLeftItemsRef} flex={slotFlexRule}>
            {topLeftItems}
          </LeftSlot>
        ) : null}
        {topCenterItems && (
          <CenterSlot
            ref={topCenterItemsRef}
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            flexBasis="100%"
          >
            {topCenterItems}
          </CenterSlot>
        )}
        {topRightItems && (
          <RightSlot ref={topRightItemsRef} flex={slotFlexRule}>
            {topRightItems}
          </RightSlot>
        )}
      </Header>
      <SubHead>{topSubheader}</SubHead>
      {children}
      {bottomCenterItems && (
        <CenterSlot
          ref={bottomCenterItemsRef}
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          flexBasis="100%"
        >
          {bottomCenterItems}
        </CenterSlot>
      )}
      {(activeOrder && renderClickToTradeConfirmation) || actionItems ? (
        <Flex alignItems="stretch" justifyContent="center" w="100%" ref={actionItemsRef}>
          {activeOrder && renderClickToTradeConfirmation
            ? renderClickToTradeConfirmation(activeOrder, security)
            : actionItems}
        </Flex>
      ) : null}
    </>
  );
};
