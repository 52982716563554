export const EXPANDABLE_HEADER_HEIGHT = 24;
export const EXPANDABLE_MIN_HEIGHT = 32; // When the ExpandableBottomPanel is collapsed, it is slightly taller to make it more visible
export const EXPANDABLE_DEFAULT_HEIGHT = 320;
export const BLOTTER_MIN_HEIGHT = 150;
export const PRINCIPAL_MAIN_HEADER_HEIGHT = 32;
export const SECONDARY_HEADER_HEIGHT = 24;
export const PANEL_HEADER_HEIGHT = 96;
export const COLUMN_WIDTH = 310;
export const CARD_WIDTH = 300;
export const CARD_HEIGHT = 380;
export const COMPACT_CARD_HEIGHT = 189;
export const EXPANDED_CARD_HEIGHT = 571;
