import { Children, cloneElement } from 'react';
import styled, { useTheme } from 'styled-components';
import { HStack } from '../Core';
import { ICON_SIZES, Icon, IconName } from '../Icons';
import { BreadcrumbWrapper } from './styles';

export { BreadcrumbItem, BreadcrumbWrapper } from './styles';

export const Breadcrumb = styled(({ children }) => {
  const theme = useTheme();

  const elements = Children.map(children, (c, index) => {
    const isLast = index === children.length - 1;
    return (
      <HStack key={index}>
        {cloneElement(c, { $isLast: isLast })}
        {!isLast ? <Icon icon={IconName.ChevronRight} color={theme.colorTextDefault} size={ICON_SIZES.TINY} /> : null}
      </HStack>
    );
  });

  return <BreadcrumbWrapper>{elements}</BreadcrumbWrapper>;
})``;
