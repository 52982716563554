import styled from 'styled-components';

import { MarketDataCard as MarketDataCardComponent } from './MarketDataCard';
import { MarketDataCardHeader as MarketDataCardHeaderComponent } from './MarketDataCardHeader';

export {
  ButtonActions as MarketDataCardButtonActions,
  DetailsWrapper as MarketDataCardDetailsWrapper,
  ExpandDetails as MarketDataCardExpandDetails,
  FormWrapper as MarketDataCardFormWrapper,
  CardLabel as MarketDataCardLabel,
  PlaceholderWrapper as MarketDataCardPlaceholderWrapper,
  Side as MarketDataCardSide,
  Summary as MarketDataCardSummary,
  Wrapper as MarketDataCardWrapper,
} from './styles';

export const MarketDataCard = styled(MarketDataCardComponent)``;
export const MarketDataCardHeader = styled(MarketDataCardHeaderComponent)``;
