import { useLocation } from 'react-router';
import { useNavigationItems } from './useNavigationItems';

export function useCurrentNavigationItem() {
  const navItems = useNavigationItems();
  const location = useLocation();
  const path = location.pathname;

  return navItems?.find(ni => ni.path === path);
}
