import type { ModeEnum } from './types';

export class CustomerTradingLimit {
  WarnThreshold!: string;
  RejectThreshold!: string;
  ThresholdCurrency!: string;
  Mode!: ModeEnum;
  Counterparty?: string; //name of Customer
  CustomerUser?: string; //email of CustomerUser
  Currency?: string;
  Symbol?: string;
  Description?: string;
  TradingLimitID?: string;
  UpdateAction?: string;
  MarketAccount?: string;

  constructor(data: CustomerTradingLimit) {
    Object.assign(this, data);
  }
}
