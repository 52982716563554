/**
 * See: https://github.com/facebook/react/issues/10135
 * This function sets the value of the given input/textarea element and sets it value programmatically, as well as
 * emits an event which bubbles up and triggers react change detection.
 *
 * This is needed because just setting the element.value does not trigger react change detection,
 * and element.onChange() takes an advanced kind of event. The solution below is a proposed work-around
 * from the react github. See link above.
 * @param element
 * @param value
 */
export function setNativeValue(element: HTMLInputElement | HTMLTextAreaElement, value: string) {
  const { set: valueSetter } = Object.getOwnPropertyDescriptor(element, 'value') || {};
  const prototype = Object.getPrototypeOf(element);
  const { set: prototypeValueSetter } = Object.getOwnPropertyDescriptor(prototype, 'value') || {};

  if (prototypeValueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter.call(element, value);
  } else if (valueSetter) {
    valueSetter.call(element, value);
  } else {
    throw new Error('The given element does not have a value setter');
  }

  element.dispatchEvent(new Event('input', { bubbles: true }));
}
