import { useCallback, useRef } from 'react';

/**
 * Provides a stable function that will always call the latest version of the provided callback.
 * @param callback The callback to be used
 * @returns
 */
export function useDynamicCallback<T extends (...args: never[]) => unknown>(callback?: T): T {
  const ref = useRef<T>();
  ref.current = callback;
  // @ts-expect-error Weird type-checking issue here, should probably find a way to fix that yet
  return useCallback<T>((...args) => ref.current?.apply(this, args), []);
}
