import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { base } from './BaseTheme';
import { darkColors, mapDarkTheme } from './DarkTheme';

const colors: Colors = {
  white: darkColors.white,
  black: darkColors.black,
  orange: darkColors.orange,
  purple: darkColors.purple,

  gray: {
    main: '#181B25',

    '000': '#010412',
    '010': '#10131F',
    '020': '#20232D',
    '030': '#272933',
    '040': '#2F313A',
    '050': '#373940',
    '060': '#414349',
    '070': '#535558',
    '080': '#808280',
    '090': '#A3A49E',
    '100': '#FFFFEE',
  },

  primary: {
    mute: setAlpha(0.1, '#7D8CA3'),
    dim: setAlpha(0.4, '#7D8CA3'),
    default: '#7D8CA3',
    lighten: '#91A0B7',
  },

  green: {
    mute: setAlpha(0.1, '#28BB8F'),
    dim: setAlpha(0.4, '#28BB8F'),
    default: '#28BB8F',
    lighten: '#32C599',
  },

  red: {
    mute: setAlpha(0.1, '#FF435A'),
    dim: setAlpha(0.4, '#FF435A'),
    default: '#FF435A',
    lighten: '#FF4D64',
  },

  yellow: {
    mute: setAlpha(0.1, '#CCAC3E'),
    dim: setAlpha(0.4, '#CCAC3E'),
    default: '#CCAC3E',
    lighten: '#F2D264',
  },

  blue: {
    mute: setAlpha(0.1, '#338EEB'),
    dim: setAlpha(0.4, '#338EEB'),
    default: '#338EEB',
    lighten: '#4AA4FF',
  },
};

const baseSize = base.baseSize;

export const DarkPxTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkPx',

  borderRadiusContent: baseSize,
  borderRadiusMedium: baseSize * 0.4,
  borderRadiusDefault: baseSize * 0.3,
  borderRadiusSmall: baseSize * 0.2,
  borderRadiusTiny: baseSize * 0.1,
  spacingLayout: baseSize * 0.5,
  borderRadiusButtonLarge: baseSize * 0.75,
  borderRadiusButtonDefault: baseSize * 0.5,
  borderRadiusButtonSmall: baseSize * 0.25,
  borderRadiusButtonTiny: baseSize * 0.1,

  colorTextPositiveButton: colors.black.default,
  colorTextPositiveButtonHover: colors.black.default,
  colorTextPositiveButtonActive: colors.black.default,
  colorTextPositiveButtonDisabled: setAlpha(0.7, colors.black.default),
  colorTextPositiveButtonFocus: colors.black.default,

  colorTextNegativeButton: colors.black.default,
  colorTextNegativeButtonHover: colors.black.default,
  colorTextNegativeButtonActive: colors.black.default,
  colorTextNegativeButtonDisabled: setAlpha(0.7, colors.black.default),
  colorTextNegativeButtonFocus: colors.black.default,

  colorTextPrimaryButton: colors.white.default,
  colorTextPrimaryButtonHover: colors.white.default,
  colorTextPrimaryButtonActive: colors.white.default,
  colorTextPrimaryButtonDisabled: setAlpha(0.7, colors.white.default),
  colorTextPrimaryButtonFocus: colors.white.default,

  backgroundDrawer: colors.gray.main,
  backgroundSidebar: colors.gray.main,
  colorTabActive: colors.gray.main,
});
