import { useCallback, useState } from 'react';
import { useDynamicCallback } from '../../hooks/useDynamicCallback';
import { useAccordion, type AccordionInputs, type AccordionOutputs } from '../Accordion';

import type { FilterBuilderSide, UseFilterBuilderOutput, UseFilterBuilderProps } from '../Filters/FilterBuilder/types';
import { useFilterBuilder } from '../Filters/FilterBuilder/useFilterBuilder';

export type UseAccordionFilterBuilderProps = {
  filterBuilderProps: UseFilterBuilderProps;
  accordionProps?: Parameters<typeof useAccordion>[0];
};
export type UseAccordionFilterBuilderOutput = {
  filterBuilder: UseFilterBuilderOutput;
  accordion: AccordionOutputs;
  openClause: UseFilterBuilderOutput['addAndOpenClause'];
};

export const useAccordionFilterBuilder = ({
  filterBuilderProps,
  accordionProps,
}: UseAccordionFilterBuilderProps): UseAccordionFilterBuilderOutput => {
  const filterBuilder = useFilterBuilder(filterBuilderProps);

  const checkForExistingClauses = useDynamicCallback(() => filterBuilder.filterClauses.length > 0);
  const openFilterClause = filterBuilder.addAndOpenClause;
  const filterBuilderOpenClause: (property: string | undefined, side?: FilterBuilderSide | undefined) => void =
    useCallback(
      (property, side) => !checkForExistingClauses() && openFilterClause(property, side),
      [checkForExistingClauses, openFilterClause]
    );

  const [onOpenedCallback, setOnOpenedCallback] = useState<AccordionInputs['onOpened']>(() => filterBuilderOpenClause);
  const accordion = useAccordion({ ...accordionProps, onOpened: onOpenedCallback });

  const { open: openAccordion } = accordion;

  const openClause = useCallback(
    (property: string | undefined, side?: FilterBuilderSide, value?: string | string[]) => {
      if (accordion.isOpen) {
        openFilterClause(property, side, value);
      } else {
        openAccordion();
        // This sets openedcallback to a function which will set itself back to the default state after executing
        setOnOpenedCallback(() => () => {
          openFilterClause(property, side, value);
          setOnOpenedCallback(() => filterBuilderOpenClause);
        });
      }
    },
    [openFilterClause, openAccordion, accordion.isOpen, filterBuilderOpenClause]
  );

  return { filterBuilder, accordion, openClause };
};
