import {
  CustomerBalanceDetails,
  getCreditAmount,
  useBalancesByMarketAccountCurrency,
  useWLExposuresContext,
  type Security,
} from '@talos/kyoko';
import Big from 'big.js';

interface BalanceDetailsProps {
  accountID?: string;
  security: Security;
}

export const BalanceDetails = ({ accountID, security }: BalanceDetailsProps) => {
  const { BaseCurrency, QuoteCurrency } = security;
  const balances = useBalancesByMarketAccountCurrency();

  const baseBalance = accountID ? balances?.get(accountID)?.get(BaseCurrency) : undefined;
  const quoteBalance = accountID ? balances?.get(accountID)?.get(QuoteCurrency) : undefined;

  const { activeExposures } = useWLExposuresContext();
  const exposure = activeExposures?.find(ex => ex.MarketAccount === accountID);

  const baseCreditAmount = getCreditAmount(baseBalance, exposure);
  const quoteCreditAmount = getCreditAmount(quoteBalance, exposure);

  const baseOutstandingBalance = Big(baseBalance?.OutstandingSell || '0').plus(baseBalance?.PendingWithdrawal || '0');
  const quoteOutstandingBalance = Big(quoteBalance?.OutstandingSell || '0').plus(
    quoteBalance?.PendingWithdrawal || '0'
  );

  return (
    <CustomerBalanceDetails
      accountID={accountID}
      baseAmount={baseBalance?.Amount}
      baseAvailableAmount={baseBalance?.AvailableAmount}
      baseCreditBalance={baseCreditAmount}
      baseOutstandingBalance={baseOutstandingBalance}
      pendingWithdrawal={
        baseBalance?.PendingWithdrawal === '0' ? quoteBalance?.PendingWithdrawal : baseBalance?.PendingWithdrawal
      }
      quoteAmount={quoteBalance?.Amount}
      quoteAvailableAmount={quoteBalance?.AvailableAmount}
      quoteCreditBalance={quoteCreditAmount}
      quoteOutstandingBalance={quoteOutstandingBalance}
      security={security}
    />
  );
};
