import {
  BaseStyle,
  Button,
  Crossmark,
  GlobalStyle,
  WLOrgConfigProvider,
  themes,
  useDisclosure,
  useWLOrgConfigContext,
} from '@talos/kyoko';
import { ThemeProvider } from 'styled-components';
import { ErrorBoundaryWrapper, ErrorMessage, ErrorTitle } from '../../components/ErrorBoundary/styles';

import { hashThemeRunTime } from '@talos/kyoko/src/styles';
import { useEffect } from 'react';
import { SupportModal } from '../../components/SupportModal';

export const Unauthorized = () => {
  return (
    <WLOrgConfigProvider>
      <UnauthorizedContent />
    </WLOrgConfigProvider>
  );
};

const UnauthorizedContent = () => {
  const { config, isLoaded } = useWLOrgConfigContext();
  const modalState = useDisclosure();

  useEffect(() => {
    if (isLoaded) {
      const { fontUrl } = themes[config.theme];
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = fontUrl;
      document.head.appendChild(link);
    }
  }, [config, isLoaded]);

  const handleOpenModal = () => {
    modalState.open();
  };

  if (!isLoaded) {
    return null;
  }

  const theme = themes[config.theme ?? hashThemeRunTime('DarkTheme')];

  return (
    <ThemeProvider theme={theme}>
      <BaseStyle />
      <GlobalStyle />
      <ErrorBoundaryWrapper>
        <div>
          <Crossmark />
          <ErrorTitle>You are not authorized to view this page</ErrorTitle>
          <ErrorMessage>
            If this issue persists, please get in touch with support and we will resolve the problem as soon as
            possible.
          </ErrorMessage>
          <p>
            <Button onClick={handleOpenModal}>Contact support</Button>
          </p>
        </div>
        <SupportModal {...modalState} />
      </ErrorBoundaryWrapper>
    </ThemeProvider>
  );
};
