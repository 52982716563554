import type { IHeaderParams } from 'ag-grid-community';
import { useRef } from 'react';
import { HStack } from '../Core';
import { Icon, IconName } from '../Icons';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';

/** Custom tooltip header (currently used in limited use cases)
 *
 * See {@link AgGridDefaultHeaderTooltip} for a React tooltip header that matches the default ag-grid header display
 */
export const AgGridTooltipHeader = (props: IHeaderParams & { tooltip: string }) => {
  const item = useRef(
    <HStack w="100%" justifyContent="space-between">
      <Text>{props.displayName}</Text>
      <Tooltip tooltip={props.tooltip} usePortal={true} placement="left">
        <Icon icon={IconName.QuestionMarkCircle} size={14} />
      </Tooltip>
    </HStack>
  );
  return item.current;
};
