import type { CSSProperties } from 'react';
import styled from 'styled-components';
import { parseDimension } from '../../styles';
import { Box, type BoxProps } from './Box';

export interface GridProps extends BoxProps {
  columns?: CSSProperties['gridTemplateColumns'];
  rows?: CSSProperties['gridTemplateRows'];
  gap?: CSSProperties['gap'] | string;
  placeItems?: CSSProperties['placeItems'];
}

export const Grid = styled(Box)<GridProps>`
  display: grid;
  ${({ placeItems }) => placeItems && `place-items: ${placeItems}`};
  ${({ columns }) => columns && `grid-template-columns: ${columns}`};
  ${({ rows }) => rows && `grid-template-rows: ${rows}`};
  ${({ theme, gap }) => gap && `gap: ${parseDimension(theme, gap)}`};
`;
