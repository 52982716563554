import type { ICellRendererParams } from 'ag-grid-enterprise';
import { ProductTypeEnum, type Security } from '../../types';
import { VStack } from '../Core';
import { CurrencyIcon } from '../CurrencyIcon';
import { Text } from '../Text';
import { CurrencyIconCell, SymbolCell } from './styles';

export function SymbolCellRenderer({ data }: ICellRendererParams) {
  const security: Security = data.security;
  return (
    <>
      <SymbolCell gap="spacingDefault">
        <CurrencyIconCell>
          <CurrencyIcon currency={security?.BaseCurrency} size="fontSizeHuge" />
        </CurrencyIconCell>
        <VStack alignItems="flex-start" title={security.Description}>
          {security.ProductType === ProductTypeEnum.Spot ? (
            <Text as="div" color="colors.gray.100">
              {security.DisplaySymbol}
            </Text>
          ) : (
            <>
              <Text as="div" color="colors.gray.100">
                {security.DisplaySymbol.match(/^(\w+)/)?.[1]}
              </Text>
              <Text as="div" color="colors.gray.100">
                {security.DisplaySymbol.match(/^\w+[^\w](.*)$/)?.[1]}
              </Text>
            </>
          )}
        </VStack>
      </SymbolCell>
    </>
  );
}
