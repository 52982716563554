import type React from 'react';
import { useEffect, useMemo, useRef } from 'react';
import { v1 as uuid } from 'uuid';
import { useConstant } from '../../hooks/useConstant';
import { useAccordionGroupContext } from './AccordionGroup/AccordionGroupContext';

export type AccordionInputs = {
  id?: string;
  initialOpen?: boolean;
  headerClickable?: boolean;
  onOpened?: () => void;
  onClosed?: () => void;
  ghost?: boolean;
  alignChevron?: 'left' | 'right';
};

export type AccordionOutputs = {
  isOpen: boolean;
  toggle: (e?: React.MouseEvent<HTMLElement>) => void;
  open: () => void;
  close: () => void;
  headerClickable: boolean;
  initialOpen: boolean;
  onOpened?: () => void;
  onClosed?: () => void;
  ghost: boolean;
  alignChevron?: 'left' | 'right';
};

export const useAccordion = ({
  id: customID,
  initialOpen = false,
  headerClickable = true,
  onOpened,
  onClosed,
  ghost = false,
  alignChevron = 'right',
}: AccordionInputs = {}) => {
  const id = useConstant(customID ?? uuid());
  const { accordionsMap, openAccordion, closeAccordion, toggleAccordion, registerAccordion, unregisterAccordion } =
    useAccordionGroupContext();

  const initialOpenRef = useRef(initialOpen);
  useEffect(() => {
    registerAccordion(id, initialOpenRef.current);
    return () => unregisterAccordion(id);
  }, [registerAccordion, unregisterAccordion, id]);

  // Listen to changes in the AccordionGroup map and pluck out any changes in our own open state
  const isOpen = useMemo(() => {
    const newOpen = accordionsMap.get(id);
    return newOpen == null ? initialOpen : newOpen;
  }, [accordionsMap, initialOpen, id]);

  const value = useMemo(() => {
    return {
      isOpen,
      toggle: () => toggleAccordion(id),
      open: () => openAccordion(id),
      close: () => closeAccordion(id),
      headerClickable,
      ghost,
      initialOpen,
      onOpened,
      onClosed,
      alignChevron,
    };
  }, [
    id,
    isOpen,
    openAccordion,
    closeAccordion,
    toggleAccordion,
    headerClickable,
    ghost,
    alignChevron,
    initialOpen,
    onOpened,
    onClosed,
  ]);

  return value;
};
