import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { DarkTheme, darkColors, mapDarkTheme } from './DarkTheme';
import { getInputStylesAppliedToSelect } from './utils';

const colors: Colors = {
  black: {
    default: '#000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    main: '#0f151e',

    '000': '#01030C',
    '010': '#10121A',
    '020': '#20222A',
    '030': '#272930',
    '040': '#2F3038',
    '050': '#37383F',
    '060': '#414249',
    '070': '#53545A',
    '080': '#808186',
    '090': '#A3A4A7',
    '100': '#E5E5E6',
  },

  primary: {
    mute: 'rgba(15, 166, 223, 0.1)',
    dim: 'rgba(15, 166, 223, 0.4)',
    default: '#0fa6df',
    lighten: '#73c5e5',
  },

  green: {
    mute: 'rgba(25, 165, 89, 0.1)',
    dim: 'rgba(25, 165, 89, 0.4)',
    default: '#19a559',
    lighten: '#69b790',
  },

  red: {
    mute: 'rgba(146, 46, 67, 0.1)',
    dim: 'rgba(146, 46, 67, 0.4)',
    default: '#922E43',
    lighten: '#D23557',
  },

  yellow: {
    mute: 'rgba(204, 172, 62, 0.1)',
    dim: 'rgba(204, 172, 62, 0.4)',
    default: '#CCAC3E',
    lighten: '#F2D264',
  },

  blue: {
    mute: 'rgba(69, 140, 193, 0.1)',
    dim: 'rgba(69, 140, 193, 0.4)',
    default: '#458CC1',
    lighten: '#53AFF8',
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: darkColors.purple,
};

export const DarkISPTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkISP',

  backgroundSidebar: colors.gray.main,
  borderColorSidebar: colors.gray.main,

  backgroundHeader: colors.gray.main,
  borderColorHeader: colors.gray.main,

  backgroundDefaultButton: setAlpha(0.42, '#8d9ea5'),
  backgroundDefaultButtonHover: setAlpha(0.52, '#8d9ea5'),
  backgroundDefaultButtonFocus: setAlpha(0.52, '#8d9ea5'),
  backgroundDefaultButtonActive: setAlpha(0.42, '#8d9ea5'),

  backgroundPriorityButton: setAlpha(0.42, '#8d9ea5'),
  backgroundPriorityButtonHover: setAlpha(0.52, '#8d9ea5'),
  backgroundPriorityButtonFocus: setAlpha(0.52, '#8d9ea5'),
  backgroundPriorityButtonActive: setAlpha(0.42, '#8d9ea5'),

  ...getInputStylesAppliedToSelect(DarkTheme),

  colorTextDefault: '#8d9ea5',

  backgroundVolumeLadderBidSpread: setAlpha(0.8, colors.primary.default),
  backgroundVolumeLadderOfferSpread: setAlpha(0.8, colors.primary.default),
});
