import { useMemo } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { MarketFeeModeEnum } from '../../../../types';
import { prettyName } from '../../../../utils';
import { IconName } from '../../../Icons';
import type { FilterableProperty } from '../types';

const messages = defineMessages({
  feeMode: {
    defaultMessage: 'Fee Mode',
    id: 'Filters.FilterBuilder.filters.feeMode',
  },
});

const DEALER_FEE_MODE_OPTIONS: MarketFeeModeEnum[] = [
  MarketFeeModeEnum.SpecificCurrency,
  MarketFeeModeEnum.QuoteCurrency,
  MarketFeeModeEnum.ReceiveCurrency,
  MarketFeeModeEnum.AmountCurrency,
];

/**
 * This hook creates the filter property definition for MarketFeeMode properties.
 *
 * @param overrides Any overrides to make to the defaults here (e.g. `key`, etc.)
 * @returns Filter Property definition to use for MarketFeeMode filters
 */
export function useMarketFeeModesFilter<TKey extends string = 'FeeModes', P = FilterableProperty<TKey>>(
  overrides?: { [K in keyof P]: K extends keyof FilterableProperty<TKey> ? P[K] : never } & { key?: TKey }
): FilterableProperty<TKey> {
  const { formatMessage } = useIntl();
  return useMemo(
    () => ({
      ...({
        key: 'FeeModes' as TKey,
        label: formatMessage(messages.feeMode),
        field: 'FeeMode',
        icon: IconName.Cash,
        options: DEALER_FEE_MODE_OPTIONS,
        getOptionLabel: prettyName,
      } satisfies FilterableProperty<TKey>),
      ...overrides,
    }),
    [formatMessage, overrides]
  );
}
