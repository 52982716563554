import { animated } from '@react-spring/web';
import styled from 'styled-components';
import { modeColor } from '../../styles';
import { Notification } from '../Notification';

export const Wrapper = styled.div<{ direction: 'column' | 'row' | 'column-reverse' | 'row-reverse' }>`
  position: relative;
  display: flex;
  flex-direction: ${({ direction }) => direction};
`;

export const ToastWrapper = styled(animated.div)`
  box-shadow: 0 5px 40px ${({ theme }) => modeColor(theme, 'hsla(0, 0%, 0%, 0.1)', theme.colors.black.dim)};
  transition: opacity 200ms ease, transform 200ms ease, maxHeight 200ms ease;
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  overflow: hidden;

  margin-top: ${({ theme }) => theme.spacingSmall}px;

  ${Notification} {
    &:not(:first-child) {
      ${({ theme }) => `margin-top: ${theme.spacingSmall}px;`}
    }
    &:not(:last-child) {
      ${({ theme }) => `margin-bottom: ${theme.spacingSmall}px;`}
    }
  }

  div {
    word-break: break-word;
  }
`;
