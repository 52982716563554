import type { Colors, DefaultTheme } from 'styled-components';
import { lightColors, mapLightTheme } from './LightTheme';

const colors: Colors = {
  ...lightColors,

  primary: {
    default: '#2015cc',
    lighten: '#554beb',
    dim: 'rgba(32, 21, 204, 0.4)',
    mute: 'rgba(32, 21, 204, 0.1)',
  },
};

export const LightBinalokaTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'Light',
});
