import Big from 'big.js';
import type { Balance } from '../types/Balance';
import type { CustomerBalance } from '../types/CustomerBalance';
import type { CustomerCredit } from '../types/CustomerCredit';
import type { EnrichedCreditExposure, Exposure } from '../types/Exposure';
import { bigMax, toBigWithDefault } from './number';

// https://talostrading.atlassian.net/browse/DEAL-2877
function getCreditBalance(customerBalance: Balance | CustomerBalance | undefined) {
  if (!customerBalance) {
    return Big(0);
  }
  return Big(customerBalance.AvailableAmount || '0')
    .minus(toBigWithDefault(bigMax(customerBalance.Amount, 0), 0))
    .minus(('PendingWithdrawal' in customerBalance && customerBalance.PendingWithdrawal) || '0')
    .minus(('OutstandingSell' in customerBalance && customerBalance.OutstandingSell) || '0');
}

export function getCreditAmount(
  customerBalance: Balance | CustomerBalance | undefined,
  creditOrExposure: CustomerCredit | Exposure | EnrichedCreditExposure | undefined
): Big | undefined {
  const creditBalance = getCreditBalance(customerBalance);

  // Only show Available Credit if we have an existing Exposure Record for it
  if (!creditOrExposure) {
    return undefined;
  }

  if (Big(creditBalance).lt(0)) {
    return Big(0);
  }

  return creditBalance;
}
