import type { ParameterTypeEnum } from './OrderStrategy';
import type { OrdTypeEnum, PresenceEnum } from './types';

export interface WLOrderStrategyParameter {
  Description: string;
  DisplayName: string;
  Name: string;
  Presence: PresenceEnum;
  Type: ParameterTypeEnum;
}

export interface WLOrderStrategy {
  Name: string;
  Group: string;
  Parameters: WLOrderStrategyParameter[];
  Description: string;
  DisplayName: string;
  OrdType: OrdTypeEnum;
}

export enum WLParameterKeysEnum {
  LimitPrice = 'LimitPrice',
  EndTime = 'EndTime',
  StartTime = 'StartTime',
  ErrorAction = 'ErrorAction',
  ClipSize = 'ClipSize',
  ClipInterval = 'ClipInterval',
  Variance = 'Variance',
}

export type WhitelabelParametersFormState = {
  [WLParameterKeysEnum.LimitPrice]?: string;
  [WLParameterKeysEnum.StartTime]?: string;
  [WLParameterKeysEnum.EndTime]?: string;
  [WLParameterKeysEnum.ErrorAction]?: string;
  [WLParameterKeysEnum.ClipSize]?: string;
  [WLParameterKeysEnum.ClipInterval]?: string;
  [WLParameterKeysEnum.Variance]?: string;
  [key: string]: unknown;
};
