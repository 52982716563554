import { useMemo } from 'react';
import { getAgGridColId } from '../components/BlotterTable/columns/getAgGridColId';
import type { PriceParams } from '../components/BlotterTable/columns/price.types';
import type { Column, ColumnDef } from '../components/BlotterTable/columns/types';
import { useDefaultColumns } from '../components/BlotterTable/useDefaultColumns';
import { EMPTY_ARRAY } from '../utils/empty';
import type {
  ExecTypeEnum,
  OrdRejReasonEnum,
  OrdStatusEnum,
  SideEnum,
  TimeInForceEnum,
  TradeStatusEnum,
} from './types';

export function isMarketOrder(entity: any): entity is MarketOrder {
  return entity instanceof MarketOrder;
}
// ExecutionReport5000
export class MarketOrder {
  static readonly rowID = 'OID';
  static readonly defaultColumns = [
    'SubmitTime',
    'OrdStatus',
    'MarketAccount',
    'ClOrdID',
    'Symbol',
    'Side',
    'OrderQty',
    'Price',
    'OID',
    'Status',
    'OrdType',
    'ExecType',
    'ExecID',
    'EID',
  ] satisfies (keyof MarketOrder)[];

  Timestamp!: string;

  MessageID?: string;

  ParentService?: string;

  Market?: string;

  Symbol?: string;

  CredentialID?: number;

  Status?: string;

  OrderID?: string;

  ClOrdID?: string;

  SubmitTime?: string;

  ExecID?: string;

  Side?: SideEnum;

  TransactTime?: string;

  ExecType?: ExecTypeEnum;

  OrdStatus!: OrdStatusEnum;

  OrderQty?: string;

  OrdType?: string;

  Price?: string;

  Currency?: string;

  LeavesQty?: string;

  CumQty?: string;

  AvgPx?: string;

  TimeInForce?: TimeInForceEnum;

  LastPx?: string;

  LastQty?: string;

  LastAmt?: string;

  LastFee?: string;

  CumAmt?: string;

  CumFee?: string;

  FeeCurrency?: string;

  OrdRejReason?: OrdRejReasonEnum;

  ClOID?: string;

  OID?: string;

  EID?: string;

  TradeStatus?: TradeStatusEnum;

  AggressorSide?: SideEnum;

  QID?: string;

  AmountCurrency?: string;

  ParentOrderID?: string;

  RID?: string;

  ExpectedFillPrice?: string;

  ExpectedFillQty?: string;

  MarketAccountID?: string;

  MarketAccount?: string;

  Text?: string;

  PricingReference?: string;

  constructor(data: MarketOrder) {
    Object.assign(this, data);
  }
}

interface UseMarketOrderColumns {
  defaultColumns?: (keyof MarketOrder | Partial<Column>)[];
}

export function useMarketOrderColumns({ defaultColumns = EMPTY_ARRAY }: UseMarketOrderColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            {
              field: 'SubmitTime',
              type: 'date',
              sortable: true,
              params: { milliseconds: true },
            },
            {
              field: 'OrdStatus',
              type: 'orderStatus',
              sortable: true,
            },
            {
              field: 'MarketAccount',
              type: 'text',
            },
            {
              id: 'marketAccountGroup',
              type: 'marketAccountGroupDerived',
              hide: true,
              params: {
                marketAccountField: 'MarketAccount' satisfies keyof MarketOrder,
              },
            },
            {
              field: 'ClOrdID',
              type: 'id',
            },
            {
              field: 'Symbol',
              type: 'security',
            },
            {
              field: 'Side',
              type: 'side',
            },
            {
              field: 'OrderQty',
              type: 'size',
              params: { currencyField: 'Currency' },
            },
            {
              field: 'Price',
              type: 'price',
              params: { assetField: 'Symbol', showReferencePrice: true } satisfies PriceParams,
            },
            {
              field: 'PricingReference',
              type: 'price',
              hide: true,
              params: {
                isReferencePrice: true,
              },
            },
            {
              field: 'OID',
              type: 'text',
            },
            {
              field: 'Status',
              type: 'text',
            },
            {
              field: 'OrdType',
              type: 'text',
              width: 100,
            },
            {
              field: 'ExecType',
              type: 'text',
              width: 100,
            },
            {
              field: 'ExecID',
              type: 'text',
              width: 400,
            },
            {
              field: 'EID',
              type: 'text',
            },
          ] satisfies ColumnDef<MarketOrder>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );
  const defaultHiddenColumns = useMemo(() => {
    return new Map(
      (
        [
          {
            field: 'Timestamp',
            type: 'date',
            sortable: true,
            params: { milliseconds: true },
          },
          {
            field: 'MessageID',
            type: 'text',
          },
          {
            field: 'ParentService',
            type: 'text',
          },
          {
            field: 'Market',
            type: 'market',
          },
          {
            field: 'CredentialID',
            type: 'text',
          },
          {
            field: 'OrderID',
            type: 'id',
          },
          {
            field: 'TransactTime',
            type: 'date',
            params: { milliseconds: true },
          },
          {
            field: 'Currency',
            type: 'currency',
          },
          {
            field: 'LeavesQty',
            type: 'size',
            params: { currencyField: 'Currency' },
          },
          {
            field: 'CumQty',
            type: 'size',
            params: { currencyField: 'Currency' },
          },
          {
            field: 'AvgPx',
            type: 'price',
            params: { assetField: 'Symbol' },
          },
          {
            field: 'TimeInForce',
            type: 'text',
          },
          {
            field: 'LastQty',
            type: 'size',
            params: { currencyField: 'Currency' },
          },
          {
            field: 'CumAmt',
            type: 'size',
            params: { currencyField: 'AmountCurrency' },
          },
          {
            field: 'CumFee',
            type: 'size',
            params: { currencyField: 'FeeCurrency' },
          },
          {
            field: 'FeeCurrency',
            type: 'currency',
          },
          {
            field: 'OrdRejReason',
            type: 'text',
          },
          {
            field: 'ClOID',
            type: 'text',
          },
          {
            field: 'TradeStatus',
            type: 'tradeStatus',
          },
          {
            field: 'AggressorSide',
            type: 'side',
          },
          {
            field: 'QID',
            type: 'text',
          },
          {
            field: 'AmountCurrency',
            type: 'currency',
          },
          {
            field: 'ParentOrderID',
            type: 'id',
          },
          {
            field: 'RID',
            type: 'text',
          },
          {
            field: 'ExpectedFillPrice',
            type: 'price',
            params: { assetField: 'Symbol' },
          },
          {
            field: 'ExpectedFillQty',
            type: 'size',
            params: { currencyField: 'Currency' },
          },
          {
            field: 'MarketAccountID',
            type: 'text',
          },
          {
            field: 'Text',
            type: 'text',
          },
        ] satisfies (false | ColumnDef<MarketOrder>)[]
      ).map(c => [getAgGridColId(c), { ...c, hide: true }])
    );
  }, []);
  const columnDefinitions = useMemo(() => {
    return new Map(
      (
        [
          ...defaultVisibleColumns.values(),
          ...defaultHiddenColumns.values(),
        ] satisfies ColumnDef<MarketOrder>[] as Column[]
      ).map(c => [getAgGridColId(c), c])
    );
  }, [defaultVisibleColumns, defaultHiddenColumns]);
  return useDefaultColumns(defaultColumns, columnDefinitions);
}
