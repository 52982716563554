import type { IAggFuncParams } from 'ag-grid-community';
import type Big from 'big.js';

export type SizedAvgValue =
  | {
      // good workable value
      size: Big;
      sizeCurrency: string;
      mark: Big;
      markCurrency: string;
      aggregate: true;
    }
  | {
      // bad value
      size: null;
      sizeCurrency: null;
      mark: null;
      markCurrency: null;
      aggregate: false;
    }
  | undefined;

const EXIT_AGGREGATE: SizedAvgValue = {
  size: null,
  sizeCurrency: null,
  mark: null,
  markCurrency: null,
  aggregate: false,
};

/**
 * The sizedAvgAggFunc is designed to be used together with the sizedAvg column.
 *
 * This function will aggregate your averages given a size value and mark value for each row. The aggregate
 * is done as long as the sizeCurrency and markCurrency are always matching.
 */
export function sizedAvgAggFunc({ values }: IAggFuncParams<unknown, SizedAvgValue>) {
  return values.reduce((totals, value) => {
    // If value is undefined, return totals.
    // We allow to continue aggregation if a value is just undefined (as opposed to explicitly bad (aggregate: false))
    if (!value) {
      return totals;
    }

    // If the value we are trying to reduce is conveying that it cannot be aggregated, we exit the aggregation immediately
    if (!value.aggregate) {
      return EXIT_AGGREGATE;
    }

    // If we havent instantiated the reducer yet, instantiate.
    // value here can be undefined, which is fine.
    if (!totals) {
      return value;
    }

    // If we find a mismatch in currency we immediately exit the aggregation
    if (value.sizeCurrency !== totals.sizeCurrency || value.markCurrency !== totals.markCurrency) {
      return EXIT_AGGREGATE;
    }

    // Logically if the size of the value is 0, then we're not gonna be changing the avg in any way, so we can just continue with last totals
    if (value.size.eq(0)) {
      return totals;
    }

    const currentValue = totals.size.times(totals.mark);
    const incomingValue = value.size.times(value.mark);
    const combinedValue = currentValue.plus(incomingValue);
    const combinedSize = totals.size.plus(value.size);
    const combinedMark = combinedValue.div(combinedSize);

    return {
      size: combinedSize,
      sizeCurrency: totals.sizeCurrency,
      mark: combinedMark,
      markCurrency: totals.markCurrency,
      aggregate: true,
    };
  }, undefined as SizedAvgValue);
}
