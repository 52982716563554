import { useTheme, type DefaultTheme } from 'styled-components';
import { CustomerBalanceTransactionStatusEnum, TradeStatusEnum } from '../../types/types';
import { Icon, IconName } from '../Icons';
import { IndicatorDotVariants, IndicatorDotWrapper } from '../IndicatorDot';
import type { TimelineItemVariant } from '../Timeline';
import { Wrapper } from './styles';

type TradeStatusProps = {
  tradeStatus: TradeStatusEnum | undefined;
  align?: 'left' | 'right';
  iconPlacement?: 'left' | 'right';
  text?: string;
  theme: DefaultTheme;
};

export const getTradeStatusIconVariant = (tradeStatus: TradeStatusEnum | undefined): TimelineItemVariant => {
  switch (tradeStatus) {
    case TradeStatusEnum.Confirmed:
      return 'positive';
    default:
      return 'primary';
  }
};

export const getTransactionHistoryIconVariant = (
  transactionStatus: CustomerBalanceTransactionStatusEnum | undefined
): TimelineItemVariant => {
  switch (transactionStatus) {
    case CustomerBalanceTransactionStatusEnum.Completed:
      return 'positive';
    case CustomerBalanceTransactionStatusEnum.Rejected:
      return 'warning';
    default:
      return 'primary';
  }
};

export const getTradeStatusIconName = (tradeStatus: TradeStatusEnum | undefined): IconName => {
  switch (tradeStatus) {
    case TradeStatusEnum.Pending:
      return IconName.Clock;
    case TradeStatusEnum.Confirmed:
      return IconName.CheckCircleSolid;
    case TradeStatusEnum.Canceled:
      return IconName.CloseCircleSolid;
    default:
      return IconName.Circle;
  }
};

const TradeStatusIcon = ({ tradeStatus, ...props }) => {
  const defaultTheme = useTheme();
  const theme = props.theme ?? defaultTheme;
  const { colorTextPositive, colorTextPrimary } = theme;
  const icon = getTradeStatusIconName(tradeStatus);
  switch (tradeStatus) {
    case TradeStatusEnum.Pending:
      return <Icon icon={icon} color={colorTextPrimary} {...props} />;
    case TradeStatusEnum.Confirmed:
      return <Icon icon={icon} color={colorTextPositive} {...props} />;
    case TradeStatusEnum.Canceled:
      return <Icon icon={icon} color={colorTextPrimary} {...props} />;
    default:
      return <Icon icon={icon} color={colorTextPrimary} {...props} />;
  }
};

export function TradeStatus({ tradeStatus, theme, align = 'right', text, ...props }: TradeStatusProps) {
  return (
    <Wrapper iconPlacement="left" align={align} title={text} theme={theme} {...props}>
      {tradeStatus}
      <IndicatorDotWrapper show={!!text} theme={theme} variant={IndicatorDotVariants.Warning}>
        <TradeStatusIcon tradeStatus={tradeStatus} theme={theme} size="fontSizeLarge" />
      </IndicatorDotWrapper>
    </Wrapper>
  );
}
