import { SubAccountReconCheckpointEvalStatusEnum } from '../../types';
import { HStack } from '../Core';
import { Icon, IconName, type IconProps } from '../Icons';
import { Text } from '../Text';

const StatusIcon = ({
  status,
  ...iconProps
}: { status: SubAccountReconCheckpointEvalStatusEnum } & Omit<IconProps, 'icon' | 'color'>) => {
  switch (status) {
    case SubAccountReconCheckpointEvalStatusEnum.Done:
      return <Icon icon={IconName.CheckCircleSolid} color="colorTextPositive" {...iconProps} />;
    case SubAccountReconCheckpointEvalStatusEnum.Pending:
    case SubAccountReconCheckpointEvalStatusEnum.InProgress:
      return <Icon icon={IconName.Clock} color="colors.blue.lighten" {...iconProps} />;
    default:
      // dont show any icon if we dont know what it is
      return null;
  }
};

export const ReconCheckpointEvalStatus = ({
  status,
  textColor,
}: {
  status: SubAccountReconCheckpointEvalStatusEnum;
  textColor?: string;
}) => {
  const statusText = RECON_CHECKPOINT_EVAL_STATUS_TEXT_MAPPING[status];

  return (
    <HStack gap="spacingSmall">
      <StatusIcon status={status} />
      <Text color={textColor}>{statusText ?? status}</Text>
    </HStack>
  );
};

export const RECON_CHECKPOINT_EVAL_STATUS_TEXT_MAPPING: { [key in SubAccountReconCheckpointEvalStatusEnum]: string } = {
  Done: 'Done',
  Pending: 'Pending',
  InProgress: 'In Progress',
};
