import type { ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { get, isFunction } from 'lodash';
import { getPricingReference, toBig } from '../../../utils';
import { AgGridPrice } from '../../AgGrid';
import { priceAggFunc, type PriceValue } from '../aggFuncs/priceAggFunc';
import { baseColumn } from './baseColumn';
import type { PriceParams } from './price.types';
import type { ColDefFactory, Column } from './types';
import { numericColumnComparator } from './utils';

export const price: ColDefFactory<Column<PriceParams>> = column => ({
  width: 150,
  minWidth: 70,
  maxWidth: 180,
  ...baseColumn(column),
  type: 'numericColumn',
  cellEditor: 'input',

  cellRenderer: ({ value, data, colDef }: ICellRendererParams<unknown, PriceValue>) => {
    return <AgGridPrice value={value} data={data} colDef={colDef} {...column.params} />;
  },
  comparator: (a: PriceValue, b: PriceValue) => {
    return numericColumnComparator(a?.price, b?.price);
  },
  headerClass: () => {
    const columnGroupClass = column.columnGroup ? 'ag-custom-column-group' : '';
    return column.params?.align !== 'left' ? `ag-right-aligned-header ${columnGroupClass}` : `${columnGroupClass}`;
  },
  cellClass: () => {
    return column.params?.align !== 'left' ? 'ag-right-aligned-cell' : '';
  },
  // There is no official type provided for this callback I could find -- so typing the parameters of this callback manually for now.
  valueParser: ({ oldValue, newValue }: { oldValue: PriceValue; newValue: string | undefined }): PriceValue => {
    try {
      const newValueBig = toBig(newValue);
      if (!newValueBig) {
        return undefined;
      }

      return {
        asset: oldValue?.asset,
        quoteCurrency: oldValue?.quoteCurrency,
        aggregate: true,
        price: newValueBig,
      };
    } catch (e) {
      return oldValue;
    }
  },
  valueGetter: ({ data, context }: ValueGetterParams<unknown>): PriceValue => {
    if (!data) {
      return undefined;
    }

    const priceField = column.field;
    const price: string | undefined = priceField ? get(data, priceField) : undefined;

    // Sometimes a price is relative (a reference price) and not absolute!
    let isReferencePrice = column.params?.isReferencePrice ?? false;
    if (isFunction(column.params?.isReferencePrice)) {
      isReferencePrice = column.params.isReferencePrice(data);
    }
    if (isReferencePrice) {
      const referencePrice = getPricingReference(data, priceField);
      return {
        price: toBig(referencePrice?.value),
        quoteCurrency: referencePrice?.currency,
        aggregate: false,
        asset: undefined,
      };
    }

    // currency or security
    let quoteCurrency: string | undefined = undefined;
    let asset: string | undefined = undefined;

    const { asset: inputAsset, assetField, quoteCurrencyField } = column.params ?? {};
    const resolvedAsset = inputAsset ?? (assetField ? get(data, assetField) : undefined);

    const maybeSecurity = resolvedAsset ? context.current.securitiesBySymbol?.get(resolvedAsset) : undefined;
    const maybeCurrency = resolvedAsset ? context.current.currenciesBySymbol?.get(resolvedAsset) : undefined;
    const maybeQuoteCurrency = quoteCurrencyField ? get(data, quoteCurrencyField) : undefined;

    if (maybeSecurity) {
      asset = maybeSecurity.Symbol;
      quoteCurrency = maybeSecurity.QuoteCurrency;
    } else if (maybeCurrency) {
      asset = maybeCurrency?.Symbol;
    }

    if (maybeQuoteCurrency) {
      quoteCurrency = maybeQuoteCurrency;
    }

    return {
      price: toBig(price),
      quoteCurrency,
      asset,
      aggregate: true,
    };
  },
  // used for exports since we have implemented a cell renderer of our own
  valueFormatter: ({ value }: ValueFormatterParams<unknown, PriceValue>) => {
    if (!value || !value.price) {
      return '';
    }

    return value.price.toFixed();
  },
  aggFunc: column.aggregate ? priceAggFunc : undefined,
});
