import { createContext, useContext } from 'react';

export const EndpointsContext = createContext<EndpointsContextProps | undefined>(undefined);
EndpointsContext.displayName = 'EndpointsContext';

export function useEndpointsContext() {
  const context = useContext(EndpointsContext);
  if (context === undefined) {
    throw new Error('Missing EndpointsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export interface EndpointsContextProps {
  apiEndpoint: string;
  wsEndpoint: string;
  orgApiEndpoint?: string;
}
