import styled, { keyframes } from 'styled-components';

const drawCircle = keyframes`
  from {
    stroke-dasharray: 0,112;
  }
  to {
    stroke-dasharray: 112,112;
  }
`;

const drawLine = keyframes`
  0% {
    opacity: 0;
  }
  1% {
    opacity: 1;
    stroke-dasharray: 0,22;
  }
  100% {
    opacity: 1;
    stroke-dasharray: 22,22;
  }
`;

export const Circle = styled.circle`
  stroke: ${({ theme }) => theme.colorTextAttention};
  fill: none;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-dasharray: 112, 112;
  transform: rotate(-90deg);
  transform-origin: center center;
  animation: ${drawCircle} 100ms linear;
`;

export const Line = styled.line<{ delay: number }>`
  stroke: ${({ theme }) => theme.colorTextAttention};
  fill: none;
  stroke-width: 4;
  stroke-linecap: round;
  stroke-dasharray: 0, 22;
  opacity: 0;
  animation: ${drawLine} 100ms ${({ delay }) => delay}ms linear;
  animation-fill-mode: forwards;
`;
