import styled from 'styled-components';
import { HStack } from '../Core';

export const Wrapper = styled(HStack)`
  vertical-align: middle;
`;

export const Expiration = styled.span`
  color: ${({ theme }) => theme.colors.blue.lighten};
`;
