import {
  Dialog,
  VStack,
  isTalosWLDemoEnv,
  selectIsFormOpen,
  useDisclosure,
  useDrawer,
  useGlobalDialog,
  useSecuritiesContext,
  useWLLayoutType,
  useWLOrderFormSelector,
  useWLOrgConfigContext,
  useWLThemeType,
} from '@talos/kyoko';
import { useMemo, type ReactNode } from 'react';
import { useTheme } from 'styled-components';
import { NavigationSidebar } from '../../components/NavigationSidebar';
import { NoSecuritiesNotificationWrapper } from '../../components/RestrictedAccess';
import { useCurrentNavigationItem } from '../../hooks/useCurrentNavigationItem';
import { NavigationTypes, tradingLayouts } from '../../layouts/layout';
import type { LayoutComponentPart, LayoutPart } from '../../layouts/types';
import { MarketDataCardsProvider } from '../../providers/MarketDataCardsContextProvider';
import { DemoSettingsDialog } from '../DemoSettingsDialog';
import { ErrorBoundary } from '../ErrorBoundary';
import { Part } from '../Layout/Part';
import { SupportModal } from '../SupportModal';
import { Header } from './Header';
import { NavigationDrawer } from './NavigationDrawer';
import { NavigationHeader } from './NavigationHeader';
import { LayoutWrapper, PartsWrapper } from './styles';
import { useLayoutComponent } from './useLayoutComponent';
import { useResizableLayout } from './useResizableLayout';

interface LayoutPartComponentProps {
  formsOpen: boolean;
  handleMouseDown: (e: React.MouseEvent<Element, MouseEvent> | React.TouchEvent<Element>, part: LayoutPart) => void;
  isLefthandFormCollapsible?: boolean;
  isRighthandFormCollapsible?: boolean;
  mouseDown: boolean;
  part: LayoutComponentPart;
}

const LayoutPartComponent = ({
  formsOpen,
  handleMouseDown,
  isLefthandFormCollapsible,
  isRighthandFormCollapsible,
  mouseDown,
  part,
}: LayoutPartComponentProps) => {
  return ((isRighthandFormCollapsible || isLefthandFormCollapsible) && formsOpen) ||
    (!isRighthandFormCollapsible && !isLefthandFormCollapsible) ||
    !part.isOrderForms ? (
    <Part
      {...part}
      resizing={mouseDown}
      animate={(isRighthandFormCollapsible || isLefthandFormCollapsible) && part.isOrderForms}
      collapsed={(isRighthandFormCollapsible || isLefthandFormCollapsible) && part.isOrderForms && !formsOpen}
      onMouseDown={handleMouseDown}
    >
      {part.component as ReactNode}
    </Part>
  ) : null;
};

export const Layout = () => {
  const { config } = useWLOrgConfigContext();
  const { themeType, setThemeType } = useWLThemeType();
  const { layoutType } = useWLLayoutType();
  const { securitiesList } = useSecuritiesContext();

  const formsOpen = useWLOrderFormSelector(selectIsFormOpen);

  const layoutComponent = useLayoutComponent(layoutType);
  const theme = useTheme();

  const { partsWrapperRef, columns, rows, mouseDown, handleMouseDown } = useResizableLayout(
    layoutComponent,
    theme.spacingLayout
  );

  const drawer = useDrawer({
    width: 200,
    placement: 'left',
    closeOnClickOutside: true,
    closeOnEscape: true,
  });

  const hasSidebar = !config.navigationType || config.navigationType === NavigationTypes.Sidebar;
  const hasDrawer = config.navigationType === NavigationTypes.SidebarHeaderToggle;
  const hasNavigatioHeader =
    config.navigationType === NavigationTypes.Header ||
    config.navigationType === NavigationTypes.CompactHeader ||
    config.navigationType === NavigationTypes.SidebarHeaderToggle;

  const modalState = useDisclosure();
  const demoSettingsDialogState = useDisclosure();

  const showLocaleSelector = isTalosWLDemoEnv;

  const navItem = useCurrentNavigationItem();
  const filteredColumns = useMemo(
    () =>
      config.orderFormToggle &&
      tradingLayouts.includes(navItem?.layoutComponent ?? layoutType) &&
      !layoutComponent.showOrderFormToggle
        ? columns.split(' ').slice(0, -1).join(' ')
        : columns,
    [config, columns, layoutComponent, navItem, layoutType]
  );

  const { content, ...dialog } = useGlobalDialog();

  const isRighthandFormCollapsible = layoutComponent.showOrderFormToggle === 'right' || config.orderFormToggle;
  const isLefthandFormCollapsible = layoutComponent.showOrderFormToggle === 'left';

  return (
    <>
      <MarketDataCardsProvider>
        <LayoutWrapper flexDirection={hasNavigatioHeader ? 'column' : 'row'}>
          <ErrorBoundary minimal={true}>
            <Dialog {...dialog}>{content}</Dialog>

            <SupportModal {...modalState} />
            {isTalosWLDemoEnv && (
              <DemoSettingsDialog
                themeType={themeType ?? theme.type}
                onChangeThemeType={setThemeType}
                showClose={true}
                showCancel={false}
                title="Demo Settings"
                {...demoSettingsDialogState}
              />
            )}
            {hasSidebar && (
              <NavigationSidebar
                isTalosWLDemoEnv={isTalosWLDemoEnv}
                openSupportModal={modalState.open}
                openDemoModal={demoSettingsDialogState.open}
                themeType={themeType ?? theme.type}
                onChangeThemeType={setThemeType}
              />
            )}
            {hasDrawer && (
              <NavigationDrawer
                isTalosWLDemoEnv={isTalosWLDemoEnv}
                openSupportModal={modalState.open}
                openDemoModal={demoSettingsDialogState.open}
                themeType={themeType ?? theme.type}
                onChangeThemeType={setThemeType}
                {...drawer}
              />
            )}
            {hasNavigatioHeader && (
              <NavigationHeader
                isTalosWLDemoEnv={isTalosWLDemoEnv}
                openDrawer={drawer.open}
                openDemoModal={demoSettingsDialogState.open}
                openSupportModal={modalState.open}
                drawerOpen={drawer.isOpen}
                compact={config.navigationType === NavigationTypes.CompactHeader}
                sidebarToggle={config.navigationType === NavigationTypes.SidebarHeaderToggle}
                themeType={themeType ?? theme.type}
                onChangeThemeType={setThemeType}
                maxWidth={layoutComponent.maxWidth}
                showSymbolSelector={layoutComponent.headerSymbolSelector !== false}
              />
            )}
            <VStack flex="1" p="spacingLayout" pt={hasSidebar ? 0 : undefined} alignItems="stretch" overflow="hidden">
              {/* If there are securities configured, show the no securities notification */}
              {securitiesList.length === 0 ? (
                <NoSecuritiesNotificationWrapper />
              ) : (
                <>
                  {hasSidebar && (
                    <Header
                      isTalosWLDemoEnv={isTalosWLDemoEnv}
                      showLocaleSelector={showLocaleSelector}
                      maxWidth={layoutComponent.maxWidth}
                      showSymbolSelector={layoutComponent.headerSymbolSelector !== false}
                    />
                  )}
                  <PartsWrapper
                    ref={partsWrapperRef}
                    columns={filteredColumns}
                    rows={rows}
                    maxWidth={layoutComponent.maxWidth}
                  >
                    {layoutComponent.parts.map((part, index) => (
                      <LayoutPartComponent
                        key={index}
                        formsOpen={formsOpen}
                        handleMouseDown={handleMouseDown}
                        isLefthandFormCollapsible={isLefthandFormCollapsible}
                        isRighthandFormCollapsible={isRighthandFormCollapsible}
                        mouseDown={mouseDown}
                        part={part}
                      />
                    ))}
                  </PartsWrapper>
                </>
              )}
            </VStack>
          </ErrorBoundary>
        </LayoutWrapper>
      </MarketDataCardsProvider>
    </>
  );
};
