import { cloneDeep, merge } from 'lodash';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { LightTheme, mapLightTheme } from './LightTheme';

const customcolors: Partial<Colors> = {
  primary: {
    mute: setAlpha(0.1, '#1C5184'),
    dim: setAlpha(0.4, '#1C5184'),
    default: '#1C5184',
    lighten: '#2365A5',
  },

  green: {
    mute: setAlpha(0.1, '#1A8440'),
    dim: setAlpha(0.4, '#1A8440'),
    default: '#1A8440',
    lighten: '#29CE64',
  },
  red: {
    mute: setAlpha(0.1, '#C83636'),
    dim: setAlpha(0.4, '#C83636'),
    default: '#C83636',
    lighten: '#FA4444',
  },
  blue: {
    mute: setAlpha(0.1, '#2365A5'),
    dim: setAlpha(0.4, '#2365A5'),
    default: '#2365A5',
    lighten: '#3085D8',
  },
  yellow: {
    mute: setAlpha(0.1, '#CC8907'),
    dim: setAlpha(0.4, '#CC8907'),
    default: '#CC8907',
    lighten: '#FFAB09',
  },
};

const colors: Colors = merge(cloneDeep(LightTheme.colors), customcolors);

export const LightCFTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightCF',
});
