import {
  EMPTY_OBJECT,
  InlineFormattedNumber,
  isCounterCurrency,
  OrderFormSides,
  OrdTypeEnum,
  selectForm,
  selectLimitPrice,
  useCurrency,
  useEstimatedAmount,
  useEstimatedTotal,
  useIntl,
  useWLHomeCurrency,
  useWLOrderFormSelector,
  VStack,
} from '@talos/kyoko';
import type { BigSource } from 'big.js';
import { useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { useOrderSummaryResponse } from './hooks';
import { OrderSummaryLine } from './OrderSummaryLine';

interface OrderSummaryProps {
  bidPrice?: string;
  offerPrice?: string;
}

const messages = defineMessages({
  quantity: {
    id: 'OrderForms.OrderSummary.quantity',
    defaultMessage: 'Quantity',
  },
  amount: {
    id: 'OrderForms.OrderSummary.amount',
    defaultMessage: 'Amount',
  },
  limitPrice: {
    id: 'OrderForms.OrderSummary.limitPrice',
    defaultMessage: 'Limit Price',
  },
  estimatedTotal: {
    id: 'OrderForms.OrderSummary.estimatedTotal',
    defaultMessage: 'Estimated Total',
  },
  estimatedFees: {
    id: 'OrderForms.OrderSummary.estimatedFees',
    defaultMessage: 'Estimated Fees',
  },
});

export const OrderSummary = ({ bidPrice, offerPrice }: OrderSummaryProps) => {
  const { formatMessage } = useIntl();
  const { homeCurrency } = useWLHomeCurrency();
  const homeCurrencyInfo = useCurrency(homeCurrency);

  const form = useWLOrderFormSelector(selectForm);
  const maybeLimitPrice = useWLOrderFormSelector(selectLimitPrice);

  // Prepare response props for server summary
  const orderSummaryResponseProps = useMemo(
    () => ({
      MarketAccount: form.marketAccountField.value?.SourceAccountID,
      Symbol: form.symbolField.value?.Symbol,
      Currency: form.orderCurrencyField.value,
      OrderQty: form.quantityField.value,
      OrdType: form.strategyField.value?.OrdType,
      Side: form.sideField.value,
      Price: maybeLimitPrice?.value,
      EquivalentCurrency: homeCurrency,
    }),
    [
      form.marketAccountField.value,
      form.orderCurrencyField.value,
      form.quantityField.value,
      form.sideField.value,
      form.strategyField.value,
      form.symbolField.value,
      homeCurrency,
      maybeLimitPrice?.value,
    ]
  );

  // Fetch order summary from server
  const orderSummary = useOrderSummaryResponse(orderSummaryResponseProps);

  // Calculate estimated values if no server response
  const estimatedAmount = useEstimatedAmount({
    LimitPrice: maybeLimitPrice?.isVisible ? maybeLimitPrice.value : undefined,
    OrderQty: form.quantityField.value,
    Currency: form.orderCurrencyField.value,
    security: form.symbolField.value,
    marketRate:
      form.strategyField.value?.OrdType === OrdTypeEnum.Market
        ? form.sideField.value === OrderFormSides.Buy
          ? offerPrice
          : bidPrice
        : undefined,
  });

  const estimatedTotal = useEstimatedTotal({
    security: form.symbolField.value,
    quantity: form.quantityField.value,
    quantityCurrency: form.orderCurrencyField.value,
    price: maybeLimitPrice?.isVisible ? maybeLimitPrice.value : undefined,
  });

  // Determine increments and currencies
  const security = form.symbolField.value;
  const Currency = form.orderCurrencyField.value;
  const Strategy = form.strategyField.value?.Name;
  const { BaseCurrency, QuoteCurrency, DefaultPriceIncrement, DefaultSizeIncrement, MinAmtIncrement } =
    security ?? EMPTY_OBJECT;

  const selectedStrategy = form.strategyField.availableItems?.find(s => s.Name === Strategy);
  const usingCounterCurrency = Currency ? isCounterCurrency(Currency, security) : false;
  const counterCurrency = usingCounterCurrency ? BaseCurrency : QuoteCurrency;
  let qtyIncrement = DefaultSizeIncrement;
  if (usingCounterCurrency) {
    qtyIncrement = MinAmtIncrement ? MinAmtIncrement : DefaultPriceIncrement;
  }
  const amtIncrement = usingCounterCurrency ? DefaultSizeIncrement : DefaultPriceIncrement;

  const showLimitPrice = !!selectedStrategy?.Parameters?.some(param => param.Name === 'LimitPrice');

  // Populate values based on server response or estimates
  const values: OrderSummaryValues = useMemo(() => {
    // Determine whether we have a server response or need to use estimates
    const hasOrderSummary = orderSummary != null;
    const hasEstimateResponses = !hasOrderSummary || orderSummary?.OrdType === OrdTypeEnum.Market;

    // Quantity values
    const qtyNumber = hasOrderSummary ? orderSummary.OrderQty : form.quantityField.value;
    const qtyCurrency = hasOrderSummary ? orderSummary.Currency : Currency;

    // Amount values
    const amountNumber = hasOrderSummary ? orderSummary.Amount : estimatedAmount || undefined;
    const amountCurrency = hasOrderSummary ? orderSummary.AmountCurrency : counterCurrency;

    // Limit Price values
    const limitPriceNumber = maybeLimitPrice?.value;
    const limitPriceCurrency = maybeLimitPrice?.value ? QuoteCurrency : undefined;

    // Estimates Fee values
    const estimatedFeesNumber = hasOrderSummary ? orderSummary.Fee : undefined;
    const estimatedFeesCurrency = hasOrderSummary ? orderSummary.FeeCurrency : undefined;

    // Estimated Total values
    const estimatedTotalNumber = hasOrderSummary ? orderSummary.EstimatedTotal : estimatedTotal || undefined;
    const estimatedTotalCurrency = hasOrderSummary
      ? orderSummary.EquivalentCurrency
      : homeCurrencyInfo?.Symbol ?? homeCurrency;
    const estimatedTotalIncrement = hasOrderSummary ? undefined : homeCurrencyInfo?.DefaultIncrement ?? '0.0001';

    return {
      qty: {
        number: qtyNumber,
        currency: qtyCurrency,
        increment: qtyIncrement,
      },
      amount: {
        number: amountNumber,
        currency: amountCurrency,
        increment: amtIncrement,
        estimate: hasEstimateResponses,
      },
      limitPrice: showLimitPrice
        ? {
            number: limitPriceNumber,
            currency: limitPriceCurrency,
            increment: DefaultPriceIncrement,
          }
        : undefined,
      estimatedFees: {
        number: estimatedFeesNumber,
        currency: estimatedFeesCurrency,
        estimate: hasEstimateResponses,
      },
      estimatedTotal: {
        number: estimatedTotalNumber,
        currency: estimatedTotalCurrency,
        increment: estimatedTotalIncrement,
        estimate: hasEstimateResponses,
      },
    };
  }, [
    orderSummary,
    form.quantityField.value,
    Currency,
    qtyIncrement,
    amtIncrement,
    estimatedAmount,
    counterCurrency,
    maybeLimitPrice?.value,
    QuoteCurrency,
    DefaultPriceIncrement,
    estimatedTotal,
    homeCurrencyInfo?.Symbol,
    homeCurrency,
    homeCurrencyInfo?.DefaultIncrement,
    showLimitPrice,
  ]);

  return <OrderSummaryComponent values={values} formatMessage={formatMessage} />;
};

interface FormattedNumberProps {
  number?: BigSource;
  currency?: string;
  increment?: string;
  estimate?: boolean;
}

interface OrderSummaryValues {
  qty?: FormattedNumberProps;
  amount?: FormattedNumberProps;
  limitPrice?: FormattedNumberProps;
  estimatedTotal?: FormattedNumberProps;
  estimatedFees?: FormattedNumberProps;
}

interface OrderSummaryComponentProps {
  values: OrderSummaryValues;
  formatMessage: (message: { id: string; defaultMessage: string }) => string;
}

const OrderSummaryComponent = ({ values, formatMessage }: OrderSummaryComponentProps) => {
  return (
    <VStack gap="spacingDefault" alignItems="space-between" fontSize="fontSizeSmall">
      <OrderSummaryLine
        title={formatMessage(messages.quantity)}
        value={
          values.qty?.number && (
            <InlineFormattedNumber
              number={values.qty.number}
              currency={values.qty.currency}
              increment={values.qty.increment}
              round
              align="right"
            />
          )
        }
        data-testid="quantity-line"
      />
      <OrderSummaryLine
        title={formatMessage(messages.amount)}
        value={
          values.amount?.number && (
            <InlineFormattedNumber
              number={values.amount.number}
              currency={values.amount.currency}
              increment={values.amount.increment}
              estimate={values.amount.estimate}
              round
              align="right"
            />
          )
        }
        data-testid="amount-line"
      />
      {values.limitPrice?.number && (
        <OrderSummaryLine
          title={formatMessage(messages.limitPrice)}
          value={
            values.limitPrice.number && (
              <InlineFormattedNumber
                number={values.limitPrice.number}
                currency={values.limitPrice.currency}
                increment={values.limitPrice.increment}
                round
                align="right"
              />
            )
          }
          data-testid="limit-price-line"
        />
      )}
      {values.estimatedFees?.number && (
        <OrderSummaryLine
          title={formatMessage(messages.estimatedFees)}
          value={
            <InlineFormattedNumber
              number={values.estimatedFees.number}
              currency={values.estimatedFees.currency}
              increment={values.estimatedFees.increment}
              estimate={values.estimatedFees.estimate}
              round
              align="right"
            />
          }
          data-testid="estimated-fees"
        />
      )}
      <OrderSummaryLine
        title={formatMessage(messages.estimatedTotal)}
        value={
          values.estimatedTotal?.number && (
            <InlineFormattedNumber
              number={values.estimatedTotal.number}
              currency={values.estimatedTotal.currency}
              increment={values.estimatedTotal.increment}
              estimate={values.estimatedTotal.estimate}
              round
              align="right"
            />
          )
        }
        data-testid="estimated-total"
      />
    </VStack>
  );
};
