import {
  EMPTY_ARRAY,
  ProtectedCustomerUserConfigTradingLayoutArrangements,
  getIntlKey,
  type CustomerUserConfigTradingLayoutArrangements,
} from '@talos/kyoko';
import { useMemo } from 'react';
import type { LayoutComponentArrangement, MultiLayoutComponent } from '../../../layouts/types';
import { useCurrentLayoutComponent } from '../useCurrentLayoutComponent';
import { EditLayoutButtonInner } from './EditLayoutButtonInner';

export function EditLayoutButton() {
  const layoutComponent = useCurrentLayoutComponent();

  const items = useMemo<LayoutComponentArrangement[]>(
    () =>
      'arrangements' in layoutComponent
        ? // Get all keys from the list of available arrangements, which can be either Global or Protected types
          (
            Object.keys((layoutComponent as MultiLayoutComponent).arrangements) as Array<
              keyof typeof CustomerUserConfigTradingLayoutArrangements
            >
          )
            // Filter the list of keys down to only the items that are *not* Protected.
            // On the Principal side of things, we have a feature flag to map Protected arrangements
            // to users/companies that can use them, but on the whitelabel side, we have no feature
            // flag support in general, so we simply strip out any and all Protected arrangement options.
            .filter(key => !ProtectedCustomerUserConfigTradingLayoutArrangements[key])
            .map(key => ({ ...(layoutComponent as MultiLayoutComponent).arrangements[key], intlKey: getIntlKey(key) }))
        : EMPTY_ARRAY,
    [layoutComponent]
  );

  return items.length > 1 ? <EditLayoutButtonInner items={items} /> : null;
}
