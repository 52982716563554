import { useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';
import { useTheme } from 'styled-components';
import { useMixpanel } from '../../contexts';
import { useDynamicCallback } from '../../hooks';
import { MixpanelEvent } from '../../tokens';
import { format, readableDay, type DateFnInput } from '../../utils';
import { Alert, AlertVariants } from '../Alert';
import { Button } from '../Button';
import { HStack } from '../Core';
import { FormControlSizes } from '../Form';
import { FormattedMessage } from '../Intl';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import type { UseWsBlotterTable, WebsocketRequest } from './useWsBlotterTable';

const messages = defineMessages({
  tooManyRecords: {
    defaultMessage: 'Too many records ({recordsReceived}), only showing results through {date}',
    id: 'BlotterTable.tooManyRecords',
  },
  loadMoreRecords: {
    defaultMessage: 'Load more records',
    id: 'BlotterTable.loadMoreRecords',
  },
  loadMoreRecordsDescription: {
    defaultMessage:
      'Raise the pagination limit. If done excessively this can cause performance issues with the application.',
    id: 'BlotterTable.loadMoreRecordsDescription',
  },
});

type WSBlotterTableMaxRecordsReachedWarningProps<TOutputType, TInputType = TOutputType> = UseWsBlotterTable<
  WebsocketRequest,
  TOutputType,
  TInputType
>['paginationLimit'] & {
  getTimestamp: (item: TOutputType) => string | undefined;
};

export function WSBlotterTableMaxRecordsReachedWarning<TRowType>({
  limitReached,
  lastRecord,
  recordsReceived,
  raiseLimit,
  getTimestamp,
}: WSBlotterTableMaxRecordsReachedWarningProps<TRowType>) {
  // We hold our own state here to enable dismissing of the limit reached alert.
  const [showLimitReached, setShowLimitReached] = useState(!!limitReached);

  useEffect(() => {
    setShowLimitReached(!!limitReached);
  }, [limitReached]);

  return (
    <MaxRecordsReachedWarning
      showLimitReached={showLimitReached}
      dismiss={() => setShowLimitReached(false)}
      earliestTimestamp={lastRecord ? getTimestamp(lastRecord) : undefined}
      recordsReceived={recordsReceived}
      onRaiseLimitClicked={raiseLimit}
    />
  );
}

interface MaxRecordsReachedWarningProps {
  showLimitReached: boolean;
  recordsReceived?: number;
  dismiss?: () => void;
  earliestTimestamp: DateFnInput;
  onRaiseLimitClicked?: () => void;
}

export function MaxRecordsReachedWarning({
  showLimitReached,
  dismiss,
  recordsReceived,
  earliestTimestamp,
  onRaiseLimitClicked,
}: MaxRecordsReachedWarningProps) {
  const theme = useTheme();
  const mixpanel = useMixpanel();

  const handleRaiseLimitClicked = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.RaisePaginationLimit);
    onRaiseLimitClicked?.();
  });

  const showRaiseLimitButton = onRaiseLimitClicked != null;
  const formattedRecordsReceived = format(recordsReceived);

  return (
    <>
      {showLimitReached && (
        <Alert
          variant={AlertVariants.Warning}
          dismissable={!!dismiss}
          onDismiss={dismiss}
          mx="spacingComfortable"
          my="spacingSmall"
          alignItems="center"
        >
          <HStack w="100%" justifyContent="flex-start" gap="spacingComfortable">
            <Text size={theme.fontSizeDefault} color={theme.colors.gray['100']}>
              <FormattedMessage
                {...messages.tooManyRecords}
                values={{ recordsReceived: formattedRecordsReceived, date: readableDay(earliestTimestamp, true) }}
              />
            </Text>
            {showRaiseLimitButton && (
              <Tooltip tooltip={<FormattedMessage {...messages.loadMoreRecordsDescription} />}>
                <Button onClick={handleRaiseLimitClicked} size={FormControlSizes.Small}>
                  <FormattedMessage {...messages.loadMoreRecords} />
                </Button>
              </Tooltip>
            )}
          </HStack>
        </Alert>
      )}
    </>
  );
}
