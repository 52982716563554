// Cypress Test Environment overrides, with mock typing based on needed Cypress functions
type CypressWindow = Window &
  typeof globalThis & {
    Cypress: {
      env(varString: string): unknown;
      env(varString: string, value?: unknown): void;
    };
  };

const CYPRESS_SUPPRESS_ROW_VIRT_ENV = 'CYPRESS_SUPPRESS_ROW_VIRT';
const isCypressTestEnv =
  import.meta.env.VITE_AVA_ENV === 'local' ||
  import.meta.env.VITE_AVA_ENV === 'test' ||
  import.meta.env.VITE_AVA_ENV === 'staging' ||
  import.meta.env.VITE_AVA_ENV === 'uat';

export function isCypressWindow(window: Window): window is CypressWindow {
  return isCypressTestEnv && (window as CypressWindow)?.Cypress != null;
}

/** For Cypress testing, we want to have specific grid overrides setup  */
export const cypressGridSettings = {
  isRowVirtSuppressed: () => isCypressWindow(window) && window.Cypress.env(CYPRESS_SUPPRESS_ROW_VIRT_ENV) === 'true',
  isColVirtSuppressed: () => isCypressWindow(window),
  toggleIsRowVirtSuppressed: (isRowSuppressed: boolean) => {
    if (isCypressWindow(window)) {
      window.Cypress.env(CYPRESS_SUPPRESS_ROW_VIRT_ENV, isRowSuppressed ? 'true' : 'false');
    }
  },
};
