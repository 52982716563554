import { compact, isArray, isEqual, keys } from 'lodash';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';
import { FilterClauseType, type FilterClause, type FilterableProperty, type UseFilterBuilderProps } from '../Filters';
import { removeEmptyFilters } from './useWsBlotterTable';

interface GenericFilter {
  [key: string]: string[];
}

export type GenericFilterFilterProps = {
  initialFilter?: GenericFilter;
  onFilterChanged?: (filter: GenericFilter) => void;
};

export const useGenericFilter = function useGenericFilter(
  filterableProperties: FilterableProperty<any>[],
  filterProps?: GenericFilterFilterProps
) {
  const [filter, setFilter] = useState<GenericFilter>(filterProps?.initialFilter ?? {});

  const changeFilter = useCallback(
    (action: SetStateAction<GenericFilter>) => {
      const priorFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;

      if (!isEqual(priorFilter, newFilter)) {
        if (filterProps?.onFilterChanged) {
          filterProps.onFilterChanged(newFilter);
        }
        setFilter(newFilter);
      }
    },
    [filter, filterProps]
  );

  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter = removeEmptyFilters<GenericFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => {
              const value = filterClausesByPropertyKey.get(key)?.selections;
              if (value) {
                return [key, value];
              } else {
                return [key, []];
              }
            })
          ) satisfies GenericFilter),
        });

        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      keys(filter).forEach(key => {
        clauses.push({
          key: key,
          type: FilterClauseType.INCLUSIVE,
          selections: (isArray(filter[key]) ? filter[key] : compact([filter[key]])) as string[],
        });
      });
    }

    return clauses;
  }, [filter]);

  const filterBuilderProps = useMemo(
    () =>
      ({
        initialFilterClauses,
        properties: filterableProperties,
        onFilterClausesChanged: handleFilterClausesChanged,
      } satisfies UseFilterBuilderProps),
    [initialFilterClauses, filterableProperties, handleFilterClausesChanged]
  );

  return {
    filter,
    filterBuilderProps,
  };
};
