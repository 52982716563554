import type { ICellRendererParams } from 'ag-grid-enterprise';
import { Checkbox } from '../Form';

export interface AgGridCheckboxProps extends ICellRendererParams {
  disabled?: boolean;
}

export const AgGridCheckbox = ({ value, setValue, disabled }: AgGridCheckboxProps) => {
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Checkbox checked={value} disabled={disabled} onChange={() => setValue?.(!value)} />
    </div>
  );
};
