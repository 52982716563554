import { useWLOrgConfigContext, useWLRoleAuth, type CustomerUserConfigNavigationItem } from '@talos/kyoko';
import { TOP_NAV_PATHS } from 'components/NavigationSidebar/Nav';
import { checkRequiredPermissions } from 'utils/layoutPermissions';

export function useNavigationItems(): CustomerUserConfigNavigationItem[] {
  const { isAuthorized } = useWLRoleAuth();
  const { config } = useWLOrgConfigContext();

  return (config.navigationItems ?? TOP_NAV_PATHS).filter(ni =>
    checkRequiredPermissions(ni.requiredPermissions, isAuthorized)
  );
}
