import { hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { LightTheme, lightColors, mapLightTheme } from './LightTheme';

const colors: Colors = {
  gray: {
    main: '#FFFFFF',
    '000': '#F1F1F1',
    '010': '#F8F8F8',
    '020': '#ececec',
    '030': hsla(240, 0, 0.91, 1),
    '040': hsla(240, 0, 0.85, 1),
    '050': hsla(240, 0, 0.81, 1),
    '060': hsla(240, 0, 0.77, 1),
    '070': hsla(240, 0, 0.73, 1),
    '080': '#201e2a',
    '090': '#031B23',
    '100': '#181428',
  },

  black: {
    default: '#000000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#FFFFFF',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.1)',
  },

  primary: {
    mute: setAlpha(0.1, '#1CF1A5'),
    dim: setAlpha(0.4, '#1CF1A5'),
    default: '#1CF1A5',
    lighten: '#1CF1A5',
  },

  green: {
    mute: setAlpha(0.1, '#2C8059'),
    dim: setAlpha(0.4, '#2C8059'),
    default: '#2C8059',
    lighten: '#1CF1A5',
  },

  red: {
    mute: setAlpha(0.1, '#BD5F4B'),
    dim: setAlpha(0.4, '#BD5F4B'),
    default: '#BD5F4B',
    lighten: '#FE7D62',
  },

  yellow: {
    mute: setAlpha(0.1, '#F3D900'),
    dim: setAlpha(0.4, '#F3D900'),
    default: '#F3D900',
    lighten: '#F3D900',
  },

  blue: {
    mute: setAlpha(0.1, '#306fb8'),
    dim: setAlpha(0.4, '#306fb8'),
    default: '#306fb8',
    lighten: '#4898F4',
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: lightColors.purple,
};

export const LightStablehouseTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightSH',

  fontFamily: "'Inter', sans-serif",
  fontUrl: 'https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',

  spacingLayout: 16,
  borderRadiusContent: 8,
  borderColorHeader: colors.gray['020'],
  boxShadowHeader: `0 2px 15px ${setAlpha(0.5, colors.gray['000'])}`,

  borderColorContent: colors.gray['020'],
  boxShadowContent: `0 2px 15px ${setAlpha(0.5, colors.gray['000'])}`,

  backgroundNegativeButtonActive: '#771400',

  backgroundDefaultButton: colors.gray.main,
  backgroundDefaultButtonHover: colors.gray.main,
  backgroundDefaultButtonFocus: colors.gray.main,
  backgroundDefaultButtonActive: colors.gray.main,

  borderColorDefaultButton: colors.gray['040'],
  borderColorDefaultButtonHover: colors.gray['040'],
  borderColorDefaultButtonFocus: colors.gray['040'],
  borderColorDefaultButtonActive: colors.gray['040'],
  borderWidthButton: 1,

  backgroundPriorityButton: colors.gray['010'],
  backgroundPriorityButtonHover: colors.gray['020'],
  backgroundPriorityButtonFocus: colors.gray['010'],
  backgroundPriorityButtonActive: colors.gray['010'],

  borderColorPriorityButton: colors.gray['040'],
  borderColorPriorityButtonHover: colors.gray['040'],
  borderColorPriorityButtonFocus: colors.gray['040'],
  borderColorPriorityButtonActive: colors.gray['040'],

  backgroundPrimaryButton: colors.gray['100'],
  backgroundPrimaryButtonHover: colors.gray['080'],
  backgroundPrimaryButtonFocus: colors.gray['080'],
  backgroundPrimaryButtonActive: colors.gray['100'],
  backgroundPrimaryButtonDisabled: colors.gray['050'],

  colorTextPrimaryButton: colors.gray['000'],
  colorTextPrimaryButtonHover: colors.gray['000'],
  colorTextPrimaryButtonFocus: colors.gray['000'],
  colorTextPrimaryButtonActive: colors.gray['000'],

  colorTextDefaultButton: colors.gray['100'],
  colorTextDefaultButtonHover: colors.gray['100'],
  colorTextDefaultButtonActive: colors.gray['100'],
  colorTextDefaultButtonDisabled: colors.gray['100'],
  colorTextDefaultButtonFocus: colors.gray['100'],

  backgroundPositiveButtonFocus: colors.green.default,

  backgroundNegativeButtonFocus: colors.red.default,

  borderColorInput: colors.gray['040'],
  borderColorInputFocus: colors.blue.lighten,
  backgroundInput: colors.gray.main,

  colorTextNegative: colors.red.lighten,
  colorTextPositive: colors.green.default,

  colorOrderStatusFilled: colors.green.default,

  backgroundVolumeLadderBidSpread: colors.primary.lighten,
  backgroundVolumeLadderOfferSpread: colors.primary.lighten,

  backgroundChartUpColor: colors.green.default,
  backgroundChartDownColor: colors.red.default,
  borderColorChartUpColor: colors.green.default,
  borderColorChartDownColor: colors.red.default,

  backgroundSelect: colors.gray.main,
  backgroundSelectHover: colors.gray.main,
  backgroundSelectFocus: colors.gray.main,
  backgroundSelectDisabled: colors.gray.main,
  backgroundSelectReadOnly: colors.gray.main,
  backgroundSelectInvalid: colors.gray.main,

  borderColorSelect: colors.gray['040'],
  borderColorSelectHover: LightTheme.borderColorInputHover,
  borderColorSelectFocus: colors.blue.lighten,
  borderColorSelectDisabled: LightTheme.borderColorInputDisabled,
  borderColorSelectReadOnly: LightTheme.borderColorInputReadOnly,
});
