import type { ReactNode } from 'react';
import styled from 'styled-components';
import { IconButton } from '../Button';
import type { BoxProps } from '../Core';
import { HStack } from '../Core';
import { FormControlSizes } from '../Form/types';
import { Icon, IconName } from '../Icons';
import { Text } from '../Text';

export interface PopperHeaderProps {
  icon?: IconName;
  iconColor?: string;
  title?: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
  /** If true, will disable the close button (X) in the header */
  closeDisabled?: boolean;
  onCloseClicked?: () => void;

  boxProps?: BoxProps;
}

/** This component exists to be used as a header component for all "popper" components -- modals, dropdowns, popovers, etc */
export function PopperHeader({
  icon,
  iconColor,
  title,
  prefix,
  suffix,
  onCloseClicked,
  closeDisabled,
  ...boxProps
}: PopperHeaderProps) {
  return (
    <HeaderHStack {...boxProps}>
      <HStack gap="spacingSmall" color="colorTextImportant">
        {icon && <Icon icon={icon} color={iconColor ?? 'colorTextSubtle'} />}
        {title}
        {prefix}
      </HStack>

      <HStack gap="spacingSmall">
        {suffix}
        {onCloseClicked && (
          <IconButton
            ghost
            color="colorTextSubtle"
            size={FormControlSizes.Small}
            icon={IconName.Close}
            onClick={onCloseClicked}
            disabled={closeDisabled}
            data-testid="close-dialog-button"
          />
        )}
      </HStack>
    </HeaderHStack>
  );
}

const HeaderHStack = styled(HStack)`
  gap: ${({ theme }) => theme.spacingComfortable}px;
  padding-left: ${({ theme }) => theme.spacingDefault}px;
  padding-right: ${({ theme }) => theme.spacingSmall}px;
  justify-content: space-between;
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  background: ${({ theme }) => theme.backgroundModalHeader};
  min-height: 32px;
  border-bottom: ${({ theme }) => `1px solid ${theme.backgroundDivider}`};
  border-top-right-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  border-top-left-radius: ${({ theme }) => theme.borderRadiusDefault}px;
`;

interface PathTitleProps {
  paths: string[];
}

/**
 * A component for rendering a path title of sorts. Taking in an array of paths,
 * will render something like Path1 > Path2 > Path3
 * The last part of the Path will be highlighted (color text-wise)
 */
export const PathTitle = ({ paths }: PathTitleProps) => {
  return (
    <HStack gap="spacingSmall">
      {paths.map((path, i) => {
        const isLast = i === paths.length - 1;
        return (
          <>
            <Text color={isLast ? 'colorTextImportant' : 'colorTextDefault'}>{path}</Text>
            {!isLast && <Icon icon={IconName.ChevronRight} size="fontSizeSmall" />}
          </>
        );
      })}
    </HStack>
  );
};
