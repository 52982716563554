import { useEffect } from 'react';
import { distinctUntilChanged, map } from 'rxjs';
import { useSparklineContext, type SparklineValues } from '../contexts';
import { useObservable } from './useObservable';

/**
 * Hook to subscribe to the latest sparkline data for a security.
 *
 * @param symbol Symbol name to subscribe to
 * @returns Observable of Sparkline data for the given symbol
 */
export function useSparklineData(symbol: string) {
  const { sparklinesBySymbol, registerSubscription, unregisterSubscription } = useSparklineContext();

  useEffect(() => {
    registerSubscription(symbol);
    return () => {
      unregisterSubscription(symbol);
    };
  }, [registerSubscription, symbol, unregisterSubscription]);

  return useObservable<SparklineValues | undefined>(
    () => sparklinesBySymbol.pipe(map((rates: Map<string, SparklineValues | undefined>) => rates.get(symbol))),
    [sparklinesBySymbol, symbol]
  );
}
/**
 * Hook to subscribe to the latest sparkline data for multiple securities.
 *
 * @param symbols Array of symbols to subscribe for
 * @returns Observable map of Symbol => Sparkline Data
 */
export function useSparklinesData(symbols: string[]) {
  const { sparklinesBySymbol, registerSubscriptions, unregisterSubscriptions } = useSparklineContext();

  useEffect(() => {
    registerSubscriptions(symbols);
    return () => {
      unregisterSubscriptions(symbols);
    };
  }, [registerSubscriptions, symbols, unregisterSubscriptions]);

  const sparklinesObservable = useObservable<Map<string, SparklineValues | undefined>>(
    () =>
      sparklinesBySymbol.pipe(
        map(rates => new Map(symbols.map(symbol => [symbol, rates.get(symbol)]))),
        distinctUntilChanged()
      ),
    [sparklinesBySymbol, symbols]
  );
  return sparklinesObservable;
}
