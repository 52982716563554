import { useTheme } from 'styled-components';
import { StepConnection } from './StepConnection';
import { StepIcon } from './StepIcon';
import { ProcessStepsWrapper, StepLabel, StepWrapper } from './styles';
import { ProcessStepSizes, ProcessStepState, type ProcessStep } from './types';

export const ProcessSteps = ({
  steps,
  size = ProcessStepSizes.default,
  showLabels = true,
  onClickStep,
}: ProcessStepsProps) => {
  const { colorTextImportant, colorTextSubtle } = useTheme();
  return (
    <ProcessStepsWrapper size={size}>
      {steps.map((step, index) => {
        const labelColor = step.state === ProcessStepState.Active ? colorTextImportant : colorTextSubtle;
        return (
          <StepWrapper
            showLabel={showLabels}
            key={`${step.label}_${index}`}
            disabled={step.disabled}
            size={size}
            onClick={() => !step.disabled && onClickStep && onClickStep(step, index)}
          >
            <StepIcon size={size} state={step.state} />
            {showLabels && (
              <StepLabel color={labelColor} size={size}>
                {step.label}
              </StepLabel>
            )}
            <StepConnection size={size} state={step.state} hidden={index === 0} />
          </StepWrapper>
        );
      })}
    </ProcessStepsWrapper>
  );
};

type ProcessStepsProps = {
  steps: ProcessStep[];
  size?: ProcessStepSizes;
  showLabels?: boolean;
  onClickStep?: (step: ProcessStep, index: number) => void;
};
