import type { IStrategy, IStrategy4860Parameters, OrdTypeEnum, PresenceEnum } from './types';

/**
 * Note the types in this file should really only be used for Orders in Principal and not Orders in White Label
 */
export interface OrderStrategy extends Omit<IStrategy, 'Parameters' | 'Revision' | 'Metadata'> {
  Parameters: Parameter[];
  InstrumentScope: 'Native' | 'Synthetic' | 'All';
}

export interface Parameter extends Omit<IStrategy4860Parameters, 'DefaultValue' | 'Metadata'> {
  DefaultValue: IStrategy4860Parameters['DefaultValue'] | undefined;
  Type: ParameterTypeEnum;
}

export enum ParameterTypeEnum {
  PriceOffset = 'PriceOffset',
  PriceProtection = 'PriceProtection',
  Qty = 'Qty',
  Date = 'Date',
  Duration = 'Duration',
  Interval = 'Interval',
  Percent = 'Percent',
  Price = 'Price',
  Bps = 'Bps',
  Aggregation = 'Aggregation',
  String = 'String',
  Enum = 'Enum',
  UnifiedLiquidity = 'UnifiedLiquidity',
  TargetParticipationRate = 'TargetParticipationRate',
  ReduceOnly = 'ReduceOnly',
  ReduceFirst = 'ReduceFirst',
  JSON = 'JSON',
}

// These "Like" interfaces are used in Kyoko components that use both Principal+Whitelabel Strategy Params
export interface ParameterLike {
  DisplayName: string;
  Name: string;
  Type: ParameterTypeEnum;
  Presence: PresenceEnum;
  Description: string;
  DefaultValue?: string;
  EnumValues?: EnumParameter[];
}

export interface EnumParameter {
  Index: number;
  Name: string;
  Description: string;
}

export interface StrategyLike {
  Name: string;
  DisplayName: string;
  Parameters: ParameterLike[];
  Group?: string;
  Description?: string;
  OrdType?: OrdTypeEnum;
}
