import type { UseComboboxStateChange } from 'downshift';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { FilterableSelectProperty } from '../../Filters/FilterBuilder/types';

function defaultOptionSorter<T = string>(getOptionLabel: (option: T) => string): (a: T, b: T) => -1 | 1 {
  return (a: T, b: T) => (getOptionLabel(a) < getOptionLabel(b) ? -1 : 1);
}
type UseMultiSelectSelectionManagerProps<T = string> = {
  property: FilterableSelectProperty<string, T>;
  selections: T[];
  onSelectionsChange: (newSelections: T[]) => void;
};
/**
 * Hook to handle common multi-select behavior to store in-process multi-select items from the dropdown, and
 * sort them to the top of the selection list when the dropdown is opened
 *
 * @template T - The data type of the selection items.
 *
 * @param {Property} params.property - The property object containing options and sorting logic.
 * @param {T[]} params.selections - The current selections.
 * @param {Function} params.onSelectionsChange - Callback function to handle selection changes.
 *
 * @returns {T[]} combinedCurrentSelections - The combined current selections including unconfirmed additions.
 * @returns {T[]} dropdownItems - The items to be displayed in the dropdown.
 * @returns {Function} isSelectionDisabled - Function to check if a selection is disabled.
 * @returns {Function} addUnconfirmedAdditions - Function to add unconfirmed additions to the selections.
 * @returns {Function} handleSelectionsChange - Function to handle changes in selections.
 * @returns {Function} handleOpenChange - Function to handle changes in the open state of the dropdown.
 */
export const useMultiSelectSelectionManager = <T = string,>({
  property,
  selections,
  onSelectionsChange,
}: UseMultiSelectSelectionManagerProps<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const { options, getOptionLabel, optionSorter: propertySorter } = property;

  const optionSorter = useMemo(() => {
    return propertySorter ?? defaultOptionSorter(getOptionLabel);
  }, [getOptionLabel, propertySorter]);

  // We remember what the latest selections were _before_ we started editing and mutating the "selections" prop.
  const [latestConfirmedSelections, setLatestConfirmedSelections] = useState(selections);

  const selectionsSet = useMemo(() => {
    return new Set(selections);
  }, [selections]);

  // Do sorting in separate memos for performance reasons
  const sortedLatestConfirmedSelections = useMemo(() => {
    // Note: Create new arr in order to not sort the original reference which is used elsewhere (unsorted)
    return [...latestConfirmedSelections].sort(optionSorter);
  }, [latestConfirmedSelections, optionSorter]);

  const sortedSelectableOptions = useMemo(() => {
    return options.sort(optionSorter);
  }, [optionSorter, options]);

  // We need to keep track of all additions that occur while editing internally and remember until editing is complete (dropdown closes)
  // These are seen as "unconfirmed" as their use is in contrast with the above "latestConfirmedSelections", which are set once we are done editing (dropdown closes)
  const [unconfirmedAdditions, setUnconfirmedAdditions] = useState(() => new Set<T>());

  // We combine the latest confirmed selections with all additions we have observed so far in this "round" of editing.
  const combinedCurrentSelections = useMemo(() => {
    const combinedSet = new Set(latestConfirmedSelections);
    for (const addition of unconfirmedAdditions) {
      combinedSet.add(addition);
    }
    return [...combinedSet];
  }, [unconfirmedAdditions, latestConfirmedSelections]);

  const dropdownItems = useMemo(() => {
    const sortedLatestConfirmedSelectionsSet = new Set(sortedLatestConfirmedSelections);
    const selectableWithoutSelected = sortedSelectableOptions.filter(
      option => !sortedLatestConfirmedSelectionsSet.has(option)
    );
    return [...sortedLatestConfirmedSelectionsSet, ...selectableWithoutSelected];
  }, [sortedSelectableOptions, sortedLatestConfirmedSelections]);

  const isSelectionDisabled = useCallback(
    (selection: T) => {
      return !selectionsSet.has(selection);
    },
    [selectionsSet]
  );

  const addUnconfirmedAdditions = useCallback(
    (newSelections: T[]) => {
      const additions = newSelections.filter(newSelection => !selectionsSet.has(newSelection));
      if (additions.length > 0) {
        setUnconfirmedAdditions(curr => {
          for (const addition of additions) {
            curr.add(addition);
          }
          return new Set(curr);
        });
      }
    },
    [selectionsSet]
  );

  // Whenever selections change and we are closed (or if we close the dropdown), we update the "latest confirmed selections set".
  const handleOpenChange = useCallback(
    (change: UseComboboxStateChange<T>) => {
      setIsOpen(change.isOpen ?? false);
      if (change.isOpen != null && !change.isOpen && selections) {
        setLatestConfirmedSelections(selections);
        setUnconfirmedAdditions(new Set());
      }
    },
    [selections]
  );

  const handleSelectionsChange = useCallback(
    (newSelections: T[]) => {
      if (isOpen) {
        addUnconfirmedAdditions(newSelections);
      } else {
        // its closed, so immediately set the latestConfirmedSelections to newSelections
        setLatestConfirmedSelections(newSelections);
      }
      onSelectionsChange(newSelections);
    },
    [addUnconfirmedAdditions, isOpen, onSelectionsChange]
  );

  // Whenever the selections change, and we are closed, we need to reflect the changed selections
  // in the latestConfirmedSelections state
  // The side-effect of re-running this when isOpen changes is fine
  useEffect(() => {
    if (!isOpen) {
      setLatestConfirmedSelections(selections);
    }
  }, [selections, isOpen]);

  return {
    combinedCurrentSelections,
    dropdownItems,
    isSelectionDisabled,
    addUnconfirmedAdditions,
    handleSelectionsChange,
    handleOpenChange,
  };
};
