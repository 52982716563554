import styled from 'styled-components';
import { Button } from '../Button';

export const FormWrapper = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const FormFooter = styled.div`
  padding: ${({ theme }) => theme.spacingMedium}px;
  display: grid;
  border-top: solid 1px ${({ theme }) => theme.borderColorDivider};
  column-gap: ${({ theme }) => theme.spacingDefault}px;
  grid-template-columns: min(120px, 35%) 1fr;

  ${Button} {
    width: 100%;
  }
`;

export const ScrollContainer = styled.div`
  overflow-y: auto;
  overflow-y: overlay;
  flex: 1 1 0;
`;

export const BalancesTable = styled.table`
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  width: 100%;
  text-align: right;
  border-collapse: collapse;
  margin-top: ${({ theme }) => theme.spacingComfortable}px;

  td {
    padding: ${({ theme }) => theme.spacingSmall}px 0;
  }

  td:first-child {
    text-align: left;
    color: ${({ theme }) => theme.colorTextSubtle};
  }
`;

export const SectionHeader = styled.div`
  margin-bottom: ${({ theme }) => theme.spacingDefault}px;
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  cursor: pointer;

  i {
    vertical-align: bottom;
  }
`;

export const QuickOptionsContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacingSmall}px;
  height: ${({ theme }) => theme.baseSize * 1.5}px;
`;
