import { pipe, tap } from 'rxjs';
import type { MinimalSubscriptionResponse } from '../types/SubscriptionResponse';

/**
 * Given a map of key->items, constructs a synthetic initial: true SubscriptionResponse
 * @param map A map of unique key -> item of type T
 * @param type optionally SubscriptionResponse.type to set on the SubscriptionResponse, defaults to empty string ""
 * @returns A synthetic MinimalSubscriptionResponse<T>
 */
export function mapToInitialSubscriptionResponse<T>(
  map: Map<string, T>,
  type?: string,
  latestMessage?: MinimalSubscriptionResponse<T>
): MinimalSubscriptionResponse<T> {
  return {
    ...latestMessage,
    type: type ?? latestMessage?.type ?? '',
    initial: true,
    data: [...map.values()],
  };
}

/**
 * A pipe to attach to your observables which only allows messages to pass if they are deemed meaningful, i.e.:
 * json is not undefined AND (json.length > 0 OR json.initial is true)
 * @param json a SubscriptionResponse<T>
 * @returns only relevant SubscriptionResponse messages
 */
export const jsonDefinedAndMeaningful = <T>(
  json: MinimalSubscriptionResponse<T> | undefined
): json is MinimalSubscriptionResponse<T> => json !== undefined && (json.data.length > 0 || !!json.initial);

export const jsonDefined = <T>(
  json: MinimalSubscriptionResponse<T> | undefined
): json is MinimalSubscriptionResponse<T> => json !== undefined;

/**
 * Tap an observable, and log all data flowing through that observable to the console.
 * @param prefix Prefix to log, to uniquely identify this console log
 * @returns Identity observable (input === output)
 *
 * @deprecated Don't use this in production, this should be dev-only.
 */
export function debugObservable<T>(prefix: string) {
  // eslint-disable-next-line no-console
  return pipe(tap<T>(x => console.log(prefix, x)));
}
