import { useMarketsContext } from '../../contexts';
import { Text } from '../Text';

export function MarketPart({ lastMarket }: { lastMarket: string }) {
  const { marketsByName } = useMarketsContext();

  return (
    <>
      <Text color="colorTextSubtle">on</Text>{' '}
      <Text color="colorTextImportant">{marketsByName.get(lastMarket)?.DisplayName}</Text>
    </>
  );
}
