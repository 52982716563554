import { useMemo } from 'react';
import { getAgGridColId } from '../components/BlotterTable/columns/getAgGridColId';
import type { PriceParams } from '../components/BlotterTable/columns/price.types';
import type { Column, ColumnDef } from '../components/BlotterTable/columns/types';
import { useDefaultColumns } from '../components/BlotterTable/useDefaultColumns';
import type { ExpectTrue } from '../tests';
import { EMPTY_ARRAY } from '../utils/empty';
import type {
  CxlRejReasonEnum,
  DecisionStatusEnum,
  ExecTypeEnum,
  IAllocation,
  IMultilegParameters,
  IOMSExecutionReport,
  IOMSExecutionReport4203Aggregations,
  IOMSExecutionReport4203LegSummary,
  IOMSExecutionReport4203TransactedAssets,
  OrdRejReasonEnum,
  OrdRiskStatusEnum,
  OrdStatusEnum,
  OrdTypeEnum,
  PricingModeEnum,
  RequestSourceEnum,
  SideEnum,
} from './types';

// OMSExecutionReport4203
export class ExecutionReport {
  static readonly rowID = 'ExecID';
  static readonly defaultColumns: (Column | keyof ExecutionReport)[] = [
    'Timestamp',
    'Revision',
    'ExecType',
    'ExecID',
    'OrderQty',
    'Price',
    'LastMarket',
    'LastExecID',
    'CumQty',
    'AvgPx',
    'CumFee',
    'AvgPxAllIn',
    'CumAmt',
    'OrdStatus',
  ];

  Timestamp!: string;

  MessageID?: string;

  User!: string;

  RequestUser?: string;

  Symbol!: string;

  OrderID!: string;

  ClOrdID!: string;

  Comments?: string;

  OrigClOrdID?: string;

  SubmitTime!: string;

  ExecID!: string;

  Side!: SideEnum;

  TransactTime?: string;

  ExecType!: ExecTypeEnum;

  OrdStatus!: OrdStatusEnum;

  OrdType!: OrdTypeEnum;

  OrderQty!: string;

  Price?: string;

  Currency!: string;

  LeavesQty?: string;

  CumQty!: string;

  AvgPx?: string;

  TimeInForce?: string;

  LastMarket?: string;

  LastMarketAccount?: string;

  LastPx?: string;

  LastQty?: string;

  LastAmt?: string;

  LastFee?: string;

  LastFeeCurrency?: string;

  CumAmt?: string;

  CumFee?: string;

  FeeCurrency?: string;

  OrdRejReason?: OrdRejReasonEnum;

  LastExecID?: string;

  CxlRejReason?: CxlRejReasonEnum;

  DecisionStatus!: DecisionStatusEnum;

  QuoteID?: string;

  AmountCurrency?: string;

  SessionID?: string;

  CancelSessionID?: string;

  ExpectedFillPrice?: string;

  ExpectedFillQty?: string;

  SubAccount?: string;

  Strategy?: string;

  AvgPxAllIn?: string;

  RFQID?: string;

  ParentOrderID?: string;

  ParentRFQID?: string;

  StartTime?: string;

  EndTime?: string;

  EID?: string;

  AllowedSlippage?: string;

  EventIndicator?: string;

  LastTalosFee?: string;

  CumTalosFee?: string;

  TalosFeeCurrency?: string;

  AggressorSide?: SideEnum;

  Revision!: number;

  Markets!: IOMSExecutionReport['Markets'];

  Text?: string;

  Group?: string;

  Parameters?: Partial<IOMSExecutionReport['Parameters']>;

  Allocation?: IAllocation;

  PricingReference?: string;

  LastRequestTimestamp?: string;
  RiskStatus?: Record<OrdRiskStatusEnum, boolean>;
  LastTradeTime?: string;
  PricingMode?: PricingModeEnum;
  TriggerTime?: string;
  OrgID?: string;
  ProcessingService?: {
    ServiceID?: number;
    PartitionID?: number;
  };
  MultilegParams?: IMultilegParameters;
  FixingDetails?: {
    Index?: string;
    Fixing?: string;
  };
  LegSummary?: IOMSExecutionReport4203LegSummary[];
  Aggregations?: IOMSExecutionReport4203Aggregations[];
  TransactedAssets?: IOMSExecutionReport4203TransactedAssets[];

  RequestSource?: RequestSourceEnum;
  LastRequestSource?: RequestSourceEnum;

  constructor(data: ExecutionReport) {
    Object.assign(this, data);
  }
}

interface UseExecutionReportColumns {
  defaultColumns?: (keyof ExecutionReport | Partial<Column>)[];
}

export function useExecutionReportColumns({ defaultColumns = EMPTY_ARRAY }: UseExecutionReportColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            { field: 'Timestamp', type: 'date', sortable: true, params: { milliseconds: true } },
            { field: 'Revision', type: 'text', sortable: true, width: 120 },
            { field: 'ExecType', type: 'text', width: 150 },
            { field: 'ExecID', type: 'id' },
            { field: 'OrderQty', type: 'size', params: { currencyField: 'Currency' } },
            {
              field: 'Price',
              type: 'price',
              params: { assetField: 'Symbol', showReferencePrice: true } satisfies PriceParams,
            },
            {
              field: 'PricingReference',
              type: 'price',
              hide: true,
              params: {
                isReferencePrice: true,
              },
            },
            { field: 'LastMarket', type: 'market' },
            { field: 'LastExecID', type: 'id' },
            { field: 'CumQty', type: 'size', params: { currencyField: 'Currency' } },
            { field: 'AvgPx', type: 'price', params: { assetField: 'Symbol' } },
            { field: 'CumFee', type: 'size', params: { currencyField: 'FeeCurrency' } },
            {
              field: 'AvgPxAllIn',
              title: 'Filled Price All-In',
              type: 'price',
              params: { assetField: 'Symbol', showFeeIcon: 'always' },
            },
            { field: 'CumAmt', title: 'Filled Counter Amt', type: 'size', params: { currencyField: 'AmountCurrency' } },
            { field: 'OrdStatus', type: 'orderStatus' },
          ] satisfies ColumnDef<ExecutionReport>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );
  const defaultHiddenColumns = useMemo(() => {
    return new Map(
      (
        [
          { field: 'AggressorSide', type: 'text' },
          { field: 'Allocation', type: 'text' },
          { field: 'AllowedSlippage', type: 'text' },
          { field: 'CancelSessionID', type: 'text' },
          { field: 'ClOrdID', type: 'id' },
          { field: 'Comments', type: 'text', sortable: true },
          { field: 'CumTalosFee', type: 'id' },
          { field: 'CxlRejReason', type: 'text' },
          { field: 'DecisionStatus', type: 'text' },
          { field: 'EID', type: 'text' },
          { field: 'EndTime', type: 'date' },
          { field: 'ExpectedFillPrice', type: 'price', params: { assetField: 'Symbol' } },
          { field: 'ExpectedFillQty', type: 'size', params: { currencyField: 'Currency' } },
          { field: 'Group', type: 'text' },
          { field: 'LastAmt', type: 'size', params: { currencyField: 'AmountCurrency' } },
          { field: 'LastFee', type: 'size', params: { currencyField: 'FeeCurrency' } },
          { field: 'LastPx', type: 'price', params: { assetField: 'Symbol' } },
          { field: 'LastQty', type: 'size', params: { currencyField: 'Currency' } },
          { field: 'LastTalosFee', type: 'id' },
          { field: 'LeavesQty', type: 'size', params: { currencyField: 'Currency' } },
          { field: 'LastMarketAccount', type: 'marketAccount' },
          // 'Market Account Label',
          { field: 'Markets', type: 'text' },
          { field: 'MessageID', type: 'text' },
          { field: 'OrdRejReason', type: 'text' },
          { field: 'OrdType', type: 'text' },
          { field: 'OrderID', type: 'id' },
          { field: 'OrigClOrdID', type: 'id' },
          { field: 'Parameters', type: 'text' },
          { field: 'ParentOrderID', type: 'id' },
          { field: 'ParentRFQID', type: 'id' },
          { field: 'QuoteID', type: 'id' },
          { field: 'RFQID', type: 'id' },
          { field: 'SessionID', type: 'text' },
          { field: 'Side', type: 'side' },
          { field: 'StartTime', type: 'date' },
          { field: 'Strategy', type: 'text' },
          { field: 'SubAccount', type: 'text' },
          { field: 'SubmitTime', type: 'date', sortable: true, params: { milliseconds: true } },
          { field: 'Symbol', type: 'security' },
          { field: 'Text', type: 'text' },
          { field: 'TimeInForce', type: 'text' },
          { field: 'TransactTime', type: 'date', params: { milliseconds: true } },
          { field: 'User', type: 'user' },
        ] satisfies (false | ColumnDef<ExecutionReport>)[]
      ).map(c => [getAgGridColId(c), { ...c, hide: true }])
    );
  }, []);

  const columnDefinitions = useMemo(() => {
    return new Map(
      (
        [
          ...defaultVisibleColumns.values(),
          ...defaultHiddenColumns.values(),
        ] satisfies ColumnDef<ExecutionReport>[] as Column[]
      ).map(c => [getAgGridColId(c), c])
    );
  }, [defaultVisibleColumns, defaultHiddenColumns]);

  return useDefaultColumns(defaultColumns, columnDefinitions);
}

// TYPE LEVEL TESTS
type _Expect_ExecutionReport_To_Only_Have_Keys_From_IOMSExecutionReport = ExpectTrue<
  {
    [K in keyof ExecutionReport & string]: K extends keyof IOMSExecutionReport ? true : K;
  }[Exclude<keyof ExecutionReport, never>]
>;
type _Expect_All_Keys_In_IOMSExecutionReport_To_Be_In_ExecutionReport = ExpectTrue<
  {
    [K in keyof IOMSExecutionReport & string]: K extends keyof ExecutionReport ? true : K;
  }[Exclude<keyof IOMSExecutionReport, never>]
>;
