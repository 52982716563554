import styled, { css } from 'styled-components';
import { Button, InternalButtonSpan, getIconSpacing } from '../Button';
import { Box } from '../Core';
import { getFontSize, getLineHeight } from '../Form/utils';
import { Icon } from '../Icons';

export const IconsContainer = styled(Box)`
  display: grid;
  ${Icon} {
    grid-area: -1/1;
  }
`;
export const FadingIcon = styled(Icon)<{ show: boolean }>`
  transition: opacity 100ms;

  ${({ show }) =>
    !show &&
    css`
      opacity: 0;
    `}
`;

export const CopyButtonWrapperButton = styled(Button)`
  ${InternalButtonSpan} {
    ${({ size, theme }) => css`
      gap: ${getIconSpacing(size)}px;
      font-size: ${getFontSize(theme, size)}rem;
      line-height: ${getLineHeight(theme, size)}rem;
    `}
  }
`;
