import type Big from 'big.js';
import type { ExpectTrue } from '../../tests';
import { MARKET_ORDER_OUTCOME_DATAPOINTS } from '../../tokens';
import { parseDate, toBig, type OptionalProperties } from '../../utils';
import type { AggregationWindowEnum, IMarketOrderOutcomeDatapoint, OrdStatusEnum, UpdateActionEnum } from '../types';
import type { DatapointProps } from './types';

export class MarketOrderOutcomeDatapoints {
  public static readonly Stream_Name = MARKET_ORDER_OUTCOME_DATAPOINTS;

  public readonly StartTime: Date;

  public readonly Market: string | undefined;
  public readonly OutcomePct: Big | undefined;
  public readonly OrderStatus: OrdStatusEnum;
  public readonly UpdateAction: UpdateActionEnum | undefined;
  public readonly ReleaseRevision: string | undefined;
  public readonly AggregationWindow: AggregationWindowEnum | undefined;
  constructor(data: _MarketOrderOutcomeDatapoints) {
    this.StartTime = parseDate(data.StartTime);
    this.Market = data.Market;
    this.OutcomePct = toBig(data.OutcomePct);
    this.OrderStatus = data.OrderStatus;
    this.UpdateAction = data.UpdateAction;
    this.ReleaseRevision = data.ReleaseRevision;
    this.AggregationWindow = data.AggregationWindow;
  }
}

type _MarketOrderOutcomeDatapoints = OptionalProperties<DatapointProps<IMarketOrderOutcomeDatapoint>, 'UpdateAction'>;

// TYPE LEVEL TESTS
type _Expect_MarketOrderOutcomeDatapoint_To_Only_Have_Keys_From_IMarketOrderOutcomeDatapoint = ExpectTrue<
  {
    [K in keyof MarketOrderOutcomeDatapoints & string]: K extends keyof _MarketOrderOutcomeDatapoints ? true : K;
  }[Exclude<keyof MarketOrderOutcomeDatapoints, never>]
>;
type _Expect_All_Keys_In_IMarketOrderOutcomeDatapoint_To_Be_In_MarketOrderOutcomeDatapoint = ExpectTrue<
  {
    [K in keyof _MarketOrderOutcomeDatapoints & string]: K extends keyof MarketOrderOutcomeDatapoints ? true : K;
  }[Exclude<keyof _MarketOrderOutcomeDatapoints, never>]
>;
