/**
 * Checks if two arrays contain the same elements. The order of elements doesn't matter.
 * This function checks for value matching and not deep equality of elements.
 *
 * @param {T[]} a - The first array to compare.
 * @param {T[]} b - The second array to compare.
 * @returns {boolean} - Returns true if both arrays contain the same elements, false otherwise.
 */
export function arrayContentsEqual<T>(a?: T[], b?: T[]) {
  if (!a && !b) {
    return true;
  }
  return a?.length === b?.length && a?.every(value => b?.includes(value));
}

export function pushUnique<T>(array: T[], item: T): T[] {
  if (!array.includes(item)) {
    return [...array, item];
  }
  return array;
}

/** Array Extension to remove values of null, undefined, and NaN
 * @param {boolean} compactAll - If true, removes all falsy values
 * @returns {T[]} - Returns a new array based on above conditions
 */
// replacement for Sugar.Array.compact
export function compactArrayForThis<T>(this: T[], compactAll: boolean) {
  return this.filter(item =>
    compactAll ? !!item : item !== undefined && item !== null && Number.isNaN(item) === false
  ) as Exclude<T, null | undefined>[]; // TOOD this type could be improved to exclude falsy values if compactAll is true
}
globalThis.Array.prototype.compact = compactArrayForThis;

// target replacement for .compact()
/** Array Extension to remove values of null, undefined, and NaN
 * @param {boolean} array - The array to compact
 * @param {boolean} compactAll - If true, removes all falsy values
 * @returns {T[]} - Returns a new array based on above conditions
 */
export function compactArray<T>(array: T[], compactAll: boolean = false) {
  return array.filter(item =>
    compactAll ? !!item : item !== undefined && item !== null && Number.isNaN(item) === false
  ) as Exclude<T, null | undefined>[]; // TOOD this type could be improved to exclude falsy values if compactAll is true
}
