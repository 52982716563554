import type { ReactNode } from 'react';
import styled from 'styled-components';
import { Tooltip, type TooltipProps } from '../../Tooltip';
import { Label } from '../styles';
import { ToggleSwitch, ToggleSwitchWrapper, ToggleWrapper, type ToggleWrapperProps } from './styles';

export * from './styles';

export const Toggle = styled(({ label, checked, disabled, tooltip, onChange, tooltipProps, ...props }: ToggleProps) => {
  return (
    <ToggleWrapper
      data-testid="toggle-button"
      data-checked={checked}
      data-disabled={disabled}
      onClick={() => !disabled && onChange(!checked)}
      disabled={disabled}
      checked={checked}
      {...props}
    >
      {label && (
        <>
          {tooltip ? (
            <Tooltip {...tooltipProps} tooltip={tooltip}>
              <Label tooltip={true}>{label}</Label>
            </Tooltip>
          ) : (
            <Label>{label}</Label>
          )}
        </>
      )}
      <ToggleSwitchWrapper>
        <ToggleSwitch data-testid="toggle-switch" />
      </ToggleSwitchWrapper>
    </ToggleWrapper>
  );
})``;

export interface ToggleProps extends Omit<ToggleWrapperProps, 'onClick'> {
  /** Tooltip text/component to show */
  tooltip?: ReactNode;
  /** Extra props from {@link TooltipProps} - the other {@link tooltip} prop takes the priority for specified display, if provided */
  tooltipProps?: Omit<TooltipProps, 'children' | 'tooltip'>;

  onChange(value: boolean): void;
}
