import styled, { css, type DefaultTheme } from 'styled-components';
import { Button } from '../Button';
import { HStack } from '../Core';
import { Icon } from '../Icons';
import type { TimelineItemVariant } from './types';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

function getIconColor(variant: TimelineItemVariant, theme: DefaultTheme) {
  switch (variant) {
    case 'primary':
      return theme.colorTextPrimary;
    case 'secondary':
      return theme.colorTextImportant;
    case 'default':
      return theme.colorTextMuted;
    case 'positive':
      return theme.colorTextPositive;
    case 'warning':
      return theme.colorTextWarning;
  }
}

export const ItemTrackContainer = styled.div<{ variant: TimelineItemVariant }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${Icon} {
    position: relative;
    color: ${({ variant, theme }) => getIconColor(variant, theme)};
    z-index: 20;
  }
`;

export const ItemTrack = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `0 ${theme.spacingComfortable}px`};
  align-items: center;
  position: relative;
  height: 100%;
  z-index: 10;
  width: ${({ theme }) => `${16 + 2 * theme.spacingComfortable}px`};

  &:before {
    width: 1px;
    content: '';
    height: 100%;

    // Dotted border with more spacing between dots
    background-image: ${({ theme }) => `linear-gradient(${theme.borderColorTimeline} 20%, rgba(255, 255, 255, 0) 0%)`};
    background-size: 1px 5px;
    background-repeat: repeat-y;
  }
`;

export const ItemContent = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorTextImportant};
    padding: ${theme.spacingMedium}px;
    background: ${theme.backgroundTimelineContent};
    border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  `};
`;

export const ItemHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + ${ItemContent} {
    ${({ theme }) => css`
      margin-top: ${theme.spacingSmall}px;
    `};
  }
`;

export const GroupLabel = styled(Button).attrs(props => ({ ...props, ghost: true }))`
  padding: 0;
  line-height: 1;
  height: auto;
  color: ${({ theme }) => theme.colorTextSubtle};
  border: none;

  &:hover {
    background: transparent;
    text-decoration: underline;
    border: none;
  }
`;

export const ItemHeaderDetails = styled(HStack)`
  margin-bottom: ${({ theme }) => theme.spacingSmall}px;
  justify-content: flex-start;
  ${({ theme }) => css`
    font-size: ${theme.fontSizeSmall}rem;
    color: ${theme.colorTextMuted};
  `}
`;
ItemHeaderDetails.defaultProps = {
  gap: 'spacingSmall',
};

export const ItemInitiator = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorTextSubtle};
    text-transform: uppercase;
  `}
`;

export const ItemLabel = styled.div`
  color: ${({ theme }) => theme.colorTextImportant};
  & + ${ItemHeaderDetails} {
    ${({ theme }) => css`
      margin-top: ${theme.spacingSmall}px;
    `};
  }
`;

export const ItemTimestamp = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorTextSubtle};
  `}
`;

export const ItemContentWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.spacingLarge}px;
  flex: 1;
  overflow: hidden;
`;

export const ItemWrapper = styled.div`
  display: flex;
  align-items: stretch;
  overflow: hidden;
`;

export const ToBottomButtonWrapper = styled.div`
  pointer-events: auto;
`;

export const Overlay = styled.div`
  pointer-events: none;
  position: absolute;
  height: 50px;
  right: 0;
  left: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.backgroundTimelineOverlay};
`;
