import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.gray['100']};
  align-self: center;
  padding: 0 ${({ theme }) => theme.spacingSmall}px;
  white-space: nowrap;
`;

export const Currency = styled.span`
  display: inline;
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  & > svg {
    // pixel perfect
    vertical-align: middle;
    margin-top: -0.1em;
    margin-right: ${({ theme }) => theme.spacingSmall}px;
  }

  & > span {
    vertical-align: middle;
    line-height: 1;
  }

  &:first-child {
    margin-right: ${({ theme }) => theme.spacingDefault}px;
  }
`;

export const DisplaySecurityWrapper = styled(Wrapper)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;
