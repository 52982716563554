import { Button, ICON_SIZES, Icon, IconName, Text, VStack, useDisclosure } from '@talos/kyoko';
import { useCallback } from 'react';
import { SupportModal } from '../../components/SupportModal';
import { RestrictedIconWrapper } from './styles';

export const RestrictedAccessWrapper = () => {
  return (
    <VStack
      h="100%"
      w="100%"
      justifyContent="center"
      alignItems="center"
      gap="spacingDefault"
      background="backgroundContent"
    >
      <RestrictedIconWrapper>
        <Icon icon={IconName.Key} color="colors.yellow.lighten" size={ICON_SIZES.LARGE} />
      </RestrictedIconWrapper>
      <Text size="fontSizeBig" color="colorTextAttention" weight="fontWeightMedium">
        Restricted Access
      </Text>
      <Text>This account doesn&apos;t have access to this area.</Text>
    </VStack>
  );
};

export const NoSecuritiesNotificationWrapper = () => {
  const modalState = useDisclosure();

  const openSupportModal = useCallback(() => {
    modalState.open();
  }, [modalState]);

  return (
    <VStack
      h="100%"
      w="100%"
      justifyContent="center"
      alignItems="center"
      gap="spacingDefault"
      background="backgroundContent"
    >
      <RestrictedIconWrapper>
        <Icon icon={IconName.Key} size={ICON_SIZES.LARGE} />
      </RestrictedIconWrapper>
      <Text size="fontSizeBig" color="colorTextAttention" weight="fontWeightMedium">
        No Trading Available
      </Text>
      <Text>This account does not have any pairs available to trade. Please contact support.</Text>
      {/* Contact support button */}
      <Button onClick={openSupportModal}>Support</Button>
      <SupportModal {...modalState} />
    </VStack>
  );
};
