import type Big from 'big.js';
import { createContext, useContext } from 'react';
import type { Observable } from 'rxjs';

export interface MarketPrice {
  bid: Big | undefined | null;
  offer: Big | undefined | null;
  spread: Big | undefined | null;
}
export interface MarketPriceContextProps {
  marketPricesBySymbol: Observable<Map<string, MarketPrice | undefined>>;
  registerSubscription: (symbol: string) => void;
  registerSubscriptions: (symbols: string[]) => void;
  unregisterSubscription: (symbol: string, persistRate?: boolean) => void;
  unregisterSubscriptions: (symbols: string[]) => void;
}
export const MarketPriceContext = createContext<MarketPriceContextProps | undefined>(undefined);
MarketPriceContext.displayName = 'MarketPriceContext';

export function useMarketPriceContext() {
  const context = useContext(MarketPriceContext);
  if (context === undefined) {
    throw new Error('Missing MarketPriceContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}
