import type { IHeaderParams } from 'ag-grid-community';
import { isNil } from 'lodash';
import type { CSSProperties, ReactNode } from 'react';
import { Box, HStack } from '../Core';
import { Icon, IconName } from '../Icons';
import { Tooltip } from '../Tooltip';

const visibilityHiddenStyle: CSSProperties = { visibility: 'hidden' };

export const AgGridFilterPermissionActionHeader = ({
  tooltipContent,
  ...rest
}: IHeaderParams & { tooltipContent?: ReactNode }) => {
  const disableTooltip = isNil(tooltipContent);

  return (
    <HStack h="100%" alignItems="center" justifyContent="flex-start" w="100%" gap="spacingSmall">
      Permission
      <Box style={disableTooltip ? visibilityHiddenStyle : undefined}>
        <Tooltip
          tooltip={
            <Box w="150px" color="colorTextImportant">
              {tooltipContent}
            </Box>
          }
          usePortal
          disabled={disableTooltip}
        >
          <Icon icon={IconName.InformationCircle} />
        </Tooltip>
      </Box>
    </HStack>
  );
};
