import {
  type AgEventType,
  type CellEvent,
  type GridApi,
  isColumn as isAgColumn,
  type ValueService,
} from 'ag-grid-community';
import { AGGRID_AUTOCOLUMN_ID } from '../BlotterTable/types';

interface AgGridGetCellValueParams<TData, TValue> {
  api: GridApi<TData>;
  column: CellEvent<AgEventType, TData, TValue>['column'] | null;
  node?: CellEvent<AgEventType, TData, TValue>['node'] | null;
  value: CellEvent<AgEventType, TData, TValue>['value'];
}

/**
 * This function provides a method to get the "display value" of an ag-grid cell, using the same method
 * that ag-grid uses internally.
 *
 * @param params AgGrid params object (e.g. from an event callback, etc.)
 * @returns Value as formatted for display in an ag-grid cell (e.g. using `valueFormatter`, etc.)
 */
export function getCellDisplayValue<TData = any, TValue = any>(params: AgGridGetCellValueParams<TData, TValue>) {
  const invokedForRowGroupColumn = params.column?.getColId() === AGGRID_AUTOCOLUMN_ID;
  // If we are invoking on behalf of the row group column, we can either be a group row or a leaf row. For group rows, there'll be a rowGroupColumn.
  // Otherwise, params.column will contain the column relevant for the invoked cell this function is invoked for.
  const column = invokedForRowGroupColumn ? params.node?.rowGroupColumn ?? params.column : params.column;

  if (!column) {
    return params.value;
  }

  if (!params.node) {
    return params.value;
  }

  try {
    if (!isAgColumn(column)) {
      return params.value;
    }

    // We're grabbing this private property because we want to be calling the formatValue function in the exact same way that AgGrid would internally
    const valueService: ValueService | undefined = params.node?.['beans']?.['valueService'];
    if (!valueService) {
      return params.value;
    }
    const formattedValue = valueService.formatValue(column, params.node, params.value);

    return formattedValue ?? params.value;
  } catch {
    return params.value;
  }
}

/**
 * This function provides a method to get the "filter value" of an ag-grid cell, using the same method
 * that ag-grid uses internally.
 *
 * @param params AgGrid params object (e.g. from an event callback, etc.)
 * @returns Value that ag-grid will use when filtering this cell (e.g. using `filterValueGetter`, etc.)
 */
export function getCellFilterValue<TData = any, TValue = any>(params: AgGridGetCellValueParams<TData, TValue>) {
  if (!params.column) {
    return params.value;
  }

  try {
    if (!isAgColumn(params.column)) {
      return params.value;
    }

    // We're grabbing this private property because we want to be calling the getValue function in the exact
    // same way that AgGrid would internally (setting filter to true to get the filter value)
    const valueService: ValueService | undefined = params.node?.['beans']?.['valueService'];
    if (!valueService) {
      return params.value;
    }

    const filterValue = valueService.getValue(params.column, params.node, true);
    return filterValue;
  } catch {
    return params.value;
  }
}
