// this function is symlinked between packages/shared and packages/kyoko
// to ensure it is identical in both cases
export const hashTheme = (theme: string): string => {
  if (['DarkTheme', 'LightTheme'].includes(theme)) {
    return theme;
  }

  // we only want to hash the name, not the light/dark prefix
  // This is to have a consistent hash for the same theme, regardless of light/dark (which prepend them)
  const prefix = theme.startsWith('Light') ? 'Light' : 'Dark';
  const str = theme.replace(prefix, '');

  // Remove all non-numeric/non-alphabetical characters
  return prefix + btoa(str).replace(/[^a-zA-Z0-9]/g, '') + 'Theme';
};
