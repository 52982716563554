import type { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SidebarHeaderWrapper } from './styles';

// The collapsedIcon image URI will be shown the the sidebar is not expanded

export const SidebarHeader = styled(function SidebarHeader({ children }: { children?: ReactNode }) {
  return (
    <Link to="/">
      <SidebarHeaderWrapper>{children}</SidebarHeaderWrapper>
    </Link>
  );
})``;
