import { isNil } from 'lodash';
import type { RequiredProperties } from '../utils/types';
import type { Security } from './Security';
import { ProductTypeEnum } from './types';

export const isPerpSecurity = (security: Security): security is PerpSecurity =>
  security.ProductType === ProductTypeEnum.PerpetualSwap &&
  !isNil(security.SettleValueType) &&
  !isNil(security.Markets);

export type PerpSecurity = RequiredProperties<Security, 'Markets' | 'SettleValueType'>;
