import { hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { lightColors, mapLightTheme } from './LightTheme';

const colors: Colors = {
  gray: {
    main: hsla(210, 0, 1.0, 1),
    '000': hsla(210, 0, 0.92, 1),
    '010': '#f5f5f5',
    '020': hsla(210, 0, 0.89, 1),
    '030': hsla(220, 0, 0.86, 1),
    '040': hsla(220, 0, 0.83, 1),
    '050': hsla(220, 0, 0.79, 1),
    '060': hsla(200, 0, 0.74, 1),
    '070': hsla(200, 0, 0.69, 1),
    '080': hsla(200, 0, 0.63, 1),
    '090': hsla(210, 0, 0.44, 1),
    '100': hsla(0, 0, 0.1, 1),
  },

  black: {
    default: '#000000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#FFFFFF',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.1)',
  },

  primary: {
    default: '#6610f2',
    lighten: '#6610f2',
    dim: setAlpha(0.4, '#6610f2'),
    mute: setAlpha(0.1, '#6610f2'),
  },

  green: {
    default: '#21db99',
    lighten: '#21db99',
    dim: 'rgba(51, 126, 90, 0.4)',
    mute: 'rgba(51, 126, 90, 0.1)',
  },

  red: {
    default: '#fa4877',
    lighten: '#fa4877',
    dim: 'rgba(146, 46, 67, 0.4)',
    mute: 'rgba(146, 46, 67, 0.1)',
  },

  yellow: {
    default: '#ffa519',
    lighten: '#ffa519',
    dim: 'rgba(204, 172, 62, 0.4)',
    mute: 'rgba(204, 172, 62, 0.1)',
  },

  blue: {
    mute: 'rgba(69, 140, 193, 0.1)',
    dim: 'rgba(69, 140, 193, 0.4)',
    default: '#6678fa',
    lighten: '#6678fa',
  },

  orange: {
    default: '#E65200',
    lighten: '#FF6B00',
    dim: 'rgba(230, 82, 0, 0.4)',
    mute: 'rgba(230, 82, 0, 0.1)',
  },

  purple: lightColors.purple,
};

export const LightAbraTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightAB',

  fontFamily: "'Nunito', sans-serif",
  fontUrl: 'https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',
  borderRadiusDefault: 8,
  borderRadiusContent: 8,
  spacingLayout: 8,

  backgroundBody: colors.gray['010'],

  backgroundPriorityButton: colors.gray['060'],
  backgroundPriorityButtonHover: colors.gray['070'],
  backgroundPriorityButtonFocus: colors.gray['070'],
  backgroundPriorityButtonActive: colors.gray['060'],

  backgroundDefaultButton: colors.gray['030'],
  backgroundDefaultButtonHover: colors.gray['040'],
  backgroundDefaultButtonFocus: colors.gray['040'],
  backgroundDefaultButtonActive: colors.gray['030'],

  backgroundSelect: colors.gray['030'],
  backgroundSelectHover: colors.gray['040'],
  backgroundSelectFocus: colors.gray['040'],

  backgroundInput: '#f6f7f8',

  backgroundToggleSwitch: setAlpha(0.12, colors.gray[100]),

  borderColorInput: '#f6f7f8',
  borderColorInputHover: '#f6f7f8',
  borderColorInputFocus: '#ffc107',

  borderWidthButton: 1,

  borderColorPriorityButton: colors.gray['060'],
  borderColorPriorityButtonHover: colors.gray['070'],
  borderColorPriorityButtonFocus: colors.gray['070'],
  borderColorPriorityButtonActive: colors.gray['060'],

  borderColorDefaultButton: colors.gray['030'],
  borderColorDefaultButtonHover: colors.gray['040'],
  borderColorDefaultButtonFocus: colors.gray['040'],
  borderColorDefaultButtonActive: colors.gray['030'],

  colorTextVolumeLadderSpread: colors.gray['080'],
});
