import type {
  ICustomerOrderSummary,
  ICustomerOrderSummary4432PerCurrencyFees,
  ICustomerOrderSummary4432PerCurrencyPnL,
} from './types';

export class CustomerDealSummary {
  Spread: ICustomerOrderSummary['TargetSpread'];

  FilledSpread: ICustomerOrderSummary['ActualSpread'];

  PnL: ICustomerOrderSummary4432PerCurrencyPnL[] | undefined;

  Fees: ICustomerOrderSummary4432PerCurrencyFees[] | undefined;

  SalesCommission: ICustomerOrderSummary['SalesCommission'];

  constructor(data: CustomerDealSummary) {
    Object.assign(this, data);
    // Need to reverse the PnL since we expect base to be first, quote second.
    this.PnL = this.PnL?.reverse();
  }
}
