import type { ReactNode } from 'react';
import { useTheme, type DefaultTheme } from 'styled-components';
import type { Security } from '../../types/Security';
import { Box, Flex, type BoxProps } from '../Core';
import { CurrencyIcon } from '../CurrencyIcon';
import { Tooltip } from '../Tooltip';
import { Wrapper } from './styles';

export { Expiration } from './styles';

type SecurityProps = (
  | {
      symbol?: string;
      security: Security;
    }
  | {
      symbol: string;
      security?: Security;
    }
) & {
  currency?: string;
  showIcon?: boolean;
  showDescription?: boolean;
  theme?: DefaultTheme;
  iconSize?: number;
  colorful?: boolean;
  securityTooltip?: ReactNode;
  suffix?: ReactNode;
} & Omit<BoxProps, 'security'>;

export function SecurityRenderer({
  symbol,
  security,
  currency,
  showIcon,
  showDescription,
  theme,
  iconSize = 16,
  colorful,
  securityTooltip,
  suffix,
  ...props
}: SecurityProps) {
  const hookTheme = useTheme();
  const themeToUse = theme ?? hookTheme;
  const { colorTextSubtle, colorTextImportant } = themeToUse;

  const displaySymbol = security?.DisplaySymbol || symbol;

  return (
    <Wrapper gap="spacingSmall" color="colorTextImportant" {...props}>
      {showIcon !== false && (currency != null || security != null) && (
        <CurrencyIcon
          size={iconSize}
          currency={currency || security?.BaseCurrency || symbol?.split('-')[0]}
          theme={themeToUse}
          colorful={colorful}
          style={ICON_STYLE}
        />
      )}

      <Tooltip
        disabled={!securityTooltip}
        tooltip={securityTooltip}
        targetStyle={{ display: 'block', verticalAlign: 'middle' }}
        usePortal
      >
        <Box overflow="hidden" flex="1 1 0" textAlign="left">
          <Flex color={colorTextImportant} gap="spacingDefault">
            {displaySymbol}
            {
              /* + means that additional information can be seen by hovering symbol */
              !!securityTooltip && '+'
            }
            {suffix}
          </Flex>
          {showDescription && (
            <Flex
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              w="100%"
              color={colorTextSubtle}
              fontSize="fontSizeTiny"
              gap="spacingSmall"
            >
              {security?.Description}
            </Flex>
          )}
        </Box>
      </Tooltip>
    </Wrapper>
  );
}

const ICON_STYLE = {
  flexShrink: 0,
};
