import { createContext, useContext } from 'react';
import type { Organization } from '../types/Organization';

export const OrganizationContext = createContext<OrganizationContextProps | undefined>(undefined);
OrganizationContext.displayName = 'OrganizationContext';

export function useOrganizationContext() {
  const context = useContext(OrganizationContext);
  if (context === undefined) {
    throw new Error('Missing OrganizationContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}
export interface OrganizationContextProps {
  users: NonNullable<Organization['Users']>;
  isLoaded: boolean;
}
