export const Logo = ({ fill }) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M144.365 16.8925C142.776 23.25 141.186 29.2896 139.915 35.6471C153.584 37.8722 165.663 43.2761 178.696 51.8587L178.378 52.4945C158.034 40.0974 142.776 33.422 117.982 35.6471C99.5449 36.9186 82.0618 43.9119 68.0753 55.0375C54.4067 65.2094 44.2347 79.8317 38.1951 96.3611C32.7913 111.619 31.2019 128.149 34.0628 144.042C36.9236 160.254 43.9169 175.83 54.7246 188.545C67.1217 203.167 83.9691 213.975 102.406 218.743C116.71 222.557 135.465 222.875 150.405 218.107L150.723 218.743C149.133 219.379 148.18 219.379 147.226 219.696C132.922 223.193 117.346 223.511 103.042 219.696C102.088 224.147 99.227 235.272 98.2734 239.723H97.6377C98.5913 235.59 98.9092 234.319 99.5449 231.14C100.499 227.325 101.452 223.511 102.406 219.696C78.8831 213.657 58.5391 198.399 45.8241 177.419C35.3343 160.89 30.884 141.181 32.1555 122.109C33.1091 107.487 37.5594 92.5467 45.1884 79.8317C55.6782 62.0307 71.8898 48.0442 91.2802 40.4152C106.22 34.6935 123.068 32.7862 138.961 35.3292C140.233 29.6075 142.14 21.6606 143.412 16.5746C144.365 16.8925 143.729 16.5746 144.365 16.8925Z"
      fill={fill}
    />
    <path
      d="M98.5913 240.676L95.7304 240.04L96.0483 239.087C96.684 236.226 97.0019 234.636 97.3198 233.047L97.9555 230.504C98.5913 228.279 98.9092 226.372 99.5449 224.465C99.8628 222.875 100.181 221.286 100.499 220.014C77.6116 213.975 57.2676 198.717 44.5526 178.055C34.6985 161.525 29.9304 141.499 31.2019 122.109C32.1555 107.169 36.6058 92.2287 44.5526 79.5137C55.0425 61.7128 71.572 47.4084 90.9623 39.7794C105.585 34.0577 122.114 32.1504 138.326 34.3756C139.279 30.8789 140.233 26.7466 141.186 22.6142C141.822 20.3891 142.14 18.164 142.776 16.5746V15.3031L145.319 15.9388L145.001 16.8925C144.365 20.3891 143.412 23.5678 142.458 26.7466C142.14 29.2896 141.504 31.8326 140.869 34.3756C153.584 36.6007 165.345 41.6867 179.014 50.5872L179.967 51.2229L178.378 53.7659L177.424 53.1302C156.444 40.4152 141.504 34.3756 117.664 36.2828C100.181 37.8722 82.6976 44.5476 68.7111 55.3553C55.0425 65.8451 44.8705 80.4674 38.8309 96.9968C33.427 111.937 32.1555 128.466 34.3806 144.36C37.2415 160.254 44.2347 175.512 54.7246 187.909C66.8038 202.213 83.6512 213.021 101.77 217.789C116.71 221.922 134.829 221.604 149.133 217.153L150.087 216.836L150.723 219.696L149.769 220.014C148.18 220.65 147.226 220.65 146.272 220.968C131.968 224.464 116.71 224.782 102.724 220.968C102.088 223.511 101.452 226.69 100.499 230.186C99.5449 234.001 98.5913 237.815 98.2734 239.722L98.5913 240.676Z"
      fill={fill}
    />
    <path
      d="M82.0618 153.261H93.8232C100.499 153.261 105.585 151.353 108.445 148.175C111.624 144.678 112.896 139.274 112.896 131.645C112.896 124.016 111.306 118.294 108.445 114.798C105.585 110.983 101.452 109.394 95.0947 109.394H81.4261V153.261H82.0618ZM67.7575 165.658V96.9968H95.4126C106.22 96.9968 114.485 99.8577 119.571 105.579C124.657 110.983 127.518 119.884 127.518 131.327C127.518 137.367 126.564 143.089 124.339 147.857C122.432 152.625 119.571 156.439 115.757 159.618C112.896 161.843 109.717 163.433 106.22 164.068C102.406 165.022 97.6377 165.658 91.2802 165.658H67.7575Z"
      fill={fill}
    />
    <path
      d="M154.855 165.658L130.697 96.9968H146.59L161.848 148.492L177.106 96.9968H193L168.842 165.658H154.855Z"
      fill={fill}
    />
  </>
);
