import { hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { DarkTheme, darkColors, mapDarkTheme } from './DarkTheme';

const colors: Colors = {
  ...darkColors,

  gray: {
    main: '#1F2128',

    '000': '#121317',
    '010': hsla(231, 0.11, 0.11, 1),
    '020': hsla(231, 0.11, 0.14, 1),
    '030': hsla(231, 0.11, 0.16, 1),
    '040': hsla(231, 0.11, 0.2, 1),
    '050': '#363843',
    '060': hsla(231, 0.11, 0.28, 1),
    '070': hsla(231, 0.11, 0.36, 1),
    '080': hsla(231, 0.05, 0.57, 1),
    '090': hsla(231, 0.05, 0.75, 1),
    '100': hsla(231, 0.05, 0.95, 1),
  },

  green: {
    default: '#04d1b8',
    dim: 'rgba(4, 209, 184, 0.4)',
    mute: 'rgba(4, 209, 184, 0.1)',
    lighten: '#84e6da',
  },
  red: {
    lighten: '#fb7871',
    default: '#fa564e',
    dim: 'rgba(250, 86, 78, 0.4)',
    mute: 'rgba(250, 86, 78, 0.1)',
  },
  blue: {
    lighten: '#33439d',
    default: '#001489',
    dim: 'rgba(0, 20, 137, 0.4)',
    mute: 'rgba(0, 20, 137, 0.1)',
  },
  yellow: {
    lighten: '#e8b540',
    default: '#B68616',
    dim: 'rgba(182, 134, 22, 0.4)',
    mute: 'rgba(182, 134, 22, 0.1)',
  },
  primary: {
    default: '#04d1b8',
    dim: 'rgba(4, 209, 184, 0.4)',
    mute: 'rgba(4, 209, 184, 0.1)',
    lighten: '#84e6da',
  },
};

export const DarkCanarySidebarTheme: DefaultTheme = mapDarkTheme(colors, {
  fontFamily: "'Inter', sans-serif",
  fontUrl: 'https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',

  borderRadiusDefault: 4,
  borderRadiusSmall: 2,
  borderRadiusTiny: 1,
  borderRadiusContent: 7,

  spacingLayout: DarkTheme.baseSize * 0.5,

  borderColorSidebar: colors.blue.default,
  backgroundSidebar: colors.blue.default,
  backgroundSidebarItemActive: setAlpha(0.15, colors.primary.default),
  backgroundSidebarItemHover: setAlpha(0.2, colors.primary.default),

  colorTextSidebarItem: colors.white.default,
  colorTextSidebarItemActive: colors.primary.default,
  colorTextSidebarItemHover: setAlpha(0.9, colors.primary.default),

  backgroundHeader: colors.blue.default,
  borderColorHeader: colors.blue.default,
});
