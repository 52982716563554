import { cloneDeep, merge } from 'lodash';
import type { Colors, DefaultTheme } from 'styled-components';
import { DarkTheme, mapDarkTheme } from './DarkTheme';

const customcolors: Partial<Colors> = {
  black: {
    default: '#000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    main: '#1A1F31',

    '000': '#000D22',
    '010': '#13151b',
    '020': '#171b2c',
    '030': '#262a37',
    '040': '#2b2f3e',
    '050': '#303545',
    '060': '#444957',
    '070': '#595d6a',
    '080': '#82858f',
    '090': '#acaeb4',
    '100': '#eaeaec',
  },

  primary: {
    mute: 'rgba(0, 96, 245, 0.1)',
    dim: 'rgba(0, 96, 245, 0.4)',
    default: '#0060f5',
    lighten: '#327ff7',
  },

  green: {
    mute: 'rgba(51, 132, 0, 0.1)',
    dim: 'rgba(51, 132, 0, 0.4)',
    default: '#12b76a',
    lighten: '#12b76a',
  },

  red: {
    mute: 'rgba(196, 0, 76, 0.1)',
    dim: 'rgba(196, 0, 76, 0.4)',
    default: '#f04438',
    lighten: '#f04438',
  },

  yellow: {
    mute: 'rgba(204, 172, 62, 0.1)',
    dim: 'rgba(204, 172, 62, 0.4)',
    default: '#CCAC3E',
    lighten: '#F2D264',
  },

  blue: {
    mute: 'rgba(0, 96, 245, 0.1)',
    dim: 'rgba(0, 96, 245, 0.4)',
    default: '#0060f5',
    lighten: '#327ff7',
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },
};

const colors: Colors = merge(cloneDeep(DarkTheme.colors), customcolors);

export const DarkBCBTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkBCB',

  fontFamily: "'Montserrat', sans-serif",
  fontUrl:
    'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',

  backgroundHeader: colors.gray.main,
  borderColorHeader: colors.gray.main,
});
