import { memo, useCallback } from 'react';
import { useTheme } from 'styled-components';
import { useDynamicCallback } from '../../hooks';
import type { Currency } from '../../types';
import { Flex } from '../Core';
import { CurrencyIcon } from '../CurrencyIcon';
import type { RenderResultFunc } from '../Form/Autocomplete';
import { FuseAutocompleteResult } from '../Form/AutocompleteDropdown';
import { SearchSelect } from '../Form/SearchSelect';
import type { SearchSelectProps } from '../Form/SearchSelect/types';

type CurrencySelectProps = {
  value?: Currency;
} & Omit<SearchSelectProps<Currency>, 'selection' | 'getLabel' | 'renderPrefix' | 'getDescription'>;

const getLabel = (c: Currency) => c.Symbol;
const getDescription = (c: Currency) => c.Description;

export const CurrencySelect = memo(function CurrencySelect({ value, disabled, prefix, ...props }: CurrencySelectProps) {
  const { spacingSmall, spacingDefault, colorTextImportant, colorTextSubtle } = useTheme();

  const renderCurrencyIcon = useCallback(
    (item: Currency) => {
      return (
        <CurrencyIcon
          style={{
            color: 'currentColor',
            marginRight: spacingDefault,
          }}
          currency={item.Symbol}
        />
      );
    },
    [spacingDefault]
  );

  const renderResult: RenderResultFunc<Currency> = useDynamicCallback((searchResults, disabled) =>
    FuseAutocompleteResult(searchResults, disabled, renderCurrencyIcon)
  );

  return (
    <SearchSelect
      {...props}
      prefix={
        <Flex mr="spacingSmall">
          {prefix}{' '}
          {value != null && (
            <CurrencyIcon
              style={{ color: disabled ? colorTextSubtle : colorTextImportant, marginLeft: spacingSmall }}
              currency={value.Symbol}
            />
          )}
        </Flex>
      }
      renderResult={renderResult}
      selection={value}
      getLabel={getLabel}
      getDescription={getDescription}
      disabled={disabled}
    />
  );
});
