export enum FormRowStatus {
  Added = 'Added',
  Updated = 'Updated',
  Removed = 'Removed',
  None = 'None',
}

export type FormRowErrors = { [key: string]: string };

export interface FormRow<R> {
  readonly data: R;
  readonly status: FormRowStatus;
  readonly errors: { [key: string]: string };
  setData(data: R);
  setErrors(errors?: { [key: string]: string });
  remove(force?: boolean);
}
