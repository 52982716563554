export const Logo = ({ fill }) => (
  <>
    <path
      d="M161.473253,160.665621 C154.475018,165.697789 145.409985,168.315402 135.276844,168.315402 C112.905816,168.315402 97.1664974,151.455978 97.1664974,128.499251 L97.1664974,128.146667 C97.1664974,106.803335 113.124251,89.0172243 133.323891,89.0172243 C147.847011,89.0172243 156.458515,93.6718873 166.032611,101.649332 L182.84374,81.4542047 C169.920931,70.512101 156.464068,64.4997126 134.211513,64.4997126 C96.6509549,64.4997126 69.2744439,93.3820668 69.2744439,128.499251 L69.2744439,128.853681 C69.2744439,165.389508 95.740194,192.499713 134.405883,192.499713 C157.021261,192.499713 174.569144,183.658345 187.274444,172.913762 L187.274444,143.592063 L161.473253,143.592063 L161.473253,160.665621 Z"
      fill={fill}
    />
    <polygon
      fill={fill}
      points="135.274444 143.499713 161.274444 143.499713 161.274444 117.499713 135.274444 117.499713"
    />
  </>
);
