import type { ValueFormatterParams } from 'ag-grid-community';
import type { BigSource } from 'big.js';
import { isNil } from 'lodash';
import { toBigWithDefault } from '../../../utils';
import { AG_GRID_OPTION_GROUP_KEY } from '../../AgGrid/Option/AgGridOptionGroupCell';
import { AG_GRID_OPTION_STRIKE_PRICE_KEY } from '../../AgGrid/Option/AgGridOptionStrikePrice';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';
import { numericColumnComparator } from './utils';
export const STRIKE_CLASSNAME = 'option_strike';

export const optionStrike: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  cellClass: STRIKE_CLASSNAME,
  headerClass: STRIKE_CLASSNAME,
  comparator: numericColumnComparator,
  cellRenderer: AG_GRID_OPTION_STRIKE_PRICE_KEY,
  valueFormatter: ({ value }: ValueFormatterParams<unknown, BigSource | undefined>) => {
    if (isNil(value)) {
      return '';
    }
    return toBigWithDefault(value, 0).toFixed(0);
  },
  sort: 'asc',
});

export const optionSide: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  children: column.children,
});

export const optionGroup: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  cellRenderer: AG_GRID_OPTION_GROUP_KEY,
  rowGroup: true,
});
