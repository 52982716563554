import { useSelect } from 'downshift';
import { memo, useEffect, type ReactNode } from 'react';
import { defineMessages } from 'react-intl';
import { usePopper } from 'react-popper';
import type { StyledComponentAllProps } from '../../../utils/types';
import { Button, type ButtonProps } from '../../Button';
import { IconName } from '../../Icons';
import { FormattedMessage } from '../../Intl';
import { DropdownFilterWrapper, DropdownMenu, MenuItem } from './styles';

const messages = defineMessages({
  notFound: {
    defaultMessage: 'Not found',
    id: 'Filters.DropdownFilter.notFound',
  },
});

const DropdownFilterRaw = <T,>({
  options,
  onChange,
  selection,
  getKey,
  renderItem,
  renderSelectedItem = renderItem,
  buttonProps = { endIcon: IconName.ChevronDown },
  menuProps,
  itemProps,
  wrapperProps,
}: DropdownFilterProps<T>) => {
  const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    items: options,
    selectedItem: selection,
    onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem!),
  });
  const toggleButtonProps = getToggleButtonProps(buttonProps);
  const menuElementProps = getMenuProps({ ...menuProps });
  const dropdownPopper = usePopper(toggleButtonProps.ref?.current, null, {
    placement: 'bottom',
  });
  const { update } = dropdownPopper;
  useEffect(() => {
    if (isOpen && update) {
      update();
    }
  }, [isOpen, update]);

  return (
    <DropdownFilterWrapper {...wrapperProps}>
      <Button endIcon={IconName.ChevronDown} {...toggleButtonProps}>
        {renderSelectedItem(selection)}
      </Button>
      <DropdownMenu {...menuElementProps} isOpen={isOpen} style={dropdownPopper.styles.popper}>
        {isOpen && (
          <>
            {options.map((item, index) => (
              <MenuItem
                highlighted={highlightedIndex === index}
                key={getKey(item)}
                {...getItemProps({ item, index })}
                {...itemProps}
              >
                {renderItem(item)}
              </MenuItem>
            ))}
            {(!options || options.length === 0) && (
              <MenuItem highlighted={false}>
                <FormattedMessage {...messages.notFound} />
              </MenuItem>
            )}
          </>
        )}
      </DropdownMenu>
    </DropdownFilterWrapper>
  );
};

export const DropdownFilter = memo(DropdownFilterRaw) as typeof DropdownFilterRaw;

export type DropdownFilterProps<T> = {
  options: T[];
  selection: T;
  getKey: (obj: T) => string;
  // Render function for menu items
  renderItem: (obj: T) => ReactNode;
  // Render function for selected item, defaults to renderItem
  renderSelectedItem: (obj: T) => ReactNode;
  onChange: (obj: T) => void;
  buttonProps?: ButtonProps;
  menuProps?: Omit<StyledComponentAllProps<typeof DropdownMenu>, 'isOpen'>;
  itemProps?: Omit<StyledComponentAllProps<typeof MenuItem>, 'highlighted'>;
  wrapperProps?: StyledComponentAllProps<typeof DropdownFilterWrapper>;
};
