import type { InputHTMLAttributes } from 'react';
import { RadioButtonBullet, RadioButtonLabel, RadioButton as RadioButtonStyle, RadiobuttonWrapper } from './styles';

import { v4 as uuid } from 'uuid';

export function RadioButton({
  id,
  checked,
  disabled,
  name,
  size,
  children,
  onChange,
  ...props
}: InputHTMLAttributes<HTMLInputElement>) {
  const forId = `${id ? id : name}${uuid()}`;
  return (
    <RadiobuttonWrapper disabled={disabled} checked={checked} data-testid={props['data-testid']}>
      <RadioButtonStyle disabled={disabled} checked={checked} size={size}>
        <input checked={checked} disabled={disabled} id={forId} name={name} type="radio" onChange={onChange} />
        <div />
        <RadioButtonBullet active={checked} size={size} />
      </RadioButtonStyle>
      <RadioButtonLabel htmlFor={forId} checked={checked} size={size}>
        {children}
      </RadioButtonLabel>
    </RadiobuttonWrapper>
  );
}

RadioButton.defaultProps = {
  isDisabled: false,
};
