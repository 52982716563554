import type { ICellRendererParams } from 'ag-grid-community';
import Big from 'big.js';
import { useMemo } from 'react';
import { useUnderlyingPriceContext } from '../../../contexts';
import { useCurrency } from '../../../hooks';
import { toBig } from '../../../utils';
import { Box, VStack } from '../../Core';
import { InlineFormattedNumber, NumberVariants } from '../../FormattedNumber';
import type { AgGridOptionsData } from './types';

export type GridOptionsPriceQuoteParams = {
  currency: string | undefined;
  type: 'Bid' | 'Offer' | undefined;
  quoteCurrency: string | undefined;
  underlyingQuoteCurrency: string | undefined;
  underlyingPrice: string | undefined;
  expiration: string | undefined;
  highlight?: boolean;
  markPrice?: string;
};

export const AG_GRID_OPTION_PRICE_QUOTE_KEY = 'optionPriceQuote';

export function AgGridOptionPriceQuote(params: ICellRendererParams<AgGridOptionsData> & GridOptionsPriceQuoteParams) {
  const {
    currency: baseCurrency,
    quoteCurrency,
    type,
    underlyingQuoteCurrency = 'USD',
    underlyingPrice,
    expiration,
    highlight = false,
    markPrice,
  } = params;

  // note sure if this is supposed to be baseCurrency === quoteCurrency
  const isPriceInQuote = baseCurrency && quoteCurrency && baseCurrency !== quoteCurrency;
  const variant = type === 'Bid' ? NumberVariants.Positive : type === 'Offer' ? NumberVariants.Negative : undefined;
  const price = params.getValue?.();
  const priceCurrency = isPriceInQuote ? quoteCurrency : baseCurrency;

  const underlyingQuoteCurrencyIncrement = useCurrency(underlyingQuoteCurrency)?.DefaultIncrement ?? '0.01';
  const priceIncrement = useCurrency(priceCurrency)?.DefaultIncrement;

  const { underlyingAmountRecord } = useUnderlyingPriceContext();

  const shouldHighlight = useMemo(() => {
    if (!highlight || !price || !markPrice) {
      return false;
    }
    return type === 'Bid' ? toBig(price)?.gt(markPrice) : toBig(price)?.lt(markPrice);
  }, [highlight, type, price, markPrice]);

  if (!price) {
    return null;
  }

  const underlying = (expiration && underlyingAmountRecord?.[expiration]?.value) || underlyingPrice;
  const underlyingAmount = underlying ? Big(price).mul(underlying) : null;

  return (
    <VStack justifyContent="center" alignItems="flex-end" data-highlight={shouldHighlight}>
      <InlineFormattedNumber
        highlightAll
        variant={variant}
        number={price}
        currency={priceCurrency}
        increment={priceIncrement}
      />

      {isPriceInQuote ? null : underlyingAmount ? (
        <InlineFormattedNumber
          variant={NumberVariants.Muted}
          number={underlyingAmount}
          currency={underlyingQuoteCurrency}
          increment={underlyingQuoteCurrencyIncrement}
          round
        />
      ) : (
        <Box h="spacingComfortable" />
      )}
    </VStack>
  );
}
