import { useWsBlotterTable, type UseWsBlotterTableProps, type WebsocketRequest } from '../BlotterTable';
import { EntityAdminPage } from './EntityAdminPage';
import type { EntityPageClass, EntityPageRecord } from './types';
import { useEntityAdminPage, type useEntityAdminPageProps } from './useEntityAdminPage';

export interface EntityAdminPageWSProps<T extends EntityPageRecord> extends useEntityAdminPageProps<T> {
  /** The initial request for the Websocket API endpoint. */
  wsRequest: UseWsBlotterTableProps<any, T>['initialRequest'];
}

export const EntityAdminPageWS = <T extends EntityPageRecord>({ wsRequest, ...props }: EntityAdminPageWSProps<T>) => {
  const { blotterTableProps, ...entityAdminPage } = useEntityAdminPage<T>(props);

  const blotterTableWS = useWsBlotterTable<WebsocketRequest, EntityPageClass<T>>({
    initialRequest: wsRequest,
    ...blotterTableProps,
  });

  return <EntityAdminPage<T> blotterTable={blotterTableWS} {...entityAdminPage} />;
};
