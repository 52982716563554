import type { RollupMembership } from '../../types/RollupMembership';
import type { SubAccount } from '../../types/SubAccount';
import { SubAccountTypeEnum } from '../../types/types';
import { EMPTY_ARRAY } from '../../utils/empty';
import type { Node, Tree } from './types';

export function createTreeFromRollupMemberships(
  membershipsByParent: Map<number, Map<number, RollupMembership>>,
  membershipsByChildren: Map<number, Map<number, RollupMembership>>,
  subAccounts: Map<number, SubAccount>
) {
  const tree: Tree<SubAccount> = {
    rootIds: [],
    nodes: new Map(),
  };
  for (const [parentID, children] of membershipsByParent.entries()) {
    // if parent does not appear as child then it is a root
    if (!membershipsByChildren.has(parentID)) {
      tree.rootIds.push(parentID.toString());
    }
    const subAccount = subAccounts.get(parentID);
    if (!subAccount) {
      continue;
    }
    const newNode: Node<SubAccount> = {
      name: subAccount.DisplayName,
      id: parentID.toString(),
      children: Array.from(children.keys()).map(id => id.toString()),
      parents: membershipsByChildren.has(parentID)
        ? Array.from(membershipsByChildren.get(parentID)!.keys()).map(id => id.toString())
        : null,
      item: subAccount,
    };
    tree.nodes.set(parentID.toString(), newNode);
    for (const [childID] of children.entries()) {
      //if child is not parent then populate it as leaf node with no children
      if (!membershipsByParent.has(childID)) {
        const childAccount = subAccounts.get(childID);
        if (!childAccount) {
          continue;
        }
        const leafNode: Node<SubAccount> = {
          name: childAccount.DisplayName,
          id: childID.toString(),
          children: EMPTY_ARRAY,
          parents: membershipsByChildren.has(childID)
            ? Array.from(membershipsByChildren.get(childID)!.keys()).map(id => id.toString())
            : null,
          item: childAccount,
        };
        tree.nodes.set(childID.toString(), leafNode);
      }
    }
  }
  return tree;
}

export function getSubAccountsForNode(tree: Tree<SubAccount>, subAccount: SubAccount) {
  const members: Map<string, SubAccount> = new Map();
  const pushMembers = (id: string) => {
    const node = tree.nodes.get(id);
    if (node) {
      if (node.item && node.item.Type === SubAccountTypeEnum.Book) {
        members.set(node.id, node.item);
      }
      for (const child of node.children) {
        pushMembers(child);
      }
    }
  };
  pushMembers(subAccount.SubaccountID.toString());
  return Array.from(members.values());
}
