import { memo, useCallback, useMemo, useState } from 'react';
import { filter, map, scan, shareReplay } from 'rxjs/operators';
import { CurrencyConversionContext, useHomeCurrencyContext, type CurrencyConversionContextProps } from '../contexts';
import { useConstant, useObservable, useSubscription } from '../hooks';
import { wsScanToMap } from '../pipes/wsScanToMap';
import { CURRENCY_CONVERSION } from '../tokens';
import type { CurrencyConversionRate } from '../types/CurrencyConversionRate';
import type { CurrencyConversionRequest } from '../types/CurrencyConversionRequest';

export const CurrencyConversionProvider = memo(function CurrencyConversionsProvider(
  props: React.PropsWithChildren<unknown>
) {
  // Constant tracking latest set of rates per currency pair
  const [rates] = useState(new Map<string, CurrencyConversionRate>());

  // Constant tracking currencies per EquivalentCurrency
  const [requestMap] = useState(new Map<string, CurrencyConversionRequest>());

  // Constant tracking active CurrencyConversion requests
  const [requests, setRequests] = useState<CurrencyConversionRequest[]>([]);

  // Tracks number of listeners per EquivalentCurrency and Currency
  const listeners = useConstant(new Map<string, Map<string, number>>());
  const { data: subscription } = useSubscription<CurrencyConversionRate>(requests);
  const { homeCurrency } = useHomeCurrencyContext();

  const currencyRatesByCurrencyPair = useObservable(
    () =>
      subscription?.pipe(
        scan((memo, json) => {
          for (const d of json.data) {
            memo.set(`${d.Asset}-${d.EquivalentCurrency}`, d);
          }
          return memo;
        }, rates),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [subscription, rates]
  );

  const homeCurrencyRatesByCurrency = useObservable(
    () =>
      subscription?.pipe(
        map(json => ({ ...json, data: json?.data?.filter(d => d.EquivalentCurrency === homeCurrency) })),
        filter(json => json?.data?.length > 0),
        wsScanToMap({ getUniqueKey: d => d.Asset, newMapEachUpdate: false }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [subscription, homeCurrency]
  );

  const registerSubscription = useCallback(
    (currency?: string, equivalentCurrency?: string) => {
      if (!(currency && equivalentCurrency)) {
        return;
      }
      // Get listeners for EquivalentCurrency
      let currencyListeners = new Map<string, number>();
      if (listeners.has(equivalentCurrency)) {
        currencyListeners = listeners.get(equivalentCurrency)!;
      } else {
        listeners.set(equivalentCurrency, currencyListeners);
      }

      // Get number of listeners for this Currency
      const count = currencyListeners.get(currency);
      if (count) {
        // Increment listeners by 1 if there are existing listeners
        currencyListeners.set(currency, count + 1);
      } else {
        // Set listeners to 1 if there are no existing listeners
        currencyListeners.set(currency, 1);
        const currencies = Array.from(currencyListeners.keys());

        // Create a new request that includes the EquivalentCurrency and the list of Currencies
        const request: CurrencyConversionRequest = {
          name: CURRENCY_CONVERSION,
          Currencies: currencies,
          tag: 'CurrencyConversionProvider',
          EquivalentCurrency: equivalentCurrency,
        };
        // Add new request to requestMap
        requestMap.set(equivalentCurrency, request);
        // Update list of requests to include the latest addition
        setRequests(Array.from(requestMap.values()));
      }
    },
    [listeners, requestMap]
  );

  const registerSubscriptions = useCallback(
    (currencies: string[], equivalentCurrency?: string) =>
      currencies.forEach(currency => registerSubscription(currency, equivalentCurrency)),
    [registerSubscription]
  );

  const unregisterSubscription = useCallback(
    (currency?: string, equivalentCurrency?: string) => {
      if (!(currency && equivalentCurrency)) {
        return;
      }

      // Get listeners for EquivalentCurrency
      const currencyListeners = listeners.get(equivalentCurrency);
      if (!currencyListeners?.has(currency)) {
        return;
      }

      const count = currencyListeners.get(currency)! - 1;
      // Update the number of listeners for this Currency
      currencyListeners.set(currency, count);
      if (count <= 0) {
        currencyListeners.delete(currency);
        const currencies = Array.from(currencyListeners.keys());
        // Check if other listeners use the EquivalentCurrency
        if (currencies.length > 0) {
          // Update request to exclude currency
          const request: CurrencyConversionRequest = {
            name: CURRENCY_CONVERSION,
            Currencies: currencies,
            tag: 'CurrencyConversionProvider',
            EquivalentCurrency: equivalentCurrency,
          };
          requestMap.set(equivalentCurrency, request);
        } else {
          // Remove request from requestMap if there are no more listeners
          requestMap.delete(equivalentCurrency);
        }
        setRequests(Array.from(requestMap.values()));
      }
      if (currencyListeners.size === 0) {
        listeners.delete(equivalentCurrency);
      }
    },
    [listeners, requestMap]
  );

  const unregisterSubscriptions = useCallback(
    (currencies: string[], equivalentCurrency?: string) =>
      currencies.forEach(currency => unregisterSubscription(currency, equivalentCurrency)),
    [unregisterSubscription]
  );

  const value = useMemo<CurrencyConversionContextProps>(
    () => ({
      currencyRatesByCurrencyPair,
      homeCurrencyRatesByCurrency,
      registerSubscription,
      registerSubscriptions,
      unregisterSubscription,
      unregisterSubscriptions,
    }),
    [
      currencyRatesByCurrencyPair,
      homeCurrencyRatesByCurrency,
      registerSubscription,
      unregisterSubscription,
      registerSubscriptions,
      unregisterSubscriptions,
    ]
  );

  return <CurrencyConversionContext.Provider value={value}>{props.children}</CurrencyConversionContext.Provider>;
});
