import { useEffect, useRef, useState, type KeyboardEvent } from 'react';
import styled from 'styled-components';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import { IconButton } from '../Button';
import { FormControlSizes } from '../Form/types';
import { IconName } from '../Icons';
import { TabEditorWrapper } from './styles';

export interface TabEditorProps {
  initialValue?: string;
  onSubmit(text: string): void;
  onCancel(): void;
}

const selectElementContent = (element: Node) => {
  const range = document.createRange();
  const selection = window.getSelection();
  range.selectNodeContents(element);
  range.collapse(false);
  selection?.removeAllRanges();
  selection?.addRange(range);
};

export const TabEditor = styled(({ initialValue = '', onSubmit, onCancel, ...props }: TabEditorProps) => {
  const value = useRef(initialValue);
  const [disableSubmit, setDisableSumbit] = useState(true);

  const ref = useRef(null);
  useOnClickOutside(ref, onCancel);

  const inputRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (inputRef.current != null) {
      inputRef.current.innerText = value.current;
      inputRef.current.focus();
      // Find closet wrapper and scroll into view
      inputRef.current?.closest("[role='button']")?.scrollIntoView({
        behavior: 'smooth',
        // We don't want any changes to the vertical scroll of the window
        block: 'nearest',
      });
      setDisableSumbit(!value.current);
      selectElementContent(inputRef.current);
    } else {
      throw new Error(`Tried to set innerText without inputRef in the DOM. This should never occur.`);
    }
  }, []);

  const handleSubmit = () => {
    if (inputRef.current != null && inputRef.current.innerText !== '') {
      onSubmit(inputRef.current?.innerText);
    }
  };

  const handleKeyUp = () => {
    setDisableSumbit(!inputRef.current?.innerText);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLSpanElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
      return false;
    }
    if (e.key === 'Escape') {
      e.preventDefault();
      onCancel();
      return false;
    }
  };

  return (
    <TabEditorWrapper role="tab" ref={ref} {...props}>
      <span
        data-testid="tab-name-editor"
        ref={inputRef}
        contentEditable={true}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
      />
      <IconButton icon={IconName.Check} disabled={disableSubmit} onClick={handleSubmit} size={FormControlSizes.Small} />
    </TabEditorWrapper>
  );
})``;
