import type { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { Z_INDEX, modeColor, parseColor, parseDimension } from '../../styles';
import { box, type BoxProps } from '../Core';
import { FormControlSizes } from '../Form/types';
import { TableSize, type ColProps, type TableProps } from './types';

export interface TableCellProps {
  align?: string;
  width?: string;
  minimize?: boolean;
  maximize?: boolean;
  pinned?: 'left' | 'right' | 'top';
  verticalAlign?: CSSProperties['verticalAlign'];
}

const cell = css<TableCellProps & BoxProps>`
  position: relative;

  ${({ verticalAlign }) => verticalAlign && `vertical-align: ${verticalAlign}`};
  ${({ align }) => align && `text-align: ${align}`};

  ${({ width }) => width && `width: ${width}`}

  ${({ minimize }) =>
    minimize &&
    `
    width: 1%;
    padding: 0;
    white-space: nowrap;
  `};

  ${({ maxWidth }) => maxWidth && `width: 100%`};

  ${props =>
    props.align === 'left' &&
    `
    &:first-child {
      padding-left: 0;
    }
  `}
  ${props =>
    props.align === 'right' &&
    `
    &:last-child {
      padding-right: 0;
    }
  `}

  ${({ pinned, theme }) =>
    pinned &&
    `
    position: sticky;
    background: ${theme.backgroundContent};
    z-index: ${Z_INDEX.blotters};
    ${pinned}: 0;
  `}

  min-height: ${({ theme }) => `${theme.spacingSmall + theme.baseSize * FormControlSizes.Small}px`};
`;

export const Tr = styled.tr<BoxProps & { clickable?: boolean }>`
  &&& {
    ${box};
  }
  ${({ background, theme, style }) => {
    return `background: ${
      background ? (typeof background === 'string' ? parseColor(theme, background) : background) : style?.background
    }`;
  }}
  ${({ theme, clickable }) =>
    clickable &&
    `
    transition: background 150ms;
    &:hover {
      cursor: pointer;
      background: ${theme.backgroundBlotterTableRowHover}
  `};
`;

export const Th = styled.th<TableCellProps & BoxProps>`
  &&& {
    // Hack to make sure box styles are prioritized
    ${box}
  }
  ${cell};
`;

export const Td = styled.td<
  TableCellProps &
    BoxProps & {
      pinned?: 'left' | 'right';
    }
>`
  &&& {
    // Hack to make sure box styles are prioritized
    ${box}
  }
  ${cell};
  color: ${({ theme }) => theme.colorTextImportant};
`;

export const Thead = styled.thead<BoxProps>`
  ${box};
  ${Th} {
    font-weight: ${({ theme }) => theme.fontWeightRegular};
    color: var(--colors-gray-090);
    font-size: ${({ theme }) => theme.fontSizeSmall}rem;
    text-transform: uppercase;
  }
`;

export const StickyThead = styled(Thead)`
  position: sticky;
  top: 0;
  background: ${({ theme }) => theme.backgroundContent};
  z-index: ${Z_INDEX.adminBlotterHeader};
`;

export const Tbody = styled.tbody`
  ${Th} {
    font-weight: ${({ theme }) => theme.fontWeightRegular};
    color: var(--colors-gray-080);
  }
`;

export const Tfoot = styled.tfoot``;

const tableStyle = css<TableProps>`
  ${Td},
  ${Th} {
    height: ${({ rowHeight }) => rowHeight};
    padding: ${({ theme }) => theme.spacingSmall}px;
    ${({ theme, size }) =>
      size === TableSize.Large &&
      css`
        padding: ${theme.spacingDefault}px ${theme.spacingComfortable}px;
      `};
    ${({ theme, size }) =>
      size === TableSize.Small &&
      css`
        font-size: ${theme.fontSizeSmall}rem;
        padding: ${theme.spacingTiny}px;
      `};
    ${({ theme, bordered }) =>
      bordered &&
      `
      border-bottom: 1px solid ${modeColor(theme, 'black.mute', 'white.mute')};
    `};
  }

  ${({ theme, insetPadding }) =>
    insetPadding &&
    css`
      td:first-child,
      th:first-child {
        padding-left: ${parseDimension(theme, insetPadding)};
      }
    `}

  ${({ theme, insetPadding }) =>
    insetPadding &&
    css`
      td:last-child,
      th:last-child {
        padding-right: ${parseDimension(theme, insetPadding)};
      }
    `}
`;

export const Table = styled.table<TableProps>`
  ${box};
  text-align: left;
  border-collapse: collapse;
  position: relative;

  ${({ maxWidth }) => maxWidth && `width: 100%`};

  ${({ zebra }) =>
    zebra &&
    css`
      ${Tr}:nth-child(even) ${Td},
		  ${Tr}:nth-child(even) ${Th} {
        background: ${({ theme }) => theme.backgroundContent};
      }
    `};

  ${tableStyle};
`;

export const Col = styled.col<ColProps>`
  background: ${({ background, theme }) => parseColor(theme, background)};
`;
