import { useTheme } from 'styled-components';
import { ReconStateEnum, ReconStateEnumLabels } from '../../types';
import { Icon, IconName, type IconProps } from '../Icons';
import { IndicatorDotVariants, IndicatorDotWrapper } from '../IndicatorDot';
import { Wrapper } from './styles';

export const ReconMismatchStatusIcon = ({
  status,
  ...iconProps
}: { status: ReconStateEnum } & Omit<IconProps, 'icon' | 'color'>) => {
  const theme = useTheme();
  switch (status) {
    case ReconStateEnum.PendingReview:
      return <Icon icon={IconName.Clock} color={theme.colors.blue.lighten} {...iconProps} />;
    case ReconStateEnum.AutoResolved:
    case ReconStateEnum.ManualResolved:
    case ReconStateEnum.Ignored:
      return <Icon icon={IconName.CheckCircleSolid} color={theme.colorTextPositive} {...iconProps} />;

    default:
      return <Icon icon={IconName.Circle} color={theme.colors.blue.lighten} {...iconProps} />;
  }
};

interface ReconMismatchStatusProps {
  status: ReconStateEnum;
  align?: 'left' | 'right';
  text?: string;
}

export function ReconMismatchStatus({ status, align = 'left', text }: ReconMismatchStatusProps) {
  const theme = useTheme();
  return (
    <Wrapper iconPlacement="left" align={align} theme={theme}>
      {ReconStateEnumLabels[status]}
      <IndicatorDotWrapper show={!!text} theme={theme} variant={IndicatorDotVariants.Warning}>
        <ReconMismatchStatusIcon status={status} size="fontSizeLarge" />
      </IndicatorDotWrapper>
    </Wrapper>
  );
}
