import { createContext, useCallback, useContext, useEffect, useState } from 'react';

export interface RecentSymbolsContextProps {
  recentSymbols: Record<string, number[]>;
  setRecentSymbols: (value: Record<string, number[]>) => void;
}
export const RecentSymbolsContext = createContext<RecentSymbolsContextProps | undefined>(undefined);
RecentSymbolsContext.displayName = 'RecentSymbolsContext';
export const useRecentSymbols = () => {
  const recentSymbolsContext = useContext(RecentSymbolsContext);

  const recentSymbols = recentSymbolsContext?.recentSymbols;
  const setRecentSymbols = recentSymbolsContext?.setRecentSymbols;

  const [recentSymbolsList, setRecentSymbolsList] = useState<string[]>([]);

  const updateRecentSymbols = useCallback(
    (symbol: string) => {
      if (symbol != null && recentSymbols != null && setRecentSymbols != null) {
        const lastMonth = Sugar.Date.create('last month').getTime();
        const timestampOfNewSelection = Date.now();

        // Is this legacy backwards compatability code here, handling both arrs and individual numbers? Leaving as is for now for safety reasons
        recentSymbols[symbol] = Array.isArray(recentSymbols[symbol])
          ? [...recentSymbols[symbol], timestampOfNewSelection]
          : [timestampOfNewSelection];

        // Remove usage from previous months
        for (const symbol in recentSymbols) {
          recentSymbols[symbol] = recentSymbols[symbol].filter(ts => ts > lastMonth);
          if (recentSymbols[symbol].length < 1) {
            delete recentSymbols[symbol];
          }
        }
        setRecentSymbols({ ...recentSymbols });
      }
    },
    [recentSymbols, setRecentSymbols]
  );

  useEffect(() => {
    if (recentSymbols) {
      setRecentSymbolsList(
        Object.keys(recentSymbols).sort((a, b) => recentSymbols[b].length - recentSymbols[a].length)
      );
    }
  }, [recentSymbols]);

  return {
    recentSymbolsList,
    updateRecentSymbols,
  };
};
