import { useEffect, useMemo } from 'react';
import { useSecuritiesContext } from '../../../contexts';
import { useWLTradableCustomerMarketAccounts } from '../../../hooks';
import { useWLCustomerMarketAccountContext } from '../../../providers/WLCustomerMarketAccountsProvider';
import type { Security, WLOrderStrategy } from '../../../types';
import { BufferedDispatcher, EMPTY_ARRAY } from '../../../utils';
import { useWLOrderFormDispatch, useWLOrderFormSelector } from '../WLOrderFormStore';
import { setMarketAccounts, setSecurities, setStrategiesBySymbol } from './OMSReferenceDataSlice/reducer';
import { setReferenceData as setOrderSliceReferenceData } from './OrderSlice/reducer';

import type { RootState } from './types';

export const useOMSDependencies = ({
  getStrategiesGivenSecurity,
}: {
  getStrategiesGivenSecurity: (s: Security) => WLOrderStrategy[];
}): void => {
  const dispatch = useWLOrderFormDispatch();
  const dispatcher = useMemo(() => new BufferedDispatcher(dispatch), [dispatch]);

  const { securitiesBySymbol, securitiesList } = useSecuritiesContext();

  useEffect(() => {
    dispatcher.dispatchImmediate(
      setSecurities({
        securitiesList,
        securitiesBySymbol,
      })
    );
  }, [dispatcher, securitiesList, securitiesBySymbol]);

  const {
    customerMarketAccountsList: allCustomerMarketAccounts = EMPTY_ARRAY,
    customerMarketAccountsBySourceAccountID,
  } = useWLCustomerMarketAccountContext();

  const tradableMarketAccountsSet = useWLTradableCustomerMarketAccounts();

  const tradableMarketAccounts = useMemo(() => {
    if (!tradableMarketAccountsSet) {
      return allCustomerMarketAccounts;
    }
    return allCustomerMarketAccounts.filter(account => tradableMarketAccountsSet.has(account.SourceAccountID));
  }, [allCustomerMarketAccounts, tradableMarketAccountsSet]);

  const strategiesBySymbol = useMemo(() => {
    const strategiesBySymbol = new Map<string, WLOrderStrategy[]>();
    for (const security of securitiesList) {
      strategiesBySymbol.set(security.Symbol, getStrategiesGivenSecurity(security));
    }
    return strategiesBySymbol;
  }, [getStrategiesGivenSecurity, securitiesList]);

  useEffect(() => {
    dispatcher.dispatchImmediate(setStrategiesBySymbol(strategiesBySymbol));
  }, [strategiesBySymbol, dispatcher]);

  useEffect(() => {
    if (tradableMarketAccounts && customerMarketAccountsBySourceAccountID) {
      dispatcher.dispatchImmediate(
        setMarketAccounts({
          customerMarketAccountsList: tradableMarketAccounts,
          customerMarketAccountsBySourceAccountID: new Map(customerMarketAccountsBySourceAccountID),
        })
      );
    }
  }, [dispatcher, tradableMarketAccounts, customerMarketAccountsBySourceAccountID]);

  // Sharing references
  const omsReferenceDataState = useWLOrderFormSelector((state: RootState) => state.referenceData);
  useEffect(() => {
    dispatch(setOrderSliceReferenceData(omsReferenceDataState));
  }, [dispatch, omsReferenceDataState]);
};
