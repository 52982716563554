import type { PropsWithChildren } from 'react';
import { defineMessages } from 'react-intl';
import styled from 'styled-components';
import { useMixpanel } from '../../contexts';
import { MixpanelEvent, MixpanelEventProperty } from '../../tokens';
import { HStack } from '../Core';
import { FormControlSizes, Toggle } from '../Form';
import { IconName } from '../Icons';
import { FormattedMessage } from '../Intl';
import { useSidebarContext } from './SidebarContext';
import { SidebarItem, type SidebarItemProps } from './SidebarItem';

const messages = defineMessages({
  pinSidebar: {
    id: 'Sidebar.pinSidebar',
    defaultMessage: 'Pin Sidebar',
  },
});

type SidebarPinToggleProps = PropsWithChildren<Omit<SidebarItemProps, 'icon' | 'onClick'>> & {
  onChange?: (pinned: boolean) => void;
};

export const SidebarPinToggle = styled(function SidebarPinToggle({
  children,
  onChange,
  ...props
}: SidebarPinToggleProps) {
  const { isPinned, setPinned } = useSidebarContext();
  const mixpanel = useMixpanel();
  const handleClick = () => {
    const newPinnedState = !isPinned;
    setPinned(newPinnedState);
    onChange && onChange(newPinnedState);
  };

  return (
    <SidebarItem onClick={handleClick} icon={isPinned ? IconName.SidebarPinned : IconName.SidebarUnpinned} {...props}>
      <HStack justifyContent="space-between" w="100%" overflow="hidden">
        <FormattedMessage {...messages.pinSidebar} />
        <Toggle
          onChange={() => {
            mixpanel.track(MixpanelEvent.PinSidebar, {
              [MixpanelEventProperty.Enabled]: isPinned,
            });
          }}
          checked={isPinned}
          size={FormControlSizes.Small}
        />
      </HStack>
    </SidebarItem>
  );
})``;
