import type { IAggFuncParams, ICellRendererParams } from 'ag-grid-community';
import { WARNING_SEVERITY_TO_TEXT, WarningSeverity } from '../../../types';
import type { AgGridWarningProps } from '../../AgGrid/types';
import { PMSRowWarnings } from '../../PMSRowWarnings';
import type { ColDefFactory, Column } from './types';
import { warning } from './warning';
import type { WarningColumnValue } from './warning.types';

export interface PMSWarningColumnValue extends WarningColumnValue {
  warnings: string[];
}
function isPMSWarning(data: unknown): data is PMSWarningColumnValue {
  return (data as PMSWarningColumnValue)?.warnings != null;
}

/** The PMS grids provide a given list of warnings associated with severity - this aggregates these into a tooltip */
export const pmsWarnings: ColDefFactory<Column> = column => ({
  ...warning(column),
  colId: 'pmsWarnings',
  cellRendererParams: (params: ICellRendererParams<unknown, WarningColumnValue | undefined>): AgGridWarningProps => {
    if (isPMSWarning(params.value)) {
      return {
        ...params,
        tooltip: <PMSRowWarnings warningData={params.value} />,
      };
    }

    return {
      ...params,
      value: undefined,
    };
  },
  aggFunc: !column.aggregate
    ? undefined
    : ({
        values,
        rowNode,
      }: IAggFuncParams<unknown, PMSWarningColumnValue | undefined>): PMSWarningColumnValue | undefined => {
        // Collect all warnings and calc the highest severity to show on grouping
        const aggregateResult = values.reduce(
          (result, next) => {
            if (next == null || next.severity == null) {
              return result;
            }

            next.warnings.forEach(() => {
              result.messageBySeverityMap[next.severity] = (result.messageBySeverityMap[next.severity] ?? 0) + 1;
              result.severity = Math.max(result.severity, next.severity);
            });

            return result;
          },
          { messageBySeverityMap: {}, severity: WarningSeverity.NONE } as {
            messageBySeverityMap: { [P in WarningSeverity]: number };
            severity: WarningSeverity;
          }
        );

        const allMessages = Object.entries(aggregateResult.messageBySeverityMap)
          // sort highest to lowest
          .sort(([a], [b]) => Number(b) - Number(a))
          .reduce((acc, [key, value]) => {
            if (Number(key) > 0) {
              acc.push(`${value} ${WARNING_SEVERITY_TO_TEXT[key]} warning${value > 1 ? 's' : ''}`);
            }
            return acc;
          }, [] as string[]);
        return allMessages.length === 0
          ? undefined
          : {
              severity: aggregateResult.severity,
              nodeId: rowNode?.id ?? '',
              warnings: allMessages,
            };
      },
});
