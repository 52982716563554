import { Tooltip } from 'components/Tooltip';
import { useDynamicCallback } from 'hooks/useDynamicCallback';
import { debounce, isEqual } from 'lodash';
import RCSlider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { useEffect, useMemo, useState } from 'react';
import styled, { useTheme } from 'styled-components';

export interface SliderProps {
  value: number | number[];
  handleChange: (value: number | number[]) => void;
  useRange?: boolean;
  min?: number;
  max?: number;
  reverse?: boolean;
  disabled?: boolean;
}
export const Wrapper = styled(RCSlider)`
  &.rc-slider-disabled {
    background-color: unset;
  }
  .rc-slider-handle-dragging {
    box-shadow: none !important;
  }
  .rc-slider-handle[aria-valuenow='0'] {
    margin-left: 7px;
  }
  &.reverse .rc-slider-handle {
    margin-right: -7px;
  }

  .rc-slider-handle::after {
    content: '||';
    font-size: 10px;
    position: absolute;
    left: 3px;
    color: ${({ theme }) => theme.colorTextPrimaryButton};
  }
`;

export const handleRender = (node, props) => {
  return <Tooltip tooltip={props.value} children={node} placement="top" trigger="hover" />;
};

// TODO: this is an extremely basic first pass needed to unblock the multileg payoff charts
// further work is needed for example to support various sizes, states, etc.
export const Slider = (props: SliderProps) => {
  const theme = useTheme();
  const { value, useRange, min, max, reverse, disabled } = props;
  const [stateValue, setStateValue] = useState(value);
  const [isChanging, setIsChanging] = useState(false);

  const debouncedHandleChange = useMemo(() => debounce(props.handleChange, 20), [props.handleChange]);

  useEffect(() => {
    if (isChanging) {
      return;
    }
    if (value instanceof Array && !isEqual(value, stateValue)) {
      setStateValue(value);
    } else if (value !== stateValue) {
      setStateValue(value);
    }
  }, [value, stateValue, isChanging]);

  const handleChange = useDynamicCallback((value: number | number[]) => {
    if (!isChanging) {
      setIsChanging(true);
    }
    setStateValue(value);
    debouncedHandleChange(value);
  });

  return (
    <>
      <Wrapper
        className={reverse && stateValue === max ? 'reverse' : ''}
        range={useRange}
        allowCross={false}
        onChange={handleChange}
        onChangeComplete={() => setIsChanging(false)}
        value={stateValue}
        min={min}
        reverse={reverse}
        max={max}
        disabled={disabled}
        handleRender={handleRender}
        railStyle={{ background: theme.colors.gray['040'] }}
        trackStyle={{ background: theme.backgroundPrimaryButton }}
        handleStyle={{
          borderRadius: theme.borderRadiusButtonDefault,
          opacity: 1,
          border: `1px solid ${theme.colors.gray['040']}`,
          background: theme.colors.gray['030'],
        }}
      />
    </>
  );
};
