import { defineMessages } from 'react-intl';
import { HStack } from '../Core';
import { IconName } from '../Icons';
import { FormattedMessage } from '../Intl';
import { ThemeToggle, type ThemeToggleProps } from '../ThemeToggle';
import { SidebarItem } from './SidebarItem';

const messages = defineMessages({
  themeType: {
    id: 'Sidebar.themeType',
    defaultMessage: 'Theme Type',
  },
});

/**
 * A single component theme selector for the sidebar.
 */
export function SidebarThemeToggle({ themeType, onChangeThemeType }: ThemeToggleProps): React.ReactElement {
  return (
    <SidebarItem icon={IconName.ColorSwatch}>
      <HStack justifyContent="space-between" w="100%" overflow="hidden" gap="spacingComfortable">
        <FormattedMessage {...messages.themeType} />
        <ThemeToggle themeType={themeType} onChangeThemeType={onChangeThemeType} />
      </HStack>
    </SidebarItem>
  );
}
