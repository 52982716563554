import { defineMessages } from 'react-intl';
import { Flex } from '../../../Core';
import { Icon } from '../../../Icons';
import { FormattedMessage } from '../../../Intl';
import { Text } from '../../../Text';
import { ActionableText, DropdownLabelContainer } from '../styles';
import type { FilterableProperty } from '../types';

const messages = defineMessages({
  clearAll: {
    defaultMessage: 'Clear all',
    id: 'Filters.FilterBuilder.RHS.clearAll',
  },
  selectAll: {
    defaultMessage: 'Select all',
    id: 'Filters.FilterBuilder.RHS.selectAll',
  },
});

export interface DropdownResultsHeaderProps<T = string> {
  property: FilterableProperty<string, T>;
  showClearAll: boolean;
  showSelectAll?: boolean;
  onClearAll?: () => void;
  onSelectAll?: () => void;
}

export const DropdownResultsHeader = <TData,>({
  property,
  showClearAll,
  onClearAll,
  showSelectAll,
  onSelectAll,
}: DropdownResultsHeaderProps<TData>) => {
  return (
    <DropdownLabelContainer>
      <Flex alignItems="center" gap="spacingSmall">
        <Icon icon={property.icon} />
        <Text size="fontSizeTiny">{property.label}</Text>
      </Flex>
      {showClearAll && (
        <ActionableText size="fontSizeTiny" onClick={onClearAll}>
          <FormattedMessage {...messages.clearAll} />
        </ActionableText>
      )}
      {showSelectAll && (
        <ActionableText size="fontSizeTiny" onClick={onSelectAll}>
          <FormattedMessage {...messages.selectAll} />
        </ActionableText>
      )}
    </DropdownLabelContainer>
  );
};
