import styled from 'styled-components';
import { VStack } from '../Core';
import { ItemTrack } from '../Timeline/styles';

export const ItemsWrapper = styled.div`
  height: 100%;
  margin-left: ${({ theme }) => -theme.spacingDefault}px;
`;

export const ItemWrapper = styled.div`
  &.last-item {
    ${ItemTrack} {
      &:before {
        background: transparent;
      }
    }
  }
`;

export const EmptyDataWrapper = styled(VStack)`
  flex: 1;
  width: 100%;
  min-height: 165px;
`;
