import { ParameterTypeEnum } from './OrderStrategy';
import { PresenceEnum } from './types';

export const ManualStrategy = {
  Name: 'Manual',
  Group: 'All',
  Parameters: [
    {
      Name: 'LimitPrice',
      Type: ParameterTypeEnum.Price,
      Presence: PresenceEnum.Required,
      Description: 'Limit Price for the order.',
      DisplayName: 'Limit Price',
    },
  ],
  Description: 'Manual order',
  DisplayName: 'Manual',
};
