import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { base } from './BaseTheme';
import { lightColors, mapLightTheme } from './LightTheme';

const baseSize = base.baseSize;
const colors: Colors = {
  white: lightColors.white,
  black: lightColors.black,
  orange: lightColors.orange,
  purple: lightColors.purple,

  gray: {
    main: '#FFFFF8',

    '000': '#E3E3E2',
    '010': '#F0F0ED',
    '020': '#F8F8F4',
    '030': '#E9E9E6',
    '040': '#DADAD8',
    '050': '#D0D0CF',
    '060': '#C6C6C6',
    '070': '#BABABB',
    '080': '#9A9A9C',
    '090': '#68686E',
    '100': '#1F1F28',
  },

  primary: {
    mute: setAlpha(0.1, '#7D8CA3'),
    dim: setAlpha(0.4, '#7D8CA3'),
    default: '#7D8CA3',
    lighten: '#91A0B7',
  },

  green: {
    mute: setAlpha(0.1, '#219774'),
    dim: setAlpha(0.4, '#219774'),
    default: '#219774',
    lighten: '#3FB592',
  },

  red: {
    mute: setAlpha(0.1, '#D54254'),
    dim: setAlpha(0.4, '#D54254'),
    default: '#D54254',
    lighten: '#E95668',
  },

  yellow: {
    mute: setAlpha(0.1, '#C89200'),
    dim: setAlpha(0.4, '#C89200'),
    default: '#C89200',
    lighten: '#E3A600',
  },

  blue: {
    mute: setAlpha(0.1, '#338EEB'),
    dim: setAlpha(0.4, '#338EEB'),
    default: '#338EEB',
    lighten: '#4AA4FF',
  },
};

export const LightPxTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightPx',

  borderRadiusContent: baseSize,
  borderRadiusMedium: baseSize * 0.4,
  borderRadiusDefault: baseSize * 0.3,
  borderRadiusSmall: baseSize * 0.2,
  borderRadiusTiny: baseSize * 0.1,
  spacingLayout: baseSize * 0.5,
  borderRadiusButtonLarge: baseSize * 0.75,
  borderRadiusButtonDefault: baseSize * 0.5,
  borderRadiusButtonSmall: baseSize * 0.25,
  borderRadiusButtonTiny: baseSize * 0.1,

  colorTextPositiveButton: colors.white.default,
  colorTextPositiveButtonHover: colors.white.default,
  colorTextPositiveButtonActive: colors.white.default,
  colorTextPositiveButtonDisabled: setAlpha(0.7, colors.white.default),
  colorTextPositiveButtonFocus: colors.white.default,

  colorTextNegativeButton: colors.white.default,
  colorTextNegativeButtonHover: colors.white.default,
  colorTextNegativeButtonActive: colors.white.default,
  colorTextNegativeButtonDisabled: setAlpha(0.7, colors.white.default),
  colorTextNegativeButtonFocus: colors.white.default,

  colorTextPrimaryButton: colors.white.default,
  colorTextPrimaryButtonHover: colors.white.default,
  colorTextPrimaryButtonActive: colors.white.default,
  colorTextPrimaryButtonDisabled: setAlpha(0.7, colors.white.default),
  colorTextPrimaryButtonFocus: colors.white.default,

  backgroundDrawer: colors.gray.main,
  backgroundSidebar: colors.gray.main,
  colorTabActive: colors.gray.main,
});
