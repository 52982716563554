/**
 *
 * @param a An ISO 8601 string timestamp
 * @param b An ISO 8601 string timestamp
 * @returns -1 if a is earlier than b, 0 if they are equal, and 1 if a is later in time than b.
 */
export function compareTimestampsWithMicrosecondPrecision(a: string, b: string) {
  const unixTsA = isoTimestampToMicrosecondUnixTs(a);
  const unixTsB = isoTimestampToMicrosecondUnixTs(b);

  const diff = unixTsA - unixTsB;
  if (diff < 0) {
    return -1;
  }
  if (diff > 0) {
    return 1;
  }
  return 0;
}

/**
 * Given an iso 8601 timestamp, returns a unix timestamp with microsecond precision as a number.
 * Works with between 0 and 6 decimal points given. If more than 6 decimal points are given, only millisecond precision is returned.
 */
export function isoTimestampToMicrosecondUnixTs(timestamp: string) {
  // Example: 2023-06-14T12:15:54.875117Z
  const microsecondUnixTs = new Date(timestamp).getTime() * 1000;

  const decimalPointSplit = timestamp.split('.');
  if (decimalPointSplit.length !== 2) {
    return microsecondUnixTs;
  }

  const decimalsWithZ = decimalPointSplit[1];
  const decimals = decimalsWithZ.replace('Z', '');

  if (decimals.length <= 3 || decimals.length > 6) {
    // There are no microseconds if length is less than or equal to 3. Or if we're over 6 just don't bother for now
    return microsecondUnixTs;
  }

  const microsecondsString = decimals.substring(3, decimals.length);

  // If there's only 1 or 2 digits in the microsecond string, they represent 100 and 10 microseconds
  // We need to multiply by 10^(missing digits) in order to protect against the backend sending not-6-digit decimals
  try {
    const microseconds = parseInt(microsecondsString) * Math.pow(10, 3 - microsecondsString.length);
    return microsecondUnixTs + microseconds;
  } catch (e) {
    return microsecondUnixTs;
  }
}
