import type { MarketFeeModeEnum, ModeEnum } from './types';

export enum PricingTierTypeEnum {
  AllInTier = 'AllInTier',
  FeeTier = 'FeeTier',
  SymbolGroup = 'SymbolGroup',
}

export interface AllInTier {
  Fee?: string;
  FeeMode?: MarketFeeModeEnum;
  FeeCurrency?: string;
  PricingAggregation?: string;
  Revision: number;
  Spread?: string;
  Tier: string;
  UpdateUser: string;
  MinMarketDataThrottle?: string;
  BidSpread?: string;
  OfferSpread?: string;
  Symbol?: string;
  QuoteTTL?: string;
  RFQTTL?: string;
  AcceptPriceLeniency?: string;
  SalesCommission?: string;
}

export interface FeeTier {
  Fee?: string;
  FeeCurrency?: string;
  FeeMode?: MarketFeeModeEnum;
  Revision: number;
  Tier: string;
  UpdateUser: string;
  Symbol?: string;
}

export interface SymbolGroup {
  Tier: string;
  Revision: number;
  UpdateUser: string;
  Mode: ModeEnum;
  Symbol?: string;
}
