import { immerable } from 'immer';
import { isEqual } from 'lodash';
import {
  DateTimeDurationPickerValueType,
  calcDateFromDuration,
  emptyDuration,
  isDuration,
  type DateTimeDurationPickerValue,
  type Duration,
} from '../components/DateTimeDurationPicker';
import { formattedDate, formattedDateForSubscription, parseDate } from '../utils/date';
import {
  BaseField,
  FieldValidationLevel,
  FieldValidationType,
  type FieldData,
  type FieldValidationResult,
  type FieldValidationRule,
} from './BaseField';
import { fieldsMessages } from './messages';

export function absoluteDateValue(
  paramValue: string | Date | Duration | null | undefined,
  relativeTo: Date | null
): Date {
  if (typeof paramValue === 'string') {
    return parseDate(paramValue);
  }
  if (typeof paramValue === 'undefined') {
    return parseDate(relativeTo);
  }
  if (isDuration(paramValue)) {
    return calcDateFromDuration(paramValue, relativeTo);
  }
  return parseDate(paramValue);
}

export interface DateFieldData extends FieldData<string> {
  dateTimeDuration?: DateTimeDurationPickerValue;
  relativeDate?: Date | Duration | null;
}

export class DateField extends BaseField<DateFieldData> {
  constructor(initial?: Partial<DateFieldData>) {
    super({
      name: 'DateField',
      value: undefined,
      isRequired: true,
      placeholder: 'Type here',
      isTouched: false,
      isDisabled: false,
      isVisible: true,
      errors: [],
      dateTimeDuration: undefined,
      ...initial,
    });
  }

  public override get hasValue(): boolean {
    return (this.data.value != null && this.data.value !== '') || this.data.dateTimeDuration != null;
  }

  public override get displayValue(): string {
    return this.value ? formattedDate(this.value) : '';
  }

  public get dateTimeDuration(): DateTimeDurationPickerValue | undefined {
    return this.data.dateTimeDuration;
  }

  public get value(): string | undefined {
    if (this.data.dateTimeDuration?.type === DateTimeDurationPickerValueType.DateTime) {
      return formattedDateForSubscription(this.data.dateTimeDuration.value);
    }

    if (
      this.data.dateTimeDuration?.type === DateTimeDurationPickerValueType.Duration &&
      !isEqual(this.data.dateTimeDuration.value, emptyDuration)
    ) {
      const relativeTo = this.data.relativeDate ? absoluteDateValue(this.data.relativeDate, parseDate()) : parseDate();
      return formattedDateForSubscription(calcDateFromDuration(this.data.dateTimeDuration.value, relativeTo));
    }

    return formattedDateForSubscription(this.data.value);
  }

  public setRelativeDate(relativeDate: DateTimeDurationPickerValue) {
    const updatedData = {
      relativeDate: relativeDate.value,
    };

    const updated = this.updateData(updatedData);
    return updated.invariantCheck();
  }

  public get relativeTo(): Date | undefined | null {
    return absoluteDateValue(this.data.relativeDate, parseDate());
  }

  public override updateValue(
    value: string | DateTimeDurationPickerValue | undefined,
    isSystemOverride = false
  ): DateField {
    const updatedData = {
      value: typeof value === 'string' ? value : undefined,
      dateTimeDuration: typeof value !== 'string' ? value : undefined,
      isTouched: !isSystemOverride,
    };

    const updated = this.updateData(updatedData);
    return updated.invariantCheck();
  }

  public setTouched(isTouched: boolean): DateField {
    const updated = this.updateData({ isTouched });
    return updated.invariantCheck();
  }

  public setIsRequired(isRequired: boolean): DateField {
    const updated = this.updateData({ isRequired });
    return updated.invariantCheck();
  }

  public setIsVisible(isVisible: boolean): DateField {
    const updated = this.updateData({ isVisible });
    return updated.invariantCheck();
  }

  public validate<C>(rules: FieldValidationRule<DateField, C, string>[] = [], context?: C): DateField {
    const checked = this.invariantCheck();
    const errors = checked.data.errors.filter(e => e.type !== FieldValidationType.Rule);

    rules.forEach(rule => {
      const result = rule(this, context);
      if (result) {
        errors.unshift({ ...result, type: FieldValidationType.Rule });
      }
    });

    return this.updateData({ errors });
  }

  public override setDisabled(isDisabled: boolean): DateField {
    return this.updateData({ isDisabled });
  }

  private invariantCheck() {
    const errors: FieldValidationResult[] = this.data.errors.filter(e => e.type === FieldValidationType.Rule);

    if (this.data.isRequired && this.data.value == null && !this.data.dateTimeDuration) {
      errors.push({
        message: fieldsMessages.dataNameIsRequired,
        values: { dataName: this.data.name },
        level: FieldValidationLevel.Error,
      });
    }

    return this.updateData({ errors });
  }

  private updateData(data: Partial<DateFieldData>): DateField {
    const newData = {
      ...this.data,
      ...data,
    };
    return new DateField(newData);
  }
}

DateField[immerable] = true;
