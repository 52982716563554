import type { ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { startOfDay } from 'date-fns';
import type { Position } from '../../../types/Position';
import { formattedExpiry, parseDate } from '../../../utils';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

const NO_EXPIRY_DATE = '-';
export type PositionExpiryValue = string | typeof NO_EXPIRY_DATE;

export const positionExpiry: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  valueGetter: ({ data }: ValueGetterParams<Position>): PositionExpiryValue => {
    const expiry = data?.Expiration;

    if (expiry == null || expiry === '') {
      return NO_EXPIRY_DATE;
    }

    return expiry;
  },
  valueFormatter: ({ value, node, column }: ValueFormatterParams<Position, PositionExpiryValue>): string => {
    const formattedValue = value === NO_EXPIRY_DATE ? NO_EXPIRY_DATE : formattedExpiry(value);
    const isColumnBeingUsedInGrouping = column.isRowGroupActive();

    // A node is pinned if its for example the top total row in the blotter.
    if (node?.isRowPinned()) {
      return '';
    }

    const isNonGroupingColumnLeafNode = !isColumnBeingUsedInGrouping && !node?.group;
    if (isNonGroupingColumnLeafNode || isColumnBeingUsedInGrouping) {
      return formattedValue;
    }

    return '';
  },
  // For filtering off of this column, we normalize all values to start of day.
  filterValueGetter: ({ data, context, ...params }: ValueGetterParams<Position>) => {
    let expiry: string | undefined = undefined;
    if (params.node?.group && params.node.key) {
      // If we're a grouped node then we can grab the grouping value from node.key
      expiry = params.node.key;
    } else {
      expiry = data?.Expiration;
    }

    if (expiry == null || expiry === '') {
      return undefined;
    }

    return startOfDay(parseDate(expiry)).toISOString();
  },
});
