import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { darkColors, mapDarkTheme } from './DarkTheme';

const colors: Colors = {
  black: darkColors.black,

  white: darkColors.white,

  gray: darkColors.gray,

  primary: {
    mute: setAlpha(0.1, '#00818f'),
    dim: setAlpha(0.4, '#00818f'),
    default: '#00818f',
    lighten: '#009dad',
  },

  green: darkColors.green,
  red: darkColors.red,

  yellow: {
    mute: setAlpha(0.1, '#F2D264'),
    dim: setAlpha(0.4, '#F2D264'),
    default: '#F2D264',
    lighten: '#CCAC3E',
  },

  blue: {
    mute: setAlpha(0.1, '#458CC1'),
    dim: setAlpha(0.4, '#458CC1'),
    default: '#458CC1',
    lighten: '#53AFF8',
  },

  orange: darkColors.orange,
  purple: darkColors.purple,
};

export const DarkXeroFTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkXF',
});
