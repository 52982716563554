import styled from 'styled-components';
import { Z_INDEX } from '../../../styles';

export const AccordionRowCell = styled.td<{ body?: boolean }>`
  padding-top: ${({ theme, body }) => (body ? 0 : theme.spacingMedium)}px;
  padding-bottom: ${({ theme, body }) => (body ? 0 : theme.spacingMedium)}px;
`;

export const ContainerRow = styled.tbody<{ clickable: boolean }>`
  transition: 200ms;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray['030']};
  background: ${({ theme }) => theme.backgroundContent};

  &:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.colors.gray['030']};
  }

  ${({ clickable, theme }) =>
    clickable &&
    `
    :hover {
      cursor: pointer;
      background: ${theme.backgroundContentHover};
    }`};
`;

export const AccordionTableHeader = styled.th<{ clickable?: boolean }>`
  padding: ${({ theme }) => theme.spacingMedium}px;
  padding-bottom: ${({ theme }) => theme.spacingDefault}px;
  :not(:first-child) {
    padding-left: 0;
  }

  padding-bottom: ${({ theme }) => theme.spacingMedium}px;

  text-align: left;
  font-weight: ${({ theme }) => theme.fontWeightRegular};
  color: ${({ theme }) => theme.colorTextDefault};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizeBlotterTableHeader}rem;

  ${({ clickable }) =>
    clickable &&
    `
    :hover {
      cursor: pointer;
    }
  `};
`;

export const AccordionTableContent = styled.tbody<{ ghost?: boolean }>`
  background: ${({ theme, ghost }) => (ghost ? 'transparent' : theme.backgroundAccordionContent)};
`;

export const Acc = styled.td`
  padding: 0;
`;

export const Table = styled.table<{ columnSpacing?: number; stickyHeader?: boolean }>`
  border-spacing: 0;
  border-collapse: collapse;

  // [sc-52513] Add Sticky Headers to Dealer Columns
  & ${AccordionTableHeader} {
    ${({ stickyHeader, theme }) =>
      stickyHeader &&
      `
      position: sticky;
      top: 0;
      background: ${theme.backgroundContent};
      z-index: ${Z_INDEX.blotters};
  `};
  }

  & ${AccordionRowCell} {
    padding-left: ${({ theme, columnSpacing }) => (columnSpacing ? columnSpacing : theme.spacingMedium)}px;
    padding-right: ${({ theme, columnSpacing }) => (columnSpacing ? columnSpacing : theme.spacingMedium)}px;

    // First and last child should all have this default spacing at the edges
    :first-child {
      padding-left: ${({ theme }) => theme.spacingMedium}px;
    }

    :last-child {
      padding-right: ${({ theme }) => theme.spacingMedium}px;
    }

    // first child should always have some left padding because it is on the edge,
    :not(:first-child) {
      padding-left: 0px;
    }
  }
`;
