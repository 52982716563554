import { PointerSensor as DndKitPointerSensor } from '@dnd-kit/core';

import { shouldHandleEvent } from './shouldHandleEvent';

export class PointerSensor extends DndKitPointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown' as const,
      handler: ({ nativeEvent: event }: React.PointerEvent) => {
        return shouldHandleEvent(event.target as HTMLElement);
      },
    },
  ];
}
