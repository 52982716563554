import { values } from 'lodash';
import { useMemo } from 'react';

import { defineMessages } from 'react-intl';
import { useIntl, useTranslatedOptionLabel } from '../../../../hooks';
import { SideEnum } from '../../../../types/types';
import { IconName } from '../../../Icons';
import type { FilterableProperty } from '../types';

const messages = defineMessages({
  side: {
    defaultMessage: 'Side',
    id: 'Filters.FilterBuilder.filters.side',
  },
  buy: {
    defaultMessage: 'Buy',
    id: 'Filters.FilterBuilder.filters.buy',
  },
  sell: {
    defaultMessage: 'Sell',
    id: 'Filters.FilterBuilder.filters.sell',
  },
});

/**
 * This hook creates the filter property definition for Side properties.
 *
 * @param overrides Any overrides to make to the defaults here (e.g. `key`, etc.)
 * @returns Filter Property definition to use for Side filters
 */
export function useSidesFilter<TKey extends string = 'Sides', P = FilterableProperty<TKey>>(
  overrides?: { [K in keyof P]: K extends keyof FilterableProperty<TKey> ? P[K] : never } & { key?: TKey }
) {
  const { formatMessage } = useIntl();
  const getOptionLabel = useTranslatedOptionLabel(messages);

  return useMemo(
    () => ({
      ...({
        key: 'Sides' as TKey,
        label: formatMessage(messages.side),
        icon: IconName.ArrowUpDown,
        options: values(SideEnum),
        getOptionLabel,
      } satisfies FilterableProperty<TKey>),
      ...overrides,
    }),
    [formatMessage, getOptionLabel, overrides]
  );
}
