import { isAfter, isBefore } from 'date-fns';
import { formattedDate, formattedDateWithMilliseconds, formattedDay, isValidDateInput } from '../../utils';

export const NOW = 'Now';
export const EOD = 'EOD';
export const SOD = 'SOD';

export const DEFAULT_SHORTCUTS = {
  '5m': '5m',
  '10m': '10m',
  '15m': '15m',
  '30m': '30m',
  '1h': '1h',
  '2h': '2h',
  '4h': '4h',
  '12h': '12h',
  [NOW]: 'Now',
  [EOD]: EOD,
} as const;

export const DEFAULT_DATE_PICKER_EOD = { hours: 17, minutes: 0 } as const;

// Checks the given date against a min accepted date, and if it is in violation, will change it to be that of the min date
export function validateMinDate(date: Date, minValue: Date, showTimePicker?: boolean) {
  if (!isBefore(date, minValue)) {
    return date;
  }

  const newMinValue = new Date(minValue);
  if (!showTimePicker && minValue.getTime() % (24 * 60 * 60 * 1000)) {
    newMinValue.setUTCDate(minValue.getUTCDate() + 1);
    newMinValue.setUTCHours(10, 0, 0);
  }
  return newMinValue;
}

// Checks the given date against a max accepted date, and if it is in violation, will change it to be that of the max date
export function validateMaxDate(date: Date, maxValue: Date, showTimePicker?: boolean) {
  if (!isAfter(date, maxValue)) {
    return date;
  }

  const newMaxValue = new Date(maxValue);
  if (!showTimePicker && maxValue.getTime() % (24 * 60 * 60 * 1000)) {
    newMaxValue.setUTCDate(maxValue.getUTCDate() + 1);
    newMaxValue.setUTCHours(10, 0, 0);
  }
  return newMaxValue;
}

export function getFormatter(showMilliseconds: boolean, showTimePicker: boolean) {
  if (showMilliseconds) {
    return formattedDateWithMilliseconds;
  } else if (showTimePicker) {
    return formattedDate;
  } else {
    return formattedDay;
  }
}

// Determines whether or not the diff between the provided oldDate and newDate are reason enough to emit a new change
export const shouldEmitChange = (oldDate: Date | null, newDate: Date | null): boolean => {
  if (oldDate === newDate) {
    return false;
  }
  if (oldDate === null || newDate === null) {
    return true;
  }
  if (isValidDateInput(oldDate) && isValidDateInput(newDate)) {
    return (oldDate as Date).getTime() !== (newDate as Date).getTime();
  }
  return true;
};
