import { useContext } from 'react';
import { IntlContext } from '../contexts/IntlContext';

export function useIntlContext() {
  const context = useContext(IntlContext);
  if (context === undefined) {
    throw new Error('useIntlContext must be used within an IntlProvider');
  }
  return context;
}
