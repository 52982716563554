import { uniqBy } from 'lodash';
import type React from 'react';
import { createContext, useCallback, useContext, useState } from 'react';
import { v1 as uuid, validate } from 'uuid';
import type { NoteProps } from './types';

export const NOTES_LOCAL_STORAGE_KEY = 'dismissedNotes';

export interface NotesContextProps {
  notes: NoteProps[];
  addNote: (note: NoteProps) => void;
  removeNote: (id: string, closable?: boolean) => void;
  setNotes: (notes: NoteProps[]) => void;
}

const NotesContext = createContext<NotesContextProps | null>(null);
NotesContext.displayName = 'NotesContext';

export function useNotes() {
  const context = useContext(NotesContext);
  if (context == null) {
    throw new Error('Missing NotesContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const NotesProvider = function NotesProvider({
  children,
  initialState = [],
}: React.PropsWithChildren<{
  initialState?: NoteProps[];
}>) {
  const [notes, setNotes] = useState<NoteProps[]>(initialState);

  const getDismissedNotes = useCallback((): string[] => {
    try {
      return JSON.parse(localStorage.getItem(NOTES_LOCAL_STORAGE_KEY) || '[]');
    } catch (e) {
      console.error(e);
      // Someone has messed with localStorage manually...
    }
    return [];
  }, []);

  const setDismissedNotes = useCallback((notes: string[] = []) => {
    return localStorage.setItem(NOTES_LOCAL_STORAGE_KEY, JSON.stringify(notes));
  }, []);

  const addNote = useCallback(
    ({ id = uuid(), text = '', closable = true, ...note }: NoteProps) => {
      if (closable && getDismissedNotes().includes(id)) {
        return;
      }
      setNotes(notes =>
        uniqBy(
          [
            {
              id,
              text,
              closable,
              ...note,
            },
            ...notes,
          ],
          'id'
        )
      );
    },
    [getDismissedNotes]
  );

  const removeNote = useCallback(
    (id, closable = true) => {
      if (closable) {
        const dismissedNotes = getDismissedNotes();
        if (!validate(id)) {
          // Only add the note to the dismissed notes if it's not a UUID
          setDismissedNotes([...dismissedNotes, id]);
        }
      }
      const note = notes.find(note => note.id === id);
      if (note != null && note.onDismiss != null) {
        note.onDismiss();
      }
      setNotes(notes => notes.filter(note => note.id !== id));
    },
    [getDismissedNotes, setDismissedNotes, notes]
  ) satisfies NotesContextProps['removeNote'];

  return <NotesContext.Provider value={{ notes, addNote, removeNote, setNotes }}>{children}</NotesContext.Provider>;
};
