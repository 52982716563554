import type { ITourProps } from './types';
import { useTour } from './useTour';

/**
 * Simple convenient declarative API to start/enqueue tours.
 * Useful when tours should start/enqueue based on some when another component is being rendered.
 * @see useTour
 */
export function Tour({ tour, enqueue, delay }: ITourProps) {
  useTour(tour, true, { enqueue, delay });
  return null;
}
