import type { ICellRendererParams } from 'ag-grid-enterprise';
import Big from 'big.js';
import { InlineFormattedNumber, NumberVariants } from '../FormattedNumber';
import { Highlight } from '../FormattedNumber/styles';
import type { AgGridFormattedNumberProps } from './types';

export function AgGridFormattedNumber({
  sentiment,
  highlightNegative,
  value,
  data,
  unit,
  round,
  increment,
}: ICellRendererParams & AgGridFormattedNumberProps) {
  if (data == null || value == null) {
    return '';
  }

  if (increment == null) {
    return <Highlight>{`${value} ${unit ?? ''}`.trim()}</Highlight>;
  }

  return (
    <InlineFormattedNumber
      round={round}
      number={value}
      increment={increment}
      currency={unit}
      variant={
        sentiment != null ? sentiment : highlightNegative && Big(value).lt(0) ? NumberVariants.Negative : undefined
      }
      background="backgroundBlotterTableRowHover"
    />
  );
}
