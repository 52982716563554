import styled, { css, type DefaultTheme } from 'styled-components';
import { Currency, Highlight, StackedNumber } from '../../FormattedNumber/styles';
import { Loader } from '../../Spinner/styles';
import { Button } from '../Button';
import { ButtonVariants } from '../styles';

function getQuoteButtonTextColor(theme: DefaultTheme, variant?: ButtonVariants) {
  switch (variant) {
    case ButtonVariants.Positive:
      return theme.colorTextPositiveButton;
    case ButtonVariants.Negative:
      return theme.colorTextNegativeButton;
    case ButtonVariants.Primary:
      return theme.colorTextPrimaryButton;
    case ButtonVariants.Default:
    default:
      return theme.colorTextDefaultButton;
  }
}

export const QuoteButton = styled(Button)`
  ${StackedNumber} {
    &:first-child,
    &:last-child {
      opacity: 0.75;
    }
  }

  ${({ theme, variant }) => {
    return css`
      ${Loader} {
        stroke: ${getQuoteButtonTextColor(theme, variant)};
      }
      ${Highlight}, ${Currency}, ${StackedNumber}, & {
        color: ${getQuoteButtonTextColor(theme, variant)};
      }
    `;
  }}
`;
