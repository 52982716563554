import type { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { createContext, memo, useCallback, useContext, useEffect, useState, type PropsWithChildren } from 'react';
import { v1 as uuid } from 'uuid';

import {
  CardViewEnum,
  MarketDataCardState,
  MixpanelEvent,
  MixpanelEventProperty,
  useMixpanel,
  useWLMarketDataCardsConfig,
  useWLOrgConfigContext,
  type MarketDataCardConfig,
  type MarketDataCardsContextProps as SharedMarketDataCardsContextProps,
} from '@talos/kyoko';

export interface MarketDataCardsContextProps extends SharedMarketDataCardsContextProps {
  cards: MarketDataCardConfig[];
  cardsAreDisplayed: boolean;
  cardsAreExpanded: boolean;
  setCardsAreDisplayed: React.Dispatch<React.SetStateAction<boolean>>;
  createCard: (symbol: string) => void;
  moveCard: (e: DragEndEvent) => void;
  setCards: React.Dispatch<React.SetStateAction<MarketDataCardConfig[]>>;
  setIsAddingCard: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MarketDataCardsContext = createContext<MarketDataCardsContextProps | undefined>(undefined);
MarketDataCardsContext.displayName = 'MarketDataCardsContext';

export const useMarketDataCards = () => {
  const context = useContext(MarketDataCardsContext);
  if (context === undefined) {
    throw new Error('Missing MarketDataCardsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};

export const MarketDataCardsProvider = memo((props: PropsWithChildren<unknown>) => {
  const mixpanel = useMixpanel();
  const { config } = useWLOrgConfigContext();
  const { defaultMarketDataCardsCollapsed } = config;

  const [cardFilter, setCardFilter] = useState('');
  const [isAddingCard, setIsAddingCard] = useState(false);
  const [isShowingPlaceholders, setIsShowingPlaceholders] = useState(false);
  const { isLoaded, marketDataCardsConfig, setMarketDataCardsConfig } = useWLMarketDataCardsConfig();
  const [cards, setCards] = useState<MarketDataCardConfig[]>(marketDataCardsConfig ?? []);
  /**
   * This is a slightly-hacky way to allow a downstream component to tell us whether
   * there are actually MarketDataCards being displayed actively on the screen, since not
   * all layouts display them. That way, in the Header component, we can display a
   * button to collapse/expand them all, but only whether that's contextually relevant,
   * e.g. if the cards are actually being rendered currently.
   *
   * TODO: Figure out a better way to handle this, possibly via Portal injection.
   */
  const [cardsAreDisplayed, setCardsAreDisplayed] = useState<boolean>(false);

  // Check JSON config to see default this value initially (if there are no existing cards)
  let cardsAreExpanded = !defaultMarketDataCardsCollapsed;

  // If there *are* existing cards, check their config value, to keep all of these in sync with each other
  // This way, if the site also allows for collapse-/expand-all functionality, we account for that here.
  if (cards.length > 0) {
    cardsAreExpanded = cards.some(card => !!card.detailsExpanded);
  }

  const showPlaceholderCards = useCallback(() => {
    setCardFilter('');
    setIsAddingCard(true);
    setIsShowingPlaceholders(true);
  }, []);

  const hidePlaceholderCards = useCallback(() => {
    setIsAddingCard(false);
    setIsShowingPlaceholders(false);
  }, []);

  const createCard = useCallback(
    (symbol: string) => {
      mixpanel.track(MixpanelEvent.CreateMarketCard);
      setCards(prev => {
        return [
          ...prev,
          {
            id: uuid(),
            activeSlideIndex: 1,
            availableMarketAccounts: [],
            detailsExpanded: cardsAreExpanded,
            selectedMarketAccounts: [],
            selectedMarkets: [],
            availableMarkets: [],
            view: CardViewEnum.COMPACT,
            state: MarketDataCardState.NEW_CARD,
            symbol,
            topOfBookSizeBucket: '1',
            sizeBuckets: [],
            priceIncrement: '1',
          },
        ];
      });
      setIsShowingPlaceholders(false);
    },
    [cardsAreExpanded, mixpanel, setCards]
  );

  const moveCard = useCallback(
    (e: DragEndEvent) => {
      const { active, over } = e;

      if (active.id && over?.id && active.id !== over.id) {
        setCards(prev => {
          const oldIndex = prev.findIndex(item => item.id === active.id);
          const newIndex = prev.findIndex(item => item.id === over.id);

          mixpanel.track(MixpanelEvent.MovedCard, { [MixpanelEventProperty.ColumnIndex]: newIndex });

          return arrayMove(prev, oldIndex, newIndex);
        });
      }
    },
    [mixpanel, setCards]
  );

  const saveCard = useCallback(
    (nextCard: MarketDataCardConfig) => {
      mixpanel.track(MixpanelEvent.UpdateMarketCard);
      const next = cards.map(card => {
        if (card.id === nextCard.id) {
          return {
            ...card,
            ...nextCard,
            state: MarketDataCardState.UPDATED_CARD,
          };
        }
        return card;
      });

      setCards(next);
      setIsAddingCard(false);
    },
    [cards, mixpanel]
  );

  const deleteCard = useCallback(
    (card: MarketDataCardConfig) => {
      mixpanel.track(MixpanelEvent.DeleteCard);
      const next = cards.filter(prevCard => prevCard.id !== card.id);
      setCards(next);
    },
    [mixpanel, cards]
  );

  useEffect(() => {
    if (isLoaded) {
      setCards(marketDataCardsConfig);
    }
  }, [isLoaded, marketDataCardsConfig, setCards]);

  useEffect(() => {
    if (isLoaded && cards !== marketDataCardsConfig) {
      setMarketDataCardsConfig(cards);
    }
  }, [cards, isLoaded, marketDataCardsConfig, setMarketDataCardsConfig]);

  return (
    <MarketDataCardsContext.Provider
      value={{
        cards,
        cardsAreDisplayed,
        cardsAreExpanded,
        setCards,
        setCardsAreDisplayed,
        showPlaceholderCards,
        hidePlaceholderCards,
        createCard,
        moveCard,
        saveCard,
        deleteCard,
        cardFilter,
        setCardFilter,
        isAddingCard,
        setIsAddingCard,
        isShowingPlaceholders,
      }}
    >
      {props.children}
    </MarketDataCardsContext.Provider>
  );
});
