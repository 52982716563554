import { useCallback, useMemo } from 'react';
import { Flex } from '../../Core';
import { Checkbox } from '../Checkbox';
import { AutocompleteDropdown } from './AutocompleteDropdown';
import { FuseAutocompleteResult } from './FuseAutocompleteResult';
import type { AutocompleteDropdownProps } from './types';

export type CheckboxFuseAutocompleteDropdownProps<T> = Omit<
  AutocompleteDropdownProps<T>,
  'renderResult' | 'selectedItem'
> & { selectedItems: T[]; addSelectedItem: (item: T) => void; removeSelectedItem: (item: T) => void };

/**
 * A dropdown implementation where each item in the results list has a checkbox to its left.
 * So this is for multiselect-like components to use.
 */
export const CheckboxFuseAutocompleteDropdown = <T,>({
  addSelectedItem,
  removeSelectedItem,
  selectedItems,
  ...props
}: CheckboxFuseAutocompleteDropdownProps<T>) => {
  // Turn the selected items into a set for fast lookup
  const selectedItemsSet = useMemo(() => {
    return new Set(selectedItems ?? []);
  }, [selectedItems]);

  const handleCheckedChange = useCallback(
    (item: T, newChecked: boolean) => {
      if (newChecked) {
        addSelectedItem(item);
      } else {
        removeSelectedItem(item);
      }
    },
    [addSelectedItem, removeSelectedItem]
  );
  const { isItemDisabled } = props;
  const prefix = (item: T) => (
    <Flex alignItems="center" mr="spacingSmall">
      <Checkbox
        checked={selectedItemsSet && selectedItemsSet.has(item)}
        onChange={e => handleCheckedChange(item, e.target.checked)}
        disabled={isItemDisabled ? isItemDisabled?.(item) : false}
      />
    </Flex>
  );

  return (
    <AutocompleteDropdown
      {...props}
      renderResult={(searchResult, disabled) => FuseAutocompleteResult(searchResult, disabled, prefix)}
    />
  );
};
