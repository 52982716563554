import type { UseComboboxStateChange } from 'downshift';
import { isEqual } from 'lodash';
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useRef, type KeyboardEvent } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useTheme } from 'styled-components';
import { useDynamicCallback } from '../../../hooks/useDynamicCallback';
import { EMPTY_OBJECT } from '../../../utils/empty';
import { AutocompleteDropdown, FuseAutocompleteResult } from '../AutocompleteDropdown';
import type { AutocompleteDropdownProps } from '../AutocompleteDropdown/types';
import { BaseSelect } from '../BaseSelect';
import { useDropdownPopper } from '../Dropdown/useDropdownPopper';
import { Input } from '../Input';
import { FormControlSizes } from '../types';
import { SearchSelectWrapper } from './styles';
import type { SearchSelectProps, SearchSelectRef } from './types';
import { useSearchSelect } from './useSearchSelect';

const messages = defineMessages({
  search: {
    defaultMessage: 'Search...',
    id: 'Form.SearchSelect.search',
  },
});

export function getStringLabel(item: string) {
  return item;
}

/** Returns DisplayName ?? Name */
export function getDisplayNameOrNameLabel(item: { DisplayName?: string; Name: string }) {
  return item.DisplayName ?? item.Name;
}

/** Warning - be careful NOT to confuse this with **SymbolSelector** - this is specifically for generic dropdowns */
const SearchSelectInner = <T,>(props: SearchSelectProps<T>, ref: React.ForwardedRef<SearchSelectRef<T>>) => {
  const { baseSize } = useTheme();
  const {
    id,
    options,
    selection,
    getLabel,
    getDescription,
    onChange,
    onBlur,
    isItemDisabled,
    disabled = false,
    readOnly = false,
    placeholder,
    searchPlaceholder,
    showClear = false,
    initialSortByLabel = true,
    equalityChecker = isEqual,
    matchThreshold,
    prefix,
    suffix,
    size = FormControlSizes.Default,
    invalid,
    touched,
    dropdownContentRef: incomingDropdownContentRef,
    dropdownWidth,
    dropdownSize = props.size || FormControlSizes.Default,
    dropdownPlacement = 'bottom',
    variant,
    portalize,
    autoFocus,
    getGroup,
    maxHeight,
    groupMaxHeight,
    groupSorter,
    RenderGroupHeader,
    renderResult,
    showDropdownSearch = true,
    itemSize = getDropdownItemHeight(baseSize, dropdownSize),
    initialIsOpen,
    selectorStyle,
    fuseDistance,
    showDescriptionInButton,
    gridTemplateColumns,
    additionalSearchKeys,
    showTitle,
    onIsOpenChange,
    dropdownSuffix,
    centered,
    showChevron,
    searchPrefix,
    onClearClick,
    onInputValueChange,
    onStateChange,
    sortFilterOverride,
    scrollSelectionIntoViewIndex,
    customButtonLayout,
    ...restOfProps
  } = props;

  const dropdownReferenceElement = useRef<HTMLLabelElement | null>(null);
  const defaultDropdownContentRef = useRef<HTMLDivElement>(null);
  const dropdownContentRef = incomingDropdownContentRef ?? defaultDropdownContentRef;
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOpenChange = useDynamicCallback((changes: UseComboboxStateChange<T>) => {
    if (changes.isOpen == null) {
      return;
    }

    onIsOpenChange?.(Object.assign(changes, { isOpen: changes.isOpen }));

    if (changes.isOpen && showDropdownSearch) {
      inputRef.current?.focus();
    } else if (!changes.isOpen) {
      dropdownReferenceElement.current?.focus();
    }
  });

  const searchSelect = useSearchSelect({
    id,
    selection,
    items: options,
    onInputValueChange,
    onStateChange,
    inputRef,
    initialSortByLabel,
    onChange,
    getLabel,
    getDescription,
    isItemDisabled,
    matchThreshold,
    fuseDistance,
    getGroup,
    groupSorter,
    itemSize,
    onIsOpenChange: handleOpenChange,
    initialIsOpen,
    additionalSearchKeys,
    sortFilterOverride,
    scrollSelectionIntoViewIndex,
  });
  const { reset, getInputProps, isOpen, openMenu } = searchSelect;
  const { formatMessage } = useIntl();
  useImperativeHandle(
    ref,
    () => {
      return { openMenu };
    },
    [openMenu]
  );

  const dropdownPopper = useDropdownPopper({
    isOpen,
    dropdownWidth,
    dropdownPlacement,
    referenceElement: dropdownReferenceElement.current ?? null,
    dropdownContentRef,
  });

  const handleClearClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      reset();
      e.preventDefault();

      // We allow the implementer to handle the clear click event as they wish.
      if (onClearClick) {
        onClearClick(e);
      } else {
        onChange(undefined);
      }
    },
    [reset, onChange, onClearClick]
  );

  // React 18 timing synchoronizes state changes, so we (sometimes) need to wait a tick for isOpen to get changed
  // - This was needed to certain auto-opening (double) dropdowns worked as expected (https://github.com/talostrading/Ava-UI/pull/6271#discussion_r1667075934)
  // - TODO: this should be rewritten to avoid the in-render ref change, but it fixes this for now
  const isOpenRef = useRef(isOpen);
  if (isOpenRef.current !== isOpen) {
    isOpenRef.current = isOpen;
  }
  const handleSelectClick = useDynamicCallback(() => {
    requestAnimationFrame(() => {
      if (!isOpenRef.current && !readOnly && !disabled) {
        openMenu();
      }
    });
  });

  const handleSelectKeyDown = useCallback(
    (e: KeyboardEvent) => {
      // If the user is focusing the select and starts typing alpha numeric chars, open the dropdown for them
      if (/\b[a-zA-Z0-9]\b/.test(e.key) && !isOpen) {
        handleSelectClick();
      } else if (e.key === 'Enter' || e.key === ' ') {
        // Prevent default here, otherwise the potential space click can cause the search input field to start with a space entered
        e.preventDefault();
        handleSelectClick();
      }
    },
    [handleSelectClick, isOpen]
  );

  const handleDropdownKeyDown = useDynamicCallback((e: KeyboardEvent) => {
    // We have to catch and stop the dropdown key events from propagating and hitting the SearchSelectWrapper
    // onKeyDown event handlers
    if (e.key === 'Enter' || e.key === ' ' || e.key === 'Escape') {
      // escape is included so any drawer parent is not closed
      e.stopPropagation();
    }
  });

  const handleBlur = useDynamicCallback((e: React.FocusEvent<HTMLDivElement, Element>) => {
    // Check if the target focus is being shifted to is either the search select wrapper, is within the wrapper,
    // or is within the dropdown (the dropdown might be portalized so we have to explicitly check that too)
    if (
      e.relatedTarget === inputRef.current ||
      inputRef.current?.contains(e.relatedTarget) ||
      dropdownContentRef.current?.contains(e.relatedTarget)
    ) {
      return;
    }

    onBlur?.();
  });

  const initialRender = useRef(true);
  useEffect(() => {
    if (autoFocus && initialRender.current) {
      openMenu();
      initialRender.current = false;
    }
  }, [autoFocus, openMenu]);

  const defaultRenderResult: AutocompleteDropdownProps<T>['renderResult'] = useCallback((item, disabled) => {
    return FuseAutocompleteResult(item, disabled);
  }, []);

  return (
    <SearchSelectWrapper
      {...restOfProps}
      onKeyDown={handleSelectKeyDown}
      data-value={selection ? getLabel(selection) : ''}
    >
      <BaseSelect
        id={id}
        isDropdownOpened={isOpen}
        value={selection}
        disabled={disabled}
        readOnly={readOnly}
        prefix={prefix}
        size={size}
        invalid={invalid}
        touched={touched}
        autoFocus={autoFocus}
        placeholder={placeholder}
        wrapperRef={dropdownReferenceElement}
        onClick={handleSelectClick}
        getLabel={getLabel}
        variant={variant}
        clearable={showClear}
        onClearClick={handleClearClick}
        suffix={suffix}
        style={selectorStyle}
        customButtonLayout={customButtonLayout}
        getDescription={showDescriptionInButton && getDescription ? getDescription : undefined}
        gridTemplateColumns={gridTemplateColumns}
        showTitle={showTitle}
        centered={centered}
        showChevron={showChevron}
      />
      <AutocompleteDropdown
        {...searchSelect}
        {...dropdownPopper}
        renderResult={renderResult ?? defaultRenderResult}
        RenderGroupHeader={RenderGroupHeader}
        maxHeight={maxHeight}
        groupMaxHeight={groupMaxHeight}
        equalityChecker={equalityChecker}
        portalize={portalize}
        size={dropdownSize}
        onKeyDown={handleDropdownKeyDown}
        suffix={dropdownSuffix}
        childrenAboveResults={
          <Input
            // hide the input field if we dont want to do searching. We still have to render it due to downshift requirements to set the inputRef
            style={showDropdownSearch ? EMPTY_OBJECT : { display: 'none' }}
            size={dropdownSize}
            {...getInputProps({ disabled, ref: inputRef, onBlur: handleBlur })}
            placeholder={searchPlaceholder ?? formatMessage(messages.search)}
            disabled={disabled}
            readOnly={readOnly}
            prefix={searchPrefix}
          />
        }
      />
    </SearchSelectWrapper>
  );
};

/**
 * Dropdown component (using downshiftjs) that allows for searching and selecting items from a list of options.
 * - has support for custom tab-based groups
 *
 * Warning: Be careful NOT to confuse this with **SymbolSelector**, which uses useSearchSelect internally and sounds similar - it's related but not the same */
export const SearchSelect = memo(forwardRef(SearchSelectInner)) as <T>(
  props: SearchSelectProps<T> & { ref?: React.ForwardedRef<SearchSelectRef> }
) => ReturnType<typeof SearchSelectInner>;

/** This function calculates the search select's item size. Its kinda arbitrary so this is being exposed so the logic can be reused in other implementations */
export function getDropdownItemHeight(baseSize: number, dropdownSize: number) {
  return (baseSize * 2.5 * dropdownSize) / 2;
}
