import { hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';

import { darkColors, mapDarkTheme } from './DarkTheme';

export const colors: Colors = {
  black: {
    default: '#000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    '000': hsla(15, 0.09, 0.09, 1),
    '010': hsla(24, 0.09, 0.1, 1),
    main: hsla(15, 0.09, 0.17, 1),
    '020': hsla(20, 0.09, 0.19, 1),

    '030': hsla(20, 0.09, 0.21, 1),
    '040': hsla(20, 0.09, 0.23, 1),
    '050': hsla(20, 0.09, 0.25, 1),
    '060': '#4C423F',
    '070': '#695C57',
    '080': '#938F87',
    '090': '#E3E2E0',
    '100': '#F1F1F0',
  },

  primary: {
    mute: setAlpha(0.1, '#CDA973'),
    dim: setAlpha(0.4, '#CDA973'),
    default: '#CDA973',
    lighten: '#d2b281',
  },

  green: {
    mute: hsla(75, 1, 0.7, 0.1),
    dim: hsla(75, 1, 0.7, 0.4),
    default: '#D8FF64',
    lighten: '#A6DE00',
  },

  red: {
    mute: hsla(6, 1, 0.66, 0.1),
    dim: hsla(6, 1, 0.66, 0.4),
    default: '#FF3620',
    lighten: '#FF6250',
  },

  yellow: {
    mute: hsla(28, 0.92, 0.53, 0.1),
    dim: hsla(28, 0.92, 0.53, 0.4),
    default: hsla(28, 0.92, 0.53, 1),
    lighten: hsla(28, 0.92, 0.65, 1),
  },

  blue: {
    mute: setAlpha(0.1, '#CDA973'),
    dim: setAlpha(0.4, '#CDA973'),
    default: '#CDA973',
    lighten: '#d2b281',
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: darkColors.purple,
};

export const DarkZerocapTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkZC',

  fontUrl: 'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;1,400;1,500&display=swap',
  fontFamily: "'Poppins', sans-serif",

  colorModeCurrencyIcon: 'colorful',

  backgroundBody: 'linear-gradient(225deg, #3E0000 0%, #5B1418 33.35%, #B35540 66.49%, #F68646 100%)',
  backgroundLayoutWrapper: setAlpha(0.3, '#200809'),
  spacingLayoutWrapper: 16,

  backgroundDropdownResults: colors.gray.main,
  backgroundDropdownInput: colors.gray.main,

  backgroundContent: setAlpha(0.4, '#200809'),
  borderColorContent: 'transparent',
  backgroundHeader: setAlpha(0.4, '#200809'),
  borderColorHeader: 'transparent',
  backgroundSidebar: setAlpha(0.8, '#200809'),
  backdropFilterSidebar: 'blur(5px)',
  borderColorSidebar: 'transparent',
  backgroundSidebarItemActive: 'rgba(0, 0, 0, 0.75)',
  backgroundSidebarItemHover: 'rgba(0, 0, 0, 0.95)',

  backgroundContentHover: 'rgba(0, 0, 0, 0.25)',

  borderColorTabSelected: colors.primary.lighten,

  backgroundToggleChecked: colors.primary.lighten,

  backgroundCheckboxChecked: colors.primary.lighten,
  backgroundRadiobuttonChecked: colors.primary.lighten,

  backgroundChart: 'rgba(0, 0, 0, 0)',
  borderColorChartAxis: 'rgba(0, 0, 0, 0.25)',
  borderColorChartGridLine: 'rgba(0, 0, 0, 0.25)',
  borderColorChartCrosshair: 'rgba(0, 0, 0, 0.5)',

  backgroundInput: 'rgba(0, 0, 0, 0.2)',
  borderColorInput: 'rgba(255, 255, 255, 0.2)',
  borderColorInputFocus: colors.primary.lighten,

  borderRadiusButtonDefault: 16,

  backgroundPopover: colors.gray.main,

  borderColorDivider: 'rgba(0, 0, 0, 0.25)',
  borderRadiusContent: 4,
  borderRadiusLayoutWrapper: 4,

  backgroundBlotterTableRow: 'transparent',
  backgroundBlotterTableRowHover: 'rgba(0, 0, 0, 0.25)',
  borderColorBlotterTable: 'rgba(0, 0, 0, 0.25)',

  borderColorTab: 'rgba(0, 0, 0, 0.25)',

  spacingLayout: 16,

  colorTextPositiveButton: colors.gray.main,
  colorTextPositiveButtonHover: colors.gray.main,
  colorTextPositiveButtonFocus: colors.gray.main,
  colorTextPositiveButtonActive: colors.gray.main,
  colorTextPositiveButtonDisabled: colors.gray.main,

  colorTextNegativeButton: colors.gray.main,
  colorTextNegativeButtonHover: colors.gray.main,
  colorTextNegativeButtonFocus: colors.gray.main,
  colorTextNegativeButtonActive: colors.gray.main,
  colorTextNegativeButtonDisabled: colors.gray.main,

  colorTextPrimaryButton: colors.gray.main,
  colorTextPrimaryButtonHover: colors.gray.main,
  colorTextPrimaryButtonFocus: colors.gray.main,
  colorTextPrimaryButtonActive: colors.gray.main,
  colorTextPrimaryButtonDisabled: colors.gray.main,

  backgroundPrimaryButton: colors.primary.lighten,
  backgroundPrimaryButtonHover: colors.primary.default,
  backgroundPrimaryButtonFocus: colors.primary.lighten,
  backgroundPrimaryButtonActive: colors.primary.lighten,

  backgroundNegativeButton: colors.red.lighten,
  backgroundNegativeButtonHover: colors.red.default,
  backgroundNegativeButtonFocus: colors.red.lighten,
  backgroundNegativeButtonActive: colors.red.lighten,

  backgroundPositiveButton: colors.green.default,
  backgroundPositiveButtonHover: colors.green.lighten,
  backgroundPositiveButtonFocus: colors.green.default,
  backgroundPositiveButtonActive: colors.green.default,
});
