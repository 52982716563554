import {
  getHours,
  getMilliseconds,
  getMinutes,
  getSeconds,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
} from 'date-fns';

// Numeric enums for simple ordering logic.
export enum TimePickerPrecision {
  HOUR = 1,
  MINUTE = 2,
  SECOND = 3,
  MILLISECOND = 4,
}

export type PrecisionToolkit = {
  precision: TimePickerPrecision;
  delimiter: string | null;
  formatter: (input: string) => string;
  max: number;
  min: number;
  step: number;
  dateSetter: (date: Date, value: number) => Date;
  valueGetter: (date: Date) => number;
};

export const PRECISION_TOOLKITS: { [key: number]: PrecisionToolkit } = {
  [TimePickerPrecision.HOUR]: {
    precision: TimePickerPrecision.HOUR,
    delimiter: null,
    formatter: input => addLeadingZeroes(input, 2),
    max: 23,
    step: 1,
    min: 0,
    dateSetter: setHours,
    valueGetter: getHours,
  },
  [TimePickerPrecision.MINUTE]: {
    precision: TimePickerPrecision.MINUTE,
    delimiter: ':',
    formatter: input => addLeadingZeroes(input, 2),
    max: 59,
    step: 1,
    min: 0,
    dateSetter: setMinutes,
    valueGetter: getMinutes,
  },
  [TimePickerPrecision.SECOND]: {
    precision: TimePickerPrecision.SECOND,
    delimiter: ':',
    formatter: input => addLeadingZeroes(input, 2),
    max: 59,
    step: 1,
    min: 0,
    dateSetter: setSeconds,
    valueGetter: getSeconds,
  },
  [TimePickerPrecision.MILLISECOND]: {
    precision: TimePickerPrecision.MILLISECOND,
    delimiter: '.',
    formatter: input => addLeadingZeroes(input, 3),
    max: 999,
    step: 1,
    min: 0,
    dateSetter: setMilliseconds,
    valueGetter: getMilliseconds,
  },
};

export const PRECISIONS: TimePickerPrecision[] = Object.keys(PRECISION_TOOLKITS).map(p => +p);

/**
 * Increments the value by the precision's specified step.
 * Loops around to min if max is exceeded.
 * Returns the incremented value.
 * @param value number to be incremented
 * @param precision precision of the number
 */
export const stepPrecisionValue = (value: number, precision: TimePickerPrecision, incOrDec: 'inc' | 'dec'): number => {
  const { max, min, step } = PRECISION_TOOLKITS[precision];
  const stepWithDirection = (incOrDec === 'inc' ? 1 : -1) * step;
  const steppedValue = value + stepWithDirection;

  if (steppedValue > max) {
    return min;
  } else if (steppedValue < min) {
    return max;
  } else {
    return steppedValue;
  }
};

const addLeadingZeroes = (input: string, maxLength: number): string => {
  let output = input;
  while (output.length < maxLength) {
    output = '0' + output;
  }

  return output;
};
