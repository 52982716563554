import { useCallback } from 'react';

import {
  BlotterTable,
  BlotterTableFilters,
  Button,
  FormControlSizes,
  FormattedMessage,
  MixpanelEvent,
  WSBlotterTableMaxRecordsReachedWarning,
  createCSVFileName,
  useMixpanel,
} from '@talos/kyoko';

import { BlotterActions } from '../styles';

import { useOrderHistoryBlotter } from './hooks/useOrderHistoryBlotter';

import { defineMessages } from 'react-intl';
import type { TabbedBlotterProps } from '../types';

export type OrderHistoryBlotterProps = Required<TabbedBlotterProps>;

const messages = defineMessages({
  exportOrders: {
    defaultMessage: 'Export Orders',
    id: 'Blotters.OrderHistoryBlotter.exportOrders',
  },
});

export function OrderHistoryBlotter({
  renderInBackground: hidden,
  loading,
  maxRows,
  onStartLoading,
  onFinishedLoading,
}: OrderHistoryBlotterProps) {
  const mixpanel = useMixpanel();
  const { blotterTable, blotterTableFilterProps, filterBuilderAccordion } = useOrderHistoryBlotter({ maxRows });

  const handleExportOrders = useCallback(() => {
    onStartLoading();
    blotterTable.exportDataAsCSV({
      includeHiddenColumns: true,
      fileName: createCSVFileName({
        name: 'OrderHistory',
      }),
    });
    onFinishedLoading();
    mixpanel.track(MixpanelEvent.ExportOrders);
  }, [blotterTable, mixpanel, onStartLoading, onFinishedLoading]);

  return (
    <>
      {!hidden && (
        <>
          <BlotterTableFilters
            {...filterBuilderAccordion}
            {...blotterTableFilterProps}
            {...blotterTable.blotterTableFiltersProps}
            prefix={
              <BlotterActions>
                <Button size={FormControlSizes.Small} loading={loading} onClick={handleExportOrders}>
                  <FormattedMessage {...messages.exportOrders} />
                </Button>
              </BlotterActions>
            }
          />
        </>
      )}
      <WSBlotterTableMaxRecordsReachedWarning {...blotterTable.paginationLimit} getTimestamp={r => r.Timestamp} />
      <BlotterTable {...blotterTable} />
    </>
  );
}
