import type { ICellRendererParams } from 'ag-grid-enterprise';
import { createRef, useEffect } from 'react';
import { HStack } from '../Core';
import { Icon, IconName } from '../Icons';
import type { WatchListRowData } from './types';

export interface ReorderCellRendererParams extends ICellRendererParams {
  data: WatchListRowData;
}

export const ReorderCellRenderer = function ReorderCellRenderer({
  data,
  registerRowDragger,
}: ReorderCellRendererParams) {
  const draggerRef = createRef<any>();

  useEffect(() => {
    registerRowDragger(draggerRef.current, undefined, data.security.DisplaySymbol, true);
  }, [draggerRef, registerRowDragger, data.security.DisplaySymbol]);

  return (
    <>
      <HStack background="backgroundBlotterTableRowHover" h="calc(100% - 2px)" ref={draggerRef}>
        <Icon icon={IconName.Reorder} size="fontSizeBig" style={{ cursor: 'grab' }} />
      </HStack>
    </>
  );
};
