import { isFilterClause } from '../useFilterBuilder';

const invalidFilterClause = { valid: false, filterClauses: [] } as const;

export const deserializeFilterClauses = (serializedFilterClauses: string) => {
  let maybeFilterClauses: unknown;
  try {
    maybeFilterClauses = JSON.parse(serializedFilterClauses);
  } catch (error) {
    return invalidFilterClause;
  }

  if (!Array.isArray(maybeFilterClauses) || maybeFilterClauses.find(mfc => !isFilterClause(mfc))) {
    return invalidFilterClause;
  }

  return { valid: true, filterClauses: maybeFilterClauses.filter(isFilterClause) } as const;
};
