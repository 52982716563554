import { useEffect } from 'react';
import type { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { useCurrencyConversionContext, useHomeCurrencyContext } from '../contexts';
import type { CurrencyConversionRate } from '../types/CurrencyConversionRate';
import { useObservable, useObservableValue } from './useObservable';

export function useHomeCurrencyRate(symbol?: string): Observable<CurrencyConversionRate | undefined> {
  const { registerSubscription, unregisterSubscription, homeCurrencyRatesByCurrency } = useCurrencyConversionContext();
  const { homeCurrency } = useHomeCurrencyContext();

  useEffect(() => {
    if (symbol) {
      registerSubscription(symbol, homeCurrency);
      return () => {
        unregisterSubscription(symbol, homeCurrency);
      };
    }
  }, [symbol, registerSubscription, unregisterSubscription, homeCurrency]);

  return useObservable(
    () =>
      homeCurrencyRatesByCurrency.pipe(
        map(rates => (symbol ? rates.get(symbol) : undefined)),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [homeCurrencyRatesByCurrency, symbol]
  );
}

export function useHomeCurrencyRates(symbols: string[]): Observable<CurrencyConversionRate[]> {
  const { registerSubscriptions, unregisterSubscriptions, homeCurrencyRatesByCurrency } =
    useCurrencyConversionContext();
  const { homeCurrency } = useHomeCurrencyContext();

  useEffect(() => {
    if (symbols) {
      registerSubscriptions(symbols, homeCurrency);
      return () => {
        unregisterSubscriptions(symbols, homeCurrency);
      };
    }
  }, [symbols, registerSubscriptions, unregisterSubscriptions, homeCurrency]);

  return useObservable(
    () =>
      homeCurrencyRatesByCurrency.pipe(
        map(rates => symbols.map(currency => rates.get(currency)).compact<true>()),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [homeCurrencyRatesByCurrency, symbols]
  );
}

export function useHomeCurrencyRatesValue(symbols: string[]): CurrencyConversionRate[] | undefined {
  const obs = useHomeCurrencyRates(symbols);
  return useObservableValue(() => obs, [obs], []);
}

export function useHomeCurrencyRateValue(symbol?: string): CurrencyConversionRate | undefined {
  const obs = useHomeCurrencyRate(symbol);
  return useObservableValue(() => obs, [obs]);
}
