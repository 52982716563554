import styled from 'styled-components';
import { Box } from '../Core';

export const Wrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const EmptyMessage = styled.div`
  color: ${({ theme }) => theme.colors.gray['100']};
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  font-weight: ${({ theme }) => theme.fontWeightMedium};
  margin-bottom: 40px;
`;
