import {
  Button,
  HStack,
  Text,
  useWLCustomerMarketAccountContext,
  useWLRoleAuth,
  WL_VIEW_FUNDING_SCHEDULE,
} from '@talos/kyoko';

// Funding Schedule only render if the user has read::cfd permissions and there is a MarketAccount that has SourceData.CFDFundingSchedule
export const FundingSchedule = () => {
  const { isAuthorized } = useWLRoleAuth();
  const { customerMarketAccountsList } = useWLCustomerMarketAccountContext();
  // [DEAL-3967] Find the first MarketAccount that has SourceData.CFDFundingSchedule. All configured CFD accounts will have the same CFDFundingSchedule.
  const fundingSchedule = customerMarketAccountsList?.find(
    marketAccount => marketAccount.SourceData?.CFDFundingSchedule != null
  )?.SourceData?.CFDFundingSchedule;
  // [DEAL-3967] If the user has read::cfd permissions, and there is a MarketAccount that has SourceData.CFDFundingSchedule, show the CFDFundingSchedule
  const showFundingSchedule = isAuthorized(WL_VIEW_FUNDING_SCHEDULE) && fundingSchedule != null;

  return showFundingSchedule ? (
    <Button data-testid="funding-schedule">
      <HStack gap="spacingSmall">
        <Text>Funding Schedule:</Text>
        <Text color="colors.blue.lighten">{fundingSchedule}</Text>
      </HStack>
    </Button>
  ) : null;
};
