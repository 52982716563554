import { NavButton } from '@talos/kyoko';
import styled from 'styled-components';

export const LoginWrapper = styled.div`
  width: ${({ theme }) => theme.baseSize * 24}px;
  background: ${({ theme }) => theme.backgroundContent};
  padding: ${({ theme }) => theme.spacingHuge}px;
  align-items: center;
  display: flex;
  flex-direction: column;

  p {
    margin: ${({ theme }) => theme.spacingHuge}px 0 ${({ theme }) => theme.spacingLarge}px;
    line-height: 1.5;
  }

  ${NavButton} {
    width: 100%;
  }
`;
