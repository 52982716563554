import type { ReactNode } from 'react';
import { usePortal } from '../../hooks';
import { EMPTY_ARRAY } from '../../utils';
import {
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  BlotterTable,
  BlotterTableFilters,
  type BlotterTableFiltersProps,
  type BlotterTableProps,
  type Column,
} from '../BlotterTable';
import { Button, ButtonVariants } from '../Button';
import { Box, HStack } from '../Core';
import type { InputsAndDropdownsDrawerOption } from '../Drawer/InputsAndDropdownsDrawer';
import { FormControlSizes } from '../Form';
import { IconName } from '../Icons';
import { Panel, PanelActions, PanelContent, PanelHeader } from '../Panel';
import { Tab, TabList } from '../Tabs';
import { Text } from '../Text';
import { useEntityAdminPageTabsContext } from './EntityAdminPageTabsWrapper';
import type { EntityPageClass, EntityPageRecord } from './types';
import type { HierarchicalColumnProps } from './utils';

export interface EntityAdminPageProps<T extends EntityPageRecord> {
  /** The title of the page. */
  title?: string;

  /** The subtitle of the page. */
  subtitle?: ReactNode;

  /** The name of the entity. */
  entityName?: ReactNode;

  /** The field to use in the API request. */
  entityIDField: keyof T;

  /** The field to use as the Child ID for Tree Data blotters. */
  childIDField?: keyof T;

  /** The panel actions to display alongside the New Entity button. */
  panelActions?: ReactNode[];

  /** The drawer options to display. */
  drawerOptions?: InputsAndDropdownsDrawerOption<T>[];

  /** Function to get the drawer options based on the entity. */
  getEntityDrawerOptions?: (
    entity: T | undefined,
    addingChildEntity?: boolean
  ) => InputsAndDropdownsDrawerOption<T>[] | undefined;

  /** Callback function when columns are ready. */
  onColumnsReady?: (columns: Column<T>[]) => void;

  /** Whether to allow adding new entities. */
  allowAddEntity?: boolean;

  /** Whether to allow editing existing entities. */
  allowEditEntity?: boolean;

  /** Whether to allow deleting entities. */
  allowDeleteEntity?: boolean;

  /** Function to resolve the entity name for the drawer. */
  getEditEntityName?: (entity: T) => T[keyof T];

  /** The hierarchical properties, of tree data structures */
  addChildEntityButtonProps?: HierarchicalColumnProps<T>['buttonProps'];

  /** The blotter table props. */
  blotterTable: BlotterTableProps<EntityPageClass<T>> & { refresh?: (force?: boolean) => void };

  /** The blotter table filters. */
  blotterTableFilters?: BlotterTableFiltersProps;

  /** The selected entity. */
  selectedEntity?: T;

  /** The open entity drawer function. */
  openEntityDrawer: HierarchicalColumnProps<T>['openEntityDrawer'];

  /** Whether adding a child entity. */
  addingChildEntity?: boolean;

  /** Callback function when saving an entity. */
  handleOnSaveEntity: (entity: T) => Promise<T>;

  /** Callback function when deleting an entity. */
  handleOnDeleteEntity: (entity: T) => Promise<T>;

  /** Callback function when updating an entity. */
  handleOnUpdateEntity: (entity: T) => Promise<T>;

  /** Callback function when creating a new entity. */
  handleOnCreateNewEntity: (entity: T) => Promise<T>;

  /** If provided, the key to use for persisting the blotter table. */
  persistKey?: string;
}

export const EntityAdminPage = <T extends EntityPageRecord>({
  drawerOptions: _drawerOptions,
  entityName = 'Entity',
  title = `${entityName} Page Title`,
  subtitle = `${entityName} Page Subtitle`,
  panelActions = EMPTY_ARRAY,
  allowAddEntity = false,
  blotterTable,
  blotterTableFilters,
  openEntityDrawer,
}: EntityAdminPageProps<T>) => {
  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);
  const tabsContext = useEntityAdminPageTabsContext();
  const tabItems = tabsContext?.tabs?.items;

  return (
    <HStack h="100%" w="100%" gap="spacingTiny" overflow="hidden">
      <Panel>
        <PanelHeader alignItems="center" pb={tabItems != null ? undefined : 'spacingMedium'}>
          <Box>
            {title && <h2 data-testid="entity-admin-page-title">{title}</h2>}
            {subtitle && (
              <Box mt="spacingDefault">
                <Text whiteSpace="break-spaces" data-testid="entity-admin-page-subtitle">
                  {subtitle}
                </Text>
              </Box>
            )}
          </Box>
          <PanelActions>
            <Box ref={filtersContainerRef} />
            {panelActions.length > 0 && (
              <HStack data-testid="entity-admin-page-panel-actions" gap="spacingDefault">
                {panelActions.map((inputComponent, index) => (
                  <Box key={index}>{inputComponent}</Box>
                ))}
              </HStack>
            )}
            {allowAddEntity && (
              <Button
                startIcon={IconName.Plus}
                onClick={() => openEntityDrawer(undefined, false)}
                variant={ButtonVariants.Positive}
                data-testid="entity-admin-page-new-entity-button"
                size={FormControlSizes.Small}
              >
                New {entityName}
              </Button>
            )}
          </PanelActions>
        </PanelHeader>
        {tabItems != null && (
          <TabList pl="spacingLarge" mb="spacingDefault">
            {tabItems.map((tabItem, index) => (
              <Tab key={index} label={tabItem.label} />
            ))}
          </TabList>
        )}
        <PanelContent>
          <BlotterTableFilters {...blotterTableFilters} />
          <BlotterTable {...blotterTable} />
        </PanelContent>
      </Panel>
    </HStack>
  );
};
