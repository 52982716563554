import { stringArray } from './stringArray';
import type { ColDefFactory, Column } from './types';

export const marketAccounts: ColDefFactory<Column> = column => ({
  ...stringArray({
    ...column,
    params: {
      ...column.params,
      getDisplayName: (marketAccountName, context) =>
        context.current.marketAccountsByName?.get(marketAccountName)?.DisplayName ?? marketAccountName,
    },
  }),
});
