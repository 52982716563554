import type { PropsWithChildren } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Accordion, AccordionBody, useAccordion } from '../Accordion';
import { Flex, HStack } from '../Core';
import { Icon, IconName } from '../Icons';
import { useSidebarContext } from './SidebarContext';
import { SidebarGroupItem, SidebarGroupWrapper } from './styles';

type SidebarGroupItemProps = PropsWithChildren<{
  title: string;
  icon?: IconName;
  match?: string;
}>;

export const SidebarGroup = styled(function SidebarGroup({ children, match, title, ...props }: SidebarGroupItemProps) {
  const { isExpanded } = useSidebarContext();
  const matches = useRouteMatch(match);
  const isActive = match != null && matches != null;
  const accordion = useAccordion({
    id: title,
    initialOpen: isActive,
  });
  return (
    <SidebarGroupWrapper>
      <Accordion {...accordion}>
        <SidebarGroupItem {...props} onClick={() => accordion.toggle()} isActive={isActive}>
          <HStack justifyContent="space-between" w="100%">
            {isExpanded ? title : null}
            {isExpanded && <Icon icon={accordion.isOpen ? IconName.ChevronUp : IconName.ChevronDown} />}
          </HStack>
        </SidebarGroupItem>
        {isExpanded && (
          <AccordionBody>
            <Flex flexDirection="column" gap="spacingDefault" pt="spacingDefault">
              {children}
            </Flex>
          </AccordionBody>
        )}
      </Accordion>
    </SidebarGroupWrapper>
  );
})``;
