import {
  useAccordionFilterBuilder,
  useConstant,
  usePersistedBlotterTable,
  useWsBlotterTable,
  type Column,
  type CustomerTrade,
  type SideEnum,
  type WebsocketRequest,
} from '@talos/kyoko';
import { useMemo } from 'react';

import { pick } from 'lodash';
import { useTradesFilter, type TradesTableFilter } from '../../../../components/Filters/FilterBuilder';

const COLUMNS: Column[] = [
  { type: 'tradeStatus', field: 'TradeStatus' },
  { type: 'date', field: 'TransactTime', sortable: true },
  { type: 'account', field: 'MarketAccount' },
  { type: 'security', field: 'Symbol' },
  { type: 'side', field: 'Side' },
  { type: 'size', field: 'Quantity', params: { currencyField: 'Currency' } },
  { type: 'currency', field: 'Currency', hide: true },
  { type: 'size', field: 'Amount', params: { currencyField: 'AmountCurrency' } },
  { type: 'currency', field: 'AmountCurrency', hide: true },
  { type: 'price', field: 'Price', params: { assetField: 'Symbol' } },
  { type: 'size', field: 'Fee', hide: true, params: { currencyField: 'FeeCurrency' } },
  { type: 'id', field: 'OrderID' },
  { type: 'id', field: 'TradeID' },
  { type: 'filler', id: 'filler1' },
];

interface TradesWebsocketRequest extends WebsocketRequest {
  MarketAccounts?: string[];
  Sides?: SideEnum[];
  Symbols?: string[];
}

interface UseTradesBlotterProps {
  maxRows: number;
}

const filter = { StartDate: Sugar.Date.create('a year ago') };

const DEFAULT_SORT = '-TransactTime';

export const useTradesBlotter = ({ maxRows }: UseTradesBlotterProps) => {
  const request: TradesWebsocketRequest = useConstant({
    name: 'Trade',
    tag: 'BLOTTER_TRADES',
    Throttle: '1s',
    sort_by: DEFAULT_SORT,
  });

  const persistedBlotterTable = usePersistedBlotterTable(`trades`, {
    columns: COLUMNS,
    sort: DEFAULT_SORT,
    filter,
  });

  const filteredTrades = useTradesFilter({ persistedBlotterTable });
  const { clientSideFilter: clientLocalFilter, blotterTableFilterProps, filterBuilderProps } = filteredTrades;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: false },
    filterBuilderProps,
  });

  const blotterTable = useWsBlotterTable<TradesWebsocketRequest, CustomerTrade>({
    initialRequest: request,
    columns: persistedBlotterTable.columns,
    filter: onlyServerFilterKeys(filteredTrades.filter),
    initialSort: persistedBlotterTable.initialSort,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    onSortChanged: persistedBlotterTable.onSortChanged,
    rowID: 'TradeID',
    clientLocalFilter,
    startingRowLimit: maxRows,
  });

  return useMemo(
    () => ({
      blotterTable,
      blotterTableFilterProps,
      filterBuilderAccordion,
    }),
    [blotterTable, blotterTableFilterProps, filterBuilderAccordion]
  );
};

function onlyServerFilterKeys(filter: TradesTableFilter | undefined) {
  if (!filter) {
    return filter;
  }
  return pick(filter, ['EndDate', 'StartDate', 'MarketAccounts', 'Symbols']);
}
