import type { CustomerBalanceTransaction, UsePersistedBlotterTable } from '@talos/kyoko';
import { useDateAndQuickTextFilter } from './useDateAndQuickTextFilter';

interface UseTransfersFilterParams<TData extends CustomerBalanceTransaction> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
}

export function useTransfersFilter<TData extends CustomerBalanceTransaction>({
  persistedBlotterTable,
}: UseTransfersFilterParams<TData>) {
  return useDateAndQuickTextFilter<TData>({ persistedBlotterTable });
}
