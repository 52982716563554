import type { ReactNode } from 'react';
import { HStack } from '../Core';

export function LeftMiddleRightFlexbox({
  left,
  middle,
  right,
}: {
  left?: ReactNode;
  middle?: ReactNode;
  right?: ReactNode;
}) {
  return (
    <HStack w="100%">
      <HStack flexBasis="40%" justifyContent="flex-start" data-testid="lmr-flexbox-left">
        {left}
      </HStack>
      <HStack flexBasis="20%" justifyContent="center" data-testid="lmr-flexbox-middle">
        {middle}
      </HStack>
      <HStack flexBasis="40%" justifyContent="flex-end" data-testid="lmr-flexbox-right">
        {right}
      </HStack>
    </HStack>
  );
}
