import { hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { lightColors, mapLightTheme } from './LightTheme';

export const colors: Colors = {
  gray: {
    main: '#FFFFFF',
    '000': '#E3E2E0',
    '010': '#F0EFED',
    '020': '#F1F1F0',
    '030': '#EAE9E8',
    '040': hsla(40, 0.05, 0.85, 1),
    '050': hsla(40, 0.05, 0.81, 1),
    '060': '#C8C6C2',
    '070': '#ADABA5',
    '080': '#938F87',
    '090': '#695C57',
    '100': '#2F2927',
  },

  black: {
    default: '#000000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#FFFFFF',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.1)',
  },

  primary: {
    mute: hsla(75, 1, 0.7, 0.1),
    dim: hsla(75, 1, 0.7, 0.4),
    default: '#85B200',
    lighten: '#D8FF64',
  },

  green: {
    // mute: hsla(127, 0.84, 0.22, 0.1),
    // dim: hsla(127, 0.84, 0.22, 0.4),
    // default: hsla(127, 0.84, 0.22, 1),
    // lighten: hsla(127, 0.84, 0.42, 1),
    mute: hsla(75, 1, 0.7, 0.1),
    dim: hsla(75, 1, 0.7, 0.4),
    default: '#D8FF64',
    lighten: '#A6DE00',
  },

  red: {
    mute: hsla(6, 1, 0.66, 0.1),
    dim: hsla(6, 1, 0.66, 0.4),
    default: '#FF3620',
    lighten: '#FF6250',
  },

  yellow: {
    mute: hsla(28, 0.92, 0.53, 0.1),
    dim: hsla(28, 0.92, 0.53, 0.4),
    default: hsla(28, 0.92, 0.53, 1),
    lighten: hsla(28, 0.92, 0.65, 1),
  },

  blue: {
    default: '#458CC1',
    lighten: '#53AFF8',
    dim: 'rgba(69, 140, 193, 0.4)',
    mute: 'rgba(69, 140, 193, 0.1)',
  },

  orange: {
    default: '#E65200',
    lighten: '#FF6B00',
    dim: 'rgba(230, 82, 0, 0.4)',
    mute: 'rgba(230, 82, 0, 0.1)',
  },

  purple: lightColors.purple,
};

export const LightZerocapTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightZC',

  fontUrl: 'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;1,400;1,500&display=swap',
  fontFamily: "'Poppins', sans-serif",

  colorModeCurrencyIcon: 'colorful',

  backgroundBody: 'linear-gradient(225deg, #3E0000 0%, #5B1418 33.35%, #B35540 66.49%, #F68646 100%)',
  backgroundContent: 'rgba(255, 255, 255, 1)',
  borderColorContent: 'transparent',
  backgroundHeader: 'rgba(255, 255, 255, 0.95)',
  borderColorHeader: 'transparent',
  backgroundSidebar: 'rgba(255, 255, 255, 0.85)',
  backdropFilterSidebar: 'blur(5px)',
  borderColorSidebar: 'transparent',
  backgroundSidebarItemActive: 'rgba(255, 255, 255, 0.5)',
  backgroundSidebarItemHover: 'rgba(255, 255, 255, 0.75)',

  backgroundContentHover: 'rgba(255, 255, 255, 0.25)',

  borderColorTabSelected: colors.primary.lighten,

  backgroundToggleChecked: colors.primary.lighten,

  backgroundCheckboxChecked: colors.primary.lighten,
  backgroundRadiobuttonChecked: colors.primary.lighten,

  backgroundChart: 'rgba(0, 0, 0, 0)',
  borderColorChartAxis: 'rgba(0, 0, 0, 0.25)',
  borderColorChartGridLine: 'rgba(0, 0, 0, 0.1)',
  borderColorChartCrosshair: 'rgba(0, 0, 0, 0.5)',

  borderColorInputFocus: colors.primary.lighten,

  borderRadiusButtonDefault: 16,

  backgroundPopover: colors.gray.main,

  borderColorDivider: 'rgba(255, 255, 255, 0.25)',
  borderRadiusContent: 4,

  backgroundBlotterTableRow: 'transparent',
  borderColorBlotterTable: 'rgba(255, 255, 255, 0.25)',

  borderColorTab: 'rgba(255, 255, 255, 0.25)',

  spacingLayout: 8,

  colorTextPositiveButton: colors.gray['100'],
  colorTextPositiveButtonHover: colors.gray['100'],
  colorTextPositiveButtonFocus: colors.gray['100'],
  colorTextPositiveButtonActive: colors.gray['100'],

  colorTextNegativeButton: colors.gray['100'],
  colorTextNegativeButtonHover: colors.gray['100'],
  colorTextNegativeButtonFocus: colors.gray['100'],
  colorTextNegativeButtonActive: colors.gray['100'],

  colorTextPrimaryButton: colors.gray['100'],
  colorTextPrimaryButtonHover: colors.gray['100'],
  colorTextPrimaryButtonFocus: colors.gray['100'],
  colorTextPrimaryButtonActive: colors.gray['100'],

  backgroundPrimaryButton: '#CDA973',
  backgroundPrimaryButtonHover: '#DABF96',
  backgroundPrimaryButtonFocus: '#D3B485',
  backgroundPrimaryButtonActive: '#CDA973',
});
