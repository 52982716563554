import Big from 'big.js';
import { useCallback, type ChangeEvent, type ReactNode } from 'react';
import { useTheme } from 'styled-components';

import type { Currency } from '../../types';
import { daysUntil, formattedDate } from '../../utils';
import { Box, type BoxProps } from '../Core';

import { differenceBy } from 'lodash';
import { Checkbox, type FormControlSizes } from '../../components/Form';
import { InlineFormattedNumber } from '../../components/FormattedNumber';
import { Td, Th } from '../../components/Table';
import { Button, NavButton, ToggleButton, type ButtonVariants } from '../Button';
import { CurrencyIcon } from '../CurrencyIcon';
import type { IconName } from '../Icons';
import { CurrencyLabel, Table, TableHead, TableRow } from './styles';

export enum DataTableColumnTypes {
  Checkbox,
  Currency,
  Date,
  RelativeDateTime,
  DaysUntil,
  Number,
  Percent,
  String,
  Button,
  ToggleButton,
  Custom,
  NavButton,
}

export function DataTable<T>({
  selection,
  columnDefs: columns,
  rowData: rows,
  rowID: idField,
  currenciesMap = new Map(),
  rowHeight,
  disabledCondition,
  onSelectionChange,
  onButtonClick,
  ...props
}: DataTableProps<T>) {
  const handleSelectionChanged = useCallback(
    (selected: boolean, row: T) => {
      const newSelection = selection ?? [];
      if (onSelectionChange != null) {
        if (selected) {
          onSelectionChange([...newSelection, row]);
        } else {
          onSelectionChange(newSelection.filter(l => l[idField] !== row[idField]));
        }
      }
    },
    [selection, idField, onSelectionChange]
  );

  const availableRows = rows.filter(
    r => !columns.find(c => c.type === DataTableColumnTypes.Checkbox)?.disabledCondition?.(r)
  );
  const isAllSelected =
    (selection?.length ?? 0) > 0 && differenceBy(availableRows, selection ?? [], 'Lender' as keyof T).length === 0;
  const isSomeSelected = !isAllSelected && (selection?.length ?? 0) > 0;

  const handleSelectAll = useCallback(() => {
    onSelectionChange && onSelectionChange(isAllSelected ? [] : availableRows);
  }, [isAllSelected, availableRows, onSelectionChange]);

  return (
    <Box {...props}>
      <Table>
        <TableHead>
          <tr>
            {columns.map((column, index) => (
              <Th pinned={column.pinned} key={`${column.field}_${index}`}>
                {column.type === DataTableColumnTypes.Checkbox ? (
                  <Checkbox
                    checked={isAllSelected}
                    disabled={availableRows.length === 0}
                    indeterminate={isSomeSelected}
                    onChange={handleSelectAll}
                  />
                ) : (
                  column.label
                )}
              </Th>
            ))}
          </tr>
        </TableHead>
        <tbody>
          {rows.map((row, index) => {
            const handleCellSelectionChanged = (selected: boolean) => handleSelectionChanged(selected, row);
            const handleButtonCellClick = (action: string) => {
              if (onButtonClick != null) {
                onButtonClick(action, row);
              }
            };
            const selected = (selection ?? []).some(s => s[idField] === row[idField]);
            return (
              <Row<T>
                disabledCondition={disabledCondition}
                key={((row[idField] as any) ?? index).toString()}
                selected={selected}
                row={row}
                idField={idField}
                index={index}
                columns={columns}
                rowHeight={rowHeight}
                currenciesMap={currenciesMap}
                handleCellSelectionChanged={handleCellSelectionChanged}
                handleButtonCellClick={handleButtonCellClick}
              />
            );
          })}
        </tbody>
      </Table>
    </Box>
  );
}

function Row<T>({
  selected,
  row,
  columns,
  currenciesMap,
  rowHeight,
  disabledCondition,
  handleCellSelectionChanged,
  handleButtonCellClick,
}: {
  selected: boolean;
  row: T;
  idField: keyof T;
  index: number;
  rowHeight?: number;
  columns: DataTableColumn<T>[];
  currenciesMap: Map<string, Currency>;
  disabledCondition?: (data: T) => boolean;
  handleCellSelectionChanged: (selected: boolean) => void;
  handleButtonCellClick: (action: string) => void;
}): JSX.Element {
  const isDisabled = disabledCondition != null && disabledCondition(row);
  return (
    <TableRow isDisabled={isDisabled} rowHeight={rowHeight} isSelected={selected}>
      {columns.map((column, i) => (
        <Cell
          key={`${column.field}_${i}`}
          data={row}
          isSelected={selected}
          column={column}
          currenciesMap={currenciesMap}
          onSelectionChange={handleCellSelectionChanged}
          onButtonClick={handleButtonCellClick}
        />
      ))}
    </TableRow>
  );
}

function Cell<T>({ data, column, isSelected, currenciesMap, onSelectionChange, onButtonClick }: CellProps<T>) {
  const handleSelectionChange = useCallback(
    (val: ChangeEvent<HTMLInputElement>) => onSelectionChange != null && onSelectionChange(val.target.checked),
    [onSelectionChange]
  );
  const { colorTextMuted, colorTextImportant } = useTheme();
  const width =
    column.width ??
    (column.type === DataTableColumnTypes.Checkbox ||
    column.type === DataTableColumnTypes.Button ||
    column.type === DataTableColumnTypes.ToggleButton ||
    column.type === DataTableColumnTypes.Number
      ? '1px'
      : 'auto');
  const shouldRender = column.renderCondition == null || column.renderCondition(data);
  const isDisabled = column.disabledCondition != null && column.disabledCondition(data);
  return (
    <Td
      color={isDisabled ? colorTextMuted : colorTextImportant}
      width={width}
      align={column.type === DataTableColumnTypes.Number ? 'right' : 'left'}
      pinned={column.pinned}
    >
      {shouldRender
        ? (() => {
            switch (column.type) {
              case DataTableColumnTypes.Checkbox:
                return <Checkbox checked={isSelected} disabled={isDisabled} onChange={handleSelectionChange} />;
              case DataTableColumnTypes.ToggleButton: {
                const value = column.field && getValue<T>(data, column.field);
                const label = value != null ? value : column.buttonLabel;
                return (
                  <ToggleButton
                    size={column.size}
                    disabled={isDisabled}
                    selected={isSelected}
                    onClick={() => onSelectionChange && onSelectionChange(!isSelected)}
                    selectedVariant={column.variant}
                  >
                    {label}
                  </ToggleButton>
                );
              }
              case DataTableColumnTypes.Number: {
                let increment = column.increment ?? '0.01';
                let unit = column.unit ?? '';
                const currency = column.currencyField != null ? (data[column.currencyField] as unknown as string) : '';
                const currencyInfo = currenciesMap.get(currency ?? '');
                if (currencyInfo != null) {
                  increment = column.increment ?? currencyInfo.DefaultIncrement;
                  unit = column.unit ?? currencyInfo.Symbol;
                }
                const number = getValue<T>(data, column.field);
                return (
                  <InlineFormattedNumber
                    highlightColor="inherit"
                    number={number}
                    currency={unit}
                    increment={increment}
                  />
                );
              }
              case DataTableColumnTypes.Percent: {
                const increment = column.increment ?? '0.01';
                const value = getValue<T>(data, column.field);
                const number = value != null ? Big(value).times(100).toFixed() : undefined;
                return (
                  <InlineFormattedNumber highlightColor="inherit" number={number} currency="%" increment={increment} />
                );
              }
              case DataTableColumnTypes.Date: {
                return formattedDate(getValue<T>(data, column.field));
              }
              case DataTableColumnTypes.DaysUntil: {
                return daysUntil(new Date(Date.parse(getValue<T>(data, column.field))));
              }
              case DataTableColumnTypes.Currency: {
                const currency = currenciesMap.get(getValue<T>(data, column.field));
                return (
                  <>
                    {currency?.Symbol && <CurrencyIcon currency={currency?.Symbol} />}
                    <CurrencyLabel>{currency?.Symbol}</CurrencyLabel>
                  </>
                );
              }
              case DataTableColumnTypes.Button: {
                const value = column.field && getValue<T>(data, column.field);
                const label = value != null ? value : column.buttonLabel;
                return (
                  <Button
                    ghost={column.ghost}
                    size={column.size}
                    disabled={isDisabled}
                    variant={column.variant}
                    startIcon={column.startIcon}
                    onClick={() => onButtonClick != null && onButtonClick(column.action)}
                  >
                    {label}
                  </Button>
                );
              }
              case DataTableColumnTypes.NavButton: {
                const value = column.field && getValue<T>(data, column.field);
                const label = value != null ? value : column.buttonLabel;
                const url = column.urlField ? getValue<T>(data, column.urlField) : column.url;
                const to = url?.includes('//') ? { pathname: url } : url;
                return (
                  <NavButton ghost to={to} size={column.size} disabled={isDisabled} variant={column.variant}>
                    {label}
                  </NavButton>
                );
              }
              case DataTableColumnTypes.Custom: {
                return column.render(data);
              }
              default:
                return getValue<T>(data, column.field);
            }
          })()
        : null}
    </Td>
  );
}

type DataTableProps<T> = {
  rowData: T[];
  selection?: T[];
  columnDefs: DataTableColumn<T>[];
  rowID: keyof T;
  currenciesMap?: Map<string, Currency>;
  rowHeight?: number;
  disabledCondition?: (data: T) => boolean;
  onButtonClick?: (action: string, row: T) => void;
  onSelectionChange?: (selection: T[]) => void;
} & BoxProps;

export type DataTableColumn<T> = DataTableBaseColumn<T> &
  (
    | DataTableStringColumn<T>
    | DataTableNumberColumn<T>
    | DataTableCurrencyColumn<T>
    | DataTableDateColumn<T>
    | DataTableRelativeDateTimeColumn<T>
    | DataTableDaysUntilColumn<T>
    | DataTablePercentColumn<T>
    | DataTableCheckboxColumn<T>
    | DataTableButtonColumn<T>
    | DataTableToggleButtonColumn<T>
    | DataTableCustomColumn<T>
    | DataTableNavButtonColumn<T>
  );

type DataTableBaseColumn<T> = {
  width?: string;
  pinned?: 'right' | 'left';
  renderCondition?: (data: T) => boolean;
  disabledCondition?: (data: T) => boolean;
};

type DataTableStringColumn<T> = {
  label?: ReactNode;
  type: DataTableColumnTypes.String;
  field: keyof T & string;
};

type DataTableDateColumn<T> = {
  label?: ReactNode;
  type: DataTableColumnTypes.Date;
  format?: string;
  field: keyof T & string;
};

type DataTableRelativeDateTimeColumn<T> = {
  label?: ReactNode;
  type: DataTableColumnTypes.RelativeDateTime;
  format?: string;
  field: keyof T & string;
};

type DataTableDaysUntilColumn<T> = {
  label?: ReactNode;
  type: DataTableColumnTypes.DaysUntil;
  format?: string;
  field: keyof T & string;
};

type DataTableCurrencyColumn<T> = {
  label?: ReactNode;
  type: DataTableColumnTypes.Currency;
  field: keyof T & string;
};

type DataTablePercentColumn<T> = {
  label?: ReactNode;
  increment?: string;
  type: DataTableColumnTypes.Percent;
  field: keyof T & string;
};

type DataTableCheckboxColumn<T> = {
  label?: ReactNode;
  type: DataTableColumnTypes.Checkbox;
  field?: keyof T & string;
};

type DataTableToggleButtonColumn<T> = {
  label?: ReactNode;
  buttonLabel?: string;
  action: string;
  type: DataTableColumnTypes.ToggleButton;
  field?: keyof T & string;
  size?: FormControlSizes;
  variant?: ButtonVariants;
};

type DataTableButtonColumn<T> = {
  label?: ReactNode;
  buttonLabel?: string;
  action: string;
  type: DataTableColumnTypes.Button;
  ghost?: boolean;
  startIcon?: IconName;
  endIcon?: IconName;
  field?: keyof T & string;
  size?: FormControlSizes;
  variant?: ButtonVariants;
};

type DataTableNavButtonColumn<T> = {
  label?: ReactNode;
  buttonLabel?: string;
  urlField?: keyof T;
  url?: string;
  type: DataTableColumnTypes.NavButton;
  field?: keyof T & string;
  size?: FormControlSizes;
  variant?: ButtonVariants;
};

type DataTableNumberColumn<T> = {
  label?: ReactNode;
  type: DataTableColumnTypes.Number;
  increment?: string;
  currencyField?: keyof T;
  unit?: string;
  field: keyof T & string;
};

type DataTableCustomColumn<T> = {
  field?: keyof T & string;
  label?: ReactNode;
  type: DataTableColumnTypes.Custom;
  render: (data: T) => ReactNode;
};

type CellProps<T> = {
  data: T;
  column: DataTableColumn<T>;
  isSelected: boolean;
  currenciesMap: Map<string, Currency>;
  onSelectionChange?: (selected: boolean) => void;
  onButtonClick?: (action: string) => void;
};

function getValue<T>(data: T, field: keyof T): string {
  return data[field] as unknown as string;
}
