import { Box, CARD_HEIGHT, CustomerUserConfigTradingLayoutArrangements, ResizerPlacement } from '@talos/kyoko';
import { Blotters } from '../../../components/Blotters/Blotters';
import { Securities } from '../../../components/Securities';
import { WatchList } from '../../../components/WatchList';
import { BLOTTERS_MIN_HEIGHT, MAIN_HEADER_HEIGHT, VOLUME_LADDER_HEIGHT } from '../../../styles/dimensions';
import type { LayoutComponentArrangement } from '../../types';

export const WMLayout1Arrangement: LayoutComponentArrangement = {
  columns: ['minmax(16rem,1fr)'],
  displayName: CustomerUserConfigTradingLayoutArrangements.WMLayout1,
  headerSymbolSelector: false,
  rows: [`minmax(${VOLUME_LADDER_HEIGHT}px,1fr)`, 'minmax(0px,1fr)', `minmax(${BLOTTERS_MIN_HEIGHT}px,1fr)`],
  parts: [
    {
      column: '1',
      row: '1/3',
      component: (
        <Securities>
          <Box data-no-dnd="true" h={CARD_HEIGHT}>
            <WatchList />
          </Box>
        </Securities>
      ),
    },
    {
      column: '1',
      row: '3',
      component: <Blotters />,
      minHeight: BLOTTERS_MIN_HEIGHT,
      maxHeight: window.innerHeight - MAIN_HEADER_HEIGHT - VOLUME_LADDER_HEIGHT - 20,
      resizable: ResizerPlacement.TOP,
    },
  ],
};
