import { useCallback, useEffect, useMemo, useState, type CSSProperties } from 'react';

import {
  AccordionGroup,
  BLOTTER_TABLE_FILTERS_CONTAINER_ID,
  Box,
  MixpanelEvent,
  MixpanelEventProperty,
  MixpanelEventSource,
  MixpanelSourceProvider,
  PortalContextProvider,
  Tab,
  TabList,
  TabPanel,
  TabSize,
  Tabs,
  WL_BLOTTER_TAB,
  WL_VIEW_BLOTTERS_ACTIVE_ORDERS,
  WL_VIEW_BLOTTERS_BALANCES,
  WL_VIEW_BLOTTERS_CREDITS,
  WL_VIEW_BLOTTERS_FUNDING_EVENTS,
  WL_VIEW_BLOTTERS_ORDER_HISTORY,
  WL_VIEW_BLOTTERS_POSITIONS,
  WL_VIEW_BLOTTERS_TRADES,
  WL_VIEW_BLOTTERS_TRANSFERS,
  useIntl,
  useMixpanel,
  usePortal,
  useTabs,
  useWLOrgConfigContext,
  useWLRoleAuth,
} from '@talos/kyoko';

import { useTimeoutFn } from 'react-use';
import { Wrapper } from './styles';

import { defineMessages } from 'react-intl';
import { ActiveOrdersBlotter } from './ActiveOrdersBlotter';
import { BalancesBlotter } from './BalancesBlotter';
import { CreditExposuresBlotter } from './CreditExposuresBlotter';
import { FundingEventsBlotter } from './FundingEventsBlotter/FundingEventsBlotter';
import { OrderHistoryBlotter } from './OrderHistoryBlotter';
import { PositionsBlotter } from './PositionsBlotter';
import { TradesBlotter } from './TradesBlotter';
import { TransfersBlotter } from './TransfersBlotter';
import type { TabbedBlotterProps } from './types';

const messages = defineMessages({
  activeOrders: {
    defaultMessage: 'Active Orders',
    id: 'Blotters.activeOrders',
  },
  orderHistory: {
    defaultMessage: 'Order History',
    id: 'Blotters.orderHistory',
  },
  trades: {
    defaultMessage: 'Trades',
    id: 'Blotters.trades',
  },
  balances: {
    defaultMessage: 'Balances',
    id: 'Blotters.balances',
  },
  credit: {
    defaultMessage: 'Credit',
    id: 'Blotters.credit',
  },
  transfers: {
    defaultMessage: 'Transfers',
    id: 'Blotters.transfers',
  },
  fundingEvents: {
    defaultMessage: 'Funding Events',
    id: 'Blotters.fundingEvents',
  },
  positions: {
    defaultMessage: 'Positions',
    id: 'Blotters.positions',
  },
});

interface TabItem {
  mixpanelLabel: string;
  label: string;
  render: ({
    renderInBackground,
    loading,
    onStartLoading,
    onFinishedLoading,
  }: Required<
    Pick<TabbedBlotterProps, 'renderInBackground' | 'loading' | 'onStartLoading' | 'onFinishedLoading'>
  >) => JSX.Element;
}

export const DEFAULT_BLOTTER_TABS = [
  WL_BLOTTER_TAB.ActiveOrders,
  WL_BLOTTER_TAB.OrderHistory,
  WL_BLOTTER_TAB.Trades,
  WL_BLOTTER_TAB.Balances,
  WL_BLOTTER_TAB.Credits,
  WL_BLOTTER_TAB.Transfers,
  WL_BLOTTER_TAB.FundingEvents,
  WL_BLOTTER_TAB.Positions,
];

const TAB_TO_PERMISSION_LOOKUP_HASH = {
  [WL_BLOTTER_TAB.ActiveOrders]: WL_VIEW_BLOTTERS_ACTIVE_ORDERS,
  [WL_BLOTTER_TAB.OrderHistory]: WL_VIEW_BLOTTERS_ORDER_HISTORY,
  [WL_BLOTTER_TAB.Trades]: WL_VIEW_BLOTTERS_TRADES,
  [WL_BLOTTER_TAB.Balances]: WL_VIEW_BLOTTERS_BALANCES,
  [WL_BLOTTER_TAB.Credits]: WL_VIEW_BLOTTERS_CREDITS,
  [WL_BLOTTER_TAB.Transfers]: WL_VIEW_BLOTTERS_TRANSFERS,
  [WL_BLOTTER_TAB.FundingEvents]: WL_VIEW_BLOTTERS_FUNDING_EVENTS,
  [WL_BLOTTER_TAB.Positions]: WL_VIEW_BLOTTERS_POSITIONS,
};

export const BlottersComponent = ({ blotterTabs = DEFAULT_BLOTTER_TABS, height, width }: BlotterProps) => {
  const [loading, setLoading] = useState(false);
  const [style, setStyle] = useState<CSSProperties>({ height, width });
  const mixpanel = useMixpanel();
  const { isAuthorized } = useWLRoleAuth();
  const { formatMessage } = useIntl();
  const blotterTabsLookupHash = useMemo<Partial<Record<WL_BLOTTER_TAB, boolean>>>(
    () =>
      blotterTabs.reduce(
        (acc, blotterTab) => ({
          ...acc,
          // True if there are no associated permissions in the TAB_TO_PERMISSION_LOOKUP_HASH above, or if the user is authorized with that permission
          [blotterTab]:
            !TAB_TO_PERMISSION_LOOKUP_HASH[blotterTab] || isAuthorized(TAB_TO_PERMISSION_LOOKUP_HASH[blotterTab]),
        }),
        {}
      ),
    [blotterTabs, isAuthorized]
  );

  const { config } = useWLOrgConfigContext();
  const maxRows = config.blotterRowsMax;
  const tabItems = useMemo(() => {
    const items: TabItem[] = [];
    if (blotterTabsLookupHash[WL_BLOTTER_TAB.ActiveOrders]) {
      items.push({
        label: formatMessage(messages.activeOrders),
        mixpanelLabel: messages.activeOrders.defaultMessage,
        render: ({ renderInBackground: hidden, loading, onStartLoading, onFinishedLoading }) => (
          <ActiveOrdersBlotter
            renderInBackground={hidden}
            loading={loading}
            maxRows={maxRows}
            onStartLoading={onStartLoading}
            onFinishedLoading={onFinishedLoading}
          />
        ),
      });
    }
    if (blotterTabsLookupHash[WL_BLOTTER_TAB.OrderHistory]) {
      items.push({
        label: formatMessage(messages.orderHistory),
        mixpanelLabel: messages.orderHistory.defaultMessage,
        render: ({ renderInBackground: hidden, loading, onStartLoading, onFinishedLoading }) => (
          <OrderHistoryBlotter
            renderInBackground={hidden}
            loading={loading}
            maxRows={maxRows}
            onStartLoading={onStartLoading}
            onFinishedLoading={onFinishedLoading}
          />
        ),
      });
    }
    if (blotterTabsLookupHash[WL_BLOTTER_TAB.Trades]) {
      items.push({
        label: formatMessage(messages.trades),
        mixpanelLabel: messages.trades.defaultMessage,
        render: ({ renderInBackground: hidden, loading, onStartLoading, onFinishedLoading }) => (
          <TradesBlotter
            renderInBackground={hidden}
            loading={loading}
            maxRows={maxRows}
            onStartLoading={onStartLoading}
            onFinishedLoading={onFinishedLoading}
          />
        ),
      });
    }
    if (blotterTabsLookupHash[WL_BLOTTER_TAB.Balances]) {
      items.push({
        label: formatMessage(messages.balances),
        mixpanelLabel: messages.balances.defaultMessage,
        render: ({ renderInBackground }) => <BalancesBlotter renderInBackground={renderInBackground} />,
      });
    }
    if (blotterTabsLookupHash[WL_BLOTTER_TAB.Credits] && config.showExposureBlotter) {
      items.push({
        label: formatMessage(messages.credit),
        mixpanelLabel: messages.credit.defaultMessage,
        render: ({ renderInBackground }) => <CreditExposuresBlotter renderInBackground={renderInBackground} />,
      });
    }

    if (blotterTabsLookupHash[WL_BLOTTER_TAB.Transfers] && config.enableBalanceTransactions) {
      items.push({
        label: formatMessage(messages.transfers),
        mixpanelLabel: messages.transfers.defaultMessage,
        render: ({ renderInBackground }) => (
          <TransfersBlotter renderInBackground={renderInBackground} maxRows={maxRows} />
        ),
      });
    }

    if (blotterTabsLookupHash[WL_BLOTTER_TAB.FundingEvents]) {
      items.push({
        label: formatMessage(messages.fundingEvents),
        mixpanelLabel: messages.fundingEvents.defaultMessage,
        render: ({ renderInBackground }) => <FundingEventsBlotter renderInBackground={renderInBackground} />,
      });
    }

    if (blotterTabsLookupHash[WL_BLOTTER_TAB.Positions]) {
      items.push({
        label: formatMessage(messages.positions),
        mixpanelLabel: messages.positions.defaultMessage,
        render: ({ renderInBackground }) => <PositionsBlotter renderInBackground={renderInBackground} />,
      });
    }

    return items;
  }, [blotterTabsLookupHash, config.enableBalanceTransactions, config.showExposureBlotter, formatMessage, maxRows]);

  const tabs = useTabs({
    initialSelectedIndex: 0,
    initialItems: tabItems,
  });

  const onStartLoading = useCallback(() => {
    setLoading(true);
  }, []);
  const onFinishedLoading = useCallback(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    setStyle({ height: height ?? '100%', width });
  }, [width, height]);

  useEffect(() => {
    if (tabItems.length === 0) {
      // No need to track the label since tabs haven't loaded yet
      return;
    }
    // Track on initial load
    mixpanel.track(MixpanelEvent.SetBlotterTab, {
      [MixpanelEventProperty.TabIndex]: tabs.selectedIndex,
      [MixpanelEventProperty.Blotter]: tabItems.at(tabs.selectedIndex)?.mixpanelLabel,
    });
  }, [tabItems, mixpanel, tabs.selectedIndex]);

  useTimeoutFn(() => setLoading(false), loading ? 500 : 0);

  const { setPortalRef: filtersContainerRef } = usePortal(BLOTTER_TABLE_FILTERS_CONTAINER_ID);

  return (
    <Wrapper>
      <Tabs style={style} {...tabs} size={TabSize.Large}>
        <TabList isBordered={true} rightItems={<Box ref={filtersContainerRef} />}>
          {tabItems.map((tab, index) => (
            <Tab key={`${tab.label}-${index}`} label={tab.label} />
          ))}
        </TabList>
        {tabItems.map((tab, index) => {
          const renderInBackground = tabs.selectedIndex !== index;
          return (
            <TabPanel
              renderInBackground={renderInBackground}
              key={`${tab.label}`}
              display="flex"
              flexDirection="column"
            >
              <AccordionGroup>
                {tab.render({
                  renderInBackground: renderInBackground,
                  loading,
                  onStartLoading,
                  onFinishedLoading,
                })}
              </AccordionGroup>
            </TabPanel>
          );
        })}
      </Tabs>
    </Wrapper>
  );
};

type BlotterProps = {
  blotterTabs?: WL_BLOTTER_TAB[];
  height?: number;
  width?: number;
  selectedIndex?: number;
};

export const Blotters = (props: BlotterProps) => (
  <MixpanelSourceProvider value={MixpanelEventSource.OrdersBlotter}>
    <PortalContextProvider>
      <BlottersComponent {...props} />
    </PortalContextProvider>
  </MixpanelSourceProvider>
);
