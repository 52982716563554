export const Logo = ({ fill }) => (
  <>
    <polygon
      fillOpacity="0.7"
      fill={fill}
      fillRule="nonzero"
      points="46 72 80.2270755 72 120 130.708599 84.8928945 130.708599"
    />
    <polygon fillOpacity="0.8" fill={fill} fillRule="nonzero" points="44 189 78.3204323 189 120 131 84.7971374 131" />
    <polygon
      fillOpacity="0.9"
      fill={fill}
      fillRule="nonzero"
      points="96 72 130.23261 72 170 130.48612 134.854151 130.48612"
    />
    <polygon
      fillOpacity="0.7"
      fill={fill}
      fillRule="nonzero"
      points="174.584261 72 209 72 177.666932 119 160 93.2705979"
    />
    <polygon fillOpacity="0.5" fill={fill} fillRule="nonzero" points="146 72 159 72 152.600923 82" />
    <polygon
      fillOpacity="0.8"
      fill={fill}
      fillRule="nonzero"
      points="176.586406 189 211 189 177.788239 141 160 165.804095"
    />
    <polygon fillOpacity="0.6" fill={fill} fillRule="nonzero" points="144 189 161 189 152.601354 177" />
    <polygon fill={fill} fillRule="nonzero" points="94 189 127.96524 189 170 131 134.758288 131" />
  </>
);
