import { useTransition } from '@react-spring/web';
import type React from 'react';
import { useCallback, useMemo, useRef, useState, type CSSProperties, type PropsWithChildren } from 'react';
import { useOnClickOutside, useOnKeyDown } from '../../hooks';
import type { BoxProps } from '../Core';
import { DrawerContentWrapper, DrawerWrapper, Wrapper } from './styles';

export { DrawerContent, DrawerFooter, DrawerHeader } from './styles';

export type DrawerInputs = {
  initialIsOpen?: boolean;
  closeOnClickOutside?: boolean;
  closeOnEscape?: boolean;
  position?: 'absolute' | 'relative' | 'fixed';
  placement?: 'right' | 'left';
  resizeable?: boolean;
  width?: number;
  onIsOpenChange?: (isOpen: boolean) => void;
};

export type DrawerProps = {
  isOpen: boolean;
  position: 'absolute' | 'relative' | 'fixed';
  placement?: 'right' | 'left';
  open: () => void;
  close: () => void;
  drawerRef: React.RefObject<HTMLDivElement>;
  contentRef: React.RefObject<HTMLDivElement>;
  resizeable?: boolean;
  width?: number;
  style?: CSSProperties;
  adjustForGapMargin?: CSSProperties['marginRight'];
} & BoxProps;

export const useDrawer = ({
  initialIsOpen = false,
  closeOnClickOutside = false,
  closeOnEscape = false,
  position = 'fixed',
  placement = 'right',
  resizeable,
  width,
  onIsOpenChange,
}: DrawerInputs): DrawerProps => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  const contentRef = useRef<HTMLDivElement>(null);
  const drawerRef = useRef<HTMLDivElement>(null);

  const open = useCallback(() => {
    onIsOpenChange?.(true);
    setIsOpen(true);
  }, [onIsOpenChange]);

  const close = useCallback(() => {
    onIsOpenChange?.(false);
    setIsOpen(false);
  }, [onIsOpenChange]);

  useOnClickOutside(drawerRef, isOpen && closeOnClickOutside ? close : () => {});
  useOnKeyDown({ key: 'Escape' }, isOpen && closeOnEscape ? close : () => {});

  return useMemo(
    () => ({
      isOpen,
      open,
      close,
      contentRef,
      drawerRef,
      position,
      placement,
      resizeable,
      width,
    }),
    [isOpen, open, close, contentRef, drawerRef, position, placement, resizeable, width]
  );
};

export function Drawer({
  open,
  isOpen,
  children,
  position,
  drawerRef,
  contentRef,
  width,
  style,
  placement = 'right',
  adjustForGapMargin,
  ...props
}: PropsWithChildren<DrawerProps>) {
  const transitions = useTransition(isOpen, {
    from: { transform: placement === 'left' ? 'translateX(-100%)' : 'translateX(100%)' },
    enter: { transform: 'translateX(0%)' },
    leave: { transform: placement === 'left' ? 'translateX(-100%)' : 'translateX(100%)' },
    reverse: !isOpen,
    config: { duration: 200 },
  });

  const marginRight = isOpen ? undefined : adjustForGapMargin != null ? adjustForGapMargin : '-spacingTiny';

  if (position === 'relative') {
    return (
      <DrawerWrapper style={style} width={isOpen ? width : 0} ref={drawerRef} mr={marginRight} {...props}>
        <DrawerContentWrapper width={width} ref={contentRef}>
          {children}
        </DrawerContentWrapper>
      </DrawerWrapper>
    );
  }

  return transitions(
    (styles, item) =>
      item && (
        <Wrapper position={position} ref={drawerRef} style={styles} width={width} placement={placement}>
          {children}
        </Wrapper>
      )
  );
}
