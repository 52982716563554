import type { ICellRendererParams } from 'ag-grid-enterprise';
import type Big from 'big.js';
import { memo } from 'react';
import { Box } from '../Core';
import { InlineFormattedNumber } from '../FormattedNumber';
import { PriceCell } from './styles';
import type { WatchListRowData } from './types';

export interface SpreadCellRendererParams extends ICellRendererParams {
  data: WatchListRowData;
  value: Big | undefined;
}
export const SpreadCellRenderer = memo(function SpreadCellRenderer({ value, data }: SpreadCellRendererParams) {
  const isLoaded = value !== undefined;
  const hasData = value != null;

  return (
    <PriceCell alignItems="center" flexDirection="column" w="100%">
      {isLoaded && hasData ? (
        <Box
          background="backgroundContent"
          fontSize="fontSizeTiny"
          zIndex={1}
          p="spacingTiny"
          borderRadius="borderRadiusDefault"
        >
          <InlineFormattedNumber
            highlightColor="colorTextSubtle"
            number={value.times(10_000).toFixed()}
            increment="1"
            round={true}
          />
        </Box>
      ) : null}
    </PriceCell>
  );
});
