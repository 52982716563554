import { PriceProtectionEnum } from '../types/types';

/**
 * Prettifies the price protection enum. Falsy values and 'Error' will be prettified to "Enabled" as
 * that is how backend behaves: https://app.shortcut.com/talostrading/story/50585/iss#activity-51654
 */
export const prettyPriceProtection = (priceProtection: PriceProtectionEnum | undefined) => {
  if (priceProtection === PriceProtectionEnum.Error || !priceProtection) {
    return 'Enabled';
  }

  return priceProtection;
};
