import { defineMessages } from 'react-intl';
import { useIntl } from '../../hooks';
import { useCurrentFeeTier } from '../../hooks/useCurrentFeeTier';
import { IconName } from '../Icons';
import { Text } from '../Text';
import { SidebarItem } from './SidebarItem';

const messages = defineMessages({
  feeTier: {
    id: 'Sidebar.feeTier',
    defaultMessage: 'Fee Tier',
  },
});

export const SidebarCurrentFeeTier = () => {
  const { formatMessage } = useIntl();
  const currentFeeTier = useCurrentFeeTier();
  return (
    currentFeeTier && (
      <SidebarItem icon={IconName.Fees}>
        <Text data-testid="sidebar-current-fee-tier">
          {formatMessage(messages.feeTier)}: {currentFeeTier}
        </Text>
      </SidebarItem>
    )
  );
};
