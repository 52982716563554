import { useMemo, useState } from 'react';
import { useCurrenciesContext } from '../../contexts';
import { AddressRoutingTypeEnum, CustomerAddressTypeEnum, type Customer } from '../../types';
import { getAddressRoutingTypeOfCurrency } from '../../utils';
import { Button, ButtonVariants } from '../Button';
import { Box, Flex } from '../Core';
import { CurrencySelect } from '../CurrencySelect';
import { FormGroup, Input, SearchSelect } from '../Form';

export interface NewCustomerAddressFormState {
  counterparty: string | undefined;
  name: string;
  memo: string;
  address: string;
  currency: string;
  addressType: CustomerAddressTypeEnum;
}

type EnabledFields = {
  [K in keyof NewCustomerAddressFormState]?: boolean;
};
interface CustomerAddressCreatorProps {
  addressRoutingType?: AddressRoutingTypeEnum;
  enabledFields: EnabledFields;
  onCancel: () => void;
  onSubmit: (form: NewCustomerAddressFormState) => void;
  showBackground?: boolean;
  counterpartyOptions?: Customer[];
}

const areRequiredFieldsSet = (form: NewCustomerAddressFormState, requiredFields: EnabledFields): boolean => {
  return Object.keys(requiredFields).every(
    key => requiredFields[key] !== true || form[key as keyof NewCustomerAddressFormState] !== ''
  );
};

export const CustomerAddressCreator = ({
  addressRoutingType,
  enabledFields,
  onCancel,
  onSubmit,
  showBackground,
  counterpartyOptions,
}: CustomerAddressCreatorProps) => {
  const [form, setForm] = useState<NewCustomerAddressFormState>({
    counterparty: '',
    name: '',
    memo: '',
    address: '',
    addressType: CustomerAddressTypeEnum.Withdrawal,
    currency: '',
  });

  const { currenciesBySymbol } = useCurrenciesContext();

  const effectiveAddressRoutingType =
    addressRoutingType || getAddressRoutingTypeOfCurrency(currenciesBySymbol.get(form.currency));

  const availableCurrencies = useMemo(() => [...currenciesBySymbol.values()], [currenciesBySymbol]);

  const effectiveEnabledFields = useMemo(() => {
    if (effectiveAddressRoutingType === AddressRoutingTypeEnum.Fiat) {
      // Address input is not enabled for Fiat currencies
      return { ...enabledFields, address: false };
    } else {
      return enabledFields;
    }
  }, [enabledFields, effectiveAddressRoutingType]);

  const effectiveRequiredFields = useMemo(() => {
    if (effectiveAddressRoutingType === AddressRoutingTypeEnum.Fiat) {
      // Address input is not enabled for Fiat currencies
      return { ...enabledFields, memo: false, address: false };
    } else {
      // Memo is never required
      return { ...enabledFields, memo: false };
    }
  }, [enabledFields, effectiveAddressRoutingType]);

  return (
    <Box p="spacingMedium" background={showBackground ? 'backgroundCard' : undefined}>
      {effectiveEnabledFields.counterparty && counterpartyOptions != null && (
        <FormGroup label="Counterparty">
          <SearchSelect
            selection={counterpartyOptions.find(option => option.Name === form.counterparty)}
            options={counterpartyOptions}
            getLabel={option => option.DisplayName}
            onChange={option => setForm(prev => ({ ...prev, counterparty: option?.Name }))}
            data-testid="customer-address-creator-counterparty-select"
          />
        </FormGroup>
      )}
      {effectiveEnabledFields.currency && (
        <FormGroup label="Currency">
          <CurrencySelect
            options={availableCurrencies}
            onChange={curr => setForm(prev => ({ ...prev, currency: curr?.Symbol || '', address: '' }))}
            value={currenciesBySymbol.get(form.currency)}
            showDescriptionInButton={true}
            data-testid="customer-address-creator-currency-select"
          />
        </FormGroup>
      )}
      {effectiveEnabledFields.addressType && (
        <FormGroup label="Address Type">
          <Flex>
            {[CustomerAddressTypeEnum.Deposit, CustomerAddressTypeEnum.Withdrawal].map(type => {
              return (
                <Button
                  key={type}
                  width="100%"
                  variant={form.addressType === type ? ButtonVariants.Priority : ButtonVariants.Default}
                  onClick={() => setForm(prev => ({ ...prev, addressType: type }))}
                  data-testid={`customer-address-creator-address-type-${type}-button`}
                >
                  {type}
                </Button>
              );
            })}
          </Flex>
        </FormGroup>
      )}
      {effectiveEnabledFields.name && (
        <FormGroup label="Name of Address">
          <Input
            disabled={effectiveEnabledFields.currency && !form.currency}
            placeholder="Name..."
            value={form.name}
            onChange={e => setForm(prev => ({ ...prev, name: e.target.value }))}
            data-testid="customer-address-creator-name-input"
          />
        </FormGroup>
      )}
      {effectiveEnabledFields.address && (
        <FormGroup label={`${form.addressType} Address`}>
          <Input
            disabled={effectiveEnabledFields.currency && !form.currency}
            placeholder="Address..."
            value={form.address}
            onChange={e => setForm(prev => ({ ...prev, address: e.target.value }))}
            data-testid="customer-address-creator-address-input"
          />
        </FormGroup>
      )}
      {effectiveEnabledFields.memo && (
        <FormGroup label="Memo of Address">
          <Input
            disabled={effectiveEnabledFields.currency && !form.currency}
            placeholder="Memo..."
            value={form.memo}
            onChange={e => setForm(prev => ({ ...prev, memo: e.target.value }))}
            data-testid="customer-address-creator-memo-input"
          />
        </FormGroup>
      )}
      <Flex justifyContent="space-between">
        <Button onClick={onCancel}>Cancel</Button>
        <Button
          variant={ButtonVariants.Priority}
          onClick={() => onSubmit(form)}
          disabled={!areRequiredFieldsSet(form, effectiveRequiredFields)}
          data-testid="customer-address-creator-submit-button"
        >
          Create {form.addressType} Address
        </Button>
      </Flex>
    </Box>
  );
};
