import { isObject } from 'lodash';
import type { UpdateActionEnum } from './types';

export interface WebsocketError {
  code: number;
  msg: string;
}

export interface ErrorSubscriptionResponse {
  reqid?: number;
  type: 'error';
  ts: string;
  seq?: number;
  error?: WebsocketError;
}

export const isErrorSubscriptionResponse = (res: unknown): res is ErrorSubscriptionResponse => {
  return isObject(res) && 'type' in res && res.type === 'error' && 'ts' in res;
};

export interface SubscriptionResponse<TData = any, TType extends string = string> {
  reqid?: number;
  type: TType;
  tag?: string;
  ts: string;
  initial?: boolean;
  action?: UpdateActionEnum;
  // note: data will be undefined if this subscription is not supported by an older backend!
  // we should change this to "data?" just as reminder to developers to think about and handle this possibility
  data: TData[];
  seq?: number;
  next?: string;
  page?: boolean;
  error?: WebsocketError;
}

/**
 * A SubscriptionResponse type which only requires you to provide the minimal necessary fields; namely data.
 */
export type MinimalSubscriptionResponse<TData = any, TType extends string = string> = Partial<
  SubscriptionResponse<TData, TType>
> &
  Pick<SubscriptionResponse<TData, TType>, 'data'>;
