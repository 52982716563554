import { Fragment, type CSSProperties } from 'react';
import { format, highlight } from '../../utils/number';
import type { NumberVariants } from './types';

import { useTheme } from 'styled-components';
import { Currency, StackedNumber } from './styles';

export function StackedFormattedNumber({
  number,
  increment,
  specification = 'M.m',
  highlightAll,
  variant,
  round = false,
  pretty = true,
  largeSize,
  baseSize,
  currency,
}: StackedFormattedNumberProps) {
  const numberString = number == null ? '-' : format(number, { spec: increment, round, pretty });
  const hi = highlight({ number: numberString, specification });
  const value = hi.value;
  const startHighlight = hi.highlight[0];
  const endHighlight = hi.highlight[1];
  const theme = useTheme();

  const parts: JSX.Element[] = [];
  if (startHighlight == null || endHighlight == null) {
    return (
      <>
        <StackedNumber
          key={1}
          stack="top"
          large={false}
          variant={variant}
          highlight={!!highlightAll}
          largeSize={largeSize}
          baseSize={baseSize}
        >
          &nbsp;
        </StackedNumber>
        <StackedNumber
          key={2}
          stack="middle"
          large={true}
          variant={variant}
          highlight={true}
          largeSize={largeSize}
          baseSize={baseSize}
        >
          {value}
          {currency && (
            <Currency isSmall={false} variant={variant} theme={theme} data-testid="full-number-currency">
              {' '}
              {currency}
            </Currency>
          )}
        </StackedNumber>
        <StackedNumber
          key={3}
          stack="bottom"
          large={false}
          variant={variant}
          highlight={!!highlightAll}
          largeSize={largeSize}
          baseSize={baseSize}
        >
          &nbsp;
        </StackedNumber>
      </>
    );
  } else {
    parts.push(
      <StackedNumber
        key={1}
        stack="top"
        large={false}
        variant={variant}
        highlight={!!highlightAll}
        largeSize={largeSize}
        baseSize={baseSize}
      >
        {startHighlight > 0 ? value.slice(0, startHighlight) : <>&nbsp;</>}
      </StackedNumber>
    );
    parts.push(
      <StackedNumber key={2} large={true} variant={variant} highlight={true} largeSize={largeSize} baseSize={baseSize}>
        {value.slice(startHighlight, endHighlight + 1)}
        {currency && (
          <Currency isSmall={false} variant={variant} theme={theme} data-testid="full-number-currency">
            {' '}
            {currency}
          </Currency>
        )}
      </StackedNumber>
    );
    parts.push(
      <StackedNumber
        key={3}
        stack="bottom"
        large={false}
        variant={variant}
        highlight={!!highlightAll}
        largeSize={largeSize}
        baseSize={baseSize}
      >
        {endHighlight + 1 < value.length ? value.slice(endHighlight + 1) : <>&nbsp;</>}
      </StackedNumber>
    );
    return <Fragment>{parts}</Fragment>;
  }
}

type StackedFormattedNumberProps = {
  number?: string | number;
  specification?: string;
  highlightAll?: boolean;
  variant?: NumberVariants;
  round?: boolean;
  pretty?: boolean;
  increment?: string;
  largeSize?: CSSProperties['fontSize'];
  baseSize?: CSSProperties['fontSize'];
  currency?: string | null;
};
