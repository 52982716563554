/* eslint @typescript-eslint/explicit-module-boundary-types: ["warn"] */

import {
  addDays,
  addHours,
  addMinutes,
  addSeconds,
  differenceInCalendarDays,
  differenceInMilliseconds,
  format,
  formatDuration,
  intervalToDuration,
  isAfter,
  isBefore,
  isEqual,
  isPast,
  isValid,
  isWithinInterval,
  parseISO,
  toDate,
} from 'date-fns';
import { isDate } from 'lodash';
import parseDuration, { type Units } from 'parse-duration';
// Import the Sugar locales for readable date functions.
import 'sugar/locales/de';
import 'sugar/locales/es';
import 'sugar/locales/fr';
import 'sugar/locales/it';
import 'sugar/locales/pl';

export const DURATIONS = {
  SECOND: 's' as const,
  MINUTE: 'm' as const,
  HOUR: 'h' as const,
  DAY: 'd' as const,
  MONTH: 'month',
};

export type DateFnInput = Parameters<typeof parseDate>[0];
type SugarDateCreateInput = Exclude<Parameters<typeof Sugar.Date.create>[0], undefined>;

/**
 * All-purpose date creation function.
 *
 * Accepts ISO-format date strings, date objects, and null/undefined.
 * For backwards compatibility with SugarJS, `null` and `undefined` will be converted to `now()`.
 *
 * @param input A value to create a date from
 * @returns A new date object
 */
export const parseDate = (input?: SugarDateCreateInput | null): Date => {
  const now = new Date();
  const sugarDate = Sugar.Date.create(input ?? now, { clone: true });

  if (import.meta.env.VITE_AVA_ENV !== 'prod' && import.meta.env.VITE_AVA_ENV !== 'sandbox') {
    // in development environments, let's check that things will still work the same if we don't use SugarJS for date creation
    let dateFnDate = new Date(NaN); // invalid date
    if (typeof input === 'string') {
      dateFnDate = parseISO(input ?? now);
    } else {
      dateFnDate = toDate(input ?? now);
    }
    if (!isValid(dateFnDate) && typeof input === 'string') {
      const duration = parseDuration(input);
      if (duration !== null) {
        console.warn(`parseDate('${input}') should be replaced with an explicit call to "parseDuration('${input}')"`);
        dateFnDate = new Date(duration);
      }
    }
    if (
      isValid(sugarDate) !== isValid(dateFnDate) ||
      (isValid(sugarDate) &&
        !isEqual(sugarDate, dateFnDate) &&
        // date-fns truncates fractional milliseconds, sugarjs rounds them
        differenceInMilliseconds(sugarDate, dateFnDate) > 2)
    ) {
      console.warn(
        `parseDate(${
          isDate(input) ? `Date(${(input as Date).valueOf()})` : JSON.stringify(input)
        }) does not parse the same through sugar and date-fns.`,
        {
          sugarDate,
          sugarDateJson: JSON.stringify(sugarDate),
          dateFnDate,
          dateFnDateJson: JSON.stringify(dateFnDate),
        }
      );
      if (typeof input === 'string' && input.match(/ago|last|today|from/)) {
        console.warn(
          `parseDate('${input}') should be replaced with an explicit relative date creation, e.g. "startOfDay(subDays(parseDate(), 2))" etc.`
        );
      }
    }
  }
  return sugarDate;
};

/**
 * Checks if a given time string "HH:mm:ss" is active or not.
 *
 * @param {string} startTimeString - The start time in string format.
 * @param {string} endTimeString - The end time in string format.
 * @param {Date} currentTime - The current time. Defaults to the actual current time if not provided.
 * @returns {boolean} Returns true in the following cases:
 * 1. If the startTimeString or endTimeString is not provided.
 * 2. If the startTimeString is greater than the endTimeString.
 * 3. If the current time is within the interval of the startTimeString and endTimeString.
 * Otherwise, it returns false.
 */
export const isTimeActive = (
  startTimeString?: string,
  endTimeString?: string,
  currentTime: Date = new Date()
): boolean => {
  if (!startTimeString || !endTimeString) {
    return true; // Always active if no start or end time given.
  }

  function parseTime(timeString, date = new Date()) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    date.setHours(hours, minutes, seconds, 0);
    return date;
  }

  // Parse start and end times into Date objects
  const startDate = parseTime(startTimeString);
  let endDate = parseTime(endTimeString);
  if (startDate > endDate) {
    endDate = addDays(endDate, 1);
  }

  // Check if current time is within the interval
  return isWithinInterval(currentTime, { start: startDate, end: endDate });
};

/**
 * Formats the given date as {yyyy}-{MM}-{dd} {HH}:{mm}, "stopping" at minutes
 */
export function formattedDateAtMinutes(dateString?: DateFnInput | null): string {
  return formattedDate(dateString, '{yyyy}-{MM}-{dd} {HH}:{mm}');
}

export function formattedDate(
  dateString?: DateFnInput | null,
  pattern = '{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}',
  locale?: string
): string {
  if (!dateString) {
    return '';
  }
  const parsedDate = parseDate(dateString);
  if (!isValid(parsedDate)) {
    if (import.meta.env.VITE_AVA_ENV !== 'prod' && import.meta.env.VITE_AVA_ENV !== 'sandbox') {
      console.error(`formattedDate("${dateString}") parsed as an Invalid Date`);
    }
    return '';
  }
  return Sugar.Date.format(parsedDate, pattern, locale);
}

export function formattedUTCDay(dateString?: DateFnInput | null): string {
  return formattedUTCDate(dateString, '{yyyy}-{MM}-{dd}');
}

export function formattedUTCDate(dateString?: DateFnInput | null, pattern?: string): string {
  return formattedDate(utcDate(dateString), pattern);
}

export function formattedDateWithMilliseconds(dateString?: DateFnInput): string {
  return formattedDate(dateString, '{yyyy}-{MM}-{dd} {HH}:{mm}:{ss}.{SSS}');
}

export function formattedDateForSubscription(dateString: Exclude<DateFnInput, null | undefined>): string;
export function formattedDateForSubscription(dateString?: null | undefined): undefined;
export function formattedDateForSubscription(dateString?: DateFnInput): string | undefined;
export function formattedDateForSubscription(dateString?: DateFnInput): string | undefined {
  return dateString != null
    ? Sugar.Date.format(utcDate(dateString), '{yyyy}-{MM}-{dd}T{HH}:{mm}:{ss}.{SSS}000Z')
    : undefined;
}

/**
 * Returns the number of seconds left to a given date for the Duration Parameter
 */
export function formattedDateForOrder(endTime: Date): string {
  return Math.round((+endTime - +parseDate()) / 1000) + 's';
}

export function utcDate(dateString: DateFnInput): Date {
  return Sugar.Date.setUTC(parseDate(dateString), true);
}

/** Formats given date to ddMMMyy uppercased, eg: 09JUN23 */
export function formattedExpiry(dateString: DateFnInput): string {
  // We use date-fns instead of sugarjs here since sugar doesn't support formatting months as 3 letters (September becomes Sept)
  const date = parseDate(dateString);
  if (!isValid(date)) {
    return '';
  }
  return format(date, 'ddMMMyy').toUpperCase();
}

export function formattedDay(dateString?: DateFnInput, pattern = '{yyyy}-{MM}-{dd}'): string {
  return formattedDate(dateString, pattern);
}

export function formattedTime(dateString?: DateFnInput, pattern = '{HH}:{mm}:{ss}'): string {
  return formattedDate(dateString, pattern);
}

export function formattedTimeWithMillis(dateString?: DateFnInput): string {
  return formattedTime(dateString, '{HH}:{mm}:{ss}.{SSS}');
}

export function readableDay(dateString: DateFnInput, includeTime = false, locale?: string): string {
  return formattedDate(dateString, `{Mon} {d}${includeTime ? ' {HH}:{mm}' : ''}, {yyyy}`, locale);
}

export function readableDate(dateString: DateFnInput, includeTime = false, locale?: string): string {
  return formattedDate(dateString, `{d} {Mon} {yyyy}${includeTime ? ', {HH}:{mm}' : ''}`, locale);
}

export function readableDateWithSeconds(dateString: DateFnInput, locale?: string): string {
  return formattedDate(dateString, '{d} {Mon} {yyyy}, {HH}:{mm}:{ss}', locale);
}

export function endOfDay(date?: DateFnInput | null): Date {
  const tmp = date ? parseDate(date) : parseDate();
  tmp.setHours(23, 59, 59, 999);
  return tmp;
}

export function beginningOfDay(date?: DateFnInput | null): Date {
  const tmp = date ? parseDate(date) : parseDate();
  tmp.setHours(0, 0, 0, 0);
  return tmp;
}

export function subtractMinutesFromNow(number: number): string | undefined {
  return formattedDateForSubscription(addMinutes(parseDate(), number * -1));
}

export function subtractHoursFromNow(number: number): string | undefined {
  return formattedDateForSubscription(addHours(parseDate(), number * -1));
}

export function subtractDaysFromNow(number: number): string | undefined {
  return formattedDateForSubscription(addDays(parseDate(), number * -1));
}

const ONE_HOUR = 60 * 60;

/**
 * Returns a short, pretty time representation for the OrderTimer component
 */
export function formattedOrderTime(ms: number | null): string {
  if (!ms) {
    return '';
  }
  if (ms < 1000) {
    return `<1s`;
  }
  const seconds = Math.round(ms / 1000);
  const minutes = Math.floor(seconds / 60);
  let hours = minutes / 60;
  let orderTimer = '';
  // more than 10 hours
  if (seconds >= 10 * ONE_HOUR) {
    hours = Math.round(hours);
    orderTimer = `${hours}h`;
  }

  // between 1 - 10 hours
  else if (seconds >= ONE_HOUR) {
    hours = Math.floor(hours);
    orderTimer = `${hours}h ${minutes % 60}m`;
  }

  // between 1 - 60 minutes
  else if (seconds >= 60) {
    orderTimer = `${minutes}m ${seconds % 60}s`;
  }

  // less than 1 minute
  else {
    orderTimer = `${seconds}s`;
  }
  return orderTimer;
}

/**
 * Split a duration into number and unit
 *
 * @example
 * ```
 * parseDurationUnit("5.1m"); // returns { duration: '5.1', unit: 'm'}
 * ```
 */
export function parseDurationUnit(value = ''): { duration: number | null; unit?: Units } {
  const durationMs = parseDuration(value);
  if (durationMs == null) {
    return { duration: null, unit: undefined };
  }

  const TWO_MINUTES = 2 * 60 * 1000;
  const TWO_HOURS = 2 * 60 * 60 * 1000;
  const TWO_DAYS = 2 * 24 * 60 * 60 * 1000;

  let unit: Units;
  if (durationMs < TWO_MINUTES) {
    unit = 's';
  } else if (durationMs < TWO_HOURS) {
    unit = 'm';
  } else if (durationMs < TWO_DAYS) {
    unit = 'h';
  } else {
    unit = 'd';
  }
  const duration = parseDuration(value, unit);
  return { duration, unit };
}

/**
 * Add a duration to a date. Supports all units given in the DURATION constant.
 */
export function addDuration(date: Date, value: string): Date | never {
  const { duration, unit } = parseDurationUnit(value);
  if (duration == null || unit == null) {
    throw new Error(`invalid duration: ${value}`);
  }
  switch (unit) {
    case DURATIONS.SECOND:
      return addSeconds(date, duration);
    case DURATIONS.MINUTE:
      return addMinutes(date, duration);
    case DURATIONS.HOUR:
      return addHours(date, duration);
    case DURATIONS.DAY:
      return addDays(date, duration);
    default:
      throw new Error(`invalid duration: ${value}`);
  }
}

export function getCurrentTime(): string {
  return formattedTime(new Date(), '{HH}:{mm}:{ss} {Z}');
}

export function isDateInThePast(dateString: DateFnInput): boolean {
  return dateString ? isPast(parseDate(dateString)) : false;
}

/**
 * Tests whether a value can be converted to a *valid* date object
 *
 * @param date Date input value
 * @returns True if `date` can be converted to a valid date object
 */
export function isValidDateInput(date: DateFnInput | null | undefined): boolean {
  if (isDate(date)) {
    return isValid(date);
  } else {
    return isValid(parseDate(date));
  }
}

/** Gives you a string showing days until now from give date, e.g. '10 days' */
export const daysUntil = (endDate: Date): string => daysBetween(new Date(), endDate);

/** Gives you a string showing days since now from give date, e.g. '10 days' */
export const daysSince = (startDate: Date): string => daysBetween(startDate, new Date());

/** Gives you a string showing days between two given dates, e.g. '10 days' */
export const daysBetween = (startDate: Date, endDate: Date): string => {
  return formatDuration({ days: Math.abs(differenceInCalendarDays(endDate, startDate)) }, { format: ['days'] });
};

/**
 * Gives you a string showing time between two dates formatted. E.g. '2 years, 6 months, 9 days'
 *
 * @param {Date} startDate
 * @param {Date} endDate
 * @param {string[]} format - What parts of the duration between the days to display, defaults to ['years', 'months', 'days']
 * */
export const timeBetween = (startDate: Date, endDate: Date, format = ['years', 'months', 'days']): string => {
  const duration = intervalToDuration({ start: startDate, end: endDate });
  return formatDuration(duration, { format });
};

export function isAfterOrEqual(d1: Date, d2: Date): boolean {
  return isAfter(d1, d2) || isEqual(d1, d2);
}

/**
 * Test whether a date is between 2 other dates.
 *
 * @param minValue Minimum date (inclusive)
 * @param value Date to test
 * @param maxValue Maximum date (inclusive)
 * @returns True if `(minValue ?? MIN_DATE) <= value <= (maxValue ?? MAX_DATE)`.
 * @returns False if value is null / undefined.
 */
export function dateIsBetween(minValue?: Date | null, value?: Date, maxValue?: Date | null): boolean {
  if (!value) {
    return false;
  }
  if (isDate(value)) {
    const isGtEq = isDate(minValue) ? isEqual(value, minValue) || isAfter(value, minValue) : true;
    const isLtEq = isDate(maxValue) ? isEqual(value, maxValue) || isBefore(value, maxValue) : true;
    const pass = isLtEq && isGtEq;
    return pass;
  }
  return false;
}

/**
 * Comparator to sort dates for Array.sort(...).
 *
 * @param left Left item to compare
 * @param right Right item to compare
 * @returns -1 if left comes before right, 0 if the same, 1 if left comes after right
 */
export function dateComparator(
  left: Date | string | undefined | null,
  right: Date | string | undefined | null
): number {
  if (typeof left === 'string') {
    left = new Date(left);
  }
  if (typeof right === 'string') {
    right = new Date(right);
  }
  return (left ?? MIN_DATE).valueOf() - (right ?? MAX_DATE).valueOf();
}

const MAX_DATE = Object.freeze(new Date('9999-12-31T23:59:59.999'));
const MIN_DATE = Object.freeze(new Date('0000-01-01T00:00:00.000'));
