import styled from 'styled-components';
import { Box } from '../../Core';

export const SearchSelectWrapper = styled(Box)`
  position: relative;
`;

export const IconWrapper = styled.div`
  width: ${({ theme }) => theme.baseSize}px;
  margin-right: ${({ theme }) => theme.spacingDefault}px;
  flex-shrink: 0;
  line-height: 1;
`;
