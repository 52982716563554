import type { Placement, PositioningStrategy, SideObject, State } from '@popperjs/core';
import type { SpringValues } from '@react-spring/web';
import type React from 'react';
import type { AriaAttributes, CSSProperties, MouseEventHandler, ReactNode } from 'react';
import type { Modifier } from 'react-popper';
import type { ReMap } from '../../utils/types';
export interface PopoverStateProps {
  closeOnClickOutside?: boolean;
  onClickOutside?: (e: MouseEvent) => void;
  trigger?: 'click' | 'hover' | '';
  isSmall?: boolean;
  usePortal?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  placement?: Placement;
  delay?: number;
  modifiers?: Modifier<string, object>[];
  strategy?: PositioningStrategy;
  preventOverflow?: boolean;
  noPaddingAndBorder?: boolean;
}

export type PopoverProps = React.PropsWithChildren<
  ReMap<
    Omit<PopoverState, 'update' | 'close' | 'open' | 'toggle' | 'placement'> &
      Omit<PopoverContentProps, 'transitionStyle' | 'style'>
  >
>;

export type PopoverState = {
  onClickTarget: ((e: Event) => void) | (() => void);
  onMouseEnterTarget: () => void;
  onMouseLeave: MouseEventHandler<HTMLDivElement>;
  onFocusTarget: () => void;
  onBlurTarget: () => void;
  targetRef: HTMLElement | null;
  contentRef: HTMLDivElement | null;
  setTargetRef: React.RefCallback<HTMLSpanElement>;
  setContentRef: React.RefCallback<HTMLDivElement>;
  tabIndex?: number;
  state: State | null;
  update: (() => Promise<Partial<State>>) | null;
  /** Amount of spacing around overflow prevention */
  spacing: number;
  overflow: SideObject;
  close: () => void;
  open: () => void;
  isOpen: boolean;
  toggle: () => void;
  placement?: Placement;
  isSmall: boolean;
  usePortal: boolean;
  styles: { popper: React.CSSProperties };
  attributes: { [key: string]: { [key: string]: string } | undefined };
  preventOverflow?: boolean;
  noPaddingAndBorder: boolean;
  /** Optional to set a zIndex over complex components -
   * use {@see Z_INDEX} from kyoko/src/styles/layout.ts as a basis */
  zIndex?: number;
};

export type PopoverContentProps = Pick<PopoverState, 'zIndex'> & {
  style?: CSSProperties;
  placement?: Placement;
  variant?: PopoverVariant;
  transitionStyle?: SpringValues<CSSProperties>;
  isSmall?: boolean;
  children: ReactNode;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  overflow?: SideObject;
  overflowY?: React.CSSProperties['overflowY'];
  targetStyle?: CSSProperties;
  noPaddingAndBorder?: boolean;
  'aria-busy'?: AriaAttributes['aria-busy'];
};

export enum PopoverVariant {
  Default = 'DEFAULT',
  Primary = 'PRIMARY',
  Positive = 'POSITIVE',
  Negative = 'NEGATIVE',
  Warning = 'WARNING',
}
