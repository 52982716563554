import styled from 'styled-components';
import { setAlpha } from '../../../styles';
import { Box } from '../../Core';

export const ChartTooltipWrapper = styled(Box)`
  backdrop-filter: blur(10px);
  background: ${({ theme }) => setAlpha(0.75, theme.backgroundBody)};
`;
ChartTooltipWrapper.defaultProps = {
  p: 'spacingDefault',
  borderRadius: 'borderRadiusDefault',
};
