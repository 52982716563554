import { createContext, useCallback, useContext, useMemo } from 'react';
import { NotificationVariants } from '../components/Notification';
import { useToasts, type ToastProps } from '../components/Toast';

export const GlobalToastsContext = createContext<GlobalToastsProviderProps | undefined>(undefined);
GlobalToastsContext.displayName = 'GlobalToastsContext';

export const useGlobalToasts = () => {
  const context = useContext(GlobalToastsContext);
  if (context === undefined) {
    throw new Error('Missing GlobalToastsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};

export const GlobalToastsProvider = function GlobalToastsProvider({ children }) {
  const { add: addToast, remove, toasts } = useToasts();

  // Dismissable by default
  const add = useCallback(
    toast =>
      addToast({
        dismissable: true,
        timeout: toast.variant === NotificationVariants.Negative ? 5000 : 3000,
        ...toast,
      }),
    [addToast]
  );

  const value = useMemo(
    () => ({
      add,
      remove,
      toasts,
    }),
    [add, remove, toasts]
  );
  return <GlobalToastsContext.Provider value={value}>{children}</GlobalToastsContext.Provider>;
};

export interface GlobalToastsProviderProps {
  add(toast: Omit<ToastProps, 'id'> & { id?: string }): void;
  remove(id: string): any;
  toasts: ToastProps[];
}
