import { darken } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { DarkTheme, darkColors, mapDarkTheme } from './DarkTheme';

const colors: Colors = {
  black: {
    default: '#000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    main: '#242036',

    '000': '#181428',
    '010': '#2e2d3d',
    '020': '#343244',
    '030': '#5E5D6F',
    '040': '#545363',
    '050': '#79778E',
    '060': '#6c6b7f',
    '070': '#5e5d6f',
    '080': '#605f71',
    '090': '#bcbbc6',
    '100': '#e4e3e8',
  },

  primary: {
    mute: setAlpha(0.1, '#1CF1A5'),
    dim: setAlpha(0.4, '#1CF1A5'),
    default: '#1CF1A5',
    lighten: '#1CF1A5',
  },

  green: {
    mute: setAlpha(0.1, '#2C8059'),
    dim: setAlpha(0.4, '#2C8059'),
    default: '#2C8059',
    lighten: '#1CF1A5',
  },

  red: {
    mute: setAlpha(0.1, '#BD5F4B'),
    dim: setAlpha(0.4, '#BD5F4B'),
    default: '#BD5F4B',
    lighten: '#FE7D62',
  },

  yellow: {
    mute: setAlpha(0.1, '#F3D900'),
    dim: setAlpha(0.4, '#F3D900'),
    default: '#F3D900',
    lighten: '#F3D900',
  },

  blue: {
    mute: setAlpha(0.1, '#306fb8'),
    dim: setAlpha(0.4, '#306fb8'),
    default: '#306fb8',
    lighten: '#4898F4',
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: darkColors.purple,
};

export const DarkStablehouseTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkSH',

  fontFamily: "'Inter', sans-serif",
  fontUrl: 'https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',

  spacingLayout: 16,
  borderRadiusContent: 8,
  borderColorHeader: colors.gray['010'],
  boxShadowHeader: `0 2px 15px ${setAlpha(0.5, colors.gray['000'])}`,

  borderColorContent: colors.gray['010'],
  boxShadowContent: `0 2px 15px ${setAlpha(0.5, colors.gray['000'])}`,

  backgroundNegativeButtonActive: '#771400',

  backgroundDefaultButton: colors.gray['000'],
  backgroundDefaultButtonHover: colors.gray['020'],
  backgroundDefaultButtonActive: colors.gray['020'],

  backgroundInput: colors.gray.main,
  borderColorInputFocus: colors.blue.lighten,

  backgroundPositiveButtonFocus: colors.green.default,

  backgroundNegativeButtonFocus: colors.red.default,

  backgroundPrimaryButton: '#FFFFFF',
  backgroundPrimaryButtonHover: '#F0F0F0',
  backgroundPrimaryButtonActive: '#EEEEEE',
  backgroundPrimaryButtonFocus: '#FFFFFF',
  backgroundPrimaryButtonDisabled: '#FFFFFF88',

  colorTextPrimaryButton: colors.gray['000'],
  colorTextPrimaryButtonHover: colors.gray['000'],
  colorTextPrimaryButtonFocus: colors.gray['000'],
  colorTextPrimaryButtonActive: colors.gray['000'],

  colorTextNegative: colors.red.default,

  backgroundChartDownColor: colors.red.default,
  borderColorChartDownColor: colors.red.default,

  backgroundVolumeLadderBidSpread: darken(0.15, colors.primary.default),
  backgroundVolumeLadderOfferSpread: darken(0.15, colors.primary.default),

  backgroundChartUpColor: colors.green.default,
  borderColorChartUpColor: colors.green.default,

  backgroundSelect: colors.gray.main,
  backgroundSelectHover: colors.gray.main,
  backgroundSelectFocus: colors.gray.main,
  backgroundSelectDisabled: colors.gray.main,
  backgroundSelectReadOnly: colors.gray.main,
  backgroundSelectInvalid: colors.gray.main,

  borderColorSelect: DarkTheme.borderColorInput,
  borderColorSelectHover: DarkTheme.borderColorInputHover,
  borderColorSelectFocus: colors.blue.lighten,
  borderColorSelectDisabled: DarkTheme.borderColorInputDisabled,
  borderColorSelectReadOnly: DarkTheme.borderColorInputReadOnly,
});
