import { Box, type BoxProps } from 'components/Core';
import { WarningSeverityIcon } from 'components/Icons/WarningSeverityIcon';
import { Tooltip } from 'components/Tooltip';
import { useOverflowHandler } from 'hooks/useOverflowHandler';
import { WarningSeverity } from 'types/WarningSeverity';
import { FormMessage, OverflowFormMessageWrapper } from './styles';

export const OverflowFormMessage: React.FC<
  BoxProps & {
    error?: boolean | undefined;
    warning?: boolean | undefined;
  }
> = props => {
  const { isOverflowing, ref } = useOverflowHandler();
  const { error, warning } = props;
  const tooltipContent =
    error || warning ? (
      <Box display="flex">
        <Box flex="none">
          <WarningSeverityIcon severity={error ? WarningSeverity.HIGH : WarningSeverity.MEDIUM} />
        </Box>
        <Box flex="auto">{props.children}</Box>
      </Box>
    ) : null;
  return (
    <OverflowFormMessageWrapper cursor="default">
      <Tooltip delay={500} tooltip={tooltipContent} placement="bottom" trigger={isOverflowing ? 'hover' : ''}>
        <FormMessage data-testid="overflow-test-message" {...props} ref={ref} />
      </Tooltip>
    </OverflowFormMessageWrapper>
  );
};
