import styled from 'styled-components';
import { parseColor } from '../../styles';
import { Flex } from '../Core';
import { Text } from '../Text';

export const CardWrapper = styled(Flex)`
  flex-direction: column;
  background: ${({ theme, background }) =>
    background ? (typeof background === 'number' ? background : parseColor(theme, background)) : theme.backgroundCard};
`;
CardWrapper.defaultProps = {
  p: 'spacingComfortable',
};

export const Header = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.backgroundBody};
`;
Header.defaultProps = {
  mx: '-spacingComfortable',
  px: 'spacingComfortable',
  pb: 'spacingComfortable',
  mb: 'spacingComfortable',
  justifyContent: 'space-between',
  alignItems: 'center',
  h: 28,
};

export const Title = styled.span`
  color: ${({ theme }) => theme.colorTextImportant};
`;

export const Subtitle = styled(Text)`
  text-transform: uppercase;
  font-weight: 500;
`;
Subtitle.defaultProps = {
  color: 'colorTextSubtle',
  size: 'fontSizeSmall',
  mb: 'spacingComfortable',
  display: 'block',
};

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacingSmall}px;
`;
