import { useMemo } from 'react';
import { pipe } from 'rxjs';
import { wsSubscriptionFilter, type FilterFunc } from '../pipes';
import { wsSubscriptionMemory } from '../pipes/wsSubscriptionMemory';
import { useBehaviorSubject } from './useBehaviorSubject';

export interface UseWSFilterPipeParams<T> {
  /** Get a unique key for each of your items of type T */
  getUniqueKey: (item: T) => string;
  /** The filtering function to use to check for inclusion for each item of type T */
  filterFunc: FilterFunc<T>;
}

/**
 * A hook returning a pipe for you to apply to any ws stream you want to do client-side filtering on.
 * @returns a stable pipe for you to attach to your ws stream. This pipe should be put in any useObservable dependency arrays, for example.
 */
export function useWSFilterPipe<T>({ getUniqueKey, filterFunc }: UseWSFilterPipeParams<T>) {
  const { observable } = useBehaviorSubject(() => filterFunc, [filterFunc]);
  return useMemo(
    () => pipe(wsSubscriptionMemory(getUniqueKey), wsSubscriptionFilter(observable)),
    [getUniqueKey, observable]
  );
}
