import type { Colors, DefaultTheme } from 'styled-components';
import { darkColors, mapDarkTheme } from './DarkTheme';

const colors: Colors = {
  ...darkColors,
};

export const DarkCanaryTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'Dark',
});
