import type {
  CellDoubleClickedEvent,
  ColDef,
  CsvExportParams,
  ExcelExportParams,
  ViewportChangedEvent,
} from 'ag-grid-community';
import type {
  ColGroupDef,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GridApi,
  GridOptions,
  IRowNode,
  MenuItemDef,
} from 'ag-grid-enterprise';
import type { ReactNode } from 'react';
import type { Observable, UnaryFunction } from 'rxjs';
import type { MinimalSubscriptionResponse } from 'types';
import type { DeepPartial } from '../../utils/types';
import type { BlotterTableFiltersProps } from './BlotterTableFilters.types';
import type { BlotterTablePauseProps } from './BlotterTablePauseButton.types';
import type { Column } from './columns/types';
import type { UseBlotterTableInitialSetupArg } from './useBlotterTable';

/**
 * An RxJS pipeline
 */
export type CompositePipeFunction<TRowType, TResponseType = TRowType> = UnaryFunction<
  UseBlotterTableProps<TResponseType>['dataObservable'],
  UseBlotterTableProps<TRowType>['dataObservable']
>;

export interface ColumnDefsOptions<R> {
  handleClickJson?: (data: R | undefined) => any;
  exportDataAsCSV?: UseBlotterTable<R>['exportDataAsCSV'];
  supportColumnColDefGroups?: UseBlotterTableProps<R>['supportColumnColDefGroups'];
}

export interface BlotterTableRow<R> {
  readonly data: R;
  setData(data: R): void;
  remove(): void;
  setSelected(selected: boolean): void;
}

export type BlotterTableProps<R = any> = {
  readonly gridOptions: GridOptions<R> | null;
  readonly density?: BlotterDensity;
  readonly background?: string;
  readonly extraComponents?: {
    [key: string]: any; // same as gridOptions.d.ts provided by ag-grid
  };
} & Partial<Pick<UseBlotterTableUtilitiesOutput<R>, 'hidePopupMenu'>>; // BlotterTable wants this one utility

export enum BlotterDensity {
  Compact,
  Default,
  Comfortable,
  VeryComfortable,
}

export const AGGRID_AUTOCOLUMN_ID = 'ag-Grid-AutoColumn'; // internal aggrid id
type BlotterTableColumnSortID<R = any> = `${'+' | '-'}${(keyof R & string) | typeof AGGRID_AUTOCOLUMN_ID}`;
export type BlotterTableSort<R = any> = BlotterTableColumnSortID<R> | BlotterTableColumnSortID<R>[];
export type BlotterTableFilter = { [key: string]: any };

/**
 * Should return `true` if a row should be visible in the blotter, otherwise `false`.
 * {@link GridOptions['doesExternalFilterPass']}
 */
export type BlotterTableClientLocalFilter<R = unknown> = (node: IRowNode<R>) => boolean;

/**
 * It's dangerous to go alone! Take this: https://www.ag-grid.com/react-grid/ *
 */

export type PinnedRow<R> = R & { groupColumnValue?: string };

export type UseBlotterTableProps<R> = {
  readonly dataObservable: Observable<MinimalSubscriptionResponse<R>>;
  /** Property to use for the getRowId function */
  readonly rowID: string | undefined;

  /**
   * A pipe which, if provided, will attach to the dataObservable and push values into a top-pinned "Totals" row.
   * If you instead want to provide your own custom observable (not a pipe) to achieve the same thing, see pinnedRowDataObs.
   */
  readonly pinnedRowDataPipe?: UnaryFunction<
    UseBlotterTableProps<R>['dataObservable'],
    Observable<DeepPartial<PinnedRow<R>>>
  >;

  /**
   * A pipe which, if provided, will be subscribed to by the blotter and push its emissions into a top-pinned "Totals" row.
   * If you instead want to chain off of the `dataObservable`, you can provide a pipe to achieve the same thing: `pinnedRowDataPipe`.
   */
  readonly pinnedRowDataObs?: Observable<DeepPartial<PinnedRow<R>>>;

  /** Initial columns to show, or flattened columns from groupable columns */
  readonly columns: Column[];

  /** ag-grid's groupable columns; if set, these are the ones used for display */
  readonly groupableColumns?: Column[] | Array<Column | ColumnGroup>;

  /** Initial sorting to apply */
  readonly sort?: BlotterTableSort<R>;

  /** Animate adds/updates/removes */
  readonly animateRows?: boolean;

  /** Row height in pixels */
  readonly rowHeight?: number;

  /** Set the DOM layout behaviour */
  readonly domLayout?: 'normal' | 'autoHeight' | 'print';

  /** Flash added or updated rows, or both */
  readonly flashRows?: ('add' | 'update')[];

  /** Adjust spacing between cells and rows */
  readonly density?: BlotterDensity;

  /** Calls `sizeColumnsToFit` when the list of columns changes */
  readonly fitColumns?: boolean;

  /** Whether or not to show the pinned rows being forwarded via the pinnedRowDataObservable */
  readonly showPinnedRows?: boolean;

  /** When to show the ag grid grouping panel */
  readonly rowGroupPanelShow?: GridOptions<R>['rowGroupPanelShow'];

  /** Whether or not to suppress the wrapping of the title on aggregated columns with aggregate function names, eg: SUM(Quantity). Defaults to true. */
  readonly suppressAggFuncInHeader?: GridOptions<R>['suppressAggFuncInHeader'];

  readonly onFirstDataRendered?: GridOptions<R>['onFirstDataRendered'];

  /** An additional context which will be spread on top of the default blotter table context*/
  readonly context?: Record<string, any>;

  /** Called per element of the grid to determine if element is visible */
  clientLocalFilter?: BlotterTableClientLocalFilter<R>;

  /** Specify what the empty state is */
  renderEmpty?(): ReactNode;

  /** Called when column ordering or visibility changes */
  onColumnsChanged?(columns: Column[], api: GridApi<R>): void;

  /** Called when column sorting changes */
  onSortChanged?<R = any>(sorting?: BlotterTableSort<R>): void;

  /** Called when a row group is either opened or closed */
  onRowGroupOpened?: GridOptions['onRowGroupOpened'];

  /** Called when either the expandAll or collapseAll functionality is used */
  onExpandOrCollapseAll?: GridOptions['onExpandOrCollapseAll'];

  /** A callback passed to the blotter, called every time a new group is created. Return true to have the group be open on creation. */
  isGroupOpenByDefault?: GridOptions['isGroupOpenByDefault'];

  /** Called when a row is double clicked */
  // TODO just expose the whole event here - no point in hiding it
  onDoubleClickRow?(data: R): void;

  /** Called when a row is double clicked */
  onDoubleClickCell?(event: CellDoubleClickedEvent<R>): void;

  /** Called when a row is clicked */
  onClickRow?(data: R): void;

  /** Called when row selection is changed */
  onRowSelectionChanged?(selectedRows: BlotterTableRow<R>[]): void;

  /** Called when constructing the context menu off a right click action */
  getContextMenuItems?(params: GetContextMenuItemsParams): (string | MenuItemDef)[];

  getExtraMainMenuItems?: (params: GetMainMenuItemsParams) => (string | MenuItemDef)[];
  handleClickJson?: ColumnDefsOptions<R>['handleClickJson'];

  onViewportChanged?: (event: ViewportChangedEvent<R>) => void;

  onRowDataUpdated?: GridOptions['onRowDataUpdated'];

  /** Sanity check to avoid accidental assignment from spreading props */
  onFilterChanged?: never;

  quickSearchParams?: {
    /** If you want to control the filter text state yourself, provide this property. Filtering will happen based on this string. */
    filterText?: string;
  };

  pauseParams?: {
    /** Whether or not to show the Pause button. Defaults to false. */
    showPauseButton?: boolean;
  };

  /** Set to true, to support ColumnGroup type in the columns prop
   * @see ColumnGroup
   */
  supportColumnColDefGroups?: boolean;

  /**
   * A callback to update the columns in the grid. This is useful for when you want to show/hide columns based on some external state.
   * @param arg Mirror of {@link UseBlotterTableInitialSetupArg}, but with the dataObservable included.
   * @returns A function to clean up the custom column update
   */
  customColumnUpdate?: (
    arg: Omit<UseBlotterTableInitialSetupArg<R>, 'hasCustomColumnUpdate'> & {
      dataObservable: UseBlotterTableProps<R>['dataObservable'];
    }
  ) => () => void;
} & Pick<
  GridOptions<R>,
  | 'groupDisplayType'
  | 'suppressMakeColumnVisibleAfterUnGroup'
  | 'suppressRowClickSelection'
  | 'isRowSelectable'
  | 'groupRemoveSingleChildren'
  | 'groupRemoveLowestSingleChildren'
  | 'autoGroupColumnDef'
  | 'onCellClicked'
  | 'getRowStyle'
  | 'groupDefaultExpanded'
  | 'treeData'
  | 'selection'
>;

export type UseBlotterTable<TRowType> = BlotterTableProps<TRowType> &
  UseBlotterTableUtilitiesOutput<TRowType> & {
    dataObservable?: Observable<{ data: TRowType[]; initial?: boolean }>;
    sort: UseBlotterTableProps<TRowType>['sort'];
    onSortChanged: UseBlotterTableProps<TRowType>['onSortChanged'];
    getColumns(): Column[];
    blotterTableFiltersProps: Required<
      Pick<
        BlotterTableFiltersProps,
        'quickFilterText' | 'onQuickFilterTextChanged' | 'paused' | 'pause' | 'resume' | 'showPauseButton'
      >
    >;
    pauseProps: BlotterTablePauseProps;
  };

export interface TalosBlotterExportParams {
  /** Whether or not to also include hidden columns in the export */
  includeHiddenColumns?: boolean;
  /** An array of colIds (computed via function getAgGridColId(column)) to ignore. Applies before the optional `includeColumn` callback */
  ignoredColIds?: Set<string>;
  /** A callback which allows you to filter out any columns you don't want included in the export. Applies after the `ignoredColIds` is applied. */
  ignoreColumn?: (columns: ColDef) => boolean;
  /** Take full control over which columns to include in exporting by providing this array. All other column-related options become no-ops essentially. */
  columnKeys?: CsvExportParams['columnKeys'];
}

// These three types below are a bit different due to being csv vs excel. But the talos-part is still shared
// - The top two are for the full export, so they can receive all pararms, while the last one is for the sheet data only
export type ExportDataAsCsvParams = TalosBlotterExportParams & CsvExportParams;
export type ExportDataAsExcelParams = TalosBlotterExportParams & ExcelExportParams;
export type GetSheetDataForExcelParams = TalosBlotterExportParams &
  Pick<ExcelExportParams, 'sheetName' | 'appendContent' | 'prependContent'>;

export interface UseBlotterTableUtilitiesOutput<R> {
  gridApi: GridApi<R> | undefined;
  /**
   * Triggers a CSV download of the grid rows
   * @param params Export parameters to be passed to ag grid
   * By default, the function hides group and pinned rows, as well as does not show any columns with an empty string as a headerName.
   */
  exportDataAsCSV(params: ExportDataAsCsvParams): void;
  /**
   * Triggers an Excel download of the grid rows
   * @param params Export parameters to be passed to ag grid
   * By default, the function hides group and pinned rows, as well as does not show any columns with an empty string as a headerName.
   */
  exportDataAsExcel(params: ExportDataAsExcelParams): void;
  /**
   * Grabs export-ready data and returns as a csv string. The returned csv string is what would otherwise be exported as a csv file, but in this case its just returned as a string.
   * @param params Export parameters to be passed to ag grid
   * By default, the function hides group and pinned rows, as well as does not show any columns with an empty string as a headerName.
   */
  getDataAsCSV(params: ExportDataAsCsvParams): string | undefined;

  addRow(data: R): void;
  getRows(): BlotterTableRow<R>[];
  getRowsAfterFilter(): BlotterTableRow<R>[];
  getSelectedRows(): BlotterTableRow<R>[];
  expandGroupRow(nodeKey: string): void;
  scrollToRow(...args: Parameters<GridApi<R>['ensureNodeVisible']>): void;
  scrollVerticallyToColumn(...args: Parameters<GridApi<R>['ensureColumnVisible']>): void;
  expandAllGroups(): void;
  collapseAllGroups(): void;
  /** Given a node level, will collapse all nodes whose node.level are greater than this provided level. */
  collapseAllLevelsGreaterThan(level: number): void;
  getRowGroupColumnIds(): Set<string>;
  /**
   * Sets new rowGroupColumns to be used. Overrides any rowGroupIndex properties and uses the order of elements provided.
   */
  setRowGroupColumns(colIds: string[]): void;
  /**
   * Adds the provided colId columns to the currently set row group columns at the end.
   */
  addRowGroupColumns(colIds: string[]): void;
  removeRowGroupColumns(colIds: string[]): void;
  getSort: () => BlotterTableSort<R> | undefined;
  setColumnsVisible: GridApi['setColumnsVisible'];
  flashCells: GridApi['flashCells'];
  /**
   * Highlights the provided rows (leaf node rows) for the user.
   *
   * The function opens all possible intermediate groups on its way to the leaf nodes,
   * scrolls the view to the first passed row, and then flashes all passed rows.
   * @param rowID the rowids of the rows to highlight to the user
   * @param expandChildren if highlighting a group row, whether or not to also expand the children of that highlighted row
   */
  highlightRows: (rowIDs: string[], expandChildren?: boolean) => void;

  /**
   * Highlights the provided group row for the user
   *
   * The key to be passed is the key that AgGrid puts on a group row node's .key attribute. If you for example
   * have a group on "Asset", and there's a "BTC" group, the node.key attributes will be "BTC". There can be several row group nodes
   * that have "BTC" as the key. This function just picks the first one it finds, and its up to you to make sure that this makes sense for your use case.
   */
  highlightGroupRow: (groupingKey: string) => void;
  /**
   * Deselects all previous selections and selects all nodes corresponding to the passed in rowids.
   */
  selectRows: (rowIDs: string[]) => void;

  refreshClientSideRowModel: GridApi['refreshClientSideRowModel'];

  getSheetDataForExcel(params: GetSheetDataForExcelParams): string | undefined;

  /**
   * AgGrid's own hidePopupMenu function. Closes **any and all** open AgGrid popup menus.
   *
   * One niched example use is how we are forced to programmatically hide AgGrid's popups ourselves when they're clicked outside of
   * when the blotter is rendered within a React Portal.
   */
  hidePopupMenu: GridApi['hidePopupMenu'];
}

export type BlotterState<R = any> = {
  columns?: ColumnState[];
  sort?: BlotterTableSort<R>;
  filter?: BlotterTableFilter;
  rowGroupsOpened?: RowGroupsOpenedState;
};

export type ColumnState = {
  id: string;
  hide?: boolean;
  width?: number;
  rowGroup?: boolean;
  rowGroupIndex?: number;
};

export interface RowGroupsOpenedState {
  [key: string]: boolean;
}

/** Simpler Native HeaderType alternative to Column type='group' based on ColGroupDef
 * - TODO: Decide if we need to:
 *   - keep this type
 *   - use native AgGrid ColGroupDef or
 *   - if we should use Column type='group'
 * @see ColGrouDef
 */
export type ColumnGroup<TData = any> = Omit<ColGroupDef, 'children' | 'groupId'> & {
  type: typeof COLUMN_GROUP_NATIVE_TYPE;
  groupId: string;
  children: Column<TData>[];
};

export const COLUMN_GROUP_NATIVE_TYPE = 'columnGroupNative';
export type ColumnOrColumnGroup = Column | ColumnGroup;

export function isColumnGroup(column: ColumnOrColumnGroup): column is ColumnGroup {
  return column.type === COLUMN_GROUP_NATIVE_TYPE;
}

/** Extract and flatten Columns and ColumnGroups for compatible analysis */
export function extractColumns(groupCollection: ColumnOrColumnGroup[]): Column[] {
  const result = groupCollection.flatMap(column => {
    return isColumnGroup(column) ? extractColumns(column.children) : column;
  });
  return result;
}
