import { useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { useCurrenciesContext, useSecuritiesContext } from '../../../../contexts';
import { useIntl } from '../../../../hooks';
import { IconName } from '../../../Icons';
import type { FilterableProperty } from '../types';

const messages = defineMessages({
  asset: {
    defaultMessage: 'Asset',
    id: 'Filters.FilterBuilder.filters.asset',
  },
});

/**
 * This hook creates the filter property definition for Assets.
 * "Assets" in the frontend are the join of Securities and Currencies.
 *
 * @returns Filter Property definition to use for Asset properties.
 */

export function useAssetsFilter() {
  const { formatMessage } = useIntl();
  const { currenciesList } = useCurrenciesContext();
  const { securitiesList, securitiesBySymbol } = useSecuritiesContext();

  return useMemo(
    () =>
      ({
        key: 'Symbols',
        label: formatMessage(messages.asset),
        icon: IconName.CurrencyDollar,
        options: [...currenciesList, ...securitiesList].map(asset => asset.Symbol),
        getOptionLabel: option => securitiesBySymbol.get(option)?.DisplaySymbol ?? option,
      } as const satisfies FilterableProperty),
    [currenciesList, formatMessage, securitiesList, securitiesBySymbol]
  );
}
