import { useDebugValue, useEffect, useMemo, useRef, useState, type DependencyList, type MutableRefObject } from 'react';
import { isObservable, type Observable } from 'rxjs';

type ObservableFactory<T> = () => Observable<T>;

/**
 * React hook to use the same observable unless one of its dependencies changes.
 *
 * @param observableFactory Observable source to subscribe to
 * @param dependencies Source observable will only be updated if the values in the list change.
 */
export function useObservable<T>(observableFactory: ObservableFactory<T>, dependencies: DependencyList): Observable<T> {
  const isFirstRender = useRef(true);
  const [source, setSource] = useState(() => {
    return observableFactory();
  });
  useMemo(() => {
    if (isFirstRender.current) {
      // avoiding setting the state a second time when the hook is first called
      // otherwise we potentially trigger a second render when a factory is passed
      isFirstRender.current = false;
      return;
    }
    setSource(observableFactory());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
  return source;
}

export function useObservableValue<T>(observable: ObservableFactory<T>, dependencies: DependencyList): T | undefined;
export function useObservableValue<T, TInitialValue extends T = T>(
  observable: ObservableFactory<T>,
  dependencies: DependencyList,
  initialValue: TInitialValue
): T;
/**
 * React hook to extract the latest value emitted by an observable.
 *
 * @param observable Observable source to subscribe to
 * @param dependencies Source observable will only be updated if the values in the list change.
 * @param initialValue If present, use this as the initial value
 */
export function useObservableValue<T>(
  observable: ObservableFactory<T>,
  dependencies: DependencyList,
  initialValue?: T
): T | undefined {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const source = useObservable(observable, dependencies);
  const [value, setValue] = useState<T | undefined>(initialValue);
  useDebugValue(value);
  useEffect(() => {
    if (isObservable(source)) {
      const subscription = source.subscribe(setValue);
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [source]);
  return value;
}

export function useObservableRef<T>(
  observable: ObservableFactory<T>,
  dependencies: DependencyList
): MutableRefObject<T | undefined>;
export function useObservableRef<T>(
  observable: ObservableFactory<T>,
  dependencies: DependencyList,
  initialValue: T
): MutableRefObject<T>;
/**
 * React hook to store the latest value emitted by an observable in a React Ref.
 *
 * @param observable Observable source to subscribe to
 * @param dependencies Source observable will only be updated if the values in the list change.
 * @param initialValue If present, use this as the initial value
 */
export function useObservableRef<T>(
  observable: ObservableFactory<T>,
  dependencies: DependencyList,
  initialValue?: T
): MutableRefObject<T | undefined> {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const source = useObservable(observable, dependencies);
  const value = useRef(initialValue);

  useEffect(() => {
    if (isObservable(source)) {
      const subscription = source.subscribe(v => (value.current = v));
      return () => {
        subscription.unsubscribe();
      };
    }
  }, [source]);

  return value;
}
