import type { CSSProperties, MouseEventHandler, ReactNode } from 'react';
import type { MixpanelEvent } from '../../tokens';
import type { IndicatorDotVariants } from '../IndicatorDot';
import type { useTabs } from './useTabs';

export interface TabProps {
  label?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  isSelected?: boolean;
  ref?: any;
  editable?: boolean;
  closable?: boolean;
  reorderable?: boolean;
  /** Whether or not this tab's label is being controlled by the smartTabLabeler */
  usingSmartLabel?: boolean;
  onClick?: MouseEventHandler;
  onRename?(text: string): void;
  onCancel?(): void;
  onRemove?(): void;
  id?: string;
  isAddingTab?: boolean;
  isDragging?: boolean;
  style?: CSSProperties;
  appearance?: TabAppearance;
  showDot?: boolean;
  dotVariant?: IndicatorDotVariants;
  mixpanelEvent?: MixpanelEvent;
  isTemporary?: boolean;
  maxTabLength?: number;
  size?: TabSize;
}

export type TabOutputProps = ReturnType<typeof useTabs>['items'][number];
export enum TabAppearance {
  Filled = 'Filled',
  Underlined = 'Underlined',
  Pill = 'Pill',
}

export enum TabSize {
  Small = 1.5,
  Default = 2,
  Large = 2.5,
}
