import * as Sentry from '@sentry/browser';

const maxBreadcrumbSize = 1024 * 8; // 8kB should be enough for everyone
const revision = import.meta.env.VITE_GIT_HASH;

export interface InitSentryArgs {
  environment: string;
  dsn: string;
  tunnel?: string;
}

export function initSentry({ environment, dsn, tunnel }: InitSentryArgs) {
  Sentry.init({
    dsn,
    environment,
    tunnel: ['prod', 'sandbox'].includes(environment) ? tunnel : undefined,
    release: revision,
    maxBreadcrumbs: 30,
    normalizeDepth: 0,
    attachStacktrace: true,
    // Ignore client side network errors that are not actionable
    ignoreErrors: ['TypeError: Failed to fetch', 'TypeError: NetworkError when attempting to fetch resource.'],
    beforeBreadcrumb: (breadcrumb, _breadcrumbHint) => {
      if (breadcrumb.category === 'console') {
        if (
          breadcrumb.data?.arguments?.[0] === 'Mixpanel' ||
          breadcrumb.data?.arguments?.[0]?.indexOf('[Sentry]') === 0
        ) {
          // This prevents us tracking the mixpanel event *and* the console event for it
          return null;
        }
      }
      if (JSON.stringify(breadcrumb).length > maxBreadcrumbSize) {
        const json = breadcrumb.data;
        if (json && 'data' in json && Array.isArray(json['data']) && json['data'].length > 2) {
          breadcrumb.data = {
            ...json,
            // show first and last records only, elide all others for brevity
            data: [json.data[0], `${json['data'].length - 2} records elided`, json['data'][json['data'].length - 1]],
          };
        } else {
          breadcrumb.data = {
            truncated: JSON.stringify({ ...json, data: json?.data }).substring(0, maxBreadcrumbSize),
          };
        }
      }
      return breadcrumb;
    },
    beforeSend: (event, eventHint) => {
      if (environment === 'local') {
        let consoleMethod: (message?: any, ...optionalParams: any[]) => void;
        switch (event.level) {
          case 'fatal':
          case 'error':
            consoleMethod = console.error;
            break;
          case 'warning':
            consoleMethod = console.warn;
            break;
          case 'debug':
            // eslint-disable-next-line no-console
            consoleMethod = console.debug;
            break;
          default:
            // eslint-disable-next-line no-console
            consoleMethod = console.log;
            break;
        }
        consoleMethod('[Sentry] ' + event.message, event, eventHint);
        return null;
      }
      return event;
    },
  });

  Sentry.setTag('version', `${import.meta.env.VITE_NPM_PACKAGE_VERSION} (${revision})`);
}
