import { createContext, useContext } from 'react';

export const DefaultFavoriteSecuritiesConfig = {
  favoriteSecurities: [] as string[],
};

export const DefaultFavoriteSecuritiesMethods = {
  setFavoriteSecurities: (favoriteSecurities: string[]) => {},
};

export const DefaultFavoriteSecuritiesContext = {
  ...DefaultFavoriteSecuritiesConfig,
  ...DefaultFavoriteSecuritiesMethods,
};

export type FavoriteSecuritiesConfig = typeof DefaultFavoriteSecuritiesConfig;
export type FavoriteSecuritiesContextProps = typeof DefaultFavoriteSecuritiesContext;

export const DefaultFavoritesConfig: FavoriteSecuritiesContextProps = {
  favoriteSecurities: [],
  setFavoriteSecurities: () => {},
};

export const FavoriteSecuritiesContext = createContext<FavoriteSecuritiesContextProps>(
  DefaultFavoriteSecuritiesContext
);
FavoriteSecuritiesContext.displayName = 'FavoriteSecuritiesContext';

export function useFavoriteSecurities() {
  const context = useContext(FavoriteSecuritiesContext);
  if (context === undefined) {
    throw new Error('Missing FavoriteSecuritiesContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}
