import { isEqual, keys } from 'lodash';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';

import {
  cleanupInitialFilterDateRange,
  FilterClauseType,
  filterExistsAndExcludes,
  removeEmptyFilters,
  useAssetsFilter,
  useDateRangeFilter,
  usePositionAsssetsFilter,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type BlotterTableFiltersProps,
  type DateRangeFilter,
  type FilterableProperty,
  type FilterClause,
  type FundingEvent,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';

import { useCustomerMarketAccountsFilter } from './filters/useCustomerMarketAccountsFilter';

export interface FundingEventsTableFilter extends DateRangeFilter {
  MarketAccounts?: string[];
  Assets?: string[];
  PositionAssets?: string[];
}

interface UseFundingEventsFilterParams<TData extends FundingEvent> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
}

export function useFundingEventsFilter<TData extends FundingEvent>({
  persistedBlotterTable,
}: UseFundingEventsFilterParams<TData>) {
  const { onFilterChanged: saveFilter } = persistedBlotterTable;

  const [initialFilter] = useState<FundingEventsTableFilter>(() =>
    cleanupInitialFilterDateRange(persistedBlotterTable.initialFilter)
  );
  const [filter, setFilter] = useState<FundingEventsTableFilter>(() =>
    cleanupInitialFilterDateRange(persistedBlotterTable.initialFilter)
  );

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const prevFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;
      if (!isEqual(prevFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<TData>>(
    row => {
      const data = row.data;
      if (filterExistsAndExcludes(filter, 'Assets', data, 'Asset')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'PositionAssets', data, 'PositionAsset')) {
        return false;
      }
      return true;
    },
    [filter]
  );

  const customerMarketAccountsFilter = useCustomerMarketAccountsFilter();
  const assetsFilter = useAssetsFilter();
  const positionAssetsFilter = usePositionAsssetsFilter();

  const filterableProperties: FilterableProperty[] = useMemo(
    () =>
      [
        customerMarketAccountsFilter,
        { ...assetsFilter, key: 'Assets', field: 'Asset', label: 'Asset' },
        positionAssetsFilter,
      ].compact(),
    [assetsFilter, customerMarketAccountsFilter, positionAssetsFilter]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof FundingEventsTableFilter)[]).forEach((key: keyof FundingEventsTableFilter) => {
        switch (key) {
          case 'StartDate':
          case 'EndDate':
            return;
          default:
            clauses.push({
              key: key,
              type: FilterClauseType.INCLUSIVE,
              selections: filter[key] as string[],
            });
        }
      });
    }
    return clauses;
  }, [filter]);
  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter: FundingEventsTableFilter = removeEmptyFilters<FundingEventsTableFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) as any as FundingEventsTableFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );
  const dateRangeFilter = useDateRangeFilter(filter, changeFilter);

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    }),
    [filterableProperties, handleFilterClausesChanged, initialFilterClauses]
  ) satisfies UseFilterBuilderProps;
  const blotterTableFilterProps = useMemo(
    () => ({
      ...dateRangeFilter,
    }),
    [dateRangeFilter]
  ) satisfies Partial<BlotterTableFiltersProps>;
  return useMemo(
    () => ({
      initialFilter,
      clientSideFilter,
      filter,
      // shortcut to spread properties into useAccordionFilterBuilder.filterBuilderProps
      filterBuilderProps,
      // shortcut to spread props into the BlotterTableFilters component
      blotterTableFilterProps,
    }),
    [blotterTableFilterProps, clientSideFilter, filter, filterBuilderProps, initialFilter]
  );
}
