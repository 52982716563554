import { CareOrderStatusEnum } from '../types/CareOrder';
import type { ValueOf } from './types';

export const getCareOrderStatusColor = (status: ValueOf<typeof CareOrderStatusEnum>) => {
  switch (status) {
    case CareOrderStatusEnum.New:
      return 'var(--colors-orange-lighten)';
    case CareOrderStatusEnum.PartiallyFilled:
    case CareOrderStatusEnum.PendingCancel:
    case CareOrderStatusEnum.PendingReplace:
      return 'var(--colors-blue-lighten)';
    case CareOrderStatusEnum.Rejected:
      return 'var(--colorOrderStatusRejected)';
    case CareOrderStatusEnum.Canceled:
      return 'var(--colorTextDefault)';
    case CareOrderStatusEnum.Filled:
    case CareOrderStatusEnum.FilledAndCanceled:
      return 'var(--colorOrderStatusFilled)';
    default:
      throw new Error(`Unknown status: ${status}`);
  }
};
