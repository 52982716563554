import { has, isEqual, noop } from 'lodash';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { formattedDateForSubscription, parseDate } from '../../../utils';
import {
  isDateRange,
  lookbackOptionToDate,
  type DateRange,
  type LookbackOption,
  type LookbackWindow,
} from '../../DateRangePicker';
import type { BlotterTableFiltersProps } from '../BlotterTableFilters.types';

/**
 * Any blotter table that wants to support date range filtering,
 * should have its filter type/interface extend this interface.
 */
export interface DateRangeFilter {
  _start?: LookbackOption;
  StartDate?: string;
  EndDate?: string;
  TimestampField?: string;
}

/**
 * This hook handles date range changes from the filter builder.
 * Pass it your {@link changeFilter} callback, and it will wire everything else up for you.
 *
 * @param filter Current filter in effect
 * @param changeFilter Action to call with the new filter
 * @returns An object that can be spread into the BlotterTableFilter props
 */
export function useDateRangeFilter<TFilter extends DateRangeFilter>(
  filter: TFilter | undefined,
  changeFilter: (action: React.SetStateAction<TFilter>) => void,
  additionalFilterDateRange?: BlotterTableFiltersProps['dateRange'] | undefined
): Pick<
  BlotterTableFiltersProps,
  'dateRange' | 'onDateRangeChanged' | 'selectedTimestampField' | 'onTimestampFieldChanged'
> {
  const handleDateRangeChanged = useCallback(
    (newRange: DateRange | LookbackWindow) => {
      changeFilter(curr => {
        const newFilter = convertDateRangeToFilterValue(newRange, curr);
        // Don't trigger the state set if the filter is the same as we don't want to trigger a subscription amend
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );

  const handleTimestampFieldChanged = useCallback(
    (timestampField: string) => {
      changeFilter(curr => ({
        ...curr,
        TimestampField: timestampField,
      }));
    },
    [changeFilter]
  );

  const dateRangeValue = useMemo((): DateRange | LookbackWindow => {
    if (filter?._start == null) {
      return {
        from: filter?.StartDate == null ? null : parseDate(filter.StartDate),
        to: filter?.EndDate == null ? null : parseDate(filter.EndDate),
      };
    }
    return {
      lookback: filter._start,
    };
  }, [filter]);

  const lastAdditionFilterDateRange = useRef<typeof additionalFilterDateRange>();
  useEffect(() => {
    if (additionalFilterDateRange && !isEqual(lastAdditionFilterDateRange.current, additionalFilterDateRange)) {
      lastAdditionFilterDateRange.current = additionalFilterDateRange;
      handleDateRangeChanged(additionalFilterDateRange);
    }
  }, [additionalFilterDateRange, handleDateRangeChanged]);

  return useMemo(
    () => ({
      dateRange: dateRangeValue,
      onDateRangeChanged: additionalFilterDateRange ? noop : handleDateRangeChanged,
      selectedTimestampField: additionalFilterDateRange ? undefined : filter?.TimestampField,
      onTimestampFieldChanged: additionalFilterDateRange ? noop : handleTimestampFieldChanged,
    }),
    [
      additionalFilterDateRange,
      dateRangeValue,
      filter?.TimestampField,
      handleDateRangeChanged,
      handleTimestampFieldChanged,
    ]
  );
}

export function convertDateRangeToFilterValue<TFilter extends DateRangeFilter = DateRangeFilter>(
  newRange: DateRange | LookbackWindow,
  curr: TFilter
): TFilter {
  const [StartDate, EndDate] = isDateRange(newRange)
    ? [newRange.from, newRange.to]
    : [lookbackOptionToDate(newRange.lookback), undefined];
  const newFilter: TFilter = {
    ...curr,
    StartDate: formattedDateForSubscription(StartDate),
  };
  if (EndDate) {
    newFilter.EndDate = formattedDateForSubscription(EndDate);
  } else {
    delete newFilter.EndDate;
  }
  // If we are tied to a lookback window then save the window. Otherwise make sure to clear the key out
  if (!isDateRange(newRange)) {
    newFilter._start = newRange.lookback;
  } else {
    delete newFilter._start;
  }
  return newFilter;
}

/**
 * Do the translation from abstract values in the initial filter to concrete values
 * like lookback window to StartDate
 *
 * @param initialFilter Initial filter (from the persisted table)
 * @returns Initial filter with StartDate set if the filter is using a lookback option (e.g. "last 24 hours")
 */
// Do the translation from abstract values in the initial filter to concrete values
// like lookback window to StartDate
export function cleanupInitialFilterDateRange<TFilter extends DateRangeFilter>(initialFilter?: TFilter): TFilter {
  if (initialFilter?._start) {
    const startDate = lookbackOptionToDate(initialFilter._start);
    return {
      ...initialFilter,
      StartDate: formattedDateForSubscription(startDate),
    };
  }
  return {
    ...(initialFilter ?? {}),
  } as TFilter;
}

/**
 * Clears DateRangeFilter keys from filter
 */
export function clearDateRangeFilters<TFilter extends DateRangeFilter>(filter: TFilter | undefined): TFilter {
  if (!filter) {
    return {} as TFilter;
  }

  return {
    ...filter,
    _start: undefined,
    StartDate: undefined,
    EndDate: undefined,
  };
}

/**
 * Checks if the given filter object includes "_start", "EndDate" or "StartDate" properties, meaning that it contains a DateRangeFilter
 * If an undefined filter object is passed, false is returned.
 */
export function hasDateRangeFilter<TFilter extends DateRangeFilter>(filter: TFilter | undefined): boolean {
  if (!filter) {
    return false;
  }

  const keys = ['_start', 'EndDate', 'StartDate'] satisfies (keyof DateRangeFilter)[];
  return keys.some(key => has(filter, key));
}
