import {
  BlotterDensity,
  type Column,
  type ColumnDef,
  columnTypes,
  type DateRangeFilter,
  POSITION,
  type Position,
  useAccordionFilterBuilder,
  useConstant,
  usePersistedBlotterTable,
  useWLHomeCurrency,
  useWsBlotterTable,
  type WebsocketRequest,
} from '@talos/kyoko';
import type { GridOptions } from 'ag-grid-community';
import { usePositionsFilter } from 'components/Filters/FilterBuilder/usePositionsFilter';
import { pick } from 'lodash';
import { useMemo } from 'react';

const getColumns = (homeCurrency: string) =>
  [
    { type: 'account', field: 'MarketAccount', width: 200, rowGroup: true },
    { type: 'asset', field: 'Asset' },
    { type: 'size', field: 'Amount', params: { currencyField: 'DeltaCurrency' } },
    {
      type: 'size',
      field: 'Equivalent.Amount',
      params: { currencyField: 'Equivalent.Currency' },
      title: { intlKey: 'amountHomeCurrency' },
      titleIntlKeyValues: { homeCurrency },
      aggregate: true,
    },
    { type: 'size', field: 'CumFees', params: { currencyField: 'FeeCurrency' }, hide: true },
    { type: 'text', field: 'FeeCurrency', hide: true },
    { type: 'size', field: 'Delta', params: { currencyField: 'DeltaCurrency' }, aggregate: true },
    { type: 'text', field: 'DeltaCurrency', hide: true },
    {
      type: 'size',
      field: 'Equivalent.Delta',
      params: { currencyField: 'Equivalent.Currency' },
      title: { intlKey: 'deltaHomeCurrency' },
      titleIntlKeyValues: { homeCurrency },
      aggregate: true,
    },
    { type: 'size', field: 'MarkPrice', params: { currencyField: 'MarkPriceCurrency' } },
    { type: 'text', field: 'MarkPriceCurrency', hide: true },
    {
      type: 'size',
      field: 'Equivalent.MarkPrice',
      params: { currencyField: 'Equivalent.Currency' },
      title: { intlKey: 'markPriceHomeCurrency' },
      titleIntlKeyValues: { homeCurrency },
      aggregate: true,
    },
  ] satisfies ColumnDef<Position>[];

export const usePositionsBlotter = () => {
  const { homeCurrency } = useWLHomeCurrency();
  const request: WebsocketRequest = useConstant({
    name: POSITION,
    tag: 'BLOTTER_POSITIONS',
    EquivalentCurrency: homeCurrency,
  });
  const autoGroupColumnDef = useMemo(
    () =>
      columnTypes.group({
        type: 'group',
        suppressColumnsToolPanel: true,
        params: { suppressCount: true },
        sortable: true,
        width: 250,
      }),
    []
  );

  const columns: Column[] = useMemo(() => getColumns(homeCurrency), [homeCurrency]);

  const persistedBlotterTable = usePersistedBlotterTable('Positions_Blotter', { columns });

  const filteredPositions = usePositionsFilter({ persistedBlotterTable });
  const { blotterTableFilterProps, filterBuilderProps, clientSideFilter: clientLocalFilter } = filteredPositions;

  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: false },
    filterBuilderProps,
  });

  const blotterTable = useWsBlotterTable<WebsocketRequest, Position>({
    initialRequest: request,
    filter: onlyServerFilterKeys(filteredPositions.filter),
    initialSort: persistedBlotterTable.initialSort,
    rowID: 'rowID' satisfies keyof Position,
    density: BlotterDensity.Comfortable,
    clientLocalFilter,
    onSortChanged: persistedBlotterTable.onSortChanged,
    columns: persistedBlotterTable.columns,
    onColumnsChanged: persistedBlotterTable.onColumnsChanged,
    ...({ autoGroupColumnDef } satisfies GridOptions),
  });

  return useMemo(
    () => ({ blotterTable, blotterTableFilterProps, filterBuilderAccordion }),
    [blotterTable, blotterTableFilterProps, filterBuilderAccordion]
  );
};

function onlyServerFilterKeys(filter: DateRangeFilter | undefined) {
  if (!filter) {
    return filter;
  }
  return pick(filter, ['EndDate', 'StartDate', 'MarketAccounts']);
}
