import { AuthContextProvider, PortalContextProvider } from '@talos/kyoko';
import { Login } from 'components/Login';
import { EssentialProviders, Providers } from 'components/Providers';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { Layout } from './components/Layout';
import { Unauthorized } from './components/Unauthorized';

function App() {
  return (
    <BrowserRouter>
      <PortalContextProvider>
        <AuthContextProvider
          loginUrl={import.meta.env.VITE_AUTH_LOGIN_URL}
          logoutUrl={import.meta.env.VITE_AUTH_LOGOUT_URL}
          sessionCookieName={import.meta.env.VITE_SESSION_COOKIE}
        >
          <EssentialProviders>
            <Switch>
              <Route exact path="/unauthorized" component={Unauthorized} />
              <Route exact path="/login" component={Login} />
              <Route path="/">
                <Providers>
                  <Layout />
                </Providers>
              </Route>
            </Switch>
          </EssentialProviders>
        </AuthContextProvider>
      </PortalContextProvider>
    </BrowserRouter>
  );
}

export default App;
