import { createContext, useContext } from 'react';
import type { Observable } from 'rxjs';
import type { MarketAccountCurrency } from '../types/MarketAccountCurrency';

export const MarketAccountCurrenciesContext = createContext<MarketAccountCurrenciesContextProps | undefined>(undefined);
MarketAccountCurrenciesContext.displayName = 'MarketAccountCurrenciesContext';

export function useMarketAccountCurrenciesContext() {
  const context = useContext(MarketAccountCurrenciesContext);
  if (context === undefined) {
    throw new Error('Missing MarketAccountCurrencies.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

/** Get a MarketAccountCurrency key for your MarketAccountID + Currency */
export function getMACKey(marketAccountID: number, currency: string): string;
/** Get a unique key for your MarketAccountCurrency */
export function getMACKey(mktAccCurrency: MarketAccountCurrency): string;
export function getMACKey(MACOrMktAccID: MarketAccountCurrency | number, currency?: string): string {
  if (typeof MACOrMktAccID === 'number') {
    return `${MACOrMktAccID}-${currency}`;
  } else {
    return `${MACOrMktAccID.MarketAccountID}-${MACOrMktAccID.Currency}`;
  }
}

export interface MarketAccountCurrenciesContextProps {
  /** Market account currencies by unique key. Use the getMACKey function to get a key for your MAC. */
  marketAccountCurrenciesByKeyObs: Observable<Map<string, MarketAccountCurrency>>;
  marketAccountCurrenciesListObs: Observable<MarketAccountCurrency[]>;
  /** This mapping provides easy lookup to see if an asset is selectable at all for transfer within a Market */
  acceptedCurrenciesByMarketObs: Observable<Map<string, Set<string>>>;
  /** This mapping provides easy lookup to see if an asset is selectable at all for transfer within a MarketAccount */
  acceptedCurrenciesByMarketAccountIDObs: Observable<Map<number, Set<string>>>;
  /** A straight set of the accepted currencies, taken from the MarketAccountCurrencies subscription */
  acceptedCurrenciesObs: Observable<Set<string>>;
}
