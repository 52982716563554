const hostname = window.location.hostname;

export const isNonProdEnv = ['local', 'staging', 'uat', 'alpha'].includes(import.meta.env.VITE_AVA_ENV);

export const isSandboxEnv = import.meta.env.VITE_AVA_ENV === 'sandbox';

export const isDemoEnv = isNonProdEnv || isSandboxEnv;

export const isTalosWLSandboxEnv =
  isSandboxEnv && ['sandbox.whitelabel-talos.com', 'cryptoco-otc-sandbox.whitelabel-talos.com'].includes(hostname);

export const isTalosWLDemoEnv = isNonProdEnv || isTalosWLSandboxEnv;
