import React, { createContext, useEffect, useMemo } from 'react';
import { useSocketClient } from '../../WebSocketClientProvider';
import { useWLOrderFormStore } from '../WLOrderFormStore';
import { RFQService } from './RFQService';

type RFQServiceContextProps = RFQService;
export const RFQServiceContext = createContext<RFQServiceContextProps | undefined>(undefined);
export function RFQServiceProvider(props: { children: React.ReactNode }) {
  const store = useWLOrderFormStore();
  const webSocketClient = useSocketClient();

  const service = useMemo(() => new RFQService(store, webSocketClient), [store, webSocketClient]);

  useEffect(() => {
    return () => {
      service.destroy();
    };
  }, [service]);

  return <RFQServiceContext.Provider value={service}>{props.children}</RFQServiceContext.Provider>;
}

export const useRFQServiceContext = () => {
  const context = React.useContext(RFQServiceContext);
  if (context === undefined) {
    throw new Error('Missing RFQServiceContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};
