import type { CreditExposure, UsePersistedBlotterTable } from '@talos/kyoko';
import { useDateAndQuickTextFilter } from './useDateAndQuickTextFilter';

interface UseCreditExposuresFilterParams<TData extends CreditExposure> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
}

export function useCreditExposuresFilter<TData extends CreditExposure>({
  persistedBlotterTable,
}: UseCreditExposuresFilterParams<TData>) {
  return useDateAndQuickTextFilter<TData>({ persistedBlotterTable });
}
