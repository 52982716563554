import { snakeCase } from 'lodash';
import type { FC, SVGAttributes } from 'react';
import styled, { useTheme } from 'styled-components';
import { marketLogoLookup } from './markets';

const Wrapper = styled.svg`
  vertical-align: middle;
`;

type LogoContent = FC<{ fill: string }>;

export const MarketLogo = ({
  market,
  size = 24,
  title,
  ...props
}: { market?: string; size?: number | string; title?: string } & SVGAttributes<SVGElement>) => {
  const { colorTextAttention, backgroundContent, fontFamily } = useTheme();
  const logoName = snakeCase(market) ?? '';

  const Logo: LogoContent =
    marketLogoLookup[btoa(logoName)] ||
    (props => (
      <text fontSize="144" fontWeight="bold" fontFamily={fontFamily} fill={props.fill}>
        <tspan x="78" y="178">
          {market?.charAt(0).toUpperCase()}
        </tspan>
      </text>
    ));

  return (
    <Wrapper
      width={size}
      height={size}
      {...props}
      viewBox="0 0 256 256"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title && <title>{title}</title>}
      <circle fill={colorTextAttention} cx="128" cy="128" r="128" />
      <Logo fill={backgroundContent} />
    </Wrapper>
  );
};
