import styled from 'styled-components';
import { InputWrapper as RawInputWrapper } from '../Form/Input/styles';

export const SecuritySelectWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const InputWrapper = styled(RawInputWrapper)<{ isOpen: boolean }>`
  ${({ theme, isOpen }) =>
    isOpen &&
    `
    background: ${({ theme }) => theme.backgroundContent};
    border-radius: ${theme.borderRadiusDefault}px ${theme.borderRadiusDefault}px 0 0;
    &:focus-within {
      border-bottom-color: transparent;
      box-shadow: none;
    }
  `}
  background: ${({ theme, disabled }) => (disabled ? theme.backgroundSelectDisabled : theme.backgroundSelect)};

  input::placeholder {
    color: ${({ theme, disabled }) => (disabled ? theme.colorTextSubtle : theme.colorTextImportant)};
  }
`;

export const Preposition = styled.div<{ disabled?: boolean }>`
  margin: 0 ${({ theme }) => theme.spacingDefault}px;
  color: ${({ theme, disabled }) => (disabled ? theme.colorTextSubtle : 'inherit')};
  width: 2rem;
  text-align: center;
`;

export const DropdownMenu = styled.ul<{ isOpen: boolean }>`
  background: ${({ theme }) => theme.backgroundContent};
  border-radius: 0 0 ${({ theme }) => theme.borderRadiusDefault}px ${({ theme }) => theme.borderRadiusDefault}px;
  border: solid 1px ${({ theme }) => theme.borderColorInputFocus};
  border-top: none;
  left: 0;
  list-style-type: none;
  margin: 0;
  margin-top: ${({ theme }) => theme.spacingTiny * -1}px;
  padding: 0;
  position: absolute;
  right: 0;
  z-index: 1;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transform: ${({ isOpen }) => (isOpen ? `scaleY(1)` : `scaleY(0.9)`)};
  transform-origin: top center;
  transition: transform 200ms ease-out;
  max-height: 60vh;
  overflow-y: auto;
  backdrop-filter: blur(10px);
`;

export const MenuItem = styled.li<{ highlighted: boolean }>`
  padding: ${({ theme }) => theme.spacingDefault}px;
  transition: background 200ms ease;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    flex: 0 0 auto;
  }

  ${({ highlighted, theme }) =>
    highlighted &&
    `
      background: ${theme.backgroundContentHover};
      color: ${theme.colorTextAttention};
    `}

  label {
    line-height: 1;
    padding-left: ${({ theme }) => theme.spacingDefault * 2 + 1}px;
    color: ${({ theme }) => theme.colorTextDefault};
    pointer-events: none;

    div {
      font-size: ${({ theme }) => theme.fontSizeTiny}rem;
      color: ${({ theme }) => theme.colorTextSubtle};
    }
  }
`;

export const CurrencySelectWrapper = styled.div`
  flex: 1 1 0;
  width: 0;
  position: relative;
`;

export const CurrencyIconWrapper = styled.div`
  margin-right: ${({ theme }) => theme.spacingDefault}px;
  padding-right: ${({ theme }) => theme.spacingDefault}px;
  border-right: solid 1px ${({ theme }) => theme.borderColorInput};
  align-self: center;

  svg {
    display: block;
  }
`;
