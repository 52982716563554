import type { MarketAccount } from '../contexts/MarketAccountsContext';
import { MarketAccountStatusEnum, MarketAccountTypeEnum, type IPricingRule } from '../types/types';
import { GET, request } from '../utils/http';

export const DEFAULT_CUSTOMER_MARKET_ACCOUNT = 'default';

export function getDefaultCustomerMarketAccountSourceAccountID(mktAccIDs: string[]): string | undefined {
  if (mktAccIDs.length === 0) {
    return undefined;
  }

  if (mktAccIDs.length === 1) {
    return mktAccIDs[0];
  }

  const maybeDefaultAccount = mktAccIDs.find(mktAcc => mktAcc === DEFAULT_CUSTOMER_MARKET_ACCOUNT);
  if (maybeDefaultAccount) {
    return maybeDefaultAccount;
  } else {
    // we have no concept of a default (shouldnt happen, but if we have bad setup it might), so just pick the first one alphabetically
    return mktAccIDs.sort()[0];
  }
}

export function getDefaultCustomerMarketAccount(mktAccs: MarketAccount[]): MarketAccount | undefined {
  const sourceAccIDs = mktAccs
    .filter(ma => ma.Status === MarketAccountStatusEnum.Active && ma.Type === MarketAccountTypeEnum.Customer)
    .map(ma => ma.SourceAccountID ?? '');
  const defaultSourceAccID = getDefaultCustomerMarketAccountSourceAccountID(sourceAccIDs);
  if (defaultSourceAccID == null) {
    return undefined;
  } else {
    return mktAccs.find(ma => ma.SourceAccountID === defaultSourceAccID);
  }
}

const getEffectivePricingRule = (
  orgApiEndpoint: string,
  counterparty: string,
  symbol: string
): Promise<{ data: IPricingRule[] } | undefined> => {
  return request(GET, `${orgApiEndpoint}/pricing-rules/counterparty/${counterparty}/security/${symbol}/effective`);
};

export async function getDefaultCustomerSubAccount(
  orgApiEndpoint: string,
  counterparty: string,
  symbol: string
): Promise<{ TradeSubAccount: string; HedgeSubAccount: string } | undefined> {
  const res = await getEffectivePricingRule(orgApiEndpoint, counterparty, symbol);
  // TradeSubAccount is optional and therefore the default Customer Sub Account could be blank

  const data = res?.data.at(0);

  if (data == null) {
    return undefined;
  }
  return { TradeSubAccount: data.TradeSubAccount, HedgeSubAccount: data.HedgeSubAccount };
}
