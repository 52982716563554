import { sortBy } from 'lodash';
import type React from 'react';
import { memo, useState } from 'react';
import { asyncScheduler } from 'rxjs';
import { map, shareReplay, throttleTime } from 'rxjs/operators';
import { BalancesContext } from '../contexts';
import { useObservable, useSubscription } from '../hooks';
import { wsScanToMap } from '../pipes';
import type { Balance, BalanceRequest } from '../types';

// Use BalanceDelta when available?
const BALANCE = 'Balance';

function getBalanceKey(b: Balance) {
  return `${b.Currency}-${b.MarketAccount}`;
}

export const WLBalancesProvider = memo((props: React.PropsWithChildren<unknown>) => {
  const [request] = useState<BalanceRequest>({
    name: BALANCE,
    tag: BALANCE,
    ShowZeroBalances: true,
  });
  const { data: subscription } = useSubscription<Balance>(request);

  const listBalances = useObservable(
    () =>
      subscription.pipe(
        wsScanToMap({ getUniqueKey: getBalanceKey, newMapEachUpdate: false }),
        map(memo => sortBy([...memo.values()], 'Currency')), // TODO sort by multi-key
        throttleTime(500, asyncScheduler, {
          leading: true,
          trailing: true,
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [subscription]
  );

  const balancesByCurrencyMarketAccount = useObservable(
    () =>
      listBalances.pipe(
        map(balances =>
          balances.reduce((memo, balance) => {
            const currency = balance.Currency;
            const mktAcc = balance.MarketAccount;

            const currencyMktAccsMap: Map<string, Balance> = memo.has(currency) ? memo.get(currency)! : new Map();
            currencyMktAccsMap.set(mktAcc, balance);

            memo.set(currency, currencyMktAccsMap);

            return memo;
          }, new Map<string, Map<string, Balance>>())
        ),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [listBalances]
  );

  const balancesByMarketAccountCurrency = useObservable(
    () =>
      listBalances.pipe(
        map(balances =>
          balances.reduce((memo, balance) => {
            const currency = balance.Currency;
            const mktAcc = balance.MarketAccount;

            const mktAccCurrencies: Map<string, Balance> = memo.has(mktAcc) ? memo.get(mktAcc)! : new Map();
            mktAccCurrencies.set(currency, balance);

            memo.set(mktAcc, mktAccCurrencies);

            return memo;
          }, new Map<string, Map<string, Balance>>())
        ),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [listBalances]
  );

  return (
    <BalancesContext.Provider
      value={{
        listBalances,
        balancesByCurrencyMarketAccount,
        balancesByMarketAccountCurrency,
      }}
    >
      {props.children}
    </BalancesContext.Provider>
  );
});
