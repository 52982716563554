export const Logo = ({ fill }) => (
  <>
    <path d="M65 65H191V191H65V149H63V193H193V63H63V107H65V65Z" fill="black" />
    <path
      d="M105.949 115.843L100.986 118.36C100.058 116.981 99.1737 116.082 98.3333 115.663C97.4579 115.184 96.3286 114.944 94.9455 114.944C93.2471 114.944 91.8377 115.356 90.7172 116.18C89.5966 116.989 89.0364 118.007 89.0364 119.236C89.0364 120.929 90.5071 122.292 93.4485 123.326L97.4929 124.742C100.785 125.88 103.192 127.273 104.715 128.921C106.238 130.554 107 132.562 107 134.944C107 138.135 105.757 140.772 103.271 142.854C100.767 144.951 97.6593 146 93.9475 146C90.4283 146 87.5219 145.109 85.2283 143.326C82.9697 141.543 81.5603 139.034 81 135.798L87.198 134.629C87.4781 136.667 87.9683 138.075 88.6687 138.854C89.9293 140.352 91.7677 141.101 94.1838 141.101C96.0923 141.101 97.6768 140.554 98.9374 139.461C100.198 138.367 100.828 136.981 100.828 135.303C100.828 134.629 100.714 134.015 100.487 133.461C100.277 132.891 99.9354 132.375 99.4626 131.91C99.0074 131.431 98.4121 130.989 97.6768 130.584C96.9414 130.165 96.066 129.768 95.0505 129.393L91.1374 128C85.5872 125.993 82.8121 123.056 82.8121 119.191C82.8121 116.584 83.9764 114.404 86.3051 112.652C88.6337 110.884 91.5313 110 94.998 110C99.6727 110 103.323 111.948 105.949 115.843Z"
      fill="black"
    />
    <path
      d="M47 127.862C47 123.247 48.6782 119.282 52.0347 115.969C55.3771 112.656 59.3936 111 64.0843 111C68.7187 111 72.6931 112.671 76.0074 116.012C79.3358 119.353 81 123.367 81 128.053C81 132.768 79.3288 136.767 75.9864 140.052C72.6299 143.351 68.5783 145 63.8315 145C59.6324 145 55.8616 143.535 52.5192 140.604C48.8397 137.362 47 133.115 47 127.862ZM51.9504 127.926C51.9504 131.55 53.1582 134.53 55.5737 136.866C57.9752 139.202 60.7489 140.37 63.8947 140.37C67.3073 140.37 70.1863 139.181 72.5316 136.803C74.8769 134.396 76.0496 131.465 76.0496 128.011C76.0496 124.514 74.891 121.583 72.5737 119.219C70.2706 116.84 67.4197 115.651 64.0211 115.651C60.6365 115.651 57.7786 116.84 55.4473 119.219C53.1161 121.569 51.9504 124.471 51.9504 127.926Z"
      fill="black"
    />
    <path d="M117.258 110.209V141H132V146H111.162V110.209H117.258Z" fill="black" />
  </>
);
