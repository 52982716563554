import type { ICellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import type { SubAccountReconCheckpoint, SubAccountReconCheckpointEvalStatusEnum } from '../../../types';
import {
  RECON_CHECKPOINT_EVAL_STATUS_TEXT_MAPPING,
  ReconCheckpointEvalStatus,
} from '../../Status/ReconCheckpointEvalStatus';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

export const reconCheckpointEvalStatus: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  cellRenderer: ({
    value,
  }: ICellRendererParams<SubAccountReconCheckpoint, SubAccountReconCheckpointEvalStatusEnum>) => {
    if (value == null) {
      return null;
    }

    return <ReconCheckpointEvalStatus status={value} />;
  },
  valueFormatter: ({ value }: ValueFormatterParams<unknown, SubAccountReconCheckpointEvalStatusEnum>) => {
    if (!value) {
      return '';
    }

    return RECON_CHECKPOINT_EVAL_STATUS_TEXT_MAPPING[value] ?? value;
  },
});
