import { memo, useMemo } from 'react';
import {
  DefaultFavoriteSecuritiesConfig,
  DefaultFavoriteSecuritiesContext,
  FavoriteSecuritiesContext,
  type FavoriteSecuritiesConfig,
  type FavoriteSecuritiesContextProps,
} from '../contexts';
import type { WLCustomerUserConfig } from '../types';
import { useWLCustomerUserConfigContext } from './WLCustomerUserConfigContextProvider';

export const WLFavoriteSecuritiesProvider = memo((props: React.PropsWithChildren<unknown>) => {
  const { config, updateConfig } = useWLCustomerUserConfigContext();

  const value = useMemo(() => {
    if (config) {
      return {
        ...DefaultFavoriteSecuritiesConfig,
        ...config?.favoriteSecurites,
        setFavoriteSecurities: (favoriteSecurities: string[]) => {
          updateFavoritesConfig(updateConfig, config, { favoriteSecurities });
        },
      } satisfies FavoriteSecuritiesContextProps;
    } else {
      return {
        ...DefaultFavoriteSecuritiesContext,
        setSecurityTypes: undefined,
      };
    }
  }, [config, updateConfig]);

  return <FavoriteSecuritiesContext.Provider value={value}>{props.children}</FavoriteSecuritiesContext.Provider>;
});

function updateFavoritesConfig(
  updateConfig: (config: Partial<WLCustomerUserConfig>) => void,
  currentConfig: WLCustomerUserConfig,
  newConfig: Partial<FavoriteSecuritiesConfig>
) {
  updateConfig({
    favoriteSecurites: {
      ...DefaultFavoriteSecuritiesConfig,
      ...currentConfig?.favoriteSecurites,
      ...newConfig,
    },
  });
}
