import type { CustomerRole } from './CustomerRole';
import type { ModeEnum } from './types';

export interface MarketAccountAuthorizations {
  [customerAccount: string]: string[];
}
export class CustomerUser {
  Config!: { Key: string; Value: string }[];
  CustomerUserID!: string;
  DisplayName!: string;
  Email!: string;
  ExternalID!: string;
  Counterparty!: string;
  Roles!: CustomerRole[];
  Mode!: ModeEnum;

  Permissions?: string[];

  // no value here means the user will have trade/write access to all accounts
  MarketAccountAuthorizations?: MarketAccountAuthorizations;

  constructor(data: CustomerUser) {
    Object.assign(this, data);
  }
}
