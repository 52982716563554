import {
  Box,
  FormattedMessage,
  IconButton,
  IconName,
  Modal,
  useWLOrgConfigContext,
  type ModalProps,
} from '@talos/kyoko';
import { useTheme } from 'styled-components';

import { Logo } from '../../components/Logo';

import { defineMessages } from 'react-intl';
import { SupportWrapper } from './styles';

const messages = defineMessages({
  supportContacts: {
    defaultMessage: 'Support contacts',
    id: 'SupportModal.supportContacts',
  },
  email: {
    defaultMessage: 'Email',
    id: 'SupportModal.email',
  },
  phone: {
    defaultMessage: 'Phone',
    id: 'SupportModal.phone',
  },
  telegram: {
    defaultMessage: 'Telegram',
    id: 'SupportModal.telegram',
  },
  website: {
    defaultMessage: 'Website',
    id: 'SupportModal.website',
  },
});

export const SupportModal = (props: ModalProps) => {
  const { config } = useWLOrgConfigContext();
  const { supportEmail, supportPhone, supportTelegram, supportWebsite, supportInfo } = config;
  const { type } = useTheme();

  return (
    <Modal {...props}>
      <SupportWrapper>
        <IconButton ghost onClick={() => props.close()} icon={IconName.Close} />
        <h2>
          <FormattedMessage {...messages.supportContacts} />
        </h2>
        {supportEmail && (
          <Box justifyContent="space-between">
            <span>
              <FormattedMessage {...messages.email} />:
            </span>
            <div>
              {(Array.isArray(supportEmail) ? supportEmail : [supportEmail]).map(url => (
                <a key={url} href={`mailto:${url}`}>
                  {url}
                </a>
              ))}
            </div>
          </Box>
        )}
        {supportPhone && (
          <Box justifyContent="space-between">
            <span>
              <FormattedMessage {...messages.phone} />:
            </span>
            <div>
              {(Array.isArray(supportPhone) ? supportPhone : [supportPhone]).map(url => (
                <a key={url} href={`tel:${url}`}>
                  {url}
                </a>
              ))}
            </div>
          </Box>
        )}
        {supportTelegram && (
          <Box justifyContent="space-between">
            <span>
              <FormattedMessage {...messages.telegram} />:
            </span>
            <div>
              {(Array.isArray(supportTelegram) ? supportTelegram : [supportTelegram]).map(url => (
                <a key={url} href={`tg://t.me/${url}`}>
                  {url}
                </a>
              ))}
            </div>
          </Box>
        )}
        {supportWebsite && (
          <Box justifyContent="space-between">
            <span>
              <FormattedMessage {...messages.website} />:
            </span>
            <div>
              {(Array.isArray(supportWebsite) ? supportWebsite : [supportWebsite]).map(url => (
                <a key={url} href={`//${url}`} target="_blank" rel="noreferrer">
                  {url}
                </a>
              ))}
            </div>
          </Box>
        )}
        {supportInfo && (
          <Box pt="spacingMedium" textAlign="center" justifyContent="center">
            {supportInfo}
          </Box>
        )}
        <Logo themeType={type} width="100%" key={type} />
      </SupportWrapper>
    </Modal>
  );
};
