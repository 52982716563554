export const Logo = ({ fill }) => (
  <>
    <polygon fill={fill} fillRule="nonzero" points="163 77 175 77 175 155 163 155" />
    <polygon fillOpacity="0.75" fill={fill} fillRule="nonzero" points="116 141 140 141 140 203 116 203" />
    <polygon fillOpacity="0.75" fill={fill} fillRule="nonzero" points="116 53 140 53 140 115 116 115" />
    <polygon fillOpacity="0.75" fill={fill} fillRule="nonzero" points="163 159 175 159 175 179 163 179" />
    <polygon fill={fill} fillRule="nonzero" points="82 101 94 101 94 179 82 179" />
    <polygon fillOpacity="0.75" fill={fill} fillRule="nonzero" points="82 77 94 77 94 97 82 97" />
  </>
);
