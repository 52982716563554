import { useMemo } from 'react';
import { getAgGridColId } from '../components/BlotterTable/columns/getAgGridColId';
import type { Column, ColumnDef } from '../components/BlotterTable/columns/types';
import { useDefaultColumns } from '../components/BlotterTable/useDefaultColumns';
import { EMPTY_ARRAY } from '../utils/empty';
import type {
  ExecTypeEnum,
  OrdRejReasonEnum,
  OrdStatusEnum,
  OrdTypeEnum,
  SideEnum,
  TimeInForceEnum,
  TradeStatusEnum,
} from './types';

// ExecutionReport5000
export class MarketExecutionReport {
  static readonly rowID = 'EID';
  static readonly defaultColumns = [
    'Timestamp',
    'ExecType',
    'OrderQty',
    'Price',
    'MarketAccount',
    'ExecID',
    'EID',
    'OID',
    'LastQty',
    'LastPx',
    'LastFee',
    'LastAmt',
    'AggressorSide',
  ] satisfies (keyof MarketExecutionReport)[];

  Timestamp?: string;

  MessageID?: string;

  Market?: string;

  Symbol?: string;

  CredentialID?: string;

  OrderID?: string;

  ClOrdID?: string;

  OrigClOrdID?: string;

  SubmitTime?: string;

  ExecID?: string;

  Side?: SideEnum;

  TransactTime?: string;

  ExecType?: ExecTypeEnum;

  OrdStatus?: OrdStatusEnum;

  OrderQty?: string;

  OrdType?: OrdTypeEnum;

  Price?: string;

  Currency?: string;

  LeavesQty?: string;

  CumQty?: string;

  AvgPx?: string;

  TimeInForce?: TimeInForceEnum;

  LastPx?: string;

  LastQty?: string;

  LastAmt?: string;

  LastFee?: string;

  CumAmt?: string;

  CumFee?: string;

  FeeCurrency?: string;

  OrdRejReason?: OrdRejReasonEnum;

  ClOID?: string;

  OID?: string;

  EID?: string;

  TradeStatus?: TradeStatusEnum;

  AggressorSide?: SideEnum;

  QID?: string;

  AmountCurrency?: string;

  ParentOrderID?: string;

  RID?: string;

  ExpectedFillPrice?: string;

  ExpectedFillQty?: string;

  MarketAccountID?: string;

  MarketAccount?: string;

  Text?: string;

  constructor(data: MarketExecutionReport) {
    Object.assign(this, data);
  }
}

interface UseMarketExecutionReportColumns {
  defaultColumns?: (keyof MarketExecutionReport | Partial<Column>)[];
}

export function useMarketExecutionReportColumns({
  defaultColumns = EMPTY_ARRAY,
}: UseMarketExecutionReportColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            {
              field: 'Timestamp',
              type: 'date',
              sortable: true,
              params: { milliseconds: true },
            },
            {
              field: 'ExecType',
              type: 'text',
              width: 150,
            },
            {
              field: 'OrderQty',
              type: 'size',
              params: { currencyField: 'Currency' },
            },
            {
              field: 'Price',
              type: 'price',
              params: { assetField: 'Symbol' },
              width: 100,
            },
            {
              field: 'MarketAccount',
              type: 'marketAccount',
            },
            {
              field: 'ExecID',
              type: 'id',
              width: 100,
            },
            {
              field: 'EID',
              type: 'text',
            },
            {
              field: 'OID',
              type: 'text',
            },
            {
              field: 'LastQty',
              type: 'size',
              params: { currencyField: 'Currency' },
            },
            {
              field: 'LastPx',
              type: 'price',
              params: { assetField: 'Symbol' },
              width: 100,
            },
            {
              field: 'LastFee',
              type: 'size',
              params: { currencyField: 'FeeCurrency' },
              width: 100,
            },
            {
              field: 'LastAmt',
              type: 'size',
              params: { currencyField: 'AmountCurrency' },
              width: 100,
            },
            {
              field: 'AggressorSide',
              type: 'text',
            },
          ] satisfies ColumnDef<MarketExecutionReport>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );
  const defaultHiddenColumns = useMemo(() => {
    return new Map(
      (
        [
          {
            field: 'MessageID',
            type: 'text',
          },
          {
            field: 'Market',
            type: 'market',
          },
          {
            field: 'Symbol',
            type: 'security',
          },
          {
            field: 'CredentialID',
            type: 'text',
          },
          {
            field: 'OrderID',
            type: 'id',
          },
          {
            field: 'ClOrdID',
            type: 'id',
          },
          {
            field: 'OrigClOrdID',
            type: 'id',
          },
          {
            field: 'SubmitTime',
            type: 'date',
            sortable: true,
            params: { milliseconds: true },
          },
          {
            field: 'Side',
            type: 'side',
          },
          {
            field: 'TransactTime',
            type: 'date',
            params: { milliseconds: true },
          },
          {
            field: 'OrdStatus',
            type: 'orderStatus',
          },
          {
            field: 'OrdType',
            type: 'text',
          },
          {
            field: 'Currency',
            type: 'currency',
          },
          {
            field: 'LeavesQty',
            type: 'size',
            params: { currencyField: 'Currency' },
          },
          {
            field: 'CumQty',
            type: 'size',
            params: { currencyField: 'Currency' },
          },
          {
            field: 'AvgPx',
            type: 'price',
            params: { assetField: 'Symbol' },
          },
          {
            field: 'TimeInForce',
            type: 'text',
          },
          {
            field: 'CumAmt',
            type: 'size',
            params: { currencyField: 'AmountCurrency' },
          },
          {
            field: 'CumFee',
            type: 'size',
            params: { currencyField: 'FeeCurrency' },
          },
          {
            field: 'FeeCurrency',
            type: 'currency',
          },
          {
            field: 'OrdRejReason',
            type: 'text',
          },
          {
            field: 'ClOID',
            type: 'text',
          },
          {
            field: 'TradeStatus',
            type: 'text',
          },
          {
            field: 'QID',
            type: 'text',
          },
          {
            field: 'AmountCurrency',
            type: 'currency',
          },
          {
            field: 'ParentOrderID',
            type: 'id',
          },
          {
            field: 'RID',
            type: 'text',
          },
          {
            field: 'ExpectedFillPrice',
            type: 'price',
            params: { assetField: 'Symbol' },
          },
          {
            field: 'ExpectedFillQty',
            type: 'size',
            params: { currencyField: 'Currency' },
          },
          {
            field: 'MarketAccountID',
            type: 'text',
          },
          {
            field: 'Text',
            type: 'text',
          },
        ] satisfies (false | ColumnDef<MarketExecutionReport>)[]
      ).map(c => [getAgGridColId(c), { ...c, hide: true }])
    );
  }, []);
  const columnDefinitions = useMemo(() => {
    return new Map(
      (
        [
          ...defaultVisibleColumns.values(),
          ...defaultHiddenColumns.values(),
        ] satisfies ColumnDef<MarketExecutionReport>[] as Column[]
      ).map(c => [getAgGridColId(c), c])
    );
  }, [defaultVisibleColumns, defaultHiddenColumns]);
  return useDefaultColumns(defaultColumns, columnDefinitions);
}
