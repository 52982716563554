import type { ValueGetterParams } from 'ag-grid-community';
import { get } from 'lodash';
import { defineMessages } from 'react-intl';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

const messages = defineMessages({
  marketAccountGroup: {
    defaultMessage: 'Market Account Group',
    id: 'BlotterTable.columns.marketAccountGroup',
  },
});

export interface MarketAccountGroupDerivedColumnParams {
  /** The field for resolving the MarketAccount.Name off the blotter data entity */
  marketAccountField?: string;
}

/** Shows the group of a market account, deriving off of some market account field */
export const marketAccountGroupDerived: ColDefFactory<Column<MarketAccountGroupDerivedColumnParams>> = column => ({
  headerValueGetter: ({ context }) => context.current.intl.formatMessage(messages.marketAccountGroup),
  ...baseColumn(column),
  valueGetter: ({ data, context }: ValueGetterParams<unknown>) => {
    const marketAccountField = column.params?.marketAccountField;
    if (!marketAccountField) {
      return '';
    }

    const marketAccountName = get(data, marketAccountField);
    if (!marketAccountName) {
      return '';
    }

    const marketAccountsByName = context.current.marketAccountsByName;
    return marketAccountsByName?.get(marketAccountName)?.Group ?? '';
  },
});
