import type { FormControlProps } from '../../Form/types';
import { Input } from '../Input';
import { useNumberInput } from './useNumberInput';
export { useNumberInput } from './useNumberInput';

export const NumberInput = (props: NumberInputProps) => {
  const inputProps = useNumberInput(props);

  return <Input {...inputProps} type="number" />;
};

export type NumberInputProps = {
  value: string;
  onChange: (value: string) => void;
  minIncrement?: string;
  defaultIncrement?: string;
  largeIncrement?: string;
  min?: string;
  max?: string;
} & Omit<FormControlProps<HTMLInputElement>, 'onChange' | 'value' | 'type' | 'step' | 'security'>; // Instead of step, use minIncrement, defaultIncrement and largeIncrement
