import type { DefaultTheme } from 'styled-components';
import { FormControlSizes } from '../Form/types';

export const getButtonBorderRadius = (theme: DefaultTheme, size = FormControlSizes.Default) => {
  switch (size) {
    case FormControlSizes.Xxs: {
      return theme.borderRadiusButtonTiny;
    }
    case FormControlSizes.Tiny: {
      return theme.borderRadiusButtonTiny;
    }
    case FormControlSizes.Small: {
      return theme.borderRadiusButtonSmall;
    }
    case FormControlSizes.Large: {
      return theme.borderRadiusButtonLarge;
    }
    case FormControlSizes.Default:
    default: {
      return theme.borderRadiusButtonDefault;
    }
  }
};
