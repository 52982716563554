import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { darkColors, mapDarkTheme } from './DarkTheme';

const colors: Colors = {
  ...darkColors,

  gray: {
    main: '#282829',

    // https://hihayk.github.io/scale/#4/7/39/95/-360/139/20/100/282829/40/40/41/white
    // worked well for me
    '000': '#181819',
    '010': '#1C1D1D',
    '020': '#212120',
    '030': '#252425',
    '040': '#454646',
    '050': '#626363',
    '060': '#808080',
    '070': '#9D9D9D',
    '080': '#BABABA',
    '090': '#D7D7D7',
    '100': '#F4F4F4',
  },
  yellow: {
    default: '#F87700',
    lighten: '#ff9e45',
    mute: setAlpha(0.1, '#F87700'),
    dim: setAlpha(0.4, '#F87700'),
  },
  primary: {
    default: '#DCA950',
    lighten: '#e6bb70',
    mute: setAlpha(0.1, '#DCA950'),
    dim: setAlpha(0.4, '#DCA950'),
  },
};

export const DarkOneIOTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkOIO',

  backgroundSidebar: colors.gray.main,
});
