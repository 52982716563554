import { animated } from '@react-spring/web';
import styled from 'styled-components';
import { maxWidth, MEDIA } from '../../styles';

export const MarketPickerWrapper = styled(animated.div)`
  background: ${({ theme }) => theme.backgroundContent};
  display: flex;
  flex-direction: column;
  bottom: 0;
  top: 0;
  width: 400px;
  transition: transform 500ms ease, opacity 500ms ease;

  ${maxWidth(MEDIA.medium)} {
    width: 300px;
  }
`;

export const Filter = styled.div`
  flex: 0 0 auto;
  padding: ${({ theme }) => theme.spacingBig}px;
`;

export const Markets = styled.div`
  flex: 1 1 0;
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    transition: ease 200ms width;
  }

  &:hover {
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 10px;
    }
  }
`;

export const MarketItemWrapper = styled(animated.div)`
  transition: max-height 200ms ease, opacity 200ms ease;
`;

export const MarketItem = styled.div<{ highlighted: boolean }>`
  padding: ${({ theme }) => `${theme.spacingDefault}px ${theme.spacingBig}px`};
  color: ${({ theme }) => theme.colorTextDefault};
  font-size: ${({ theme }) => theme.fontSizeLarge}rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: color 200ms ease, background 200ms ease;
  overflow: hidden;

  svg {
    margin-right: ${({ theme }) => theme.spacingDefault}px;
  }

  span {
    flex: 1;
  }

  i {
    opacity: 0;
    transition: opacity 200ms ease;
    color: ${({ theme }) => theme.colorTextImportant};
  }

  ${maxWidth(MEDIA.medium)} {
    font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  }

  ${({ theme, highlighted }) =>
    highlighted
      ? `
    background: ${theme.backgroundContentHover};
    color: ${theme.colorTextAttention};

    i {
      opacity: 1;
    }
  `
      : ''}

  &:hover {
    background: ${({ theme }) => theme.backgroundContentHover};
    color: ${({ theme }) => theme.colorTextAttention};
  }
`;
