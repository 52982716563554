import { animated } from '@react-spring/web';
import { darken } from 'polished';
import styled, { keyframes } from 'styled-components';

const loading = keyframes`
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -200% -200%;
  }
`;

export const RemainingTimeWrapper = styled.div`
  border-radius: 0.375rem;
  margin-top: 0.5rem;
  position: relative;
  height: 0.75rem;
  background: ${({ theme }) => theme.backgroundProgressBar};
  overflow: hidden;
`;

export const TimeBar = styled(animated.div)`
  top: 0;
  right: 0;
  height: 100%;
  position: absolute;
  background: ${({ theme }) => theme.backgroundProgressBarMeter};
  border-radius: 0.375rem;
  width: 100%;
`;

export const LoadingBar = styled.div`
  height: 100%;
  background: linear-gradient(
    270deg,
    ${({ theme }) => theme.backgroundProgressBarIndeterminate},
    ${({ theme }) => darken(0.1, theme.backgroundProgressBarIndeterminate)},
    ${({ theme }) => theme.backgroundProgressBarIndeterminate}
  );
  background-position: 0% 0%;
  background-size: 200%;
  animation: ${loading} 1s linear infinite;
  border-radius: 0.375rem;
`;

export const TimeLabel = styled.div`
  color: ${({ theme }) => theme.colorTextImportant};
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  text-align: right;
  padding-top: ${({ theme }) => theme.spacingTiny}px;
`;
