import { useMemo } from 'react';
import { toBigWithDefault } from '../../../utils';
import type { PriceQuickOption } from '../../QuickOptions';

export const MIN_BPS_OFFSET = 1;
export const MAX_BPS_OFFSET = 10_000;

interface UsePriceInputProps {
  priceOffsets: string;
}

const BID_OFFER_PRICE_OPTIONS: PriceQuickOption[] = [
  {
    label: 'Bid',
    value: 'bid',
  },
  {
    label: 'Offer',
    value: 'offer',
  },
];

export const DEFAULT_PRICE_OPTIONS: PriceQuickOption[] = [
  {
    label: '-25bps',
    value: '-0.0025',
  },
  {
    label: '-10bps',
    value: '-0.001',
  },
  ...BID_OFFER_PRICE_OPTIONS,
  {
    label: '+10bps',
    value: '0.001',
  },
  {
    label: '+25bps',
    value: '0.0025',
  },
];

export function usePriceInput({ priceOffsets }: UsePriceInputProps) {
  const quickOptions = useMemo(() => {
    if (!priceOffsets) {
      return DEFAULT_PRICE_OPTIONS;
    }
    const offsets = priceOffsets.split('|');
    if (!offsets || offsets.some(offset => isNaN(parseInt(offset)) || !offset.match(/^[0-9]+$/))) {
      return DEFAULT_PRICE_OPTIONS;
    }
    const result: PriceQuickOption[] = [];
    for (const offset of offsets.slice().reverse()) {
      result.push(getQuickOption(offset, true));
    }
    result.push(...BID_OFFER_PRICE_OPTIONS);
    for (const offset of offsets) {
      result.push(getQuickOption(offset, false));
    }
    return result;
  }, [priceOffsets]);

  return { quickOptions };
}

function getQuickOption(offset: string, negative: boolean) {
  const sign = negative ? '-' : '+';
  const value = parseInt(offset);
  const offsetValue = Math.min(MAX_BPS_OFFSET, Math.max(MIN_BPS_OFFSET, value));
  return {
    label: `${sign}${offsetValue}bps`,
    value: toBigWithDefault(offsetValue, 0)
      .times(negative ? -1 : 1)
      .div(10_000)
      .toFixed(),
  };
}
