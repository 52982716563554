import {
  type SubAccountReconCheckpoint,
  SubAccountReconCheckpointStatusEnum,
  type SubAccountReconMatch,
} from '../../types';
import { HStack } from '../Core';
import { Icon, IconName, type IconProps } from '../Icons';
import { Text } from '../Text';
import { ReconMatchStatus } from './ReconMatchStatus';

const StatusIcon = ({
  status,
  ...iconProps
}: { status: SubAccountReconCheckpoint['Status'] } & Omit<IconProps, 'icon' | 'color'>) => {
  switch (status) {
    case SubAccountReconCheckpointStatusEnum.Reconciled:
      return <Icon icon={IconName.CheckCircleSolid} color="colorTextPositive" {...iconProps} />;
    case SubAccountReconCheckpointStatusEnum.Unreconciled:
      return <Icon icon={IconName.ExclamationCircle} color="colorTextWarning" {...iconProps} />;
    case SubAccountReconCheckpointStatusEnum.Discarded:
      return <Icon icon={IconName.CloseCircle} color="colorTextDefault" {...iconProps} />;
    default:
      // dont show any icon if we dont know what it is
      return null;
  }
};

export const ReconCheckpointStatus = ({
  status,
  textColor,
}: {
  status: SubAccountReconCheckpoint['Status'];
  textColor?: string;
}) => {
  return (
    <HStack gap="spacingSmall">
      <StatusIcon status={status} />
      <Text color={textColor}>{status}</Text>
    </HStack>
  );
};

export function isReconCheckpointStatusEnum(value: string): value is SubAccountReconCheckpointStatusEnum {
  return Object.values<string>(SubAccountReconCheckpointStatusEnum).includes(value);
}

/**
 * There's a few cases where we will end up with having one of either a MatchStatus or CheckpointStatus.
 * This simple component helps us handle this.
 */
export const ReconCheckpointOrMatchStatus = ({
  status,
  textColor,
}: {
  status: SubAccountReconCheckpoint['Status'] | SubAccountReconMatch['Status'];
  textColor?: string;
}) => {
  return isReconCheckpointStatusEnum(status) ? (
    <ReconCheckpointStatus status={status} textColor={textColor} />
  ) : (
    <ReconMatchStatus status={status} textColor={textColor} />
  );
};
