import styled from 'styled-components';
import { FormControlSizes } from '../types';
import { getFontSize } from '../utils';

export const CheckboxBox = styled.label``;
export const CheckboxWrapper = styled.label<{ disabled?: boolean; checked?: boolean }>`
  ${p =>
    p.disabled &&
    `
    opacity: 0.4;
    &:hover,
    &:hover input:disabled {
      cursor: not-allowed
    }
  `}
  white-space: nowrap;
  position: relative;

  &:hover {
    ${CheckboxBox} {
      border-width: 2px;
      background: ${({ theme, checked }) =>
        checked ? theme.backgroundCheckboxCheckedHover : theme.backgroundCheckboxHover};
    }
  }
`;

function getCheckboxSize(selectedSizeMult = FormControlSizes.Default): number {
  switch (selectedSizeMult) {
    case FormControlSizes.Tiny:
    case FormControlSizes.Small:
    case FormControlSizes.Default: {
      return 16;
    }
    default: {
      return 20;
    }
  }
}

export const CheckBox = styled.span<{
  isChecked?: boolean;
  isIndeterminate?: boolean;
  isDisabled?: boolean;
  size?: number;
}>`
  vertical-align: text-top;
  position: relative;
  display: inline-block;
  width: ${({ size }) => getCheckboxSize(size)}px;
  height: ${({ size }) => getCheckboxSize(size)}px;
  vertical-align: middle;

  ${CheckboxBox} {
    position: absolute;
    background: ${({ theme, isChecked, isIndeterminate }) =>
      isChecked || isIndeterminate ? theme.backgroundCheckboxChecked : theme.backgroundCheckbox};
    border: 1px solid
      ${({ theme, isChecked, isIndeterminate }) =>
        isChecked || isIndeterminate ? theme.borderColorCheckboxChecked : theme.borderColorCheckbox};
    color: ${({ theme }) => theme.colorCheckboxCheck};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    width: ${({ size }) => getCheckboxSize(size)}px;
    height: ${({ size }) => getCheckboxSize(size)}px;
    transition: border 0.15s ease, background 0.15s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  }

  i {
    line-height: 1;
  }

  input {
    opacity: 0;
    width: 100%;
    height: 100%;
    &:focus ~ label {
      border-width: 2px;
      border-color: ${({ theme, isChecked }) =>
        isChecked ? theme.borderColorCheckboxCheckedFocus : theme.borderColorCheckboxFocus};
    }
  }
`;

export const CheckBoxLabel = styled.label<{ isChecked?: boolean; isDisabled?: boolean; size?: FormControlSizes }>`
  display: inline-block;
  margin-left: ${({ theme, size = FormControlSizes.Default }) =>
    ((theme.spacingTiny + theme.spacingSmall) * size) / 2}px;
  color: ${({ theme }) => theme.colorTextImportant};
  font-size: ${({ size, theme }) => getFontSize(theme, size)}rem;
  transition: color 0.25s ease;
  white-space: nowrap;
  line-height: 1;
  vertical-align: middle;
`;
