import { useLayoutEffect } from 'react';
import { useTourContext } from './TourContext';
import type { ITourProps } from './types';

/**
 * Hook that works similar to React Spring's `useTransition`.
 * Starts/enqueues a tour when `enable` is true.
 *
 * @param tour Name of the tour
 * @param enable Whether to start/enqueue the tour
 * @param enqueue If true, adds the tour to the queue instead of starting it immediately
 * @param delay Delay in ms before starting/enqueueing the tour
 */
export function useTour(
  tour: ITourProps['tour'],
  enable: boolean,
  { enqueue = true, delay = 500 }: Pick<ITourProps, 'enqueue' | 'delay'>
) {
  const { startTour, endTour, enqueueTour, dequeueTour } = useTourContext();

  useLayoutEffect(() => {
    if (!enable) {
      return;
    }

    let isMounted = true;
    const startOrEnqueue = enqueue ? enqueueTour : startTour;

    if (delay === 0) {
      startOrEnqueue(tour);
    } else {
      setTimeout(() => {
        if (isMounted) {
          startOrEnqueue(tour);
        }
      }, delay);
    }

    return () => {
      isMounted = false;
      endTour(tour);
      if (enqueue) {
        dequeueTour(tour);
      }
    };
  }, [startTour, endTour, enqueueTour, dequeueTour, enable, enqueue, tour, delay]);
}
