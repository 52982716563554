import {
  Dialog,
  Divider,
  FormGroup,
  HStack,
  Input,
  NumberInput,
  SearchSelect,
  Text,
  getStringLabel,
  useCurrenciesContext,
  useGetCustomerMarketAccountLabel,
  useWLCustomerMarketAccountContext,
  type DialogProps,
} from '@talos/kyoko';
import Big from 'big.js';
import { useCallback, useEffect, useMemo, useState, type ChangeEvent } from 'react';
import { useCustomerDepositWithdrawContext, type CustomerDepositFormState } from '../../providers';

export const DepositDialog = (withdrawDialog: DialogProps) => {
  const [form, setForm] = useState<CustomerDepositFormState>({
    currency: '',
    marketAccount: '',
    quantity: '',
    txHash: '',
    externalComments: '',
  });
  const { currenciesBySymbol: availableCurrenciesBySymbol } = useCurrenciesContext();
  const { depositCustomerBalance } = useCustomerDepositWithdrawContext();
  const { customerMarketAccountsList } = useWLCustomerMarketAccountContext();
  const getCustomerMarketAccountLabel = useGetCustomerMarketAccountLabel();

  const availableCurrencies = useMemo(() => {
    return [...availableCurrenciesBySymbol.values()].map(currency => currency.Symbol);
  }, [availableCurrenciesBySymbol]);

  const updateForm = useCallback((form: Partial<CustomerDepositFormState>) => {
    setForm(prev => ({ ...prev, ...form }));
  }, []);

  const handleCurrencyChange = useCallback(
    (newCurrency?: string) => {
      updateForm({ currency: newCurrency ?? '' });
    },
    [updateForm]
  );

  const handleMarketAccountChange = useCallback(
    (newMarketAccount?: string) => {
      updateForm({ marketAccount: newMarketAccount ?? '' });
    },
    [updateForm]
  );

  const handleOnChangeExternalComment = useCallback(
    (newExternalComment?: string) => {
      updateForm({ externalComments: newExternalComment });
    },
    [updateForm]
  );

  const handleQuantityChange = useCallback(
    (newQuantity?: string) => {
      updateForm({ quantity: newQuantity ?? '' });
    },
    [updateForm]
  );

  const handleTxHashChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      updateForm({ txHash: e.target.value ?? '' });
    },
    [updateForm]
  );

  const confirmDisabled = useMemo(() => {
    if (!form.currency || !form.marketAccount || !form.quantity || !form.txHash) {
      return true;
    }

    if (Big(form.quantity).lte(0)) {
      return true;
    }

    return false;
  }, [form]);

  const handleOnConfirm = useCallback(() => {
    depositCustomerBalance(form);
  }, [form, depositCustomerBalance]);

  useEffect(() => {
    // Select the market account automatically if there is only 1 option.
    // The market account selector will be disabled.
    if (customerMarketAccountsList?.length === 1) {
      updateForm({ marketAccount: customerMarketAccountsList[0].SourceAccountID });
    }
  }, [form.marketAccount, customerMarketAccountsList, updateForm]);

  const marketAccountOptions = useMemo(
    () => customerMarketAccountsList?.map(ma => ma.SourceAccountID),
    [customerMarketAccountsList]
  );

  if (!availableCurrencies || !customerMarketAccountsList || !marketAccountOptions) {
    return null;
  }

  return (
    <Dialog {...withdrawDialog} onConfirm={handleOnConfirm} confirmDisabled={confirmDisabled}>
      <FormGroup label="Currency">
        <SearchSelect
          getLabel={getStringLabel}
          selection={form.currency}
          options={availableCurrencies}
          onChange={handleCurrencyChange}
        />
      </FormGroup>
      {customerMarketAccountsList.length > 1 && (
        <FormGroup label="Account">
          <SearchSelect
            options={marketAccountOptions}
            selection={form.marketAccount}
            onChange={handleMarketAccountChange}
            disabled={customerMarketAccountsList.length <= 1}
            getLabel={getCustomerMarketAccountLabel}
          />
        </FormGroup>
      )}
      <HStack alignItems="center" justifyContent="space-between" gap="spacingDefault" mb="spacingDefault" w="100%">
        <Text whiteSpace="nowrap">
          <strong>Transaction Details</strong>
        </Text>
        <Divider mt="0.5em" orientation="horizontal" w="100%" />
      </HStack>
      <HStack justifyContent="space-between" gap="spacingDefault" alignItems="baseline">
        <FormGroup flex="1" label="Amount" w="33%">
          <NumberInput
            value={form.quantity || ''}
            onChange={handleQuantityChange}
            suffix={form.currency}
            disabled={!form.currency}
            min="0"
          />
        </FormGroup>
        <FormGroup label="Transaction Hash" w="67%">
          <Input disabled={!form.currency} value={form.txHash || ''} onChange={handleTxHashChange} />
        </FormGroup>
      </HStack>
      <FormGroup mb="spacingMedium" label="Comment">
        <Input
          value={form.externalComments ?? ''}
          autoComplete="off"
          onChange={e => handleOnChangeExternalComment(e.target.value)}
        />
      </FormGroup>
    </Dialog>
  );
};
