import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  type CSSProperties,
  type ReactElement,
} from 'react';
import { setNativeValue } from '../../../utils';
import { IconButton } from '../../Button';
import { Icon, IconName } from '../../Icons';
import { FormControlSizes, InputVariants, type FormControlProps } from '../types';
import { InputWrapper, Prefix, Suffix } from './styles';

export const Input = forwardRef<
  HTMLInputElement,
  FormControlProps<HTMLInputElement> & { inputType?: 'text' | 'file' | 'search' | 'email' | 'password' | 'number' }
>(
  (
    {
      style,
      className,
      prefix,
      suffix,
      invalid,
      touched,
      disabled,
      readOnly,
      value,
      type,
      inline,
      width,
      autoComplete = 'off',
      autoCorrect = 'off',
      autoCapitalize = 'off',
      spellCheck = false,
      wrapperRef,
      controlStyle,
      inputType = 'text',
      size = FormControlSizes.Default,
      clearable,
      variant = InputVariants.Default,
      id,
      ...props
    },
    ref
  ) => {
    // Make the forwardRef usable internally as well through this ref merging
    const inputRef = useRef<HTMLInputElement>(null);
    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const handleClearClick = useCallback(() => {
      if (inputRef.current) {
        setNativeValue(inputRef.current, '');
      }
    }, []);

    const clearIconStyles: CSSProperties = useMemo(
      () => ({
        pointerEvents: value ? 'initial' : 'none',
        opacity: value ? 1 : 0,
      }),
      [value]
    );

    const isPrefixText = typeof prefix === 'string' || (prefix as ReactElement)?.type === Icon;
    const isSuffixText = typeof suffix === 'string' || (suffix as ReactElement)?.type === Icon;

    return (
      <InputWrapper
        style={style}
        className={className}
        type={type}
        size={size}
        invalid={invalid}
        touched={touched}
        readOnly={readOnly}
        disabled={disabled}
        ref={wrapperRef}
        inline={inline}
        width={width}
        variant={variant}
        htmlFor={id}
      >
        <input
          {...props}
          id={id}
          ref={inputRef}
          value={value}
          readOnly={readOnly}
          disabled={disabled}
          autoComplete={autoComplete}
          autoCorrect={autoCorrect}
          autoCapitalize={autoCapitalize}
          spellCheck={spellCheck}
          type={inputType}
          style={controlStyle}
        />
        {prefix && (
          <Prefix size={size} isText={isPrefixText}>
            {prefix}
          </Prefix>
        )}
        {(suffix || clearable) && (
          <Suffix size={size} isText={isSuffixText} data-testid="input-suffix">
            {clearable && (
              <IconButton
                style={clearIconStyles}
                icon={IconName.Close}
                ghost={true}
                round={true}
                size={size - 0.5}
                onClick={handleClearClick}
                data-testid="clear-input"
              />
            )}
            {suffix}
          </Suffix>
        )}
      </InputWrapper>
    );
  }
);
