import { OrdTypeEnum, POST, request, useWLOrgConfigContext } from '@talos/kyoko';
import { useEffect, useState } from 'react';

export interface OrderSummaryResponse {
  Amount: string;
  AmountCurrency: string;
  OrderQty: string;
  Currency: string;
  EstimatedTotal?: string; // returned if EquivalentCurrency is provided
  EquivalentCurrency?: string; // returned if EquivalentCurrency is provided
  // below properties are returned but currently unused
  BalanceExceeding: boolean;
  Fee: string;
  FeeCurrency: string;
  MarketAccount: string;
  OrdType: string;
  Side: string;
  Symbol: string;
}

interface OrderSummaryProps {
  MarketAccount?: string;
  Symbol?: string;
  Currency?: string;
  OrderQty?: string;
  OrdType?: string;
  Side?: string;
  Price?: string;
  EquivalentCurrency?: string;
}

export const useOrderSummaryResponse = (summaryProps: OrderSummaryProps) => {
  const [orderSummaryResponse, setOrderSummaryResponse] = useState<OrderSummaryResponse>();
  const { config } = useWLOrgConfigContext();

  useEffect(() => {
    if (!config.useBackendOrderSummary) {
      // If the config doesn't allow backend order summary, don't make the request
      setOrderSummaryResponse(undefined);
      return;
    }
    const requiredFields = ['MarketAccount', 'Symbol', 'Currency', 'OrderQty', 'OrdType', 'Side'];
    if (requiredFields.some(field => !summaryProps[field])) {
      // If any required fields are missing, don't make the request
      setOrderSummaryResponse(undefined);
      return;
    }
    // Price is required when OrdType is Limit
    if (summaryProps.OrdType === OrdTypeEnum.Limit && !summaryProps.Price) {
      setOrderSummaryResponse(undefined);
      return;
    }
    // If OrderQty is 0 or Price is 0, don't make the request
    if (summaryProps.OrderQty === '0' || summaryProps.Price === '0') {
      setOrderSummaryResponse(undefined);
      return;
    }
    request<{ data: OrderSummaryResponse[] }>(POST, '/v1/order-pay-preview', summaryProps)
      .then(response => setOrderSummaryResponse(response.data.at(0)))
      .catch(() => setOrderSummaryResponse(undefined));
  }, [config.useBackendOrderSummary, summaryProps]);

  return orderSummaryResponse;
};
