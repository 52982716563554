import { Box } from '@talos/kyoko';
import styled from 'styled-components';

export const PillBox = styled(Box)`
  background: ${({ theme }) => theme.backgroundDefaultButton};
  padding: ${({ theme }) => theme.spacingSmall}px ${({ theme }) => theme.spacingDefault}px;
  border-radius: ${({ theme }) => theme.borderRadiusHuge}px;
  line-height: 1;

  border: 1px solid ${({ theme }) => theme.borderColorDefaultButton};

  &:hover {
    background: ${({ theme }) => theme.backgroundDefaultButtonHover};
  }
`;
