import {
  BlotterTableStorage,
  useWLCustomerUserConfigContext,
  type BlotterTableStorageContextProps,
} from '@talos/kyoko';
import { useEffect, useMemo, useRef } from 'react';

export function BlotterTableStorageProvider({ children }) {
  const { config, updateConfig, isLoaded } = useWLCustomerUserConfigContext();

  const configRef = useRef(config);
  useEffect(() => {
    configRef.current = config;
  }, [config]);

  const value = useMemo(
    () =>
      isLoaded
        ? {
            getState: blotterID => configRef.current?.[`blotters.${blotterID}`],
            setColumnState: (blotterID, columns) =>
              updateConfig({
                [`blotters.${blotterID}`]: { ...configRef.current?.[`blotters.${blotterID}`], columns },
              }),
            setSortState: (blotterID, sort) =>
              updateConfig({
                [`blotters.${blotterID}`]: { ...configRef.current?.[`blotters.${blotterID}`], sort },
              }),
            setFilterState: (blotterID, filter) =>
              updateConfig({
                [`blotters.${blotterID}`]: { ...configRef.current?.[`blotters.${blotterID}`], filter },
              }),
          }
        : null,
    [isLoaded, updateConfig]
  ) as BlotterTableStorageContextProps;

  return <BlotterTableStorage.Provider value={value}>{children}</BlotterTableStorage.Provider>;
}
