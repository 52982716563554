import type { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import { DayPicker } from '../DayPicker';
import { InputWrapper } from '../Form/Input/styles';
import { INPUT_BORDER_WIDTH } from '../Form/styles';
import { FormControlSizes } from '../Form/types';

export const FormGroup = styled(
  ({ label, children, help, error, disabled, size = FormControlSizes.Default, style }: FormGroupProps) => {
    return (
      <FormGroupWrapper disabled={disabled} size={size} style={style}>
        {label}
        {children}
        {error && <ErrorMessage error={!!error}>{error}</ErrorMessage>}
        {help && !error && <HelpText>{help}</HelpText>}
      </FormGroupWrapper>
    );
  }
)``;
type FormGroupProps = {
  controlId?: string;
  disabled?: boolean;
  label?: ReactNode;
  help?: ReactNode;
  error?: string;
  tooltip?: string;
  size?: FormControlSizes;
  children?: ReactNode;
  style?: CSSProperties;
};
const HelpText = styled.div`
  color: ${({ theme }) => theme.colorTextSubtle};
  font-size: ${({ theme }) => theme.fontSizeFormHelp}rem;
  padding: ${({ theme }) => theme.spacingTiny}px 0;
  line-height: 1;
`;
const ErrorMessage = styled.div<{ error?: boolean }>`
  color: ${({ theme }) => theme.colorTextNegative};
  font-size: ${({ theme }) => theme.fontSizeFormHelp}rem;
  padding: ${({ theme }) => theme.spacingTiny}px;
  line-height: 1;
`;
type FormGroupWrapperProps = { disabled?: boolean; size?: FormControlSizes };
const FormGroupWrapper = styled.label<FormGroupWrapperProps>`
  ${({ disabled, theme, size = FormControlSizes.Default }) => `
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: baseline;

  > :first-child {
    display: flex;
    flex: 1 30%;
    transition: color 200ms ease;
    font-size: ${(theme.fontSizeDefault * size) / 2}rem;
    color: ${disabled ? theme.colorTextSubtle : 'inherit'};
  }

  > ${InputWrapper} {
    flex: 2 75px;
    line-height: ${theme.baseSize * size - INPUT_BORDER_WIDTH * 2}px;
    height: ${theme.baseSize * size}px;
    font-size: ${(theme.fontSizeDefault * size) / 2}rem;
  }

  &:focus-within {
    &:first-child {
      color: ${theme.colorTextImportant};
    }
  }

  > ${ErrorMessage} {
    height: ${(theme.spacingMedium * size) / 2}px;
    font-size: ${(theme.fontSizeFormHelp * size) / 2}rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: absolute;
    left: 0;
    right: 0;
  }

  > ${HelpText} {
    height: ${theme.spacingMedium}px;
    font-size: ${(theme.fontSizeFormHelp * size) / 2}rem;
    position: absolute;
    left: 0;
    right: 0;

    color: ${disabled ? theme.colorTextMuted : ''};
  }
`}
`;
export const ShortcutsHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  font-size: ${({ theme }) => theme.fontSizeSmall}em;
  color: ${({ theme }) => theme.colorTextDefault};
  margin: ${({ theme }) => `${theme.spacingSmall}px`} 0;
  &:before,
  &:after {
    content: '';
    flex-grow: 1;
    height: 1px;
    ${({ theme }) => `background: ${theme.borderColorInput};`}
  }
  &:before {
    ${({ theme }) => `margin-right: ${theme.spacingSmall}px;`}
  }
  &:after {
    ${({ theme }) => `margin-left: ${theme.spacingSmall}px;`}
  }
`;
export const DateTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  flex: 1;
  padding: ${({ theme }) => theme.spacingComfortable}px;

  ${DayPicker} {
    padding: 0;
  }
`;
export const ShortcutButtons = styled.div`
  display: grid;
  grid-gap: ${({ theme }) => theme.spacingTiny}px;
  grid-template-columns: auto auto auto;
`;
export const DurationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacingComfortable}px;
`;
export const PopoverWrapper = styled.div`
  display: flex;
  min-height: 18em;
  margin: -${({ theme }) => theme.spacingDefault}px;
  gap: ${({ theme }) => theme.spacingComfortable}px;
  ${DateTimeWrapper}, ${DurationWrapper} {
    width: 15em;
  }
`;

export const TimePickerWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacingSmall}px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: ${({ theme }) => theme.spacingDefault}px;
`;
export const InputOverlay = styled.div<{ size?: FormControlSizes }>`
  position: absolute;
  left: ${({ theme, size = FormControlSizes.Default }) => (theme.spacingDefault * size) / 2}px;
`;
export const DurationDescription = styled.span<{ disabled?: boolean }>`
  ${({ theme, disabled }) => `color: ${disabled ? theme.colorTextSubtle : theme.colorTextImportant};`}
`;
export const DateDescription = styled.span<{ disabled?: boolean }>`
  ${({ theme, disabled }) => `color: ${disabled ? theme.colorTextSubtle : theme.colorTextImportant};`}
  flex: 0 0 auto;
  white-space: nowrap;
  ${DurationDescription}~& {
    ${({ theme, disabled }) => `color: ${disabled ? theme.colorTextMuted : theme.colorTextSubtle};`}
  }
`;
export const DateTimeDurationPickerWrapper = styled.div<{ focused: boolean }>`
  position: relative;

  ${({ focused }) =>
    focused
      ? `
    ${InputOverlay} {
      display: none;
    }`
      : `
  ${InputOverlay} {
    display: flex;
  }
  > ${InputWrapper} > input {
    color: transparent;
  }
`}
`;
