import type { ModeEnum } from './types';

export class Customer {
  CounterpartyID!: number;
  DisplayName!: string;
  Name!: string;
  Type!: 'Customer';
  Timestamp?: number;
  PricingRuleMode?: ModeEnum;

  constructor(data: Customer) {
    Object.assign(this, data);
  }
}
