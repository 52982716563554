import { useEffect, useMemo, useRef, useState } from 'react';
import { combineLatestWith, filter, map, shareReplay } from 'rxjs/operators';

import { isEqual } from 'lodash';
import { useMarketAccountsContext, useMarketsContext } from '../contexts';
import { MARKET_DATA_SNAPSHOT, TRADE_DIRECTION } from '../tokens';
import type {
  MarketDataSnapshot,
  MarketDataSnapshotRequest,
  TradeDirection,
  TradeDirectionRequest,
} from '../types/MarketDataSnapshot';
import type { SubscriptionResponse } from '../types/SubscriptionResponse';
import { DepthTypeEnum, MarketAccountTypeEnum, type FeeModeEnum, type LiquidityTypeEnum } from '../types/types';
import { EMPTY_ARRAY } from '../utils';
import { useBehaviorSubject } from './useBehaviorSubject';
import { useDynamicCallback } from './useDynamicCallback';
import { useObservable } from './useObservable';
import { useSecurity } from './useSecurity';
import { useSubscription } from './useSubscription';

/** A market (account) name that can be sent for the MarketDataSnapshot subscription */
type TradeableMarketAccountOrMarketName = string & { __type: 'TradeableMarketAccountOrMarketName' };

/**
 * Takes a list of market names and market account names, and returns only the ones that are valid to send to `useMarketDataSnapshot`.
 *
 * The MarketDataSnapshot subscription will throw an error if `markets` contains any market accounts that have `Type` !== `Trading`.
 * It will also throw an error if `markets` contains any markets that don't exist.
 *
 * @param marketsAndMarketAccounts A combined list of market names and market account names
 * @return Only those markets / market accounts which can be passed to `useMarketDataSnapshot`
 */
export function useMarketsAndMarketAccountsForMarketDataSnapshot(
  marketsAndMarketAccounts: string[]
): TradeableMarketAccountOrMarketName[] {
  const { marketAccountsByName } = useMarketAccountsContext();
  const { marketsByName } = useMarketsContext();

  const validMarketAccounts = useRef<TradeableMarketAccountOrMarketName[]>([]);

  useEffect(() => {
    const newValue = marketsAndMarketAccounts.filter(
      (market): market is TradeableMarketAccountOrMarketName =>
        marketsByName?.has(market) || marketAccountsByName?.get(market)?.Type === MarketAccountTypeEnum.Trading
    );
    if (!isEqual(validMarketAccounts.current, newValue)) {
      validMarketAccounts.current = newValue;
    }
  }, [marketAccountsByName, marketsAndMarketAccounts, marketsByName]);
  return validMarketAccounts.current;
}

export function useMarketDataSnapshot({
  clearOnSymbolChange,
  currency,
  baseCurrency = currency,
  depth,
  includeTradeDirection,
  priceIncrement,
  quantity,
  markets,
  sizeBuckets,
  symbol,
  tag,
  feeMode,
  liquidityType,
  throttle,
}: UseMarketDataSnapshotProps) {
  const [request, setRequest] = useState<(MarketDataSnapshotRequest | TradeDirectionRequest)[] | null>(null);
  const security = useSecurity(symbol);

  useEffect(() => {
    if (security != null && (depth || quantity || sizeBuckets)) {
      const marketDataRequest: MarketDataSnapshotRequest = {
        name: MARKET_DATA_SNAPSHOT,
        DepthType: DepthTypeEnum.Price,
        PriceIncrement: priceIncrement,
        Symbol: security.Symbol,
        FeeMode: feeMode,
        LiquidityType: liquidityType,
        tag: tag,
        Markets: markets,
        Throttle: throttle,
      };
      if (depth != null) {
        marketDataRequest.Depth = depth;
      } else {
        if (currency && baseCurrency && currency !== baseCurrency && quantity != null) {
          marketDataRequest.AmountBuckets = [quantity];
        } else {
          marketDataRequest.SizeBuckets = quantity ? [quantity] : sizeBuckets;
        }
      }
      const requests: (MarketDataSnapshotRequest | TradeDirectionRequest)[] = [marketDataRequest];
      if (includeTradeDirection) {
        requests.push({
          name: TRADE_DIRECTION,
          Symbol: security.Symbol,
          Markets: markets,
          tag: tag,
        });
      }
      setRequest(requests);
    } else {
      setRequest(null);
    }
  }, [
    baseCurrency,
    currency,
    priceIncrement,
    security,
    depth,
    tag,
    sizeBuckets,
    quantity,
    feeMode,
    liquidityType,
    includeTradeDirection,
    markets,
    throttle,
  ]);

  const { observable: symbolObservable } = useBehaviorSubject(() => symbol, [symbol]);
  const { data: subscription } = useSubscription<SubscriptionResponse<MarketDataSnapshot | TradeDirection>>(request, {
    unsubscribeOnPageInvisibility: true,
  });

  const checkToClearMarketData = useDynamicCallback((marketData: MarketDataSnapshot, currSymbol?: string) => {
    if (clearOnSymbolChange && currSymbol !== marketData.Symbol) {
      return {
        ...marketData,
        Bids: EMPTY_ARRAY,
        Offers: EMPTY_ARRAY,
      };
    }

    return marketData;
  });

  const marketDataSnapshots = useObservable(
    () =>
      subscription.pipe(
        filter(
          (json): json is SubscriptionResponse<MarketDataSnapshot> =>
            json.type === MARKET_DATA_SNAPSHOT && (json.data[0]['Bids'] || json.data[0]['Offers'])
        ),
        // TODO: Revise this logic as it's mostly the same as ava/useMarketDataSnapshot except the wrapper object
        map(json => json.data[0]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [subscription]
  );

  const marketDataSnapshotsWithCheckToClear = useMemo(
    () =>
      marketDataSnapshots.pipe(
        combineLatestWith(symbolObservable),
        map(([marketData, currSymbol]) => {
          return checkToClearMarketData(marketData, currSymbol);
        })
      ),
    [checkToClearMarketData, marketDataSnapshots, symbolObservable]
  );

  const tradeDirections = useObservable(
    () =>
      subscription.pipe(
        filter((json): json is SubscriptionResponse<TradeDirection> => json.type === TRADE_DIRECTION),
        map(json => json.data[0]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [subscription]
  );

  return {
    marketDataSnapshots: marketDataSnapshotsWithCheckToClear,
    tradeDirections,
  };
}

type UseMarketDataSnapshotProps = {
  clearOnSymbolChange?: boolean;
  currency?: string;
  baseCurrency?: string;
  depth?: number;
  includeTradeDirection?: boolean;
  priceIncrement?: string;
  quantity?: string;
  sizeBuckets?: string[];
  symbol?: string;
  tag: string;
  feeMode?: FeeModeEnum;
  liquidityType?: LiquidityTypeEnum;
  /**
   * If you are passing `markets`, filter them with the useMarketsAndMarketAccountsForMarketDataSnapshot function first
   */
  markets?: TradeableMarketAccountOrMarketName[];
  throttle?: string;
};
