import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface SessionState {
  /**
   * State to track if the user has been biometrically verified.
   * Is used primarily when the app is backgrounded and the user needs to verify themselves again.
   * When user successfully enables biometric verification, this state is set to true, as to not have them
   * verify themselves twice.
   */
  isBiometricallyVerified: boolean;
  archivedOrderIDSet: Set<string>;
}

function getInitialState(): SessionState {
  return {
    isBiometricallyVerified: false,
    archivedOrderIDSet: new Set(),
  };
}

export const sessionSlice = createSlice({
  name: 'session',
  initialState: getInitialState(),
  reducers: {
    setIsBiometricallyVerified: (state, action: PayloadAction<boolean>) => {
      state.isBiometricallyVerified = action.payload;
    },
    archiveOrderID: (state, action: PayloadAction<string>) => {
      state.archivedOrderIDSet = new Set(state.archivedOrderIDSet.add(action.payload));
    },
  },
});

export const { setIsBiometricallyVerified, archiveOrderID } = sessionSlice.actions;
