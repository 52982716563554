import { map, pipe } from 'rxjs';
import type { EnrichedCreditExposure } from '../types';

// Create a MarketAccount-Currency composite id for each datapoint received
export const creditExposuresCompositeIDPipe = pipe(
  map((result: { data: EnrichedCreditExposure[] }) => {
    return {
      ...result,
      data: result.data.filter(exposure => {
        // Filter out rows where Currency is not set on the exposure message
        return !!exposure.Currency;
      }),
    };
  })
);
