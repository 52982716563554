/**
 * This hook mirrors the received map of component refs, and converts each value from a FilterClause to a structure of refs + metadata which are used by the children.
 * Containing the refs in one place allows smarter parents to move around focus with full control on tab presses, etc.
 */

import { createRef, useCallback, useRef, useState } from 'react';
import type { FilterBuilderSide, FilterClause, PropertyRefs, UseFilterBuilderRefsOutput } from './types';
import { getFirstSelectionInProperty } from './utils/FocusTabHelper';

export const useFilterBuilderRefs = (
  filterClausesByPropertyKey: Map<string, FilterClause>
): UseFilterBuilderRefsOutput => {
  const [refsByPropertyKey, setRefsByPropertyKey] = useState<Map<string, PropertyRefs>>(() => {
    // Initial mapping of the filterClausesByPropertyKey map to the map of refs
    const newMap = new Map<string, PropertyRefs>();
    filterClausesByPropertyKey.forEach((clause, key) => {
      newMap.set(key, createRefsObject());
    });

    return newMap;
  });

  const addButtonRef = useRef<HTMLButtonElement>(null);

  const updateSelectionRefs = useCallback((property: string, selections: string[]) => {
    setRefsByPropertyKey(currRefs => {
      const newRefsMap = new Map(currRefs);
      const refs = newRefsMap.get(property);

      if (!refs) {
        // safeguard, Shouldn't happen
        return currRefs;
      }

      const checkedRefsSet = new Set<string>();

      selections.forEach(selection => {
        if (!refs.selections.has(selection)) {
          refs.selections.set(selection, createRef());
        }

        checkedRefsSet.add(selection);
      });

      // at this point we have gone over all selections, however there might still be some refs unaccounted for. these should be removed from the refs map
      refs.selections.forEach((ref, key) => {
        if (!checkedRefsSet.has(key)) {
          refs.selections.delete(key);
        }
      });

      const newRefs = { ...refs };
      newRefsMap.set(property, newRefs);

      return newRefsMap;
    });
  }, []);

  const resetAllPropertyRefs = useCallback((filterClauses: FilterClause[] = []) => {
    setRefsByPropertyKey(() => {
      const newMap = new Map<string, PropertyRefs>();
      filterClauses.forEach(filterClause => {
        newMap.set(filterClause.key, createRefsObject());
      });
      return newMap;
    });
  }, []);

  const addPropertyRefs = useCallback((property: string) => {
    setRefsByPropertyKey(currRefs => new Map(currRefs).set(property, createRefsObject()));
  }, []);

  const removePropertyRefs = useCallback((property: string) => {
    setRefsByPropertyKey(currRefs => {
      const newMap = new Map(currRefs);
      newMap.delete(property);
      return newMap;
    });
  }, []);

  // Yeet
  const removeAllPropertyRefs = useCallback(() => {
    setRefsByPropertyKey(new Map());
  }, []);

  /**
   * Pass in the ref you wish to open/focus.
   * @param property the key (property) of the filter clause you're referring to. Pass undefined to open the add new dropdown
   * @param side the side of the property clause you wish to open, "lhs" or "rhs". Defaults to "rhs"
   */
  const openClause = useCallback(
    (property: string | undefined, side: FilterBuilderSide = 'rhs') => {
      if (property === undefined) {
        addButtonRef.current?.click();
        return;
      }

      const refsObj = refsByPropertyKey.get(property);
      if (!refsObj) {
        return;
      }

      if (side === 'lhs') {
        refsObj.property.current?.click();
      } else {
        const firstSelection = getFirstSelectionInProperty(refsObj);
        firstSelection.current?.click();
      }
    },
    [refsByPropertyKey]
  );

  return {
    refs: {
      addButtonRef,
      refsByPropertyKey,
    },
    updateSelectionRefs,
    addPropertyRefs,
    removePropertyRefs,
    removeAllPropertyRefs,
    resetAllPropertyRefs,
    openClause,
  };
};

function createRefsObject(): PropertyRefs {
  return {
    property: createRef(),
    empty: createRef(),
    selections: new Map(),
    tail: createRef(),
  };
}
