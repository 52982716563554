import { forwardRef } from 'react';
import { useDynamicCallback } from '../../../hooks/useDynamicCallback';
import type { FormControlProps } from '../types';
import { TextAreaWrapper } from './styles';

export const TextArea = forwardRef<
  HTMLTextAreaElement,
  FormControlProps<HTMLTextAreaElement> & { rows?: number; onEnter?: () => void }
>(
  (
    {
      style,
      className,
      prefix,
      suffix,
      invalid,
      touched,
      disabled,
      value,
      type,
      inline,
      width,
      height,
      autoComplete = 'off',
      autoCorrect = 'off',
      autoCapitalize = 'off',
      spellCheck = false,
      wrapperRef,
      onEnter,
      ...props
    },
    ref
  ) => {
    // Call onEnter if provided and we're not holding shift
    const onKeyDown = useDynamicCallback((e: React.KeyboardEvent) => {
      if (e.key === 'Enter' && !e.shiftKey && onEnter != null) {
        e.preventDefault();
        onEnter();
      }
    });

    return (
      <TextAreaWrapper
        style={style}
        className={className}
        type={type}
        invalid={invalid}
        touched={touched}
        disabled={disabled}
        ref={wrapperRef}
        inline={inline}
        width={width}
      >
        {prefix}
        <textarea
          onKeyDown={onKeyDown}
          {...props}
          ref={ref}
          value={value}
          disabled={disabled}
          autoComplete={autoComplete}
          autoCorrect={autoCorrect}
          autoCapitalize={autoCapitalize}
          spellCheck={spellCheck}
          style={{ height, width }}
        />
        {suffix}
      </TextAreaWrapper>
    );
  }
);
