import { memo, useMemo } from 'react';
import styled from 'styled-components';
import type { SubAccount } from '../../types/SubAccount';
import { SubAccountTypeEnum } from '../../types/types';
import { Box, Flex } from '../Core';
import { Divider } from '../Divider';
import { ICON_SIZES, Icon, IconName } from '../Icons';
import { IndicatorBadge } from '../IndicatorBadge';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import { TreeView, useTreeview } from './Tree';
import type { Tree } from './types';
import { getSubAccountsForNode } from './utils';

export const TreeViewTooltip = memo(function TreeViewTooltip({
  tree,
  subAccount,
  highlight,
}: {
  tree: Tree<SubAccount>;
  subAccount: SubAccount;
  highlight: string[];
}) {
  const reducedTree: Tree<SubAccount> = useMemo(() => {
    const newTree: Tree<SubAccount> = {
      rootIds: [],
      nodes: tree.nodes,
    };
    const findRoots = (id: string) => {
      const node = tree.nodes.get(id);
      if (node) {
        if (node.parents) {
          for (const parent of node.parents) {
            findRoots(parent);
          }
        } else {
          newTree.rootIds.push(id);
        }
      }
    };
    findRoots(subAccount.SubaccountID.toString());
    return newTree;
  }, [tree, subAccount]);

  const treeviewState = useTreeview({ tree: reducedTree });

  const subAccountMembers: SubAccount[] = useMemo(() => {
    return getSubAccountsForNode(reducedTree, subAccount);
  }, [reducedTree, subAccount]);

  return (
    <Tooltip
      usePortal
      placement="auto"
      tooltipTestID="treeview-tooltip-content"
      tooltip={
        <MinusMarginBox w="300px">
          <Flex p="spacingComfortable" flexDirection="row" justifyContent="space-between">
            <Text>Total Unique Sub Accounts</Text>
            <Text data-testid="unique-subaccounts-amt">{subAccountMembers.length}</Text>
          </Flex>
          <Divider />
          <Box p="spacingDefault">
            <TreeView
              width="300px"
              height="400px"
              {...treeviewState}
              highlighted={highlight}
              sortNodeChildren={(a, b) => {
                if (
                  a.item &&
                  a.item.Type === SubAccountTypeEnum.Book &&
                  b.item &&
                  b.item.Type === SubAccountTypeEnum.Book
                ) {
                  return a.name.localeCompare(b.name);
                }
                if (a.item && a.item.Type === SubAccountTypeEnum.Book) {
                  return 1;
                }
                if (b.item && b.item.Type === SubAccountTypeEnum.Book) {
                  return -1;
                }
                return a.name.localeCompare(b.name);
              }}
              renderNode={node => {
                if (node.item && node.item.Type === SubAccountTypeEnum.Rollup) {
                  return (
                    <Flex gap="spacingDefault" alignItems="center" data-testid="rollup-node" data-id={node.id}>
                      {node.name}
                      <IndicatorBadge children={<Icon size={ICON_SIZES.DEFAULT} icon={IconName.TreeDiagram} />} />
                    </Flex>
                  );
                } else {
                  return (
                    <span data-testid="subaccount-node" data-id={node.id}>
                      {node.name}
                    </span>
                  );
                }
              }}
            />
          </Box>
        </MinusMarginBox>
      }
      onOpen={() => {
        treeviewState.openToNode(subAccount.SubaccountID.toString());
        treeviewState.scrollToNode(subAccount.SubaccountID.toString());
      }}
    >
      <IndicatorBadge children={<Icon size={ICON_SIZES.DEFAULT} icon={IconName.TreeDiagram} />} />
    </Tooltip>
  );
});

const MinusMarginBox = styled(Box)`
  margin: ${({ theme }) => -theme.spacingDefault}px;
`;
