import type { CustomCellEditorProps } from 'ag-grid-react';
import { useEffect, useRef } from 'react';
import { Input } from '../Form/Input';
import type { FormControlProps } from '../Form/types';

export const AgGridInput = ({
  size,
  node,
  colDef,
  parseValue,
  value,
  onValueChange,
  initialValue,
  ...props
}: CustomCellEditorProps & FormControlProps<HTMLInputElement>) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (inputRef?.current != null) {
        inputRef.current.focus();
      }
    });
  }, []);

  const isRightAligned = ((colDef.cellClass ?? '') as string | string[]).includes('ag-right-aligned-cell'); // assumes we won't ever set the cellClass as a function ...

  return (
    <Input
      ref={inputRef}
      onChange={e => onValueChange(parseValue(e.target.value))}
      value={value ?? ''}
      size={size}
      style={{
        width: '100%',
        height: `${node.rowHeight}px`,
        background: props.context.current.theme?.backgroundBody,
      }}
      type={isRightAligned ? 'number' : 'text'}
    />
  );
};
