import {
  DefaultWatchlistSettingsConfig,
  DefaultWatchlistSettingsContext,
  WatchlistSettingsContext,
  useWLCustomerUserConfigContext,
  type WLCustomerUserConfig,
  type WatchlistSettingsConfig,
  type WatchlistSettingsContextProps,
} from '@talos/kyoko';
import { memo, useMemo } from 'react';

export const WatchlistSettingsContextProvider = memo((props: React.PropsWithChildren<unknown>) => {
  const { config, updateConfig } = useWLCustomerUserConfigContext();

  const value = useMemo(() => {
    if (config) {
      return {
        ...DefaultWatchlistSettingsConfig,
        ...config?.watchlistConfig,
        setShowOnlyFavorites: (showOnlyFavorites: boolean) => {
          updateWatchlistConfig(updateConfig, config, { showOnlyFavorites });
        },
        setIsVisible: (isVisible: boolean) => {
          updateWatchlistConfig(updateConfig, config, { isVisible });
        },
        setFilteredCurrencies: (filteredCurrencies: string[]) => {
          updateWatchlistConfig(updateConfig, config, { filteredCurrencies });
        },
      } satisfies WatchlistSettingsContextProps;
    } else {
      return {
        ...DefaultWatchlistSettingsContext,
        setSecurityTypes: undefined,
      };
    }
  }, [config, updateConfig]);

  return <WatchlistSettingsContext.Provider value={value}>{props.children}</WatchlistSettingsContext.Provider>;
});

function updateWatchlistConfig(
  updateConfig: (config: Partial<WLCustomerUserConfig>) => void,
  currentConfig: WLCustomerUserConfig,
  newConfig: Partial<WatchlistSettingsConfig>
) {
  updateConfig({
    watchlistConfig: {
      ...DefaultWatchlistSettingsConfig,
      ...currentConfig?.watchlistConfig,
      ...newConfig,
    },
  });
}
