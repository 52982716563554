/**
 * The functions in this file help by finding the next focusable element in different ways
 */

import type { RefObject } from 'react';
import type { FilterBuilderRefs, FilterBuilderSide, PropertyRefs } from '../types';
interface NextElementToFocus {
  propertyKey: string | undefined;
  ref: RefObject<HTMLButtonElement>;
  side: FilterBuilderSide;
}

/**
 * Returns the details of the next element to focus given the parameters to the function
 * If there is none, returns undefined
 * @param currentlyFocusedProperty
 * @param refs
 * @param focusedSide
 * @returns next element to focus details or undefined
 */
export function getNextElementToFocus(
  currentlyFocusedProperty: string,
  refs: FilterBuilderRefs,
  focusedSide: FilterBuilderSide
): NextElementToFocus | undefined {
  const currentlyFocusedPropertyIndex = getCurrentlyFocusedPropertyIndex(currentlyFocusedProperty, refs);
  if (currentlyFocusedPropertyIndex === -1) {
    return undefined;
  }

  if (focusedSide === 'lhs') {
    // we're in an LHS, tab forward to our clause's RHS
    const [propertyKey, propertyRefs] = [...refs.refsByPropertyKey.entries()][currentlyFocusedPropertyIndex];
    const ref = getFirstSelectionInProperty(propertyRefs);
    return { propertyKey: propertyKey, ref: ref, side: 'rhs' };
  }

  const propertyKeys = [...refs.refsByPropertyKey.keys()];
  if (currentlyFocusedPropertyIndex === propertyKeys.length - 1) {
    // we are the last element in the property list, so we jump to addButtonRef
    return { propertyKey: undefined, ref: refs.addButtonRef, side: 'lhs' };
  }

  // we know that we are not the last element in the list, so use index + 1
  const [propertyKey, propertyRefs] = [...refs.refsByPropertyKey.entries()][currentlyFocusedPropertyIndex + 1];
  const ref = getFirstSelectionInProperty(propertyRefs);
  return { propertyKey: propertyKey, ref: ref, side: 'rhs' };
}

/**
 * Returns the details of the closest previous element to focus given the parameters to the function
 * If there is none, returns undefined
 * @param currentlyFocusedProperty
 * @param refs
 * @returns details of the closest previous element to focus, or undefined
 */
export function getPreviousElementToFocus(
  currentlyFocusedProperty: string | undefined,
  refs: FilterBuilderRefs
): NextElementToFocus | undefined {
  if (currentlyFocusedProperty === undefined) {
    // we are coming from the "add" property, backwards to the last defined lhs property
    if (refs.refsByPropertyKey.size > 0) {
      const [propertyKey, propertyRefs] = [...refs.refsByPropertyKey.entries()][refs.refsByPropertyKey.size - 1];
      const ref = getFirstSelectionInProperty(propertyRefs);
      return { propertyKey: propertyKey, ref: ref, side: 'rhs' };
    }

    return undefined;
  }

  const currentlyFocusedPropertyIndex = getCurrentlyFocusedPropertyIndex(currentlyFocusedProperty, refs);
  if (currentlyFocusedPropertyIndex === -1) {
    return undefined;
  }

  if (currentlyFocusedPropertyIndex === 0) {
    return undefined;
  }

  // we know that we are not the first element in the list, so use index - 1
  const [propertyKey, propertyRefs] = [...refs.refsByPropertyKey.entries()][currentlyFocusedPropertyIndex - 1];
  const ref = getFirstSelectionInProperty(propertyRefs);
  return { propertyKey: propertyKey, ref: ref, side: 'rhs' };
}

/**
 * Gets the first selection within the provided property.
 * If there are no selections, returns the "empty" ref
 * @param propertyRefs
 * @returns first selection or empty ref
 */
export function getFirstSelectionInProperty(propertyRefs: PropertyRefs): RefObject<HTMLButtonElement> {
  const selections = [...propertyRefs.selections.values()];
  return selections.length > 0 ? selections[0] : propertyRefs.empty;
}

/**
 * Looks through the refs map and tries to find the index you're at.
 * Like the normal findIndex function, we return the index or -1 if it was not found.
 * @param currentlyFocusedProperty
 * @param refs
 * @returns index of currently focused property index, -1 if not found
 */
function getCurrentlyFocusedPropertyIndex(currentlyFocusedProperty: string, refs: FilterBuilderRefs): number {
  const propertyKeys = [...refs.refsByPropertyKey.keys()];
  return propertyKeys.findIndex(propertyKey => propertyKey === currentlyFocusedProperty);
}

export function findCurrentlyFocusedPropertyLHSByRef(
  ref: HTMLButtonElement | null,
  refs: FilterBuilderRefs
): string | undefined {
  const entries = [...refs.refsByPropertyKey.entries()];
  const maybeFoundEntry = entries.find(([propertyKey, propertyRefs]) => propertyRefs.property.current === ref);
  if (maybeFoundEntry) {
    return maybeFoundEntry[0]; // its a tuple [propertyKey, propertyRefs]
  } else {
    return undefined;
  }
}
