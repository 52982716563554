import { MultiSelect } from 'components/Form/MultiSelect';
import styled from 'styled-components';
import { useDynamicCallback } from '../../hooks/useDynamicCallback';
import type { AgGridMultiSelectDropdownProps } from './types';

export const AgGridMultiSelectDropdown = function AgGridMultiSelectDropdown<T>({
  multiSelectProps,
  portalize = false,
  value,
  onValueChange,
}: AgGridMultiSelectDropdownProps<T>) {
  const handleChange = useDynamicCallback((newValue: T[] | undefined) => {
    onValueChange(newValue);
  });

  return (
    <MultiSelectWrapper data-testid="multi-select">
      <MultiSelect {...multiSelectProps} selections={value} onChange={handleChange} portalize={portalize} autoOpen />
    </MultiSelectWrapper>
  );
};

const MultiSelectWrapper = styled.div`
  margin-top: 3px;
  background: ${({ theme }) => theme.backgroundDropdownInput};
`;
