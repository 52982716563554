import { useSpring } from '@react-spring/web';
import { useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';
import { FormattedMessage } from '../../../Intl';
import { LoadingBar, RemainingTimeWrapper, TimeBar, TimeLabel } from './styles';

const messages = defineMessages({
  quoteExpired: {
    defaultMessage: 'Quote Expired',
    id: 'Forms.RFQForm.RemainingTime.quoteExpired',
  },
  durationInSeconds: {
    defaultMessage: '{duration} s',
    id: 'Forms.RFQForm.RemainingTime.durationInSeconds',
  },
});

export const RemainingTime = ({ validUntilTime, loading }: RemainingTimeProps) => {
  const [remainingTimeMs, setRemainingTimeMs] = useState<number>();
  const [timeBarStyles, timeBarAnimation] = useSpring(() => ({}));

  useEffect(() => {
    if (validUntilTime != null && !loading) {
      const durationMs = Sugar.Date.create(validUntilTime).getTime() - Date.now();
      setRemainingTimeMs(durationMs);
      timeBarAnimation.start({
        from: {
          transform: 'translateX(0%)',
        },
        to: {
          transform: 'translateX(100%)',
        },
        config: {
          duration: durationMs,
        },
      });
      const interval = setInterval(
        () => setRemainingTimeMs(Sugar.Date.create(validUntilTime).getTime() - Date.now()),
        200
      );
      return () => {
        clearInterval(interval);
      };
    }
  }, [validUntilTime, loading, timeBarAnimation]);

  useEffect(() => {
    if (validUntilTime == null) {
      timeBarAnimation.stop();
    }
  }, [validUntilTime, timeBarAnimation]);

  if (loading) {
    return (
      <RemainingTimeWrapper>
        <LoadingBar />
      </RemainingTimeWrapper>
    );
  }

  return (
    <>
      <RemainingTimeWrapper>
        <TimeBar style={timeBarStyles} />
      </RemainingTimeWrapper>
      {remainingTimeMs != null &&
        (validUntilTime == null ? (
          <TimeLabel>
            <FormattedMessage {...messages.quoteExpired} />
          </TimeLabel>
        ) : (
          <TimeLabel>
            <FormattedMessage
              {...messages.durationInSeconds}
              values={{ duration: Math.ceil(remainingTimeMs / 1000) }}
            />
          </TimeLabel>
        ))}
    </>
  );
};

type RemainingTimeProps = {
  loading: boolean;
  validUntilTime?: string;
};
