import { OrdStatusEnum, type QuoteStatusEnum } from '../types/types';

export const isOrderComplete = (
  ordStatus: OrdStatusEnum | QuoteStatusEnum
): ordStatus is OrdStatusEnum.Canceled | OrdStatusEnum.Filled | OrdStatusEnum.Rejected | OrdStatusEnum.DoneForDay =>
  Boolean(
    ordStatus === OrdStatusEnum.Canceled ||
      ordStatus === OrdStatusEnum.Filled ||
      ordStatus === OrdStatusEnum.Rejected ||
      ordStatus === OrdStatusEnum.DoneForDay
  );
