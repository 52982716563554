import { useBalancesByMarketAccountCurrency } from '../contexts';
import { useMaxBalance } from '../hooks/useMaxBalance';
import { OrderFormSides, type Security } from '../types';

export function useWLEffectiveMaxBalance({
  account,
  security,
  side,
  currency,
}: {
  account: string | undefined;
  security: Security | undefined;
  side: OrderFormSides | undefined;
  currency: string | undefined;
}) {
  const { BaseCurrency, QuoteCurrency } = security || {};
  const balancesByMarketAccountCurrency = useBalancesByMarketAccountCurrency();
  const balances = account ? balancesByMarketAccountCurrency?.get(account) : undefined;

  // Subscribe to all permutations of side and currency, so we have them all available.
  const baseBuyMaxBalance = useMaxBalance(balances, QuoteCurrency, BaseCurrency);
  const baseSellMaxBalance = useMaxBalance(balances, BaseCurrency, BaseCurrency);
  const quoteBuyMaxBalance = useMaxBalance(balances, QuoteCurrency, QuoteCurrency);
  const quoteSellMaxBalance = useMaxBalance(balances, BaseCurrency, QuoteCurrency);

  // Return the effective max balance.
  const effectiveMaxBalance = (() => {
    switch (true) {
      case currency === BaseCurrency && side === OrderFormSides.Sell:
        return baseSellMaxBalance;
      case currency === BaseCurrency && side === OrderFormSides.Buy:
        return baseBuyMaxBalance;
      case currency === QuoteCurrency && side === OrderFormSides.Sell:
        return quoteSellMaxBalance;
      case currency === QuoteCurrency && side === OrderFormSides.Buy:
        return quoteBuyMaxBalance;
      default:
        // No max available for twoway or unknown.
        return undefined;
    }
  })();

  return effectiveMaxBalance;
}
