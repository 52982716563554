import type { ICellRendererParams } from 'ag-grid-enterprise';
import type Big from 'big.js';
import { memo } from 'react';
import { useFlashSentiment } from '../../hooks';
import { Direction } from '../../types';
import { Flex, HStack } from '../Core';
import { StackedFormattedNumber } from '../FormattedNumber';
import { Placeholder, PriceCell, placeholder } from './styles';
import type { WatchListRowData } from './types';

export interface MarketPriceButtonCellRendererParams extends ICellRendererParams {
  data: WatchListRowData;
  value: Big | undefined;
}
export const MarketPriceButtonCellRenderer = memo(function MarketPriceButtonCellRenderer({
  value,
  data,
}: MarketPriceButtonCellRendererParams) {
  const security = data.security;
  const changeSentiment = useFlashSentiment(security.Symbol, Direction.Asc, value?.toFixed());
  const isLoaded = value !== undefined;
  const hasData = value != null;

  return (
    <PriceCell w="100%" flexDirection="column">
      {isLoaded && hasData ? (
        <HStack borderRadius="borderRadiusDefault" py="spacingDefault" background="backgroundCard" w="100%">
          <Flex flexDirection="column">
            <StackedFormattedNumber
              baseSize="fontSizeTiny"
              largeSize="fontSizeDefault"
              number={value.toFixed()}
              increment={security?.DefaultPriceIncrement}
              specification={security.PriceDisplaySpec}
              variant={changeSentiment}
              round={true}
            />
          </Flex>
        </HStack>
      ) : isLoaded ? (
        placeholder
      ) : (
        <Placeholder />
      )}
    </PriceCell>
  );
});
