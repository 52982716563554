import { hsl, hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { lightColors, mapLightTheme } from './LightTheme';

const colors: Colors = {
  gray: {
    main: hsla(210, 0, 1.0, 1),
    '000': hsla(210, 0, 0.92, 1),
    '010': hsla(210, 0, 0.96, 1),
    '020': hsla(210, 0, 0.89, 1),
    '030': '#EAEAEA',
    '040': hsla(220, 0, 0.83, 1),
    '050': hsla(220, 0, 0.79, 1),
    '060': hsla(200, 0, 0.74, 1),
    '070': hsla(200, 0, 0.69, 1),
    '080': hsla(200, 0, 0.63, 1),
    '090': hsla(210, 0, 0.44, 1),
    '100': hsla(0, 0, 0.1, 1),
  },

  black: {
    default: hsla(0, 0, 0, 1),
    dim: hsla(0, 0, 0, 0.5),
    mute: hsla(0, 0, 0, 0.1),
  },

  white: {
    default: hsla(0, 0, 1, 1),
    dim: hsla(0, 0, 1, 0.5),
    mute: hsla(0, 0, 1, 0.1),
  },

  primary: {
    mute: hsla(217, 1, 0.42, 0.1),
    dim: hsla(217, 1, 0.42, 1),
    default: '#0053D8',
    lighten: '#0045B2',
  },

  green: {
    mute: hsla(157, 0.83, 0.43, 0.1),
    dim: hsla(157, 0.83, 0.43, 0.4),
    default: '#1CCB21',
    lighten: '#19B31D',
  },

  red: {
    mute: hsla(348, 0.55, 0.49, 0.1),
    dim: hsla(348, 0.55, 0.49, 0.4),
    default: '#FF5A5A',
    lighten: '#FF4242',
  },

  yellow: {
    dim: hsla(45, 0.91, 0.6, 0.1),
    mute: hsla(45, 0.91, 0.6, 0.4),
    default: hsl(45, 0.91, 0.46),
    lighten: hsl(45, 0.91, 0.46),
  },

  blue: {
    mute: hsla(216, 0.7, 0.57, 0.1),
    dim: hsla(216, 0.7, 0.57, 0.4),
    default: hsl(216, 0.7, 0.57),
    lighten: hsl(216, 0.7, 0.57),
  },

  orange: {
    mute: hsla(21, 1, 0.45, 0.1),
    dim: hsla(21, 1, 0.45, 0.4),
    default: hsla(21, 1, 0.45, 1),
    lighten: hsla(25, 1, 0.5, 1),
  },

  purple: lightColors.purple,
};

export const LightPintuTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightPintu',

  fontFamily: "'Inter', sans-serif",
  fontUrl:
    'https://fonts.googleapis.com/css2?family=Inter&family=Mulish:ital,wght@0,400;0,500;0,600;1,400&display=swap',

  spacingLayout: 4,

  backgroundBody: colors.gray['010'],

  backgroundPriorityButton: colors.gray.main,
  backgroundPriorityButtonHover: colors.gray['010'],
  backgroundPriorityButtonFocus: colors.gray['010'],
  backgroundPriorityButtonActive: colors.gray['020'],

  backgroundDefaultButton: colors.gray.main,
  backgroundDefaultButtonHover: colors.gray['010'],
  backgroundDefaultButtonFocus: colors.gray['010'],
  backgroundDefaultButtonActive: colors.gray['020'],

  backgroundInput: colors.gray.main,
  backgroundInputDisabled: colors.gray['010'],
  backgroundInputInvalid: colors.gray.main,

  backgroundSelect: colors.gray.main,
  backgroundSelectHover: colors.gray.main,
  backgroundSelectFocus: colors.gray.main,
  backgroundSelectDisabled: colors.gray['010'],
  backgroundSelectInvalid: colors.gray.main,
  backgroundSelectDropdown: colors.gray.main,

  borderColorSelect: colors.gray['060'],
  borderColorSelectHover: colors.gray['070'],
  borderColorSelectFocus: colors.gray['080'],
  borderColorSelectDisabled: colors.gray['040'],
  borderColorSelectReadOnly: colors.gray['040'],

  backgroundToggle: colors.gray['010'],
  backgroundToggleChecked: colors.primary.default,

  backgroundToggleSwitch: setAlpha(0.12, colors.gray[100]),
  backgroundToggleSwitchChecked: colors.white.default,
  backgroundToggleSwitchButtonActive: setAlpha(0.12, colors.gray.main),

  backgroundCheckbox: 'transparent',
  backgroundCheckboxChecked: 'transparent',

  borderColorCheckbox: colors.gray['050'],
  borderColorCheckboxFocus: colors.gray['070'],
  borderColorCheckboxHover: colors.gray['070'],
  borderColorCheckboxChecked: colors.gray['070'],
  borderColorCheckboxCheckedFocus: colors.gray['070'],

  borderColorRadiobutton: colors.gray['050'],
  borderColorRadiobuttonFocus: colors.gray['070'],
  borderColorRadiobuttonHover: colors.gray['070'],
  borderColorRadiobuttonChecked: colors.gray['070'],
  borderColorRadiobuttonCheckedFocus: colors.gray['070'],

  borderColorInput: colors.gray['060'],
  borderColorInputHover: colors.gray['070'],
  borderColorInputFocus: colors.gray['080'],

  borderColorInputDisabled: colors.gray['040'],

  borderWidthButton: 1,

  borderColorPriorityButton: colors.gray['060'],
  borderColorPriorityButtonHover: colors.gray['070'],
  borderColorPriorityButtonFocus: colors.gray['070'],
  borderColorPriorityButtonActive: colors.gray['060'],

  borderColorDefaultButton: colors.gray['030'],
  borderColorDefaultButtonHover: colors.gray['040'],
  borderColorDefaultButtonFocus: colors.gray['040'],
  borderColorDefaultButtonActive: colors.gray['030'],
});
