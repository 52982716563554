import styled from 'styled-components';
import { maxWidth, MEDIA } from '../../styles';

import { PANEL_HEADER_HEIGHT } from '../../styles/dimensions';

import { Flex, HStack, VStack } from '../Core';

export const Panel = styled(VStack)`
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

export const PanelHeader = styled(HStack)`
  background: ${({ theme }) => theme.colors.gray.main};
  justify-content: space-between;
  white-space: nowrap;
  margin-bottom: 0;
  width: 100%;

  min-height: ${PANEL_HEADER_HEIGHT}px;

  gap: ${({ theme }) => theme.spacingComfortable}px;

  flex: 0 0 auto;
  ${maxWidth(MEDIA.medium)} {
    gap: ${({ theme }) => theme.spacingSmall}px;
  }

  h2 {
    margin: 0;
    margin-right: auto;
    font-size: ${({ theme }) => theme.fontSizeBig}rem;
  }
`;

PanelHeader.defaultProps = {
  alignItems: 'flex-end',
  p: 'spacingBig',
};

export const PanelActions = styled(HStack)`
  gap: ${({ theme }) => theme.spacingComfortable}px;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

export const PanelContent = styled(Flex)`
  position: relative;
  overflow-y: auto;
`;
PanelContent.defaultProps = {
  flexDirection: 'column',
  background: 'backgroundContent',
  h: '100%',
  w: '100%',
  px: 'spacingBig',
  pt: 0,
  pb: 'spacingBig',
};
