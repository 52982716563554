import { forwardRef, type CSSProperties, type ReactNode } from 'react';
import styled from 'styled-components';
import type { BoxProps } from '../Core';
import { Title, Wrapper, type TagSizes, type TagVariants } from './styles';

export { TagSizes, TagVariants } from './styles';

export type TagProps = {
  variant?: TagVariants;
  title?: string;
  prefix?: ReactNode;
  suffix?: ReactNode;
  size?: TagSizes;
  children?: ReactNode;
  style?: CSSProperties;
} & Omit<BoxProps, 'prefix'>;

export const Tag = styled(
  forwardRef<HTMLDivElement, TagProps>(({ children, variant, title = '', prefix, suffix, size, ...props }, ref) => {
    return (
      <Wrapper variant={variant} size={size} {...props} ref={ref}>
        {title !== '' && <Title variant={variant}>{title}</Title>}
        {prefix}
        <span>{children}</span>
        {suffix}
      </Wrapper>
    );
  })
)``;
