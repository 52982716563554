import { configureStore } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
import { createContext } from 'react';
import { createDispatchHook, createSelectorHook, createStoreHook, type ReactReduxContextValue } from 'react-redux';
import { intlDefaultFormatter, type IntlWithFormatter } from '../../contexts/IntlContext';
import type { RootState } from './state';
import { referenceDataSlice } from './state/OMSReferenceDataSlice/reducer';
import { orderSlice } from './state/OrderSlice/reducer';
import { getInitialState } from './state/OrderSlice/utils';
import { sessionSlice } from './state/SessionSlice/reducer';

enableMapSet();

type RootStateStore = ReturnType<typeof getWLOrderFormStore>;
type RootStateDispatch = RootStateStore['dispatch'];

export const OrderFormContext = createContext<ReactReduxContextValue | null>(null);

export const useWLOrderFormDispatch = createDispatchHook<RootState>(OrderFormContext).withTypes<RootStateDispatch>();
export const useWLOrderFormSelector = createSelectorHook(OrderFormContext).withTypes<RootState>();
export const useWLOrderFormStore = createStoreHook(OrderFormContext).withTypes<RootStateStore>();

export const getWLOrderFormStore = (
  params?: Parameters<typeof getInitialState>[0],
  intl: IntlWithFormatter = intlDefaultFormatter
) =>
  configureStore({
    preloadedState: {
      order: getInitialState(params, intl),
      referenceData: {
        securities: params ? params.securities : { securitiesBySymbol: new Map(), securitiesList: [] },
        marketAccounts: params
          ? params.marketAccounts
          : { customerMarketAccountsBySourceAccountID: new Map(), customerMarketAccountsList: [] },
        strategiesBySymbol: params?.strategiesBySymbol || new Map(),
      },
    },
    reducer: {
      order: orderSlice.reducer,
      referenceData: referenceDataSlice.reducer,
      session: sessionSlice.reducer,
    },
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false,
      }),
    devTools: true,
  });
