import { kebabCase } from 'lodash';
import { useCallback, useDebugValue, useMemo } from 'react';

const idCounter = new Map<string, number>();
function getCount(description: string): number {
  const counter = (idCounter.get(description) ?? -1) + 1;
  idCounter.set(description, counter);
  return counter;
}
/**
 * A hook to generate unique ids for elements.
 *
 * @description
 * This hook returns a function to call whenever you need an id.
 * Within your component, any calls to this function would be memoized,
 * i.e. if you call it with some input, it will always return the same value.
 * This makes it work well for generating input / label ids, etc.
 * If multiple components call this hook with the same prefix, they will all have unique suffixes.
 *
 * @example
 * ```
 * // inside your component
 * const id = useElementId(props.id ?? 'component-name');
 *
 * return <form>
 *  <label for={id('some-field')}>Label text</label>
 *  <input type="text" id={id('some-field')} />
 * </form>;
 * ```
 *
 * @param prefix Component description / name / id
 * @returns A function that will generate unique ids for elements
 */
export function useElementId(prefix: string): (id: string) => string {
  const idPrefix = kebabCase(prefix);
  const uniqueId = useMemo(() => getCount(idPrefix), [idPrefix]);
  useDebugValue(`${idPrefix}-*-${uniqueId}`);
  const uniqueIdGenerator = useCallback((id: string) => `${idPrefix}-${id}-${uniqueId}`, [idPrefix, uniqueId]);
  return uniqueIdGenerator;
}
