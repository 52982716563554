import Big from 'big.js';
import { isObject } from 'lodash';
import { useMemo } from 'react';
import { getAgGridColId } from '../components/BlotterTable/columns/getAgGridColId';
import type { Column, ColumnDef } from '../components/BlotterTable/columns/types';
import { useDefaultColumns } from '../components/BlotterTable/useDefaultColumns';
import type { ExpectTrue } from '../tests';
import { logger } from '../utils';
import { EMPTY_ARRAY } from '../utils/empty';
import { isOrderPending } from '../utils/isOrderPending';
import type { OptionalProperties, ValueOf } from '../utils/types';
import type { CareNewOrderSingle } from './CareNewOrderSingle';
import type { Serializable } from './Serializable';
import {
  OrdStatusEnum,
  type CxlRejReasonEnum,
  type ExecTypeEnum,
  type ICareExecutionReport,
  type OrdRejReasonEnum,
  type SideEnum,
} from './types';

export function isCareOrder(entity: any): entity is CareOrder {
  return entity instanceof CareOrder;
}

export class CareOrder implements Serializable<CareOrder> {
  static readonly rowID = 'OrderID';

  Timestamp: string;
  User: string;
  Symbol: string;
  Currency: string;
  OrderID: string;
  ClOrdID: string;
  OrigClOrdID: string | undefined;
  SubmitTime: string;
  ExecID: string;
  Side: SideEnum;
  TransactTime: string;
  ExecType: ExecTypeEnum;
  protected OrdStatus: OrdStatusEnum;
  OrderQty: string;
  LeavesQty: string;
  CumQty: string;
  OrdRejReason: OrdRejReasonEnum | undefined;
  CxlRejReason: CxlRejReasonEnum | undefined;
  RequestUser: string;
  Revision: number;
  Text?: string;
  Group: string;
  Comments: string;
  CumAmt?: string;
  AvgPx?: string;
  LastPx?: string;
  LastQty?: string;
  LastAmt?: string;
  AmountCurrency: string;
  Counterparty: string;
  LastExecID: string;
  Annotations?: Record<string, AnyObject | string | number>;
  WorkingQty?: string;

  get isCancelable(): boolean {
    return !(this.isPendingOrdStatus || this.isComplete);
  }

  get isComplete(): boolean {
    return [OrdStatusEnum.Canceled, OrdStatusEnum.Filled, OrdStatusEnum.Rejected, OrdStatusEnum.DoneForDay].includes(
      this.OrdStatus
    );
  }

  get initiatingFrom(): string | undefined {
    if (this.Annotations != null && 'ETP' in this.Annotations && isObject(this.Annotations.ETP)) {
      return (this.Annotations as CareNewOrderSingle['Annotations'])?.ETP?.InitiatingFrom;
    }
    return undefined;
  }

  get status(): ValueOf<typeof CareOrderStatusEnum> {
    const cumQty = Big(this.CumQty);
    switch (this.OrdStatus) {
      case OrdStatusEnum.New:
      case OrdStatusEnum.Replaced:
      case OrdStatusEnum.PendingNew:
        return CareOrderStatusEnum.New;
      case OrdStatusEnum.PendingCancel:
        return CareOrderStatusEnum.PendingCancel;
      case OrdStatusEnum.PendingReplace:
        return CareOrderStatusEnum.PendingReplace;
      case OrdStatusEnum.PartiallyFilled:
        return CareOrderStatusEnum.PartiallyFilled;
      case OrdStatusEnum.Filled:
        return CareOrderStatusEnum.Filled;
      case OrdStatusEnum.DoneForDay:
        if (cumQty.eq(0)) {
          return CareOrderStatusEnum.Canceled;
        }
        return cumQty.gte(this.OrderQty) ? CareOrderStatusEnum.Filled : CareOrderStatusEnum.FilledAndCanceled;
      case OrdStatusEnum.Canceled:
        return CareOrderStatusEnum.Canceled;
      case OrdStatusEnum.Rejected:
        return CareOrderStatusEnum.Rejected;
      case OrdStatusEnum.Holding:
      case OrdStatusEnum.PendingFix:
        logger.error(new Error(`Unexpected OrdStatus: ${this.OrdStatus}`));
        return CareOrderStatusEnum.Unknown;
      default: {
        const _exhaustiveCheck: never = this.OrdStatus;
        logger.error(new Error(`Unexpected OrdStatus: ${this.OrdStatus}`));
        return CareOrderStatusEnum.Unknown;
      }
    }
  }

  toJSON() {
    return { ...this };
  }

  get remainQty(): string {
    return Big(this.OrderQty)
      .minus(this.CumQty)
      .minus(this.WorkingQty ?? 0)
      .toFixed();
  }

  // Private for now to encourage using the more abstract getters (isCancelable etc.)
  private get isPendingOrdStatus(): boolean {
    return isOrderPending(this.OrdStatus);
  }

  constructor(
    data:
      | CareOrder
      | OptionalProperties<
          ICareExecutionReport,
          'OrdRejReason' | 'CxlRejReason' | 'Annotations' | 'Text' | 'OrgID' | 'OrigClOrdID'
        >
  ) {
    this.Timestamp = data.Timestamp;
    this.User = data.User;
    this.Symbol = data.Symbol;
    this.Currency = data.Currency;
    this.OrderID = data.OrderID;
    this.ClOrdID = data.ClOrdID;
    this.OrigClOrdID = data.OrigClOrdID;
    this.SubmitTime = data.SubmitTime;
    this.ExecID = data.ExecID;
    this.Side = data.Side;
    this.TransactTime = data.TransactTime;
    this.ExecType = data.ExecType;
    // tsc is angry because OrdStatus is protected...
    this.OrdStatus = data instanceof CareOrder ? data.OrdStatus : data.OrdStatus;
    this.OrderQty = data.OrderQty ?? '0';
    this.LeavesQty = data.LeavesQty ?? '0';
    this.CumQty = data.CumQty ?? '0';
    this.OrdRejReason = data.OrdRejReason;
    this.CxlRejReason = data.CxlRejReason;
    this.RequestUser = data.RequestUser;
    this.Revision = data.Revision;
    this.Text = data.Text;
    this.Group = data.Group;
    this.Comments = data.Comments;
    this.AmountCurrency = data.AmountCurrency;
    this.Counterparty = data.Counterparty;
    this.CumAmt = data.CumAmt;
    this.AvgPx = data.AvgPx;
    this.LastExecID = data.LastExecID;
    this.LastPx = data.LastPx;
    this.LastQty = data.LastQty;
    this.LastAmt = data.LastAmt;
    this.LastExecID = data.LastExecID;
    this.Annotations = data.Annotations as any;
    this.WorkingQty = (data as CareOrder).WorkingQty == null ? undefined : (data as CareOrder).WorkingQty;
  }
}

interface UseCareOrderColumns {
  defaultColumns?: (keyof CareOrder | Partial<Column>)[];
}

export function useCareOrderColumns({ defaultColumns = EMPTY_ARRAY }: UseCareOrderColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            {
              field: 'SubmitTime',
              type: 'date',
              sortable: true,
              sort: '-',
            },
            {
              field: 'Side',
              type: 'side',
              sortable: true,
            },
            {
              field: 'Symbol',
              type: 'security',
              sortable: true,
            },
            {
              field: 'status',
              type: 'careOrderStatus',
              sortable: true,
            },
            { type: 'filledPercent', id: 'filledPercent' },
            {
              field: 'OrderQty',
              type: 'size',
              sortable: true,
              params: { currencyField: 'Currency' },
            },
            {
              field: 'LeavesQty',
              type: 'size',
              sortable: true,
              params: { currencyField: 'Currency' },
            },
            {
              field: 'User',
              type: 'user',
              sortable: true,
            },
            {
              field: 'OrderID',
              type: 'id',
            },
            {
              field: 'ClOrdID',
              type: 'id',
            },
          ] satisfies ColumnDef<CareOrder>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );
  const defaultHiddenColumns = useMemo(() => {
    return new Map(
      (
        [
          {
            field: 'ExecID',
            type: 'id',
          },
          {
            field: 'ExecType',
            type: 'text',
            sortable: true,
          },
          {
            field: 'TransactTime',
            type: 'date',
            sortable: true,
          },
          {
            field: 'Text',
            type: 'text',
            sortable: true,
          },
          {
            field: 'Timestamp',
            type: 'date',
            sortable: true,
          },
          {
            field: 'RequestUser',
            type: 'user',
            sortable: true,
          },
          {
            field: 'Revision',
            type: 'text',
          },
          {
            field: 'OrigClOrdID',
            type: 'id',
          },
          {
            field: 'OrdRejReason',
            type: 'text',
          },
          {
            field: 'CxlRejReason',
            type: 'text',
          },
          {
            field: 'isCancelable',
            type: 'text',
          },
          {
            field: 'isComplete',
            type: 'text',
          },
        ] satisfies (false | ColumnDef<CareOrder>)[]
      ).map(c => [getAgGridColId(c), { ...c, hide: true }])
    );
  }, []);
  const columnDefinitions = useMemo(() => {
    return new Map(
      (
        [
          ...defaultVisibleColumns.values(),
          ...defaultHiddenColumns.values(),
        ] satisfies ColumnDef<CareOrder>[] as Column[]
      ).map(c => [getAgGridColId(c), c])
    );
  }, [defaultVisibleColumns, defaultHiddenColumns]);
  return useDefaultColumns(defaultColumns, columnDefinitions);
}

export const CareOrderStatusEnum = {
  New: 'New',
  Rejected: 'Rejected',
  Canceled: 'Canceled',
  PartiallyFilled: 'Partially Filled',
  Filled: 'Filled',
  FilledAndCanceled: 'Filled & Canceled',
  PendingCancel: 'Pending Cancel',
  PendingReplace: 'Pending Replace',
  Unknown: 'Unknown',
} as const;

// TYPE LEVEL TESTS
type OmittedClassKeys =
  | 'isCancelable'
  | 'isComplete'
  | 'initiatingFrom'
  | 'status'
  | 'toJSON'
  | 'remainQty'
  | 'WorkingQty'
  | 'setWorkingQty';
type _Expect_CareOrder_To_Only_Have_Keys_From_ICareExecutionReport = ExpectTrue<
  {
    [K in Exclude<keyof CareOrder, OmittedClassKeys> & string]: K extends keyof ICareExecutionReport ? true : K;
  }[Exclude<keyof CareOrder, OmittedClassKeys>]
>;

type OmittedInterfaceKeys = 'MessageID' | 'OrdStatus' | 'OrgID';
type _Expect_All_Keys_In_ICareExecutionReport_To_Be_In_CareOrder = ExpectTrue<
  {
    [K in keyof Exclude<ICareExecutionReport, OmittedInterfaceKeys> & string]: K extends keyof CareOrder ? true : K;
  }[Exclude<keyof ICareExecutionReport, OmittedInterfaceKeys>]
>;
