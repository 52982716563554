import { createContext, type PropsWithChildren } from 'react';
import type { MixpanelEventSource } from '../tokens';

export const MixpanelSourceContext = createContext<MixpanelEventSource | undefined>(undefined);
MixpanelSourceContext.displayName = 'MixpanelSourceContext';

export function MixpanelSourceProvider({
  value,
  children,
}: PropsWithChildren<{ value: MixpanelEventSource | undefined }>) {
  return <MixpanelSourceContext.Provider value={value}>{children}</MixpanelSourceContext.Provider>;
}

export const withMixpanelSource =
  <P extends object>(Component: React.ComponentType<P>, value: MixpanelEventSource) =>
  (props: PropsWithChildren<P>) =>
    (
      <MixpanelSourceContext.Provider value={value}>
        <Component {...props} />
      </MixpanelSourceContext.Provider>
    );
