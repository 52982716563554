import { animated } from '@react-spring/web';
import styled from 'styled-components';
import { hoverColor, parseDimension } from '../../styles';
import { Box } from '../Core';

export const ChevronWrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const Header = styled(Box)<{ clickable: boolean; ghost: boolean }>`
  padding: ${({ theme, p }) => (p != null ? parseDimension(theme, p) : `${theme.spacingMedium}px`)};
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacingDefault}px;
  align-items: center;
  transition: background 200ms;

  ${({ clickable, ghost, theme }) =>
    clickable &&
    `
    :hover {
      cursor: pointer;
      ${!ghost ? `background: ${hoverColor(theme, theme.backgroundContentHover)};` : ''}
    }
  `};
`;

Header.defaultProps = {
  background: 'backgroundContent',
};

// we set height: fit-content and use the max-height to do the animation. See link below for explanation
// https://css-tricks.com/using-css-transitions-auto-dimensions/
export const AccordionContent = styled(animated.div).withConfig<{ ghost: boolean }>({
  shouldForwardProp: (prop, defaultValidatorFn) => prop !== 'ghost' && defaultValidatorFn(prop),
})`
  background: ${({ theme, ghost }) => (!ghost ? theme.backgroundAccordionContent : 'transparent')};
  height: fit-content;
`;

export const ContentWrapper = styled(Box)``;

ContentWrapper.defaultProps = {
  p: 'spacingMedium',
};
