import type { ReactNode } from 'react';
import styled from 'styled-components';
import { Tag } from '../../Tag';
import { formControlCSS } from '../styles';
import type { FormControlProps, FormControlSizes } from '../types';

export const MultiSelectWrapper = styled.div`
  position: relative;
`;

export const Selection = styled(Tag)<{ disabled: boolean; suffix?: ReactNode; formControlSize: FormControlSizes }>`
  border-radius: ${({ theme }) => theme.borderRadiusSmall}px;
  white-space: nowrap;
  position: relative;
  padding-right: ${({ suffix, theme, formControlSize }) =>
    suffix ? `${(formControlSize / 2) * theme.spacingLarge}px` : ''};
  padding-left: ${({ suffix, theme, formControlSize }) =>
    suffix ? `${(formControlSize / 2) * theme.spacingSmall}px` : ''};
  height: ${({ theme, formControlSize }) => theme.baseSize * (formControlSize - 0.5)}px;

  opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};
`;

export const MultiSelectInputWrapper = styled.label<FormControlProps<HTMLInputElement>>`
  ${formControlCSS}
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.spacingTiny}px;
  padding-bottom: ${({ theme }) => theme.spacingTiny}px;
  padding-left: ${({ theme }) => theme.spacingTiny}px;

  height: auto;
  max-width: 100%;

  i {
    line-height: 1;
  }

  input {
    min-width: 50px;
    padding-left: ${({ theme }) => theme.spacingDefault - theme.spacingTiny}px;
    line-height: 1.5;
  }
`;
