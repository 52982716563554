import type { Currency } from '../types/Currency';
import type { Security } from '../types/Security';
import { isCounterCurrency } from '../utils';
import { useCurrency } from './useCurrency';
import { useSecurity } from './useSecurity';

export const useOrderIncrements = (order: { Currency?: string; Symbol: string }) => {
  const security = useSecurity(order.Symbol);
  const currency = useCurrency(order.Currency);

  return getOrderIncrements(order, security, currency);
};

export function getOrderIncrements(
  order: { Currency?: string; Symbol: string },
  security: Security | undefined,
  currency: Currency | undefined
) {
  const isCcy = isCounterCurrency(order.Currency, security);

  let quantityIncrement: string | undefined;
  if (!order.Currency) {
    quantityIncrement = security?.DefaultSizeIncrement;
  } else if (isCcy) {
    quantityIncrement = security?.DefaultPriceIncrement;
  } else {
    quantityIncrement = currency?.DefaultIncrement;
  }

  return {
    quantityIncrement: quantityIncrement ?? '0.01',
  };
}
