import styled from 'styled-components';

export const ErrorBoundaryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  flex-direction: column;
  text-align: center;
  padding: ${({ theme }) => theme.spacingDefault}px;
`;

export const ErrorTitle = styled.p`
  color: ${({ theme }) => theme.colorTextImportant};
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colorTextSubtle};
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
`;
