import { useCallback, useMemo } from 'react';
import type { AutocompleteGroup, AutocompleteGroupSorterFunc } from './types';

/**
 * A utility hook to help you go from an array of your known groups, eg ["Group 1", "Group 2"], to a groupSorter function
 * to pass into useAutocomplete-related hook, which will sort your groups for you in the order of the passed in array here.
 * @param groups An array of your group labels in the order you wish to have them sorted
 * @returns a groupSorter func to pass into a useAutocomplete-like hook as prop
 */
export function useAutocompleteGroupSorter(groups: string[]): AutocompleteGroupSorterFunc {
  const groupsMap = useMemo(() => new Map(groups.map((group, i) => [group, i])), [groups]);

  const groupSorter = useCallback(
    (g1: Pick<AutocompleteGroup<any>, 'group'>, g2: Pick<AutocompleteGroup<any>, 'group'>) => {
      const g1Index = groupsMap.get(g1.group);
      const g2Index = groupsMap.get(g2.group);
      if (g1Index == null || g2Index == null) {
        return 0;
      }
      return g1Index - g2Index;
    },
    [groupsMap] // Re-render and pass along the new sorter func when this map changes
  );

  return groupSorter;
}
