export const Logo = ({ fill }) => (
  <>
    <path d="M43 156.727H51.2636V129.967H43.1008V122H60.8372V156.727H69V165H43V156.727Z" fill={fill} />
    <path
      d="M67 121.97H75.129V97.354L84.5627 90V121.97H95V129.936H84.5627V149.343C84.5627 157.514 88.8781 156.697 95 156.697V164.97C86.6703 164.97 75.129 166.4 75.129 149.445V130.039H67V121.97Z"
      fill={fill}
    />
    <path
      d="M185 121.97H193.129V97.354L202.563 90V121.97H213V129.936H202.563V149.343C202.563 157.514 206.878 156.697 213 156.697V164.97C204.67 164.97 193.129 166.4 193.129 149.445V130.039H185V121.97Z"
      fill={fill}
    />
    <path
      d="M109.604 156.746V98.356H100V90L131.807 90.1019C149.983 90.1019 156.386 99.5788 156.386 109.463C156.592 116.495 152.358 122.914 145.852 125.666C153.907 128.213 160 134.939 160 144.11C160 153.077 154.733 165 131.084 165H100V156.746H109.604ZM130.775 122.099C141.721 122.099 145.542 116.495 145.542 110.075C145.542 102.33 141.102 98.4579 129.225 98.4579C127.263 98.4579 120.138 98.5598 120.138 98.5598V122.201L130.775 122.099ZM130.981 156.746C144.2 156.746 149.053 151.855 149.053 143.397C149.053 136.773 144.819 129.946 131.394 129.946H120.034C120.034 131.474 120.138 156.644 120.138 156.644C120.138 156.644 128.916 156.746 130.981 156.746Z"
      fill={fill}
    />
    <path
      d="M48 102C48 98.6667 50.8889 96 54.5 96C58.1111 96 61 98.6667 61 102C61 105.333 58.1111 108 54.5 108C50.8889 108 48 105.333 48 102Z"
      fill={fill}
    />
    <path d="M161 156.727H169.264V129.967H161.101V122H178.837V156.727H187V165H161V156.727V156.727Z" fill={fill} />
    <path
      d="M166 102C166 98.6667 168.889 96 172.5 96C176.111 96 179 98.6667 179 102C179 105.333 176.111 108 172.5 108C168.889 108 166 105.333 166 102Z"
      fill={fill}
    />
  </>
);
