import { MARKET_ACCOUNT_LEDGER_EVENT, SUB_ACCOUNT_LEDGER_EVENT } from '../tokens';
import { formattedDate, prettyName, toBig } from '../utils';
import type {
  ExternalTypeEnum,
  ILedgerEvent,
  LedgerAccountTypeEnum,
  LedgerUpdateStatusEnum,
  LedgerUpdateTypeEnum,
} from './types';

export class LedgerEvent {
  static MarketAccountStreamName = MARKET_ACCOUNT_LEDGER_EVENT;
  static SubAccountStreamName = SUB_ACCOUNT_LEDGER_EVENT;

  TransactTime!: string;
  RequestID?: string;

  Type!: LedgerUpdateTypeEnum;
  /** Depending on the Type, AccountName will be either a MarketAccount.Name or a SubAccount.Name */
  AccountName!: string;

  AccountID!: number;
  Asset!: string;
  Amount!: string;
  Price!: string;
  PriceCurrency!: string;
  AvgCost!: string;
  AvgCostCurrency!: string;
  Status!: LedgerUpdateStatusEnum;
  Revision!: number;
  Timestamp!: string;
  TradeID?: string;
  ExternalID?: string;
  ResultingAmount!: string;
  AccountType!: LedgerAccountTypeEnum;
  ExternalType!: ExternalTypeEnum;
  Fee!: string;
  FeeCurrency!: string;
  AmountAsset?: string;
  Comments?: string;

  get amountAssetWithFallback() {
    return this.AmountAsset ?? this.Asset;
  }

  get rowID() {
    return `${this.AccountType}-${this.AccountID}-${this.Asset}-${this.Revision}`;
  }

  get dayString() {
    return formattedDate(this.TransactTime, '{yyyy}-{MM}-{dd}');
  }

  get hourString() {
    return formattedDate(this.TransactTime, '{yyyy}-{MM}-{dd} {HH}:{mm}');
  }

  /** What the amount was before this LedgerEvent */
  get startingAmount() {
    const amountBig = toBig(this.Amount);
    const resultingAmountBig = toBig(this.ResultingAmount);
    if (amountBig == null || resultingAmountBig == null) {
      return undefined;
    }

    return resultingAmountBig.minus(amountBig).toFixed();
  }

  constructor(data: ILedgerEvent | LedgerEvent) {
    Object.assign(this, data);
  }
}

export function prettifyLedgerUpdateTypeEnum(type: LedgerUpdateTypeEnum | string): string {
  return prettyName(type);
}
