import { hsl } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';

import { setAlpha } from '../utils';
import { darkColors, mapDarkTheme } from './DarkTheme';

export const colors: Colors = {
  black: {
    default: '#000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    main: hsl(240, 0.35, 0.11),

    '000': hsl(240, 0.35, 0.05),
    '010': hsl(240, 0.35, 0.08),
    '020': hsl(240, 0.25, 0.15),
    '030': hsl(240, 0.25, 0.17),
    '040': hsl(240, 0.25, 0.2),
    '050': hsl(240, 0.25, 0.23),
    '060': hsl(240, 0.25, 0.27),
    '070': hsl(240, 0.25, 0.34),
    '080': hsl(240, 0.25, 0.51),
    '090': hsl(240, 0.25, 0.65),
    '100': hsl(240, 0.25, 0.9),
  },

  primary: {
    mute: 'rgba(69, 140, 193, 0.1)',
    dim: 'rgba(69, 140, 193, 0.4)',
    default: '#5300cc',
    lighten: '#b453fb',
  },

  green: {
    mute: setAlpha(0.1, '#337e41'),
    dim: setAlpha(0.4, '#337e41'),
    default: '#008e3d',
    lighten: '#53af1e',
  },

  red: {
    mute: setAlpha(0.1, '#9e2525'),
    dim: setAlpha(0.1, '#9e2525'),
    default: '#9e2525',
    lighten: '#ec5023',
  },

  yellow: {
    mute: 'rgba(204, 172, 62, 0.1)',
    dim: 'rgba(204, 172, 62, 0.4)',
    default: '#CCAC3E',
    lighten: '#F2D264',
  },

  blue: {
    mute: 'rgba(69, 140, 193, 0.1)',
    dim: 'rgba(69, 140, 193, 0.4)',
    default: '#005a9d',
    lighten: '#3aa8ff',
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: darkColors.purple,
};

export const DarkGradientsTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkGradients',

  fontUrl: 'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;1,400;1,500&display=swap',
  fontFamily: "'Poppins', sans-serif",

  spacingLayout: 16,

  borderRadiusDefault: 8,
  borderRadiusSmall: 6,
  borderRadiusTiny: 4,

  borderRadiusContent: 8,
  borderRadiusButtonDefault: 8,
  borderRadiusButtonSmall: 6,
  borderRadiusButtonTiny: 4,

  borderRadiusLayoutWrapper: 16,
  spacingLayoutWrapper: 16,
  backgroundLayoutWrapper: 'rgba(0,0,0,0.25)',

  borderColorDivider: colors.gray['030'],

  boxShadowContent: '0 2px 24px rgba(0, 0, 0, 0.25)',
  boxShadowHeader: '0 2px 24px rgba(0, 0, 0, 0.25)',
  borderColorHeader: colors.gray['000'],

  backgroundBody: `url(/customers/GradientsDark.svg) 100% 100%`,
  backgroundShadow: colors.gray['010'],
  backgroundHeader: setAlpha(0.5, colors.black.default),
  backgroundDrawer: setAlpha(0.5, colors.black.default),
  backdropFilterDrawer: 'blur(10px)',
  backgroundSidebarItemHover: setAlpha(0.1, colors.white.default),
  backgroundContent: `radial-gradient(
    farthest-corner at 50% 0%,
    ${colors.gray.main} 50%,
    ${colors.gray['010']} 100%
  )`,
  borderColorContent: colors.gray.main,

  backgroundPrimaryButton: `radial-gradient(farthest-corner at 0% 0%, #c74cfd 0%, ${colors.primary.default} 100%)`,
  backgroundPrimaryButtonHover: `radial-gradient(farthest-corner at 0% 0%, #c74cfd 50%, ${colors.primary.default} 100%)`,
  backgroundPrimaryButtonFocus: `radial-gradient(farthest-corner at 0% 0%, #c74cfd 25%, ${colors.primary.default} 100%)`,
  backgroundPrimaryButtonActive: `radial-gradient(farthest-corner at 0% 0%, #c74cfd 0%, ${colors.primary.default} 100%)`,

  borderColorPositiveButton: colors.green.default,

  backgroundPositiveButton: `radial-gradient(farthest-corner at 0% 0%, #78be10 0%, ${colors.green.default} 100%)`,
  backgroundPositiveButtonHover: `radial-gradient(farthest-corner at 0% 0%, #78be10 50%, ${colors.green.default} 100%)`,
  backgroundPositiveButtonFocus: `radial-gradient(farthest-corner at 0% 0%, #78be10 25%, ${colors.green.default} 100%)`,
  backgroundPositiveButtonActive: `radial-gradient(farthest-corner at 0% 0%, #78be10 0%, ${colors.green.default} 100%)`,

  backgroundNegativeButton: `radial-gradient(farthest-corner at 0% 0%, #ff5300 0%, ${colors.red.default} 100%)`,
  backgroundNegativeButtonHover: `radial-gradient(farthest-corner at 0% 0%, #ff5300 50%, ${colors.red.default} 100%)`,
  backgroundNegativeButtonFocus: `radial-gradient(farthest-corner at 0% 0%, #ff5300 25%, ${colors.red.default} 100%)`,
  backgroundNegativeButtonActive: `radial-gradient(farthest-corner at 0% 0%, #ff5300 0%, ${colors.red.default} 100%)`,

  backgroundPriorityButton: `radial-gradient(farthest-corner at 0% 0%, ${colors.gray['060']} 0%, ${colors.gray['020']} 100%)`,
  backgroundPriorityButtonHover: `radial-gradient(farthest-corner at 0% 0%, ${colors.gray['060']} 50%, ${colors.gray['020']} 100%)`,
  backgroundPriorityButtonFocus: `radial-gradient(farthest-corner at 0% 0%, ${colors.gray['060']} 25%, ${colors.gray['020']} 100%)`,
  backgroundPriorityButtonActive: `radial-gradient(farthest-corner at 0% 0%, ${colors.gray['060']} 0%, ${colors.gray['020']} 100%)`,

  backgroundDefaultButton: `radial-gradient(farthest-corner at 0% 0%, ${colors.gray['060']} 0%, ${colors.gray['020']} 100%)`,
  backgroundDefaultButtonHover: `radial-gradient(farthest-corner at 0% 0%, ${colors.gray['060']} 50%, ${colors.gray['020']} 100%)`,
  backgroundDefaultButtonFocus: `radial-gradient(farthest-corner at 0% 0%, ${colors.gray['060']} 25%, ${colors.gray['020']} 100%)`,
  backgroundDefaultButtonActive: `radial-gradient(farthest-corner at 0% 0%, ${colors.gray['060']} 0%, ${colors.gray['020']} 100%)`,

  boxShadowPrimaryButton: `0 1px 5px ${setAlpha(0.25, colors.primary.lighten)}`,
  boxShadowPrimaryButtonHover: `0 1px 15px ${setAlpha(0.5, colors.primary.lighten)}`,
  boxShadowPrimaryButtonFocus: `0 1px 10px ${setAlpha(0.35, colors.primary.lighten)}`,
  boxShadowPrimaryButtonActive: `0 1px 2px ${setAlpha(0.25, colors.primary.lighten)}`,
  boxShadowPrimaryButtonDisabled: 'none',

  boxShadowPositiveButton: `0 1px 5px ${setAlpha(0.25, colors.green.lighten)}`,
  boxShadowPositiveButtonHover: `0 1px 15px ${setAlpha(0.5, colors.green.lighten)}`,
  boxShadowPositiveButtonFocus: `0 1px 10px ${setAlpha(0.35, colors.green.lighten)}`,
  boxShadowPositiveButtonActive: `0 1px 2px ${setAlpha(0.25, colors.green.lighten)}`,
  boxShadowPositiveButtonDisabled: 'none',

  boxShadowNegativeButton: `0 1px 5px ${setAlpha(0.25, colors.red.lighten)}`,
  boxShadowNegativeButtonHover: `0 1px 15px ${setAlpha(0.5, colors.red.lighten)}`,
  boxShadowNegativeButtonFocus: `0 1px 10px ${setAlpha(0.35, colors.red.lighten)}`,
  boxShadowNegativeButtonActive: `0 1px 2px ${setAlpha(0.25, colors.red.lighten)}`,
  boxShadowNegativeButtonDisabled: 'none',

  boxShadowPriorityButton: `0 1px 5px ${setAlpha(0.25, colors.gray['020'])}`,
  boxShadowPriorityButtonHover: `0 1px 15px ${setAlpha(0.5, colors.gray['020'])}`,
  boxShadowPriorityButtonFocus: `0 1px 10px ${setAlpha(0.35, colors.gray['020'])}`,
  boxShadowPriorityButtonActive: `0 1px 2px ${setAlpha(0.25, colors.gray['020'])}`,
  boxShadowPriorityButtonDisabled: 'none',

  boxShadowDefaultButton: `0 1px 5px ${setAlpha(0.25, colors.gray['020'])}`,
  boxShadowDefaultButtonHover: `0 1px 15px ${setAlpha(0.5, colors.gray['020'])}`,
  boxShadowDefaultButtonFocus: `0 1px 10px ${setAlpha(0.35, colors.gray['020'])}`,
  boxShadowDefaultButtonActive: `0 1px 2px ${setAlpha(0.25, colors.gray['020'])}`,
  boxShadowDefaultButtonDisabled: 'none',

  boxShadowInput: 'none',
  boxShadowInputHover: `0 0 5px ${setAlpha(0.25, colors.white.default)}`,
  boxShadowInputFocus: `0 0 10px ${setAlpha(0.5, colors.blue.lighten)}`,
  boxShadowInputInvalid: `0 0 10px ${setAlpha(0.5, colors.red.lighten)}`,
  boxShadowInputDisabled: 'none',
  boxShadowInputInvalidFocus: `0 0 10px ${setAlpha(0.25, colors.red.lighten)}`,

  backgroundRadiobutton: `radial-gradient(farthest-corner at 80% 80%, ${colors.gray['020']} 0%, ${colors.gray['010']} 100%)`,
  backgroundRadiobuttonHover: `radial-gradient(farthest-corner at 80% 80%, ${colors.gray['030']} 0%, ${colors.gray.main} 100%)`,
  backgroundRadiobuttonChecked: `radial-gradient(farthest-corner at 0% 0%, #c74cfd 25%, ${colors.primary.default} 100%)`,
  borderColorRadiobuttonFocus: colors.primary.lighten,
  borderColorRadiobuttonHover: colors.primary.lighten,

  borderColorInput: setAlpha(0.05, colors.white.default),
  borderColorInputHover: setAlpha(0.5, colors.white.default),
  borderColorInputFocus: colors.blue.lighten,

  backgroundBlotterTableRow: 'transparent',
  backgroundBlotterTableRowHover: setAlpha(0.05, colors.white.default),
  borderColorBlotterTable: colors.gray['030'],

  backgroundToggleChecked: `radial-gradient(farthest-corner at 0% 0%, #c74cfd 0%, ${colors.primary.default} 100%)`,
  backgroundProgressBarMeter: `radial-gradient(farthest-corner at 0% 0%, #c74cfd 0%, ${colors.primary.default} 100%)`,

  borderColorTabSelected: colors.blue.lighten,
  boxShadowTabActive: `0 0 15px 1px ${setAlpha(0.75, colors.blue.lighten)}`,

  backgroundVolumeLadderBidSpread: `linear-gradient(#6e1be7 0%, ${colors.primary.default} 100%)`,
  backgroundVolumeLadderOfferSpread: `linear-gradient(#6e1be7 0%, ${colors.primary.default} 100%)`,
});
