import type { ValueGetterParams } from 'ag-grid-community';
import { Transfer } from '../../../types';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

export const transferDescription: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  valueGetter: (params: ValueGetterParams<Transfer>): string | undefined => {
    const transfer = params.node?.data;
    if (!transfer || !(transfer instanceof Transfer)) {
      return undefined;
    }

    return transfer.getTransferDescription(params.context.current.marketAccountsByID);
  },
});
