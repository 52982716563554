import styled from 'styled-components';

export const AutocompleteGroupLabel = styled.div`
  border-top: ${({ theme }) => `1px solid ${theme.colors.gray['030']}`};
  padding: ${({ theme }) => theme.spacingDefault}px;
  padding-bottom: ${({ theme }) => theme.spacingSmall}px;
`;

export const AutocompleteGroupItemLabel = styled.div<{ showBorder: boolean; showConnected: boolean }>`
  ${({ theme, showBorder }) => showBorder && `border-top: 1px solid ${theme.colors.gray['030']};`}
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 2px;
    top: ${({ theme }) => theme.baseSize + 4}px;
    bottom: ${({ theme }) => -theme.baseSize * 0.3125}px;
    left: ${({ theme }) => theme.baseSize - 1}px;
    background: ${({ theme }) => theme.borderColorCheckbox};
    z-index: 2;
    display: ${({ showConnected }) => (showConnected === true ? 'block' : 'none')};
  }
`;
