import type { ICellRendererParams } from 'ag-grid-enterprise';
import type { Currency } from '../../types/Currency';
import type { RequiredProperties } from '../../utils';
import { CurrencyRenderer } from '../CurrencyRenderer';
import type { AgGridCurrencyProps } from './types';

export function AgGridCurrency({
  value,
  currencies,
  showDescription,
  showIcon = true,
  context,
  colorful,
  iconSize = 16,
}: ICellRendererParams & AgGridCurrencyProps) {
  const currency: RequiredProperties<Partial<Currency>, 'Description' | 'Symbol'> | undefined = (
    currencies ?? context.current.currenciesBySymbol
  )?.get(value);
  const shouldRenderDescription = showDescription !== false && currency?.Description != null;

  return (
    <CurrencyRenderer
      currencyString={value}
      currency={currency}
      showDescription={shouldRenderDescription}
      showIcon={showIcon}
      theme={context.current.theme}
      colorful={colorful}
      iconSize={iconSize}
    />
  );
}
