import Big from 'big.js';
import { ConnectionStatusEnum, type Balance, type CurrencyConversionRate } from '../types';
import { useCurrencyConversionRateValue } from './useCurrencyConversionRate';

export function useMaxBalance(
  balances: Map<string, Balance> | undefined,
  currency: string | undefined,
  formCurrency: string | null | undefined
) {
  const conversionRate =
    useCurrencyConversionRateValue(currency, formCurrency ?? undefined) ??
    // If no conversion rate is available, return 1:1 conversion rate if the currencies are the same
    oneToOneConversionRate({ currency, formCurrency });
  if (!currency || !balances) {
    return undefined;
  }

  const balance = balances.get(currency);

  // Convert balance to max available amount to buy/sell based on side and form currency
  const value =
    balance?.AvailableAmount != null &&
    balance.AvailableAmount !== '' &&
    conversionRate?.Rate != null &&
    conversionRate.EquivalentCurrency === formCurrency &&
    conversionRate.Asset === currency
      ? Big(conversionRate.Rate).times(balance.AvailableAmount).toFixed()
      : undefined;
  return value;
}

const oneToOneConversionRate = ({
  currency,
  formCurrency,
}: {
  currency: string | undefined;
  formCurrency: string | undefined | null;
}): CurrencyConversionRate | undefined => {
  if (formCurrency && currency && formCurrency === currency) {
    return {
      Asset: currency,
      EquivalentCurrency: currency,
      Rate: '1',
      Status: ConnectionStatusEnum.Online,
      Currency: currency,
      Symbol: currency,
    };
  }
  return undefined;
};
