import { animated } from '@react-spring/web';
import styled, { type CSSProperties } from 'styled-components';
import { Flex } from '../Core';

export const SectionWrapper = styled(Flex)`
  gap: ${({ theme }) => theme.spacingSmall}px;
`;

export const AnimatedFlex = styled(animated(Flex))`
  gap: ${({ theme }) => theme.spacingSmall}px;
`;

export const DistributionWrapper = styled(Flex)<{
  barHeight?: CSSProperties['height'];
  barBorderRadius?: CSSProperties['borderRadius'];
}>`
  --barHeight: ${({ barHeight, theme }) => barHeight ?? `${theme.spacingSmall}px`};
  --barBorderRadius: ${({ barBorderRadius }) => barBorderRadius ?? 0};
  width: 100%;
  border-radius: var(--barBorderRadius);
  overflow: hidden;
`;
