import type { Security } from '../types/Security';
import {
  InstrumentCompositionEnum,
  ProductTypeEnum,
  SettleValueTypeEnum,
  SyntheticProductTypeEnum,
} from '../types/types';
import { toBigWithDefault } from './number';

/**
 * Returns true if the Security supports being traded in counter currency amounts.
 * Security needs a PositionCurrency https://talostrading.atlassian.net/wiki/spaces/AVA/pages/1097760782/How+to+use+currencies#Performing-conversions-for-derivatives
 */
export const canCcyFutureOrPerp = (security?: Security | undefined): boolean => {
  return !isSecurityQuantoFuture(security);
};

/**
 * Identifies Quanto contract by checking if SettlementCurrency is neither the base nor quote currency on the instrument.
 * https://talostrading.atlassian.net/wiki/spaces/AVA/pages/2199552112/Future+and+Perpetual+Contract+Types
 */
export const isSecurityQuantoFuture = (security?: Security): boolean => {
  return (
    !!security &&
    security.ProductType !== ProductTypeEnum.Spot &&
    ![security.BaseCurrency, security.QuoteCurrency].includes(security.SettlementCurrency)
  );
};

export const isSecurityExpired = (security?: Security): boolean => {
  if (!security || !security.Expiration) {
    return false;
  }
  return new Date(security.Expiration) < new Date();
};

export const isSecurityCME = (security?: Security): boolean => {
  return security?.Markets?.includes('cme') || false;
};

export const isSecurityDeribit = (security?: Security): boolean => {
  return security?.Markets?.includes('deribit') || false;
};

export const isDeribitLinearOption = (security?: Security) => {
  return (
    security &&
    isSecurityDeribit(security) &&
    isOption(security) &&
    security.BaseCurrency !== security.QuoteCurrency &&
    security.SettleValueType === SettleValueTypeEnum.Regular
  );
};

export const isSpot = (security?: Security) => {
  return (
    security &&
    security.ProductType === ProductTypeEnum.Spot &&
    security.Composition !== InstrumentCompositionEnum.Synthetic
  );
};

export const isPerpetualSwap = (security?: Security) => {
  return security && security.ProductType === ProductTypeEnum.PerpetualSwap;
};

export const isInversePerp = (security?: Security) => {
  return isPerpetualSwap(security) && security?.SettleValueType === SettleValueTypeEnum.Inverted;
};

export const isLinearPerp = (security?: Security) => {
  return isPerpetualSwap(security) && security?.SettleValueType === SettleValueTypeEnum.Regular;
};

export const isFuture = (security?: Security) => {
  return security && security.ProductType === ProductTypeEnum.Future;
};

export const isCFD = (security?: Security) => {
  return security && security.ProductType === ProductTypeEnum.CFD;
};

export const isETF = (security?: Security) => {
  return security && security.ProductType === ProductTypeEnum.ExchangeTradedFund;
};

export const isOption = (security?: Security) => {
  return security && security.ProductType === ProductTypeEnum.Option;
};

export const isMultileg = (security?: Security) => {
  return Boolean(security && security?.Composition === InstrumentCompositionEnum.Synthetic);
};

export const isDelta1Multileg = (security?: Security) => {
  return security && security?.MultilegDetails?.SyntheticProductType === SyntheticProductTypeEnum.Delta1Spread;
};

export const isCross = (security?: Security) => {
  return security && security?.MultilegDetails?.SyntheticProductType === SyntheticProductTypeEnum.Cross;
};

export const isCalendarSpread = (security?: Security): boolean => {
  return security != null && security.ProductType === ProductTypeEnum.CalendarSpread;
};

export const isOptionStrategy = (security?: Security) => {
  if (!security?.MultilegDetails?.SyntheticProductType) {
    return false;
  }

  return [
    SyntheticProductTypeEnum.CallSpread,
    SyntheticProductTypeEnum.PutSpread,
    SyntheticProductTypeEnum.CallCalendarSpread,
    SyntheticProductTypeEnum.PutCalendarSpread,
  ].includes(security.MultilegDetails.SyntheticProductType);
};

export const isBasis = (security?: Security) => {
  // ProductType Basis is legacy
  return (
    security &&
    (security.ProductType === ProductTypeEnum.Basis ||
      security.ProductType === ProductTypeEnum.CalendarSpread ||
      (isMultileg(security) && security.MultilegDetails?.SyntheticProductType !== SyntheticProductTypeEnum.Cross))
  );
};

export const getProductType = (security?: Security) => {
  if (!security) {
    return '';
  }

  if (isMultileg(security) && !security?.MultilegDetails?.SyntheticProductType) {
    return '';
  }

  return isMultileg(security)
    ? productTypeToString(security.MultilegDetails!.SyntheticProductType)
    : productTypeToString(security.ProductType);
};

// Not the same as ProductTypeEnum since it's not 1:1, i.e. Multi isn't a ProductType
export type ProductGroup =
  | 'Spot'
  | 'Perp'
  | 'Future'
  | 'Multi'
  | 'Option'
  | 'Basis'
  | 'CFD'
  | 'Synthetic'
  | 'Unknown'
  | 'Cross'
  | 'Delta1Spread'
  | 'CallCalendarSpread'
  | 'PutCalendarSpread'
  | 'CalendarSpread'
  | 'CallSpread'
  | 'PutSpread'
  | 'ExchangeTradedFund';

export const getProductGroupLabel = (productGroup: ProductGroup): string => {
  switch (productGroup) {
    case 'CalendarSpread':
      return 'Calendar Spread';
    case 'Delta1Spread':
      return 'Delta 1 Spread';
    case 'CallCalendarSpread':
      return 'Call Calendar Spread';
    case 'PutCalendarSpread':
      return 'Put Calendar Spread';
    case 'CallSpread':
      return 'Call Spread';
    case 'PutSpread':
      return 'Put Spread';
    case 'ExchangeTradedFund':
      return 'ETF';
    case 'CFD':
      return 'Swap';
    default:
      return productGroup;
  }
};

export const productTypeToString = (productType: ProductTypeEnum | SyntheticProductTypeEnum): ProductGroup => {
  switch (productType) {
    case ProductTypeEnum.PerpetualSwap:
      return 'Perp';
    default:
      return productType;
  }
};

export const productTypeLabel = (productType: ProductTypeEnum | SyntheticProductTypeEnum): string => {
  return getProductGroupLabel(productTypeToString(productType));
};

export const getGroup = (sec: Security): ProductGroup =>
  sec.Composition === InstrumentCompositionEnum.Synthetic ? 'Multi' : productTypeToString(sec.ProductType);

export enum SpreadUnit {
  BPS = 'BPS',
  PRICE = 'Price',
}

export const getSpreadUnit = (security?: Security): SpreadUnit =>
  isOption(security) || isBasis(security) ? SpreadUnit.PRICE : SpreadUnit.BPS;

// Contracts are allowed if NotionalMultiplier != 1
// Mandatory and enforced for Quantos
export const allowContracts = (security?: Security) =>
  security &&
  (!toBigWithDefault(security?.NotionalMultiplier, 1).eq(1) ||
    isSecurityQuantoFuture(security) ||
    isDeribitLinearOption(security));

export const getMinIncrementForContract = (security: Security, currency: string | undefined): string | undefined => {
  if (
    (security.SettlementCurrency === security.BaseCurrency && currency === security.QuoteCurrency) ||
    (security.SettlementCurrency === security.QuoteCurrency && currency === security.BaseCurrency)
  ) {
    return security.NotionalMultiplier;
  }
};

export const canViewDeepDive = (security?: Security) => {
  return security != null;
};

export const isExpired = (security?: Security) => {
  return security?.Expiration != null && new Date(security.Expiration) < new Date();
};
