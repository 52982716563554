import type { ModeEnum } from './types';

export type Aggregation = {
  Name: string;
  DisplayName: string;
  AggregationType: AggregationType;
  FairPriceProtection: boolean;
};

export interface AggregationWithAccounts extends Aggregation {
  Accounts: Map<string, AggregationMarket>;
}

export interface AggregationMarket {
  Aggregation: string;
  Market?: string;
  MarketAccount?: string;
  Mode: ModeEnum;
  // RowID for Customer Aggregations Blotter
  RowID?: string;
}

export enum AggregationType {
  Customer = 'Customer',
  System = 'System',
  User = 'User',
}
