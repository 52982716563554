import { createContext, useContext } from 'react';

export const HomeCurrencyContext = createContext<HomeCurrencyContextProps | undefined>(undefined);
HomeCurrencyContext.displayName = 'HomeCurrencyContext';

export function useHomeCurrencyContext() {
  const context = useContext(HomeCurrencyContext);
  if (context === undefined) {
    throw new Error('Missing HomeCurrencyContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export interface HomeCurrencyContextProps {
  homeCurrency: string;
}
