import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { HStack } from '../Core';
import { Icon } from '../Icons';

export const BreadcrumbWrapper = styled(HStack)`
  justify-content: flex-start;
  flex: 0 0 auto;
  overflow: hidden;
`;

export const BreadcrumbItem = styled(Link)<{ $isLast?: boolean }>`
  display: inline-block;
  padding: ${({ theme }) => `${theme.spacingSmall}px`};
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  border-bottom: unset;

  color: ${({ theme, $isLast }) => ($isLast ? theme.colorTextAttention : theme.colorTextDefault)};
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;

  &:hover {
    color: ${({ theme }) => theme.colors.gray['100']};
    ${Icon} {
      color: ${({ theme }) => theme.colors.gray['100']};
    }
  }
`;
