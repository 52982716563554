import { noop } from 'lodash';
import { createContext, useContext, useRef } from 'react';
import { ButtonVariants } from '../components/Button/styles';
import type { DialogProps } from '../components/Dialog/types';
import { useDisclosure, type UseDisclosureReturn } from '../hooks/useDisclosure';
import { useDynamicCallback } from '../hooks/useDynamicCallback';

type OpenDialogProps = Partial<DialogProps> & { content: JSX.Element };

const Dialog = createContext<
  | ({
      content?: JSX.Element;
      open: (params?: OpenDialogProps) => void;
    } & DialogProps &
      Omit<UseDisclosureReturn, 'open'>)
  | undefined
>(undefined);

export const useGlobalDialog = () => {
  const context = useContext(Dialog);
  if (context === undefined) {
    throw new Error('Missing Dialog.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};

const DEFAULT_PARAMS = {
  onConfirm: noop,
  onCancel: noop,
  content: undefined,
  confirmLabel: 'Yes',
  cancelLabel: 'No',
  showClose: false,
  showCancel: true,
  showConfirm: true,
  closeOnClickOutside: true,
  stretchButtons: true,
  variant: ButtonVariants.Primary,
};

export const DialogProvider = function DialogProvider({ children }) {
  const paramsRef = useRef<Partial<OpenDialogProps>>();

  // TODO: parameterize more props as needed, starting with most common for now
  // NOTE: usePortal is not supported. This Dialog is on top of the DOM chain which means that we can't actually place it inline the local component
  const dialog = useDisclosure();

  const openDialog = useDynamicCallback((params?: OpenDialogProps) => {
    paramsRef.current = { ...DEFAULT_PARAMS, ...params };

    dialog.open();
  });

  return (
    <Dialog.Provider
      value={{
        ...dialog,
        open: openDialog,
        ...paramsRef.current,
      }}
    >
      {children}
    </Dialog.Provider>
  );
};
