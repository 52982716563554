import styled from 'styled-components';
import { MarketColorDotVariant, type MarketColorDotProps } from './types';

const DOT_SIZE = 8;

type MarketColorDotWrapperProps = Omit<MarketColorDotProps, 'market'> & {
  color: string;
  borderColor: string;
};

export const MarketColorDotWrapper = styled.div<MarketColorDotWrapperProps>`
  background: ${({ color }) => `${color}`};
  ${({ variant, borderColor }) => variant === MarketColorDotVariant.Bordered && `border: 1px solid ${borderColor};`}
  border-radius: 50%;
  height: ${({ variant }) => `${DOT_SIZE + (variant === MarketColorDotVariant.Bordered ? 1 : 0)}px`};
  min-width: ${({ variant }) => `${DOT_SIZE + (variant === MarketColorDotVariant.Bordered ? 1 : 0)}px`};
`;
