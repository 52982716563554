import type { DefaultTheme } from 'styled-components';
import type { KeysBeginningWith } from '../../utils/types';
import type { BoxProps } from '../Core';

export enum TableSize {
  Small = 'Small',
  Default = 'Default',
  Large = 'Large',
}

export interface TableProps extends BoxProps {
  zebra?: boolean;
  size?: TableSize;
  bordered?: boolean;
  rowHeight?: string;
  insetPadding?: KeysBeginningWith<DefaultTheme, 'spacing'>;
}

export type ColProps = { background?: string };
