import { has } from 'lodash';
import type { ParameterLike, StrategyLike } from '../../../types/OrderStrategy';
import { PresenceEnum } from '../../../types/types';
import { logger } from '../../../utils';
import { ParametersTable } from '../../ParametersTable';
import { Text } from '../../Text';
import { Tooltip } from '../../Tooltip';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

export const orderParameters: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  valueFormatter: params => {
    const strategiesByName = params.context.current.strategiesByName;
    const strategy = params.data?.Strategy;
    try {
      const value =
        strategy && strategiesByName?.has(strategy)
          ? filterOrderStrategyParams(params.value, strategy, strategiesByName)
          : params.value;
      return JSON.stringify(value);
    } catch (e) {
      logger.error(e as Error);
      return params.value;
    }
  },
  cellRenderer: params => {
    return (
      <Tooltip usePortal tooltip={<ParametersTable order={params.data} />} placement="bottom-start">
        <Text>{params.valueFormatted ?? ''}</Text>
      </Tooltip>
    );
  },
});

interface OrderStrategyParams {
  [key: string]: ParameterLike;
}

/*
 * Filter out order strategy parameters that are not present or hidden in Strategy parameters
 */
function filterOrderStrategyParams(
  orderStrategyParams: OrderStrategyParams,
  strategyName: string,
  strategiesByName: Map<string, StrategyLike>
): OrderStrategyParams {
  const strategyParams = strategiesByName.get(strategyName)?.Parameters ?? [];
  return strategyParams.reduce((acc, strategyParam: ParameterLike) => {
    const key = strategyParam.Name;
    if (
      has(orderStrategyParams, key) &&
      ![PresenceEnum.Hidden, PresenceEnum.TopLevelHidden].includes(strategyParam.Presence)
    ) {
      acc[key] = orderStrategyParams[key];
    }
    return acc;
  }, {});
}
