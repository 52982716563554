import type { GridOptions, GroupCellRendererParams } from 'ag-grid-community';
import { GroupCellRenderer } from 'ag-grid-enterprise';
import { Text } from '../../Text';
import { AGGRID_AUTOCOLUMN_ID } from '../types';

/**
 * When you are building a tree blotter, you need a different group column than if you're building a standard row grouping blotter.
 *
 * A row grouping blotter can figure out exactly what entity / column is being used to perform grouping and grab formatting and filtering logic
 * from the correct columns. A tree blotter cannot do this, and thus the implementer of new tree blotters has to apply manual logic
 * (overriding on top of this base object) to perform the necessary functions.
 *
 * Lastly, this column definition is not a factory like the other surrounding column definitions. Group columns are still handled
 * differently by useBlotterTable in terms of being passed as a parameter.
 */
export const baseTreeGroupColumnDef: GridOptions['autoGroupColumnDef'] = {
  cellRendererSelector: params => {
    // If its a pinned row (the totals row in this case), we opt out of the default group cell renderer and just do basic text to show "Totals"
    return params.node.rowPinned
      ? {
          params,
          component: (params: GroupCellRendererParams) => <Text>{params.data.groupColumnValue ?? params.value}</Text>,
        }
      : // else we just imitate the basic GroupCellRenderer behavior for all other rows
        { params, component: GroupCellRenderer };
  },
  colId: AGGRID_AUTOCOLUMN_ID,
  headerName: 'Group',
  editable: false,
  suppressColumnsToolPanel: false,
  cellRendererParams: {
    suppressCount: true,
  },
  sortable: true,
};
