import styled from 'styled-components';
import { SideEnum } from '../../../types';

export const ModifyOrderID = styled.div`
  font-size: ${({ theme }) => theme.fontSizeTiny}rem;
`;

export const ModifyWrapper = styled.div<{ side?: SideEnum | null }>`
  text-align: right;
  margin-left: auto;
  color: ${({ theme, side }) =>
    side === SideEnum.Buy
      ? theme.colorTextPositive
      : side === SideEnum.Sell
      ? theme.colorTextNegative
      : theme.colorTextPrimary};

  ${ModifyOrderID} {
    color: ${({ theme, side }) =>
      side === SideEnum.Buy
        ? theme.colorTextPositiveSubtle
        : side === SideEnum.Sell
        ? theme.colorTextNegativeSubtle
        : theme.colorTextPrimary};
  }
`;
