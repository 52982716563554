import { Crossmark, logger } from '@talos/kyoko';
import type React from 'react';
import { Component, type ErrorInfo } from 'react';
import { ErrorBoundaryWrapper, ErrorMessage, ErrorTitle } from './styles';

export class ErrorBoundary extends Component<
  React.PropsWithChildren<{ message?: string; minimal?: boolean }>,
  { hasError: boolean }
> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#react-error-boundaries-instrumentation
    const renderingError = new Error(error.message);
    renderingError.name = `ReactRenderingError`;
    renderingError.stack = info.componentStack;
    renderingError.cause = error;
    logger.error(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorBoundaryWrapper {...this.props}>
          {!this.props.minimal && <Crossmark />}
          {this.props.message || (
            <>
              <ErrorTitle>An unexpected error occurred</ErrorTitle>
              {!this.props.minimal && (
                <ErrorMessage>
                  If this issue persists, please get in touch with support and we will resolve the problem as soon as
                  possible.
                </ErrorMessage>
              )}
            </>
          )}
        </ErrorBoundaryWrapper>
      );
    }

    return this.props.children;
  }
}
