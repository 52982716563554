import type React from 'react';
import { memo, useContext, type ReactNode } from 'react';

import { abbreviate, format, highlight, toBigWithDefault } from '../../utils/number';

import { Big } from 'big.js';
import { isEqual } from 'lodash';
import type { DefaultTheme } from 'styled-components';
import { CurrenciesContext } from '../../contexts';
import { Icon } from '../Icons';
import { Currency, FullNumber, Highlight, Indicator, Number, NumberWrapper, RoundedNumber } from './styles';
import { SmartTruncateEnum, type InlineFormattedNumberProps, type NumberVariants } from './types';

export { NumberWrapper } from './styles';

const DEFAULT_ICON_STYLE = {
  size: 9,
  style: { marginRight: '2px' },
};

// The maximum increment that shows on compact mode before truncated *
const SMART_TRUNCATE_MAX_INCREMENT = '0.00001';
const SMART_TRUNCATE_THRESHOLD = 1000000;
const trimZeroesFunc = (value: string, trimZeroesRegExp: RegExp) => {
  const [_, beforeDecimal, afterDecimal] = trimZeroesRegExp.exec(value) ?? [];
  return afterDecimal?.length ? `${beforeDecimal}.${afterDecimal}` : beforeDecimal;
};

export const InlineFormattedNumber: React.FC<InlineFormattedNumberProps> = memo(
  props => {
    const {
      number,
      startIcon,
      specification = 'M.m',
      increment: _increment,
      isSmall: _isSmall,
      largeHighlight,
      highlightAll = true,
      currency,
      background,
      align = 'left',
      variant,
      truncate = true,
      pretty = true,
      round = false,
      estimate = false,
      theme,
      showSign,
      highlightColor,
      smartTruncate = SmartTruncateEnum.None,
      smartTruncateThreshold = SMART_TRUNCATE_THRESHOLD,
      trimTrailingZeroes = smartTruncate === SmartTruncateEnum.Compact,
      prefix,
    } = props;

    const currenciesContext = useContext(CurrenciesContext);
    const currencyInfo = currenciesContext?.currenciesBySymbol?.get(currency ?? '');
    const increment = _increment ?? currencyInfo?.DefaultIncrement;
    const incrementIsTooSmall =
      smartTruncate === SmartTruncateEnum.Compact &&
      toBigWithDefault(increment, 0).gt(0) &&
      toBigWithDefault(increment, 0).lt(SMART_TRUNCATE_MAX_INCREMENT);
    const isSmall = _isSmall ?? incrementIsTooSmall;

    if (number == null) {
      return null;
    }

    const formatFn =
      smartTruncate === SmartTruncateEnum.Compact && Big(number).gte(smartTruncateThreshold) ? abbreviate : format;

    const fullNumberString = formatFn(number.toString(), { spec: increment, pretty, showSign });
    const numberString = formatFn(number.toString(), {
      spec: incrementIsTooSmall ? SMART_TRUNCATE_MAX_INCREMENT : increment,
      truncate,
      round,
      pretty,
      showSign,
    });

    let formattedFullNumberString = fullNumberString;
    let formattedNumberString = numberString;

    if (trimTrailingZeroes) {
      /**
       * Anything before a possible decimal point, followed by a decimal point with any number of digits,
       * ending in any number of explicit 0's. If there's no decimal point, take the whole string as capture
       * group 1. If there's a decimal point with digits following it, take any number of digits excluding
       * trailing 0's before end of string as capture group 2.
       */
      const [_, afterDecimal] = increment?.split('.') || [];
      const trimZeroesRegExp = new RegExp(`(.+?)(?:\\.([\\d]{${afterDecimal?.length ?? 0},}?)0*)?$`);

      formattedFullNumberString = trimZeroesFunc(fullNumberString, trimZeroesRegExp);
      formattedNumberString = trimZeroesFunc(numberString, trimZeroesRegExp);
    }

    let parts;
    let fullParts: ReactNode[];

    if (highlightAll) {
      parts = [
        <Highlight
          highlightColor={highlightColor}
          variant={variant}
          key={1}
          largeHighlight={largeHighlight}
          theme={theme}
          data-testid="full-number-highlight"
        >
          {formattedNumberString}
        </Highlight>,
      ];
      fullParts = [
        <Highlight
          highlightColor={highlightColor}
          variant={variant}
          key={1}
          largeHighlight={largeHighlight}
          theme={theme}
          data-testid="full-number-highlight"
        >
          {formattedFullNumberString}
        </Highlight>,
      ];
    } else {
      parts = buildParts(formattedNumberString, specification, isSmall, largeHighlight, variant);
      fullParts = buildParts(formattedFullNumberString, specification, isSmall, largeHighlight, variant);
    }

    return round || formattedFullNumberString === formattedNumberString ? (
      <NumberWrapper background={background} estimate={estimate} data-testid="number-wrapper">
        {estimate && '~'}
        {startIcon && <Icon icon={startIcon} {...DEFAULT_ICON_STYLE} />}
        <Number isSmall={isSmall} variant={variant} theme={theme} data-testid="full-number">
          {prefix}
          {parts}
        </Number>
        {currency && (
          <Currency
            isSmall={isSmall}
            variant={variant}
            theme={theme}
            highlightColor={highlightColor}
            data-testid="full-number-currency"
          >
            {' '}
            {currency}
          </Currency>
        )}
      </NumberWrapper>
    ) : (
      <NumberWrapper estimate={estimate} data-testid="number-wrapper">
        <RoundedNumber>
          {estimate && '~'}
          {startIcon && <Icon icon={startIcon} {...DEFAULT_ICON_STYLE} />}
          <Number isSmall={isSmall} variant={variant} theme={theme} data-testid="rounded-number">
            {prefix}
            {parts}
          </Number>
          <Indicator variant={variant} theme={theme} data-testid="rounded-number-indicator">
            *
          </Indicator>
          {currency && (
            <Currency
              isSmall={isSmall}
              variant={variant}
              theme={theme}
              highlightColor={highlightColor}
              data-testid="rounded-number-currency"
            >
              {' '}
              {currency}
            </Currency>
          )}
        </RoundedNumber>
        <FullNumber background={background} align={align}>
          {estimate && '~'}
          {startIcon && <Icon icon={startIcon} {...DEFAULT_ICON_STYLE} />}
          <Number isSmall={isSmall} variant={variant} theme={theme} data-testid="full-number">
            {prefix}
            {fullParts}
          </Number>
          {currency && (
            <Currency
              isSmall={isSmall}
              variant={variant}
              theme={theme}
              highlightColor={highlightColor}
              data-testid="full-number-currency"
            >
              {' '}
              {currency}
            </Currency>
          )}
        </FullNumber>
      </NumberWrapper>
    );
  },
  (p, n) => isEqual(p, n)
);

function buildParts(
  numberString: string,
  specification: string,
  isSmall?: boolean,
  largeHighlight?: boolean,
  sentiment?: NumberVariants,
  theme?: DefaultTheme
) {
  const parts: (JSX.Element | string)[] = [];
  const hi = highlight({ number: numberString, specification: specification });
  const v = hi.value;
  const s = hi.highlight[0];
  const e = hi.highlight[1] ?? 0;
  if (s == null) {
    parts.push(v);
  } else {
    if (s > 0) {
      parts.push(v.slice(0, s));
    }
    parts.push(
      <Highlight
        isSmall={isSmall}
        variant={sentiment}
        key={1}
        largeHighlight={largeHighlight}
        theme={theme}
        data-testid="highlighted-number"
      >
        {v.slice(s, e + 1)}
      </Highlight>
    );
    if (e + 1 < v.length) {
      parts.push(v.slice(e + 1));
    }
  }
  return parts;
}
