import { isNumber, isString } from 'lodash';
import type { DefaultTheme } from 'styled-components';
import type { Leaves } from '../utils/types';

type ObjectMap<T> = Record<string, T>;
interface CssVars extends ObjectMap<string | number | CssVars> {}

export function writeCssVars({
  prefix = '',
  theme,
  blacklist = [],
}: {
  prefix?: string;
  theme: DefaultTheme;
  blacklist?: string[];
}) {
  const blacklistedKeys = new Set(blacklist);
  function recurse({ prefix, cssVars }: { prefix: string; cssVars: CssVars }): string[] {
    let results: string[] = [];
    for (const key in cssVars) {
      if (blacklistedKeys?.has(key)) {
        continue;
      }
      const item = cssVars[key];
      if (isString(item) || isNumber(item)) {
        results.push(`${prefix}-${key}: ${item};`);
        continue;
      }
      results = results.concat(...recurse({ prefix: `${prefix}-${key}`, cssVars: item }));
    }
    return results;
  }

  const result = recurse({
    prefix: prefix ? `--${prefix}` : `-`,
    cssVars: theme as unknown as CssVars,
  });

  return result.join('\n');
}

/**
 * Allows accessing theme variables as CSS-vars using dot notation.
 *
 * @param path Theme property in dot-notation (e.g. colors.gray.080)
 * @param fallback Default value to use if it's missing for whatever reason
 */
export function cssVar(path: Leaves<DefaultTheme>, fallback?: string): string {
  return `var(--${path.replace(/\./g, '-')}${fallback ? `, ${fallback}` : ''})`;
}
