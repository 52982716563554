import type { ReactNode } from 'react';
import type { CSSProperties } from 'styled-components';

import type { Currency } from '../../types';
import type { InputVariants } from '../Form';

export enum GridFormTypes {
  String,
  Currency,
  Number,
  Percent,
  Duration,
  Select,
  TextArea,
}

interface GridFormGroupBase<T> {
  label: ReactNode;
  field: keyof T;
  help?: ReactNode;
  tooltip?: ReactNode;
  prefix?: ReactNode;
  suffix?: ReactNode;
  gridColumn?: CSSProperties['gridColumn'];
  gridRow?: CSSProperties['gridRow'];
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  variant?: InputVariants;
}

interface GridFormSelectGroup<T> extends GridFormGroupBase<T> {
  type: GridFormTypes.Select;
  options?: GridFormGroupOption[];
}
interface GridFormCurrencyGroup<T> extends GridFormGroupBase<T> {
  type: GridFormTypes.Currency;
  options?: Currency[];
  showClear?: boolean;
}

interface GridFormDurationGroup<T> extends GridFormGroupBase<T> {
  type: GridFormTypes.Duration;
  minValue?: Date;
  maxValue?: Date;
  shortcuts?: { [key: string]: string };
}

interface GridFormTextAreaGroup<T> extends GridFormGroupBase<T> {
  type: GridFormTypes.TextArea;
  rows?: number;
}

interface GridFormNumbericGroup<T> extends GridFormGroupBase<T> {
  type: GridFormTypes.Percent | GridFormTypes.Number;
  min?: string;
  max?: string;
  minIncrement?: string;
  defaultIncrement?: string;
  largeIncrement?: string;
}

interface GridFormGroupOther<T> extends GridFormGroupBase<T> {
  type: Exclude<
    GridFormTypes,
    | GridFormTypes.Select
    | GridFormTypes.Currency
    | GridFormTypes.Duration
    | GridFormTypes.Number
    | GridFormTypes.Percent
    | GridFormTypes.TextArea
  >;
}

export type GridFormGroup<T> =
  | GridFormCurrencyGroup<T>
  | GridFormSelectGroup<T>
  | GridFormDurationGroup<T>
  | GridFormNumbericGroup<T>
  | GridFormTextAreaGroup<T>
  | GridFormGroupOther<T>;

export type GridFormGroupOption = {
  value: string;
  label: string;
  description: string;
};
