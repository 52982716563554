import { isNil } from 'lodash';
import type { RequiredProperties } from '../utils/types';
import type { Security } from './Security';
import { ProductTypeEnum } from './types';

export const isFutureSecurity = (security: Security): security is FutureSecurity =>
  security.ProductType === ProductTypeEnum.Future &&
  !isNil(security.SettleValueType) &&
  !isNil(security.Expiration) &&
  !isNil(security.Markets);

export type FutureSecurity = RequiredProperties<Security, 'Markets' | 'SettleValueType' | 'Expiration'>;
