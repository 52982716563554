import { transparentize } from 'polished';
import styled from 'styled-components';

export const Kbd = styled.kbd`
  display: inline-flex;
  background: ${({ theme }) => transparentize(0.92, theme.colors.gray['100'])};
  padding: calc(var(--spacingSmall) * 1px) calc(var(--spacingDefault) * 1px);
  font-family: 'Roboto', sans-serif;
  border-radius: calc(var(--borderRadiusSmall) * 1px);
`;
