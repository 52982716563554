import { immerable } from 'immer';

import { EMPTY_OBJECT } from '../utils';
import {
  BaseField,
  FieldValidationLevel,
  FieldValidationType,
  type FieldData,
  type FieldValidationResult,
  type FieldValidationRule,
} from './BaseField';
import { fieldsMessages } from './messages';

export interface SelectorFieldData<T> extends FieldData<T> {
  availableItems: T[];
  idProperty?: keyof T; // for Object types, semantic key (ID, Name, Symbol, etc)
}

export class SelectorField<T> extends BaseField<SelectorFieldData<T>, T> {
  constructor(initial?: Partial<SelectorFieldData<T>>) {
    super({
      name: 'SelectorField',
      value: undefined,
      isRequired: true,
      placeholder: 'Type here',
      isTouched: false,
      isDisabled: false,
      isVisible: true,
      errors: [],
      availableItems: [],
      ...initial,
    });
  }

  public get availableItems(): T[] {
    return this.data.availableItems;
  }

  public get hasAvailableItems(): boolean {
    return this.data.availableItems.length > 0;
  }

  public get value(): T | undefined {
    return this.data.value;
  }

  public override updateValue(selectedItem: T | undefined, isSystemOverride = false): SelectorField<T> {
    const updatedData = {
      value: selectedItem,
      isTouched: isSystemOverride ? false : true,
    };

    const updated = this.updateData(updatedData);
    return updated.invariantCheck();
  }

  public updateValueFromID<V>(idValue: V, isSystemOverride = false): SelectorField<T> {
    const value = this.data.availableItems.find(item => item[this.data.idProperty!] === idValue);

    const updatedData = {
      value,
      isTouched: isSystemOverride ? false : true,
    };

    const updated = this.updateData(updatedData);
    return updated.invariantCheck();
  }

  public setTouched(isTouched: boolean): SelectorField<T> {
    const updated = this.updateData({ isTouched });
    return updated.invariantCheck();
  }

  public setIsRequired(isRequired: boolean): SelectorField<T> {
    const updated = this.updateData({ isRequired });
    return updated.invariantCheck();
  }

  public setIsVisible(isVisible: boolean): SelectorField<T> {
    const updated = this.updateData({ isVisible });
    return updated.invariantCheck();
  }

  public validate<C>(rules: FieldValidationRule<SelectorField<T>, C, T>[] = [], context?: C): SelectorField<T> {
    const checked = this.invariantCheck();
    const errors = checked.data.errors.filter(e => e.type !== FieldValidationType.Rule);

    rules.forEach(rule => {
      const result = rule(this, context);
      if (result) {
        errors.unshift({ ...result, type: FieldValidationType.Rule });
      }
    });

    return this.updateData({ errors });
  }

  public override setDisabled(isDisabled: boolean): SelectorField<T> {
    return this.updateData({ isDisabled });
  }

  public updateAvailableItems(
    availableItems: T[],
    options?: {
      preventDefaultToValue?: boolean;
      keepIsTouched?: boolean;
    }
  ): SelectorField<T> {
    const { preventDefaultToValue = false, keepIsTouched = false } = options ?? EMPTY_OBJECT;

    const existingValue = this.value;

    let value = availableItems.find(item => {
      if (this.data.idProperty) {
        return item[this.data.idProperty] === existingValue?.[this.data.idProperty];
      }
      return item === existingValue;
    });

    // If the field is required and happens to only have 1 selectable item then just default it
    // If the field is not required do not do that since it might be valid to be blank
    // If preventDefaultToValue is false, do not fallback the value.
    if (!value && this.data.isRequired && availableItems.length === 1 && !preventDefaultToValue) {
      value = availableItems[0];
    }

    return this.updateData({
      availableItems,
      value,
      isTouched: keepIsTouched ? this.data.isTouched : value ? this.data.isTouched : false,
    });
  }

  private invariantCheck() {
    const errors: FieldValidationResult[] = this.data.errors.filter(e => e.type === FieldValidationType.Rule);

    if (this.data.isRequired && this.data.value == null) {
      errors.push({
        message: fieldsMessages.dataNameIsRequired,
        values: { dataName: this.data.name },
        level: FieldValidationLevel.Error,
      });
    }

    return this.updateData({ errors });
  }

  private updateData(data: Partial<SelectorFieldData<T>>): SelectorField<T> {
    const newData = {
      ...this.data,
      ...data,
    };
    return new SelectorField(newData);
  }
}

SelectorField[immerable] = true;
