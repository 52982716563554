export const Logo = ({ fill }) => (
  <>
    <rect fill={fill} x="59" y="87" width="28" height="82" />
    <rect fill={fill} x="169" y="87" width="28" height="82" />
    <path
      d="M87,197 C126.300459,197 127.083716,178.961009 137.5,142 C153,87 154.692417,87 169,87 C169,87 169,59 169,59 C139,59 131,66 116.5,119 C103.077791,168.060489 104,169 87,169 C87,169 87,197 87,197 Z"
      fill={fill}
    />
  </>
);
