import { hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { lightColors, mapLightTheme } from './LightTheme';

const colors: Colors = {
  black: {
    default: '#312546',
    dim: 'rgba(49, 37, 70, 0.4)',
    mute: 'rgba(49, 37, 70, 0.1)',
  },

  white: {
    default: '#ffffff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    main: hsla(210, 1.0, 1.0, 1),
    '000': hsla(210, 0.1, 0.92, 1),
    '010': setAlpha(0.1, '#988DA5'),
    '020': setAlpha(0.11, '#988DA5'),
    '030': setAlpha(0.14, '#988DA5'),
    '040': setAlpha(0.17, '#988DA5'),
    '050': setAlpha(0.21, '#988DA5'),
    '060': setAlpha(0.26, '#988DA5'),
    '070': setAlpha(0.31, '#988DA5'),
    '080': setAlpha(0.37, '#988DA5'),
    '090': '#988DA5',
    '100': '#312546',
  },

  primary: {
    mute: setAlpha(0.1, '#3A2B53'),
    dim: setAlpha(0.4, '#3A2B53'),
    default: '#3A2B53',
    lighten: '#453561',
  },

  green: {
    mute: setAlpha(0.1, '#0AA049'),
    dim: setAlpha(0.4, '#0AA049'),
    default: '#0AA049',
    lighten: '#27B663',
  },

  red: {
    mute: setAlpha(0.1, '#8D483C'),
    dim: setAlpha(0.4, '#8D483C'),
    default: '#8D483C',
    lighten: '#CA5A47',
  },

  yellow: {
    mute: setAlpha(0.1, '#D6C043'),
    dim: setAlpha(0.4, '#D6C043'),
    default: '#D6C043',
    lighten: '#FFE646',
  },

  blue: {
    mute: setAlpha(0.1, '#43AAD6'),
    dim: setAlpha(0.4, '#43AAD6'),
    default: '#43AAD6',
    lighten: '#46C7FF',
  },

  orange: {
    mute: setAlpha(0.1, '#D24500'),
    dim: setAlpha(0.4, '#D24500'),
    default: '#D24500',
    lighten: '#FF6900',
  },

  purple: lightColors.purple,
};

export const LightRainTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightRN',

  borderRadiusCard: 5,
  borderRadiusContent: 5,
  borderRadiusLayoutWrapper: 5,

  /*

  backgroundBody: colors.white.default,
  backgroundContent: colors.white.default,
  boxShadowContent: colors.gray['000'],
  borderColorContent: colors.gray['000'],
  backgroundLayoutWrapper: colors.gray['000'],

  backgroundHeader: colors.white.default,

  backgroundSidebar: colors.white.default,
  colorTextSidebarItemActive: colors.white.default,
  backgroundSidebarItemHover: colors.black.mute,

  backgroundChart: colors.white.default,
  backgroundBlotterTableRow: colors.white.default,

  colorCurrencyIcon: colors.white.default,
  colorModeCurrencyIcon: 'monochrome',

  backgroundDropdownResults: colors.white.default,
  backgroundDropdownGroupHeader: colors.white.default,
  boxShadowDropdown: '0px 8px 32px rgba(0, 0, 0, 0.2)',

  backgroundModal: colors.white.default,

  backgroundInput: colors.black.mute,
   */
});
