import {
  BlotterTable,
  BlotterTableFilters,
  Button,
  FormControlSizes,
  FormattedMessage,
  MixpanelEvent,
  createCSVFileName,
  useMixpanel,
} from '@talos/kyoko';
import { useCallback } from 'react';
import { defineMessages } from 'react-intl';
import type { TabbedBlotterProps } from '../types';
import { useFundingEventsBlotter } from './hooks/useFundingEventsBlotter';

const messages = defineMessages({
  exportEvents: {
    defaultMessage: 'Export Events',
    id: 'Blotters.FundingEventsBlotter.exportEvents',
  },
});

export type FundingEventsBlotterProps = Required<Pick<TabbedBlotterProps, 'renderInBackground'>>;

export function FundingEventsBlotter({ renderInBackground: hidden }: FundingEventsBlotterProps) {
  const mixpanel = useMixpanel();

  const { blotterTable, blotterTableFilterProps, filterBuilderAccordion } = useFundingEventsBlotter();

  const handleExportFundingEvents = useCallback(() => {
    blotterTable.exportDataAsCSV({
      includeHiddenColumns: true,
      fileName: createCSVFileName({
        name: 'FundingEvents',
      }),
    });
    mixpanel.track(MixpanelEvent.ExportFundingEvents);
  }, [blotterTable, mixpanel]);

  return (
    <>
      {!hidden && (
        <BlotterTableFilters
          {...filterBuilderAccordion}
          {...blotterTableFilterProps}
          {...blotterTable.blotterTableFiltersProps}
          showFilterBuilder={true}
          prefix={
            <Button size={FormControlSizes.Small} onClick={handleExportFundingEvents}>
              <FormattedMessage {...messages.exportEvents} />
            </Button>
          }
        />
      )}
      <BlotterTable {...blotterTable!} />
    </>
  );
}
