import { get, isString } from 'lodash';
import type { CSSProperties, DefaultTheme } from 'styled-components';
import type { Leaves } from '../../utils/types';

/**
 * Use dimension from theme if it exists, otherwise assume it's a valid CSS size.
 * @param theme Current styled-components theme
 * @param dimensionToken Spacing constant or CSS size
 */
export function parseDimension(theme: DefaultTheme, dimensionToken: string | number) {
  if (typeof dimensionToken === 'number') {
    return `${dimensionToken}px`;
  }
  let sign = '';
  let parsedDimension = dimensionToken;
  if (isString(dimensionToken) && dimensionToken.charAt(0) === '-') {
    sign = '-';
    parsedDimension = dimensionToken.slice(1, dimensionToken.length);
  }

  const parsedDimensionValue = getThemeDimensionValue(theme, parsedDimension);
  return parsedDimensionValue ? `${sign}${parsedDimensionValue}px` : dimensionToken;
}

/**
 * Use color from theme if it exists, otherwise assume it's a valid CSS color.
 * @param theme Current styled-components theme
 * @param color Color variable or CSS color
 */
export function parseColor(
  theme: DefaultTheme,
  color: Leaves<DefaultTheme['colors']> | string | undefined
): string | undefined {
  const parsedColor = color ? get(theme, `colors.${color}`) ?? get(theme, color) : color;
  if (!import.meta.env.PROD && parsedColor != null && typeof parsedColor !== 'string') {
    throw new Error(`Invalid color: ${color} in theme is of unexpected type ${typeof parsedColor}`);
  }
  return parsedColor ?? color;
}

/**
 * Leverages `parseColor` to return a different color depending on whether the current theme is light or dark.
 * @param theme Current styled-components theme
 * @param lightColor Color variable or CSS color to use for light themes
 * @param darkColor Color variable or CSS color to use for dark themes
 */
export function modeColor(
  theme: DefaultTheme,
  lightColor: Leaves<DefaultTheme['colors']> | string,
  darkColor: Leaves<DefaultTheme['colors']> | string
): string | undefined {
  return parseColor(theme, theme.type === 'light' ? lightColor : darkColor);
}

/**
 * Use font-size from theme if it exists, otherwise assume it's a valid CSS rem size.
 * @param theme Current styled-components theme
 * @param size Size constant or CSS size
 */
export function parseFontSize(theme: DefaultTheme, size: string | number): CSSProperties['fontSize'] {
  if (typeof size === 'number') {
    return `${size}rem`;
  }
  const sizeValue = getThemeDimensionValue(theme, size);
  return sizeValue ? `${sizeValue}rem` : size;
}

/**
 * Use font-size from theme if it exists, otherwise assume it's a valid CSS pixel size.
 * @param theme Current styled-components theme
 * @param size Size constant or CSS size
 */
export function parseIconSize(theme: DefaultTheme, size: string | number): CSSProperties['fontSize'] {
  if (typeof size === 'number') {
    return `${size}px`;
  }
  const sizeValue = getThemeDimensionValue(theme, size);
  return sizeValue ? `${sizeValue}rem` : size;
}

/**
 * Returns the input styles, but mapped to select theme variables.
 * Essentially a utility to help you apply your input theming to your select theming in one line
 * @param theme the theme you want to take the input styling from to be applied to your selects
 */
export function getInputStylesAppliedToSelect(theme: DefaultTheme) {
  return {
    backgroundSelect: theme.backgroundInput,
    backgroundSelectHover: theme.backgroundInput,
    backgroundSelectFocus: theme.backgroundInput,
    backgroundSelectInvalid: theme.backgroundInputInvalid,
    backgroundSelectDisabled: theme.backgroundInputDisabled,
    backgroundSelectReadOnly: theme.backgroundInputReadOnly,

    borderColorSelect: theme.borderColorInput,
    borderColorSelectHover: theme.borderColorInputHover,
    borderColorSelectFocus: theme.borderColorInputFocus,
    borderColorSelectInvalid: theme.borderColorInputInvalid,
    borderColorSelectDisabled: theme.borderColorInputDisabled,
    borderColorSelectReadOnly: theme.borderColorInputReadOnly,
  };
}

/** Validate and return the parsed dimension value from the theme */
export function getThemeDimensionValue(theme: DefaultTheme, dimensionToken: string) {
  const parsedDimensionValue = (theme as any)[dimensionToken];
  if (
    !import.meta.env.PROD &&
    parsedDimensionValue != null &&
    typeof parsedDimensionValue !== 'string' &&
    typeof parsedDimensionValue !== 'number'
  ) {
    throw new Error(
      `Invalid dimension: ${dimensionToken} in theme is of unexpected type ${typeof parsedDimensionValue}`
    );
  }
  return parsedDimensionValue;
}
