import { Box } from '@talos/kyoko';
import styled from 'styled-components';

export const RestrictedIconWrapper = styled(Box)`
  border: ${({ theme }) => `1px solid ${theme.colors.gray['050']}`};
  border-radius: 50%;
`;

RestrictedIconWrapper.defaultProps = {
  h: '48px',
  w: '48px',
  background: `colors.gray['030']`,
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
};
