import { getDatesEveryXMinutes } from './utils';

/**
 * Returns whether or not the given date is valid given the minutesInterval
 * @param date
 * @param minutesInterval
 * @returns a boolean where true indicates valid
 */
export const useTimeSelectorValidation = (date: Date | null, minutesInterval: number): boolean => {
  const datesAtInterval = getDatesEveryXMinutes(minutesInterval);

  if (!date) {
    return false;
  }

  // Does the provided date match any of the computed date's time exactly?
  return datesAtInterval.some(
    okDate =>
      okDate.getHours() === date.getHours() &&
      okDate.getMinutes() === date.getMinutes() &&
      okDate.getSeconds() === date.getSeconds() &&
      okDate.getMilliseconds() === date.getMilliseconds()
  );
};
