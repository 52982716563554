import type {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community';
import type { AgGridContext } from 'ag-grid-community/dist/types/core/interfaces/iCommon';
import type { CustomCellEditorProps } from 'ag-grid-react';
import { get, set } from 'lodash';
import { defineMessages } from 'react-intl';
import { SubAccountTypeEnum, type ISubaccount } from '../../../types/types';
import type { AgGridSearchSelectDropdownProps } from '../../AgGrid/types';
import { HStack, VStack } from '../../Core';
import { Text } from '../../Text';
import { TreeViewTooltip, type Tree } from '../../TreeView';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, ColDefIntl, Column } from './types';
import { getStringFormattedColumnComparator } from './utils';

const messages = defineMessages({
  allSubAccounts: {
    defaultMessage: 'All Sub Accounts',
    id: 'BlotterTable.columns.allSubAccounts',
  },
  wildcard: {
    defaultMessage: 'Wildcard',
    id: 'BlotterTable.columns.wildcard',
  },
});

type SubAccountNameColumnValue = string | undefined;

export interface SubAccountNameColumnParams {
  wildcard?: boolean;
  comparator?: ColDef['comparator'];
  subAccountOptionsOverride?: ISubaccount[];
  initialSelectedItem?: ISubaccount;
  /** Description getter for the cell renderer */
  getCellDescription?: (sa: ISubaccount | undefined) => string;
  /** Group getter for the search select dropdown */
  getDropdownGroup?: (sa: ISubaccount | undefined) => string;
  /** Whether or not to show a description in the cell _renderer_. The default description is the type of the sub account. */
  showDescription?: boolean;
  /** Rollup tree. If provided, will show this tree in an icon next to rollups. */
  rollupTree?: React.MutableRefObject<Tree<ISubaccount>>;
}

function defaultGetDropdownGroup(sa: ISubaccount | undefined, intl: ColDefIntl) {
  return sa === undefined ? intl.formatMessage(messages.wildcard) : sa.Type;
}

function defaultGetDropdownDescription(sa: ISubaccount | undefined, intl: ColDefIntl) {
  return sa === undefined ? intl.formatMessage(messages.allSubAccounts) : sa.Type;
}

function defaultGetCellDescription(sa: ISubaccount | undefined, intl: ColDefIntl) {
  return sa === undefined ? intl.formatMessage(messages.allSubAccounts) : sa.Type;
}

/** Type used in the subAccountName column factory for showing options in the dropdown for editing */
export interface SubAccountAutocompleteItem {
  label: string;
  value: string | undefined;
  subAccount: ISubaccount | undefined;
}
/**
 * Helper function for getting the name of the sub account, allows for both ID and Name to be passed in.
 */
function getSubAccountName(
  value: ISubaccount['Name'] | ISubaccount['SubaccountID'] | undefined,
  context: AgGridContext
): SubAccountNameColumnValue {
  if (value == null) {
    return undefined;
  }
  return typeof value === 'string' ? value : context.current.subAccountsByID?.get(value)?.Name;
}

export const subAccountName: ColDefFactory<Column<SubAccountNameColumnParams>> = column => ({
  ...baseColumn(column),
  valueGetter: ({ data, context }: ValueGetterParams<unknown>): SubAccountNameColumnValue =>
    getSubAccountName(get(data, column.field ?? 'SubAccount'), context),
  valueFormatter: ({ value, context }: ValueFormatterParams<unknown, SubAccountNameColumnValue>): string => {
    if (value == null) {
      return column.params?.wildcard ? '*' : '';
    }

    const subAccount = context.current.subAccountsByName?.get(value);
    return subAccount?.DisplayName ?? subAccount?.Name ?? value.toString();
  },
  cellRenderer: ({ valueFormatted, value, context }: ICellRendererParams<unknown, SubAccountNameColumnValue>) => {
    const subAccount = value ? context.current.subAccountsByName?.get(value) : undefined;
    const getCellDescription = column.params?.getCellDescription ?? defaultGetCellDescription;

    const rollupTree = column.params?.rollupTree?.current;
    const renderTree = subAccount != null && subAccount.Type === SubAccountTypeEnum.Rollup && rollupTree != null;

    return (
      <HStack gap="spacingDefault" alignItems="center">
        <VStack gap="spacingSmall" alignItems="flex-start">
          <Text>{subAccount?.DisplayName}</Text>
          {column.params?.showDescription && (
            <Text color="colorTextSubtle" fontSize="fontSizeTiny">
              {getCellDescription(subAccount, context.current.intl)}
            </Text>
          )}
        </VStack>
        {renderTree && (
          <TreeViewTooltip tree={rollupTree} subAccount={subAccount} highlight={[subAccount.SubaccountID.toString()]} />
        )}
      </HStack>
    );
  },
  cellEditor: 'searchSelectDropdown',
  cellEditorPopup: true,
  cellEditorParams: (
    params: CustomCellEditorProps<unknown, SubAccountNameColumnValue>
  ): AgGridSearchSelectDropdownProps<SubAccountAutocompleteItem> => {
    const selectableSubAccounts =
      column.params?.subAccountOptionsOverride ?? params.context.current.allSubAccounts ?? [];

    const items: SubAccountAutocompleteItem[] = selectableSubAccounts.map(subAccountToAutocompleteItem);

    if (column.params?.wildcard) {
      items.unshift({
        label: '*',
        value: undefined,
        subAccount: undefined,
      });
    }

    const getDescription = column.params?.getCellDescription ?? defaultGetDropdownDescription;
    const getGroup = column.params?.getDropdownGroup ?? defaultGetDropdownGroup;

    return {
      ...params,
      useSearchSelectParams: {
        items,
        getLabel: (sa: SubAccountAutocompleteItem) => sa.label,
        getDescription: item => getDescription(item.subAccount, params.context.current.intl),
        getGroup: item => getGroup(item.subAccount, params.context.current.intl),
        initialSelectedItem: column.params?.initialSelectedItem
          ? subAccountToAutocompleteItem(column.params.initialSelectedItem)
          : undefined,
      },
      useDropdownParams: {
        dropdownWidth: '300px',
      },
      maxHeight: 400,
    };
  },
  suppressKeyboardEvent: () => true,
  valueSetter: (params: ValueSetterParams<unknown>) => {
    if (params.colDef.field == null || params.data == null) {
      // Not OK -- we need to know what field to set the new value on
      return false;
    }

    const newValue: SubAccountAutocompleteItem | undefined = params.newValue;
    set(params.data, params.colDef.field, newValue?.value);
    return true;
  },
  comparator: getStringFormattedColumnComparator(column),
});

function subAccountToAutocompleteItem(subAccount: ISubaccount): SubAccountAutocompleteItem {
  return {
    label: subAccount.DisplayName ?? subAccount.Name,
    value: subAccount.Name,
    subAccount,
  };
}
