import { useStrategiesContext } from '../../contexts';
import { OrdTypeEnum } from '../../types';
import { Text } from '../Text';
import type { SmartSummaryEntity } from './types';

export function StrategyPart({ entity }: { entity: SmartSummaryEntity }) {
  const { strategiesByName } = useStrategiesContext();

  let displayStrategy;
  if (entity.Strategy) {
    displayStrategy = strategiesByName.get(entity.Strategy)?.DisplayName ?? entity.Strategy;
  } else if (entity.RFQID != null) {
    displayStrategy = 'RFQ';
  } else if (entity.OrdType === OrdTypeEnum.Market) {
    displayStrategy = 'Market';
  } else if (entity.Strategy == null) {
    displayStrategy = 'Limit';
  }

  return <Text color="colorTextImportant">{displayStrategy}</Text>;
}
