import styled, { css } from 'styled-components';

import type { ReactNode } from 'react';
import { Label } from '../styles';
import { FormControlSizes } from '../types';
import { getFontSize } from '../utils';

export type ToggleWrapperProps = {
  size?: FormControlSizes;
  label?: ReactNode;
  checked: boolean;
  disabled?: boolean;
};

export const ToggleSwitchWrapper = styled.div`
  width: 24px;
  background: ${({ theme }) => theme.backgroundToggle};
  border-radius: 999em;
  padding: 0 2px;
  transition: background 150ms, opacity 200ms;
`;

export const ToggleSwitch = styled.div`
  margin: 1px auto;
  background: ${({ theme }) => theme.backgroundToggleSwitch};
  border-radius: 100%;
  transition: transform 300ms ease, background 150ms;
`;

export const ToggleWrapper = styled.div<ToggleWrapperProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${ToggleSwitchWrapper} {
    width: ${({ theme, size = FormControlSizes.Default }) => theme.baseSize * size}px;
  }

  ${ToggleSwitch} {
    height: ${({ theme, size = FormControlSizes.Default }) => (theme.baseSize * size) / 2}px;
    width: ${({ theme, size = FormControlSizes.Default }) => (theme.baseSize * size) / 2}px;
    transform: translateX(-${({ theme, size = FormControlSizes.Default }) => (theme.baseSize * size) / 4 - 1}px);
  }

  ${Label} {
    white-space: nowrap;
    cursor: pointer;
    font-size: ${({ theme, size }) => getFontSize(theme, size)}rem;
    color: ${({ checked, theme }) => (checked ? theme.colorTextAttention : theme.colorTextDefault)};
    &:first-child {
      margin-right: ${({ theme }) => theme.spacingSmall}px;
    }
    transition: opacity 200ms;
  }

  ${({ checked, theme, size = FormControlSizes.Default }) =>
    checked &&
    `
      ${ToggleSwitch} {
        transform: translateX(${(theme.baseSize * size) / 4 - 1}px);
        background: ${theme.backgroundToggleSwitchChecked};
      }

      ${ToggleSwitchWrapper} {
        background: ${theme.backgroundToggleChecked};
      }
  `};

  ${({ disabled }) =>
    disabled
      ? css`
          ${ToggleSwitchWrapper}, ${Label} {
            opacity: 0.35;
          }
          &:hover {
            cursor: not-allowed;
          }
        `
      : css`
          &:hover {
            cursor: pointer;
          }
        `}
`;
