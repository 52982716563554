import { hsl, hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { lightColors, mapLightTheme } from './LightTheme';

const colors: Colors = {
  gray: {
    main: hsla(210, 0, 1.0, 1),
    '000': hsla(204, 0, 0.92, 1),
    '010': hsla(204, 0, 0.96, 1),
    '020': hsla(204, 0, 0.95, 1),
    '030': hsla(209, 0, 0.93, 1),
    '040': hsla(209, 0, 0.91, 1),
    '050': hsla(209, 0, 0.88, 1),
    '060': hsla(210, 0, 0.74, 1),
    '070': hsla(209, 0, 0.69, 1),
    '080': hsl(209, 0, 0.45),
    '090': hsl(209, 0, 0.25),
    '100': hsl(211, 0, 0.08),
  },

  black: {
    default: '#000000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#FFFFFF',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.1)',
  },

  primary: {
    mute: setAlpha(0.1, '#5186F6'),
    dim: setAlpha(0.4, '#5186F6'),
    default: '#4373d9',
    lighten: '#5186F6',
  },

  green: {
    mute: setAlpha(0.1, '#3ADCCA'),
    dim: setAlpha(0.4, '#3ADCCA'),
    default: '#2ab5a6',
    lighten: '#3adcca',
  },

  red: {
    mute: setAlpha(0.1, '#dc3a4c'),
    dim: setAlpha(0.4, '#dc3a4c'),
    default: '#b52a39',
    lighten: '#dc3a4c',
  },

  yellow: {
    default: '#CCAC3E',
    lighten: '#F2D264',
    dim: 'rgba(204, 172, 62, 0.4)',
    mute: 'rgba(204, 172, 62, 0.1)',
  },

  blue: {
    default: '#458CC1',
    lighten: '#53AFF8',
    dim: 'rgba(69, 140, 193, 0.4)',
    mute: 'rgba(69, 140, 193, 0.1)',
  },

  orange: {
    default: '#E65200',
    lighten: '#FF6B00',
    dim: 'rgba(230, 82, 0, 0.4)',
    mute: 'rgba(230, 82, 0, 0.1)',
  },

  purple: lightColors.purple,
};

export const LightAnchorageTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightAD',

  fontFamily: "'Akkurat Pro', sans-serif",
  fontFace: `@font-face {
    font-family: 'Akkurat Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/customers/AkkuratPro-Regular.woff2) format('woff2');
  }`,
  borderRadiusDefault: 5,
  borderRadiusSmall: 3,
  borderRadiusTiny: 3,

  backgroundInput: 'transparent',

  colorTextVolumeLadderSpread: colors.gray['090'],
});
