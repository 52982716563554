import { Box, FormSection, OrderFormSides } from '@talos/kyoko';
import styled from 'styled-components';

const HEADER_HEIGHT = 4;

export const OrderFormsWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  overflow: hidden;
  transition: width 300ms ease;
`;

export const OrderFormHeader = styled(FormSection)`
  height: ${({ theme }) => theme.baseSize * HEADER_HEIGHT}px;
  border-bottom: solid 1px ${({ theme }) => theme.borderColorDivider};
  display: flex;

  h2 {
    font-weight: ${({ theme }) => theme.fontWeightRegular};
    text-align: center;
    margin: ${({ theme }) => theme.spacingTiny}px 0;
    font-size: ${({ theme }) => theme.fontSizeLarge}rem;
    text-transform: uppercase;
  }
`;

export const ModifyOrderID = styled.div`
  font-size: ${({ theme }) => theme.fontSizeTiny}rem;
`;

export const ModifyWrapper = styled.div<{ side?: OrderFormSides | null }>`
  text-align: right;
  margin-left: auto;
  color: ${({ theme, side }) =>
    side === OrderFormSides.Buy
      ? theme.colorTextPositive
      : side === OrderFormSides.Sell
      ? theme.colorTextNegative
      : theme.colorTextPrimary};

  ${ModifyOrderID} {
    color: ${({ theme, side }) =>
      side === OrderFormSides.Buy
        ? theme.colorTextPositiveSubtle
        : side === OrderFormSides.Sell
        ? theme.colorTextNegativeSubtle
        : theme.colorTextPrimary};
  }
`;

export const SymbolWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const OVERLAY_Z_INDEX = 10;

export const Overlay = styled.div`
  background: ${({ theme }) => theme.backgroundOverlay};
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: ${OVERLAY_Z_INDEX};
  padding-bottom: 200px;
`;

export const FeedbackMessage = styled.div`
  margin: ${({ theme }) => theme.spacingMedium}px;
  text-align: center;
`;

export const MessageWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: height 200ms ease;
`;

export const QuoteSpread = styled.div`
  padding: ${({ theme }) => theme.spacingTiny}px;
  line-height: 1;
  background: ${({ theme }) => theme.backgroundContent};
  color: ${({ theme }) => theme.colorTextAttention};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  min-width: 25px;
  text-align: center;
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
`;

export const OmsToasts = styled.div`
  bottom: 45px;
  position: absolute;
  padding: ${({ theme }) => `0 ${theme.spacingMedium}px ${theme.spacingMedium}px`};
  width: 100%;
  z-index: 11;
`;
