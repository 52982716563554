import type { BigSource } from 'big.js';
import type { ReactNode } from 'react';
import type { DefaultTheme } from 'styled-components';
import type { IconName } from '../Icons';

export enum NumberVariants {
  Positive = 'positive',
  Negative = 'negative',
  Warning = 'warning',
  Primary = 'primary',
  Muted = 'muted',
}

export type InlineFormattedNumberProps = {
  number?: BigSource;
  increment?: string;
  specification?: string;
  isSmall?: boolean;
  startIcon?: IconName;
  largeHighlight?: boolean;
  highlightAll?: boolean;
  currency?: string | null;
  background?: string;
  align?: 'left' | 'right' | 'center';
  variant?: NumberVariants;
  truncate?: boolean;
  round?: boolean;
  pretty?: boolean;
  estimate?: boolean;
  theme?: DefaultTheme;
  highlightColor?: string;
  showSign?: boolean;
  prefix?: ReactNode;
  /** When SmartTruncateEnum.Compact is used, decimals after 5dps will be truncated with "*"" */
  smartTruncate?: SmartTruncateEnum;
  smartTruncateThreshold?: BigSource;
  /** Whether or not to trim any trailing zeroes */
  trimTrailingZeroes?: boolean;
};

export enum SmartTruncateEnum {
  None = 'None',
  Compact = 'Compact',
}
