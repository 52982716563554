import styled from 'styled-components';

import {
  Box,
  CARD_HEIGHT,
  CARD_WIDTH,
  COMPACT_CARD_HEIGHT,
  Flex,
  Icon,
  MarketDataCardWrapper as MarketDataCardWrapperComponent,
  setAlpha,
} from '@talos/kyoko';

export const AddButton = styled(Icon)`
  transition: 200ms;
`;

export const AddCard = styled(Flex)`
  align-items: center;
  background-color: ${({ theme }) => setAlpha(0.04, theme.colors.gray['100'])};
  height: 100%;
  justify-content: center;
  border: 1px solid ${({ theme }) => theme.borderColorContent};
  backdrop-filter: ${({ theme }) => theme.backdropFilterContent};
  box-shadow: ${({ theme }) => theme.boxShadowContent};
  border-radius: ${({ theme }) => theme.borderRadiusContent}px;

  ${AddButton} {
    color: ${({ theme }) => theme.colors.gray['070']};
  }

  &:hover {
    background-color: ${({ theme }) => setAlpha(0.08, theme.colors.gray['100'])};

    ${AddButton} {
      color: ${({ theme }) => theme.colorTextAttention};
    }
  }

  &:active {
    ${AddButton} {
      color: ${({ theme }) => theme.colors.gray['080']};
    }
  }
`;

export const CardWrapper = styled(Box)<{ isDragged: boolean }>`
  ${({ isDragged }) => isDragged && `z-index: 1;`}
`;

export const MarketDataCardWrapper = styled(MarketDataCardWrapperComponent)<{
  expanded: boolean;
}>`
  cursor: pointer;
  min-height: ${({ expanded }) => (expanded ? CARD_HEIGHT : COMPACT_CARD_HEIGHT)}px;
  width: auto;
  border-radius: ${({ theme }) => theme.borderRadiusContent}px;
  overflow: hidden;

  &:hover {
    border-color: ${({ theme }) => theme.colorTextAttention};
  }
`;

export const Cards = styled.div`
  display: grid;
  flex: 1 1 0;
  gap: ${({ theme }) => theme.spacingLayout}px;
  grid-auto-flow: initial;
  grid-auto-rows: min-content;
  grid-template-columns: repeat(auto-fill, minmax(${CARD_WIDTH}px, 1fr));
  height: 100%;
  overflow: auto;
  padding: 0;
  width: 100%;

  &::-webkit-scrollbar-corner {
    background: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  // This was added to get around an issue where the bottom scrollbar of this div was taking precedence over popovers etc on hover
  z-index: 1;
`;
