import { isNil } from 'lodash';

export async function copyText(text?: string) {
  if (isNil(text)) {
    return;
  }

  try {
    await navigator.clipboard.writeText(text);
  } catch (ex) {
    console.error(ex);

    if (import.meta.env.DEV) {
      // In dev mode, we're not in a secure context, so can't use navigator.clipboard
      const textField = document.createElement('textarea');
      textField.innerHTML = text;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand('copy');
      textField.remove();
    }
  }
}
