import { compact } from 'lodash';

import { defineMessages, useIntl } from 'react-intl';
import { useSecurity } from '../../hooks';
import { Button, ButtonVariants, IconButton } from '../Button';
import { HStack } from '../Core';
import { FormControlSizes } from '../Form';
import { IconName } from '../Icons';
import { FormattedMessage } from '../Intl';
import { SecurityRenderer } from '../SecurityRenderer';
import { SymbolSelector, SymbolSelectorAppearance } from '../SymbolSelector';
import { Tooltip } from '../Tooltip';
import { VolumeHeaderRow } from './styles';

export const messages = defineMessages({
  saveSettings: {
    defaultMessage: 'Save Settings',
    id: 'VolumeLadder.saveSettings',
  },
  // client specific disclaimer override to be specified in the WL config json if necessary in conjunction with turning on the showDisclaimer flag.
  disclaimer: {
    defaultMessage: 'Disclaimer',
    id: 'VolumeLadder.disclaimer',
  },
});

export const VolumeLadderHeader = ({
  symbol,
  onSymbolChange,
  onSettingsClick,
  onSaveSettings,
  showSettings,
  errors,
  disabledSymbolSelector,
  isCompact,
  isNarrow,
  disableCustomLadderSizeBuckets,
  showDisclaimer,
}: VolumeLadderHeaderProps) => {
  const security = useSecurity(symbol);
  const hasErrors = compact(errors).length > 0;
  const { formatMessage } = useIntl();

  return (
    <VolumeHeaderRow
      p={isCompact ? 'spacingSmall' : undefined}
      h={isCompact ? '42px' : undefined}
      position="relative"
      justifyContent={isCompact ? 'center' : undefined}
      data-testid="volume-ladder-header"
    >
      {!showSettings && (
        <>
          {disabledSymbolSelector ? (
            symbol ? (
              <SecurityRenderer
                symbol={symbol}
                security={security}
                showDescription={!isNarrow && !isCompact}
                iconSize={!isNarrow && !isCompact ? 24 : 16}
              />
            ) : null
          ) : (
            <SymbolSelector
              appearance={SymbolSelectorAppearance.Ghost}
              showSelectedSymbolDescription={!isCompact && !isNarrow}
              symbol={symbol}
              onSymbolChanged={onSymbolChange}
              dropdownWidth="200px"
              disabled={disabledSymbolSelector}
            />
          )}
        </>
      )}

      <HStack
        gap={isCompact ? 'spacingSmall' : 'spacingMedium'}
        position="absolute"
        right={isCompact ? 'spacingSmall' : 'spacingMedium'}
      >
        {showSettings && (
          <Button
            onClick={onSaveSettings}
            disabled={hasErrors}
            variant={ButtonVariants.Primary}
            size={isCompact ? FormControlSizes.Small : FormControlSizes.Default}
          >
            <FormattedMessage {...messages.saveSettings} />
          </Button>
        )}
        {showDisclaimer && (
          <Tooltip tooltip={formatMessage(messages.disclaimer)}>
            <IconButton
              icon={IconName.ExclamationCircleSolid}
              variant={ButtonVariants.Default}
              ghost={true}
              size={FormControlSizes.Small}
              data-testid="disclaimer-icon"
            />
          </Tooltip>
        )}

        {!disableCustomLadderSizeBuckets && (
          <IconButton
            onClick={onSettingsClick}
            icon={showSettings ? IconName.Close : IconName.Cog}
            variant={ButtonVariants.Default}
            ghost={!showSettings}
            size={isCompact ? FormControlSizes.Small : FormControlSizes.Default}
            data-testid="volume-ladder-settings-button"
          />
        )}
      </HStack>
    </VolumeHeaderRow>
  );
};

type VolumeLadderHeaderProps = {
  symbol?: string;
  showSettings: boolean;
  errors: boolean[];
  disabledSymbolSelector?: boolean;
  isCompact?: boolean;
  isNarrow?: boolean;
  onSaveSettings(): void;
  onSettingsClick(): void;
  onSymbolChange(symbol?: string): void;
  disableCustomLadderSizeBuckets?: boolean;
  showDisclaimer?: boolean;
};
