import Big from 'big.js';
import { memo, useCallback, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Button, ButtonVariants } from '../Button';
import { Box } from '../Core';
import { FormControlSizes, NumberInput } from '../Form';
import { RadioButton } from '../Form/RadioButton';
import { FormattedMessage } from '../Intl';
import { Popover, usePopoverState } from '../Popover';
import { Buttons, Content, Wrapper } from './styles';

export { SlippageWrapper } from './styles';

const messages = defineMessages({
  slippage: {
    defaultMessage: 'Slippage',
    id: 'Slippage.slippage',
  },
  custom: {
    defaultMessage: 'Custom',
    id: 'Slippage.custom',
  },
  apply: {
    defaultMessage: 'Apply',
    id: 'Slippage.apply',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: 'Slippage.cancel',
  },
  noBps: {
    defaultMessage: 'None',
    id: 'Slippage.noBps',
  },
  bps: {
    defaultMessage: '{bps} bps',
    id: 'Slippage.bps',
  },
});

export const MIN_SLIPPAGE = '0';
export const MAX_SLIPPAGE = '1000';

export interface Slippage {
  ID: string;
  value: string | null;
}

export const DEFAULT_SLIPPAGES: Slippage[] = [
  {
    ID: '0',
    value: null,
  },
  {
    ID: '1',
    value: '1',
  },
  {
    ID: '2',
    value: '2',
  },
  {
    ID: '5',
    value: '5',
  },
];

export const CUSTOM_SLIPPAGE: Slippage = {
  ID: 'custom',
  value: null,
};

export interface SlippageControlProps {
  slippage: Slippage;
  onApply: (slippage: Slippage) => any;
}

export const SlippageControl = memo<SlippageControlProps>(({ slippage, onApply, ...props }) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedSlippage, setSelectedSlippage] = useState<Slippage>(slippage);
  const [isValid, setIsValid] = useState(false);

  const popover = usePopoverState({
    placement: 'top',
    closeOnClickOutside: true,
    usePortal: true,
    isSmall: true,
    delay: undefined,
  });

  const close = popover.close;
  const slippageLabelGetter = useSlippageLabelGetter();

  const handleCancel = useCallback(() => {
    setSelectedSlippage(slippage);
    close();
  }, [slippage, close]);

  const handleApply = useCallback(() => {
    if (selectedSlippage.ID === CUSTOM_SLIPPAGE.ID) {
      onApply({
        ...CUSTOM_SLIPPAGE,
        value: inputValue.toString(),
      });
    } else {
      onApply(selectedSlippage as Slippage);
    }
    close();
  }, [inputValue, selectedSlippage, onApply, close]);

  useEffect(() => {
    const isCustom = selectedSlippage.ID === CUSTOM_SLIPPAGE.ID;
    const didChange = selectedSlippage.ID !== slippage.ID;
    const isInputValid = inputValue !== '' && Big(inputValue).lt(MAX_SLIPPAGE) && Big(inputValue).gt(MIN_SLIPPAGE);

    if (isCustom && isInputValid) {
      setIsValid(true);
    } else if (!isCustom && didChange) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [slippage, selectedSlippage, inputValue]);

  return (
    <Popover {...popover} {...props}>
      <Wrapper active={popover.isOpen} data-testid="allowed-slippage-button">
        <label>
          <FormattedMessage {...messages.slippage} />
        </label>
        <span>{slippageLabelGetter(slippage)}</span>
      </Wrapper>
      <Content data-testid="allowed-slippage-content">
        <Box mb="spacingDefault" color="colorTextImportant">
          <FormattedMessage {...messages.slippage} />
        </Box>
        <ul>
          {DEFAULT_SLIPPAGES.map((defaultSlippage, i) => (
            <li key={i}>
              <RadioButton
                size={FormControlSizes.Small}
                checked={defaultSlippage.ID === selectedSlippage.ID}
                onChange={() => setSelectedSlippage(defaultSlippage)}
                data-testid={`allowed-slippage-custom-radio-${defaultSlippage.value}`}
              >
                {slippageLabelGetter(defaultSlippage)}
              </RadioButton>
            </li>
          ))}
          <li>
            <RadioButton
              size={FormControlSizes.Small}
              checked={CUSTOM_SLIPPAGE.ID === selectedSlippage.ID}
              onChange={() => setSelectedSlippage(CUSTOM_SLIPPAGE)}
              data-testid="allowed-slippage-custom-radio"
            >
              <NumberInput
                placeholder={`${MIN_SLIPPAGE}-${MAX_SLIPPAGE} bps`}
                onChange={value => setInputValue(value)}
                onClick={() => setSelectedSlippage(CUSTOM_SLIPPAGE)}
                value={inputValue}
                defaultIncrement="1"
                min={MIN_SLIPPAGE}
                max={MAX_SLIPPAGE}
                size={FormControlSizes.Small}
                data-testid="allowed-slippage-custom-input"
              />
            </RadioButton>
          </li>
        </ul>
        <Buttons>
          <Button size={FormControlSizes.Small} onClick={handleCancel}>
            <FormattedMessage {...messages.cancel} />
          </Button>
          <Button
            variant={ButtonVariants.Primary}
            size={FormControlSizes.Small}
            disabled={!isValid}
            onClick={handleApply}
            data-testid="slippage-apply-bps"
          >
            <FormattedMessage {...messages.apply} />
          </Button>
        </Buttons>
      </Content>
    </Popover>
  );
});

function useSlippageLabelGetter() {
  const { formatMessage } = useIntl();

  return useCallback(
    (slippage: Slippage) => {
      if (slippage.value === null) {
        return formatMessage(messages.noBps);
      }
      return formatMessage(messages.bps, { bps: slippage.value });
    },
    [formatMessage]
  );
}
