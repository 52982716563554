import styled, { css, type DefaultTheme } from 'styled-components';

export type IndicatorDotProps = {
  show?: boolean;
  size?: number;
  variant?: IndicatorDotVariants;
  theme?: DefaultTheme;
  halo?: boolean;
};

export enum IndicatorDotVariants {
  Primary = 'PRIMARY',
  Default = 'DEFAULT',
  Positive = 'POSITIVE',
  Negative = 'NEGATIVE',
  Warning = 'WARNING',
  Notification = 'NOTIFICATION',
}

export const Dot = styled.div<IndicatorDotProps>`
  border-radius: 50%;
  box-shadow: 0 0 0 1px ${({ theme }) => theme.backgroundContent};
  ${({ size }) => `height: ${size}px; min-width: ${size}px;`};
  background: ${({ theme }) => theme.colors.gray['050']};
  ${({ variant, theme }) => variant === IndicatorDotVariants.Primary && `background: ${theme.colors.primary.lighten};`}
  ${({ variant, theme }) => variant === IndicatorDotVariants.Positive && `background: ${theme.colors.green.lighten};`}
  ${({ variant, theme }) => variant === IndicatorDotVariants.Warning && `background: ${theme.colors.yellow.lighten};`}
  ${({ variant, theme }) => variant === IndicatorDotVariants.Negative && `background: ${theme.colors.red.lighten};`}
  ${({ variant, theme }) =>
    variant === IndicatorDotVariants.Notification && `background: ${theme.colors.orange.lighten};`}
  opacity: ${({ show }) => (show ? '1' : '0')};
`;

const haloBackground = css<IndicatorDotProps>`
  ${({ theme, variant }) => {
    switch (variant) {
      case IndicatorDotVariants.Primary:
        return `
      background: ${theme.colors.primary.mute};
      &::after {
        background: ${theme.colors.primary.lighten};
      }
      `;
      case IndicatorDotVariants.Positive:
        return `
      background: ${theme.colors.green.mute};
      &::after {
        background: ${theme.colors.green.lighten};
      }
      `;
      case IndicatorDotVariants.Negative:
        return `
      background: ${theme.colors.red.mute};
      &::after {
        background: ${theme.colors.red.lighten};
      }
      `;
      case IndicatorDotVariants.Warning:
        return `
      background: ${theme.colors.yellow.mute};
      &::after {
        background: ${theme.colors.yellow.lighten};
      }
      `;
      case IndicatorDotVariants.Notification:
        return `
      background: ${theme.colors.orange.mute};
      &::after {
        background: ${theme.colors.orange.lighten};
      }
      `;
      case IndicatorDotVariants.Default:
      default:
        return `
      background: ${theme.colors.gray['050']};
      &::after {
        background: ${theme.colors.gray['090']};
      }
      `;
    }
  }}
`;

export const HaloDot = styled.div<IndicatorDotProps>`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &::after {
    content: '';
    width: 0.375rem;
    height: 0.375rem;
    border-radius: 50%;
  }

  ${haloBackground}
`;

export const Wrapper = styled.div<Pick<IndicatorDotProps, 'size'>>`
  position: relative;
  width: fit-content;

  ${Dot}, ${HaloDot} {
    position: absolute;
    transform: scale(1) translate(50%, -50%);

    top: 0;
    right: 0;
    user-select: none;
    color: ${({ theme }) => theme.colorTextAttention};
    text-align: center;
    font-size: ${({ theme }) => `${theme.fontSizeTiny}rem`};

    ${({ size, theme }) => size === theme.indicatorDotSizeDefault && 'top: 2px'};
    ${({ size, theme }) => size === theme.indicatorDotSizeDefault && 'right: 1px'};
  }
`;
