import type { ElementType, ReactElement, ReactNode } from 'react';
import { FormattedMessage as ReactIntlFormattedMessage, type MessageDescriptor } from 'react-intl';

export interface FormattedMessageProps<V extends Record<string, any> = Record<string, any>>
  extends Omit<MessageDescriptor, 'id'> {
  id: NonNullable<MessageDescriptor['id']>;
  values?: V;
  tagName?: ElementType<any>;
  children?(nodes: ReactNode[]): ReactElement | null;
}

export function FormattedMessage(props: FormattedMessageProps) {
  return <ReactIntlFormattedMessage {...props} />;
}
