import { createContext, useContext } from 'react';
import type { Observable } from 'rxjs';
import type { ConnectionErrorCodeEnum, ConnectionStatusEnum } from '../types/types';
import type { MarketCredential } from './UserContext';

export const ConnectionStatusContext = createContext<ConnectionStatusContextProps | undefined>(undefined);
ConnectionStatusContext.displayName = 'ConnectionStatusContext';

export function useConnectionStatusContext(): ConnectionStatusContextProps {
  const context = useContext(ConnectionStatusContext);
  if (context === undefined) {
    throw new Error('Missing ConnectionStatus.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export interface ConnectionStatusContextProps {
  /**
   * A function to update the market credentials list after adding, enabling or disabling credential
   */
  updateMarketCredentials: () => void;
  /**
   * A map of connection statuses by ConnectionID and Market Name
   */
  connectionStatusByConnectionIDAndMarketName: Map<number, Map<string, ConnectionStatus>> | undefined;
  /**
   * A map of connection statuses by CredentialID and Market Name
   */
  connectionStatusByCredentialIDAndMarketName: Observable<Map<number, Map<string, ConnectionStatus>>>;
  /**
   * A map of market credentials with connection status by CredentialID
   */
  marketCredentialsWithConnectionStatusByCredentialID: Observable<Map<number, MarketCredentialWithConnectionStatus>>;
  /**
   * A map of market credentials with connection status by Market Account Name
   */
  marketCredentialsWithConnectionStatusByMarketAccountName: Map<string, MarketCredentialWithConnectionStatus>;
}

export interface MarketCredentialWithConnectionStatus {
  credential: MarketCredential;
  connectionStatus?: ConnectionStatus;
}

export interface ConnectionStatus {
  Market: string;
  ConnectionID: number;
  ConnectionType: {
    BalanceUpdates?: boolean;
    OrdersRequests?: boolean;
    OrdersUpdates?: boolean;
    TradesUpdates?: boolean;
    MarketDataDepth?: boolean;
    MarketDataTrades?: boolean;
  };
  CredentialID: number;
  Status: ConnectionStatusEnum;
  ErrorCode: ConnectionErrorCodeEnum;
  UpdateAction: string;
  Text?: string;
}
