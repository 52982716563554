import type { ExpandedChangedEvent, ICellRendererParams } from 'ag-grid-community';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex } from '../Core';
import { Icon, IconName } from '../Icons';
import { Text } from '../Text';

export const AG_GRID_FULL_WIDTH_GROUP_ROW = 'AgGridFullWidthGroupRow';

export type AgGridFullWidthGroupRowParams = ICellRendererParams & {
  getLabel?: (params: ICellRendererParams) => string;
};

export function AgGridFullWidthGroupRow({ getLabel, ...cellRendererParams }: AgGridFullWidthGroupRowParams) {
  const { node } = cellRendererParams;
  const [expanded, setExpanded] = useState(node.expanded);

  useEffect(() => {
    const expandListener = (event: ExpandedChangedEvent) => setExpanded(event.node.expanded);

    node.addEventListener('expandedChanged', expandListener);

    return () => {
      node.removeEventListener('expandedChanged', expandListener);
    };
  }, [node]);

  const onClick = useCallback(() => node.setExpanded(!node.expanded), [node]);
  const label = getLabel ? getLabel(cellRendererParams) : 'Unknown';

  return (
    <RowContainer
      px="spacingComfortable"
      cursor="pointer"
      onClick={onClick}
      h="100%"
      w="100%"
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex gap="spacingSmall" alignItems="center">
        <Icon
          size="fontSizeSmall"
          icon={IconName.ChevronRight}
          style={{
            transform: !expanded ? 'rotate(0deg)' : 'rotate(90deg)',
            transition: 'transform 100ms linear',
          }}
        />
        <Text>{label}</Text>
      </Flex>
    </RowContainer>
  );
}

const RowContainer = styled(Flex)`
  background-color: var(--colors-gray-030);
  transition-duration: 100ms;
  transition-property: background-color;
  :hover {
    background-color: var(--colors-gray-00);
  }
`;
