import type { DefaultTheme } from 'styled-components';
import { DarkGriffoTheme } from './DarkGriffoTheme';

/** @deprecated */
export const DarkGriffoDevTheme: DefaultTheme = {
  ...DarkGriffoTheme,
  name: 'DarkGODev',
  spacingLayout: 16,
  boxShadowContent: '0 2px 16px rgba(0, 0, 0, 0.75)',
  boxShadowHeader: '0 2px 16px rgba(0, 0, 0, 0.75)',
};
