import { useCallback, useEffect, useMemo, useState, type HTMLAttributes, type ReactNode } from 'react';

import { useTransition } from '@react-spring/web';
import { v1 as uuid } from 'uuid';
import { useConstant } from '../../hooks';
import { EMPTY_OBJECT } from '../../utils';
import { Notification, type NotificationVariants } from '../Notification';
import { ToastWrapper, Wrapper } from './styles';

export type ToastProps = {
  text: ReactNode;
  variant?: NotificationVariants;
  id?: string;
  timeout?: number;
  dismissable?: boolean;
};

type UseToastArgs = {
  defaultProps: Partial<Pick<ToastProps, 'timeout' | 'dismissable' | 'variant'>>;
};

const TRANSITION = {
  from: { opacity: 0, transform: 'scale(0.95)' },
  enter: { opacity: 1, transform: 'scale(1)' },
  leave: { opacity: 0, transform: 'scale(0.95)' },
  keys: item => item.id,
};

export function useToasts(args?: UseToastArgs) {
  const timeouts: ReturnType<typeof setTimeout>[] = useConstant([]);
  const [toasts, setToasts] = useState<ToastProps[]>([]);
  const toastDefaultProps = args?.defaultProps ?? EMPTY_OBJECT;

  const remove = useCallback((id: string) => {
    setToasts(prev => prev.filter(toast => toast.id !== id));
  }, []);

  const add = useCallback(
    (toast: ToastProps) => {
      if (toast.id == null) {
        toast.id = uuid();
      }
      const newToast = { ...toastDefaultProps, ...toast } as ToastProps & { id: string };
      setToasts(prev => [...prev, newToast]);
      if (newToast.timeout != null) {
        timeouts.push(setTimeout(() => remove(newToast.id), newToast.timeout));
      }
    },
    [remove, timeouts, toastDefaultProps]
  );

  useEffect(() => {
    return () => {
      for (const timeout of timeouts) {
        clearTimeout(timeout);
      }
    };
  }, [timeouts]);

  return useMemo(
    () => ({
      toasts,
      add,
      remove,
    }),
    [toasts, add, remove]
  );
}

export type ToastsProps = {
  toasts: ToastProps[];
  direction?: 'column' | 'row' | 'column-reverse' | 'row-reverse';
  remove(id: string | undefined): void;
  stackingLimit?: number;
};

export function Toasts({ toasts, remove, stackingLimit, direction = 'column' }: ToastsProps) {
  const [preventStacking, setPreventStacking] = useState(false);
  const transitions = useTransition(toasts, TRANSITION);
  return (
    <Wrapper direction={direction}>
      {transitions((style, toast, t, index) => {
        const stackingStyle =
          !!stackingLimit && toasts?.length > stackingLimit && !preventStacking
            ? getStackingStyle(style, index, toasts.length, stackingLimit, direction.includes('reverse'))
            : style;
        return (
          <Toast
            key={t.key}
            style={stackingStyle}
            onRemove={() => remove(toast.id)}
            onClick={() => setPreventStacking(!preventStacking)}
            {...toast}
          />
        );
      })}
    </Wrapper>
  );
}

export function Toast({
  style,
  onRemove,
  onClick,
  ...toast
}: ToastProps & { onRemove: () => void } & HTMLAttributes<HTMLDivElement>) {
  return (
    <ToastWrapper style={style}>
      <Notification variant={toast.variant} dismissable={toast.dismissable} onClick={onClick} onRemove={onRemove}>
        {toast.text}
      </Notification>
    </ToastWrapper>
  );
}

function getStackingStyle(style, index: number, length: number, stackingLimit: number, reverse: boolean) {
  return length - index === 1
    ? style
    : {
        position: 'absolute',
        transform: `scale(${1 - 0.01 * (length - index - 1)}) translateY(${
          (length - index - 1) * 3 * (reverse ? 1 : -1)
        }%)`,
        opacity: length - index > stackingLimit ? 0 : 1,
      };
}
