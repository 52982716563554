import type { ReactNode } from 'react';
import { useCurrency } from '../../hooks/useCurrency';
import type { Currency } from '../../types/Currency';
import type { PositionReconStatus, PositionWarning } from '../../types/Position';
import { ReconStreamStatusEnumLabels } from '../../types/ReconStatus';
import { WARNING_SEVERITY_TO_ALERT_VARIANT } from '../../types/WarningSeverity';
import { ReconStreamStatusEnum } from '../../types/types';
import { formattedDate } from '../../utils/date';
import { Alert } from '../Alert';
import { HStack, VStack } from '../Core';
import { InlineFormattedNumber } from '../FormattedNumber';
import { Text } from '../Text';

interface PositionWarningsProps {
  warning: PositionWarning;
}

export function PositionWarnings({ warning }: PositionWarningsProps) {
  const { severity, asset, reconStatus } = warning;

  const currency = useCurrency(asset);

  return (
    <VStack gap="spacingMedium" w="100%" p="spacingDefault">
      <Alert
        w="100%"
        variant={WARNING_SEVERITY_TO_ALERT_VARIANT[severity]}
        dismissable={false}
        justifyContent="space-between"
        alignItems="center"
      >
        <Text size="fontSizeMd" color="gray.100">
          {ReconStreamStatusEnumLabels[reconStatus.Status]}
        </Text>
      </Alert>
      {displayReconStatusDetails(reconStatus, currency)}
    </VStack>
  );
}

function displayReconStatusDetails(reconStatus: PositionReconStatus, currency: Currency | undefined): ReactNode {
  switch (reconStatus.Status) {
    case ReconStreamStatusEnum.MatchingSinceLatestMismatch:
    case ReconStreamStatusEnum.LedgerAheadSinceLatestMismatch:
      return (
        <VStack gap="spacingSmall" alignItems="flex-start" w="100%">
          {reconStatus.TotalMismatch != null && (
            <HStack gap="spacingLarge" justifyContent="space-between" w="100%">
              <Text color="gray.100">Total mismatch</Text>
              <InlineFormattedNumber
                number={reconStatus.TotalMismatch}
                increment={currency?.DefaultIncrement}
                currency={currency?.Symbol}
                align="right"
              />
            </HStack>
          )}
          {reconStatus.LastMismatchTime != null && (
            <HStack gap="spacingLarge" justifyContent="space-between" w="100%">
              <Text color="gray.100">Last mismatch</Text>
              <Text>{formattedDate(reconStatus.LastMismatchTime)}</Text>
            </HStack>
          )}
        </VStack>
      );

    default:
      return <></>;
  }
}
