import { values, without } from 'lodash';
import { LoanQuoteStatusEnum, OrdStatusEnum, QuoteStatusEnum } from '../../../types/types';
import { OrderStatusText } from '../../Status/types';

export enum Filters {
  NewRequest = 'Request Sent',
  Review = 'Review',
  Open = 'Open',
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  OpenLoan = 'Open',
  Filled = 'Filled',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
}

export const LOAN_QUOTE_STATUS_MAPPING = {
  [Filters.NewRequest]: [LoanQuoteStatusEnum.PendingNew],
  [Filters.Review]: [LoanQuoteStatusEnum.LenderFirm, LoanQuoteStatusEnum.BorrowerFirm],
  [Filters.OpenLoan]: [LoanQuoteStatusEnum.Filled],
  [Filters.Canceled]: [LoanQuoteStatusEnum.Canceled],
  [Filters.Rejected]: [LoanQuoteStatusEnum.Rejected],
};

export const QUOTE_STATUS_MAPPING = {
  'All Open': [
    QuoteStatusEnum.Open,
    QuoteStatusEnum.PendingNew,
    QuoteStatusEnum.PendingFill,
    QuoteStatusEnum.PendingCancel,
  ],
  'All Filled': [QuoteStatusEnum.Filled, QuoteStatusEnum.PendingFix],
  'All Canceled': [QuoteStatusEnum.Canceled],
  'All Rejected': [QuoteStatusEnum.Rejected],
};

export const ORD_STATUS_MAPPING = {
  'All Open': [
    OrdStatusEnum.New,
    OrdStatusEnum.PendingNew,
    OrdStatusEnum.PendingReplace,
    OrdStatusEnum.PendingCancel,
    OrdStatusEnum.PartiallyFilled,
  ],
  'All Filled': [OrdStatusEnum.Filled, OrdStatusEnum.PendingFix, OrdStatusEnum.DoneForDay],
  'All Canceled': [OrdStatusEnum.Canceled],
  'All Rejected': [OrdStatusEnum.Rejected],
};
const incompleteOrderStatuses = without(
  values(OrdStatusEnum),
  OrdStatusEnum.Canceled,
  OrdStatusEnum.Filled,
  OrdStatusEnum.Rejected,
  OrdStatusEnum.DoneForDay
) as Exclude<
  OrdStatusEnum,
  OrdStatusEnum.Canceled | OrdStatusEnum.Filled | OrdStatusEnum.Rejected | OrdStatusEnum.DoneForDay
>[];
export const ORD_TEXT_TO_STATUS_MAPPING: Record<OrderStatusText, OrdStatusEnum[]> = {
  [OrderStatusText.Open]: [
    OrdStatusEnum.New,
    OrdStatusEnum.PendingNew,
    OrdStatusEnum.PartiallyFilled,
    OrdStatusEnum.Holding,
    OrdStatusEnum.Replaced,
  ],
  [OrderStatusText.Filled]: [OrdStatusEnum.Filled, OrdStatusEnum.DoneForDay],
  [OrderStatusText.Canceled]: [OrdStatusEnum.Canceled, OrdStatusEnum.DoneForDay],
  [OrderStatusText.FilledAndCanceled]: [OrdStatusEnum.Canceled, OrdStatusEnum.DoneForDay],
  [OrderStatusText.Rejected]: [OrdStatusEnum.Rejected],
  [OrderStatusText.Paused]: incompleteOrderStatuses,
  [OrderStatusText.SystemPaused]: incompleteOrderStatuses,
  [OrderStatusText.Staged]: incompleteOrderStatuses,
  [OrderStatusText.Waiting]: incompleteOrderStatuses,
  [OrderStatusText.PendingCancel]: [OrdStatusEnum.PendingCancel],
  [OrderStatusText.PendingReplace]: [OrdStatusEnum.PendingReplace],
  [OrderStatusText.PendingFix]: [OrdStatusEnum.PendingFix],
};
