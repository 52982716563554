import { useMemo } from 'react';

import {
  EMPTY_ARRAY,
  IconName,
  useGetCustomerMarketAccountLabel,
  useIntl,
  useWLCustomerMarketAccountContext,
  type FilterableProperty,
} from '@talos/kyoko';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  account: {
    defaultMessage: 'Account',
    id: 'Filters.FilterBuilder.filters.account',
  },
});

/**
 * This hook creates the filter property definition for CustomerMarketAccount properties.
 *
 * @param overrides Any overrides to make to the defaults here (e.g. `key`, etc.)
 * @returns Filter Property definition to use for CustomerMarketAccount filters
 */
export function useCustomerMarketAccountsFilter<TKey extends string = 'MarketAccounts', P = FilterableProperty<TKey>>(
  overrides?: { [K in keyof P]: K extends keyof FilterableProperty<TKey> ? P[K] : never } & { key?: TKey }
) {
  const { customerMarketAccountsList } = useWLCustomerMarketAccountContext();
  const { formatMessage } = useIntl();
  const getCustomerMarketAccountLabel = useGetCustomerMarketAccountLabel();

  return useMemo(
    () => ({
      ...({
        key: 'MarketAccounts' as TKey,
        label: formatMessage(messages.account),
        icon: IconName.UserCircle,
        options: customerMarketAccountsList?.map(ma => ma.SourceAccountID) ?? EMPTY_ARRAY,
        getOptionLabel: getCustomerMarketAccountLabel,
      } satisfies FilterableProperty<TKey>),
      ...overrides,
    }),
    [formatMessage, getCustomerMarketAccountLabel, customerMarketAccountsList, overrides]
  );
}
