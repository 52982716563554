import type { ICellRendererParams } from 'ag-grid-community';
import { isCreditUsageWarning } from '../../../types';
import type { AgGridWarningProps } from '../../AgGrid/types';
import { CreditUsageWarnings } from '../../CreditUsageWarnings';
import type { ColDefFactory, Column } from './types';
import { warning } from './warning';
import type { WarningColumnValue } from './warning.types';

export const creditUsageWarning: ColDefFactory<Column> = column => ({
  ...warning(column),
  colId: 'creditUsageWarning',
  headerName: 'Credit Usage Warning',
  cellRendererParams: (params: ICellRendererParams<any, WarningColumnValue | undefined>): AgGridWarningProps => {
    const valueIsAggregatedFromChild = params.value?.nodeId !== params.node.id;
    if (isCreditUsageWarning(params.value)) {
      return {
        ...params,
        tooltip: (
          <CreditUsageWarnings warning={params.value} isWarningAggregatedFromChild={valueIsAggregatedFromChild} />
        ),
      };
    }

    return { ...params, value: undefined };
  },
});
