import { useMemo } from 'react';
import { getAgGridColId } from '../components/BlotterTable/columns/getAgGridColId';
import type { Column, ColumnDef } from '../components/BlotterTable/columns/types';
import { useDefaultColumns } from '../components/BlotterTable/useDefaultColumns';
import { EMPTY_ARRAY } from '../utils/empty';
import type { OrdRejReasonEnum, QuoteStatusEnum, SideEnum } from './types';

// Quote4411
export class MarketQuote {
  static readonly rowID = 'QID';
  static readonly defaultColumns = [
    'SubmitTime',
    'QID',
    'Market',
    'QuoteReqID',
    'Side',
    'TradedSide',
    'Symbol',
    'OrderQty',
    'QuoteStatus',
    'ParentRFQID',
    'RID',
  ] satisfies (keyof MarketQuote)[];

  Timestamp!: string;

  MessageID?: string;

  Symbol!: string;

  Currency!: string;

  RID!: string;

  QID!: string;

  QuoteReqID?: string;

  QuoteStatus!: QuoteStatusEnum;

  QuoteID?: string;

  QuoteRequestRejectReason?: OrdRejReasonEnum;

  SubmitTime?: string;

  OrderQty?: string;

  AmountCurrency?: string;

  Side?: SideEnum;

  TradedPx?: string;

  TradedQty?: string;

  TradedAmt?: string;

  TradedSide?: SideEnum;

  ParentRFQID?: string;

  TransactTime?: string;

  EventIndicator?: string;

  Text?: string;

  BidAmt?: string;

  BidPx?: string;

  Market!: string;

  MarketAccount?: string;

  OfferAmt?: string;

  OfferPx?: string;

  ValidUntilTime?: string;

  constructor(data: MarketQuote) {
    Object.assign(this, data);
  }
}

interface UseMarketQuoteColumns {
  defaultColumns?: (keyof MarketQuote | Partial<Column>)[];
}

export function useMarketQuoteColumns({ defaultColumns = EMPTY_ARRAY }: UseMarketQuoteColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            {
              field: 'SubmitTime',
              type: 'date',
              sortable: true,
              sort: '-',
            },
            {
              field: 'QID',
              type: 'text',
            },
            {
              field: 'Market',
              type: 'market',
            },
            {
              field: 'QuoteReqID',
              type: 'id',
            },
            {
              field: 'Side',
              type: 'quoteSide',
            },
            {
              field: 'TradedSide',
              type: 'side',
            },
            {
              field: 'Symbol',
              type: 'security',
            },
            {
              field: 'OrderQty',
              type: 'size',
              params: { currencyField: 'Currency' },
            },
            {
              field: 'QuoteStatus',
              type: 'quoteStatus',
            },
            {
              field: 'ParentRFQID',
              type: 'id',
            },
            {
              field: 'RID',
              type: 'text',
            },
          ] satisfies ColumnDef<MarketQuote>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );
  const defaultHiddenColumns = useMemo(() => {
    return new Map(
      (
        [
          {
            field: 'Timestamp',
            type: 'date',
          },
          {
            field: 'MessageID',
            type: 'text',
          },
          {
            field: 'Currency',
            type: 'currency',
          },
          {
            field: 'QuoteID',
            type: 'id',
          },
          {
            field: 'QuoteRequestRejectReason',
            type: 'text',
          },
          {
            field: 'AmountCurrency',
            type: 'currency',
          },
          {
            field: 'TradedPx',
            type: 'price',
            params: { assetField: 'Symbol' },
          },
          {
            field: 'TradedQty',
            type: 'size',
            params: { currencyField: 'Currency' },
          },
          {
            field: 'TradedAmt',
            type: 'size',
            params: { currencyField: 'AmountCurrency' },
          },
          {
            field: 'TransactTime',
            type: 'date',
          },
          {
            field: 'EventIndicator',
            type: 'text',
          },
          {
            field: 'Text',
            type: 'text',
          },
          {
            field: 'BidAmt',
            type: 'size',
            params: { currencyField: 'AmountCurrency' },
          },
          {
            field: 'BidPx',
            type: 'price',
            params: { assetField: 'Symbol' },
          },
          {
            field: 'MarketAccount',
            type: 'marketAccount',
          },
          {
            field: 'OfferAmt',
            type: 'size',
            params: { currencyField: 'AmountCurrency' },
          },
          {
            field: 'OfferPx',
            type: 'price',
            params: { assetField: 'Symbol' },
          },
          {
            field: 'ValidUntilTime',
            type: 'date',
          },
        ] satisfies (false | ColumnDef<MarketQuote>)[]
      ).map(c => [getAgGridColId(c), { ...c, hide: true }])
    );
  }, []);
  const columnDefinitions = useMemo(() => {
    return new Map(
      (
        [
          ...defaultVisibleColumns.values(),
          ...defaultHiddenColumns.values(),
        ] satisfies ColumnDef<MarketQuote>[] as Column[]
      ).map(c => [getAgGridColId(c), c])
    );
  }, [defaultVisibleColumns, defaultHiddenColumns]);
  return useDefaultColumns(defaultColumns, columnDefinitions);
}
