import Big, { type BigSource } from 'big.js';
import type { IHedgePositionStatus } from '../../types';
import { bigMin, toBig, toBigWithDefault } from '../../utils';

export type PositionThresholdMeterValuesArgs = Partial<
  Pick<
    IHedgePositionStatus,
    | 'CurrentPosition'
    | 'TargetPosition'
    | 'LongPositionLimit'
    | 'ShortPositionLimit'
    | 'HedgeControlStatus'
    | 'TargetAsset'
  >
>;

/**
 * Util function for rendering the position threshold meter.
 * It calculates the correct target percent, (where the indicator will be placed)
 * and the correct current percent (where the current position is, i.e. the width of the bar in the meter).
 */
export function getPositionThresholdMeterValues(value: PositionThresholdMeterValuesArgs) {
  const targetPosition = value.TargetPosition;
  const shortLimit = toBigWithDefault(value.ShortPositionLimit, 0);
  const longLimit = toBigWithDefault(value.LongPositionLimit, 0);
  const currentPosition = toBigWithDefault(value.CurrentPosition, 0);

  const targetOffset = Big(currentPosition).abs();
  const positionLessThanZero = !!toBig(currentPosition)?.lt(0);
  const positionGreaterThanZero = !!toBig(currentPosition)?.gt(0);

  const bigTargetPosition = toBigWithDefault(targetPosition, 0);

  let targetPercent: BigSource = 0;

  if (bigTargetPosition.lt(0) && !shortLimit.eq(0)) {
    targetPercent = bigTargetPosition.div(shortLimit);
  } else if (bigTargetPosition.gt(0) && !longLimit.eq(0)) {
    targetPercent = bigTargetPosition.div(longLimit);
  }

  let currentPercent: Big | BigSource = 0;
  if (positionLessThanZero) {
    if (shortLimit.eq(0)) {
      // if the short limit is zero, the current percent is -1 or less, set it to -1
      currentPercent = -1;
    } else {
      currentPercent = Big(bigMin(targetOffset.div(shortLimit), 1) ?? 0).mul(-1);
    }
  } else if (positionGreaterThanZero) {
    if (longLimit.eq(0)) {
      // if the long limit is zero, the current percent is 1 or greater, set it to 1
      currentPercent = 1;
    } else {
      currentPercent = bigMin(targetOffset.div(longLimit), 1) ?? 0;
    }
  }

  return {
    currentPercent: Number(currentPercent.toFixed(4)),
    targetPercent: Number(targetPercent.toFixed(4)),
  };
}
