import { Fragment, memo, useMemo } from 'react';
import { useTheme } from 'styled-components';

import {
  Box,
  Divider,
  DonutChart,
  ExposureStatusEnum,
  Flex,
  FormattedMessage,
  HStack,
  Icon,
  IconName,
  IndicatorBadgeWrapper,
  InlineFormattedNumber,
  RadioButton,
  Text,
  Tooltip,
  VStack,
  useCurrenciesContext,
  useGetCustomerMarketAccountLabel,
  useWLDefaultMarketAccount,
  useWLExposuresContext,
  useWLExposuresUtils,
  type EnrichedCreditExposure,
} from '@talos/kyoko';
import { defineMessages } from 'react-intl';
import { PillBox } from './styles';

const messages = defineMessages({
  limit: {
    defaultMessage: 'Limit',
    id: 'CreditUtilization.limit',
  },
  exposure: {
    defaultMessage: 'Exposure',
    id: 'CreditUtilization.exposure',
  },
});

export const CreditUtilization = memo(function CreditUtilization() {
  const { currenciesBySymbol } = useCurrenciesContext();
  const { defaultMarketAccount, setDefaultMarketAccount } = useWLDefaultMarketAccount();
  const { activeExposures, currentExposure, currentExposurePercentage, getExposurePercentage } =
    useWLExposuresContext();
  const { getAbbreviatedAmountByMarketAccount } = useWLExposuresUtils();
  const theme = useTheme();

  const CreditUtilizationTooltip = useMemo(() => {
    if (!activeExposures || !currentExposure) {
      return null;
    }
    return (
      <Flex
        flexDirection="column"
        w="250px"
        fontSize={theme.fontSizeSmall}
        gap={theme.spacingSmall}
        p={theme.spacingDefault}
      >
        {activeExposures.map((exposure: EnrichedCreditExposure, index) => {
          const exposurePercentage = getExposurePercentage(exposure);

          return (
            <Fragment key={exposure.MarketAccount}>
              {index > 0 && <Divider mt="spacingDefault" mb="spacingDefault" />}
              <RadioButton
                checked={exposure.MarketAccount === defaultMarketAccount}
                onChange={() => setDefaultMarketAccount(exposure.MarketAccount)}
              >
                <VStack gap="spacingSmall" ml="spacingTiny">
                  <HStack justifyContent="space-between" w="100%">
                    <Text color={theme.colorTextImportant}>
                      <strong>{exposure.MarketAccount}</strong>
                    </Text>
                    <div>
                      {!isNaN(exposurePercentage) && (
                        <InlineFormattedNumber
                          currency="%"
                          increment="0.01"
                          number={exposurePercentage}
                          align="right"
                        />
                      )}
                      {exposure.Status !== ExposureStatusEnum.Online && (
                        <Icon color="colors.yellow.lighten" icon={IconName.ExclamationCircle} />
                      )}
                    </div>
                  </HStack>
                  <HStack justifyContent="space-between" w="100%">
                    <Text color={theme.colorTextDefault}>
                      <FormattedMessage {...messages.limit} />
                    </Text>
                    <InlineFormattedNumber
                      number={exposure.ExposureLimit ?? 0}
                      currency={exposure.ExposureCurrency}
                      increment={currenciesBySymbol.get(exposure.ExposureCurrency)?.DefaultIncrement}
                      align="right"
                    />
                  </HStack>
                  <HStack justifyContent="space-between" w="100%">
                    <Text color={theme.colorTextDefault}>
                      <FormattedMessage {...messages.exposure} />
                    </Text>
                    <InlineFormattedNumber
                      number={exposure.Exposure ?? 0}
                      currency={exposure.ExposureCurrency}
                      increment={currenciesBySymbol.get(exposure.ExposureCurrency)?.DefaultIncrement}
                      align="right"
                    />
                  </HStack>
                </VStack>
              </RadioButton>
            </Fragment>
          );
        })}
      </Flex>
    );
  }, [
    activeExposures,
    currentExposure,
    theme,
    defaultMarketAccount,
    getExposurePercentage,
    currenciesBySymbol,
    setDefaultMarketAccount,
  ]);

  const color = useMemo(() => {
    if (currentExposurePercentage == null) {
      return theme.colorTextAttention;
    }
    if (currentExposurePercentage >= 100) {
      return theme.colorTextNegative;
    } else if (currentExposurePercentage >= 75) {
      return theme.colorTextWarning;
    }
    return theme.colorTextAttention;
  }, [currentExposurePercentage, theme]);

  const getCustomerMarketAccountLabel = useGetCustomerMarketAccountLabel();

  if (!currentExposure) {
    // Don't show credit utilization until we receive a response.
    return null;
  }

  return (
    <PillBox>
      <Tooltip tooltip={CreditUtilizationTooltip} placement="bottom">
        <HStack justifyContent="space-between" gap={theme.spacingDefault}>
          <DonutChart
            percent={
              currentExposurePercentage !== undefined && isNaN(currentExposurePercentage)
                ? 100
                : currentExposurePercentage ?? 0
            }
            size={16}
            strokeColor={color}
          />
          <Box background={theme.borderColorInputReadOnly} borderRadius={3} px={5} py={2}>
            <IndicatorBadgeWrapper>
              <Icon icon={IconName.Users} size={10} />
              <Text color={theme.colorTextImportant} size={theme.fontSizeSmall}>
                {getCustomerMarketAccountLabel(currentExposure?.MarketAccount)}
              </Text>
            </IndicatorBadgeWrapper>
          </Box>
          <Text color={theme.colorTextImportant} size={theme.fontSizeSmall}>
            {getAbbreviatedAmountByMarketAccount(currentExposure.MarketAccount, 'Exposure')}
            {' / '}
            {getAbbreviatedAmountByMarketAccount(currentExposure.MarketAccount, 'ExposureLimit')}{' '}
            <Text color={theme.colorTextDefault}>
              <span>{currentExposure?.ExposureCurrency}</span>
            </Text>
          </Text>
        </HStack>
      </Tooltip>
    </PillBox>
  );
});
