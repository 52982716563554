import type { ExpectTrue } from '../../tests';
import { NUM_ORDERS_DATAPOINTS } from '../../tokens';
import { parseDate, type OptionalProperties, type RequiredProperties } from '../../utils';
import type {
  AggregationWindowEnum,
  AnalyticsOrderDepthEnum,
  INumOrdersDatapoint,
  OrderMarketabilityEnum,
  StrategyTypeEnum,
  UpdateActionEnum,
} from '../types';
import type { DatapointProps, OptionalDatapointProperties } from './types';

export class NumOrdersDatapoint {
  public static readonly Stream_Name = NUM_ORDERS_DATAPOINTS;

  public readonly StartTime: Date;
  public readonly Asset: string | undefined;

  public readonly NumberOfOrders: number;
  public readonly Market: string | undefined;
  public readonly AnalyticsOrderDepth: AnalyticsOrderDepthEnum | undefined;
  public readonly Strategy: string | undefined;
  public readonly StrategyType: StrategyTypeEnum | undefined;
  public readonly OrderMarketability: OrderMarketabilityEnum | undefined;
  public readonly UpdateAction: UpdateActionEnum | undefined;
  public readonly ReleaseRevision: string | undefined;
  public readonly AggregationWindow: AggregationWindowEnum | undefined;

  constructor(data: NumOrdersDatapoint) {
    this.StartTime = parseDate(data.StartTime);
    this.Asset = data.Asset;
    this.NumberOfOrders = data.NumberOfOrders;
    this.Market = data.Market;
    this.AnalyticsOrderDepth = data.AnalyticsOrderDepth;
    this.Strategy = data.Strategy;
    this.StrategyType = data.StrategyType;
    this.OrderMarketability = data.OrderMarketability;
    this.UpdateAction = data.UpdateAction;
    this.ReleaseRevision = data.ReleaseRevision;
    this.AggregationWindow = data.AggregationWindow;
  }
}

type _NumOrdersDatapoint = RequiredProperties<
  OptionalProperties<DatapointProps<INumOrdersDatapoint>, OptionalDatapointProperties>,
  'NumberOfOrders' | 'UpdateAction'
>;

// TYPE LEVEL TESTS
type _Expect_NumOrdersDatapoint_To_Only_Have_Keys_From_INumOrdersDatapoint = ExpectTrue<
  {
    [K in keyof NumOrdersDatapoint & string]: K extends keyof _NumOrdersDatapoint ? true : K;
  }[Exclude<keyof NumOrdersDatapoint, never>]
>;
type _Expect_All_Keys_In_INumOrdersDatapoint_To_Be_In_NumOrdersDatapoint = ExpectTrue<
  {
    [K in keyof _NumOrdersDatapoint & string]: K extends keyof NumOrdersDatapoint ? true : K;
  }[Exclude<keyof _NumOrdersDatapoint, never>]
>;
