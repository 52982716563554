import Big from 'big.js';
import type { Currency, Security } from '../../types';
import { allowContracts, getMinIncrementForContract } from '../../utils';
import { useNumberInput } from '../Form/NumberInput/useNumberInput';

const isSecurity = (asset?: Currency | Security): asset is Security => (asset as Security)?.BaseCurrency != null;

export function useAmountInput({
  currency,
  asset,
  value,
  min,
  max,
  onChange,
  minIncrementOverride,
  defaultIncrementOverride,
}: {
  currency?: string;
  asset?: Currency | Security;
  value: string;
  min?: string;
  max?: string;
  onChange: (value: string) => void;
  minIncrementOverride?: string;
  defaultIncrementOverride?: string;
}) {
  let base;
  let minIncrement;
  let defaultIncrement;
  if (isSecurity(asset)) {
    base = currency === asset?.BaseCurrency;
    minIncrement =
      minIncrementOverride ||
      (base ? asset?.MinSizeIncrement : asset?.MinAmtIncrement || asset?.MinPriceIncrement) ||
      '0.01';
    defaultIncrement =
      defaultIncrementOverride || (base ? asset?.DefaultSizeIncrement : asset?.DefaultPriceIncrement) || '0.1';
    if (allowContracts(asset)) {
      if (currency) {
        // sc-77242
        minIncrement = getMinIncrementForContract(asset, currency) || minIncrement;
        defaultIncrement = minIncrement;
      } else {
        // https://talostrading.atlassian.net/browse/UI-3168
        minIncrement = '1';
        defaultIncrement = '1';
      }
    }
  } else {
    minIncrement = asset?.MinIncrement || '0.01';
    defaultIncrement = asset?.DefaultIncrement || '0.1';
  }
  const largeIncrement = Big(defaultIncrement).lt(1) ? '1' : Big(defaultIncrement).times(10).toFixed();

  return useNumberInput({
    minIncrement,
    defaultIncrement,
    largeIncrement,
    value,
    min,
    max,
    onChange,
  });
}
