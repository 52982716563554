import type { ExpectTrue } from '../../tests';
import { TOTAL_TRADING_VOLUME } from '../../tokens';
import { parseDate } from '../../utils';
import type { AggregationWindowEnum, AggressorFlagEnum, INumExecutionsDatapoint, UpdateActionEnum } from '../types';
import type { DatapointProps } from './types';

export class NumExecutionsDatapoint {
  public static readonly Stream_Name = TOTAL_TRADING_VOLUME;

  public readonly StartTime: Date;

  public readonly Market: string | undefined;
  public readonly Asset: string | undefined;
  public readonly Strategy: string | undefined;
  public readonly UpdateAction: UpdateActionEnum | undefined;
  public readonly NumberOfExecutions: number;
  public readonly ExecutionType: AggressorFlagEnum;
  public readonly ReleaseRevision: string | undefined;
  public readonly AggregationWindow: AggregationWindowEnum | undefined;

  constructor(data: JSON_NumExecutionsDatapoint | NumExecutionsDatapoint) {
    this.StartTime = parseDate(data.StartTime);
    this.Market = data.Market;
    this.Asset = data.Asset;
    this.Strategy = data.Strategy;
    this.NumberOfExecutions = data.NumberOfExecutions;
    this.ExecutionType = data.ExecutionType;
    this.UpdateAction = data.UpdateAction;
    this.ReleaseRevision = data.ReleaseRevision;
    this.AggregationWindow = data.AggregationWindow;
  }
}

type JSON_NumExecutionsDatapoint = DatapointProps<INumExecutionsDatapoint>;

// TYPE LEVEL TESTS
type _Expect_NumExecutionsDatapoint_To_Only_Have_Keys_From_INumExecutionsDatapoint = ExpectTrue<
  {
    [K in keyof NumExecutionsDatapoint & string]: K extends keyof JSON_NumExecutionsDatapoint ? true : K;
  }[Exclude<keyof NumExecutionsDatapoint, never>]
>;
type _Expect_All_Keys_In_INumExecutionsDatapoint_To_Be_In_NumExecutionsDatapoint = ExpectTrue<
  {
    [K in keyof JSON_NumExecutionsDatapoint & string]: K extends keyof NumExecutionsDatapoint ? true : K;
  }[Exclude<keyof JSON_NumExecutionsDatapoint, never>]
>;
