import Big, { type BigSource } from 'big.js';
import { defineMessages } from 'react-intl';
import type { Security } from '../../../types';
import { FormattedMessage } from '../../Intl';
import { BalanceDetails, BalanceDetailsSummaryLine } from '../BalanceDetails';

const messages = defineMessages({
  availableAmount: {
    defaultMessage: 'Available Amount',
    id: 'Forms.CustomerBalanceDetails.availableAmount',
  },
  availableCredit: {
    defaultMessage: 'Available Credit',
    id: 'Forms.CustomerBalanceDetails.availableCredit',
  },
  balances: {
    defaultMessage: 'Balances',
    id: 'Forms.CustomerBalanceDetails.balances',
  },
  openOrders: {
    defaultMessage: 'Open Orders',
    id: 'Forms.CustomerBalanceDetails.openOrders',
  },
  outstanding: {
    defaultMessage: 'Outstanding',
    id: 'Forms.CustomerBalanceDetails.outstanding',
  },
});

interface BalanceDetailsProps {
  accountID?: string;
  baseAmount?: BigSource;
  baseAvailableAmount?: BigSource;
  baseCreditBalance?: BigSource;
  baseOutstandingBalance?: BigSource;
  pendingWithdrawal?: BigSource;
  quoteAmount?: BigSource;
  quoteAvailableAmount?: BigSource;
  quoteCreditBalance?: BigSource;
  quoteOutstandingBalance?: BigSource;
  security?: Security;
}

export function CustomerBalanceDetails({
  accountID,
  baseAmount,
  baseAvailableAmount,
  baseCreditBalance,
  baseOutstandingBalance,
  pendingWithdrawal,
  quoteAmount,
  quoteAvailableAmount,
  quoteCreditBalance,
  quoteOutstandingBalance,
  security,
}: BalanceDetailsProps) {
  const baseOutstandingAmount = Big(baseOutstandingBalance ?? '0').toString();
  const quoteOutstandingAmount = Big(quoteOutstandingBalance ?? '0').toString();
  const shouldUseOpenOrdersLabelForOutstandingBalance = Big(pendingWithdrawal || '0').eq('0');

  return (
    <BalanceDetails>
      <BalanceDetailsSummaryLine
        accountID={accountID}
        title={<FormattedMessage {...messages.availableAmount} />}
        security={security}
        baseBalance={baseAvailableAmount}
        quoteBalance={quoteAvailableAmount}
      />
      <BalanceDetailsSummaryLine
        accountID={accountID}
        title={<FormattedMessage {...messages.balances} />}
        security={security}
        baseBalance={baseAmount}
        quoteBalance={quoteAmount}
      />
      {baseCreditBalance !== undefined && quoteCreditBalance !== undefined && (
        <BalanceDetailsSummaryLine
          accountID={accountID}
          title={<FormattedMessage {...messages.availableCredit} />}
          security={security}
          baseBalance={Big(baseCreditBalance).lt(0) ? 0 : baseCreditBalance}
          quoteBalance={Big(quoteCreditBalance).lt(0) ? 0 : quoteCreditBalance}
          estimate={true}
        />
      )}
      {(baseOutstandingAmount !== '0' || quoteOutstandingAmount !== '0') && (
        <BalanceDetailsSummaryLine
          accountID={accountID}
          title={
            <FormattedMessage
              {...messages[shouldUseOpenOrdersLabelForOutstandingBalance ? 'openOrders' : 'outstanding']}
            />
          }
          security={security}
          baseBalance={baseOutstandingAmount}
          quoteBalance={quoteOutstandingAmount}
          warnOnPositive={true}
        />
      )}
    </BalanceDetails>
  );
}
