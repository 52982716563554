import { easings, useTransition, type UseTransitionProps } from '@react-spring/web';
import { createContext, type CSSProperties, forwardRef, type PropsWithChildren, useCallback, useContext } from 'react';
import FocusLock from 'react-focus-lock';

import { Portal, useTopLevelPortalElement } from '../Portal';
import { Overlay, Wrapper } from './styles';

import { noop } from 'lodash';
import { useEventListener } from '../../hooks/useEventListener';
import { useForwardedRef } from '../../hooks/useForwardedRef';
import { useOnClickOutside } from '../../hooks/useOnClickOutside';
import type { BoxProps } from '../Core';

const MODAL_TRANSITION: UseTransitionProps = {
  from: { opacity: 0, transform: 'scale(0.9) translateY(12px)' },
  enter: { opacity: 1, transform: 'scale(1) translateY(0)' },
  leave: { opacity: 0, transform: 'scale(0.9) translateY(12px)' },
  config: {
    duration: 300,
    easing: easings.easeOutBack,
  },
  delay: 100,
};

const OVERLAY_TRANSITION: UseTransitionProps = {
  from: { opacity: 0 },
  enter: { opacity: 1 },
  leave: { opacity: 0 },
  config: (isOpen, i, phase) => ({
    duration: phase === 'enter' ? 200 : 100,
  }),
};

export type ModalProps = PropsWithChildren<
  {
    isOpen: boolean;
    close(): void;

    autoFocusFirstElement?: boolean;
    useOverlay?: boolean;
    usePortal?: boolean;
    portalID?: string;
    closeOnEscape?: boolean;
    closeOnClickOutside?: boolean;
    width?: number | string;
    /**
     * If provided, modal will float with the margin top specified, and grow downwards.
     */
    mt?: CSSProperties['marginTop'];

    style?: CSSProperties;
    preventClosing?: boolean;
  } & Omit<BoxProps, 'onChange'>
>;

export const Modal = forwardRef<HTMLDivElement | null, ModalProps>(
  (
    {
      close,
      closeOnEscape = true,
      closeOnClickOutside = true,
      useOverlay = true,
      usePortal = true,
      portalID = 'modal',
      isOpen,
      width,
      autoFocusFirstElement = true,
      children,
      mt,
      preventClosing = false,
      ...props
    },
    ref
  ) => {
    const transition = useTransition(isOpen, MODAL_TRANSITION);
    useTopLevelPortalElement('modal');

    const MaybePortal = usePortal ? Portal : MockPortal;

    const onKeyDown = useCallback(
      (e: KeyboardEvent) => {
        if (closeOnEscape && e.key === 'Escape' && isOpen) {
          if (!preventClosing) {
            close();
            e.stopPropagation();
          }
        }
      },
      [close, closeOnEscape, isOpen, preventClosing]
    );

    useEventListener('keydown', isOpen ? onKeyDown : noop);

    const innerRef = useForwardedRef<HTMLDivElement>(ref);
    useOnClickOutside(innerRef, isOpen && closeOnClickOutside && !preventClosing ? close : undefined);

    return (
      <MaybePortal portalId={portalID}>
        <ModalContext.Provider value={modalContextDefaultValue}>
          <MaybeOverlay useOverlay={useOverlay} isOpen={isOpen} isCentered={!mt}>
            {transition(
              (style, item, t) =>
                item && (
                  <FocusLock autoFocus={autoFocusFirstElement} className="focus-lock">
                    <Wrapper
                      mt={mt}
                      {...props}
                      style={{ ...style, ...props.style }}
                      w={width}
                      ref={innerRef}
                      key={t.key}
                    >
                      {children}
                    </Wrapper>
                  </FocusLock>
                )
            )}
          </MaybeOverlay>
        </ModalContext.Provider>
      </MaybePortal>
    );
  }
);

// Use when we don't want a portal, but still allows us to set portalId
const MockPortal = ({ children }: PropsWithChildren<{ portalId: string }>) => <>{children}</>;

function MaybeOverlay({
  useOverlay,
  isOpen,
  isCentered,
  children,
}: PropsWithChildren<{ useOverlay: boolean; isOpen: boolean; isCentered: boolean }>) {
  const transition = useTransition(isOpen, OVERLAY_TRANSITION);
  return transition(
    (style, item) =>
      item && (
        <Overlay style={style} isTransparent={!useOverlay} isCentered={isCentered}>
          {children}
        </Overlay>
      )
  );
}

const modalContextDefaultValue: ModalContextProps = { portalize: true };
const ModalContext = createContext<ModalContextProps | undefined>(undefined);

export interface ModalContextProps {
  portalize: boolean;
}

export function useModalContext() {
  return useContext(ModalContext);
}
