import { hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { lightColors, mapLightTheme } from './LightTheme';

const colors: Colors = {
  gray: {
    main: hsla(0, 0, 1.0, 1),
    '000': hsla(0, 0, 0.92, 1),
    '010': hsla(0, 0, 0.96, 1),
    '020': hsla(0, 0, 0.89, 1),
    '030': hsla(0, 0, 0.86, 1),
    '040': hsla(0, 0, 0.83, 1),
    '050': hsla(0, 0, 0.79, 1),
    '060': hsla(0, 0, 0.74, 1),
    '070': hsla(0, 0, 0.69, 1),
    '080': hsla(0, 0, 0.63, 1),
    '090': hsla(0, 0, 0.44, 1),
    '100': hsla(0, 0, 0.1, 1),
  },

  black: {
    default: '#000000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#FFFFFF',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.1)',
  },

  primary: {
    mute: 'hsla(246.58, 100%, 50%, 0.1)',
    dim: 'hsla(246.58, 100%, 50%, 0.4)',
    default: 'hsl(246.58, 100%, 50%)',
    lighten: 'hsl(246.58, 100%, 50%)',
  },

  green: {
    default: '#337E5A',
    lighten: '#56AE84',
    dim: 'rgba(51, 126, 90, 0.4)',
    mute: 'rgba(51, 126, 90, 0.1)',
  },

  red: {
    default: '#922E43',
    lighten: '#D23557',
    dim: 'rgba(146, 46, 67, 0.4)',
    mute: 'rgba(146, 46, 67, 0.1)',
  },

  yellow: {
    default: '#CCAC3E',
    lighten: '#F2D264',
    dim: 'rgba(204, 172, 62, 0.4)',
    mute: 'rgba(204, 172, 62, 0.1)',
  },

  blue: {
    default: '#458CC1',
    lighten: '#53AFF8',
    dim: 'rgba(69, 140, 193, 0.4)',
    mute: 'rgba(69, 140, 193, 0.1)',
  },

  orange: {
    default: '#E65200',
    lighten: '#FF6B00',
    dim: 'rgba(230, 82, 0, 0.4)',
    mute: 'rgba(230, 82, 0, 0.1)',
  },

  purple: lightColors.purple,
};

export const LightTDATheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightTDA',

  backgroundBody: colors.gray['010'],

  backgroundDefaultButton: colors.gray['030'],
  backgroundDefaultButtonHover: colors.gray['040'],
  backgroundDefaultButtonFocus: colors.gray['040'],
  backgroundDefaultButtonActive: colors.gray['030'],

  backgroundPriorityButton: colors.gray['060'],
  backgroundPriorityButtonHover: colors.gray['070'],
  backgroundPriorityButtonFocus: colors.gray['070'],
  backgroundPriorityButtonActive: colors.gray['060'],

  backgroundToggleSwitchButton: setAlpha(0.32, colors.gray['000']),
  backgroundToggleSwitchButtonActive: setAlpha(0.12, colors.gray.main),

  colorTextVolumeLadderSpread: colors.gray['080'],
  backgroundVolumeLadderBidSpread: colors.primary.default,
  backgroundVolumeLadderOfferSpread: colors.primary.default,
});
