import type { ValueFormatterParams } from 'ag-grid-community';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

export const exposureDefinition: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  valueFormatter: ({ value, context }: ValueFormatterParams<unknown, string | undefined>) => {
    if (!value) {
      return '';
    }

    return context.current.exposureDefinitionsByName?.get(value)?.DisplayName ?? value;
  },
});
