import {
  EMPTY_ARRAY,
  type CustomerBalance,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';
import { useCallback, useMemo, useState } from 'react';
import type { LocalAgGridFilter } from './useDateAndQuickTextFilter';

interface UseBalancesFilterParams<TData extends CustomerBalance> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
  showZeroBalances: boolean;
}

export function useBalancesFilter<TData extends CustomerBalance>({
  persistedBlotterTable,
  showZeroBalances,
}: UseBalancesFilterParams<TData>) {
  const localFilter = useCallback<LocalAgGridFilter>(
    item => showZeroBalances || item.data.Amount !== '0',
    [showZeroBalances]
  );

  const [initialFilter] = useState(() => persistedBlotterTable.initialFilter ?? {});

  const clientSideFilter = useCallback<LocalAgGridFilter>(
    row => {
      if (!localFilter(row)) {
        return false;
      }
      return true;
    },
    [localFilter]
  );

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses: EMPTY_ARRAY,
      properties: EMPTY_ARRAY,
    }),
    []
  ) satisfies UseFilterBuilderProps;

  return useMemo(
    () => ({
      initialFilter,
      clientSideFilter,
      // shortcut to spread properties into useAccordionFilterBuilder.filterBuilderProps
      filterBuilderProps,
    }),
    [clientSideFilter, filterBuilderProps, initialFilter]
  );
}
