import type { ConnectionStatus } from '../contexts/ConnectionStatusContext';
import { ConnectionStatusEnum } from '../types/types';

const ConnectionMessage: { [K in keyof typeof ConnectionStatusEnum]: string } = {
  [ConnectionStatusEnum.Error]: 'Error',
  [ConnectionStatusEnum.Offline]: 'Offline',
  [ConnectionStatusEnum.Stale]: 'Stale',
  [ConnectionStatusEnum.Starting]: 'Starting',
  [ConnectionStatusEnum.Stopping]: 'Stopping',
  [ConnectionStatusEnum.Online]: 'Online',
  [ConnectionStatusEnum.Unavailable]: 'Not configured',
  [ConnectionStatusEnum.Disabled]: 'Not supported',
  [ConnectionStatusEnum.Keepalive]: 'Not supported',
  [ConnectionStatusEnum.NotInSession]: 'Not supported',
  [ConnectionStatusEnum.RateLimited]: 'Rate limited',
};
export const connectionStatusText = (status: ConnectionStatusEnum | undefined): string => {
  return status && status in ConnectionMessage
    ? ConnectionMessage[status]
    : ConnectionMessage[ConnectionStatusEnum.NotInSession];
};

export function isConnectionRecovering(status?: ConnectionStatus) {
  return status?.Text === 'Recovering';
}
