import { useMemo } from 'react';
import { Accordion, AccordionBody } from '../Accordion';
import { Box, HStack } from '../Core';
import { DEFAULT_LOOKBACK_OPTIONS, DateRangePicker } from '../DateRangePicker';
import { Divider } from '../Divider';
import { FilterBuilder, FilterBuilderClearAllButton, FilterBuilderToggleButton } from '../Filters/FilterBuilder';
import { FormControlSizes, Input, SearchSelect } from '../Form';
import { Icon, IconName } from '../Icons';
import { Portal } from '../Portal';

import type { CSSProperties } from 'styled-components';
import { useMixpanel } from '../../contexts/MixpanelContext';
import { useDynamicCallback } from '../../hooks/useDynamicCallback';
import { MixpanelEvent } from '../../tokens/mixpanel';
import { Tooltip } from '../Tooltip';
import type { BlotterTableFiltersProps } from './BlotterTableFilters.types';
import { BlotterTablePauseButton } from './BlotterTablePauseButton';

export const BLOTTER_TABLE_FILTERS_CONTAINER_ID = 'filtersContainer';

const getTimestampFieldLabel = (tsf: string) => (tsf === '' ? 'Default' : tsf);

export function BlotterTableFilters({
  quickFilterPrefix,
  prefix,
  suffix,

  accordionBodyPrefix,

  showQuickFilter = true,
  showQuickFilterOnly = false,
  quickFilterText,
  onQuickFilterTextChanged,

  showFilterBuilder = true,
  showDateRangePicker = true,
  dateRangePickerClearable = true,

  selectedTimestampField,
  timestampFields,
  dateRange,
  dateRangeShortcuts = DEFAULT_LOOKBACK_OPTIONS,
  onDateRangeChanged = () => {},
  onTimestampFieldChanged = () => {},

  filterBuilder,
  accordion,
  canSubmitClearedDateRange = false,
  portalId,

  pause,
  paused,
  resume,
  showPauseButton,
  size = FormControlSizes.Small,
}: BlotterTableFiltersProps) {
  const mixpanel = useMixpanel();

  const quickFilterInputWidth: CSSProperties['width'] = showQuickFilterOnly
    ? '100%'
    : size <= FormControlSizes.Small
    ? '120px'
    : '240px';

  const handleOnFilterTextChanged: React.ChangeEventHandler<HTMLInputElement> = useDynamicCallback(e => {
    mixpanel.track(MixpanelEvent.FilterQuickSearch);
    onQuickFilterTextChanged?.(e.target.value);
  });

  const handleOnDateRangeChanged: typeof onDateRangeChanged = useDynamicCallback((...args) => {
    mixpanel.track(MixpanelEvent.ChangeTimeFilter);
    onDateRangeChanged(...args);
  });

  const timestampFieldDescription = useMemo(() => {
    return (
      selectedTimestampField != null &&
      timestampFields?.find(timestampField => timestampField.Name === selectedTimestampField)?.Description
    );
  }, [selectedTimestampField, timestampFields]);

  const timestampFieldOptions = useMemo(() => {
    return timestampFields?.map(tsf => tsf.Name);
  }, [timestampFields]);

  const handleTimestampFieldChange = useDynamicCallback((newValue: string | undefined) => {
    if (newValue != null) {
      mixpanel.track(MixpanelEvent.FilterTimeTypeChanged);
      onTimestampFieldChanged(newValue);
    }
  });

  const dateRangePickerOptions = useMemo(() => {
    return dateRangeShortcuts.map(shortcut => ({
      value: shortcut,
    }));
  }, [dateRangeShortcuts]);

  // When using a RestBlotterTable, the pause and resume props are not available.
  // The type of the props is `Partial<BlotterTablePauseProps>`, so we need to check if they are defined.
  const hasPauseResumeProps = useMemo(() => pause != null && resume != null && paused != null, [pause, resume, paused]);

  return (
    <>
      <Portal portalId={portalId ?? BLOTTER_TABLE_FILTERS_CONTAINER_ID}>
        <HStack justifyContent="space-between" gap="spacingSmall">
          {quickFilterPrefix}
          {quickFilterPrefix && showQuickFilter && <Divider orientation="vertical" m="spacingSmall" />}
          {showQuickFilter && (
            <Input
              prefix={<Icon icon={IconName.Search} />}
              clearable={true}
              size={size}
              width={quickFilterInputWidth}
              value={quickFilterText}
              onChange={handleOnFilterTextChanged}
              data-testid="filter-search"
            />
          )}
          {showQuickFilter && prefix && <Divider orientation="vertical" m="spacingSmall" />}
          {prefix}
          {prefix && (showFilterBuilder || (showDateRangePicker && dateRange != null)) && (
            <Divider orientation="vertical" m="spacingSmall" />
          )}
          {showFilterBuilder && filterBuilder != null && accordion != null && (
            <>
              <FilterBuilderClearAllButton
                removeAllFilterClauses={filterBuilder.removeAllFilterClauses}
                disabled={filterBuilder.filterClauses.length === 0}
                size={size}
              />
              <FilterBuilderToggleButton
                filterClauses={filterBuilder.filterClauses}
                isOpen={accordion.isOpen}
                size={size}
                onClick={() => accordion.toggle()}
              />
            </>
          )}
          {showDateRangePicker && dateRange != null && (
            <>
              {timestampFieldOptions && timestampFieldOptions?.length > 0 && (
                <Tooltip tooltip={timestampFieldDescription} usePortal>
                  <SearchSelect
                    data-testid="timestamp-search"
                    selection={selectedTimestampField ?? ''}
                    options={timestampFieldOptions}
                    getLabel={getTimestampFieldLabel}
                    onChange={handleTimestampFieldChange}
                    size={size}
                    style={{ width: '125px' }}
                    showDropdownSearch={false}
                    portalize
                  />
                </Tooltip>
              )}
              <Box w="200px">
                <DateRangePicker
                  size={size}
                  onChange={handleOnDateRangeChanged}
                  value={dateRange}
                  shortcuts={dateRangePickerOptions}
                  clearable={dateRangePickerClearable}
                  canSubmitCleared={canSubmitClearedDateRange}
                />
              </Box>
            </>
          )}
          {showPauseButton && hasPauseResumeProps && (
            <>
              <Divider orientation="vertical" mx="spacingSmall" />
              <BlotterTablePauseButton pause={pause!} paused={paused!} resume={resume!} />
            </>
          )}
          {suffix && <Divider orientation="vertical" m="spacingSmall" />}
          {suffix}
        </HStack>
      </Portal>
      {showFilterBuilder && filterBuilder != null && accordion != null && (
        <Accordion {...accordion}>
          <AccordionBody p="0" px="spacingMedium">
            <HStack w="100%" justifyContent="flex-start" gap="spacingMedium">
              {accordionBodyPrefix && (
                <>
                  {accordionBodyPrefix}
                  <Divider orientation="vertical" />
                </>
              )}
              <Box py="spacingMedium">
                <FilterBuilder {...filterBuilder} />
              </Box>
            </HStack>
          </AccordionBody>
        </Accordion>
      )}
    </>
  );
}
