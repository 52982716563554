import styled from 'styled-components';
import type { ProcessStepSizes } from './types';

export const ProcessStepsWrapper = styled.div<{ size: ProcessStepSizes }>`
  display: grid;
  gap: ${({ theme, size }) => size * theme.baseSize * 4}px;
  grid-template-columns: repeat(auto-fit, minmax(${({ theme, size }) => size * theme.baseSize * 3}px, min-content));
  grid-auto-flow: column;
`;

export const StepWrapper = styled.div<{ size: ProcessStepSizes; disabled?: boolean; showLabel?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  color: ${({ theme }) => theme.colorTextImportant};
  transition: color 200ms ease;
  padding-bottom: ${({ size, theme, showLabel }) =>
    showLabel ? size * theme.fontSizeDefault * theme.baseSize + size * theme.spacingMedium : 0}px;

  &:hover {
    color: ${({ theme }) => theme.colorTextAttention};
  }
`;

export const StepLabel = styled.div<{ size: ProcessStepSizes; color: string }>`
  margin-top: ${({ size, theme }) => size * theme.spacingMedium}px;
  font-size: ${({ size, theme }) => size * theme.fontSizeDefault}rem;
  color: ${({ color }) => color};
  position: absolute;
  bottom: 0;
  white-space: nowrap;
`;

export const StepConnectionWrapper = styled.svg<{ size: ProcessStepSizes; width: number }>`
  position: absolute;
  transform: translate(-100%, ${({ theme, size }) => (size * theme.baseSize * 2.66667) / 2}px);
  top: 0;
  left: 0;
  width: ${({ width }) => width}px;
`;
