export const Logo = ({ fill }) => (
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M180.341 155.256V179.947H77.1138V155.256H70.0746V187H187.378V155.256M70.0746 70V101.744H77.1138V77.0534H180.341V101.669H187.378V70M168.723 140.425H160.852V115.425H168.723C177.038 115.425 182.857 120.706 182.857 127.926C182.857 135.092 177.038 140.425 168.723 140.425ZM169.001 108.484H150.985L152.261 111.588V147.366H168.723C182.025 147.366 192 139.311 192 127.758C192 116.591 182.467 108.477 169.001 108.477M119.581 131.981L114.98 127.146C111.432 130.482 109.492 133.204 109.492 137.872C109.492 143.981 115.536 148.706 123.405 148.706C128.726 148.706 133.106 147.202 136.487 144.593L140.699 148.926L146.076 144.314L141.143 139.261C142.973 136.038 143.968 132.203 143.968 128.036H136.597C136.597 129.814 136.319 131.815 135.709 133.759L123.683 121.536C122.409 120.205 121.687 118.704 121.687 117.371C121.687 115.541 123.015 114.427 125.125 114.427C127.34 114.427 128.838 115.649 128.838 117.373C128.838 118.429 128.228 119.598 127.008 120.652L131.22 125.206C134.602 122.597 136.153 119.596 136.153 116.818C136.153 112.264 132.054 108.486 124.847 108.486C118.478 108.486 114.152 112.096 114.152 117.375C114.152 120.209 115.704 123.489 118.585 126.379L131.722 139.764C129.782 141.32 127.179 142.322 123.685 142.322C118.585 142.322 116.424 139.654 116.424 137.487C116.424 135.377 117.366 133.543 119.583 131.987M92.0552 137.315C92.0552 140.371 89.1176 141.203 84.96 141.203C79.4726 141.203 74.6492 140.205 69.5518 138.369V146.034C73.8193 147.645 78.6967 148.704 85.016 148.704C94.6049 148.704 101.198 144.478 101.198 136.924C101.198 123.699 77.1449 126.09 77.1449 120.035C77.1449 117.2 80.6365 115.98 84.4061 115.98C89.7275 115.98 94.9369 117.311 99.0944 118.59V110.979C94.6049 109.424 89.7275 108.479 84.4061 108.479C75.3193 108.482 68 113.314 68 120.037C68 134.48 92.0552 131.145 92.0552 137.315Z"
      fill={fill}
    />
  </>
);
