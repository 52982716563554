import { Observable, type Subject, type Subscriber } from 'rxjs';
import type { MinimalSubscriptionResponse } from '../types/SubscriptionResponse';
import { UpdateActionEnum } from '../types/types';

/**
 * A testing util you can wrap your observables with to be notified when the observable's amount of subscribers changes
 * @param sourceObservable The observable you want to monitor
 * @param onChangeCallback A callback called with the new amount of subscribers on each change
 * @returns your observable you're passing in, just wrapped
 */
export function subscriberCount<T>(sourceObservable: Observable<T>, onChangeCallback: (subscribers: number) => void) {
  let counter = 0;
  return new Observable((subscriber: Subscriber<T>) => {
    const subscription = sourceObservable.subscribe(subscriber);
    counter++;
    onChangeCallback(counter);

    return () => {
      subscription.unsubscribe();
      counter--;
      onChangeCallback(counter);
    };
  });
}

export interface TestData {
  value: number;
  id: string;
  UpdateAction?: UpdateActionEnum;
}

export const data1: TestData[] = [
  {
    value: 10,
    id: '1',
  },
  {
    value: 10,
    id: '2',
  },
];

export const data2: TestData[] = [
  {
    value: 20, // overrides the value from data1
    id: '1',
  },
  {
    value: 10,
    id: '3',
  },
];

export const data3: TestData[] = [
  {
    id: '4',
    value: 40,
  },
];

export const combinedData1Data2: TestData[] = [
  {
    value: 20,
    id: '1',
  },
  {
    value: 10,
    id: '2',
  },
  {
    value: 10,
    id: '3',
  },
];

export const data1RemoveFirst: TestData[] = [
  {
    ...data1[0],
    UpdateAction: UpdateActionEnum.Remove,
  },
];

export const data1AfterRemove: TestData[] = [data1[1]];

export function wsMessage<T>(data: T[], initial = false, action?: UpdateActionEnum): MinimalSubscriptionResponse<T> {
  return {
    initial,
    type: 'TestData',
    action,
    data,
  };
}

/**
 * These test statements in here are reusable for any pipe which implements a scan-to-map-like behavior.
 * There are cases where we can't simply just reuse the scanToMap pipe and instead need to build it in into some other logic,
 * in which case having one set of tests to apply to all these implementations is very handy.
 */
export function reusableScanToMapTests(
  setupFunction: (
    onValueEmitted: (newMap: Map<string, any>) => void,
    ...whatever
  ) => { subject: Subject<MinimalSubscriptionResponse<any>> }
) {
  it('adds and updates entries', () => {
    let latestMap = new Map<string, TestItem>();
    const callback = (newMap: Map<string, TestItem>) => (latestMap = newMap);
    const { subject } = setupFunction(callback, item => item);

    const msg1 = wsMessage([item1, item2]);
    subject.next(msg1);

    expect(latestMap).toEqual(
      new Map([
        ['1', item1],
        ['2', item2],
      ])
    );

    // Send another message
    const msg2 = wsMessage([item3]);
    subject.next(msg2);
    expect(latestMap).toEqual(
      new Map([
        ['1', item1],
        ['2', item2],
        ['3', item3],
      ])
    );

    // Send a last message with an update instead of a pure addition
    const changedItem1: TestItem = { ...item1, value: 'changed' };
    const msg3 = wsMessage([changedItem1]);
    subject.next(msg3);

    expect(latestMap).toEqual(
      new Map([
        ['1', changedItem1],
        ['2', item2],
        ['3', item3],
      ])
    );
  });

  it('removes entries when UpdateAction is specified', () => {
    let latestMap = new Map<string, TestItem>();
    const callback = (newMap: Map<string, TestItem>) => (latestMap = newMap);
    const { subject } = setupFunction(callback, item => item);

    const msg1 = wsMessage([item1]);
    subject.next(msg1);

    expect(latestMap).toEqual(new Map([['1', item1]]));

    const msg2 = wsMessage([{ ...item1, UpdateAction: UpdateActionEnum.Remove }]);
    subject.next(msg2);
    expect(latestMap).toEqual(new Map());
  });

  it('removes entries when top-level json action is specified', () => {
    let latestMap = new Map<string, TestItem>();
    const callback = (newMap: Map<string, TestItem>) => (latestMap = newMap);
    const { subject } = setupFunction(callback, item => item);

    const msg1 = wsMessage([item1]);
    subject.next(msg1);

    expect(latestMap).toEqual(new Map([['1', item1]]));

    const msg2 = wsMessage([item1], false, UpdateActionEnum.Remove);
    subject.next(msg2);
    expect(latestMap).toEqual(new Map());
  });

  it('prioritizes entry UpdateAction over json.action', () => {
    // Say that there's a case (there shouldnt be) where there's a top-level attribute and a entry-level update action,
    // we want to use the entry-level one above the top-level one.
    // This shouldnt happen but just defining here in this test what the behavior is so we dont change it willy-nilly

    let latestMap = new Map<string, TestItem>();
    const callback = (newMap: Map<string, TestItem>) => (latestMap = newMap);
    const { subject } = setupFunction(callback, item => item);

    const msg1 = wsMessage([item1, item2]);
    subject.next(msg1);

    expect(latestMap).toEqual(
      new Map([
        ['1', item1],
        ['2', item2],
      ])
    );

    // Item 1 not removed, item 2 removed.
    const msg2 = wsMessage(
      [{ ...item1, UpdateAction: UpdateActionEnum.Update }, item2],
      false,
      UpdateActionEnum.Remove
    );
    subject.next(msg2);

    expect(latestMap).toEqual(new Map([['1', { ...item1, UpdateAction: UpdateActionEnum.Update }]]));
  });

  it('clears map on initial message', () => {
    let latestMap = new Map<string, TestItem>();
    const callback = (newMap: Map<string, TestItem>) => (latestMap = newMap);
    const { subject } = setupFunction(callback, item => item);

    const msg1 = wsMessage([item1, item2]);
    subject.next(msg1);

    expect(latestMap).toEqual(
      new Map([
        ['1', item1],
        ['2', item2],
      ])
    );

    subject.next(wsMessage([], true));
    expect(latestMap).toEqual(new Map());
  });
}

export interface TestItem {
  id: string;
  value: string;
  UpdateAction?: UpdateActionEnum;
  Revision?: number;
  Timestamp?: string;
}

const item1: TestItem = {
  id: '1',
  value: '1',
};

const item2: TestItem = {
  id: '2',
  value: '2',
};

const item3: TestItem = {
  id: '3',
  value: '3',
};
