import styled from 'styled-components';
import { Flex } from '../../Core';
import { Table, Th, Tr } from '../../Table';

export const Wrapper = styled(Flex)`
  padding: ${({ theme }) => theme.spacingDefault}px;
  flex-direction: column;
  overflow-y: auto;

  ${Th} {
    padding-right: ${({ theme }) => theme.spacingBig}px;
    border-right: 1px solid ${({ theme }) => theme.colors.gray['040']};
  }

  ${Tr} {
    border-top: 1px solid ${({ theme }) => theme.colors.gray['040']};
  }

  ${Table} {
    border: 1px solid ${({ theme }) => theme.colors.gray['040']};
  }
`;
