import { useCallback, useMemo, type ReactNode } from 'react';
import { useTheme } from 'styled-components';
import type { LoanQuoteStatusEnum, OrdStatusEnum, QuoteStatusEnum } from '../../../types/types';
import type { FormControlSizes } from '../../Form';
import { Icon, IconName } from '../../Icons';
import { ToggleButtonFilter } from './ToggleButtonFilter';
import { Filters, LOAN_QUOTE_STATUS_MAPPING, ORD_TEXT_TO_STATUS_MAPPING, QUOTE_STATUS_MAPPING } from './constants';
import { StatusToggleButtonContentWrapper } from './styles';

const filters = [Filters.NewRequest, Filters.Review, Filters.Open, Filters.Filled, Filters.Canceled, Filters.Rejected];

const getFilterLabel = (filter: Filters): string => filter;

type SupportedTypes = OrdStatusEnum | QuoteStatusEnum | LoanQuoteStatusEnum;
export type StatusToggleButtonFilterProps = {
  size?: FormControlSizes;
  statusMapping: 'OrdStatus' | 'QuoteStatus' | 'LoanQuoteStatus';
  selections: SupportedTypes[];
  onChange(statuses: SupportedTypes[]);
};

/**
 * This component wraps the ToggleButtonFilter component in order to encapsulate the logic around resolving
 * the mapping between a Filter and a OrdStatusEnum | QuoteStatusEnum
 */
export const StatusToggleButtonFilter = ({
  statusMapping,
  selections = [],
  size,
  onChange,
}: StatusToggleButtonFilterProps) => {
  const mapping =
    statusMapping === 'OrdStatus'
      ? ORD_TEXT_TO_STATUS_MAPPING
      : statusMapping === 'QuoteStatus'
      ? QUOTE_STATUS_MAPPING
      : LOAN_QUOTE_STATUS_MAPPING;

  // OrderStatusEnum[] -> Filter
  const selectedFilters: Filters[] = useMemo(() => {
    const selected: { [key: string]: boolean } = {};
    for (const [filter, statuses] of Object.entries(mapping)) {
      selected[filter] = Sugar.Array.intersect(selections, statuses).length > 0;
    }

    return filters.filter(filter => selected[filter]);
  }, [selections, mapping]);

  const availableFilters = useMemo(() => filters.filter(filter => mapping[filter]), [mapping]);

  const handleToggleChange = useCallback(
    (newSelections: Filters[]) => {
      onChange(newSelections.map(newSelection => mapping[newSelection]).flat());
    },
    [mapping, onChange]
  );

  const renderToggleButtonContent = (filter: Filters): ReactNode => {
    return (
      <StatusToggleButtonContentWrapper>
        <StatusToggleButtonFilterIcon filter={filter} />
        {getFilterLabel(filter)}
      </StatusToggleButtonContentWrapper>
    );
  };

  return (
    <ToggleButtonFilter
      size={size}
      options={availableFilters}
      selections={selectedFilters}
      getKey={getFilterLabel}
      onToggleChange={handleToggleChange}
      renderContent={renderToggleButtonContent}
    />
  );
};

function StatusToggleButtonFilterIcon({ filter }: { filter: Filters }) {
  const { colorTextPositive, colorTextPrimary, colorTextWarning, colorTextAttention } = useTheme();
  switch (filter) {
    case Filters.Canceled:
      return <Icon icon={IconName.MinusCircle} color={colorTextPrimary} />;
    case Filters.NewRequest:
      return <Icon icon={IconName.Circle} color={colorTextPositive} />;
    case Filters.Review:
      return <Icon icon={IconName.Circle} color={colorTextPrimary} />;
    case Filters.Rejected:
      return <Icon icon={IconName.ExclamationCircle} color={colorTextWarning} />;
    case Filters.Filled:
      return <Icon icon={IconName.CheckCircle} color={colorTextPositive} />;
    case Filters.OpenLoan:
      return <Icon icon={IconName.Dot} color={colorTextAttention} />;
    default:
      return <Icon icon={IconName.Circle} color={colorTextPrimary} />;
  }
}
