import { memo, type PropsWithChildren } from 'react';
import { useInView } from 'react-intersection-observer';

import { Wrapper } from './styles';
import type { MarketDataCardProps } from './types';

export const MarketDataCard = memo(function MarketDataCard({
  children,
  className,
  ErrorBoundary,
  isDragged,
  isExpanded,
  view,
}: PropsWithChildren<MarketDataCardProps>) {
  const [isVisibleRef, isVisible] = useInView();

  return (
    <Wrapper
      className={className}
      view={view}
      ref={isVisibleRef}
      expanded={isExpanded}
      isDragged={isDragged}
      data-testid="security-card"
    >
      {isVisible && <ErrorBoundary>{children}</ErrorBoundary>}
    </Wrapper>
  );
});
