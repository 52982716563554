// Note this is a subset of the Order Strategies that exist. The full set is driven by the backend.
export enum CustomerOrderStrategiesEnum {
  Limit = 'Limit',
  Market = 'Market',
  SteadyPace = 'SteadyPace',
  StopLimit = 'StopLimit',
  MultilegAggressiveLimit = 'MultilegAggressiveLimit',
  Manual = 'Manual',
  StopMarket = 'StopMarket',
}

export const DISABLED_REASONABILITY_CHECK_STRATEGIES_SET: Set<string> = new Set([
  CustomerOrderStrategiesEnum.SteadyPace,
]);
