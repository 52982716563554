import type { ReactNode } from 'react';
import { useTheme } from 'styled-components';
import { Dot, HaloDot, Wrapper, type IndicatorDotProps } from './styles';
export { IndicatorDotVariants } from './styles';

export function IndicatorDotWrapper({
  children,
  size,
  ...props
}: Omit<IndicatorDotProps, 'halo'> & { children: ReactNode }) {
  const defaultTheme = useTheme();
  const theme = props.theme ?? defaultTheme;
  const { indicatorDotSizeDefault } = theme;
  size = size ?? indicatorDotSizeDefault;
  return (
    <Wrapper size={size} theme={theme}>
      {children}
      <IndicatorDot size={size} {...props} />
    </Wrapper>
  );
}

export function IndicatorDot({ halo, size, ...props }: IndicatorDotProps) {
  const defaultTheme = useTheme();
  const theme = props.theme ?? defaultTheme;
  const { indicatorDotSizeDefault } = theme;
  size = size ?? indicatorDotSizeDefault;

  if (halo) {
    return <HaloDot {...props} size={size} theme={theme} />;
  }
  return <Dot {...props} size={size} theme={theme} />;
}
