import type { ExpectTrue } from '../tests';
import type { OptionalProperties } from '../utils';
import type { IMarketIndex, UpdateActionEnum } from './types';

type Optional = 'UpdateUser' | 'EndTime';
type Omitted = 'IndexID';
type _IMarketIndex = OptionalProperties<Omit<IMarketIndex, Omitted>, Optional> & { Index: string };

export class MarketFixingIndex {
  Index: string;
  Market: string;
  MarketSymbol: string;
  Revision: number;
  Timestamp: string;
  UpdateAction: UpdateActionEnum;
  UpdateUser?: string;
  EndTime?: string;

  constructor(marketFixingIndex: _IMarketIndex) {
    this.Timestamp = marketFixingIndex.Timestamp;
    this.Revision = marketFixingIndex.Revision;
    this.UpdateUser = marketFixingIndex.UpdateUser;
    this.UpdateAction = marketFixingIndex.UpdateAction;
    this.Index = marketFixingIndex.Index;
    this.Market = marketFixingIndex.Market;
    this.MarketSymbol = marketFixingIndex.MarketSymbol;
    this.EndTime = marketFixingIndex.EndTime;
  }
}

// TYPE LEVEL TESTS
type _Expect_FixingIndex_To_Only_Have_Keys_From_IIndex = ExpectTrue<
  {
    [K in keyof MarketFixingIndex & string]: K extends keyof _IMarketIndex ? true : K;
  }[Exclude<keyof MarketFixingIndex, never>]
>;
type _Expect_All_Keys_In_IMarketIndex_To_Be_In_MarketFixingIndex = ExpectTrue<
  {
    [K in keyof _IMarketIndex & string]: K extends keyof MarketFixingIndex ? true : K;
  }[Exclude<keyof _IMarketIndex, never>]
>;
