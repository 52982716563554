import { useTheme, type DefaultTheme } from 'styled-components';
import { ExposureStatusEnum } from '../../types/types';
import { Icon, IconName } from '../Icons';
import { IndicatorDotVariants, IndicatorDotWrapper } from '../IndicatorDot';
import { Wrapper } from './styles';

type ExposureStatusProps = {
  status: ExposureStatusEnum;
  align?: 'left' | 'right';
  text?: string;
  theme?: DefaultTheme;
};

const StatusIcon = ({ status, ...props }) => {
  const defaultTheme = useTheme();
  const theme = props.theme ?? defaultTheme;
  const { colorTextPositive, colorTextPrimary, colorTextWarning } = theme;
  switch (status) {
    case ExposureStatusEnum.Online:
      return <Icon icon={IconName.CheckCircleSolid} color={colorTextPositive} {...props} />;
    case ExposureStatusEnum.Offline:
      return <Icon icon={IconName.Circle} color={colorTextWarning} {...props} />;
    default:
      return <Icon icon={IconName.Circle} color={colorTextPrimary} {...props} />;
  }
};

export function ExposureStatus({ status, theme, align = 'right', text, ...props }: ExposureStatusProps) {
  return (
    <Wrapper iconPlacement="left" align={align} title={text} theme={theme} {...props}>
      {status}
      <IndicatorDotWrapper show={!!text} theme={theme} variant={IndicatorDotVariants.Warning}>
        <StatusIcon status={status} theme={theme} size="fontSizeLarge" />
      </IndicatorDotWrapper>
    </Wrapper>
  );
}
