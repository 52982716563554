import { hsl } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { mapDarkTheme } from './DarkTheme';

const colors: Colors = {
  black: {
    default: '#000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    main: '#000000',

    '000': '#121016',
    '010': hsl(204, 0, 0.05),
    '020': hsl(204, 0, 0.1),
    '030': hsl(204, 0, 0.15),
    '040': hsl(204, 0, 0.2),
    '050': hsl(204, 0, 0.23),
    '060': hsl(204, 0, 0.27),
    '070': hsl(204, 0, 0.34),
    '080': hsl(204, 0, 0.51),
    '090': hsl(210, 0, 0.75),
    '100': hsl(210, 0, 1),
  },

  primary: {
    mute: setAlpha(0.1, '#5186F6'),
    dim: setAlpha(0.4, '#5186F6'),
    default: '#4373d9',
    lighten: '#5186F6',
  },

  green: {
    mute: setAlpha(0.1, '#3ADCCA'),
    dim: setAlpha(0.4, '#3ADCCA'),
    default: '#2ab5a6',
    lighten: '#3adcca',
  },

  red: {
    mute: setAlpha(0.1, '#dc3a4c'),
    dim: setAlpha(0.4, '#dc3a4c'),
    default: '#b52a39',
    lighten: '#dc3a4c',
  },

  yellow: {
    mute: 'rgba(204, 172, 62, 0.1)',
    dim: 'rgba(204, 172, 62, 0.4)',
    default: '#CCAC3E',
    lighten: '#F2D264',
  },

  blue: {
    mute: 'rgba(69, 140, 193, 0.1)',
    dim: 'rgba(69, 140, 193, 0.4)',
    default: '#458CC1',
    lighten: '#53AFF8',
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: {
    default: '#8B4ED9',
    lighten: '#A45FFF',
  },
};

export const DarkAnchorageTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkAD',

  fontFamily: "'Akkurat Pro', sans-serif",
  fontFace: `@font-face {
    font-family: 'Akkurat Pro';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/customers/AkkuratPro-Regular.woff2) format('woff2');
  }`,

  backgroundCard: colors.gray.main,
  backgroundHeader: colors.gray.main,
  borderColorContent: colors.gray.main,
  borderColorHeader: colors.gray.main,
  backgroundSidebar: colors.gray.main,
  borderColorSidebar: colors.gray.main,

  backgroundSidebarItemActive: colors.gray['020'],
  backgroundSidebarItemHover: colors.gray['020'],

  borderColorDivider: colors.gray['000'],

  borderRadiusDefault: 5,
  borderRadiusSmall: 3,
  borderRadiusTiny: 3,
});
