import { desaturate } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { darkColors, mapDarkTheme } from './DarkTheme';

const colors: Colors = {
  black: {
    default: '#000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    main: desaturate(1, '#181A22'),

    '000': desaturate(1, '#01030C'),
    '010': desaturate(1, '#10121A'),
    '020': desaturate(1, '#20222A'),
    '030': desaturate(1, '#272930'),
    '040': desaturate(1, '#2F3038'),
    '050': desaturate(1, '#37383F'),
    '060': desaturate(1, '#414249'),
    '070': desaturate(1, '#53545A'),
    '080': desaturate(1, '#808186'),
    '090': desaturate(1, '#A3A4A7'),
    '100': desaturate(1, '#E5E5E6'),
  },

  primary: {
    mute: 'hsla(246.58, 100%, 50%, 0.1)',
    dim: 'hsla(246.58, 100%, 50%, 0.4)',
    default: 'hsl(246.58, 100%, 50%)',
    lighten: 'hsl(246.58, 100%, 50%)',
  },

  green: {
    mute: 'rgba(51, 126, 90, 0.1)',
    dim: 'rgba(51, 126, 90, 0.4)',
    default: '#337E5A',
    lighten: '#56AE84',
  },

  red: {
    mute: 'rgba(146, 46, 67, 0.1)',
    dim: 'rgba(146, 46, 67, 0.4)',
    default: '#922E43',
    lighten: '#D23557',
  },

  yellow: {
    mute: 'rgba(196, 160, 98, 0.1)',
    dim: 'rgba(196, 160, 98, 0.4)',
    default: '#c4a062',
    lighten: '#d2b789',
  },

  blue: {
    mute: 'rgba(69, 140, 193, 0.1)',
    dim: 'rgba(69, 140, 193, 0.4)',
    default: '#458CC1',
    lighten: '#53AFF8',
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: darkColors.purple,
};

export const DarkTDATheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkTDA',

  backgroundBody: colors.gray['010'],
  backgroundContent: colors.black.default,
  backgroundContentHover: colors.white.mute,
  backgroundSidebar: colors.black.default,
  borderColorSidebar: colors.black.default,

  borderColorContent: colors.black.default,
  backgroundHeader: colors.black.default,
  borderColorHeader: colors.black.default,

  borderColorDivider: colors.gray['010'],
});
