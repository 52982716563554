import type { ExportGridMode } from '../components';
import { formattedDate } from './date';

export function getFileExportFormattedDate(date = new Date()) {
  return formattedDate(date, '{yyyy}-{MM}-{dd} {HH}.{mm}.{ss}');
}

export const createCSVFileName = ({ name, tabLabel }: { name: string; tabLabel?: string }) => {
  return createExportFileName({ name, tabLabel, mode: 'CSV' });
};

/** Create a file name for exporting CSV/Excel data */
export const createExportFileName = ({
  name,
  tabLabel,
  mode,
}: {
  name: string;
  tabLabel?: string;
  mode: ExportGridMode;
}) => {
  const extension = mode === 'Excel' ? 'xlsx' : 'csv';
  if (!tabLabel) {
    return `${name}-${getFileExportFormattedDate()}.${extension}`;
  } else {
    return `${name}-${tabLabel.replace(/[^a-zA-Z0-9-]/g, '')}-${getFileExportFormattedDate()}.${extension}`;
  }
};
