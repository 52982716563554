import { useTheme, type DefaultTheme } from 'styled-components';

import type { LoanTransaction } from '../../types/LoanTransaction';
import { LoanSides } from '../../types/loanTypes';
import { LoanStatusEnum, LoanTransactionStatusEnum } from '../../types/types';
import { Icon, IconName } from '../Icons';
import { IndicatorDotVariants, IndicatorDotWrapper } from '../IndicatorDot';
import { Wrapper } from './styles';

export enum LoanStatusText {
  Open = 'Open',
  Closed = 'Closed',
  Error = 'Error',
  TransferDue = 'Transfer Due',
  CptyTransferDue = 'Cpty Transfer Due',
}

const getLoanStatusColor = (statusText: LoanStatusText | undefined, theme: DefaultTheme) => {
  const { colorTextPositive, colors, colorTextPrimary, colorTextWarning } = theme;
  switch (statusText) {
    case LoanStatusText.Open:
      return colorTextPositive;
    case LoanStatusText.TransferDue:
    case LoanStatusText.CptyTransferDue:
      return colors.blue.lighten;
    case LoanStatusText.Error:
      return colorTextWarning;
    default:
      return colorTextPrimary;
  }
};

export const getLoanStatusText = ({
  status,
  side,
  transactions,
  participant,
}: {
  participant: string;
  status: LoanStatusEnum;
  side?: LoanSides;
  transactions?: LoanTransaction[];
}) => {
  const borrowLoanStatusTextMapping: { readonly [key in LoanStatusEnum]: string } = {
    [LoanStatusEnum.Open]: LoanStatusText.Open,
    [LoanStatusEnum.Closed]: LoanStatusText.Closed,
    [LoanStatusEnum.Error]: LoanStatusText.Error,
  };

  const lendLoanStatusTextMapping: { readonly [key in LoanStatusEnum]: string } = {
    [LoanStatusEnum.Open]: LoanStatusText.Open,
    [LoanStatusEnum.Closed]: LoanStatusText.Closed,
    [LoanStatusEnum.Error]: LoanStatusText.Error,
  };

  const activeTransactions = transactions?.filter(t => t.Status === LoanTransactionStatusEnum.New);
  let loanStatusText: string;

  if (activeTransactions?.find(t => t.FromParticipant === participant)) {
    loanStatusText = LoanStatusText.TransferDue;
  } else if (activeTransactions?.find(t => t.ToParticipant === participant)) {
    loanStatusText = LoanStatusText.CptyTransferDue;
  } else if (side === LoanSides.Borrow) {
    loanStatusText = borrowLoanStatusTextMapping[status];
  } else if (side === LoanSides.Lend) {
    loanStatusText = lendLoanStatusTextMapping[status];
  } else {
    return LoanStatusText.Error;
  }

  return loanStatusText;
};

const getIcon = (status: LoanStatusText) => {
  switch (status) {
    case LoanStatusText.Open:
      return IconName.Circle;
    case LoanStatusText.TransferDue:
    case LoanStatusText.CptyTransferDue:
      return IconName.ArrowLeftRight;
    case LoanStatusText.Closed:
      return IconName.CheckCircleSolid;
    case LoanStatusText.Error:
      return IconName.ExclamationCircleSolid;
  }
};

const StatusIcon = ({ loanStatusText, ...props }) => {
  const defaultTheme = useTheme();
  const theme = props.theme ?? defaultTheme;
  const icon = getIcon(loanStatusText);
  const color = getLoanStatusColor(loanStatusText, useTheme() ?? theme);
  return <Icon icon={icon} color={color} {...props} />;
};

type LoanStatusProps = {
  status: LoanStatusEnum;
  side?: LoanSides;
  transactions?: LoanTransaction[];
  align?: 'right' | 'left';
  iconPlacement?: 'right' | 'left';
  text?: string;
  theme?: DefaultTheme;
  showLabel?: boolean;
  participant: string;
};

export function LoanStatus({
  status,
  side,
  align = 'right',
  showLabel,
  text,
  transactions,
  participant,
  ...props
}: LoanStatusProps) {
  const defaultTheme = useTheme();
  const theme = props.theme ?? defaultTheme;
  const loanStatusText = getLoanStatusText({ status, side, transactions, participant });

  return (
    <Wrapper align={align} title={text} theme={theme} {...props}>
      {!showLabel ? loanStatusText : null}
      <IndicatorDotWrapper show={!!text} theme={theme} variant={IndicatorDotVariants.Warning}>
        <StatusIcon loanStatusText={loanStatusText} theme={theme} size={14} />
      </IndicatorDotWrapper>
    </Wrapper>
  );
}
