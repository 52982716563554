import { memo, useEffect, useState } from 'react';

import { SymbolSelector as SymbolSelectorComponent } from './SymbolSelector';
import type { SymbolSelectorProps } from './types';

export { SymbolSelectorAppearance, type SymbolSelectorProps } from './types';

const MemoizedSymbolSelector = memo(SymbolSelectorComponent) as typeof SymbolSelectorComponent;

/**
 * Symbol Selection component customizing the internal SearchSelect component
 *
 * (Be careful not conflate the **SymbolSelector** with the **SearchSelect** component!)
 * - both are complex (and heavily used) but SymbolSelect is a symbol-based wrapper around the useSearchSelect hook
 */
export const SymbolSelector = (props: SymbolSelectorProps) => {
  const { portalize } = props;

  const [mountKey, setMountKey] = useState(0);
  useEffect(() => {
    // re-mount the SymbolSelector component for downshift to ensure proper portal recognization (not always an issue)
    if (portalize && !mountKey) {
      setMountKey(1);
    }
  }, [portalize, mountKey]);

  return <MemoizedSymbolSelector key={mountKey} {...props} />;
};
