import type Big from 'big.js';
import { createContext, useContext } from 'react';
import type { Observable } from 'rxjs';

export interface RefRate {
  currentPrice: Big | undefined;
  prevPrice: Big | undefined;
  isLoaded: boolean;
}
export interface RefRateContextProps {
  refRatesBySymbol: Observable<Map<string, RefRate | undefined>>;
  registerSubscription: (symbol: string) => void;
  registerSubscriptions: (symbols: string[]) => void;
  unregisterSubscription: (symbol: string, persistRate?: boolean) => void;
  unregisterSubscriptions: (symbols: string[]) => void;
}
export const RefRateContext = createContext<RefRateContextProps | undefined>(undefined);
RefRateContext.displayName = 'RefRateContext';

export function useRefRateContext() {
  const context = useContext(RefRateContext);
  if (context === undefined) {
    throw new Error('Missing RefRateContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}
