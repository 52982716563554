import { toBigWithDefault } from '../utils';
import type {
  HedgeControlStatusEnum,
  HedgeDecisionEnum,
  IHedgeOrderStatus,
  IStrategyParameters,
  SideEnum,
} from './types';

export class HedgeOrderStatus {
  Timestamp!: string;
  Revision!: number;
  InitiatingOrderID!: string;
  HedgeControlStatus!: HedgeControlStatusEnum;
  HedgeDecision?: HedgeDecisionEnum;
  HedgeSymbol!: string;
  OrderSymbol!: string;
  HedgeSide!: SideEnum;
  HedgeStrategy!: string;
  ActiveHedgeOrderID?: string;
  UnhedgedQty!: string;
  CumHedgeQty!: string;
  CumHedgeAmt!: string;
  AvgHedgePx!: string;
  AvgHedgePxAllIn!: string;
  CumHedgeFee!: string;
  FeeCurrency?: string;
  HedgeStrategyParams!: IStrategyParameters;
  Currency?: string;
  AmountCurrency?: string;
  LastDelta?: string;
  Text?: string;

  /** Front-end calculated UnhedgedQty + CumHedgeQty */
  get totalQty() {
    const unhedgedQtyBig = toBigWithDefault(this.UnhedgedQty, 0).abs(); // UI-5316, Currently, the UnhedgedQty number is always negative, which is causing issues in the calculation.
    const cumHedgeQtyBig = toBigWithDefault(this.CumHedgeQty, 0);
    return unhedgedQtyBig.plus(cumHedgeQtyBig).toFixed();
  }

  constructor(data: HedgeOrderStatus | IHedgeOrderStatus) {
    Object.assign(this, data);
  }
}
