import styled from 'styled-components';
import { ToggleButton } from '../../Button';

export const ToggleButtonFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacingSmall}px;

  ${ToggleButton} {
    flex: 1 0 auto;
  }
`;

export const StatusToggleButtonContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > i {
    margin-right: ${({ theme }) => theme.spacingSmall}px;
  }
`;
