import { useMemo, type PropsWithChildren } from 'react';
import { debounceTime, scan, shareReplay } from 'rxjs';
import type { RequestStream } from '../types/RequestStream';
import type { SubscriptionResponse } from '../types/SubscriptionResponse';
import { useConstant } from './useConstant';
import { useDynamicCallback } from './useDynamicCallback';
import { useNamedState } from './useNamedState';
import { useObservable } from './useObservable';
import { useSubscription } from './useSubscription';

export function useMultipleStreamsSubscription<
  TReturn,
  TKey,
  TRequest,
  TAllRequests extends RequestStream | RequestStream[] | null,
  TSubscriptionResponse
>({
  subscriptionCallback,
  getRequest,
  getAllRequests,
}: PropsWithChildren<{
  subscriptionCallback: (
    acc: Map<TKey, TReturn>,
    json: SubscriptionResponse<TSubscriptionResponse>
  ) => Map<TKey, TReturn>;
  getRequest: (key: TKey) => TRequest;
  getAllRequests: (requests: Map<TKey, TRequest>) => TAllRequests;
}>) {
  // Constant tracking latest value per key
  const [values] = useNamedState(new Map<TKey, TReturn>(), 'latest values');
  // Constant tracking active requests
  const [requests, setRequests] = useNamedState<TAllRequests | null>(null, 'requests');
  // Constant tracking requests per key
  const [requestMap] = useNamedState(new Map<TKey, TRequest>(), 'requestMap');

  // Tracks number of listeners per key
  const listeners = useConstant(new Map<TKey, number>());

  const { data: subscription } = useSubscription<SubscriptionResponse<TSubscriptionResponse>>(requests);

  const valueMapObservable = useObservable(
    () =>
      subscription?.pipe(
        scan(subscriptionCallback, values),
        debounceTime(100),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [subscription, subscriptionCallback, values]
  );

  const registerSubscription = useDynamicCallback((key: TKey) => {
    // Get listeners for this key
    const count = listeners.get(key);
    if (count) {
      // Increment listeners by 1 if there are existing listeners
      listeners.set(key, count + 1);
    } else {
      // Set listeners to 1 if there are no existing listeners
      listeners.set(key, 1);

      // Create a new request for this key
      const request: TRequest = getRequest(key);
      // Add new request to requestMap
      requestMap.set(key, request);
      // Update list of requests to include the latest addition
      setRequests(getAllRequests(requestMap));
    }
  });

  const registerSubscriptions = useDynamicCallback((keys: TKey[]) => keys.forEach(key => registerSubscription(key)));

  const unregisterSubscription = useDynamicCallback((key: TKey) => {
    // Get listeners for this key
    const count = (listeners.get(key) ?? 1) - 1;
    // Update the number of listeners for this key
    listeners.set(key, count);
    if (count <= 0) {
      listeners.delete(key);
      // Remove request from requestMap if there are no more listeners
      requestMap.delete(key);
      setRequests(getAllRequests(requestMap));
    }
  });

  const unregisterSubscriptions = useDynamicCallback((keys: TKey[]) =>
    keys.forEach(key => unregisterSubscription(key))
  );

  return useMemo(
    () => ({
      valueMapObservable,
      registerSubscription,
      registerSubscriptions,
      unregisterSubscription,
      unregisterSubscriptions,
    }),
    [valueMapObservable, registerSubscription, unregisterSubscription, registerSubscriptions, unregisterSubscriptions]
  );
}
