import { logger } from './logger';

export const tryParseJSON = (value: string, logFailure = true) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    if (logFailure) {
      logger.error(e as Error, { extra: { value } });
    }
    return value;
  }
};
