import { default as Big, type BigSource } from 'big.js';
import { InlineFormattedNumber, NumberVariants } from '../FormattedNumber';
import type { AgGridSizeProps } from './types';

export function AgGridSize(props: AgGridSizeProps) {
  const {
    sentiment,
    highlightNegative,
    value,
    round,
    context,
    getSentiment,
    inlineFormattedNumberAlignment,
    truncate,
    trimTrailingZeroes,
  } = props;
  if (value == null) {
    return <></>;
  }

  const { value: numericValue, currency, increment } = value;

  if (numericValue === undefined || numericValue === '') {
    return <></>;
  }

  const currencyInfo = context.current.currenciesBySymbol?.get(currency);

  let workingIncrement = increment;
  // If there was no increment passed in, for now we naively grab the DefaultIncrement off the currencyInfo
  if (workingIncrement == null && currencyInfo) {
    workingIncrement = currencyInfo.DefaultIncrement;
  }

  const variant =
    getSentiment != null ? getSentiment(props) : tryGetVariant(numericValue, sentiment, highlightNegative);

  return (
    <InlineFormattedNumber
      round={round}
      truncate={truncate}
      number={numericValue}
      increment={workingIncrement}
      currency={currency}
      variant={variant}
      align={inlineFormattedNumberAlignment}
      trimTrailingZeroes={trimTrailingZeroes}
      background="backgroundBlotterTableRowHover"
    />
  );
}

function tryGetVariant(
  numericValue: BigSource,
  sentiment: NumberVariants | undefined,
  highlightNegative: boolean | undefined
) {
  // Always return sentiment if specified
  if (sentiment != null) {
    return sentiment;
  }

  // If we want to highlightNegative values, try to parse the numeric value but default to undefined if fail or not lt(0)
  if (highlightNegative) {
    try {
      return Big(numericValue || 0).lt(0) ? NumberVariants.Negative : undefined;
    } catch (e) {
      return undefined;
    }
  }

  return undefined;
}
