import styled from 'styled-components';
import { formControlCSS, inputColor } from '../styles';
import type { FormControlProps } from '../types';
import { FormControlSizes, InputStates } from '../types';

export const SelectWrapper = styled.label<FormControlProps<HTMLSelectElement>>`
  ${formControlCSS};
  padding-right: ${({ theme }) => theme.spacingBig}px;
  background: ${({ theme, disabled, invalid, touched, readOnly }) =>
    disabled
      ? theme.backgroundSelectDisabled
      : readOnly
      ? theme.backgroundSelectReadOnly
      : invalid && (touched == null || touched)
      ? theme.backgroundSelectInvalid
      : theme.backgroundSelect};

  &:after {
    pointer-events: none;
    content: ' ';
    position: absolute;
    right: ${({ size = FormControlSizes.Default }) => (size / 2) * 0.5}rem;
    top: 0;
    bottom: 0;
    width: ${({ size = FormControlSizes.Default }) => (size / 2) * 0.5}rem;
    display: block;
    ${({ disabled, theme }) =>
      `background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'  viewBox='0 0 8 5'%3E%3Cpath fill='${encodeURIComponent(
        disabled ? theme.colorTextSubtle : theme.colorTextDefault
      )}' fillRule='nonzero' d='M1 0L0 1l4 4 4-4-1-1-3 3z'/%3E%3C/svg%3E") no-repeat center center;`}
  }

  > select {
    margin-right: -${({ theme }) => theme.spacingBig}px;
    padding-right: ${({ theme }) => theme.spacingBig}px;
  }

  select {
    optgroup,
    option {
      background-color: ${({ theme }) => theme.backgroundSelectDropdown};
      ${({ theme, disabled, variant }) => inputColor(theme, variant, InputStates.Default, disabled)};
    }
    optgroup {
      font-weight: ${({ theme }) => theme.fontWeightBold};
    }
  }
`;
