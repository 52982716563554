import { transparentize } from 'polished';
import styled from 'styled-components';
import { Box } from '../Core';

export const HighchartsWrapper = styled(Box)`
  .highcharts-navigator-handle {
    cursor: col-resize !important;
  }
`;
HighchartsWrapper.defaultProps = {
  h: '100%',
  position: 'relative',
  overflow: 'hidden',
};

export const ChartBlockingLoadingOverlay = styled(Box)<{ showOverlay?: boolean }>`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  background-color: ${({ theme, showOverlay }) => (showOverlay ? transparentize(0.3, theme.colors.gray.main) : '')};
`;
