import { createContext, useContext } from 'react';
import type { Observable } from 'rxjs';
import type { ConnectionType } from '../types/ConnectionType';
import type { Market } from '../types/Market';
import type { MarketTypeEnum } from '../types/types';

export const MarketsContext = createContext<MarketsContextProps | undefined>(undefined);
MarketsContext.displayName = 'MarketsContext';

export function useMarketsContext(): MarketsContextProps {
  const context = useContext(MarketsContext);
  if (context === undefined) {
    throw new Error('Missing MarketsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export interface MarketsContextProps {
  isLoaded: boolean;
  /**
   * Array of all markets in the system, sorted by DisplayName
   */
  marketsList: Market[];
  marketsByName: Map<string, Market>;
  marketDisplayNameByName: Map<string, string>;
  marketTypeByName: Map<string, MarketTypeEnum>;
  marketsWithConnTypesConfigured: (connectionTypes: ConnectionType[]) => Observable<Market[]>;
  /**
   * Returns only those markets which have at least one of the provided connection types configured.
   *
   * @param connectionTypes - Only markets with at least one of these connection types supported will be returned (default: All connection types defined in {@link ConnectionType | ConnectionType})
   * @returns Observable markets array, sorted by DisplayName
   */
  marketsWithConnTypesSupported: (connectionTypes?: ConnectionType[]) => Observable<Market[]>;
  isMarketSupported: (m: Market | string, connectionType: ConnectionType) => boolean;
  isMarketConfigured: (m: Market | string, connectionType: ConnectionType) => boolean;
  isMarketOnline: (m: Market | string, connectionType: ConnectionType) => boolean;
  isMarketOffline: (m: Market | string, connectionType: ConnectionType) => boolean;
}
