import { useTheme, type DefaultTheme } from 'styled-components';

import { CustomerAddressStatusEnum } from '../../types/types';
import { Icon, IconName } from '../Icons';
import { IndicatorDotVariants, IndicatorDotWrapper } from '../IndicatorDot';
import { Wrapper } from './styles';

type AddressStatusProps = {
  status: CustomerAddressStatusEnum;
  align?: 'left' | 'right';
  text?: string;
  theme?: DefaultTheme;
};

const StatusIcon = ({ status, ...props }) => {
  const defaultTheme = useTheme();
  const theme = props.theme ?? defaultTheme;
  const { colorTextPositive, colorTextWarning } = theme;
  switch (status) {
    case CustomerAddressStatusEnum.Pending:
      return <Icon icon={IconName.Circle} color={colorTextWarning} {...props} />;
    case CustomerAddressStatusEnum.Confirmed:
      return <Icon icon={IconName.CheckCircleSolid} color={colorTextPositive} {...props} />;
    default:
      return <Icon icon={IconName.Circle} color={theme.colors.blue.lighten} {...props} />;
  }
};

const getFriendlyAddressStatus = (status: CustomerAddressStatusEnum) => {
  switch (status) {
    case CustomerAddressStatusEnum.Pending:
      return 'Pending';
    case CustomerAddressStatusEnum.Confirmed:
      return 'Confirmed';
    default:
      return 'Unknown status';
  }
};

export function AddressStatus({ status, theme, align = 'right', text, ...props }: AddressStatusProps) {
  return (
    <Wrapper iconPlacement="left" align={align} title={text} theme={theme} {...props}>
      {getFriendlyAddressStatus(status)}
      <IndicatorDotWrapper show={!!text} theme={theme} variant={IndicatorDotVariants.Warning}>
        <StatusIcon status={status} theme={theme} size="fontSizeLarge" />
      </IndicatorDotWrapper>
    </Wrapper>
  );
}
