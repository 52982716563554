import type { CSSProperties } from 'react';
import { useConstant, useJsonModal, useSecurity } from '../../hooks';
import type { Security } from '../../types';
import { IconButton } from '../Button';
import type { BoxProps } from '../Core';
import { VStack } from '../Core';
import { CurrencyIcon } from '../CurrencyIcon';
import { FormControlSizes } from '../Form';
import { IconName } from '../Icons';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';
import { UnifiedLiquidityTooltip } from '../UnifiedLiquidity';
import { SymbolDisplayWrapper } from './styles';

export enum SymbolDisplaySize {
  Small,
  Default,
}

export interface SymbolDisplayProps {
  symbol?: string;
  showDescription?: boolean;
  size?: SymbolDisplaySize;
  underlyingSymbols?: string[];
  showJSON?: boolean;
}

export const SymbolDisplay = ({
  symbol,
  showDescription = true,
  size,
  underlyingSymbols,
  showJSON = false,
  ...props
}: SymbolDisplayProps & BoxProps) => {
  const security = useSecurity(symbol);
  const { handleClickJson, jsonModal } = useJsonModal<Security>();
  const showUnifiedLiquidity = !!underlyingSymbols?.length;
  const targetStyle: CSSProperties = useConstant({ float: 'right' });

  return (
    <Tooltip
      usePortal
      disabled={!showUnifiedLiquidity}
      tooltip={<UnifiedLiquidityTooltip security={security} symbols={underlyingSymbols} />}
      targetStyle={targetStyle}
    >
      <SymbolDisplayWrapper {...props} gap={size === SymbolDisplaySize.Small ? 'spacingSmall' : 'spacingDefault'}>
        <CurrencyIcon currency={security?.BaseCurrency} size={size === SymbolDisplaySize.Small ? 12 : 24} />
        <VStack gap="spacingTiny" alignItems="flex-start" style={{ lineHeight: 1 }}>
          <Text color="colorTextImportant">
            {security?.DisplaySymbol ?? symbol}
            {showUnifiedLiquidity ? '+' : ''}{' '}
          </Text>
          {showDescription && (
            <Text size="fontSizeTiny" color="colorTextDefault">
              {security?.Description}
            </Text>
          )}
        </VStack>
        {showJSON && (
          <IconButton
            icon={IconName.Braces}
            ghost={true}
            onClick={() => handleClickJson(security)}
            size={FormControlSizes.Small}
          />
        )}
        {jsonModal}
      </SymbolDisplayWrapper>
    </Tooltip>
  );
};
