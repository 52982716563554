import { compact, uniq } from 'lodash';
import { useMemo } from 'react';
import { useSecuritiesContext } from '../../contexts';
import { useUnifiedLiquidityAvailableSymbols, type UnifiedLiquidityParameter } from '../../hooks';
import { ProductTypeEnum, type Security } from '../../types';
import { isMultileg } from '../../utils/security';
import { VStack } from '../Core';
import { Text } from '../Text';

interface UnifiedLiquidityTooltipProps {
  security: Security | undefined;
  symbols: string[] | undefined;
  availableSymbols?: string[];
}

export const UnifiedLiquidityTooltip = ({ security, symbols, availableSymbols }: UnifiedLiquidityTooltipProps) => {
  const { securitiesBySymbol } = useSecuritiesContext();

  const displayStr = useMemo(
    () => getDisplayStr(security, securitiesBySymbol, symbols, availableSymbols),
    [security, symbols, availableSymbols, securitiesBySymbol]
  );

  if (!symbols) {
    return null;
  }

  return (
    <VStack alignItems="flex-start" gap="spacingTiny">
      <Text color="colorTextSubtle" size="fontSizeSm">
        Unified Liquidity
      </Text>
      <Text color="colorTextImportant" size="fontSizeMd">
        {displayStr}
      </Text>
    </VStack>
  );
};

export const UnifiedLiquidityDescriptiveTooltip = ({
  security,
  markets,
  marketAccounts,
  unifiedLiquidityParameter,
}: {
  security: Security;
  markets: string[];
  marketAccounts: string[];
  unifiedLiquidityParameter: UnifiedLiquidityParameter;
}) => {
  const unifiedLiquidityAvailableSymbols = useUnifiedLiquidityAvailableSymbols({
    markets,
    marketAccounts,
    symbol: security.Symbol,
  });
  const { securitiesBySymbol } = useSecuritiesContext();

  const displayStr = useMemo(
    () => getDisplayStr(security, securitiesBySymbol, unifiedLiquidityAvailableSymbols),
    [security, unifiedLiquidityAvailableSymbols, securitiesBySymbol]
  );
  return (
    <>
      {unifiedLiquidityParameter.Description}
      {unifiedLiquidityAvailableSymbols.length > 0 && (
        <>
          <br />
          Included symbols: <Text color="colorTextAttention">{displayStr}</Text>
        </>
      )}
    </>
  );
};

function getDisplayStr(
  security: Security | undefined,
  securitiesBySymbol,
  symbols: string[] | undefined,
  availableSymbols?: string[]
): string {
  if (security?.ProductType === ProductTypeEnum.PerpetualSwap || isMultileg(security)) {
    return uniq(
      compact(
        (availableSymbols ?? symbols ?? []).sort().map(symbol => {
          return securitiesBySymbol.get(symbol)?.DisplaySymbol;
        })
      )
    ).join(', ');
  } else {
    return symbols?.join(', ') ?? '';
  }
}
