import styled, { css } from 'styled-components';
import { Flex, Grid } from '../Core';
import stripe from './stripe.svg';

const stripesCss = css`
  background: ${`url(${stripe})`};
  margin: ${({ theme }) => theme.spacingSmall}px 0;
`;

export const Content = styled(Flex)<{ color: string }>`
  ${({ theme }) => css`
    gap: ${theme.spacingSmall}px;
    padding: ${theme.spacingSmall}px;
    height: 100%;
    font-size: ${theme.fontSizeSm}rem;
    line-height: ${theme.lineHeightSm}rem;
    justify-content: center;
    align-items: center;
  `}
`;

export const AlertBannerWrapper = styled(Grid)<{ color: string }>`
  position: absolute;
  inset: 0;
  background: ${({ color }) => color};
  background: linear-gradient(
    90deg,
    ${({ color }) => color} 0%,
    ${({ color }) => color} max(10px, 1%),
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 0) 90%,
    ${({ color }) => color} min(calc(100% - 10px), 99%),
    ${({ color }) => color} 100%
  );
  pointer-events: none;
`;

export const LeftStripes = styled.div`
  ${stripesCss};
  background-position: right;
  margin-left: ${({ theme }) => theme.spacingComfortable}px 0;
`;

export const RightStripes = styled.div`
  ${stripesCss};
  background-position: left;
  margin-right: ${({ theme }) => theme.spacingComfortable}px 0;
`;
