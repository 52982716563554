import {
  BaseStyle,
  Button,
  ButtonVariants,
  FormControlSizes,
  GlobalStyle,
  NavButton,
  WLOrgConfigProvider,
  hashThemeRunTime,
  themes,
  useDisclosure,
  useWLOrgConfigContext,
} from '@talos/kyoko';
import { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { ErrorBoundaryWrapper } from '../../components/ErrorBoundary/styles';
import { Logo } from '../../components/Logo';
import { SupportModal } from '../../components/SupportModal';
import { LoginWrapper } from './styles';

export const Login = () => {
  return (
    <WLOrgConfigProvider>
      <LoginContent />
    </WLOrgConfigProvider>
  );
};

const LoginContent = () => {
  const { config, isLoaded } = useWLOrgConfigContext();
  const modalState = useDisclosure();

  useEffect(() => {
    if (isLoaded) {
      const { fontUrl } = themes[config.theme];
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = fontUrl;
      document.head.appendChild(link);
    }
  }, [config, isLoaded]);

  if (!isLoaded) {
    return null;
  }

  const theme = themes[config.theme ?? hashThemeRunTime('DarkTheme')];
  const { type } = theme;

  const handleOpenModal = () => {
    modalState.open();
  };

  return (
    <ThemeProvider theme={theme}>
      <BaseStyle />
      <GlobalStyle />
      <ErrorBoundaryWrapper>
        <LoginWrapper>
          <Logo themeType={type} key={type} width="100%" />
          <p>
            You have been logged out.
            <br />
            Click to log back in again
          </p>
          <NavButton size={FormControlSizes.Large} to="/" variant={ButtonVariants.Primary}>
            Login
          </NavButton>
          <p>Are you having trouble logging in?</p>
          <Button onClick={handleOpenModal}>Contact support</Button>
        </LoginWrapper>
        <SupportModal {...modalState} />
      </ErrorBoundaryWrapper>
    </ThemeProvider>
  );
};
