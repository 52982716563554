import type { PropsWithChildren, ReactNode } from 'react';
import { map } from 'rxjs';
import styled, { css, useTheme } from 'styled-components';
import { useObservableValue } from '../../hooks/useObservable';
import { useResizeObservable } from '../../hooks/useResizeObservable';
import type { RequiredProperties } from '../../utils';
import { Box, Flex, HStack } from '../Core';
import { DEFAULT_MODAL_MARGIN } from '../Modal/styles';

type PreviewViewProps = PropsWithChildren<{
  disclosure?: { close: () => void; isOpen: boolean };
  header?: ReactNode;
  footer?: ReactNode;
}>;

export function PreviewView({
  children,
  disclosure,
  header,
  footer,
}: RequiredProperties<PreviewViewProps, 'disclosure'>) {
  const theme = useTheme();
  const { elementRef, sizeObservable } = useResizeObservable<HTMLDivElement>();

  // Keep track of the height of the box, such that we can animate it in and out
  const boxHeight = useObservableValue(
    () => sizeObservable.pipe(map(size => size.contentRect.height)),
    [sizeObservable],
    0
  );

  return (
    <>
      <Overlay
        zIndex={4}
        showPreview={disclosure.isOpen}
        onClick={() => {
          disclosure.close();
        }}
      />
      <PreviewContainer zIndex={5} boxHeight={boxHeight} showPreview={disclosure.isOpen}>
        <Flex flexDirection="column" ref={elementRef}>
          {header && (
            <HStack
              borderBottom={`1px solid ${theme.colors.gray['040']}`}
              textTransform="uppercase"
              fontSize="fontSizeSmall"
              color="colorTextSubtle"
              p="spacingDefault"
            >
              {header}
            </HStack>
          )}
          <Box>{children}</Box>
          {footer && (
            <Box borderTop={`1px solid ${theme.colors.gray['040']}`} p="spacingMedium">
              {footer}
            </Box>
          )}
        </Flex>
      </PreviewContainer>
    </>
  );
}

const Overlay = styled(Box)<{ showPreview: boolean }>`
  inset: 0;
  background: ${({ theme }) => theme.colors.gray['010']};
  position: absolute;
  transition: opacity 0.4s;

  ${({ showPreview }) => css`
    --expanded-opacity: 0.8;
    --hidden-opacity: 0;
    ${showPreview
      ? css`
          opacity: var(--expanded-opacity);
        `
      : css`
          opacity: var(--hidden-opacity);
        `}
    pointer-events: ${showPreview ? 'auto' : 'none'};
  `}
`;

const PreviewContainer = styled(Box)<{ boxHeight?: number; showPreview: boolean }>`
  position: absolute;
  inset: 0;
  background: ${({ theme }) => theme.backgroundModal};

  transition: transform 0.3s ease-in-out;

  ${({ boxHeight, showPreview, theme }) => css`
    --expanded-transform: calc(100% - ${boxHeight}px);
    --hidden-transform: calc(100% + ${theme[DEFAULT_MODAL_MARGIN]}px);
    transform: translateY(var(--hidden-transform));

    ${showPreview &&
    css`
      transform: translateY(var(--expanded-transform));
      box-shadow: 0px -6px 8px 0px ${theme.colors.gray['010']};
    `}
  `}
`;

export const PreviewWrapperBox = styled(Box)`
  position: relative;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.gray['030']};
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
`;
