export const Logo = ({ fill }) => (
  <>
    <path d="M146.819 88.943V78H135.7V100.062H176.481V88.943H146.819Z" fill={fill} />
    <path
      d="M124.546 100.027V78H113.392V88.9078H96.7489C87.4948 88.9078 80.0001 96.4025 80.0001 105.657C79.9649 114.911 87.4948 122.441 96.7489 122.441H165.327V133.559H96.7489C87.4948 133.559 80.0001 141.089 80.0001 150.343C80.0001 159.597 87.4948 167.092 96.7489 167.092H113.392V178H124.546V155.973C124.546 155.973 96.7489 156.008 96.5026 155.973C89.2894 155.375 89.4653 145.03 96.7841 144.749H176.481V111.322H96.7841C89.4653 111.04 89.2894 100.73 96.5026 100.132C96.7489 100.027 124.546 100.027 124.546 100.027Z"
      fill={fill}
    />
    <path d="M135.7 177.93H146.819V166.987H176.481V155.868H135.7V177.93Z" fill={fill} />
  </>
);
