export const MEDIA = {
  small: 768,
  default: 1024,
  medium: 1440,
  large: 1680,
  big: 1920,
};

export const minWidth = (width: number) => `@media screen and (min-width: ${width}px)`;
export const maxWidth = (width: number) => `@media screen and (max-width: ${width}px)`;
export const only = (from: number, to: number) => `@media screen and (min-width: ${from}px) and (max-width: ${to}px)`;
