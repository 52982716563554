import { isArray } from 'lodash';
import { createContext, useContext, useMemo } from 'react';
import { map, shareReplay } from 'rxjs/operators';
import { useObservable, useObservableValue } from '../hooks/useObservable';
import { useStaticSubscription } from '../hooks/useStaticSubscription';
import { wsScanToMap } from '../pipes';
import { MARKET_ACCOUNT } from '../tokens';
import { type IMarketAccount, MarketAccountStatusEnum } from '../types';
import type { CustomerMarketAccount } from '../types/CustomerMarketAccount';
import { DEFAULT_CUSTOMER_MARKET_ACCOUNT } from '../utils/customerMarketAccounts';

// The Market Accounts values from this provider only include Active Market Accounts
interface WLCustomerMarketAccountContextProps {
  customerMarketAccountsBySourceAccountID: Map<string, CustomerMarketAccount> | undefined;
  customerMarketAccountsList: CustomerMarketAccount[] | undefined;
  isLoaded: boolean;
}

export const WLCustomerMarketAccountContext = createContext<WLCustomerMarketAccountContextProps | undefined>(undefined);

export function useWLCustomerMarketAccountContext() {
  const context = useContext(WLCustomerMarketAccountContext);
  if (context === undefined) {
    throw new Error(
      'Missing WLCustomerMarketAccountContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return context;
}

export const WLCustomerMarketAccountsProvider = ({ children }) => {
  const { data: subscription } = useStaticSubscription<IMarketAccount>({
    name: MARKET_ACCOUNT,
    tag: 'WLCustomerMarketAccountsProvider',
  });

  // Convert the Market Account to a Customer Market Account
  // SourceData is a JSON string, so we need to parse it
  const convertToCustomerMarketAccount = (account: IMarketAccount): CustomerMarketAccount => ({
    ...account,
    SourceData: account.SourceData ? JSON.parse(account.SourceData) : undefined,
  });

  const customerMarketAccountsBySourceAccountIDObs = useObservable(
    () =>
      subscription.pipe(
        wsScanToMap({
          getUniqueKey: (account: IMarketAccount) => account.SourceAccountID,
          newMapEachUpdate: true, // Ensures that a new map is created with each update
          shouldDelete: account => account.Status !== MarketAccountStatusEnum.Active, // addition
          getInsertable: account => convertToCustomerMarketAccount(account),
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [subscription]
  );

  const customerMarketAccountsListObs = useObservable(
    () =>
      customerMarketAccountsBySourceAccountIDObs.pipe(
        map(map => [...map.values()]),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [customerMarketAccountsBySourceAccountIDObs]
  );

  const customerMarketAccountsBySourceAccountID = useObservableValue(
    () => customerMarketAccountsBySourceAccountIDObs,
    [customerMarketAccountsBySourceAccountIDObs]
  );
  const customerMarketAccountsList = useObservableValue(
    () => customerMarketAccountsListObs,
    [customerMarketAccountsListObs]
  );

  const value = useMemo(
    () => ({
      customerMarketAccountsBySourceAccountID,
      customerMarketAccountsList,
      isLoaded: isArray(customerMarketAccountsList),
    }),
    [customerMarketAccountsBySourceAccountID, customerMarketAccountsList]
  );

  return <WLCustomerMarketAccountContext.Provider value={value}>{children}</WLCustomerMarketAccountContext.Provider>;
};

/**
 * Hook to get sorted market accounts, sorted by shared logic.
 *
 * @param marketAccounts Array of market accounts
 * @returns sorted Array of market accounts
 */
export const useWLSortedMarketAccounts = (marketAccounts): CustomerMarketAccount[] | undefined => {
  return useMemo(() => {
    if (!marketAccounts) {
      return undefined;
    }

    return marketAccounts.sort((a, b) => {
      if (a.SourceAccountID === DEFAULT_CUSTOMER_MARKET_ACCOUNT) {
        return -1;
      } else if (b.SourceAccountID === DEFAULT_CUSTOMER_MARKET_ACCOUNT) {
        return 1;
      } else if (a.SourceAccountID && b.SourceAccountID) {
        return a.SourceAccountID.localeCompare(b.SourceAccountID);
      } else {
        return b.MarketAccountID - a.MarketAccountID;
      }
    });
  }, [marketAccounts]);
};
