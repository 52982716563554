import type { DefaultTheme } from 'styled-components';
import { LightGriffoTheme } from './LightGriffoTheme';

/** @deprecated */
export const LightGriffoDevTheme: DefaultTheme = {
  ...LightGriffoTheme,
  name: 'LightGODev',
  spacingLayout: 16,
  boxShadowContent: '0 2px 16px rgba(0, 0, 0, 0.05)',
  boxShadowHeader: '0 2px 16px rgba(0, 0, 0, 0.1)',
};
