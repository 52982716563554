import { hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { DarkTheme, darkColors, mapDarkTheme } from './DarkTheme';
import { getInputStylesAppliedToSelect } from './utils';

const colors: Colors = {
  black: {
    default: '#000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    main: '#1b1b1b',

    '000': '#161616',
    '010': hsla(200, 0, 0.11, 1),
    '020': hsla(200, 0, 0.14, 1),
    '030': hsla(200, 0, 0.16, 1),
    '040': hsla(200, 0, 0.2, 1),
    '050': '#353535',
    '060': '#4E4E4E',
    '070': '#A1A1A1',
    '080': '#A1A1A1',
    '090': '#D1D1D1',
    '100': '#F3F3F3',
  },

  primary: {
    mute: setAlpha(0.1, '#A3EC26'),
    dim: setAlpha(0.4, '#A3EC26'),
    default: '#92D422',
    lighten: '#A3EC26',
  },

  green: {
    mute: setAlpha(0.1, '#26c964'),
    dim: setAlpha(0.4, '#26c964'),
    default: '#08970E',
    lighten: '#26c964',
  },

  red: {
    mute: setAlpha(0.1, '#FF5757'),
    dim: setAlpha(0.4, '#FF5757'),
    default: '#C7232A',
    lighten: '#FF5757',
  },

  yellow: {
    mute: setAlpha(0.1, '#F3D900'),
    dim: setAlpha(0.4, '#F3D900'),
    default: '#F3D900',
    lighten: '#F3D900',
  },

  blue: {
    mute: setAlpha(0.1, '#268ec9'),
    dim: setAlpha(0.4, '#268ec9'),
    default: '#227fb4',
    lighten: '#268ec9',
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: darkColors.purple,
};

export const DarkWintermuteTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkWM',

  // fontFamily: "'Manrope', sans-serif",
  // fontUrl:
  //   'https://fonts.googleapis.com/css2?family=Manrope:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',

  spacingLayout: 8,

  backgroundBody: colors.gray['000'],

  backgroundHeader: setAlpha(0.75, colors.gray.main),
  borderColorHeader: 'transparent',

  backgroundContent: setAlpha(0.75, colors.gray.main),

  backgroundChart: 'rgba(0, 0, 0, 0)',

  backgroundInput: colors.gray['000'],

  ...getInputStylesAppliedToSelect(DarkTheme),
  backgroundSelect: colors.gray['000'],
  backgroundSelectHover: colors.gray['000'],
  backgroundSelectFocus: colors.gray['000'],
  backgroundSelectInvalid: colors.gray['000'],
  backgroundSelectDisabled: colors.gray['000'],
  backgroundSelectReadOnly: colors.gray['000'],

  backgroundPositiveButton: setAlpha(0.6, colors.green.lighten),
  backgroundPositiveButtonHover: setAlpha(0.7, colors.green.lighten),
  backgroundPositiveButtonFocus: setAlpha(0.7, colors.green.lighten),
  backgroundPositiveButtonActive: setAlpha(0.6, colors.green.default),
  backgroundPositiveButtonDisabled: setAlpha(0.3, colors.green.default),

  backgroundNegativeButton: setAlpha(0.5, colors.red.lighten),
  backgroundNegativeButtonHover: setAlpha(0.6, colors.red.lighten),
  backgroundNegativeButtonFocus: setAlpha(0.6, colors.red.lighten),
  backgroundNegativeButtonActive: setAlpha(0.5, colors.red.default),
  backgroundNegativeButtonDisabled: setAlpha(0.25, colors.red.default),

  backgroundDefaultButton: 'transparent',
  backgroundDefaultButtonHover: 'transparent',
  backgroundDefaultButtonFocus: 'transparent',
  backgroundDefaultButtonActive: 'transparent',
  backgroundDefaultButtonDisabled: 'transparent',

  backgroundPriorityButton: setAlpha(0.08, colors.gray['100']),
  backgroundPriorityButtonHover: setAlpha(0.12, colors.gray['100']),
  backgroundPriorityButtonFocus: setAlpha(0.12, colors.gray['100']),
  backgroundPriorityButtonActive: setAlpha(0.08, colors.gray['100']),
  backgroundPriorityButtonDisabled: setAlpha(0.04, colors.gray['100']),

  borderWidthButton: 1,
  borderColorDefaultButton: colors.gray['060'],
  borderColorDefaultButtonHover: colors.gray['060'],
  borderColorDefaultButtonFocus: colors.gray['060'],
  borderColorDefaultButtonActive: colors.gray['060'],
  borderColorDefaultButtonDisabled: colors.gray['060'],

  colorTextPrimaryButton: colors.black.default,
  colorTextPrimaryButtonHover: colors.black.default,
  colorTextPrimaryButtonFocus: colors.black.default,
  colorTextPrimaryButtonActive: colors.black.default,
  colorTextPrimaryButtonDisabled: setAlpha(0.5, colors.black.default),

  colorTextPositiveButton: colors.black.default,
  colorTextPositiveButtonHover: colors.black.default,
  colorTextPositiveButtonFocus: colors.black.default,
  colorTextPositiveButtonActive: colors.black.default,
  colorTextPositiveButtonDisabled: setAlpha(0.5, colors.black.default),

  colorTextNegativeButton: colors.black.default,
  colorTextNegativeButtonHover: colors.black.default,
  colorTextNegativeButtonFocus: colors.black.default,
  colorTextNegativeButtonActive: colors.black.default,
  colorTextNegativeButtonDisabled: setAlpha(0.5, colors.black.default),

  backgroundVolumeLadderBidSpread: setAlpha(0.6, colors.blue.lighten),
  backgroundVolumeLadderOfferSpread: setAlpha(0.6, colors.blue.lighten),

  backgroundToggle: colors.gray['000'],
});
