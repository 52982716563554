import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { lightColors, mapLightTheme } from './LightTheme';

const colors: Colors = {
  black: lightColors.black,
  white: lightColors.white,
  gray: lightColors.gray,

  primary: {
    mute: setAlpha(0.1, '#00818f'),
    dim: setAlpha(0.4, '#00818f'),
    default: '#00818f',
    lighten: '#009dad',
  },

  green: lightColors.green,
  red: lightColors.red,

  yellow: {
    mute: setAlpha(0.1, '#F2D264'),
    dim: setAlpha(0.4, '#F2D264'),
    default: '#F2D264',
    lighten: '#CCAC3E',
  },

  blue: lightColors.blue,
  orange: lightColors.orange,
  purple: lightColors.purple,
};

export const LightXeroFTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightXF',
});
