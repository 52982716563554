import styled from 'styled-components';
import { Button } from '../Button';
import { ButtonVariants, type ButtonProps } from '../styles';

export type ToggleButtonProps = {
  selected: boolean;
  selectedVariant?: ButtonVariants;
  unselectedVariant?: ButtonVariants;
} & ButtonProps;

export const ToggleButton = styled(
  ({
    selected,
    selectedVariant = ButtonVariants.Priority,
    unselectedVariant = ButtonVariants.Default,
    ...props
  }: ToggleButtonProps) => {
    return (
      <Button
        data-selected={selected}
        {...props}
        variant={selected ? selectedVariant : unselectedVariant}
        aria-pressed={selected}
      />
    );
  }
)``;
