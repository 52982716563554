import { useEffect, useMemo, useState, type RefObject } from 'react';
import { useMultiSelectAutocomplete } from '../../../Form/MultiSelect';
import { useMultiSelectSelectionManager } from '../../../Form/MultiSelect/useMultiSelectSelectionManager';
import type { FilterableSelectProperty, PropertyRefs, UseFilterBuilderRefsOutput } from '../types';
export const MAX_VISIBLE_SELECTIONS_PER_PROPERTY = 4;

type UsePropertySelectionInputs<T = string> = {
  property: FilterableSelectProperty<string, T>;
  selections: T[];
  onSelectionsChange: (newSelections: T[]) => void;
  inputRef?: RefObject<HTMLInputElement>;
  refs: PropertyRefs<T>;
} & Pick<UseFilterBuilderRefsOutput<T>, 'updateSelectionRefs'>;

/**
 * This hook abstracts away a lot of the complexities native to the RHS of the FilterBuilder
 * and lets you implement your own RHS renderer component easily
 */
export const usePropertyMultiSelect = <T,>({
  property,
  selections,
  refs,
  updateSelectionRefs,
  onSelectionsChange,
  inputRef,
}: UsePropertySelectionInputs<T>) => {
  const { getOptionLabel } = property;

  // this reference element will be switched between the currently selected button. Its the dropdown's anchor point
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(refs.empty.current ?? null);
  const {
    combinedCurrentSelections,
    dropdownItems,
    isSelectionDisabled,
    addUnconfirmedAdditions,
    handleSelectionsChange,
    handleOpenChange,
  } = useMultiSelectSelectionManager<T>({ property, selections, onSelectionsChange });

  // the currently visible selections for the filter
  const visibleSelections = useMemo(() => {
    return combinedCurrentSelections.slice(0, MAX_VISIBLE_SELECTIONS_PER_PROPERTY - 1);
  }, [combinedCurrentSelections]);

  // whenever the visible selections change, we update the refs
  useEffect(() => {
    updateSelectionRefs(property.key, visibleSelections);
  }, [property, updateSelectionRefs, visibleSelections]);

  // this useeffect keeps the reference element set correctly (as in, the anchor point for the dropdown)
  useEffect(() => {
    if (selections.length === 0) {
      setReferenceElement(refs.empty.current);
    } else if (visibleSelections.length === refs.selections.size && referenceElement?.id === 'no-selections') {
      // the current anchor point is the noSelectionsRef, but we have >0 selections, so set anchor point to last selection.
      const lastSelectionRef =
        selections.length > visibleSelections.length
          ? refs.tail.current
          : refs.selections.get(selections[selections.length - 1])!.current;
      setReferenceElement(lastSelectionRef);
    }
  }, [referenceElement?.id, refs, selections, visibleSelections.length]);

  const { autocompleteOutput, multipleSelectionOutput } = useMultiSelectAutocomplete<T>({
    initialIsOpen: selections.length === 0, // start open if we're brand new
    selections: selections,
    items: dropdownItems,
    initialSortByLabel: false,
    getLabel: getOptionLabel,
    getDescription: property.getOptionDescription,
    getGroup: property.getOptionGroup,
    groupSorter: property.groupSorter,
    onChange: handleSelectionsChange,
    onIsOpenChange: handleOpenChange,
    clearInputAfterSelection: false,
    removeItemOnInputBackspace: false,
    highlightInputTextAfterSelection: true,
    inputRef,
    matchThreshold: property.matchThreshold,
  });

  return {
    referenceElement,
    setReferenceElement,
    dropdownItems,
    visibleSelections,
    isSelectionDisabled,
    addUnconfirmedAdditions,
    tailLength: combinedCurrentSelections.length - visibleSelections.length,
    autocompleteOutput,
    multipleSelectionOutput,
  };
};
