import { camelCase, isObject } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useAggregationsContext, useStrategiesContext } from '../../contexts';
import { DEFAULT_AGGREGATION_DISPLAY_NAME } from '../../tokens';
import { ParameterTypeEnum } from '../../types/OrderStrategy';
import type { IStrategyParameterEnum } from '../../types/types';
import {
  EMPTY_ARRAY,
  format,
  formattedDate,
  prettyName,
  prettyPriceProtection,
  prettyTimeUnit,
  toBig,
} from '../../utils';
import {
  canParseAsDuration,
  DateTimeDurationPickerValueType,
  formatDuration,
  isDateTimeDurationPickerValue,
} from '../DateTimeDurationPicker';
import { Text } from '../Text';

export interface ParameterRow {
  title: string;
  value: string;
}

export const useParameterRows = (
  orderStrategy?: string,
  orderParameters?: { [key: string]: any },
  showEmptyParams = false,
  excludedParams: string[] = EMPTY_ARRAY
): ParameterRow[] => {
  const { strategiesByName } = useStrategiesContext();

  const { aggregationsByName } = useAggregationsContext();

  const getAggregationDisplayNameByName = useCallback(
    (name: string) => {
      if (name == null || name === '') {
        return DEFAULT_AGGREGATION_DISPLAY_NAME;
      } else {
        return aggregationsByName?.get(name)?.DisplayName;
      }
    },
    [aggregationsByName]
  );

  const rows = useMemo(() => {
    if (!orderStrategy || !strategiesByName) {
      return [];
    }

    const strategy = strategiesByName.get(orderStrategy);
    if (!strategy) {
      return [];
    }

    return strategy.Parameters.map(parameter => {
      if (orderParameters == null) {
        return null;
      }
      if (excludedParams.includes(parameter.Type)) {
        return null;
      }
      const title = parameter.DisplayName;
      let value: any = getParamFromOrderParams(orderParameters, parameter.Name);
      if (isObject(value) && !isDateTimeDurationPickerValue(value)) {
        return null;
      }
      if (parameter.Type === ParameterTypeEnum.Date) {
        if (isDateTimeDurationPickerValue(value)) {
          value =
            value.type === DateTimeDurationPickerValueType.Duration
              ? formatDuration(value.value)
              : formattedDate(value.value);
        } else {
          value = canParseAsDuration(value) ? value : formattedDate(value);
        }
      } else if (parameter.Type === ParameterTypeEnum.Aggregation) {
        value = prettyName(getAggregationDisplayNameByName(value));
      } else if (parameter.Type === ParameterTypeEnum.String) {
        value = prettyName(value);
      } else if (parameter.Type === ParameterTypeEnum.Percent) {
        const valueBig = toBig(value);
        value = valueBig ? `${valueBig.times(100).toFixed()}%` : undefined;
      } else if (parameter.Type === ParameterTypeEnum.Enum) {
        // Prettify enum with its Name.
        const enumValues = parameter.EnumValues as IStrategyParameterEnum[];
        value = enumValues.find(enumValue => value === enumValue.Index)?.Name ?? value;
      } else if (parameter.Type === ParameterTypeEnum.PriceProtection) {
        value = prettyPriceProtection(value);
        return { title, value };
      } else if (parameter.Name === 'MaxImbalanceAmt') {
        value = `${format(value, { pretty: true, removeTrailingZeros: true })} ${
          strategy.Parameters.find(p => p.Name === 'MaxImbalanceAmtCurrency' || p.Name === 'MaxImbalanceAmtCurrencyID')
            ?.DefaultValue || ''
        }`;
      } else if (
        [ParameterTypeEnum.Price, ParameterTypeEnum.PriceOffset, ParameterTypeEnum.Qty].includes(parameter.Type)
      ) {
        value = format(value, { pretty: true, removeTrailingZeros: true });
      } else if (parameter.Type === ParameterTypeEnum.Interval) {
        const unit = (value ?? '').at(-1);
        const number = (value ?? '').slice(0, -1);
        value = `${number} ${prettyTimeUnit(unit)}`;
      }
      if (value) {
        return { title, value };
      }
      if (showEmptyParams) {
        value = <Text color="colorTextSubtle">None</Text>;
        return { title, value };
      }
      return null;
    }).compact();
  }, [
    strategiesByName,
    getAggregationDisplayNameByName,
    showEmptyParams,
    orderStrategy,
    orderParameters,
    excludedParams,
  ]);

  return rows;
};

/**
 * Just solves the case where we might receive both lowercased and uppercased parameters within the order (if we're getting older implementations
 * using the Data model)
 * @param orderParams
 * @param paramName
 * @returns either the parameter value, or undefined otherwise.
 */
function getParamFromOrderParams(orderParams: { [key: string]: any }, paramName: string): any | undefined {
  let param = orderParams[paramName];
  if (!param) {
    // try again with lowercased paramName
    param = orderParams[camelCase(paramName)];
  }

  return param ?? undefined;
}
