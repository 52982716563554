import { createContext, useContext } from 'react';
import { of, type Observable } from 'rxjs';
import { useObservableValue } from '../hooks/useObservable';
import type { Balance } from '../types/Balance';

export const BalancesContext = createContext<BalancesContextProps>({
  listBalances: of([]),
  balancesByCurrencyMarketAccount: of(new Map()),
  balancesByMarketAccountCurrency: of(new Map()),
});

export const useBalancesContext = () => {
  return useContext(BalancesContext);
};

/**
 * Returns an observable of a mapping currency -> market accounts -> Balances
 */
export const useBalancesByCurrencyMarketAccount = () => {
  const { balancesByCurrencyMarketAccount } = useBalancesContext();
  return useObservableValue(() => balancesByCurrencyMarketAccount, [balancesByCurrencyMarketAccount]);
};

/**
 * Returns an observable of a mapping market account -> currencies -> Balances
 */
export const useBalancesByMarketAccountCurrency = () => {
  const { balancesByMarketAccountCurrency } = useBalancesContext();
  return useObservableValue(() => balancesByMarketAccountCurrency, [balancesByMarketAccountCurrency]);
};

type BalancesContextProps = {
  listBalances: Observable<Balance[]>;
  balancesByCurrencyMarketAccount: Observable<Map<string, Map<string, Balance>>>;
  balancesByMarketAccountCurrency: Observable<Map<string, Map<string, Balance>>>;
};
