import { useCallback, useRef, useState, type ReactNode } from 'react';
import { useMixpanel } from '../../contexts';
import { useDynamicCallback } from '../../hooks';
import { useGlobalToasts } from '../../providers/GlobalToastsProvider';
import type { MixpanelEvent } from '../../tokens';
import { ButtonVariants, type ButtonProps } from '../Button';
import { FormControlSizes } from '../Form/types';
import { IconName } from '../Icons';
import { NotificationVariants } from '../Notification';
import type { ToastProps } from '../Toast';
import { CopyButtonWrapperButton, FadingIcon, IconsContainer } from './styles';

const COPY_TIMEOUT = 1500;

/**
 * Type must either include value or onCopy.
 */
type ConditionalProps =
  | {
      /**
       * Value to be copied. Can either be the value itself, or a callback to be called at the time of copying
       * which then returns the value to be copied.
       */
      value: string | number | AnyObject | (() => string | number | AnyObject);
      onClick?: never;
    }
  | {
      value?: never;
      /**
       * Custom copying function which will be triggered onClick. No copying is done by component if custom
       * onCopy is specified
       */
      onClick: () => void;
    };

type CopyButtonProps = {
  /**
   * Tooltip rendered on hover
   */
  children?: ReactNode;
  iconPlacement?: 'left' | 'right';
  size?: FormControlSizes;
  mixpanelEvent?: MixpanelEvent;
  /**
   * If specified, a toast with specified props will be triggered when copying.
   */
  toastProps?: ToastProps;
} & Omit<ButtonProps, 'value'> &
  ConditionalProps;

export function CopyButton(props: CopyButtonProps) {
  const {
    value,
    onClick: _onClick,
    children,
    iconPlacement = 'right',
    size = FormControlSizes.Tiny,
    toastProps,
    mixpanelEvent,
    variant = ButtonVariants.Default,
    ...rest
  } = props;

  const mixpanel = useMixpanel();
  const onCopy = useDynamicCallback(() => _onClick?.());
  const { add } = useGlobalToasts();
  const copyToClipBoard = useCallback((copiedValue: string) => {
    // In secure contexts, clipboard is defined always
    navigator.clipboard.writeText(copiedValue);
  }, []);
  const [copied, setCopied] = useState(false);
  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const handleCopy = useCallback(() => {
    setCopied(true);
    if (mixpanelEvent) {
      mixpanel.track(mixpanelEvent);
    }
    if (toastProps) {
      add({ variant: NotificationVariants.Positive, timeout: COPY_TIMEOUT, ...toastProps });
    }
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = setTimeout(() => {
      setCopied(false);
    }, COPY_TIMEOUT);

    if (!value) {
      return onCopy();
    }

    const computedValue = typeof value === 'function' ? value() : value;

    if (typeof computedValue === 'object') {
      copyToClipBoard(JSON.stringify(computedValue));
    } else {
      copyToClipBoard(computedValue.toString());
    }
  }, [mixpanel, mixpanelEvent, value, toastProps, onCopy, copyToClipBoard, add]);

  const checkmarkColor = variant === ButtonVariants.Default ? 'green.lighten' : undefined;

  const onlyIcon = children == null;

  return (
    <CopyButtonWrapperButton variant={variant} size={size} onClick={handleCopy} data-testid="copy-button" {...rest}>
      {iconPlacement === 'right' && children}
      <IconsContainer mx={onlyIcon ? '-spacingTiny' : 0}>
        <FadingIcon show={copied} icon={IconName.Check} color={checkmarkColor} />
        <FadingIcon show={!copied} icon={IconName.ClipboardCopy} />
      </IconsContainer>
      {iconPlacement === 'left' && children}
    </CopyButtonWrapperButton>
  );
}
