import { useEffect, useState } from 'react';
import { concat, from, map, of, scan, type Observable } from 'rxjs';

import { useEndpointsContext } from '../contexts';
import type { OHLCData } from '../types/OHLCData';
import type { ResolutionEnumNoPrefix } from '../types/OrderAnalytic';
import { formattedDateForSubscription, Get, urlFromLocalOrAbsolute } from '../utils';
import { useSubscription } from './useSubscription';

interface useOHLCDataProps {
  symbol: string | undefined;
  markets?: string[];
  startDate: Date | undefined;
  endDate: Date | undefined;
  resolution: ResolutionEnumNoPrefix;
  liveUpdates?: boolean;
}

export const useOHLCData = ({
  symbol,
  markets,
  startDate,
  endDate,
  resolution,
  liveUpdates = true,
}: useOHLCDataProps) => {
  const { apiEndpoint } = useEndpointsContext();
  const [request, setRequest] = useState<any>();
  const [observable, setObservable] = useState<Observable<OHLCData[]>>(of([]));
  const { data: sub } = useSubscription(request);

  const sd = formattedDateForSubscription(startDate);
  const ed = formattedDateForSubscription(endDate);

  useEffect(() => {
    if (sd == null || ed == null || symbol == null || resolution == null) {
      return;
    }
    const marketsPart = markets && markets.length > 0 ? `/markets/${encodeURIComponent(markets.join(','))}` : '';

    if (liveUpdates) {
      setRequest({
        name: 'OHLCV',
        tag: `OHLCV-${symbol}-${resolution}`,
        Symbol: symbol,
        Markets: markets,
        Resolution: resolution,
      });
    }

    setObservable(
      concat(
        from(
          Get(
            urlFromLocalOrAbsolute(apiEndpoint),
            `/symbols/${symbol}${marketsPart}/ohlcv/${resolution}?startDate=${sd}&endDate=${ed}`
          )
        ),
        sub
      ).pipe(
        map(res => res.data),
        scan((prices: OHLCData[], data: OHLCData[]) => {
          data.forEach(newPrice => {
            const lastPrice = prices.at(-1);
            const lastPriceTimestamp = lastPrice ? Date.parse(lastPrice.Timestamp) : 0;
            const newPriceTimestamp = Date.parse(newPrice.Timestamp);
            if (lastPriceTimestamp === newPriceTimestamp) {
              prices.pop();
            }
            if (lastPriceTimestamp <= newPriceTimestamp) {
              prices.push(newPrice);
            }
          });
          return prices;
        })
      )
    );
  }, [symbol, sd, ed, resolution, sub, apiEndpoint, liveUpdates, markets]);

  return observable;
};
