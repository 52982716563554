import { isNumber } from 'lodash';
import type { CSSProperties, ForwardedRef, InputHTMLAttributes, ReactNode } from 'react';

export enum InputVariants {
  Default = 'Default',
  Primary = 'Primary',
  Positive = 'Positive',
  Negative = 'Negative',
  Warning = 'Warning',
}

export enum InputStates {
  Default = '',
  Hover = 'Hover',
  Focus = 'Focus',
}

export enum FormControlSizes {
  Xxs = 0.75, // Mostly the same as Tiny (same font size, line height) except with less vertical padding
  Tiny = 1,
  Small = 1.5,
  Default = 2,
  Large = 2.5,
}

export const FORM_CONTROL_SIZES = Object.values(FormControlSizes).filter(isNumber);

export type ValueTypes = 'text' | 'number';
export type FormControlProps<T> = Omit<InputHTMLAttributes<T>, 'prefix'> & {
  size?: FormControlSizes;
  width?: CSSProperties['width'];
  inline?: boolean;
  prefix?: ReactNode;
  suffix?: ReactNode;
  type?: ValueTypes;
  touched?: boolean;
  invalid?: boolean;
  wrapperRef?: ForwardedRef<HTMLLabelElement>;
  controlStyle?: any;
  clearable?: boolean;
  variant?: InputVariants;
};
