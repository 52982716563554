import Big from 'big.js';
import { maxBy } from 'lodash';
import type { MarketDataSnapshot } from '../../types';
import type { StepData } from './types';

export const formatMarketData = (marketData: MarketDataSnapshot, sizeBuckets: string[]): StepData => {
  const bids = marketData.Bids;
  const offers = marketData.Offers;

  // Calculate midMarketPrice for first level with positive spread
  const midMarketPrice = sizeBuckets.reduce((res, size, index) => {
    const bid = bids[index];
    const offer = offers[index];
    if (res === '' && offer != null && bid != null && Big(offer.Price).minus(bid.Price).gt(0)) {
      return Big(bid?.Price ?? '0')
        .add(offer?.Price ?? '0')
        .div(2)
        .toFixed();
    }
    return res;
  }, '');

  // Calculates spread in abs and BPS as well as on bid and offer side using distance from midMarketPrice
  const steps = sizeBuckets.map((bucket: string, index: number) => {
    const bid = Big(bids[index]?.Price ?? 0);
    const offer = Big(offers[index]?.Price ?? 0);
    if (bid.gt(0) && offer.gt(0)) {
      const spread = offer.minus(bid);
      const spreadBps = spread.div(offer.plus(bid).div(2)).times(10_000).toFixed();

      const bidSpread = Big(midMarketPrice || '0').gt(0)
        ? Big(midMarketPrice).minus(bid).div(midMarketPrice).toFixed()
        : undefined;

      const offerSpread = Big(midMarketPrice || '0').gt(0)
        ? offer.minus(midMarketPrice).div(midMarketPrice).toFixed()
        : undefined;

      return {
        bid: bid.toFixed(),
        offer: offer.toFixed(),
        size: bids[index]?.Size ?? offers[index]?.Size,
        spread: spread.toFixed(),
        spreadBps,
        bidSpread,
        offerSpread,
      };
    } else {
      if (bid.gt(0)) {
        return {
          size: bids[index]?.Size ?? bucket,
          bid: bid.toFixed(),
        };
      }
      if (offer.gt(0)) {
        return {
          size: offers[index]?.Size ?? bucket,
          offer: offer.toFixed(),
        };
      }

      return {
        size: bucket,
      };
    }
  });

  // Find step with largest spread
  const maxSpreadStep = maxBy(steps, step => {
    const bid = Big(step.bidSpread ?? '0');
    const offer = Big(step.offerSpread ?? '0');
    return bid.gte(offer) ? bid.toNumber() : offer.toNumber();
  });

  return {
    steps,
    symbol: marketData.Symbol,
    status: marketData.Status,
    maxSpreadBps: maxSpreadStep?.spreadBps,
    midMarketPrice,
  };
};

export function validateBuckets(sizeBuckets: string[], errors: boolean[], index?: number) {
  if (index != null) {
    const bucket = sizeBuckets[index];
    errors[index] = validateBucket(sizeBuckets, index, bucket);
    return errors;
  } else {
    return sizeBuckets.map((bucket, index) => {
      return validateBucket(sizeBuckets, index, bucket);
    });
  }
}

export function validateBucket(sizeBuckets: string[], index: number, bucket: string) {
  const prevBucket = sizeBuckets[index - 1];
  const nextBucket = sizeBuckets[index + 1];
  const error =
    (prevBucket != null && Big(prevBucket || 0).gte(bucket || 0)) ||
    (nextBucket != null && Big(nextBucket || 0).lte(bucket || 0));
  return error;
}

export const DEFAULT_BUCKETS = [
  '0.0001',
  '0.0002',
  '0.0005',
  '0.001',
  '0.002',
  '0.005',
  '0.01',
  '0.02',
  '0.05',
  '0.1',
  '0.2',
  '0.5',
  '1',
  '2',
  '5',
  '10',
  '20',
  '50',
  '100',
  '200',
  '500',
  '1000',
  '2000',
  '5000',
  '10000',
  '20000',
  '50000',
  '100000',
  '200000',
  '500000',
  '1000000',
  '2000000',
  '5000000',
  '10000000',
  '20000000',
  '50000000',
  '100000000',
  '200000000',
  '500000000',
  '1000000000',
  '2000000000',
  '5000000000',
];
