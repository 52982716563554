import type { Security } from '../types/Security';
import { ProductTypeEnum, SettleValueTypeEnum } from '../types/types';
import { isCounterCurrency, toBigWithDefault } from '../utils';
import { useCurrencyConversionRateValue } from './useCurrencyConversionRate';

/**
 * Calculates the total estimated notional amount for an order given the quantity and the limit price entered by the user.
 */
export const useEstimatedAmount = ({
  OrderQty,
  Currency,
  LimitPrice,
  security,
  marketRate,
}: {
  OrderQty?: string;
  Currency?: string;
  LimitPrice?: string;
  security?: Security;
  marketRate?: string;
}) => {
  const { BaseCurrency, QuoteCurrency, NotionalMultiplier, ProductType } = security || {};
  const usingCounterCurrency = isCounterCurrency(Currency, security);
  let conversionCurrency = Currency;
  let amtCurrency = usingCounterCurrency ? BaseCurrency : QuoteCurrency;
  if (!Currency || Currency === '') {
    // For contracts use conversion rate of security.Symbol and amtCurrency based on SettleValueType
    conversionCurrency = security?.Symbol;
    amtCurrency = security?.SettleValueType === SettleValueTypeEnum.Inverted ? BaseCurrency : QuoteCurrency;
  }
  const { Rate: conversionRate } = useCurrencyConversionRateValue(conversionCurrency, amtCurrency) ?? {};

  if (!security) {
    return null;
  }
  let estimatedAmount: string | null = null;
  const limitPriceBig = toBigWithDefault(LimitPrice, 0);
  const orderQtyBig = toBigWithDefault(OrderQty, 0);
  const marketRateBig = toBigWithDefault(marketRate, 0);
  const conversionRateBig = toBigWithDefault(conversionRate, 0);
  if (orderQtyBig.lte(0)) {
    return null;
  }

  switch (ProductType) {
    case ProductTypeEnum.Spot:
      if (limitPriceBig.gt(0)) {
        estimatedAmount = usingCounterCurrency
          ? orderQtyBig
              .div(NotionalMultiplier || 1)
              .div(limitPriceBig)
              .toFixed()
          : orderQtyBig
              .times(NotionalMultiplier || 1)
              .times(limitPriceBig)
              .toFixed();
      } else if (marketRateBig.gt(0)) {
        estimatedAmount = usingCounterCurrency
          ? orderQtyBig
              .div(NotionalMultiplier || 1)
              .div(marketRateBig)
              .toFixed()
          : orderQtyBig
              .times(NotionalMultiplier || 1)
              .times(marketRateBig)
              .toFixed();
      } else if (conversionRateBig.gt(0)) {
        estimatedAmount = orderQtyBig
          .times(NotionalMultiplier || 1)
          .times(conversionRateBig)
          .toFixed();
      }
      break;

    case ProductTypeEnum.Future:
    case ProductTypeEnum.PerpetualSwap:
    case ProductTypeEnum.CFD:
      if (limitPriceBig.gt(0)) {
        if (!Currency || Currency === '') {
          // Contracts
          if (security?.SettleValueType === SettleValueTypeEnum.Inverted) {
            estimatedAmount = orderQtyBig.mul(security.NotionalMultiplier).div(limitPriceBig).toFixed();
          } else {
            estimatedAmount = orderQtyBig.mul(security.NotionalMultiplier).mul(limitPriceBig).toFixed();
          }
        } else if (usingCounterCurrency) {
          estimatedAmount = orderQtyBig.div(limitPriceBig).toFixed();
        } else {
          estimatedAmount = orderQtyBig.mul(limitPriceBig).toFixed();
        }
      } else if (marketRateBig.gt(0)) {
        if (usingCounterCurrency) {
          estimatedAmount = orderQtyBig.div(marketRateBig).toFixed();
        } else {
          estimatedAmount = orderQtyBig.times(marketRateBig).toFixed();
        }
      } else if (conversionRateBig.gt(0)) {
        estimatedAmount = orderQtyBig.times(conversionRateBig).toFixed();
      }
      break;
  }

  return estimatedAmount;
};
