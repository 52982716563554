import { KeyboardSensor as DndKitKeyboardSensor } from '@dnd-kit/core';

import { shouldHandleEvent } from './shouldHandleEvent';

export class KeyboardSensor extends DndKitKeyboardSensor {
  static activators = [
    {
      eventName: 'onKeyDown' as const,
      handler: ({ nativeEvent: event }: React.KeyboardEvent) => {
        return shouldHandleEvent(event.target as HTMLElement);
      },
    },
  ];
}
