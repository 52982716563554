import type { CSSProperties } from 'react';
import styled, { type DefaultTheme } from 'styled-components';
import type { IconName } from './IconNames';

import { getThemeDimensionValue, parseColor, parseDimension, parseIconSize } from '../../styles/themes/utils';
import type { KeysBeginningWith, VariantsBeginningWith } from '../../utils/types';

const INHERIT = 'inherit' as const;

export const ICON_SIZES = {
  TINY: 10,
  SMALL: 12,
  MEDIUM: 16,
  LARGE: 20,
  DEFAULT: INHERIT,
};

export const Icon = styled.i
  .attrs<Pick<IconProps, 'icon'>>(({ className, icon }) => ({
    className: [className, icon].join(' '),
  }))
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => !['size', 'color'].includes(prop) && defaultValidatorFn(prop),
  })<IconProps>`
  display: inline-block;
  transition: transform 200ms linear;

  ${({ rotate }) => rotate && `transform: rotate(${rotate}deg)`};
  ${({ rotating }) => rotating && `animation: spin 1s linear infinite;`};
  ${({ variant, color, theme }) =>
    (variant || color) &&
    `color: ${parseColor(theme, variant || color) ?? getThemeDimensionValue(theme, `colorText${variant}`)};`}
  ${({ size = 'inherit', theme }) => size != null && size !== 'inherit' && `font-size: ${parseIconSize(theme, size)};`}
  ${({ theme, spaceAfter }) => spaceAfter && `margin-right: ${parseDimension(theme, spaceAfter)};`}
  ${({ theme, spaceBefore }) => spaceBefore && `margin-left: ${parseDimension(theme, spaceBefore)};`}
  ${({ theme, p }) => p && `padding: ${parseDimension(theme, p)};`}
`;

Icon.defaultProps = {
  size: 'inherit',
};

export const SVGIcon = styled.svg`
  transition: width 200ms ease;
`;
SVGIcon.defaultProps = {
  width: 20,
  height: 20,
};

export type IconProps = {
  icon: IconName;
  rotate?: number;
  rotating?: boolean;
  size?: KeysBeginningWith<DefaultTheme, 'fontSize'> | 'inherit' | number;
  spaceAfter?: CSSProperties['marginRight'];
  spaceBefore?: CSSProperties['marginLeft'];
  p?: CSSProperties['padding'] | string;
  variant?: VariantsBeginningWith<DefaultTheme, 'colorText'> | CSSProperties['color'];
};
