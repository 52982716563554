import Big, { type BigSource } from 'big.js';
import { useTheme } from 'styled-components';

interface DonutChartProps {
  percent: BigSource;
  size: number | string;
  strokeColor: string;
}

const CIRCLE_PROPERTIES = {
  cx: '4',
  cy: '4',
  r: '3',
  strokeWidth: '2',
};

export const DonutChart = ({ percent, size, strokeColor }: DonutChartProps) => {
  const { backgroundProgressBar } = useTheme();
  const effectivePercent = Big(percent).gt(5) ? Big(percent).minus(5).div(5).toFixed() : '0';
  return (
    <svg width={size} height={size} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle {...CIRCLE_PROPERTIES} stroke={backgroundProgressBar} />
      {Big(percent).gt(0) && (
        <circle
          {...CIRCLE_PROPERTIES}
          stroke={strokeColor}
          strokeDasharray={`${effectivePercent} 20`}
          strokeLinecap="round"
          transform="rotate(-90 4 4)"
        />
      )}
    </svg>
  );
};
