import type { IAggFunc, IAggFuncParams } from 'ag-grid-community';

/**
 * The equalityAggFunc can be used when you just want to aggregate / "roll up" / "bubble up" values in columns if all the values within a group
 * are the exact same.
 *
 * The function checks equality by doing "===", and if all values provided are the same, will simply return the first value in the provided values array.
 */
export function equalityAggFunc({ values }: IAggFuncParams): ReturnType<IAggFunc> {
  // If all values provided are the same, we return that value. Else return undefined.
  const [firstValue, ...rest] = values;
  const allValuesAreSame = rest.every(value => value === firstValue);
  return allValuesAreSame ? firstValue : undefined;
}
