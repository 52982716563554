import { IconButton } from '../Button';
import { FormControlSizes } from '../Form';
import { IconName } from '../Icons';
import { Tooltip } from '../Tooltip';
import type { BlotterTablePauseProps } from './BlotterTablePauseButton.types';

type BlotterTablePauseButtonProps = Omit<BlotterTablePauseProps, 'showPauseButton'>;

export const BlotterTablePauseButton = ({ paused, pause, resume }: BlotterTablePauseButtonProps) => {
  return (
    <Tooltip
      tooltip={
        paused
          ? 'The blotter is paused and is not receiving any updates. Click to resume the blotter. Resuming the blotter clears the current entries and restarts.'
          : 'The blotter is receiving updates. Click to pause the blotter. Pausing the blotter stops it from receiving any new updates.'
      }
    >
      <IconButton
        icon={paused ? IconName.Play : IconName.Pause}
        onClick={() => (paused ? resume() : pause())}
        size={FormControlSizes.Small}
        data-testid="blotter-pause-button"
      />
    </Tooltip>
  );
};
