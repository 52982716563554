import { entries, noop } from 'lodash';
import { EMPTY, Subject, type Observable } from 'rxjs';
import { SECURITY } from '../tokens/requestTypes';

export enum RDCStatus {
  Created = 'Created',
  Initializing = 'Initializing',
  Running = 'Running',
  Terminated = 'Terminated',
  Unsupported = 'Unsupported',
}

const TALOS_DB = 'TalosCache';

// This is a mapping of cacheable types to the keyPath used to store them in the IndexedDB. If adding a new cacheable
// type, add it here so the db can be created with the correct keyPath.
export const RDC_TYPE_TO_KEYPATH = {
  Security: 'Symbol',
};

export type RDC_CACHEABLE_TYPE = keyof typeof RDC_TYPE_TO_KEYPATH;

export interface IRefDataCache {
  init(dbVersion: string): Promise<void>;
  write(type: RDC_CACHEABLE_TYPE, entities: AnyObject[]): Promise<void>;
  read(type: RDC_CACHEABLE_TYPE, key: string): Promise<IObjectWithTimestamp | undefined>;
  readAll(type: RDC_CACHEABLE_TYPE): Promise<IObjectWithTimestamp[]>;
  observeError(): Observable<Error>;
  status: RDCStatus;
  close(): void;
}

// For now this is just Securities. If expanded to cache beyond that to have other time properties which indicate the
// last time the entity was touched, we will need to make this more dynamic.
interface IObjectWithTimestamp extends AnyObject {
  Timestamp: string;
}

export class IndexedDBCache implements IRefDataCache {
  static readonly CacheableTypes: RDC_CACHEABLE_TYPE[] = [SECURITY];

  static isCacheable(type: string | undefined): type is RDC_CACHEABLE_TYPE {
    return IndexedDBCache.CacheableTypes.includes(type as RDC_CACHEABLE_TYPE);
  }
  private _status = RDCStatus.Created;
  private _db: IDBDatabase | undefined;

  private _errorSubject = new Subject<Error>();

  private _transactionCount = 0;
  private _initProm: Promise<void>;
  private _initResolve = noop;
  private _initReject = noop;

  constructor() {
    this._initProm = new Promise((resolve, reject) => {
      this._initResolve = resolve;
      this._initReject = reject;
    });
  }

  /**
   *
   * @param dbVersion version string used to open the database. If the version is different than the current version,
   *   the database will be deleted and recreated.
   * @returns Promise which resolves when the cache is ready to use or is unsupported.
   */
  async init(dbVersion: string): Promise<void> {
    if (this._status !== RDCStatus.Created) {
      throw new Error(`Attempting to init cache in bad state ${this._status}`);
    }
    if (!window || window.indexedDB === undefined) {
      this._status = RDCStatus.Unsupported;
      this._initResolve();
      return;
    }
    performance.mark('cache-open-start');
    this._status = RDCStatus.Initializing;
    const dbName = `${TALOS_DB}-${dbVersion}`;

    // We intentionally have the indexedDB version hardcoded to 1 as it just works as a cache for us. If there are
    // schema / table changes we will just delete the database cache and recreate it.
    const req = indexedDB.open(dbName, 1);
    req.onsuccess = event => {
      performance.mark('cache-open-end');
      performance.measure('cache-open', 'cache-open-start', 'cache-open-end');
      this._db = req.result;
      this._status = RDCStatus.Running;
      this._initResolve();
    };
    req.onerror = event => {
      this._status = RDCStatus.Terminated;
      const error = new Error(`Failed to open indexedDB ${TALOS_DB}`);
      this._errorSubject.next(error);
      this._initReject(error);
    };
    req.onupgradeneeded = (event: IDBVersionChangeEvent) => {
      this._db = req.result;

      entries(RDC_TYPE_TO_KEYPATH).forEach(([type, keypath]) => {
        this._db?.createObjectStore(type, { keyPath: keypath });
      });
    };
    return this._initProm;
  }

  async write(type: RDC_CACHEABLE_TYPE, entities: AnyObject[]) {
    await this._initProm;
    if (this._status === RDCStatus.Unsupported) {
      return;
    }
    const [perfMeasure, perfStartKey, perfEndKey] = this.nextPerfKeys('write', type);
    performance.mark(perfStartKey);

    return new Promise<void>((resolve, reject) => {
      if (this._status !== RDCStatus.Running) {
        throw new Error(`Attempting to write to cache ${type} in bad state ${this._status}`);
      }

      if (!this._db?.objectStoreNames.contains(type)) {
        throw new Error(`No store for type ${type} when writing`);
      }

      const trans = this._db.transaction(type, 'readwrite');
      const store = trans.objectStore(type);

      // TODO we should consider add batching of writes to avoid locking up trying to write a large number of entities.
      entities.forEach(entity => {
        store.put(entity);
      });

      trans.oncomplete = event => {
        performance.mark(perfEndKey);
        performance.measure(perfMeasure, perfStartKey, perfEndKey);
        resolve();
      };
      trans.commit();
    });
  }

  observeError(): Observable<Error> {
    return this._errorSubject.asObservable();
  }

  async read(type: RDC_CACHEABLE_TYPE, key: string): Promise<IObjectWithTimestamp> {
    await this._initProm;
    const [perfMeasure, perfStartKey, perfEndKey] = this.nextPerfKeys('read', type);
    performance.mark(perfStartKey);

    return new Promise((resolve, reject) => {
      if (this._status !== RDCStatus.Running) {
        const error = new Error(`Attempting to read from cache ${type} in bad state ${this._status}`);
        this._errorSubject.next(error);
        throw error;
      }

      if (!this._db?.objectStoreNames.contains(type)) {
        const error = new Error(`No store for type ${type} when reading`);
        this._errorSubject.next(error);
        throw error;
      }

      const trans = this._db.transaction(type, 'readonly');
      const store = trans.objectStore(type);

      const req = store.get(key);
      req.onsuccess = event => {
        performance.mark(perfEndKey);
        performance.measure(perfMeasure, perfStartKey, perfEndKey);
        resolve(req.result);
      };
      req.onerror = event => {
        const error = new Error(`Failed to read ${key} from ${type}`);
        this._errorSubject.next(error);
        reject(error);
      };
    });
  }

  async readAll(type: RDC_CACHEABLE_TYPE): Promise<IObjectWithTimestamp[]> {
    await this._initProm;
    const [perfMeasure, perfStartKey, perfEndKey] = this.nextPerfKeys('read-all', type);
    performance.mark(perfStartKey);

    return new Promise((resolve, reject) => {
      if (this._status !== RDCStatus.Running) {
        const error = new Error(`Attempting to read all from cache ${type} in bad state ${this._status}`);
        this._errorSubject.next(error);
        throw error;
      }

      if (!this._db?.objectStoreNames.contains(type)) {
        const error = new Error(`No store for type ${type} when reading`);
        this._errorSubject.next(error);
        throw error;
      }

      const trans = this._db.transaction(type, 'readonly');
      const store = trans.objectStore(type);

      const req = store.getAll();

      req.onsuccess = event => {
        performance.mark(perfEndKey);
        performance.measure(perfMeasure, perfStartKey, perfEndKey);
        resolve(req.result);
      };
      req.onerror = event => {
        const error = new Error(`Failed to read all from ${type}`);
        this._errorSubject.next(error);
        reject(error);
      };
    });
  }

  // Leaving this index based lookup for future reference. It is not currently used as it turned out it was slower to
  // read the single entity out of the index than it was to just read all entities and filter in memory.

  /**
  async readFirstByIndex(type: RDC_CACHEABLE_TYPE, index: string, direction: IDBCursorDirection): Promise<any> {
    await this._initProm;
    const [perfMeasure, perfStartKey, perfEndKey] = this.nextPerfKeys('read-index', type);
    performance.mark(perfStartKey);

    return new Promise((resolve, reject) => {
      if (this._status !== RDCStatus.Running) {
        const error = new Error(`Attempting to read-index from cache ${type} in bad state ${this._status}`);
        this._errorSubject.next(error);
        throw error;
      }

      if (!this._db?.objectStoreNames.contains(type)) {
        const error = new Error(`No store for type ${type} when index reading`);
        this._errorSubject.next(error);
        throw error;
      }

      const trans = this._db.transaction(type, 'readonly');
      const store = trans.objectStore(type);
      if (!store.indexNames.contains(index)) {
        const error = new Error(`No index ${index} for type ${type} when index index reading`);
        this._errorSubject.next(error);
        throw error;
      }

      const req = store.index(index).openCursor(null, direction);

      req.onsuccess = event => {
        performance.mark(perfEndKey);
        performance.measure(perfMeasure, perfStartKey, perfEndKey);
        resolve(req.result?.value);
      };

      req.onerror = event => {
        const error = new Error(`Failed to read-index from ${type}`);
        this._errorSubject.next(error);
        reject(error);
      };
    });
  }
  */

  get status() {
    return this._status;
  }

  // Cleanup Database
  async deleteDatabase() {
    try {
      if (this._db?.name) {
        return await IndexedDBCache.Delete(this._db.name);
      } else {
        throw new Error('No database to delete');
      }
    } catch (e) {
      console.error(`Failed to delete indexedDB: ${this._db?.name}: ${e instanceof Error ? e.message : e}`);
    }
  }

  close() {
    // maybe throw if we are in a bad state so it is easier to debug? But throwing on a close can be annoying.
    // note: this only is ever called in tests and the lifecycle of connection is assumed to be the lifecycle of the app.
    this._db?.close();
  }

  private nextPerfKeys(
    op: 'write' | 'read' | 'read-all' | 'read-index',
    type: RDC_CACHEABLE_TYPE
  ): [string, string, string] {
    this._transactionCount++;
    return [
      `cache-${op}-${type}-${this._transactionCount}`,
      `cache-${op}-${type}-${this._transactionCount}-start`,
      `cache-${op}-${type}-${this._transactionCount}-end`,
    ];
  }

  static async Delete(dbName: string) {
    const req = indexedDB.deleteDatabase(dbName);
    return new Promise<void>((resolve, reject) => {
      req.onsuccess = event => {
        resolve();
      };
      req.onerror = event => {
        reject(`Failed to delete indexedDB ${dbName}`);
      };
    });
  }
}

export const NOOP_CACHE: IRefDataCache = {
  init(dbVersion: string): Promise<void> {
    return Promise.resolve();
  },
  write(type: RDC_CACHEABLE_TYPE, entities: AnyObject[]): Promise<void> {
    return Promise.resolve();
  },
  read(type: RDC_CACHEABLE_TYPE, key: string): Promise<IObjectWithTimestamp | undefined> {
    return Promise.resolve(undefined);
  },
  readAll(type: RDC_CACHEABLE_TYPE): Promise<IObjectWithTimestamp[]> {
    return Promise.resolve([]);
  },
  observeError(): Observable<Error> {
    return EMPTY;
  },
  get status(): RDCStatus {
    return RDCStatus.Running;
  },
  close() {
    return;
  },
};
