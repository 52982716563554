import {
  GlobalCustomerUserConfigTradingLayoutArrangements,
  ProtectedCustomerUserConfigTradingLayoutArrangements,
} from '@talos/kyoko';
import type { MultiLayoutComponent } from '../../types';
import { CandlesticksAndMarketDataCardsArrangement } from './CandlesticksAndMarketDataCardsArrangement';
import { MarketDataCardsArrangement } from './MarketDataCardsArrangement';
import { SingleSymbolViewArrangement } from './SingleSymbolViewArrangement';
import { WMLayout1Arrangement } from './WMLayout1Arrangement';
import { WMLayout2Arrangement } from './WMLayout2Arrangement';
import { WatchlistAndMarketDataCardsArrangement } from './WatchlistAndMarketDataCardsArrangement';
import { WatchlistCandlesticksMarketDataCardsArrangement } from './WatchlistCandlesticksMarketDataCardsArrangement';

export const ViewOnlyLayout: MultiLayoutComponent = {
  arrangements: {
    [GlobalCustomerUserConfigTradingLayoutArrangements.SingleSymbolView]: SingleSymbolViewArrangement,
    [GlobalCustomerUserConfigTradingLayoutArrangements.MarketDataCards]: MarketDataCardsArrangement,
    [GlobalCustomerUserConfigTradingLayoutArrangements.CandlesticksAndMarketDataCards]:
      CandlesticksAndMarketDataCardsArrangement,
    [GlobalCustomerUserConfigTradingLayoutArrangements.WatchlistAndMarketDataCards]:
      WatchlistAndMarketDataCardsArrangement,
    [GlobalCustomerUserConfigTradingLayoutArrangements.WatchlistCandlesticksMarketDataCards]:
      WatchlistCandlesticksMarketDataCardsArrangement,
    [ProtectedCustomerUserConfigTradingLayoutArrangements.WMLayout1]: WMLayout1Arrangement,
    [ProtectedCustomerUserConfigTradingLayoutArrangements.WMLayout2]: WMLayout2Arrangement,
  },
};
