import { BaseStyle, GlobalStyle, themes, useWLOrgConfigContext } from '@talos/kyoko';
import { useEffect, type PropsWithChildren } from 'react';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { usePreferredTheme } from '../hooks';

export function ThemeProvider({ children }: PropsWithChildren) {
  const { config } = useWLOrgConfigContext();
  const theme = usePreferredTheme(config.theme);

  useEffect(() => {
    const { fontUrl } = themes[config.theme];
    const link = document.getElementById('themeFont') as HTMLLinkElement;
    if (link != null) {
      link.href = fontUrl;
    }
  }, [config]);

  return (
    <StyledComponentsThemeProvider theme={theme}>
      <BaseStyle />
      <GlobalStyle />
      {children}
    </StyledComponentsThemeProvider>
  );
}
