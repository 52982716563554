import type { ButtonHTMLAttributes } from 'react';
import styled, { type DefaultTheme } from 'styled-components';
import { FormControlSizes } from '../../Form/types';
import { ButtonWrapper, type ButtonProps } from '../styles';

export const IconButtonWrapper = styled(ButtonWrapper)<IconButtonWrapperProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: ${({ round }) => (round ? '100%' : '')};
  flex: 0 0 auto;

  & {
    background: ${({ ghost: minimal }) => (minimal ? 'transparent' : '')};
    border-color: ${({ ghost: minimal }) => (minimal ? 'transparent' : '')};
  }

  width: ${({ size = FormControlSizes.Default, theme }) => theme.baseSize * size}px;
  height: ${({ size = FormControlSizes.Default, theme }) => theme.baseSize * size}px;

  font-size: ${({ theme, size }) => getIconFontSize(theme, size)}rem;
`;

const getIconFontSize = (theme: DefaultTheme, size = FormControlSizes.Default) => {
  switch (size) {
    case FormControlSizes.Tiny: {
      return theme.fontSizeSmall;
    }
    case FormControlSizes.Small: {
      return theme.fontSizeDefault;
    }
    case FormControlSizes.Default: {
      return theme.fontSizeLarge;
    }
    case FormControlSizes.Large: {
      return theme.fontSizeBig;
    }
    default: {
      return size - 1;
    }
  }
};

export type IconButtonWrapperProps = ButtonProps & ButtonHTMLAttributes<HTMLButtonElement> & { round?: boolean };
