import type { ReactNode } from 'react';
import { Box, type BoxProps } from '../Core';
import { AccordionContext } from './AccordionContext';
import type { AccordionOutputs } from './useAccordion';

export type AccordionProps = AccordionOutputs & { children: ReactNode } & Pick<BoxProps, 'w'>;

export const Accordion = ({ children, w, ...accordion }: AccordionProps) => {
  return (
    <AccordionContext.Provider value={accordion}>
      <Box w={w}>{children}</Box>
    </AccordionContext.Provider>
  );
};
