import chroma from 'chroma-js';
import styled from 'styled-components';
import { Button, ButtonWrapper } from '../Button';

export const Wrapper = styled(Button)<{ active: boolean }>`
  color: ${({ theme }) => theme.colors.gray['090']};
  height: 1.25rem;
  padding: 0 1.25rem 0 ${({ theme }) => theme.spacingDefault}px;
  border: ${({ theme }) => `1px solid ${theme.colors.gray['060']}`};
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  display: flex;
  align-items: center;
  position: relative;
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  background: transparent;
  &:hover {
    background: transparent;
  }
  label {
    text-align: center;
    color: ${({ theme }) => theme.colors.gray['090']};
    text-transform: uppercase;
    letter-spacing: 0.05em;
    font-weight: ${({ theme }) => theme.fontWeightMedium};
    margin-right: ${({ theme }) => theme.spacingSmall}px;
  }
  label,
  span {
    cursor: pointer;
  }
  &:after {
    pointer-events: none;
    content: ' ';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 0.4rem;
    display: block;
    background: transparent
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 5'%3E%3Cpath fill='${({
        theme,
      }) =>
        encodeURIComponent(
          chroma(theme.colors.gray['080']).hex()
        )}' fill-rule='nonzero' d='M1 0L0 1l4 4 4-4-1-1-3 3z'/%3E%3C/svg%3E")
      no-repeat center center;
  }
`;

export const Content = styled.div`
  width: 130px;
  text-align: left;
  padding: ${({ theme }) => theme.spacingSmall}px;
  ul {
    margin: 0 0 ${({ theme }) => theme.spacingSmall}px;
    padding: 0;
    list-style: none;
  }
  li {
    margin: 3px 0;
  }
  label {
    text-transform: none;
  }
  input[type='number'] {
    width: 101px;
    text-align: left;
    margin: 0;
  }
`;

export const Buttons = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacingDefault}px;
  align-items: center;
  margin-top: ${({ theme }) => theme.spacingDefault}px;
  width: 100%;
  ${ButtonWrapper} {
    flex: 1;
  }
`;

export const SlippageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    width: 40px;
    margin-left: ${({ theme }) => theme.spacingSmall}px;
  }
`;
