import * as Highcharts from 'highcharts/highcharts';

enum Direction {
  Vertical = 0,
  Horizontal = 1,
}

/**
 * Custom implementation of the HighCharts "squarified" / "stripes" layout algorithm, but with padding to ensure
 * we can see the background color of the parent element.
 * @remarks
 * This kinda works now, though the implementation isn't quite the most "accurate".  The "padding" around the child boxes
 * is wrong, it should be based on the size of the parent's data label, but it isn't right now.
 * The dimensions that we're operating on in the layout function, are different than the final render dimensions, so we can't
 * just go e.g. `parentY = parent.y + parent.dataLabel.height`.
 */
const squarifiedWithPadding = function squarifiedWithPadding(parent, children) {
  const direction = parent.direction;
  const directionChange = (children?.[0]?.parentNode?.level ?? 0) === 0; // set to true for squarified algorithm
  const childrenArea: Highcharts.BBoxObject[] = [];
  const parentNode = children?.[0]?.parentNode;
  const { parentHeight, parentWidth, parentX, parentY } =
    (parentNode?.level ?? 0) === 0
      ? {
          parentHeight: parent.height,
          parentWidth: parent.width,
          parentX: parent.x,
          parentY: parent.y,
        }
      : {
          parentHeight: parent.height - 3, //parentNode?.point?.dataLabel?.box.getBBox().height,
          parentWidth: parent.width - 2,
          parentX: parent.x + 1,
          parentY: parent.y + 2, //parentNode?.point?.dataLabel?.box.getBBox().height,
        };
  const plot = {
    x: parentX,
    y: parentY,
    parent: { ...parent, height: parentHeight, width: parentWidth, x: parentX, y: parentY },
  };
  const end = children.length - 1;

  const group = new TreemapAlgorithmGroup(parentHeight, parentWidth, direction, plot);

  // Loop through and calculate all areas
  children.forEach((child, i) => {
    child.point.percentage = child.val / parent.val;
    const pTot = parentWidth * parentHeight * (child.val / parent.val);
    group.addElement(pTot);

    if (group.lastPoint.newRatio > group.lastPoint.lastRatio) {
      algorithmCalcPoints(directionChange, false, group, childrenArea);
    }
    // If last child, then calculate all remaining areas
    if (i === end) {
      algorithmCalcPoints(directionChange, true, group, childrenArea);
    }
  });

  return childrenArea;
} satisfies Highcharts.TreemapLayoutAlgorithm;

function algorithmCalcPoints(
  directionChange: boolean,
  last: boolean,
  group: TreemapAlgorithmGroup,
  childrenArea: Highcharts.BBoxObject[]
): void {
  let plotX: number;
  let plotY: number;
  let plotWidth: number;
  let plotHeight: number;
  let groupWidth = group.lastWidth;
  let groupHeight = group.lastHeight;
  const plot = group.plot;
  let keep: number | undefined;
  const end = group.elArr.length - 1;

  if (last) {
    groupWidth = group.newWidth;
    groupHeight = group.newHeight;
  } else {
    keep = group.elArr[group.elArr.length - 1];
  }
  group.elArr.forEach((p, i): void => {
    if (last || i < end) {
      if (group.direction === Direction.Vertical) {
        plotX = plot.x;
        plotY = plot.y;
        plotWidth = groupWidth;
        plotHeight = p / plotWidth;
      } else {
        plotX = plot.x;
        plotY = plot.y;
        plotHeight = groupHeight;
        plotWidth = p / plotHeight;
      }
      childrenArea.push({
        x: plotX,
        y: plotY,
        width: plotWidth,
        height: Highcharts.correctFloat(plotHeight),
      });
      if (group.direction === Direction.Vertical) {
        plot.y = plot.y + plotHeight;
      } else {
        plot.x = plot.x + plotWidth;
      }
    }
  });
  // Reset variables
  group.reset();
  if (group.direction === Direction.Vertical) {
    group.width = group.width - groupWidth;
  } else {
    group.height = group.height - groupHeight;
  }
  plot.y = plot.parent.y + (plot.parent.height - group.height);
  plot.x = plot.parent.x + (plot.parent.width - group.width);
  if (directionChange) {
    group.direction = 1 - group.direction;
  }
  // If not last, then add uncalculated element
  if (!last) {
    group.addElement(keep as any);
  }
}

/**
 * Custom implementation of the HighCharts TreemapSeries.pointAttribs function, to customize the fill behavior.
 * @remarks
 * By default, Highcharts will not fill parent elements if `interactByLeaf` is false.  We need the fill to be set,
 * because we have offset the child elements, making the parent element's background visible (the default Highcharts
 * layouts don't allow the parent node to be visible).
 */
function pointAttribs(
  this: Highcharts.TreemapSeries,
  point: Highcharts.TreemapPoint,
  state?: string //Highcharts.StatesOptionsKey
): Highcharts.SVGAttributes {
  const mapOptionsToLevel = Highcharts.isObject(this.mapOptionsToLevel) ? this.mapOptionsToLevel : {};
  const level = (point && mapOptionsToLevel[point.node.level]) || {};
  const options = this.options as Highcharts.SeriesOptionsRegistry['SeriesTreemapOptions'];

  const stateOptions = (state && options.states && options.states[state]) || {};
  const className = (point && point.getClassName()) || '';
  let opacity: number;

  // Set attributes by precedence. Point trumps level trumps series.
  // Stroke width uses pick because it can be 0.
  const attr: Highcharts.SVGAttributes = {
    stroke:
      (point && (point as any).borderColor) || level.borderColor || stateOptions.borderColor || options.borderColor,
    'stroke-width': Highcharts.pick(
      point && (point as any).borderWidth,
      level.borderWidth,
      stateOptions.borderWidth,
      options.borderWidth
    ),
    dashstyle:
      (point && (point as any).borderDashStyle) ||
      level.borderDashStyle ||
      stateOptions.borderDashStyle ||
      options.borderDashStyle,
    fill: (point && point.color) || this.color,
  };

  // Hide levels above the current view
  if (className.indexOf('highcharts-above-level') !== -1) {
    attr.fill = 'none';
    attr['stroke-width'] = 0;

    // Nodes with children that accept interaction
  } else if (
    className.indexOf('highcharts-internal-node-interactive') !== -1 ||
    className.indexOf('highcharts-internal-node') !== -1
  ) {
    opacity = Highcharts.pick(stateOptions.opacity, options.opacity as any);
    attr.fill = Highcharts.color(attr.fill).setOpacity(opacity).get();
    attr.cursor = 'pointer';
    //     // Hide nodes that have children
    // } else if (className.indexOf('highcharts-internal-node') !== -1) {
    //     attr.fill = 'none';
  } else if (state) {
    // Brighten and hoist the hover nodes
    attr.fill = Highcharts.color(attr.fill)
      .brighten(stateOptions.brightness as any)
      .get();
  }
  return attr;
}

/**
 * Custom implementation of the HighCharts TreemapSeries.drawPoints function, to customize the zIndex behavior.
 * @remarks
 * By default, Highcharts sets the z-index of the nodes in reverse order, so that the leaf nodes have the lowest
 * z-index, and the root nodes have the highest z-index (not "actual" z-indexes, these are just data attributes).
 * The effect of this is, when mousing over a point, you'll see the tooltip for the parent node, unless you are
 * mousing over the data label for that point.
 * This would make sense if there's no way to mouse over the parent element, but with our other changes, we're now
 * able to mouse over the parent OR the child, so it's more desirable to set the z-index in the opposite direction
 * to what Highcharts does.
 */
function drawPoints(this: Highcharts.TreemapSeries, points: Array<Highcharts.TreemapPoint> = this.points): void {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const series = this,
    chart = series.chart,
    renderer = chart.renderer,
    styledMode = chart.styledMode,
    options = series.options as Highcharts.SeriesTreemapOptions,
    shadow = styledMode ? {} : (options as any).shadow,
    borderRadius = options.borderRadius,
    withinAnimationLimit = (chart as any).pointCount < (options.animationLimit as any),
    allowTraversingTree = options.allowTraversingTree;

  points.forEach(function (point): void {
    const levelDynamic = point.node.levelDynamic,
      animatableAttribs: Highcharts.SVGAttributes = {},
      attribs: Highcharts.SVGAttributes = {},
      css: Highcharts.CSSObject = {},
      groupKey = 'level-group-' + point.node.level,
      hasGraphic = !!point.graphic,
      shouldAnimate = withinAnimationLimit && hasGraphic,
      shapeArgs = point.shapeArgs;

    // Don't bother with calculate styling if the point is not drawn
    if (point.shouldDraw()) {
      (point as any).isInside = true;

      if (borderRadius) {
        attribs.r = borderRadius;
      }

      Highcharts.merge(
        true, // Extend object
        // Which object to extend
        shouldAnimate ? animatableAttribs : attribs,
        // Add shapeArgs to animate/attr if graphic exists
        hasGraphic ? shapeArgs : {},
        // Add style attribs if !styleMode
        styledMode ? {} : (series as any).pointAttribs(point, point.selected ? 'select' : void 0)
      );

      // In styled mode apply point.color. Use CSS, otherwise the
      // fill used in the style sheet will take precedence over
      // the fill attribute.
      if ((series as any).colorAttribs && styledMode) {
        // Heatmap is loaded
        Highcharts.extend<Highcharts.CSSObject | Highcharts.SVGAttributes>(
          css,
          (series as any).colorAttribs(point as any)
        );
      }

      if (!(series as any)[groupKey]) {
        (series as any)[groupKey] = renderer
          .g(groupKey)
          .attr({
            // @todo Set the zIndex based upon the number of
            // levels, instead of using 1000
            zIndex: levelDynamic ?? 0, //1000 - (levelDynamic || 0),
          })
          .add((series as any).group);
        (series as any)[groupKey].survive = true;
      }
    }

    // Draw the point
    point.draw({
      animatableAttribs,
      attribs,
      css,
      group: (series as any)[groupKey],
      imageUrl: point.imageUrl,
      renderer,
      shadow,
      shapeArgs,
      shapeType: point.shapeType,
    });

    // If setRootNode is allowed, set a point cursor on clickables &
    // add drillId to point
    if (allowTraversingTree && point.graphic) {
      point.drillId = options.interactByLeaf ? series.drillToByLeaf(point) : series.drillToByGroup(point);
    }
  });
}

export function addHighchartsTreemapLayoutSquarifiedWithPadding(factory: typeof Highcharts) {
  if ((factory as any)?.seriesTypes?.treemap?.prototype) {
    (factory as any).seriesTypes.treemap.prototype.squarifiedWithPadding = squarifiedWithPadding;
    (factory as any).seriesTypes.treemap.prototype.pointAttribs = pointAttribs;
    (factory as any).seriesTypes.treemap.prototype.drawPoints = drawPoints;
  } else {
    console.error('Unable to add Highcharts treemap layout, seriesType does not exist', (factory as any)?.seriesTypes);
  }
}

/* *
 *
 *  (c) 2014-2021 Highsoft AS
 *
 *  Authors: Jon Arild Nygard / Oystein Moseng
 *
 *  License: www.highcharts.com/license
 *
 *  !!!!!!! SOURCE GETS TRANSPILED BY TYPESCRIPT. EDIT TS FILE ONLY. !!!!!!!
 *
 * */
class TreemapAlgorithmGroup {
  height: number;
  width: number;
  plot: PlotObject;
  direction: Direction;
  startDirection: any;
  total: number;
  newWidth: number;
  lastWidth: number;
  newHeight: number;
  lastHeight: number;
  elArr: number[];
  lastPoint: LastPointObject;
  /* *
   *
   *  Constructor
   *
   * */
  constructor(height: number, width: number, direction: Direction, p: PlotObject) {
    this.height = height;
    this.width = width;
    this.plot = p;
    this.direction = direction;
    this.startDirection = direction;
    this.total = 0;
    this.newWidth = 0;
    this.lastWidth = 0;
    this.newHeight = 0;
    this.lastHeight = 0;
    this.elArr = [];
    this.lastPoint = {
      total: 0,
      lastHeight: 0,
      newHeight: 0,
      lastWidth: 0,
      newWidth: 0,
      newRatio: 0,
      lastRatio: 0,
      aspectRatio: function (w, h) {
        return Math.max(w / h, h / w);
      },
    };
  }
  addElement(el = 0) {
    this.lastPoint.total = this.elArr[this.elArr.length - 1];
    this.total = (this.total ?? 0) + (el ?? 0);
    if (this.direction === Direction.Vertical) {
      // Calculate last point old aspect ratio
      this.lastWidth = this.newWidth;
      this.lastPoint.lastHeight = this.lastPoint.total / this.lastWidth;
      this.lastPoint.lastRatio = this.lastPoint.aspectRatio(this.lastWidth, this.lastPoint.lastHeight);
      // Calculate last point new aspect ratio
      this.newWidth = this.total / this.height;
      this.lastPoint.newHeight = this.lastPoint.total / this.newWidth;
      this.lastPoint.newRatio = this.lastPoint.aspectRatio(this.newWidth, this.lastPoint.newHeight);
    } else {
      // Calculate last point old aspect ratio
      this.lastHeight = this.newHeight;
      this.lastPoint.lastWidth = this.lastPoint.total / this.lastHeight;
      this.lastPoint.lastRatio = this.lastPoint.aspectRatio(this.lastPoint.lastWidth, this.lastHeight);
      // Calculate last point new aspect ratio
      this.newHeight = this.total / this.width;
      this.lastPoint.newWidth = this.lastPoint.total / this.newHeight;
      this.lastPoint.newRatio = this.lastPoint.aspectRatio(this.lastPoint.newWidth, this.newHeight);
    }
    this.elArr.push(el);
  }
  reset() {
    this.newWidth = 0;
    this.lastWidth = 0;
    this.elArr = [];
    this.total = 0;
  }
  public clone() {
    return {
      ...this,
      lastPoint: { ...this.lastPoint },
    };
  }
}

interface LastPointObject {
  lastHeight: number;
  lastRatio: number;
  lastWidth: number;
  newHeight: number;
  newRatio: number;
  newWidth: number;
  total: number;
  aspectRatio(w: number, h: number): number;
}
interface PlotObject extends Highcharts.PositionObject {
  parent: Highcharts.NodeValuesObject;
}
