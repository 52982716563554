import styled from 'styled-components';
import { Box } from '../../components/Core';

export const SummaryLineWrapper = styled(Box)`
  display: flex;
  padding: ${({ theme }) => theme.spacingSmall}px 0;
  background: ${({ theme }) => theme.colors.gray['010']};
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  line-height: 1;
  min-height: ${({ theme }) => `calc(${theme.spacingMedium}px + ${theme.fontSizeSmall}rem)`};
`;

export const SummarySection = styled.div`
  padding: ${({ theme }) => theme.spacingSmall}px;
  background: inherit;
`;

export const SymbolSummary = styled.div`
  background: inherit;
  display: inline-block;
  padding: 0 ${({ theme }) => theme.spacingSmall}px;
  border-left: ${({ theme }) => `solid 1px ${theme.colors.gray['050']}`};

  &:first-of-type {
    padding-left: 0;
    border: none;
  }
`;

export const Total = styled.span`
  color: ${({ theme }) => theme.colors.gray['090']};
  text-transform: uppercase;
  padding-right: ${({ theme }) => theme.spacingSmall}px;
`;

export const Sell = styled.span`
  color: ${({ theme }) => theme.colors.red.lighten};
  text-transform: uppercase;
  padding-right: ${({ theme }) => theme.spacingSmall}px;
`;

export const Buy = styled.span`
  color: ${({ theme }) => theme.colors.green.lighten};
  text-transform: uppercase;
  padding-right: ${({ theme }) => theme.spacingSmall}px;
`;
