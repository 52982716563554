import { sub } from 'date-fns';
import { useMemo } from 'react';
import type { Observable } from 'rxjs';
import { combineLatestWith, map } from 'rxjs/operators';
import type { IOhlcv } from '../types';
import type { OHLCData } from '../types/OHLCData';
import type { ResolutionEnumNoPrefix } from '../types/OrderAnalytic';
import { EMPTY_ARRAY } from '../utils';
import { useBehaviorSubject } from './useBehaviorSubject';
import { useDynamicCallback } from './useDynamicCallback';
import { useOHLCData } from './useOHLCData';

export const useHistoricalPrices = ({
  clearOnSymbolChange,
  symbol,
  markets,
  resolution,
}: {
  clearOnSymbolChange?: boolean;
  symbol: string | undefined;
  markets?: string[];
  resolution: ResolutionEnumNoPrefix;
}): Observable<OHLCData[]> => {
  const { endDate, startDate } = useMemo(
    () =>
      symbol != null
        ? {
            startDate: sub(new Date(), { hours: resolution === '1H' ? 24 : resolution === '5M' ? 6 : 1 }),
            endDate: new Date(),
          }
        : {},
    [resolution, symbol]
  );
  const { observable: symbolObservable } = useBehaviorSubject(() => symbol, [symbol]);

  const checkToClearOHLCData = useDynamicCallback((ohlcData: IOhlcv[], currSymbol?: string) => {
    // Symbol data comes through from this endpoint, it just isn't on the official type def
    const firstRecord: IOhlcv = ohlcData[0];

    if (clearOnSymbolChange && currSymbol !== firstRecord?.Symbol) {
      return EMPTY_ARRAY;
    }

    return ohlcData as OHLCData[];
  });

  const ohlcDataObservable = useOHLCData({ symbol, startDate, endDate, resolution, markets }) as Observable<IOhlcv[]>;

  return useMemo(
    () =>
      ohlcDataObservable.pipe(
        combineLatestWith(symbolObservable),
        map(([ohlcData, currSymbol]) => {
          return checkToClearOHLCData(ohlcData, currSymbol);
        })
      ),
    [checkToClearOHLCData, ohlcDataObservable, symbolObservable]
  );
};
