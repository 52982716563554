import {
  formattedDate,
  formattedDateAtMinutes,
  formattedDateWithMilliseconds,
  formattedDay,
  type DateFnInput,
} from '../../../utils';

export enum ChartTooltipValueType {
  currency = 'currency',
  bps = 'bps',
  percent = 'percent',
  number = 'number',
  milliseconds = 'milliseconds',
}

export type ChartTooltipResolution = '1MS' | '1S' | '1M' | '1H' | '1D';

export type DateFormatter = (date: DateFnInput) => string;

export interface ResolutionMetadata {
  dateFormatter: DateFormatter;
}

export type UseChartTooltipProps = {
  outside?: boolean;
  shared?: boolean;
  valueType: ChartTooltipValueType;
  currency?: string;
  omittedSeries?: string[];
  /** Pass a ChartTooltipResolution to the tooltip to get some default formatting for this resolution of data. */
  resolution?: ChartTooltipResolution;
  /** The function to use to format the date displayed in the tooltip. Overrides any defaults or defaults-by-resolution. */
  dateFormatter?: DateFormatter;
};

export const RESOLUTION_METADATA = {
  '1MS': {
    dateFormatter: formattedDateWithMilliseconds,
  },
  '1S': {
    dateFormatter: formattedDate,
  },
  '1M': {
    dateFormatter: formattedDateAtMinutes,
  },
  '1H': {
    dateFormatter: formattedDateAtMinutes,
  },
  '1D': {
    dateFormatter: formattedDay,
  },
} satisfies { [key in ChartTooltipResolution]: ResolutionMetadata };
