import {
  Box,
  ButtonVariants,
  Crossmark,
  DEFAULT_SLIPPAGES,
  ErrorMessage,
  FormSection,
  FormattedMessage,
  InlineFormattedNumber,
  MixpanelEvent,
  OrderFormSides,
  QuoteButton,
  QuoteButtons,
  QuoteStatusEnum,
  RemainingTime,
  SideEnum,
  SlippageControl,
  SlippageWrapper,
  Spinner,
  StackedFormattedNumber,
  bpsToPercent,
  selectForm,
  selectOrderStep,
  useDynamicCallback,
  useMixpanel,
  useRFQServiceContext,
  useWLOrderFormSelector,
  useWLOrgConfigContext,
  type CustomerQuote,
  type Slippage,
} from '@talos/kyoko';

import { useMemo, useState } from 'react';
import { defineMessages } from 'react-intl';
import styled from 'styled-components';
import { getQuoteSpreadDisplay } from './RFQForm/utils';
import { QuoteSpread } from './styles';

const messages = defineMessages({
  buyCurrency: {
    defaultMessage: 'Buy {currency}',
    id: 'OrderForms.buyCurrency',
  },

  sellCurrency: {
    defaultMessage: 'Sell {currency}',
    id: 'OrderForms.sellCurrency',
  },
});

export function PickQuoteView({ quote }: { quote: CustomerQuote | null }) {
  const mixpanel = useMixpanel();
  const rfqService = useRFQServiceContext();
  const { config } = useWLOrgConfigContext();

  const showOrderSlippage = config.allowOrderSlippage;
  const [allowedSlippageBps, setAllowedSlippageBps] = useState<Slippage>(DEFAULT_SLIPPAGES[2]);
  const allowedSlippagePercent = useMemo(() => bpsToPercent(allowedSlippageBps.value || '0'), [allowedSlippageBps]);

  const { Currency } = quote ?? {};

  const expiredQuote = quote?.QuoteStatus === QuoteStatusEnum.Canceled;
  const hasQuote =
    quote && quote.QuoteStatus !== QuoteStatusEnum.PendingNew && quote.QuoteStatus !== QuoteStatusEnum.Rejected;
  const orderStep = useWLOrderFormSelector(selectOrderStep);

  const form = useWLOrderFormSelector(selectForm);
  const {
    config: { useColoredButtonsForTwoWayQuotes, showSpreadOnRFQs },
  } = useWLOrgConfigContext();

  const Side = form.sideField.value;
  const security = form.symbolField.value;

  const handleAcceptQuote = useDynamicCallback((side: SideEnum) => {
    mixpanel.track(MixpanelEvent.AcceptRfqQuote);

    rfqService.acceptQuote({ side, allowedSlippage: showOrderSlippage ? allowedSlippagePercent : undefined });
  });

  if (!security) {
    return null;
  }
  const rejectedQuoteText = quote?.QuoteStatus === QuoteStatusEnum.Rejected ? quote?.Text : undefined;

  let quoteSpread = '-';
  if (showSpreadOnRFQs && quote?.QuoteStatus === QuoteStatusEnum.Open && quote?.BidPx && quote?.OfferPx) {
    quoteSpread = getQuoteSpreadDisplay(quote.BidPx, quote.OfferPx);
  }

  return (
    <>
      <FormSection>
        {showOrderSlippage && (
          <Box display="flex" alignItems="center" justifyContent="flex-end" mb="spacingSmall">
            <SlippageWrapper data-testid="slippage-wrapper">
              <SlippageControl slippage={allowedSlippageBps} onApply={setAllowedSlippageBps} />
            </SlippageWrapper>
          </Box>
        )}
        <QuoteButtons>
          {Side !== OrderFormSides.Buy && (
            <QuoteButton
              disabled={expiredQuote || !hasQuote || quote?.BidPx == null}
              onClick={() => handleAcceptQuote(SideEnum.Sell)}
              variant={useColoredButtonsForTwoWayQuotes ? ButtonVariants.Negative : getSideVariant(Side)}
              data-testid="accept-quote-sell"
            >
              {hasQuote ? (
                <>
                  <QuoteSideTSX>
                    <FormattedMessage {...messages.sellCurrency} values={{ currency: security.BaseCurrency }} />
                  </QuoteSideTSX>
                  <QuotePrice>
                    <StackedFormattedNumber
                      number={quote?.BidPx}
                      specification={security.PriceDisplaySpec}
                      highlightAll={false}
                    />
                  </QuotePrice>
                  <QuoteAmountTSX>
                    <InlineFormattedNumber
                      increment={
                        Currency === security.BaseCurrency
                          ? security.DefaultPriceIncrement
                          : security.DefaultSizeIncrement
                      }
                      number={quote?.BidAmt}
                      currency={Currency === security.BaseCurrency ? security.QuoteCurrency : security.BaseCurrency}
                    />
                  </QuoteAmountTSX>
                </>
              ) : rejectedQuoteText ? (
                <Crossmark />
              ) : (
                <Spinner />
              )}
            </QuoteButton>
          )}
          {Side !== OrderFormSides.Sell && (
            <QuoteButton
              disabled={expiredQuote || !hasQuote || quote?.OfferPx == null}
              onClick={() => handleAcceptQuote(SideEnum.Buy)}
              variant={useColoredButtonsForTwoWayQuotes ? ButtonVariants.Positive : getSideVariant(Side)}
              data-testid="accept-quote-buy"
            >
              {hasQuote ? (
                <>
                  <QuoteSideTSX>
                    <FormattedMessage {...messages.buyCurrency} values={{ currency: security.BaseCurrency }} />
                  </QuoteSideTSX>
                  <QuotePrice>
                    <StackedFormattedNumber
                      number={quote?.OfferPx}
                      specification={security.PriceDisplaySpec}
                      highlightAll={false}
                    />
                  </QuotePrice>
                  <QuoteAmountTSX>
                    <InlineFormattedNumber
                      increment={
                        Currency === security.BaseCurrency
                          ? security.DefaultPriceIncrement
                          : security.DefaultSizeIncrement
                      }
                      number={quote?.OfferAmt}
                      currency={Currency === security.BaseCurrency ? security.QuoteCurrency : security.BaseCurrency}
                    />
                  </QuoteAmountTSX>
                </>
              ) : rejectedQuoteText ? (
                <Crossmark />
              ) : (
                <Spinner />
              )}
            </QuoteButton>
          )}
          {showSpreadOnRFQs && Side === OrderFormSides.Twoway && quoteSpread && quoteSpread !== '-' && (
            <QuoteSpread>{quoteSpread}</QuoteSpread>
          )}
        </QuoteButtons>
        {!(orderStep === 'rfq_accepted' || orderStep === 'new_confirmation') && quote && (
          <RemainingTime
            loading={!hasQuote}
            validUntilTime={quote.QuoteStatus === QuoteStatusEnum.Open ? quote.ValidUntilTime : undefined}
          />
        )}
        {quote?.Text != null && <ErrorMessage>{quote?.Text}</ErrorMessage>}
      </FormSection>
    </>
  );
}
const getSideVariant = (side?: OrderFormSides | null) =>
  side === OrderFormSides.Buy
    ? ButtonVariants.Positive
    : side === OrderFormSides.Sell
    ? ButtonVariants.Negative
    : ButtonVariants.Primary;

export const QuotePrice = styled.div`
  display: inline-block;
  margin: ${({ theme }) => theme.spacingDefault}px 0;
`;

const QuoteSideTSX = styled.div`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  line-height: 1;
`;

const QuoteAmountTSX = styled.div`
  font-size: ${({ theme }) => theme.fontSizeTiny}rem;
  line-height: 1;
`;
