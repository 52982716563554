import { createContext, useContext } from 'react';
import type { AccordionOutputs } from './useAccordion';

export const AccordionContext = createContext<AccordionOutputs | undefined>(undefined);
AccordionContext.displayName = 'AccordionContext';

export function useAccordionContext() {
  const context = useContext(AccordionContext);
  if (context === undefined) {
    throw new Error('Missing AccordionContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}
