import { hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';

import { setAlpha } from '../utils';
import { lightColors, mapLightTheme } from './LightTheme';

export const colors: Colors = {
  black: {
    default: '#000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    '000': hsla(220, 0.25, 0.88, 1),
    '010': hsla(220, 0.25, 0.95, 1),
    main: hsla(220, 0.25, 0.97, 1),
    '020': hsla(220, 0.25, 0.93, 1),
    '030': hsla(220, 0.25, 0.91, 1),
    '040': hsla(220, 0.25, 0.85, 1),
    '050': hsla(220, 0.25, 0.81, 1),
    '060': hsla(220, 0.25, 0.77, 1),
    '070': hsla(220, 0.25, 0.73, 1),
    '080': hsla(220, 0.25, 0.61, 1),
    '090': hsla(220, 0.25, 0.42, 1),
    '100': hsla(220, 0.25, 0.14, 1),
  },

  primary: {
    mute: setAlpha(0.1, '#4f51b0'),
    dim: setAlpha(0.4, '#4f51b0'),
    default: '#4f51b0',
    lighten: '#6b6dc9',
  },

  green: {
    mute: setAlpha(0.1, '#228a6e'),
    dim: setAlpha(0.4, '#228a6e'),
    default: '#228a6e',
    lighten: '#259c7c',
  },

  red: {
    mute: setAlpha(0.1, '#b52b65'),
    dim: setAlpha(0.4, '#b52b65'),
    default: '#b52b65',
    lighten: '#db538c',
  },

  yellow: {
    mute: 'rgba(204, 172, 62, 0.1)',
    dim: 'rgba(204, 172, 62, 0.4)',
    default: '#CCAC3E',
    lighten: '#F2D264',
  },

  blue: {
    mute: setAlpha(0.1, '#1878d9'),
    dim: setAlpha(0.4, '#1878d9'),
    default: '#1878d9',
    lighten: '#42a0ff',
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: lightColors.purple,
};

export const LightConservativeTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightConservative',

  fontUrl: 'https://fonts.googleapis.com/css2?family=News+Cycle&display=swap',
  fontFamily: "'News Cycle', sans-serif",

  borderColorHeader: colors.gray['020'],
  borderColorContent: colors.gray['020'],
  borderColorDivider: colors.gray['020'],

  backgroundBody: colors.gray['010'],

  sidebarThemeName: 'DarkConservative',

  spacingLayout: 1,

  borderRadiusDefault: 2,
  borderRadiusSmall: 2,
  borderRadiusTiny: 2,

  borderRadiusButtonDefault: 2,
  borderRadiusButtonSmall: 2,
  borderRadiusButtonTiny: 2,

  borderColorInput: `transparent transparent ${colors.gray['060']} transparent`,
  borderColorInputHover: `transparent transparent ${colors.gray['080']} transparent`,
  borderColorInputFocus: `transparent transparent ${colors.gray['070']} transparent`,
  borderColorInputInvalid: `transparent transparent ${colors.red.default} transparent`,
  borderColorInputInvalidFocus: `transparent transparent ${colors.red.lighten} transparent`,

  borderColorDefaultButton: colors.gray['060'],
  borderColorDefaultButtonHover: colors.gray['060'],
  borderColorDefaultButtonFocus: colors.gray['060'],
  borderWidthButton: 1,

  backgroundDefaultButton: colors.gray.main,
  backgroundDefaultButtonHover: colors.gray['030'],

  backgroundInput: colors.gray.main,
  backgroundInputInvalid: colors.red.mute,

  backgroundSelect: colors.gray.main,
  backgroundSelectHover: colors.gray.main,
  backgroundSelectFocus: colors.gray.main,

  borderColorSelect: `transparent transparent ${colors.gray['060']} transparent`,
  borderColorSelectHover: `transparent transparent ${colors.gray['080']} transparent`,
  borderColorSelectFocus: `transparent transparent ${colors.gray['070']} transparent`,
  borderColorSelectInvalid: `transparent transparent ${colors.red.default} transparent`,

  borderColorPositiveButton: colors.green.lighten,

  borderColorNegativeButton: colors.red.lighten,

  borderColorPrimaryButton: colors.primary.lighten,

  borderColorBlotterTable: colors.gray['020'],
});
