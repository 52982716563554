import RawDayPicker from 'react-day-picker';
import styled from 'styled-components';
import { ThemeTypes } from '../../styles';

export const DayPicker = styled(RawDayPicker)`
  padding: ${({ theme }) => theme.spacingComfortable}px;
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  width: 100%;

  .DayPicker-wrapper {
    position: relative;
    flex-direction: row;
    user-select: none;
  }

  .DayPicker-Months {
    display: flex;
    justify-content: space-between;
    gap: ${({ theme }) => theme.spacingLarge}px;
    flex-wrap: nowrap;
  }

  .DayPicker-Month {
    display: grid;
    gap: ${({ theme }) => theme.spacingTiny}px;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-flow: row;
    user-select: none;
    align-content: flex-start;
    width: 100%;
  }

  .DayPicker-NavBar {
    position: absolute;
    right: ${({ theme }) => theme.spacingSmall}px;
    top: ${({ theme }) => theme.spacingSmall}px;
    display: flex;
    gap: ${({ theme }) => theme.spacingTiny}px;
  }

  .DayPicker-NavButton {
    display: inline-block;
    position: relative;
    background-color: ${({ theme }) => theme.backgroundDefaultButton};

    border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
    color: ${({ theme }) => theme.colorTextPriorityButton};
    cursor: pointer;

    &:focus {
      outline: none;
      filter: brightness(2);
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-position: center;
      background-size: 0.5em;
      background-repeat: no-repeat;
    }
    &:hover {
      opacity: 0.8;
      background-color: ${({ theme }) => theme.backgroundPriorityButtonHover};
      color: ${({ theme }) => theme.colorTextPriorityButtonHover};
      &::before {
        filter: brightness(1.5);
      }
    }
  }

  .DayPicker-NavButton--prev::before {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAKCAYAAACXDi8zAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABpSURBVHgBbY67EYAwDEMlF3y2YAVKSpeUbMCWlClZIyOk4o4mIRw5LoS40j1ZloliNmMG8c3KH0RronSswQO9sgYXHR1r8F4WoMta3KvSqT2mQkqdMaV5+dcs3n1MIljJjVkn60H1EHsBpT03FxGA7LoAAAAASUVORK5CYII=');
  }

  .DayPicker-NavButton--next::before {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAKCAYAAACXDi8zAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAB0SURBVHgBbY4xDoAgDEU/DGqcPALXcGR09IQewTjp1tFrcARWGcCCISYNf2ja/37a4qJ7O4kMhDQQjUZPEuoeYQWSl1DlQkRTQEc8ThGPXax1qiYk1BX4fwFr+Lqd0yPf4JbTilfNTrXM8lXLLCDGdEgz6wXFrTV6B+FGhwAAAABJRU5ErkJggg==');
  }

  .DayPicker-NavButton--interactionDisabled {
    display: none;
  }

  .DayPicker-Caption {
    grid-column: 1 / 8;
    margin-bottom: ${({ theme }) => theme.spacingSmall}px;
    padding: ${({ theme }) => theme.spacingSmall}px;
    text-align: left;
    justify-self: start;
    color: ${({ theme }) => theme.colorTextAttention};
  }

  .DayPicker-Caption > div {
    font-weight: ${({ theme }) => theme.fontWeightMedium};
  }

  .DayPicker-Weekdays {
    display: contents;
    margin-top: 1em;
  }

  .DayPicker-WeekdaysRow {
    display: contents;
  }

  .DayPicker-Weekday {
    padding: ${({ theme }) => theme.spacingSmall}px;
    color: ${({ theme }) => theme.colors.gray['090']};
    text-align: center;
    font-size: ${({ theme }) => theme.fontSizeSmall}em;
    text-transform: uppercase;
    opacity: 0.5;
    font-weight: 500;
  }

  .DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
  }

  .DayPicker-Body {
    display: contents;
  }

  .DayPicker-Week {
    display: contents;
  }

  .DayPicker-NavButton {
    width: ${({ theme }) => theme.fontSizeBig}rem;
    height: ${({ theme }) => theme.fontSizeBig}rem;
  }

  .DayPicker-Day {
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
    color: ${({ theme }) => theme.colorTextPriorityButton};
    font-size: ${({ theme }) => theme.fontSizeTiny}rem;
    line-height: 1.25rem;
    border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
    background: ${({ theme }) => theme.backgroundDefaultButton};
    border: ${({ theme }) => `solid ${theme.borderWidthButton}px ${theme.borderColorDefaultButton}`};
    width: 100%;
  }

  .DayPicker-WeekNumber {
    padding: ${({ theme }) => theme.spacingSmall}px;
    min-width: 1em;
    border-right: 1px solid #eaecec;
    color: #8b9898;
    vertical-align: middle;
    text-align: right;
    font-size: ${({ theme }) => theme.fontSizeTiny}rem;
    cursor: pointer;
  }

  &.DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
  }

  .DayPicker-Footer {
    padding-top: ${({ theme }) => theme.spacingSmall}px;
  }

  .DayPicker-TodayButton {
    border: none;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    color: #4a90e2;
    font-size: ${({ theme }) => theme.fontSizeSmall}em;
    cursor: pointer;
  }

  /* Default modifiers */

  .DayPicker-Day {
    &:focus {
      outline: none;
      filter: ${({ theme }) => (theme.type === ThemeTypes.dark ? 'brightness(150%)' : 'brightness(85%)')};
    }
  }

  .DayPicker-Day--today {
    color: ${({ theme }) => theme.colorTextAttention};
    background: ${({ theme }) => theme.backgroundPriorityButton};
    border: ${({ theme }) => `solid ${theme.borderWidthButton}px ${theme.borderColorPriorityButton}`};
    font-weight: ${({ theme }) => theme.fontWeightBold};
  }

  .DayPicker-Day--outside {
    color: ${({ theme }) => theme.colorTextDefault};
    border-color: transparent;

    &:empty {
      background: transparent;
    }
    cursor: default;
  }

  .DayPicker-Day--disabled {
    color: ${({ theme }) => theme.colorTextSubtle};
    cursor: default;
    pointer-events: none;
    opacity: 0.5;
  }
  /* Example modifiers */

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;

    background-color: ${({ theme }) => theme.backgroundPriorityButtonHover};
    border: ${({ theme }) => `solid ${theme.borderWidthButton}px ${theme.borderColorPriorityButtonHover}`};
    color: ${({ theme }) => theme.colorTextAttention};

    &.DayPicker-Day--start {
      background-color: ${({ theme }) => theme.backgroundPriorityButtonHover};
    }

    &.DayPicker-Day--end {
      background-color: ${({ theme }) => theme.backgroundPriorityButtonHover};
    }
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    background-color: ${({ theme }) => theme.backgroundPriorityButtonHover};
  }

  &:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: ${({ theme }) => theme.backgroundPriorityButtonHover};
    color: ${({ theme }) => theme.colorTextPriorityButtonHover};
  }

  /* DayPickerInput */

  .DayPickerInput {
    display: inline-block;
  }

  .DayPickerInput-OverlayWrapper {
    position: relative;
  }

  .DayPickerInput-Overlay {
    position: absolute;
    left: 0;
    z-index: 1;

    background: white;
  }
`;
