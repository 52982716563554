import { IconName, type CustomerUserConfigNavigationItem } from '@talos/kyoko';

export interface NavPath {
  /** Eg "dealer", "portfolio" -- as in without the leading "/" */
  path: string;
  /** Label shown in the Nav Sidebar */
  label: string;
  /** Icon shown in the Nav Sidebar */
  icon?: IconName;
  /** Passed to the authorization function. For example, VIEW_DEALER. Leave undefined for none. */
  requiredPermission?: string;
  /** Whether or not this path has subnavitems, such as Dealer having "Monitoring" sub nav item etc. Leave undefined for false. */
  hasSubNavItems?: boolean;
}

// Ordering is important!
export const ALL_NAV_PATHS: CustomerUserConfigNavigationItem[] = [
  {
    path: '/',
    label: 'Market',
    icon: IconName.ChartBarBox,
  },
];

export const TOP_NAV_PATHS = ALL_NAV_PATHS;
