import type { RequestStream } from '../types/RequestStream';
import type { SubscriptionResponse } from '../types/SubscriptionResponse';
import { useConstant } from './useConstant';
import type { UseSubscriptionReturn } from './useSubscription';
import { useSubscription } from './useSubscription';
export function useStaticSubscription<TResponse extends SubscriptionResponse<any, any>>(
  request: RequestStream | RequestStream[],
  options?: Partial<{
    replay: boolean;
    loadAll: boolean;
  }>
): UseSubscriptionReturn<TResponse>;
export function useStaticSubscription<TResponse>(
  request: RequestStream | RequestStream[],
  options?: Partial<{
    replay: boolean;
    loadAll: boolean;
  }>
): UseSubscriptionReturn<SubscriptionResponse<TResponse>>;
export function useStaticSubscription<TResponse>(
  request: RequestStream | RequestStream[],
  options?: {
    replay?: boolean;
    loadAll?: boolean;
  }
): UseSubscriptionReturn<SubscriptionResponse<TResponse>>;
/**
 * Create a static web socket subscription.
 */
export function useStaticSubscription<T = any>(
  request: RequestStream | RequestStream[],
  options = {}
): UseSubscriptionReturn<SubscriptionResponse<T>> {
  const req = useConstant(request);
  return useSubscription<T>(req, options);
}
