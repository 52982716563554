import { useCallback, useEffect, useMemo, useState, type PropsWithChildren } from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';

import type { ColDefIntl } from '../components/BlotterTable/columns';
import { DEFAULT_LOCALE, getIntlKey, IntlContext } from '../contexts/IntlContext';
import { EMPTY_OBJECT } from '../utils/empty';
import { logger } from '../utils/logger';

const formatCell: ColDefIntl['formatCell'] = (params, messages, displayNameMap) => {
  if (params.context.current.intl.doNotOverwriteDisplayNames === true) {
    // In this case, we don't want to translate the display name, so we just return the DisplayName value.
    return displayNameMap?.get(params.value)?.DisplayName ?? params.value;
  }

  // Proceed with attempting to translate
  const intlKey = getIntlKey(params.value);
  const translatedValue =
    intlKey in messages ? params.context.current.intl.formatMessage(messages[intlKey]) : undefined;

  // If the translated value is not found, we return the original value
  return translatedValue ?? params.value;
};

export interface IntlProviderProps {
  /**
   * Callback passed in from project-level IntlProvider wrapper components, so that they
   * can reference their own local translations directories, but still have loading and storage of translations
   * managed at a central layer. Should accept a string locale parameter, and return a Promise that resolves to
   * the translations messages, in a Record<string, string> format. Overrides may also be provided,
   * these will not warn on duplicate keys.
   */
  loadMessages?: (locale: string) => {
    messages: Promise<Record<string, string>>;
    overrides?: Promise<Record<string, string>>;
  };
  /**
   * This component does not have access to user preferences, the implementer must provide the locale.
   * If the locale is not provided, the browser locale will be used.
   */
  locale: string | undefined;
}

export function IntlProvider({ children, loadMessages, locale: _locale }: PropsWithChildren<IntlProviderProps>) {
  const [locale, setLocale] = useState<string | undefined>(_locale ?? DEFAULT_LOCALE);

  const [isLoaded, setIsLoaded] = useState(false);
  const [messages, setMessages] = useState<Record<string, string> | null>(loadMessages ? null : EMPTY_OBJECT);
  const [agGridMessages, setAgGridMessages] = useState<Record<string, string> | undefined>(undefined);

  const loadAgGridMessages = useCallback((locale: string) => {
    return import(`../translations/agGrid.${locale}.json`)
      .then(mod => mod.default)
      .catch(error => {
        if (locale.includes('-')) {
          const language = locale.split('-')[0];

          return import(`../translations/agGrid.${language}.json`).then(mod => mod.default);
        }

        throw error;
      });
  }, []);

  const loadKyokoMessages = useCallback((locale: string) => {
    return import(`../translations/kyoko.${locale}.json`)
      .then(mod => mod.default)
      .catch(error => {
        if (locale.includes('-')) {
          const language = locale.split('-')[0];

          return import(`../translations/kyoko.${language}.json`).then(mod => mod.default);
        }

        throw error;
      });
  }, []);

  useEffect(() => {
    if (locale == null) {
      return;
    }
    const promises = [
      // Only use the whitelabel-testing subset for testing
      loadKyokoMessages(locale === 'testing' ? 'whitelabel-testing' : locale),
      loadAgGridMessages(locale),
    ];

    if (loadMessages && locale) {
      const { messages, overrides } = loadMessages(locale);
      promises.push(messages);
      if (overrides) {
        promises.push(overrides);
      }
    }

    Promise.all(promises)
      .then(([kyokoMessages, agGridMessages, messages = EMPTY_OBJECT, overrides = EMPTY_OBJECT]) => {
        captureExceptionForDuplicates(messages, kyokoMessages);
        setMessages({
          ...kyokoMessages,
          ...messages,
          ...overrides,
        });
        setAgGridMessages(agGridMessages);
      })
      .catch(() => {
        setMessages(EMPTY_OBJECT);
      });
  }, [loadAgGridMessages, loadKyokoMessages, loadMessages, locale]);

  useEffect(() => {
    if (locale && messages && agGridMessages) {
      setIsLoaded(true);
    }
  }, [agGridMessages, locale, messages]);

  const value = useMemo(() => {
    return {
      agGridMessages,
      isLoaded,
      locale: locale ?? DEFAULT_LOCALE,
      setLocale,
      doNotOverwriteDisplayNames: locale?.split('-').at(0) === 'en',
      formatCell,
    };
  }, [agGridMessages, isLoaded, locale]);

  const onIntlError = useCallback(
    (error: Error) => {
      if (locale === 'testing') {
        console.error('Intl Error:', error.message);
      }
    },
    [locale]
  );

  const onIntlWarn = useCallback(
    (warning: string) => {
      if (locale === 'testing') {
        console.warn('Intl Warning:', warning);
      }
    },
    [locale]
  );

  return (
    <ReactIntlProvider
      // A valid locale must to be passed in, so we default to DEFAULT_LOCALE if the locale is 'testing'
      locale={value.locale === 'testing' ? DEFAULT_LOCALE : value.locale}
      messages={messages ?? EMPTY_OBJECT}
      onError={onIntlError}
      onWarn={onIntlWarn}
    >
      <IntlContext.Provider value={value}>{children}</IntlContext.Provider>
    </ReactIntlProvider>
  );
}

function captureExceptionForDuplicates(messages: Record<string, string>, kyokoMessages: Record<string, string>) {
  Object.keys(messages).forEach(key => {
    if (kyokoMessages[key]) {
      logger.error(new Error(`Duplicate defineMessages id found: ${key}`));
    }
  });
}
