import { isBefore } from 'date-fns';
import {
  type DateRange,
  type DateRangeOrLookback,
  type LookbackWindow,
  type SerializedDateRange,
  type SerializedDateRangeOrLookback,
  isLookbackWindow,
} from './dateRange';

// Reasonable minimum date for modern date ranges
const MODERN_MIN_DATE = Object.freeze(new Date('1970-01-01T00:00:00Z'));
/**
 * Converts a serialized DateRange/LookbackWindow to a DateRange/LookbackWindow for use in controls
 * like DateRangePicker
 */
export function deserializeDateRangeOrLookback(
  dateRangeInput: SerializedDateRangeOrLookback | DateRangeOrLookback
): DateRange | LookbackWindow {
  if (isLookbackWindow(dateRangeInput)) {
    return dateRangeInput;
  }
  return deserializeDateRange(dateRangeInput);
}
/** When DateRanges are serialized, the dates are converted to strings
 * - To convert back to DateRange, we need to convert the strings back to Date objects
 * - Converted dateRnages are allowed inputs as a convenience
 **/
export const deserializeDateRange = (dateRangeOrLookback: SerializedDateRange | DateRange): DateRange => {
  if (isSerializedDateRange(dateRangeOrLookback)) {
    const result = {
      from: dateRangeOrLookback.from ? new Date(dateRangeOrLookback.from) : null,
      to: dateRangeOrLookback.to ? new Date(dateRangeOrLookback.to) : null,
    };

    if (result.from == null && result.to == null) {
      throw new Error('Invalid date range: both from and to dates are null');
    }

    if (result.from && isBefore(result.from, MODERN_MIN_DATE)) {
      throw new Error(`Invalid date range: from date ${result.from} is before ${MODERN_MIN_DATE}`);
    }

    if (result.from && result.to) {
      if (isBefore(result.from, result.to)) {
        return result;
      } else {
        throw new Error(`Invalid date range: from date ${result.from} is after to date ${result.to}`);
      }
    }
    return result;
  }
  return dateRangeOrLookback;
};

function isSerializedDateRange(
  dateRangeOrLookback: SerializedDateRange | DateRange
): dateRangeOrLookback is SerializedDateRange {
  const from = (dateRangeOrLookback as SerializedDateRange).from;
  const to = (dateRangeOrLookback as SerializedDateRange).to;
  if ((from == null || typeof from === 'string') && (to == null || typeof to === 'string')) {
    return true;
  }
  return false;
}
