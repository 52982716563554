import { useCallback, type CSSProperties } from 'react';
import { useMixpanel } from '../../contexts';
import { useIntl } from '../../hooks';
import { MixpanelEvent } from '../../tokens';
import { prettyWarningSeverity } from '../../types/WarningSeverity';
import { HStack } from '../Core';
import { WarningSeverityIcon } from '../Icons';
import { Tooltip } from '../Tooltip';
import type { AgGridWarningProps } from './types';

export function AgGridWarning({ value, tooltip, tooltipWidth }: AgGridWarningProps) {
  const intl = useIntl();
  const mixpanel = useMixpanel();

  const onOpen = useCallback(() => {
    mixpanel.track(MixpanelEvent.HoverWarningIcon);
  }, [mixpanel]);

  if (value == null) {
    return null;
  }

  const { severity } = value;

  if (tooltip) {
    return (
      <Tooltip
        isSmall={false}
        onOpen={onOpen}
        targetStyle={targetStyle}
        usePortal
        placement="right-end"
        tooltip={tooltip}
        contentMaxWidth={tooltipWidth}
      >
        <WarningSeverityIcon
          severity={severity}
          data-testid="warning-bar"
          aria-label={prettyWarningSeverity(severity, intl)}
        />
      </Tooltip>
    );
  }

  return (
    <HStack w="100%" h="100%">
      <WarningSeverityIcon
        severity={severity}
        data-testid="warning-bar"
        aria-label={prettyWarningSeverity(severity, intl)}
      />
    </HStack>
  );
}

const targetStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
