import styled from 'styled-components';
import { ICON_SIZES, Icon, IconName, type IconProps } from '../Icons';

export type BulletProps = Omit<IconProps, 'icon'>;

export const Bullet = styled(({ size, ...props }: BulletProps) => (
  <Icon icon={IconName.DotSolid} size={size ?? ICON_SIZES.SMALL} {...props} />
))<{ color: string }>`
  transition: 300ms;
  color: ${p => p.color};
`;

export const BulletText = styled.span`
  font-size: ${({ theme }) => theme.fontSizeDefault}em;
`;
