import type { CSSProperties, HTMLAttributes } from 'react';
import styled, { css, type DefaultTheme } from 'styled-components';
import { getThemeDimensionValue, parseColor, parseDimension, parseFontSize } from '../../styles';

export interface BoxProps extends Omit<HTMLAttributes<HTMLDivElement>, 'content'> {
  /** Width */
  w?: CSSProperties['width'];
  /** Height */
  h?: CSSProperties['height'];
  /** Margin */
  m?: CSSProperties['margin'] | string;
  /** Margin Horizontal */
  mx?: CSSProperties['margin'] | string;
  /** Margin Vertical */
  my?: CSSProperties['margin'] | string;
  /** Margin Top */
  mt?: CSSProperties['marginTop'] | string;
  /** Margin Right */
  mr?: CSSProperties['marginRight'] | string;
  /** Margin Bottom */
  mb?: CSSProperties['marginBottom'] | string;
  /** Margin Left */
  ml?: CSSProperties['marginLeft'] | string;

  /** Padding */
  p?: CSSProperties['padding'] | string;
  /** Padding Horizontal */
  px?: CSSProperties['padding'] | string;
  /** Padding Vertical */
  py?: CSSProperties['padding'] | string;
  /** Padding Top */
  pt?: CSSProperties['paddingTop'] | string;
  /** Padding Right */
  pr?: CSSProperties['paddingRight'] | string;
  /** Padding Bottom */
  pb?: CSSProperties['paddingBottom'] | string;
  /** Padding Left */
  pl?: CSSProperties['paddingLeft'] | string;

  maxWidth?: CSSProperties['maxWidth'];
  maxHeight?: CSSProperties['maxHeight'];
  minWidth?: CSSProperties['minWidth'];
  minHeight?: CSSProperties['minHeight'];
  aspectRatio?: CSSProperties['aspectRatio'];

  flexBasis?: CSSProperties['flexBasis'];
  alignSelf?: CSSProperties['alignSelf'];
  justifySelf?: CSSProperties['justifySelf'];

  flex?: CSSProperties['flex'];
  display?: CSSProperties['display'];

  justifyContent?: CSSProperties['justifyContent'];
  alignItems?: CSSProperties['alignItems'];
  flexDirection?: CSSProperties['flexDirection'];
  flexWrap?: CSSProperties['flexWrap'];
  gap?: CSSProperties['gap'] | keyof DefaultTheme | string | number;
  columnGap?: CSSProperties['columnGap'] | keyof DefaultTheme | string | number;
  rowGap?: CSSProperties['rowGap'] | keyof DefaultTheme | string | number;
  gridTemplateRows?: CSSProperties['gridTemplateRows'];
  gridTemplateColumns?: CSSProperties['gridTemplateColumns'];
  gridTemplateAreas?: CSSProperties['gridTemplateAreas'];
  gridColumn?: CSSProperties['gridColumn'];
  gridRow?: CSSProperties['gridRow'];

  position?: CSSProperties['position'];
  top?: CSSProperties['top'];
  right?: CSSProperties['right'];
  left?: CSSProperties['left'];
  bottom?: CSSProperties['bottom'];
  inset?: CSSProperties['inset'];
  fontSize?: CSSProperties['fontSize'];
  fontWeight?: CSSProperties['fontWeight'];
  lineHeight?: CSSProperties['lineHeight'];
  textDecoration?: CSSProperties['textDecoration'];
  textTransform?: CSSProperties['textTransform'];
  textAlign?: CSSProperties['textAlign'];
  textOverflow?: CSSProperties['textOverflow'];
  whiteSpace?: CSSProperties['whiteSpace'];
  verticalAlign?: CSSProperties['verticalAlign'];
  border?: CSSProperties['border'];
  borderWidth?: CSSProperties['borderWidth'];
  borderStyle?: CSSProperties['borderStyle'];
  borderTop?: CSSProperties['borderTop'];
  borderRight?: CSSProperties['borderRight'];
  borderBottom?: CSSProperties['borderBottom'];
  borderLeft?: CSSProperties['borderLeft'];
  borderColor?: CSSProperties['borderColor'];
  borderRadius?: CSSProperties['borderRadius'];
  transition?: CSSProperties['transition'];

  background?: CSSProperties['background'];
  overflow?: CSSProperties['overflow'];
  overflowX?: CSSProperties['overflowX'];
  overflowY?: CSSProperties['overflowY'];
  opacity?: CSSProperties['opacity'];

  zIndex?: CSSProperties['zIndex'];
  cursor?: CSSProperties['cursor'];

  boxShadow?: CSSProperties['boxShadow'];
  backdropFilter?: CSSProperties['backdropFilter'];

  gridArea?: CSSProperties['gridArea'];
}

const noForwardProps = new Set(['fontSize', 'overflow', 'color']);

/** Simple styled div
 *
 * Set box properties without creating a new component.
 */

export const box = css<BoxProps>`
  ${({ w, theme }) => w && `width: ${parseDimension(theme, w)}`};
  ${({ h, theme }) => h && `height: ${parseDimension(theme, h)}`};

  ${({ maxWidth, theme }) => maxWidth && `max-width: ${parseDimension(theme, maxWidth)}`};
  ${({ maxHeight, theme }) => maxHeight && `max-height: ${parseDimension(theme, maxHeight)}`};
  ${({ minWidth, theme }) => minWidth && `min-width: ${parseDimension(theme, minWidth)}`};
  ${({ minHeight, theme }) => minHeight && `min-height: ${parseDimension(theme, minHeight)}`};
  ${({ aspectRatio }) => aspectRatio && `aspect-ratio: ${aspectRatio}`};

  ${({ m, theme }) => m != null && `margin: ${parseDimension(theme, m)}`};
  ${({ mx, theme }) =>
    mx != null && `margin-left: ${parseDimension(theme, mx)}; margin-right: ${parseDimension(theme, mx)}`};
  ${({ my, theme }) =>
    my != null && `margin-top: ${parseDimension(theme, my)}; margin-bottom: ${parseDimension(theme, my)}`};
  ${({ mt, theme }) => mt != null && `margin-top: ${parseDimension(theme, mt)}`};
  ${({ mr, theme }) => mr != null && `margin-right: ${parseDimension(theme, mr)}`};
  ${({ mb, theme }) => mb != null && `margin-bottom: ${parseDimension(theme, mb)}`};
  ${({ ml, theme }) => ml != null && `margin-left: ${parseDimension(theme, ml)}`};

  ${({ p, theme }) => p != null && `padding: ${parseDimension(theme, p)}`};
  ${({ px, theme }) =>
    px != null && `padding-left: ${parseDimension(theme, px)}; padding-right: ${parseDimension(theme, px)}`};
  ${({ py, theme }) =>
    py != null && `padding-top: ${parseDimension(theme, py)}; padding-bottom: ${parseDimension(theme, py)}`};
  ${({ pt, theme }) => pt != null && `padding-top: ${parseDimension(theme, pt)}`};
  ${({ pr, theme }) => pr != null && `padding-right: ${parseDimension(theme, pr)}`};
  ${({ pb, theme }) => pb != null && `padding-bottom: ${parseDimension(theme, pb)}`};
  ${({ pl, theme }) => pl != null && `padding-left: ${parseDimension(theme, pl)}`};

  ${({ flexBasis }) => flexBasis && `flex-basis: ${flexBasis}`};
  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf}`};
  ${({ justifySelf }) => justifySelf && `justify-self: ${justifySelf}`};

  ${({ flex }) => flex && `flex: ${flex}`};
  ${({ display }) => display && `display: ${display}`};

  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection}`};
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap}`};
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  ${({ flex }) => flex && `flex: ${flex}`};
  ${({ theme, gap }) => gap && `gap: ${parseDimension(theme, gap)}`};
  ${({ theme, columnGap }) => columnGap && `column-gap: ${parseDimension(theme, columnGap)}`};
  ${({ theme, rowGap }) => rowGap && `row-gap: ${parseDimension(theme, rowGap)}`};
  ${({ gridColumn }) => gridColumn && `grid-column: ${gridColumn}`};
  ${({ gridRow }) => gridRow && `grid-row: ${gridRow}`};
  ${({ gridTemplateColumns }) => gridTemplateColumns && `grid-template-columns: ${gridTemplateColumns}`};
  ${({ gridTemplateRows }) => gridTemplateRows && `grid-template-rows: ${gridTemplateRows}`};
  ${({ gridTemplateAreas }) => gridTemplateAreas && `grid-template-areas: ${gridTemplateAreas}`};
  ${({ theme, color }) => color && `color: ${parseColor(theme, color)};`}

  ${({ position }) => position && `position: ${position}`};
  ${({ theme, top }) => top != null && `top: ${parseDimension(theme, top)}`};
  ${({ theme, right }) => right != null && `right: ${parseDimension(theme, right)}`};
  ${({ theme, bottom }) => bottom != null && `bottom: ${parseDimension(theme, bottom)}`};
  ${({ theme, left }) => left != null && `left: ${parseDimension(theme, left)}`};
  ${({ theme, inset }) => inset != null && `inset: ${parseDimension(theme, inset)}`};
  ${({ theme, fontSize }) => fontSize && `font-size: ${parseFontSize(theme, fontSize)}`};
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};
  ${({ theme, fontWeight }) =>
    fontWeight && `font-weight: ${getThemeDimensionValue(theme, fontWeight.toString()) ?? fontWeight}`};
  ${({ theme, lineHeight }) =>
    lineHeight && `line-height: ${getThemeDimensionValue(theme, lineHeight.toString()) ?? lineHeight}`};
  ${({ textDecoration }) => textDecoration != null && `text-decoration: ${textDecoration}`};
  ${({ textTransform }) => textTransform && `text-transform: ${textTransform}`};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
  ${({ textOverflow }) => textOverflow && `text-overflow: ${textOverflow}`};
  ${({ whiteSpace }) => whiteSpace && `white-space: ${whiteSpace}`};
  ${({ verticalAlign }) => verticalAlign && `vertical-align: ${verticalAlign}`};
  ${({ border }) => border && `border: ${border}`};
  ${({ borderTop }) => borderTop && `border-top: ${borderTop}`};
  ${({ borderRight }) => borderRight && `border-right: ${borderRight}`};
  ${({ borderBottom }) => borderBottom && `border-bottom: ${borderBottom}`};
  ${({ borderLeft }) => borderLeft && `border-left: ${borderLeft}`};
  ${({ borderColor, theme }) => borderColor && `border-color: ${parseColor(theme, borderColor)}`};
  ${({ borderWidth, theme }) => borderWidth && `border-width: ${parseDimension(theme, borderWidth)}`};
  ${({ borderStyle }) => borderStyle && `border-style: ${borderStyle}`};
  ${({ borderRadius, theme }) => borderRadius && `border-radius: ${parseDimension(theme, borderRadius)}`};

  ${({ overflow }) => overflow && `overflow: ${overflow}`};
  ${({ opacity }) => opacity != null && `opacity: ${opacity}`};

  ${({ zIndex }) => zIndex != null && `z-index: ${zIndex}`};
  ${({ cursor }) => cursor != null && `cursor: ${cursor}`};

  ${({ transition }) => transition != null && `transition: ${transition}`};

  ${({ boxShadow, theme }) => boxShadow != null && `box-shadow: ${parseColor(theme, boxShadow)}`};
  ${({ backdropFilter, theme }) => backdropFilter != null && `backdrop-filter: ${parseColor(theme, backdropFilter)}`};

  ${({ gridArea }) => gridArea && `grid-area: ${gridArea}`};
`;

export const Box = styled.div
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) => !noForwardProps.has(prop) && defaultValidatorFn(prop),
  })
  .attrs<BoxProps>(({ theme, ...props }) => ({
    style: {
      ...props.style,
      // Move a few props to style rather than the generated css class
      // These ones change a lot, we don't want to generate a unique class for every variation of them
      background: props.background
        ? typeof props.background === 'string'
          ? parseColor(theme, props.background)
          : props.background
        : props.style?.background,
    },
  }))`
  ${box};
`;
