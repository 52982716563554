import type { ICellRendererParams } from 'ag-grid-enterprise';
import { Toggle } from '../Form/Toggle';

export interface AgGridToggleProps extends ICellRendererParams {
  disabled?: boolean;
}

export const AgGridToggle = ({ value, setValue, disabled }: AgGridToggleProps) => {
  return (
    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Toggle checked={value} disabled={disabled} onChange={() => setValue?.(!value)} />
    </div>
  );
};
