import type {
  CellDoubleClickedEvent,
  ColumnMovedEvent,
  ColumnResizedEvent,
  ColumnRowGroupChangedEvent,
  ColumnVisibleEvent,
  ExpandCollapseAllEvent,
  FirstDataRenderedEvent,
  RowClickedEvent,
  RowDataUpdatedEvent,
  RowDoubleClickedEvent,
  RowGroupOpenedEvent,
  SortChangedEvent,
} from 'ag-grid-community';
import type {
  BaseExportParams,
  ColDef,
  FlashCellsParams,
  GetContextMenuItemsParams,
  GetMainMenuItemsParams,
  GridApi,
  GridOptions,
  IRowNode,
  RowClassRules,
} from 'ag-grid-enterprise';
import { compact, isEmpty, isEqual, uniqBy } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { useMixpanel } from '../../contexts/MixpanelContext';
import { useDynamicCallback } from '../../hooks';
import { useConstant } from '../../hooks/useConstant';
import { MixpanelEvent, MixpanelEventProperty } from '../../tokens/mixpanel';
import { WarningSeverity } from '../../types/WarningSeverity';
import { EMPTY_OBJECT, logger } from '../../utils';
import { WARNING_ROW_CLASSNAME } from '../AgGrid/types';
import { useBlotterTableContext } from './BlotterTableContext';
import type { BlotterTablePauseProps } from './BlotterTablePauseButton.types';
import { getCustomColDefProperties } from './columns';
import { getAgGridColId } from './columns/getAgGridColId';
import type { Column } from './columns/types';
import { useGetDefaultContextMenuItems } from './contextMenu';
import { compileTransactions } from './helpers';
import {
  AGGRID_AUTOCOLUMN_ID,
  BlotterDensity,
  type BlotterTableRow,
  type BlotterTableSort,
  type ExportDataAsCsvParams,
  type ExportDataAsExcelParams,
  type GetSheetDataForExcelParams,
  type TalosBlotterExportParams,
  type UseBlotterTable,
  type UseBlotterTableProps,
  type UseBlotterTableUtilitiesOutput,
} from './types';
import { useColumnDefs } from './useColumnDefs';
import {
  alphabeticalGroupOrder,
  cellCsvSafety,
  expandAllNodesInGroup,
  getAllNodesInGroup,
  getAllParentsOfNodeInclusive,
  getParamsFormatted,
  quickFilterParser,
  removeUnnecessarySeparators,
  safeGridApi,
} from './utils';

export function useBlotterTable<R>({
  dataObservable,
  pinnedRowDataPipe,
  pinnedRowDataObs,
  rowID,
  columns: flatColumns,
  groupableColumns,
  domLayout,
  rowHeight,
  flashRows: initialFlashRows,
  animateRows = false,
  fitColumns = false,
  density,
  selection,
  showPinnedRows = true,
  clientLocalFilter,
  renderEmpty,
  onRowSelectionChanged,
  onColumnsChanged,
  onSortChanged,
  onDoubleClickRow,
  onDoubleClickCell,
  onClickRow,
  sort,
  getContextMenuItems,
  getExtraMainMenuItems,
  handleClickJson,
  suppressAggFuncInHeader = true,
  onFirstDataRendered,
  context: customBlotterContext,
  rowGroupPanelShow,
  quickSearchParams,
  autoGroupColumnDef,
  pauseParams,
  supportColumnColDefGroups,
  customColumnUpdate,
  ...otherGridOptions
}: UseBlotterTableProps<R>): UseBlotterTable<R> {
  const supplementaryGridOptions = useMemo(() => {
    if (isEmpty(otherGridOptions)) {
      return EMPTY_OBJECT;
    }
    return otherGridOptions;
  }, [otherGridOptions]);

  const columnsToUse = groupableColumns ?? flatColumns;
  const {
    rowHeightBlotterTableCompact,
    rowHeightBlotterTableDefault,
    rowHeightBlotterTableComfortable,
    rowHeightBlotterTableVeryComfortable,
  } = useTheme();
  const [paused, setPaused] = useState(false);
  const [api, setApi] = useState<GridApi>();
  const flashRows = useConstant(initialFlashRows);
  const onGridReady: NonNullable<GridOptions<R>['onGridReady']> = useCallback(params => {
    setApi(params.api);
  }, []);

  useEffect(() => {
    setApi(undefined);
  }, []);

  const rowNodeId = useRef(rowID);
  useEffect(() => {
    rowNodeId.current = rowID;
  }, [rowID]);
  const getRowId = useCallback(({ data }) => (rowNodeId.current ? data?.[rowNodeId.current] : null), []);

  // Subscribe to data and add to blotter
  const dataState = useConstant(new Set<string>());
  useEffect(() => {
    if (api && dataObservable && !paused) {
      const subscription = dataObservable.subscribe(next => {
        if ((isEmpty(next.data) && dataState.size === 0) || rowID == null) {
          api.applyTransactionAsync({ add: [] });
          api.showNoRowsOverlay();
          return;
        }
        const transactions = compileTransactions(dataState, next.data, rowID as string, !!next.initial);

        if (!isEmpty(transactions.add) || !isEmpty(transactions.update) || !isEmpty(transactions.remove)) {
          api.applyTransactionAsync(transactions, () => {
            if (next.initial || !flashRows) {
              return;
            }
            for (const flash of flashRows) {
              api.flashCells({
                rowNodes: transactions[flash]
                  .map((row: any) => api.getRowNode(row[rowNodeId.current as string]))
                  .filter(row => row != null) as IRowNode[],
                flashDelay: 5000,
                fadeDelay: 2000,
              });
            }
          });
        }
      });

      return () => {
        // Clear out blotter and unsubscribe
        subscription.unsubscribe();
      };
    }
  }, [api, dataObservable, dataState, flashRows, rowID, paused]);

  // We allow the implementer to either pass a pipe in order to chain of for example an internal observable,
  // Or we allow the implementer to provide their own pinnedRowDataObs. But only one of these can be used
  const pinnedRowDataObsToUse = useMemo(
    () => (dataObservable && pinnedRowDataPipe ? dataObservable.pipe(pinnedRowDataPipe) : pinnedRowDataObs),
    [dataObservable, pinnedRowDataPipe, pinnedRowDataObs]
  );

  // Sub to pinnedRowDataObservable and update pinned top row data when it fires
  useEffect(() => {
    if (api && pinnedRowDataObsToUse && !paused) {
      let timer: ReturnType<typeof setTimeout> | null = null;
      const subscription = pinnedRowDataObsToUse.subscribe(next => {
        // Always clear any previous timer before doing anything new
        if (timer != null) {
          clearTimeout(timer);
        }
        timer = setTimeout(() => {
          if (showPinnedRows) {
            // When updating the pinned top row data (for now we only support one pinned top row),
            // we grab the current row and update its internal data to have the row not "re-mount" on each update.
            // Otherwise, on each update, any open context menu stemming from the pinned top row will close
            const pinnedTopRow = api.getPinnedTopRow(0);
            if (pinnedTopRow) {
              pinnedTopRow.setData(next);
            } else {
              api.setGridOption('pinnedTopRowData', [next]);
            }
          }
        }, 0);
      }); // recommended by aggrid to do setTimeout here

      if (!showPinnedRows) {
        api.setGridOption('pinnedTopRowData', []);
      }

      return () => {
        timer != null && clearTimeout(timer);
        subscription.unsubscribe();
      };
    }
  }, [api, showPinnedRows, pinnedRowDataObsToUse, paused]);

  // Utility functions
  const utilities = useBlotterTableUtilities<R>(api);
  const { addRow, getRows, getSelectedRows } = utilities;
  // Create AgGrid ColumnDefs from Columns
  const columnDefs = useColumnDefs<R>(columnsToUse, {
    handleClickJson,
    exportDataAsCSV: utilities.exportDataAsCSV,
    supportColumnColDefGroups,
  });

  // Mechanism for getting current state of columns in our internal definition
  const getColumns = useCallback(() => {
    if (api === undefined) {
      // Throwing in this case as returning an empty array might cause us to overwrite our column definitions by accident
      const error = new Error('getColumns() called too early before grid was mounted and api (GridApi) was defined');
      logger.error(error);
      throw error;
    }
    const next: Column[] = [];
    const state = api.getColumnState();
    // AGGRID_AUTOCOLUMN_ID is a special column that we don't handle from getColumnState
    // TODO: Implement ability to restore state for AutoColumn (optionally)
    const columnsFromState = state.filter(columnState => columnState.colId !== AGGRID_AUTOCOLUMN_ID);
    for (const columnState of columnsFromState) {
      const column = flatColumns.find(column => columnState.colId === getAgGridColId(column));
      if (column == null) {
        console.warn(`Could not find column for ${columnState.colId}`, columnState);
      } else {
        next.push({
          ...column,
          width: columnState.width,
          hide: columnState.hide === null ? undefined : columnState.hide,
          // AgGrid throws errors if we try to set rowGroup or rowGroupIndex when treeData is true
          rowGroup: otherGridOptions.treeData ? undefined : columnState.rowGroup ?? undefined,
          rowGroupIndex: otherGridOptions.treeData ? undefined : columnState.rowGroupIndex ?? undefined,
        });
      }
    }
    return next;
  }, [api, flatColumns, otherGridOptions.treeData]);

  // Initial setup
  useBlotterTableInitialSetup<R>({
    autoGroupColumnDef,
    columnDefs,
    sort,
    fitColumns,
    api,
    hasCustomColumnUpdate: customColumnUpdate != null,
  });

  // Perform custom column updates if needed based on the current state of the blotter and data
  useEffect(() => {
    const cleanup = customColumnUpdate?.({
      dataObservable,
      autoGroupColumnDef,
      columnDefs,
      sort,
      fitColumns,
      api,
    });
    return cleanup;
  }, [api, autoGroupColumnDef, columnDefs, customColumnUpdate, dataObservable, fitColumns, sort]);

  // Event handlers
  useBlotterTableEventHandlers(
    {
      columns: flatColumns,
      sort,
      onColumnsChanged,
      onSortChanged,
      onDoubleClickRow,
      onDoubleClickCell,
      onFirstDataRendered,
      onClickRow,
      getColumns,
    },
    api
  );

  const onSelectionChanged = useCallback(() => {
    const selectedRows = getSelectedRows();
    onRowSelectionChanged != null && onRowSelectionChanged(selectedRows);
  }, [onRowSelectionChanged, getSelectedRows]);

  // Clipboard
  const processCellForClipboard = getParamsFormatted;

  // Context
  const blotterTableContext = useBlotterTableContext();
  const agGridContext = useRef<any>(null);
  useEffect(() => {
    agGridContext.current = {
      ...blotterTableContext,
      ...customBlotterContext,
      getRows,
      addRow,
    };
    api?.refreshCells({ force: true });
  }, [api, blotterTableContext, customBlotterContext, getRows, addRow]);

  const getDefaultContextMenuItems = useGetDefaultContextMenuItems();
  // Context menu
  // This function intercepts the getting of context menu items (called on context menu open) and performs some uniform logic
  const smartGetContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams) => {
      const rightClickedNode = params.node;
      if (rightClickedNode == null) {
        // Shouldn't happen
        logger.warn('No right clicked node found when attempting to build the BlotterTable AgGrid Context Menu');
        return [];
      }
      const selectedNodes = params.api.getSelectedNodes().filter(node => node.displayed);
      const isRightClickedNodeInSelection = selectedNodes.some(node => node.id === rightClickedNode.id);

      if (!isRightClickedNodeInSelection) {
        params.api.deselectAll();
      }

      if (rightClickedNode.group) {
        // If we have right clicked on a group row, we make sure to select the group row itself as well as all of its children before proceeding
        const nodes = [rightClickedNode, ...getAllNodesInGroup(rightClickedNode)];
        params.api.setNodesSelected({ nodes, newValue: true, source: 'rowClicked' });
      } else if (!rightClickedNode.rowPinned) {
        // Case of right-clicking on a normal row (also making sure its not pinned, aggrid doesnt like that), we select it
        rightClickedNode.setSelected(true, false);
      }

      // Either call the specified function or fallback to our default
      const items = getContextMenuItems?.(params) ?? getDefaultContextMenuItems(params);
      return removeUnnecessarySeparators(items);
    },
    [getContextMenuItems, getDefaultContextMenuItems]
  );

  // Main menu
  const getMainMenuItems = useMemo(() => {
    return (params: GetMainMenuItemsParams) => {
      const extras = getExtraMainMenuItems ? getExtraMainMenuItems(params) : [];
      if (extras.length > 0) {
        extras.push('separator');
      }
      return removeUnnecessarySeparators(extras.concat(['autoSizeThis', 'autoSizeAll', 'separator', 'resetColumns']));
    };
  }, [getExtraMainMenuItems]);

  const rowClassRules: RowClassRules | undefined = useMemo(() => {
    if (columnsToUse.find(c => c.type === 'warning' && c.hide !== true)) {
      return {
        [WARNING_ROW_CLASSNAME]: params => params?.data?.warningSeverity === WarningSeverity.HIGH,
      };
    }
    return undefined;
  }, [columnsToUse]);

  const [quickFilterText, setQuickFilterText] = useState('');

  useEffect(() => {
    if (api) {
      // We allow the implementer to control the filterText if they want. Otherwise, we hold the state.
      api.setGridOption('quickFilterText', quickSearchParams?.filterText ?? quickFilterText);
    }
  }, [api, quickSearchParams?.filterText, quickFilterText]);

  const gridOptions = useMemo<GridOptions<R> | null>(
    () => ({
      onGridReady,
      getRowId,
      rowHeight:
        rowHeight ??
        (density === BlotterDensity.Compact
          ? rowHeightBlotterTableCompact
          : density === BlotterDensity.Comfortable
          ? rowHeightBlotterTableComfortable
          : density === BlotterDensity.VeryComfortable
          ? rowHeightBlotterTableVeryComfortable
          : rowHeightBlotterTableDefault),
      domLayout,
      animateRows,
      selection,
      rowClassRules,
      onSelectionChanged,
      context: agGridContext,
      getContextMenuItems: smartGetContextMenuItems,
      processCellForClipboard,
      noRowsOverlayComponentParams: { renderEmpty },
      isExternalFilterPresent: () => clientLocalFilter !== undefined,
      doesExternalFilterPass: node => clientLocalFilter?.(node) ?? true,
      cacheQuickFilter: true,
      quickFilterParser: quickFilterParser,
      getMainMenuItems,
      initialGroupOrderComparator: alphabeticalGroupOrder,
      suppressAggFuncInHeader,
      rowGroupPanelShow,
      ...supplementaryGridOptions,
    }),
    [
      onGridReady,
      getRowId,
      rowHeight,
      density,
      rowHeightBlotterTableCompact,
      rowHeightBlotterTableComfortable,
      rowHeightBlotterTableVeryComfortable,
      rowHeightBlotterTableDefault,
      domLayout,
      animateRows,
      selection,
      rowClassRules,
      onSelectionChanged,
      smartGetContextMenuItems,
      processCellForClipboard,
      renderEmpty,
      getMainMenuItems,
      suppressAggFuncInHeader,
      rowGroupPanelShow,
      supplementaryGridOptions,
      clientLocalFilter,
    ]
  );
  useEffect(() => {
    api?.resetRowHeights();
  }, [api, gridOptions?.rowHeight]);

  // Whenever clientLocalFilter or the quickFilterText states change, we tell the blotter that filters have changed
  // onFilterChange called in timeout to allow updates to filter function to get picked up correctly by grid
  useEffect(() => {
    const timeout = setTimeout(() => {
      api?.onFilterChanged();
    });
    return () => {
      clearTimeout(timeout);
    };
  }, [api, clientLocalFilter]);

  const pause = useDynamicCallback(() => {
    setPaused(true);
  });

  const resume = useDynamicCallback(() => {
    api?.setGridOption('rowData', []);
    dataState.clear();
    setPaused(false);
  });

  const pauseProps: BlotterTablePauseProps = {
    pause,
    paused,
    resume,
    showPauseButton: pauseParams?.showPauseButton ?? false,
  };

  return {
    dataObservable,
    gridOptions,
    density,
    sort,
    onSortChanged,
    getColumns,
    blotterTableFiltersProps: {
      quickFilterText,
      onQuickFilterTextChanged: setQuickFilterText,
      ...pauseProps,
    },
    pauseProps,
    ...utilities,
  };
}

export type UseBlotterTableInitialSetupArg<TRowType> = Pick<GridOptions, 'autoGroupColumnDef'> & {
  api: GridApi<TRowType> | undefined;
  columnDefs: NonNullable<GridOptions['columnDefs']>;
  sort?: BlotterTableSort<TRowType>;
  fitColumns: boolean;
  /** If the column update is custom, we don't want to apply the default column update logic */
  hasCustomColumnUpdate: boolean;
};

function useBlotterTableInitialSetup<TRowType = any>({
  autoGroupColumnDef,
  columnDefs,
  sort: initialSorts,
  fitColumns,
  api,
  hasCustomColumnUpdate,
}: UseBlotterTableInitialSetupArg<TRowType>) {
  // Apply columns.
  // This useEffect has several dependencies, but the only one which should change is column defs. That can change quite often based on settings, runtime configs, etc.
  // So this useEffect should be seen as the useEffect which pushes any changes made to the column setup into the blotter itself both on init and during "runtime" (on-the-fly column def changes)
  useEffect(() => {
    if (api == null || hasCustomColumnUpdate) {
      return;
    }

    const { workingColumnDefs, workingAutoGroupColumnDef } = applySortsToColumns({
      api,
      columnDefs,
      autoGroupColumnDef,
      initialSorts,
    });

    // Propagate this now-ready set of columns to the blotter.
    api.setGridOption('columnDefs', workingColumnDefs);
    workingAutoGroupColumnDef && api.setGridOption('autoGroupColumnDef', workingAutoGroupColumnDef);

    if (fitColumns) {
      api.sizeColumnsToFit();
    }
  }, [api, columnDefs, fitColumns, initialSorts, autoGroupColumnDef, hasCustomColumnUpdate]);
}

function useBlotterTableUtilities<R>(api?: GridApi): UseBlotterTableUtilitiesOutput<R> {
  const mixpanel = useMixpanel();
  const addRow = useCallback(
    (data?: R) => {
      if (api == null) {
        return;
      }
      api.applyTransactionAsync({ add: [{ ...data }] });
    },
    [api]
  );
  const getRows = useCallback(() => {
    const rows: BlotterTableRow<R>[] = [];
    if (api != null) {
      api.forEachNode(node => {
        rows.push({
          data: node.data,
          setData: newData => node.setData(newData),
          remove: () => api.applyTransactionAsync({ remove: [node.data] }),
          setSelected: (selected: boolean) => {
            node.setSelected(selected);
          },
        });
      });
    }
    return rows;
  }, [api]);
  const getSelectedRows = useCallback(() => {
    if (api != null) {
      const selectedNodes = api.getSelectedNodes();
      const selectedData: BlotterTableRow<R>[] = selectedNodes.map(node => ({
        data: node.data,
        setData: newData => node.setData(newData),
        remove: () => api.applyTransactionAsync({ remove: [node.data] }),
        setSelected: (selected: boolean) => {
          node.setSelected(selected);
        },
      }));
      return selectedData;
    }
    return [];
  }, [api]);

  const getRowsAfterFilter = useCallback(() => {
    if (api == null) {
      return [];
    }

    const rows: BlotterTableRow<R>[] = [];
    api.forEachNodeAfterFilter(node => {
      rows.push({
        data: node.data,
        setData: newData => node.setData(newData),
        remove: () => api.applyTransactionAsync({ remove: [node.data] }),
        setSelected: (selected: boolean) => {
          node.setSelected(selected);
        },
      });
    });

    return rows;
  }, [api]);

  type GetColumnKeysToUseForExportArg = Pick<BaseExportParams, 'columnKeys'> & TalosBlotterExportParams;
  const getColumnKeysToUseForExport = useCallback(
    ({ includeHiddenColumns, ignoredColIds, ignoreColumn, columnKeys }: GetColumnKeysToUseForExportArg) => {
      const startingColumns = (includeHiddenColumns ? api?.getColumns() : api?.getAllDisplayedColumns()) ?? [];

      const columnKeysToUse =
        columnKeys ??
        startingColumns.filter(column => {
          const colDef = column.getColDef();

          const customColDef = getCustomColDefProperties(colDef);
          if (customColDef && customColDef.exportable === false) {
            return false;
          }

          if (colDef.headerName === '') {
            return false;
          }

          if (ignoredColIds && ignoredColIds.has(column.getColId())) {
            return false;
          }

          if (ignoreColumn && ignoreColumn(colDef)) {
            return false;
          }

          return true;
        });

      return columnKeysToUse;
    },
    [api]
  );

  const exportDataAsExcel = useCallback(
    (params: ExportDataAsExcelParams) => {
      const columnKeysToUse = getColumnKeysToUseForExport(params);

      return api?.exportDataAsExcel({
        columnKeys: columnKeysToUse,
        processCellCallback: params => {
          return getParamsFormatted(params, 'Excel');
        },
        ...params,
      });
    },
    [api, getColumnKeysToUseForExport]
  );

  const getSheetDataForExcel = useCallback(
    (params: GetSheetDataForExcelParams) => {
      const columnKeysToUse = getColumnKeysToUseForExport(params);

      return api?.getSheetDataForExcel({
        skipRowGroups: true,
        skipPinnedTop: true,
        skipPinnedBottom: true,
        columnKeys: columnKeysToUse,
        processCellCallback: getParamsFormatted,
        ...params,
      });
    },
    [api, getColumnKeysToUseForExport]
  );

  const exportDataAsCSV = useCallback(
    (params: ExportDataAsCsvParams) => {
      const columnKeysToUse = getColumnKeysToUseForExport(params);

      return api?.exportDataAsCsv({
        skipRowGroups: true,
        skipPinnedTop: true,
        skipPinnedBottom: true,
        suppressQuotes: false,
        columnSeparator: ',',
        columnKeys: columnKeysToUse,
        processCellCallback: params => {
          const cellContent = getParamsFormatted(params);
          const safeCsvCellContent = cellCsvSafety(cellContent);
          return safeCsvCellContent;
        },
        ...params,
      });
    },
    [api, getColumnKeysToUseForExport]
  );

  const getDataAsCSV = useCallback(
    (params: ExportDataAsCsvParams) => {
      const columnKeysToUse = getColumnKeysToUseForExport(params);

      return api?.getDataAsCsv({
        skipRowGroups: true,
        skipPinnedTop: true,
        skipPinnedBottom: true,
        suppressQuotes: false,
        columnSeparator: ',',
        columnKeys: columnKeysToUse,
        processCellCallback: getParamsFormatted,
        ...params,
      });
    },
    [api, getColumnKeysToUseForExport]
  );

  /**
   * Expand a group row.
   * The recursively param, defaulting to true, tells the function to open any intermediary group rows as well
   */
  const expandGroupRow = useCallback(
    (nodeKey: string, recursively = true) => {
      if (api == null) {
        return;
      }

      api.forEachNode((node: IRowNode) => {
        if (node.group && node.key === nodeKey) {
          node.setExpanded(true);

          // Recursively open all parent nodes up to the root level
          if (recursively) {
            let workingNode = node;
            while (workingNode.level >= 0) {
              if (workingNode.parent == null) {
                break;
              }
              workingNode = workingNode.parent;
              workingNode.setExpanded(true);
            }
          }
        }
      });
    },
    [api]
  );

  const scrollToRow = useCallback(
    (...args: Parameters<GridApi<R>['ensureNodeVisible']>) => {
      if (api == null) {
        return;
      }

      api.ensureNodeVisible(...args);
    },
    [api]
  );
  const scrollVerticallyToColumn = useCallback(
    (...args: Parameters<GridApi<R>['ensureColumnVisible']>) => {
      if (api == null) {
        return;
      }

      api.ensureColumnVisible(...args);
    },
    [api]
  );

  const expandAllGroups = useCallback(() => {
    if (api == null) {
      return;
    }
    mixpanel.track(MixpanelEvent.ExpandAllRows);

    api.expandAll();
  }, [api, mixpanel]);

  const collapseAllGroups = useCallback(() => {
    if (api == null) {
      return;
    }
    mixpanel.track(MixpanelEvent.CollapseAllRows);

    api.collapseAll();
  }, [api, mixpanel]);

  const collapseAllLevelsGreaterThan = useCallback(
    (level: number) => {
      if (api == null) {
        return;
      }
      api.forEachNode(node => {
        if (node.level > level) {
          api.setRowNodeExpanded(node, false);
        }
      });
    },
    [api]
  );

  const setRowGroupColumns = useCallback(
    (...[columnsOrColIds]: Parameters<GridApi['setRowGroupColumns']>) => {
      if (api == null) {
        return;
      }

      // Grab the column object if there are any colIds passed in so we have a uniform array going forward
      const columns = columnsOrColIds
        .map(item => {
          if (typeof item === 'string') {
            return api.getColumn(item);
          }

          return item;
        })
        .compact();
      api.setRowGroupColumns(columns);
    },
    [api]
  );

  const addRowGroupColumns = useCallback(
    (colIds: string[]) => {
      if (api == null) {
        return;
      }

      // Grab the existing grouped columns, join with the new ones we're adding, and pass to the set function above
      const currentRowGroupColumns = api.getRowGroupColumns();
      const addedRowGroupColumns = colIds.map(colId => api.getColumn(colId)).compact();
      const uniqueNewRowGroupColumns = uniqBy([...currentRowGroupColumns, ...addedRowGroupColumns], c => c.getColId());
      setRowGroupColumns(uniqueNewRowGroupColumns);
    },
    [api, setRowGroupColumns]
  );

  const removeRowGroupColumns = useCallback(
    (colIds: string[]) => {
      if (api == null) {
        return;
      }

      api.removeRowGroupColumns(colIds);
    },
    [api]
  );

  const getRowGroupColumnIds = useCallback(() => {
    if (api == null) {
      return new Set<string>();
    }

    return new Set(compact(api.getRowGroupColumns().map(c => c.getColId())));
  }, [api]);

  const setColumnsVisible: GridApi['setColumnsVisible'] = useCallback(
    (...args) => {
      if (api == null) {
        return;
      }
      api.setColumnsVisible(...args);
    },
    [api]
  );

  const getSort = useCallback(() => {
    if (api == null) {
      return undefined;
    }

    return getBlotterTableSort<R>(api);
  }, [api]);

  const flashCells = useCallback(
    (params: FlashCellsParams) => {
      if (!api) {
        return;
      }

      api.flashCells(params);
    },
    [api]
  );

  const highlightRows = useCallback(
    (rowIDs: string[], expandChildren?: boolean) => {
      if (!api) {
        return;
      }

      const nodes = compact(rowIDs.map(id => api.getRowNode(id)));
      if (nodes.length === 0) {
        return;
      }

      // We recursively expand the parent of each node we want to highlight.
      for (const node of nodes) {
        // Potentially expand all the children of this group node we're wanting to highlight
        if (node.group && expandChildren) {
          expandAllNodesInGroup(node);
        }

        // Then also work expand myself and all my parents
        const parentsAndNode = getAllParentsOfNodeInclusive(node);
        parentsAndNode.forEach(node => node.setExpanded(true));
      }

      // These timings below are a bit arbitrary, but were just leaving a bit of timing margin here
      // so the ux is good
      const firstNode = nodes[0];
      setTimeout(() => {
        // wait a bit to allow all the parent nodes to be properly expanded before ensuring node is visible
        api.ensureNodeVisible(firstNode, 'middle');
      }, 20);

      setTimeout(() => {
        // flash cells slightly later after ensuring that the node is visible to the user
        api.flashCells({ rowNodes: nodes });
      }, 50);
    },
    [api]
  );

  // This function is really just a wrapper around highlightRows above. It maps from a group node.key to a group node.id (rowID)
  const highlightGroupRow = useCallback(
    (groupingKey: string) => {
      if (!api) {
        return;
      }

      // first we have to find some groupRow that matches this key
      let referencedNode: IRowNode | undefined = undefined;
      api.forEachNodeAfterFilter(n => {
        if (referencedNode == null && n.group && n.key === groupingKey) {
          referencedNode = n;
          // there's no way to break out of this loop afaik
        }
      });

      if (!referencedNode) {
        return;
      }

      const rowID = (referencedNode as IRowNode).id;
      if (rowID) {
        highlightRows([rowID], true);
      }
    },
    [api, highlightRows]
  );

  const selectRows = useCallback(
    (rowIDs: string[]) => {
      if (!api) {
        return;
      }

      api.deselectAll();
      rowIDs.forEach(id => api.getRowNode(id)?.setSelected(true));
    },
    [api]
  );

  const refreshClientSideRowModel = useCallback(() => {
    if (!api) {
      return;
    }

    api.refreshClientSideRowModel();
  }, [api]);

  const hidePopupMenu = useCallback(() => {
    api?.hidePopupMenu();
  }, [api]);

  return {
    gridApi: api,
    addRow,
    getRows,
    getRowsAfterFilter,
    getSelectedRows,
    exportDataAsCSV,
    exportDataAsExcel,
    getDataAsCSV,
    expandGroupRow,
    scrollToRow,
    scrollVerticallyToColumn,
    expandAllGroups,
    collapseAllGroups,
    collapseAllLevelsGreaterThan,
    setRowGroupColumns,
    addRowGroupColumns,
    removeRowGroupColumns,
    getRowGroupColumnIds,
    getSort,
    setColumnsVisible,
    flashCells,
    highlightRows,
    highlightGroupRow,
    selectRows,
    refreshClientSideRowModel,
    getSheetDataForExcel,
    hidePopupMenu,
  };
}

function useBlotterTableEventHandlers<R>(
  {
    columns,
    sort,
    onColumnsChanged,
    onSortChanged,
    onFirstDataRendered,
    onDoubleClickRow,
    onDoubleClickCell,
    onClickRow,
    getColumns,
    onRowGroupOpened,
    onExpandOrCollapseAll,
    onRowDataUpdated,
  }: Pick<
    UseBlotterTableProps<R>,
    | 'columns'
    | 'sort'
    | 'onColumnsChanged'
    | 'onSortChanged'
    | 'onFirstDataRendered'
    | 'onDoubleClickRow'
    | 'onDoubleClickCell'
    | 'onClickRow'
    | 'onRowGroupOpened'
    | 'onExpandOrCollapseAll'
    | 'onRowDataUpdated'
  > & { getColumns: () => Column[] },
  api?: GridApi
) {
  const mixpanel = useMixpanel();
  // Column ordering, width, and visibility
  const previousColumns = useRef(columns);
  useEffect(() => {
    function handleColumnsChanged(
      params: ColumnVisibleEvent | ColumnMovedEvent | ColumnResizedEvent | ColumnRowGroupChangedEvent
    ) {
      if (
        params.type === 'columnVisible' &&
        params.source !== 'api' /* Fired when we programmatically trigger hide/unhide of columns */ &&
        params.source !== 'toolPanelUi' /* Fired when the user clicks one checkbox in the column picker */ &&
        params.source !== 'columnMenu' /* Fired when the user clicks the "select all" checkbox in the column picker */
      ) {
        // Prevent running columns changed hook for non-user-events or when resize is not finished
        return;
      }

      // Skip unfinished or non-user-initiated column moves and resizes
      if (
        (params.type === 'columnMoved' && (params.finished === false || params.source !== 'uiColumnMoved')) ||
        (params.type === 'columnResized' && (params.finished === false || params.source !== 'uiColumnResized'))
      ) {
        return;
      }

      if (params.type === 'columnRowGroupChanged' && params.source === 'toolPanelUi') {
        // The user has changed the row grouping by dragging and dropping. We want to track this in mixpanel.
        mixpanel.track(MixpanelEvent.DragAndDropGrouping, {
          [MixpanelEventProperty.Columns]: params.columns?.map(column => column.getColId()),
        });
      }

      if (params.column && params.type === 'columnVisible' && params.source === 'toolPanelUi') {
        // The user has changed column visibility in the tool panel. We want to track this in mixpanel.
        mixpanel.track(params.column.isVisible() ? MixpanelEvent.ShowBlotterColumn : MixpanelEvent.HideBlotterColumn, {
          [MixpanelEventProperty.Column]: params.column.getColId(),
        });
      }

      if (onColumnsChanged) {
        const next = getColumns();
        if (!isEqual(previousColumns.current, next)) {
          onColumnsChanged(next, params.api);
          previousColumns.current = next;
        }
      }
    }

    safeGridApi(api)?.addEventListener('columnVisible', handleColumnsChanged);
    safeGridApi(api)?.addEventListener('columnMoved', handleColumnsChanged);
    safeGridApi(api)?.addEventListener('columnResized', handleColumnsChanged);
    safeGridApi(api)?.addEventListener('columnRowGroupChanged', handleColumnsChanged);
    return () => {
      safeGridApi(api)?.removeEventListener('columnVisible', handleColumnsChanged);
      safeGridApi(api)?.removeEventListener('columnMoved', handleColumnsChanged);
      safeGridApi(api)?.removeEventListener('columnResized', handleColumnsChanged);
      safeGridApi(api)?.removeEventListener('columnRowGroupChanged', handleColumnsChanged);
    };
  }, [api, onColumnsChanged, columns, getColumns, mixpanel]);

  // Sorting
  const previousSort = useRef<BlotterTableSort<R> | undefined>(sort);
  useEffect(() => {
    function handleSortChanged(params: SortChangedEvent) {
      // Must check for `source` here so that we only run this when the _user click_ is triggering the event.

      if (onSortChanged && params.source === 'uiColumnSorted') {
        const sort = getBlotterTableSort<R>(params.api);
        if (!isEmpty(sort)) {
          if (!isEqual(previousSort.current, sort)) {
            onSortChanged(sort);
            previousSort.current = sort;
          }
        }
      }
    }

    safeGridApi(api)?.addEventListener('sortChanged', handleSortChanged);
    return () => {
      safeGridApi(api)?.removeEventListener('sortChanged', handleSortChanged);
    };
  }, [api, onSortChanged]);

  // Clicking rows
  useEffect(() => {
    function handleRowDoubleClicked(params: RowDoubleClickedEvent<R>) {
      if (onDoubleClickRow && params.data) {
        onDoubleClickRow(params.data);
      }
    }

    safeGridApi(api)?.addEventListener('rowDoubleClicked', handleRowDoubleClicked);
    return () => {
      safeGridApi(api)?.removeEventListener('rowDoubleClicked', handleRowDoubleClicked);
    };
  }, [api, onDoubleClickRow]);

  // Clicking cells
  useEffect(() => {
    function handleCellDoubleClicked(params: CellDoubleClickedEvent<R>) {
      if (onDoubleClickCell) {
        onDoubleClickCell(params);
      }
    }

    safeGridApi(api)?.addEventListener('cellDoubleClicked', handleCellDoubleClicked);
    return () => {
      safeGridApi(api)?.removeEventListener('cellDoubleClicked', handleCellDoubleClicked);
    };
  }, [api, onDoubleClickCell]);

  // onFirstDataRendered
  useEffect(() => {
    function handleFirstDataRendered(params: FirstDataRenderedEvent<R>) {
      if (onFirstDataRendered) {
        onFirstDataRendered(params);
      }
    }

    safeGridApi(api)?.addEventListener('firstDataRendered', handleFirstDataRendered);
    return () => {
      safeGridApi(api)?.removeEventListener('firstDataRendered', handleFirstDataRendered);
    };
  }, [api, onFirstDataRendered]);

  // onRowDataUpdated
  useEffect(() => {
    function handleRowDataUpdated(params: RowDataUpdatedEvent<R>) {
      if (onRowDataUpdated) {
        onRowDataUpdated(params);
      }
    }

    safeGridApi(api)?.addEventListener('rowDataUpdated', handleRowDataUpdated);
    return () => {
      safeGridApi(api)?.removeEventListener('rowDataUpdated', handleRowDataUpdated);
    };
  }, [api, onRowDataUpdated]);

  // onRowGroupOpened
  useEffect(() => {
    const eventHandler = (e: RowGroupOpenedEvent) => {
      onRowGroupOpened?.(e);
      mixpanel.track(MixpanelEvent.ExpandRow, { [MixpanelEventProperty.Enabled]: e.expanded });
    };
    safeGridApi(api)?.addEventListener('rowGroupOpened', eventHandler);
    return () => {
      safeGridApi(api)?.removeEventListener('rowGroupOpened', eventHandler);
    };
  }, [api, mixpanel, onRowGroupOpened]);

  // onExpandOrCollapseAll
  useEffect(() => {
    const eventHandler = (e: ExpandCollapseAllEvent) => {
      onExpandOrCollapseAll?.(e);
      mixpanel.track(e.source === 'expandAll' ? MixpanelEvent.ExpandAllRows : MixpanelEvent.CollapseAllRows);
    };
    safeGridApi(api)?.addEventListener('expandOrCollapseAll', eventHandler);
    return () => {
      safeGridApi(api)?.removeEventListener('expandOrCollapseAll', eventHandler);
    };
  }, [api, mixpanel, onExpandOrCollapseAll]);

  useEffect(() => {
    function handleRowClicked(params: RowClickedEvent<R>) {
      if (onClickRow && params.data) {
        onClickRow(params.data);
      }

      // If we are clicking on a group node, we want to (un)select all the children within the group as well.
      if (params.node.group) {
        // The selection state toggling has already happened at this point, so getting this isSelected gives us the new selected state!
        const newSelectedState = params.node.isSelected();

        // There's a strange case where isSelected returns undefined if the group contents are partially selected, but I can't replicate that....
        // For now just return early. Maybe AgGrid's documentation is incorrect? Can only make it return true or false.
        if (newSelectedState === undefined) {
          return;
        }
        // Apply this new selected state to all children within this group recursively
        const nodes = [params.node, ...getAllNodesInGroup(params.node)];
        params.api.setNodesSelected({ nodes, newValue: newSelectedState, source: 'rowClicked' });
      }
    }

    safeGridApi(api)?.addEventListener('rowClicked', handleRowClicked);
    return () => {
      safeGridApi(api)?.removeEventListener('rowClicked', handleRowClicked);
    };
  }, [api, onClickRow]);
}

function getBlotterTableSort<TRowType = any>(api: GridApi<TRowType>): BlotterTableSort<TRowType> {
  const sortString = api
    .getColumnState()
    .filter(column => column.sort != null)
    .sort((left, right) => (left.sortIndex ?? 0) - (right.sortIndex ?? 0))
    .map(columnState => `${columnState.sort === 'asc' ? '+' : '-'}${columnState.colId}` as const);

  // casting needed since getColumnState() is not typed, but it should align
  return sortString as BlotterTableSort<TRowType>;
}

/** Apply the sorts of the current column state to the columns and autoGroupColumnDef */
export type ApplySortsToColumnsArg<TRowType> = {
  api: GridApi<TRowType>;
  columnDefs: ColDef<TRowType>[];
  autoGroupColumnDef: ColDef<TRowType> | undefined;
  initialSorts: BlotterTableSort<TRowType> | undefined;
};

/** Given input columnDefs and autoGroupColumnDef, apply the existing or persisted state */
export function applySortsToColumns<TRowType>(args: ApplySortsToColumnsArg<TRowType>): {
  workingColumnDefs: ColDef<TRowType>[];
  workingAutoGroupColumnDef: ColDef<TRowType> | undefined;
} {
  const { api, columnDefs, autoGroupColumnDef, initialSorts } = args;
  // We create shallow copies of these objects here so we dont mutate anything passed in
  // The steps below mutate these shallow copies.
  const workingColumnDefs = columnDefs.map(c => ({ ...c }));
  const workingAutoGroupColumnDef = autoGroupColumnDef ? { ...autoGroupColumnDef } : undefined;

  // The returned contents here are the sorts which are currently applied to our blotter.
  const sortedColumnStates = api.getColumnState()?.filter(column => column.sort != null);

  // If there are no sorts applied to the blotter currently, but we're configured to have some amount of default sorting, we apply that default sorting.
  const shouldInitialise = initialSorts != null && sortedColumnStates.length === 0;
  const columns = compact([workingAutoGroupColumnDef, ...workingColumnDefs]);
  if (shouldInitialise) {
    const initialSortArr = Array.isArray(initialSorts) ? initialSorts : [initialSorts];
    initialSortArr.forEach((sort, sortIndex) => {
      const [dir, colId] = [sort.substring(0, 1), sort.substring(1, sort.length)];

      // We allow the user to define an initialSort on the group coldef as well, meaning that we have to include that in this search operation.
      const columnDef = columns.find(columnDef => columnDef.colId === colId);
      if (columnDef != null) {
        columnDef.sort = dir === '+' ? 'asc' : 'desc';
        columnDef.sortIndex = sortIndex;
      }
    });
  } else {
    // Else, we don't need to initialise. Just apply all the sorting the blotter is currently doing to this new set of columns our implementer is passing in.
    sortedColumnStates.forEach(sortedColumnState => {
      const columnDef = columns.find(columnDef => columnDef.field === sortedColumnState.colId);
      if (columnDef != null) {
        columnDef.sort = sortedColumnState.sort;
        columnDef.sortIndex = sortedColumnState.sortIndex;
      }
    });
  }
  return { workingColumnDefs, workingAutoGroupColumnDef };
}
