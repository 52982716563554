import Big from 'big.js';
import type { Security } from '../types/Security';
import { isCounterCurrency, isMultileg, isSpot, toBigWithDefault } from '../utils';
import { useHomeCurrencyRateValue } from './useHomeCurrencyRate';

/**
 * Computes an estimate in the home currency, given a quantity in any other currency.
 */
export function useEstimatedTotal({
  security,
  quantity,
  quantityCurrency,
  price,
  legSymbol,
}: {
  security?: Security;
  quantity?: string;
  quantityCurrency?: string;
  price?: string;
  legSymbol?: string; // when traded in contracts first leg symbol for Multileg is required to get conversion rate
}): string | null {
  const { PositionCurrency, SettlementCurrency, Symbol, BaseCurrency, QuoteCurrency } = security || {};
  const isSpotProduct = isSpot(security);
  const usingCounterCurrency = isCounterCurrency(quantityCurrency, security);
  const quantityBig = toBigWithDefault(quantity, 1);

  let value = quantityBig.toFixed();
  let rateCurrency;

  if (isSpotProduct) {
    if (usingCounterCurrency) {
      rateCurrency = SettlementCurrency;
    } else {
      value = quantityBig
        .times(price || '1')
        .times(security?.NotionalMultiplier || '1')
        .toFixed();
      rateCurrency = price ? SettlementCurrency : PositionCurrency;
    }
  } else {
    if (!quantityCurrency) {
      // Contracts
      if (isMultileg(security)) {
        rateCurrency = legSymbol || Symbol;
      } else {
        rateCurrency = Symbol;
      }
    } else if (usingCounterCurrency) {
      rateCurrency = QuoteCurrency;
    } else {
      rateCurrency = BaseCurrency;
    }
  }

  const conversionRate = useHomeCurrencyRateValue(rateCurrency);

  if (!security || !quantity || quantity === '-' || !conversionRate?.Rate) {
    return null;
  }

  if (isSpotProduct && !quantityCurrency) {
    return null;
  }

  return Big(value).times(conversionRate.Rate).toFixed();
}
