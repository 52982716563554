import { forwardRef } from 'react';
import styled from 'styled-components';
import { Icon, type IconName } from '../../Icons';
import { IconButtonWrapper, type IconButtonWrapperProps } from './styles';

export type IconButtonProps = IconButtonWrapperProps & {
  icon: IconName;
};

export const IconButton = styled(
  forwardRef<HTMLButtonElement, IconButtonProps>(({ icon, variant, color, ghost, ...props }, ref) => (
    <IconButtonWrapper {...props} ref={ref} variant={ghost ? undefined : variant} ghost={ghost}>
      <Icon icon={icon} variant={ghost ? variant : undefined} color={color} />
    </IconButtonWrapper>
  ))
)``;
