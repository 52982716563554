import { forwardRef, type ForwardedRef } from 'react';
import type { Observable } from 'rxjs';
import type { MarketDataSnapshot, Security } from '../../../types';
import { PriceQuickOptions, type PriceQuickOption } from '../../QuickOptions';
import { AmountInput, type AmountInputProps } from '../AmountInput';
import { QuickOptionsContainer } from '../styles';
import { DEFAULT_PRICE_OPTIONS } from './usePriceInput';

export type PriceInputProps = {
  marketDataObs?: Observable<MarketDataSnapshot>;
  security?: Security;
  quickOptions?: PriceQuickOption[];
} & AmountInputProps;

export const PriceInput = forwardRef(
  (
    {
      security,
      value,
      onChange,
      disabled,
      marketDataObs,
      size,
      quickOptions = DEFAULT_PRICE_OPTIONS,
      ...props
    }: PriceInputProps,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    return (
      <>
        <AmountInput
          asset={security}
          {...props}
          value={value}
          onChange={onChange}
          disabled={disabled}
          size={size}
          ref={ref}
        />
        {security &&
          marketDataObs && ( // security passed means we want to show quickoptions
            <QuickOptionsContainer>
              <PriceQuickOptions
                options={quickOptions}
                key={security.Symbol}
                security={security}
                onChange={onChange}
                disabled={disabled}
                marketDataObs={marketDataObs}
              />
            </QuickOptionsContainer>
          )}
      </>
    );
  }
);
