export const Logo = ({ fill }) => (
  <>
    <path
      d="M79.7393 156.991C89.5774 173.778 107.833 185.057 128.66 185C159.808 184.904 185.085 159.502 185 128.347C184.918 97.1925 159.499 71.9185 128.34 72.0002C97.1921 72.0819 71.915 97.5086 72.0002 128.653C72.0178 132.371 72.3984 136.078 73.1367 139.722C73.2468 140.18 73.7192 140.432 74.1667 140.318L82.0301 138.365C82.1408 138.341 82.2457 138.295 82.3385 138.23C82.4314 138.165 82.5105 138.082 82.5712 137.986C82.632 137.891 82.6732 137.784 82.6925 137.672C82.7117 137.56 82.7087 137.446 82.6836 137.335H82.6943C82.1378 134.465 81.8548 131.548 81.849 128.624C81.7779 102.906 102.647 81.9187 128.369 81.8477C154.104 81.7767 175.094 102.658 175.151 128.376C175.233 154.094 154.353 175.081 128.631 175.152C111.594 175.191 96.6381 166.051 88.4693 152.4C88.3597 152.199 88.1782 152.048 87.9613 151.976C87.7444 151.904 87.5083 151.917 87.3008 152.013L80.1015 155.812C79.8974 155.921 79.7447 156.106 79.6768 156.327C79.6089 156.548 79.6313 156.787 79.7393 156.991Z"
      fill={fill}
    />
    <path
      d="M157.583 140.139L164.72 143.962C165.144 144.185 165.709 144.061 165.879 143.605C167.904 138.769 168.964 133.584 168.999 128.341C169.189 105.731 150.944 87.1943 128.339 87H127.993C105.579 87 87.1956 105.232 87.0013 127.648C86.8246 150.259 105.056 168.809 127.661 168.989L128.007 169C138.716 169 148.489 164.831 155.816 158.043C156.177 157.725 156.244 157.174 155.912 156.813L149.828 151.322C149.467 151.004 148.93 151.075 148.612 151.375L148.598 151.361C142.927 156.409 135.599 159.199 128.007 159.202H127.746C110.536 159.05 96.6644 144.944 96.8022 127.733C96.94 110.674 110.935 96.7876 127.993 96.7876H128.268C145.464 96.9395 159.349 111.059 159.198 128.256C159.168 131.916 158.491 135.542 157.198 138.966C157.102 139.174 157.089 139.411 157.16 139.628C157.232 139.846 157.383 140.028 157.583 140.139"
      fill={fill}
    />
    <path
      d="M169.601 69.2758C169.209 69.0087 168.682 69.0799 168.418 69.4824L163.795 76.1613C163.528 76.5531 163.571 77.0946 163.963 77.3724C179.777 88.657 190.13 107.133 190.116 128.007C190.116 162.264 162.249 190.119 127.996 190.119C93.7435 190.119 65.8731 162.249 65.8731 127.979C65.8731 93.7365 93.7435 65.8669 128.011 65.8776C135.251 65.8718 142.437 67.139 149.238 69.6214C149.451 69.6974 149.685 69.6879 149.891 69.5949C150.097 69.5019 150.259 69.3326 150.343 69.1227L153.249 61.4963C153.325 61.289 153.318 61.0605 153.23 60.8582C153.141 60.6559 152.979 60.4953 152.775 60.4099C144.848 57.4853 136.464 55.9922 128.014 56C88.3047 55.9858 56.0142 88.283 56 127.989C56 167.689 88.3047 200 128 200C167.692 200 200 167.703 200 128.007C200 103.785 187.979 82.3272 169.601 69.2758"
      fill={fill}
    />
  </>
);
