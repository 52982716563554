import type Big from 'big.js';
import type { ExpectTrue } from '../../tests';
import { POST_TRADE_ORDER_ANALYTICS } from '../../tokens';
import { parseDate, toBig, type OptionalProperties } from '../../utils';
import type {
  AggregationWindowEnum,
  IPostTradeOrderAnalytics,
  IStrategyParameters,
  ProductTypeEnum,
  SideEnum,
  UpdateActionEnum,
} from '../types';
import type { DatapointProps } from './types';

export class PostTradeOrderAnalyticsDatapoint {
  public static readonly Stream_Name = POST_TRADE_ORDER_ANALYTICS;

  public readonly StartTime: Date;
  public readonly OrderStartTime: Date;
  public readonly OrderEndTime: Date;
  public readonly OrderID: string;
  public readonly EquivalentCurrency: string;
  public readonly Strategy: string | undefined;
  public readonly Asset: string | undefined;
  public readonly ProductType: ProductTypeEnum;
  public readonly Side: SideEnum;
  public readonly TotalTradedAmt: Big | undefined;
  public readonly OrderDurationMillis: number;
  public readonly ParticipationRate: Big | undefined;
  public readonly MakerPct: Big | undefined;
  public readonly ArrivalSlippageBps: Big | undefined;
  public readonly TWAPSlippageBps: Big | undefined;
  public readonly VWAPSlippageBps: Big | undefined;
  public readonly Parameters: IStrategyParameters;
  public readonly ReleaseRevision: string | undefined;
  public readonly AggregationWindow: AggregationWindowEnum | undefined;
  public readonly AsOfTimestamp: string;

  public readonly UpdateAction?: UpdateActionEnum;
  constructor(data: _PostTradeOrderAnalyticsDatapoint) {
    this.StartTime = parseDate(data.StartTime);
    this.OrderStartTime = parseDate(data.OrderStartTime);
    this.OrderEndTime = parseDate(data.OrderEndTime);
    this.OrderID = data.OrderID;
    this.EquivalentCurrency = data.EquivalentCurrency;
    this.Strategy = data.Strategy;
    this.Asset = data.Asset;
    this.ProductType = data.ProductType;
    this.Side = data.Side;
    this.TotalTradedAmt = toBig(data.TotalTradedAmt);
    this.OrderDurationMillis = data.OrderDurationMillis;
    this.ParticipationRate = toBig(data.ParticipationRate);
    this.MakerPct = toBig(data.MakerPct);
    this.ArrivalSlippageBps = toBig(data.ArrivalSlippageBps);
    this.TWAPSlippageBps = toBig(data.TWAPSlippageBps);
    this.VWAPSlippageBps = toBig(data.VWAPSlippageBps);
    this.Parameters = data.Parameters;
    this.UpdateAction = data.UpdateAction;
    this.ReleaseRevision = data.ReleaseRevision;
    this.AggregationWindow = data.AggregationWindow;
    this.AsOfTimestamp = data.AsOfTimestamp;
  }
}

type _PostTradeOrderAnalyticsDatapoint = OptionalProperties<
  DatapointProps<IPostTradeOrderAnalytics>,
  'Asset' | 'Strategy' | 'UpdateAction'
>;
// TYPE LEVEL TESTS
type _Expect_PostTradeOrderAnalyticsDatapoint_To_Only_Have_Keys_From_IPostTradeOrderAnalyticsDatapoint = ExpectTrue<
  {
    [K in keyof PostTradeOrderAnalyticsDatapoint & string]: K extends keyof _PostTradeOrderAnalyticsDatapoint
      ? true
      : K;
  }[Exclude<keyof PostTradeOrderAnalyticsDatapoint, never>]
>;
type _Expect_All_Keys_In_IPostTradeOrderAnalyticsDatapoint_To_Be_In_PostTradeOrderAnalyticsDatapoint = ExpectTrue<
  {
    [K in keyof _PostTradeOrderAnalyticsDatapoint & string]: K extends keyof PostTradeOrderAnalyticsDatapoint
      ? true
      : K;
  }[Exclude<keyof _PostTradeOrderAnalyticsDatapoint, never>]
>;
