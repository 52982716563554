import styled from 'styled-components';

export const MaxAvailableWrapper = styled.div`
  display: inline-flex;
  margin-left: auto;
  font-size: ${({ theme }) => theme.fontSizeFormLabel}rem;
`;

export const FormBalanceRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

export const BalanceLabel = styled.div`
  flex: 1;
  color: ${({ theme }) => theme.colorTextDefault};
  margin-right: ${({ theme }) => theme.spacingSmall}px;
  align-self: flex-start;
`;
