import styled from 'styled-components';
import { IconButton } from '../Button';
import { Box, Flex } from '../Core';
import { Text } from '../Text';

export const TreeLevelContainer = styled(Box)`
  padding-left: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-direction: row;
  width: 100%;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
    height: 0;
  }
  scrollbar-width: none;
  overflow-y: scroll;
  overflow-x: scroll;
`;

export const VerticalLevelLineContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
  width: ${({ theme }) => theme.spacingMedium}px;
  margin-left: ${({ theme }) => theme.spacingSmall}px;
  margin-right: ${({ theme }) => theme.spacingSmall}px;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: ${({ theme }) => theme.spacingMedium}px;
`;

export const VerticalLevelLine = styled.div<{ highlighted: boolean }>`
  width: 1px;
  background-color: ${({ theme, highlighted }) => (highlighted ? theme.colors.gray['100'] : theme.colors.gray['070'])};
  position: relative;
`;

export const TextAndIconContainer = styled(Flex)`
  width: calc(100% - ${({ theme }) => theme.spacingMedium}px);
`;

export const RowContainer = styled(Box)`
  padding: ${({ theme }) => theme.spacingSmall}px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

export const TitleText = styled(Text)<{ highlighted: boolean }>`
  padding-left: ${({ theme }) => theme.spacingDefault}px;
  padding-top: ${({ theme }) => theme.spacingSmall}px;
  padding-bottom: ${({ theme }) => theme.spacingSmall}px;
  color: ${({ theme, highlighted }) => (highlighted ? theme.colors.gray['100'] : theme.colors.gray['090'])};
  overflow-x: hidden;
  max-width: 200px;
`;

export const DropDownIconButton = styled(IconButton)<{ highlighted: boolean }>`
  color: ${({ theme, highlighted }) => (highlighted ? theme.colors.gray['100'] : theme.colors.gray['090'])};
  width: ${({ theme }) => theme.spacingMedium}px;
  height: ${({ theme }) => theme.spacingMedium}px;
`;

export const TreeTitleText = styled(Text)`
  margin: 10px;
`;

export const Details = styled.div<{ open: boolean }>`
  display: ${({ open }) => (open ? 'block' : 'none')};
`;

export const TreeContainer = styled.div`
  width: 100%;
`;

export const TreeWrapper = styled(Box)`
  overflow-x: scroll;
  overflow-y: hidden;
`;
