import { createContext, useContext, type ReactNode } from 'react';
import type { PopoverStateProps } from '../components/Popover/types';

type OverridePopoverProps = Partial<Pick<PopoverStateProps, 'trigger'>>;

const OverridePopoverPropsContext = createContext<OverridePopoverProps>({});
OverridePopoverPropsContext.displayName = 'OverridePopoverPropsContext';

export function OverridePopoverPropsProvider({
  overrideProps,
  children,
}: {
  children: ReactNode;
  overrideProps: OverridePopoverProps;
}) {
  return <OverridePopoverPropsContext.Provider value={overrideProps}>{children}</OverridePopoverPropsContext.Provider>;
}

export function useOverridePopoverProps() {
  const context = useContext(OverridePopoverPropsContext);
  return context;
}
