import { CustomerUserConfigTradingLayoutArrangements, ResizerPlacement } from '@talos/kyoko';
import { Blotters } from '../../../components/Blotters/Blotters';
import { Candlesticks } from '../../../components/Candlesticks';
import { Securities } from '../../../components/Securities';
import { WatchList } from '../../../components/WatchList';
import { BLOTTERS_MIN_HEIGHT, MAIN_HEADER_HEIGHT, VOLUME_LADDER_HEIGHT } from '../../../styles/dimensions';
import type { LayoutComponentArrangement } from '../../types';

export const WatchlistCandlesticksMarketDataCardsArrangement: LayoutComponentArrangement = {
  columns: ['minmax(16rem,1fr)', 'minmax(16rem,1fr)', 'minmax(16rem,24rem)'],
  displayName: CustomerUserConfigTradingLayoutArrangements.WatchlistCandlesticksMarketDataCards,
  headerSymbolSelector: false,
  rows: [`minmax(${VOLUME_LADDER_HEIGHT}px,1fr)`, 'minmax(0px,1fr)', `minmax(${BLOTTERS_MIN_HEIGHT}px,1fr)`],
  parts: [
    {
      column: '1',
      row: '1',
      component: <WatchList />,
    },
    {
      column: '2/4',
      row: '1',
      component: <Candlesticks hasOwnSymbolSelector={true} />,
    },
    {
      column: '1/4',
      row: '2',
      component: <Securities />,
    },
    {
      column: '1/4',
      row: '3',
      component: <Blotters />,
      minHeight: BLOTTERS_MIN_HEIGHT,
      maxHeight: window.innerHeight - MAIN_HEADER_HEIGHT - VOLUME_LADDER_HEIGHT - 20,
      resizable: ResizerPlacement.TOP,
    },
  ],
};
