import { defineMessages } from 'react-intl';
import { getIntlKey } from '../../contexts';
import { useIntl } from '../../hooks';
import { CareOrderStatusEnum } from '../../types';
import { logger, type ValueOf } from '../../utils';
import { getCareOrderStatusColor } from '../../utils/careOrder';
import { Box } from '../Core';
import { Icon, IconName, type IconProps } from '../Icons';
import { IndicatorDotVariants, IndicatorDotWrapper } from '../IndicatorDot';
import { Tooltip } from '../Tooltip';
import { Wrapper } from './styles';
import { OrderStatusSize } from './types';

const messages = defineMessages({
  new: {
    defaultMessage: 'New',
    id: 'Status.new',
  },
  filled: {
    defaultMessage: 'Filled',
    id: 'Status.filled',
  },
  rejected: {
    defaultMessage: 'Rejected',
    id: 'Status.rejected',
  },
  filledAndCanceled: {
    defaultMessage: 'Filled & Canceled',
    id: 'Status.filledAndCanceled',
  },
  partiallyFilled: {
    defaultMessage: 'Partially Filled',
    id: 'Status.partiallyFilled',
  },
  canceled: {
    defaultMessage: 'Canceled',
    id: 'Status.canceled',
  },
  pendingCancel: {
    defaultMessage: 'Pending Cancel',
    id: 'Status.pendingCancel',
  },
  pendingReplace: {
    defaultMessage: 'Pending Replace',
    id: 'Status.pendingReplace',
  },
  unknown: {
    defaultMessage: 'Unknown',
    id: 'Status.unknown',
  },
});

const StatusIcon = ({
  status,
  ...props
}: { status: ValueOf<typeof CareOrderStatusEnum> } & Omit<IconProps, 'icon' | 'color'>) => {
  const color = getCareOrderStatusColor(status);
  switch (status) {
    case CareOrderStatusEnum.New:
      return <Icon icon={IconName.CircleSolid} color={color} {...props} />;
    case CareOrderStatusEnum.Rejected:
      return <Icon icon={IconName.ExclamationCircleSolid} color={color} {...props} />;
    case CareOrderStatusEnum.PartiallyFilled:
      return <Icon icon={IconName.Circle} color={color} {...props} />;
    case CareOrderStatusEnum.Canceled:
      return <Icon icon={IconName.CloseCircleSolid} color={color} {...props} />;
    case CareOrderStatusEnum.PendingCancel:
    case CareOrderStatusEnum.PendingReplace:
      return <Icon icon={IconName.Clock} color={color} {...props} />;
    case CareOrderStatusEnum.Filled:
    case CareOrderStatusEnum.FilledAndCanceled:
      return <Icon icon={IconName.CheckCircleSolid} color={color} {...props} />;
    case CareOrderStatusEnum.Unknown:
      return <Icon icon={IconName.ExclamationCircleSolid} color={color} {...props} />;
    default: {
      const _exhaustiveCheck: never = status;
      logger.error(new Error(`Unexpected OrdStatus: ${status}`));
      return null;
    }
  }
};

type CareOrderStatusProps = {
  status: ValueOf<typeof CareOrderStatusEnum>;
  align?: 'right' | 'left';
  iconPlacement?: 'right' | 'left';
  text?: string;
  title?: string;
  size?: OrderStatusSize;
};

const StatusText = ({ status }: { status: ValueOf<typeof CareOrderStatusEnum> }) => {
  const { formatMessage } = useIntl();
  const intlKey = getIntlKey(status);
  let orderStatusTextTranslated: string = status;
  if (intlKey in messages) {
    orderStatusTextTranslated = formatMessage(messages[intlKey]);
  }
  return <>{orderStatusTextTranslated}</>;
};

export function CareOrderStatus({
  status,
  align = 'right',
  text,
  size = OrderStatusSize.Default,
  ...props
}: CareOrderStatusProps) {
  const tooltipContent = !!text && <Box maxWidth="300px">{text}</Box>;

  return tooltipContent ? (
    <Tooltip tooltip={tooltipContent} usePortal>
      {renderOrderStatus()}
    </Tooltip>
  ) : (
    renderOrderStatus()
  );

  function renderOrderStatus() {
    return (
      <Wrapper iconPlacement="left" align={align} size={size} {...props}>
        <StatusText status={status} />
        <IndicatorDotWrapper show={!!text} variant={IndicatorDotVariants.Warning}>
          <StatusIcon status={status} size={size === OrderStatusSize.Default ? 'fontSizeLarge' : 'fontSizeSmall'} />
        </IndicatorDotWrapper>
      </Wrapper>
    );
  }
}
