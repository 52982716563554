import { forwardRef, type ForwardedRef } from 'react';
import type { Currency, Security } from '../../../types';
import { Input } from '../../Form/Input';
import type { FormControlProps } from '../../Form/types';
import { useAmountInput } from '../useAmountInput';

export const AmountInput = forwardRef(function AmountInput(
  {
    currency,
    asset,
    value,
    onChange,
    min,
    max,
    minIncrementOverride,
    defaultIncrementOverride,
    ...props
  }: AmountInputProps,
  ref: ForwardedRef<HTMLInputElement>
) {
  const inputProps = useAmountInput({
    currency,
    asset,
    value,
    onChange,
    min,
    max,
    minIncrementOverride,
    defaultIncrementOverride,
  });

  const usedRef = ref ?? inputProps.ref;

  return <Input {...props} {...inputProps} suffix={currency} type="number" ref={usedRef} />;
});

export type AmountInputProps = {
  value: string;
  currency?: string;
  asset?: Currency | Security;
  onChange: (value?: string) => void;
  min?: string;
  max?: string;
  // https://talostrading.atlassian.net/browse/UI-2918
  // Current Deribit options have same Base and Quote currencies which means we can't apply the below logic correctly
  // Because it will end up using the security.SizeIncrement for both the quantity and price fields
  minIncrementOverride?: string;
  defaultIncrementOverride?: string;
} & Omit<FormControlProps<HTMLInputElement>, 'security' | 'onChange' | 'value'>;
