import type { ICellRendererParams } from 'ag-grid-enterprise';
import type { ConnectionStatusEnum } from '../../../types';
import { ConnectionStatusIndicator } from '../../StatusIndicator';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

/*
 * This column is used to display formatted connection status with icon and text
 */
export const connectionStatusWithText: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  cellRenderer: ({ value }: ICellRendererParams<unknown, ConnectionStatusEnum>) => {
    return value ? <ConnectionStatusIndicator status={value} iconRight={false} /> : 'N/A';
  },
});
