import type { ICellRendererParams, ValueFormatterParams } from 'ag-grid-community';
import type { SubAccountReconCheckpoint, SubAccountReconCheckpointStatusEnum } from '../../../types';
import { ReconCheckpointStatus } from '../../Status';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

export const reconCheckpointStatus: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  cellRenderer: ({ value }: ICellRendererParams<SubAccountReconCheckpoint, SubAccountReconCheckpointStatusEnum>) => {
    if (value == null) {
      return null;
    }

    return <ReconCheckpointStatus status={value} />;
  },
  valueFormatter: ({ value }: ValueFormatterParams<unknown, SubAccountReconCheckpointStatusEnum>) => {
    return value ?? '';
  },
});
