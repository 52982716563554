import { immerable } from 'immer';
import {
  BaseField,
  FieldValidationLevel,
  FieldValidationType,
  type FieldData,
  type FieldValidationResult,
  type FieldValidationRule,
} from './BaseField';
import { fieldsMessages } from './messages';

export class Field<T = string> extends BaseField<FieldData<T>, T> {
  constructor(initial?: Partial<FieldData<T>>) {
    super({
      name: 'Field',
      value: undefined,
      placeholder: '',
      isTouched: false,
      isDisabled: false,
      isRequired: true,
      isVisible: true,
      errors: [],
      ...initial,
    });
  }

  public get value(): T | undefined {
    return this.data.value;
  }

  public override updateValue(value: T | undefined, isSystemOverride = false): Field<T> {
    const updatedData = {
      value,
      isTouched: !isSystemOverride,
    };

    const updated = this.updateData(updatedData);
    return updated.invariantCheck();
  }

  public setTouched(isTouched: boolean): Field<T> {
    const updated = this.updateData({ isTouched });
    return updated.invariantCheck();
  }

  public setIsRequired(isRequired: boolean): Field<T> {
    const updated = this.updateData({ isRequired });
    return updated.invariantCheck();
  }

  public setIsVisible(isVisible: boolean): Field<T> {
    const updated = this.updateData({ isVisible });
    return updated.invariantCheck();
  }

  public validate<C>(rules: FieldValidationRule<Field<T>, C, T>[] = [], context?: C): Field<T> {
    const checked = this.invariantCheck();
    const errors = checked.data.errors.filter(e => e.type !== FieldValidationType.Rule);

    rules.forEach(rule => {
      const result = rule(this, context);
      if (result) {
        errors.unshift({ ...result, type: FieldValidationType.Rule });
      }
    });

    return this.updateData({ errors });
  }

  public override setDisabled(isDisabled: boolean): Field<T> {
    return this.updateData({ isDisabled });
  }

  private invariantCheck(): Field<T> {
    const errors: FieldValidationResult[] = this.data.errors.filter(e => e.type === FieldValidationType.Rule);

    if (this.data.isRequired && (this.data.value == null || this.data.value === '')) {
      errors.push({
        message: fieldsMessages.dataNameIsRequired,
        values: { dataName: this.data.name },
        level: FieldValidationLevel.Error,
      });
    }

    return this.updateData({ errors });
  }

  private updateData(data: Partial<FieldData>): Field<T> {
    const newData = {
      ...this.data,
      ...data,
    };
    return new Field(newData);
  }
}

Field[immerable] = true;
