import type { ICellRendererParams } from 'ag-grid-enterprise';
import type Big from 'big.js';
import { memo } from 'react';
import { useFlashSentiment } from '../../hooks';
import { Direction } from '../../types';
import { InlineFormattedNumber } from '../FormattedNumber';
import { Placeholder, PriceCell, placeholder } from './styles';
import type { WatchListRowData } from './types';

export interface MarketPriceCellRendererParams extends ICellRendererParams {
  data: WatchListRowData;
  value: Big | undefined;
}
export const MarketPriceCellRenderer = memo(function MarketPriceCellRenderer({
  value,
  data,
}: MarketPriceCellRendererParams) {
  const security = data.security;
  const changeSentiment = useFlashSentiment(security.Symbol, Direction.Asc, value?.toFixed());
  const isLoaded = value !== undefined;
  const hasData = value != null;

  return (
    <PriceCell w="100%" flexDirection="column">
      {isLoaded && hasData ? (
        <InlineFormattedNumber
          number={value.toFixed()}
          increment={security?.DefaultPriceIncrement}
          variant={changeSentiment}
          round={true}
        />
      ) : isLoaded ? (
        placeholder
      ) : (
        <Placeholder />
      )}
    </PriceCell>
  );
});
