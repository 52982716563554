import styled from 'styled-components';

import { HStack, VStack } from '../../../Core';

export const PasteWrapper = styled(VStack)`
  gap: ${({ theme }) => theme.spacingComfortable}px;

  textarea {
    width: 100%;
    resize: none;
  }
`;

export const ModalHeader = styled(HStack)`
  border-bottom: ${({ theme }) => `solid 1px ${theme.colors.gray['040']}`};
  padding: ${({ theme }) => `${theme.spacingMedium}px ${theme.spacingLarge}px`};
`;
