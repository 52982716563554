import { FeeModeEnum } from '../types/types';

const FEE_MODE_TO_LABEL: Record<FeeModeEnum, string> = {
  [FeeModeEnum.Maker]: 'Maker',
  [FeeModeEnum.Taker]: 'Taker',
  [FeeModeEnum.Net]: 'No Fee',
};

export function prettyFeeMode(feeMode: FeeModeEnum): string {
  return FEE_MODE_TO_LABEL[feeMode] ?? feeMode;
}
