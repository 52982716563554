import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { LightTheme, lightColors, mapLightTheme } from './LightTheme';
import { getInputStylesAppliedToSelect } from './utils';

const colors: Colors = {
  gray: {
    main: '#FFFFFF',
    '000': '#dbdce1',
    '010': '#ededf0',
    '020': '#F6F6F7',
    '030': '#e4e4e8',
    '040': '#d2d3da',
    '050': '#c9cad2',
    '060': '#c0c2cb',
    '070': '#b7b9c3',
    '080': '#9497a2',
    '090': '#666b81',
    '100': '#00092E',
  },

  black: {
    default: '#000000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#FFFFFF',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.1)',
  },

  primary: {
    mute: setAlpha(0.1, '#7B61FF'),
    dim: setAlpha(0.4, '#7B61FF'),
    default: '#7B61FF',
    lighten: '#7B61FF',
  },

  green: {
    mute: setAlpha(0.1, '#7B61FF'),
    dim: setAlpha(0.4, '#7B61FF'),
    default: '#7B61FF',
    lighten: '#7B61FF',
  },

  red: {
    mute: 'rgba(146, 46, 67, 0.1)',
    dim: 'rgba(146, 46, 67, 0.4)',
    default: '#ed423d',
    lighten: '#ed423d',
  },

  yellow: {
    default: '#CCAC3E',
    lighten: '#F2D264',
    dim: 'rgba(204, 172, 62, 0.4)',
    mute: 'rgba(204, 172, 62, 0.1)',
  },

  blue: {
    default: '#458CC1',
    lighten: '#53AFF8',
    dim: 'rgba(69, 140, 193, 0.4)',
    mute: 'rgba(69, 140, 193, 0.1)',
  },

  orange: {
    default: '#E65200',
    lighten: '#FF6B00',
    dim: 'rgba(230, 82, 0, 0.4)',
    mute: 'rgba(230, 82, 0, 0.1)',
  },

  purple: lightColors.purple,
};

export const LightOneLiquidityTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightOneLiquidity',

  backgroundBody: colors.gray['010'],

  backgroundDefaultButton: colors.gray.main,
  backgroundDefaultButtonHover: setAlpha(0.08, colors.gray['100']),
  backgroundDefaultButtonFocus: colors.gray.main,
  backgroundDefaultButtonActive: colors.gray.main,

  backgroundPriorityButton: colors.gray.main,
  backgroundPriorityButtonHover: setAlpha(0.08, colors.gray['100']),
  backgroundPriorityButtonFocus: colors.gray.main,
  backgroundPriorityButtonActive: colors.gray.main,

  borderWidthButton: 1,

  borderColorPriorityButton: colors.gray['060'],
  borderColorPriorityButtonHover: colors.gray['070'],
  borderColorPriorityButtonFocus: colors.gray['070'],
  borderColorPriorityButtonActive: colors.gray['060'],

  borderColorDefaultButton: colors.gray['030'],
  borderColorDefaultButtonHover: colors.gray['040'],
  borderColorDefaultButtonFocus: colors.gray['040'],
  borderColorDefaultButtonActive: colors.gray['030'],

  ...getInputStylesAppliedToSelect(LightTheme),

  backgroundChartUpColor: '#009d70',
  borderColorChartUpColor: '#009d70',
});
