import type React from 'react';
import { createContext, memo, useCallback, useContext } from 'react';
import { Observable, asyncScheduler, firstValueFrom } from 'rxjs';
import { map, shareReplay, throttleTime, timeout } from 'rxjs/operators';
import { v1 as uuid } from 'uuid';

import {
  MixpanelEvent,
  NEW_ORDER_SINGLE,
  ORDER,
  ORDER_CANCEL_REQUEST,
  ORDER_CONTROL_REQUEST,
  ORDER_MODIFY_REQUEST,
  OrdStatusEnum,
  OrdTypeEnum,
  OrderControlActionEnum,
  TimeInForceEnum,
  formattedDateForSubscription,
  useDynamicCallback,
  useMixpanel,
  useObservable,
  useObservableValue,
  useSecuritiesContext,
  useSocketClient,
  useStaticSubscription,
  wsScanToMap,
  type CustomerNewOrderRequest,
  type CustomerOrder,
  type CustomerOrderModifyRequest,
  type WLOrderStrategy,
} from '@talos/kyoko';
import { sub } from 'date-fns';
import { findLast } from 'lodash';
import { useStrategiesContext } from '../../providers/StrategiesProvider';
import { cleanParametersForOrder } from './utils';

type OrderContextProps = {
  recentOrders: Observable<CustomerOrder[]>;
  ordersByClOrdID: Map<string, CustomerOrder>;
  ordersByOrderID: Map<string, CustomerOrder>;
  sendOrder: (order: CustomerNewOrderRequest, transactTime?: Date) => string | undefined;
  modifyOrder: (order: CustomerOrderModifyRequest, transactTime?: Date) => string | undefined;
  cancelOrder: (orderID: string, requirePausedOrder?: boolean) => Promise<CustomerOrder>;
  pauseOrder: (orderID: string) => string;
  resumeOrder: (orderID: string) => string;
};

const RECENT_ORDERS_THROTTLE_MS = 1000;

const OrdersContext = createContext<OrderContextProps | undefined>(undefined);

export const useOrdersProvider = () => {
  const context = useContext(OrdersContext);
  if (context === undefined) {
    throw new Error('Missing OrdersContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};

export const OrdersProvider = memo((props: React.PropsWithChildren<unknown>) => {
  const mixpanel = useMixpanel();
  const client = useSocketClient();
  const { strategiesByName } = useStrategiesContext();
  const { securitiesBySymbol } = useSecuritiesContext();

  const getEffectiveStrategy = useDynamicCallback(
    ({ Strategy, Symbol }: Pick<CustomerNewOrderRequest, 'Symbol' | 'Strategy'>): WLOrderStrategy | undefined => {
      return (
        securitiesBySymbol.get(Symbol)?.SupportedStrategies?.find(s => s.Name === Strategy) ?? // Fallback to strategy map
        strategiesByName.get(Strategy)
      );
    }
  );

  const webSocketClient = useSocketClient();
  const createActionPromise = useDynamicCallback(
    ({
      clOrdID,
      expectedOrdStatus,
      action,
    }: {
      action: () => void;
      clOrdID: string;
      expectedOrdStatus?: OrdStatusEnum[];
    }): Promise<CustomerOrder> => {
      const observable = new Observable<CustomerOrder>(observer => {
        const orderRequest = {
          name: ORDER,
          tag: 'OrdersProvider',
          ClOrdID: clOrdID,
        };

        const address = uuid();
        webSocketClient.registerSubscription(address, [orderRequest], (err, json) => {
          if (json?.data?.length) {
            const report = findLast(
              json.data,
              (d: CustomerOrder) =>
                d.ClOrdID === clOrdID && (expectedOrdStatus ? expectedOrdStatus.includes(d.OrdStatus) : true)
            ) as CustomerOrder | undefined;
            if (report) {
              observer.next(report);
            }
          }
        });
        // perform the action after subscription is set up, this ensures that we'll get the first update.
        action();

        return () => webSocketClient.unregisterSubscription(address);
      });
      return firstValueFrom(
        observable.pipe(
          // If there is no response within 5 seconds, we reject the promise
          timeout(5_000)
        )
      );
    }
  );

  const sendOrder: OrderContextProps['sendOrder'] = useCallback(
    ({ Symbol, Side, OrderQty, Strategy, Currency, MarketAccount, Parameters }, transactTime = new Date()) => {
      if (client != null) {
        const ClOrdID = uuid();
        const effectiveStrategy = getEffectiveStrategy({ Strategy, Symbol });
        const modifiedParameters = cleanParametersForOrder(Parameters, effectiveStrategy);
        let ordType: OrdTypeEnum = effectiveStrategy?.OrdType ?? OrdTypeEnum.Limit;
        let timeInForce: TimeInForceEnum = TimeInForceEnum.GoodTillCancel;
        if (Strategy === 'Market' || ordType === OrdTypeEnum.Market) {
          ordType = OrdTypeEnum.Market;
          timeInForce = TimeInForceEnum.FillOrKill;
          delete modifiedParameters.LimitPrice;
        }
        const data = {
          Symbol,
          ClOrdID,
          Side,
          TransactTime: formattedDateForSubscription(transactTime),
          OrderQty,
          OrdType: ordType,
          Price: modifiedParameters.LimitPrice,
          Currency,
          TimeInForce: timeInForce,
          Strategy,
          MarketAccount,
          Parameters: modifiedParameters,
        };

        client.registerPublication({
          type: NEW_ORDER_SINGLE,
          data: [data],
        });
        return ClOrdID;
      }
    },
    [client, getEffectiveStrategy]
  );

  const modifyOrder: OrderContextProps['modifyOrder'] = useCallback(
    (order, transactTime = new Date()) => {
      if (client != null) {
        const ClOrdID = uuid();
        const { Symbol, OrderID, OrderQty, Parameters, Strategy } = order;
        const effectiveStrategy = getEffectiveStrategy({ Strategy, Symbol });
        const modifiedParameters = cleanParametersForOrder(Parameters, effectiveStrategy);
        const data = {
          Price: modifiedParameters.LimitPrice,
          Symbol,
          OrderID,
          ClOrdID,
          TransactTime: formattedDateForSubscription(transactTime),
          OrderQty,
          Strategy: effectiveStrategy?.Name,
          Parameters: modifiedParameters,
        };
        client.registerPublication({
          type: ORDER_MODIFY_REQUEST,
          data: [data],
        });
        return ClOrdID;
      }
    },
    [client, getEffectiveStrategy]
  );

  const cancelOrder: OrderContextProps['cancelOrder'] = useCallback(
    (orderID, requirePausedOrder) => {
      const clOrdID = uuid();
      const cancelOrder = () => {
        mixpanel.track(MixpanelEvent.CancelOrder);
        client?.registerPublication({
          type: ORDER_CANCEL_REQUEST,
          data: [
            {
              ClOrdID: clOrdID,
              OrderID: orderID,
              TransactTime: formattedDateForSubscription(new Date()),
            },
          ],
        });
      };
      return createActionPromise({
        clOrdID,
        expectedOrdStatus: requirePausedOrder ? [OrdStatusEnum.Canceled] : undefined,
        action: cancelOrder,
      });
    },
    [client, createActionPromise, mixpanel]
  );

  const pauseOrder = useCallback(
    orderID => {
      const clOrdID = uuid();
      client?.registerPublication({
        type: ORDER_CONTROL_REQUEST,
        data: [
          {
            ClOrdID: clOrdID,
            OrderID: orderID,
            Action: OrderControlActionEnum.Pause,
            TransactTime: formattedDateForSubscription(new Date()),
          },
        ],
      });
      return clOrdID;
    },

    [client]
  );

  const resumeOrder = useCallback(
    orderID => {
      const clOrdID = uuid();
      client?.registerPublication({
        type: ORDER_CONTROL_REQUEST,
        data: [
          {
            ClOrdID: clOrdID,
            OrderID: orderID,
            Action: OrderControlActionEnum.Resume,
            TransactTime: formattedDateForSubscription(new Date()),
          },
        ],
      });
      return clOrdID;
    },
    [client]
  );

  // Recent Orders (Last 2 days)
  const { data: recentSub } = useStaticSubscription<CustomerOrder>({
    name: ORDER,
    tag: 'RECENT_ORDERS',
    StartDate: formattedDateForSubscription(sub(new Date(), { days: 2 })),
    sort_by: '-Timestamp',
    HideApiCalls: true,
    Throttle: '1s',
  });

  const recentOrders = useObservable<CustomerOrder[]>(
    () =>
      recentSub.pipe(
        map(recent => recent.data),
        throttleTime(RECENT_ORDERS_THROTTLE_MS, asyncScheduler, {
          leading: true,
          trailing: true,
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [recentSub]
  );

  // Recent Orders (Last 1 year)
  const { data: allOrdersSub } = useStaticSubscription<CustomerOrder>({
    name: ORDER,
    tag: 'OrdersProvider',
    HideApiCalls: true,
    Throttle: '1s',
    StartDate: formattedDateForSubscription(sub(new Date(), { years: 1 })),
  });
  const ordersByClOrdIDObs = useObservable(
    () =>
      allOrdersSub.pipe(
        wsScanToMap({ getUniqueKey: d => d.ClOrdID, newMapEachUpdate: true }),
        throttleTime(RECENT_ORDERS_THROTTLE_MS, asyncScheduler, {
          leading: true,
          trailing: true,
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [allOrdersSub]
  );

  const ordersByOrderIDObs = useObservable(
    () =>
      allOrdersSub.pipe(
        wsScanToMap({ getUniqueKey: d => d.OrderID, newMapEachUpdate: true }),
        throttleTime(RECENT_ORDERS_THROTTLE_MS, asyncScheduler, {
          leading: true,
          trailing: true,
        }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [allOrdersSub]
  );

  const ordersByOrderID = useObservableValue(() => ordersByOrderIDObs, [ordersByOrderIDObs], new Map());
  const ordersByClOrdID = useObservableValue(() => ordersByClOrdIDObs, [ordersByClOrdIDObs], new Map());

  return (
    <OrdersContext.Provider
      value={{
        recentOrders,
        ordersByClOrdID,
        ordersByOrderID,
        sendOrder,
        modifyOrder,
        cancelOrder,
        pauseOrder,
        resumeOrder,
      }}
    >
      {props.children}
    </OrdersContext.Provider>
  );
});
