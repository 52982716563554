import styled from 'styled-components';
import { setAlpha } from '../../../styles';
import { Button } from '../../Button';
import { Flex } from '../../Core';
import { FormControlSizes } from '../../Form/types';
import { Icon } from '../../Icons';
import { Text } from '../../Text';

export const PropertyButton = styled(Button)`
  color: ${({ theme }) => theme.colors.gray['090']};
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
`;

export const SelectionButtonWrapper = styled.div``;
export const SelectionButton = styled(Button)``;
export const SelectionsListWrapper = styled(Flex)`
  gap: 1px;
  ${SelectionButtonWrapper} {
    ${SelectionButton} {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    :not(:last-child) {
      ${SelectionButton} {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }
`;

export const Middle = styled(Flex)`
  background: ${({ theme }) => setAlpha(0.2, theme.colors.gray['100'])};
  color: ${({ theme }) => theme.colors.gray['090']};
  margin: 0 1px;
  padding: 0 ${({ theme }) => theme.spacingDefault}px;
  font-weight: 500;
`;

export const FilterBuilderContent = styled(Flex)`
  ${Button} {
    font-weight: 500;
  }

  flex-wrap: wrap;
`;

export const SelectionRemoveIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.gray['080']};
`;

export const DropdownLabelContainer = styled(Flex)`
  background: ${({ theme }) => theme.colors.gray['050']};
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
  border-bottom: solid 1px ${({ theme }) => theme.backgroundDropdownResults};
`;
DropdownLabelContainer.defaultProps = {
  p: 'spacingSmall',
  px: 'spacingComfortable',
  gap: 'spacingDefault',
  alignItems: 'center',
  justifyContent: 'space-between',
};

export const ActionableText = styled(Text)`
  :hover {
    cursor: pointer;
  }
`;

export const FilterClauseWrapper = styled(Flex)`
  height: ${({ theme }) => theme.baseSize * FormControlSizes.Default}px;
`;

export const FilterBuilderToggleBadge = styled.span<{ isOpen: boolean }>`
  transition: background 100ms;
  padding: 2px 5px;
  border-radius: 2px;

  ${({ theme }) => `
    background: ${theme.colors.orange.default};
    margin-left: ${theme.spacingSmall}px;
  `}

  ${({ isOpen, theme }) =>
    isOpen &&
    `
    color: ${theme.colorTextSubtle};
    background: transparent;
  `}
`;
