import { cloneDeep, merge } from 'lodash';
import { hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { LightTheme, mapLightTheme } from './LightTheme';

const customcolors: Partial<Colors> = {
  gray: {
    main: hsla(210, 1.0, 1.0, 1),
    '000': hsla(210, 0.1, 0.92, 1),
    '010': hsla(210, 0.2, 0.96, 1),
    '020': hsla(210, 0.07, 0.89, 1),
    '030': hsla(220, 0.04, 0.86, 1),
    '040': hsla(220, 0.04, 0.83, 1),
    '050': hsla(220, 0.03, 0.79, 1),
    '060': hsla(200, 0.02, 0.74, 1),
    '070': hsla(200, 0.02, 0.69, 1),
    '080': hsla(200, 0.02, 0.63, 1),
    '090': hsla(210, 0.01, 0.44, 1),
    '100': '#000D22',
  },

  black: {
    default: '#000000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#FFFFFF',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.1)',
  },

  primary: {
    mute: 'rgba(0, 96, 245, 0.1)',
    dim: 'rgba(0, 96, 245, 0.4)',
    default: '#0060f5',
    lighten: '#327ff7',
  },

  green: {
    mute: 'rgba(51, 132, 0, 0.1)',
    dim: 'rgba(51, 132, 0, 0.4)',
    default: '#12b76a',
    lighten: '#12b76a',
  },

  red: {
    mute: 'rgba(196, 0, 76, 0.1)',
    dim: 'rgba(196, 0, 76, 0.4)',
    default: '#f04438',
    lighten: '#f04438',
  },

  yellow: {
    default: '#F79009',
    lighten: '#FEF0C7',
    dim: 'rgba(204, 172, 62, 0.4)',
    mute: 'rgba(204, 172, 62, 0.1)',
  },

  blue: {
    mute: 'rgba(0, 96, 245, 0.1)',
    dim: 'rgba(0, 96, 245, 0.4)',
    default: '#0060f5',
    lighten: '#327ff7',
  },

  orange: {
    default: '#E65200',
    lighten: '#FF6B00',
    dim: 'rgba(230, 82, 0, 0.4)',
    mute: 'rgba(230, 82, 0, 0.1)',
  },
};
const colors: Colors = merge(cloneDeep(LightTheme.colors), customcolors);

export const LightBCBTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightBCB',

  fontFamily: "'Montserrat', sans-serif",
  fontUrl:
    'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',

  backgroundBody: colors.gray['010'],

  backgroundPriorityButton: colors.gray['060'],
  backgroundPriorityButtonHover: colors.gray['070'],
  backgroundPriorityButtonFocus: colors.gray['070'],
  backgroundPriorityButtonActive: colors.gray['060'],

  backgroundDefaultButton: colors.gray['030'],
  backgroundDefaultButtonHover: colors.gray['040'],
  backgroundDefaultButtonFocus: colors.gray['040'],
  backgroundDefaultButtonActive: colors.gray['030'],

  colorTextVolumeLadderSpread: colors.gray['080'],
});
