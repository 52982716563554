import { useMemo } from 'react';
import { getAgGridColId } from '../components/BlotterTable/columns/getAgGridColId';
import type { Column, ColumnDef } from '../components/BlotterTable/columns/types';
import { useDefaultColumns } from '../components/BlotterTable/useDefaultColumns';
import { EMPTY_ARRAY } from '../utils/empty';
import { ReconStreamStatusEnum, type UpdateActionEnum } from './types';

export const ReconStreamStatusEnumLabels = {
  [ReconStreamStatusEnum.Unknown]: 'Unknown',
  [ReconStreamStatusEnum.Matching]: 'Matching',
  [ReconStreamStatusEnum.LedgerAhead]: 'Ledger Ahead',
  [ReconStreamStatusEnum.MatchingSinceLatestMismatch]: 'Reconciliation Mismatch',
  [ReconStreamStatusEnum.LedgerAheadSinceLatestMismatch]: 'Reconciliation Mismatch',
  [ReconStreamStatusEnum.MarketStale]: 'Market Stale',
  [ReconStreamStatusEnum.MarketOffline]: 'Market Offline',
};

export class ReconStatus {
  MarketAccount!: string;

  Asset!: string;

  TotalMismatch!: string;

  LastMismatchTime!: string;

  Status!: ReconStreamStatusEnum;

  Revision!: number;

  UpdateAction?: UpdateActionEnum;

  get rowID() {
    return `${this.MarketAccount}-${this.Asset}`;
  }
  get sortKey() {
    return `${this.MarketAccount}-${this.Asset}`;
  }
  constructor(data: ReconStatus) {
    // Should be the auto generated interface from ava.xml
    Object.assign(this, data);
  }
}

interface UseReconStatusColumns {
  defaultColumns?: (keyof ReconStatus | Partial<Column>)[];
}

export function useReconStatusColumns({ defaultColumns = EMPTY_ARRAY }: UseReconStatusColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            {
              field: 'MarketAccount',
              type: 'marketAccount',
              sortable: true,
              rowGroup: true,
            },
            {
              field: 'Asset',
              type: 'asset',
              sortable: true,
              params: { colorful: true },
            },
            {
              field: 'TotalMismatch',
              type: 'size',
              sortable: true,
              params: { currencyField: 'Asset', highlightNegative: true },
            },
            {
              field: 'LastMismatchTime',
              type: 'date',
              sortable: true,
            },
            {
              field: 'Status',
              type: 'text',
              sortable: true,
              params: { getLabel: (item: string) => ReconStreamStatusEnumLabels[item as ReconStreamStatusEnum] },
            },
            {
              field: 'Revision',
              type: 'text',
            },
            {
              field: 'UpdateAction',
              type: 'text',
            },
            {
              field: 'rowID',
              type: 'text',
            },
            {
              field: 'sortKey',
              type: 'text',
            },
          ] satisfies ColumnDef<ReconStatus>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );
  const columnDefinitions = useMemo(() => {
    return new Map(
      ([...defaultVisibleColumns.values()] satisfies ColumnDef<ReconStatus>[] as Column[]).map(c => [
        getAgGridColId(c),
        c,
      ])
    );
  }, [defaultVisibleColumns]);
  return useDefaultColumns(defaultColumns, columnDefinitions);
}
