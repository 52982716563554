import styled from 'styled-components';

import { parseColor } from '../../styles';
import { getStatusColor } from '../../utils';
import { Box } from '../Core';
import type { OrderStatusText } from '../Status';
import { Text } from '../Text';

export const CARD_BOX_SHADOW_INSET_HEIGHT = '2px';

const minWidth = '260px';

export const OrderCardWrapper = styled(Box)<{ status?: OrderStatusText; isComplete?: boolean }>`
  min-width: ${minWidth};
  background: ${({ theme }) => theme.colors.gray.main};
  color: ${({ theme }) => theme.colors.gray['100']};
  box-shadow: inset 0 ${CARD_BOX_SHADOW_INSET_HEIGHT} 0 0 ${({ status, theme }) => getStatusColor(status, theme)};

  ${({ isComplete, theme }) => isComplete && `background: ${parseColor(theme, 'gray.010')};`};
`;

export const Header = styled.div`
  display: grid;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacingDefault}px;

  > div {
    &:first-child {
      padding-bottom: ${({ theme }) => theme.spacingSmall}px;
    }

    display: flex;
    align-items: center;
    justify-content: space-between;

    * div {
      display: flex;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  font-weight: ${({ theme }) => theme.fontWeightMedium};
  color: ${({ theme }) => theme.colors.gray['100']};
`;

export const SubTitle = styled(Text)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colorTextSubtle};
  padding: 0px ${({ theme }) => theme.spacingSmall}px;
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  vertical-align: middle;
`;

export const Time = styled.div`
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  color: ${({ theme }) => theme.colors.gray['100']};
`;

export const Summary = styled.div`
  padding: ${({ theme }) => `${theme.spacingDefault}px 0 ${theme.spacingSmall}px`};
`;

export const OrderSide = styled.div<{ isBuy: boolean; isSell: boolean }>`
  color: ${props =>
    props.isBuy
      ? props.theme.colors.green.lighten
      : props.isSell
      ? props.theme.colors.red.lighten
      : props.theme.colors.gray['060']};
`;

export const OrderNumber = styled.span`
  color: ${({ theme }) => theme.colors.gray['100']};
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  margin-bottom: ${({ theme }) => theme.spacingSmall}px;
`;

export const Tbody = styled.tbody`
  tr > td:first-child {
    text-align: left;
  }
`;
export const Tr = styled.tr``;

export const Th = styled.th`
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  font-weight: ${({ theme }) => theme.fontWeightRegular};
  color: ${({ theme }) => theme.colors.gray['060']};
  text-align: right;
  padding: 2px 5px;

  &:last-child {
    padding-right: 0;
  }
`;

export const Td = styled.td<{ isSelected?: boolean }>`
  padding: 1px;

  &:first-child {
    padding-left: 0;
    padding-right: 1px;
  }

  &:last-child {
    padding-right: 0;
  }

  color: ${({ isSelected, theme }) => (isSelected ? theme.colors.gray['090'] : theme.colors.gray['080'])};
`;

export const ProfitWrapper = styled.div`
  justify-content: right;
  gap: ${({ theme }) => theme.spacingSmall}px;
`;

export const MinTd = styled(Td)`
  width: 1px;
  white-space: nowrap;
  text-align: right;
`;
