import { defineMessages } from 'react-intl';
import type { Security } from '../../types';
import { canViewDeepDive } from '../../utils';
import { Button } from '../Button';
import { FormControlSizes } from '../Form/types';
import { IconName } from '../Icons';
import { FormattedMessage } from '../Intl';

const messages = defineMessages({
  deepDive: {
    defaultMessage: 'DeepDive',
    id: 'DeepDiveButton.deepDive',
  },
});

interface DeepDiveButtonProps {
  security?: Security;
  selectedMarketAccounts?: string[];
  selectedMarkets?: string[];
  onClick: () => void;
}

export function DeepDiveButton({ security, onClick }: DeepDiveButtonProps) {
  if (!canViewDeepDive(security)) {
    return null;
  }

  return (
    <Button size={FormControlSizes.Small} endIcon={IconName.Deepdive} onClick={onClick}>
      <FormattedMessage {...messages.deepDive} />
    </Button>
  );
}
