import Big from 'big.js';
import { memo, useCallback, useState } from 'react';

import { Input } from '../Form/Input';

import { SideEnum, type Security } from '../../types';
import { abbreviate } from '../../utils';
import { format } from '../../utils/number';
import { Flex } from '../Core';
import { FormControlSizes } from '../Form';
import { InlineFormattedNumber, SmartTruncateEnum } from '../FormattedNumber';
import {
  BidSpreadBar,
  DisplayBps,
  MARKET_DATA_CARD_LADDER_INPUT_WIDTH,
  OfferSpreadBar,
  PriceValue,
  RowWrapper,
  SizeValue,
  SpreadBars,
  SpreadValue,
  TruncatedBpsSpread,
  Values,
} from './styles';
import type { ClickRowCallback, Step, UpdateRowCallback } from './types';

const getInputWidth = (expanded: boolean) =>
  expanded ? `${MARKET_DATA_CARD_LADDER_INPUT_WIDTH * 1.4}px` : `${MARKET_DATA_CARD_LADDER_INPUT_WIDTH}px`;

export const Row = memo(
  ({
    index,
    step,
    security,
    maxSpreadBps,
    midMarketPrice,
    onClickRow,
    onUpdateRow,
    showSpread,
    smartTruncate = SmartTruncateEnum.None,
    bpsIncrement = '0',
  }: VolumeLadderRowProps) => {
    const { DefaultPriceIncrement, MinPriceIncrement, PriceDisplaySpec } = security;
    const { spread, spreadBps, bid, offer, size } = step ?? {};

    const bidSpreadBarWidth = getSpreadBarWidth(bid, midMarketPrice, maxSpreadBps);
    const offerSpreadBarWidth = getSpreadBarWidth(offer, midMarketPrice, maxSpreadBps);

    const positiveSpread = Big(spread ?? 0).gt(0);
    const truncatedBpsSpread = format(spreadBps, { spec: bpsIncrement, round: true });

    const [editingBucketData, setEditingBucketData] = useState<{
      side: SideEnum | null;
      size: typeof size | null;
    }>({ side: null, size: null });
    const onKeyPress = useCallback(
      e =>
        e.key.toLowerCase() === 'enter' &&
        e.target.value != null &&
        !isNaN(e.target.value as any) &&
        onUpdateRow?.({
          index,
          size: e.target.value,
        }),
      [index, onUpdateRow]
    );
    const handleClickBid = useCallback(
      () =>
        bid != null &&
        onClickRow({
          size,
          side: SideEnum.Sell,
          price: format(bid, { pretty: false, spec: MinPriceIncrement }),
        }),
      [onClickRow, bid, MinPriceIncrement, size]
    );
    const handleClickOffer = useCallback(
      () =>
        offer != null &&
        onClickRow({
          size,
          side: SideEnum.Buy,
          price: format(offer, { pretty: false, spec: MinPriceIncrement }),
        }),
      [onClickRow, offer, MinPriceIncrement, size]
    );
    const handleEditSize = useCallback((side: SideEnum, bucketSize: typeof size) => {
      setEditingBucketData({ side, size: bucketSize });
    }, []);
    const handleSaveSize = useCallback(() => {
      if (editingBucketData.size && !isNaN(editingBucketData.size as any)) {
        onUpdateRow?.({ index, size: editingBucketData.size });
        setEditingBucketData({ side: null, size: null });
      }
    }, [editingBucketData, index, onUpdateRow, setEditingBucketData]);
    const handleUpdateSize = useCallback((side: SideEnum, bucketSize: typeof size) => {
      setEditingBucketData(prev => ({
        side,
        size: bucketSize,
      }));
    }, []);

    return (
      <RowWrapper data-testid="volume-ladder-row">
        {positiveSpread && (
          <SpreadBars>
            <div>
              <BidSpreadBar index={index} width={bidSpreadBarWidth} />
            </div>
            <div>
              <OfferSpreadBar index={index} width={offerSpreadBarWidth} />
            </div>
          </SpreadBars>
        )}
        <Values>
          {onUpdateRow ? (
            <Flex
              px="spacingDefault"
              alignItems="center"
              w={getInputWidth(editingBucketData.size != null)}
              transition="width ease 200ms"
              flex="0 0 auto"
            >
              <Input
                controlStyle={{ textAlign: 'center' }}
                onBlur={handleSaveSize}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUpdateSize(SideEnum.Buy, e.target.value)}
                onKeyUp={onKeyPress}
                onFocus={() => handleEditSize(SideEnum.Buy, format(size, { removeTrailingZeros: true, pretty: false }))}
                size={FormControlSizes.Small}
                value={editingBucketData.size !== null ? editingBucketData.size : abbreviate(size)}
              />
            </Flex>
          ) : (
            <SizeValue data-testid="bid-size" onClick={handleClickBid}>
              {abbreviate(size)}
            </SizeValue>
          )}
          <PriceValue data-testid="bid-price" onClick={handleClickBid}>
            <InlineFormattedNumber
              number={bid}
              increment={DefaultPriceIncrement}
              smartTruncate={smartTruncate}
              highlightAll={false}
              largeHighlight={true}
              trimTrailingZeroes={false}
              specification={PriceDisplaySpec}
            />
          </PriceValue>
          {showSpread && (
            <SpreadValue>
              {spread != null ? (
                <DisplayBps>
                  <TruncatedBpsSpread data-testid="truncated-bps-spread">{truncatedBpsSpread}</TruncatedBpsSpread>
                  <InlineFormattedNumber
                    number={spreadBps}
                    highlightColor="inherit"
                    increment="0.01"
                    currency="BPS"
                    round={true}
                  />
                </DisplayBps>
              ) : undefined}
            </SpreadValue>
          )}
          <PriceValue data-testid="offer-price" onClick={handleClickOffer}>
            <InlineFormattedNumber
              number={offer}
              increment={DefaultPriceIncrement}
              smartTruncate={smartTruncate}
              highlightAll={false}
              largeHighlight={true}
              trimTrailingZeroes={false}
              specification={PriceDisplaySpec}
            />
          </PriceValue>
          {onUpdateRow ? (
            <Flex
              px="spacingDefault"
              alignItems="center"
              w={getInputWidth(editingBucketData.size != null)}
              transition="width ease 200ms"
              flex="0 0 auto"
            >
              <Input
                controlStyle={{ textAlign: 'center' }}
                onBlur={handleSaveSize}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleUpdateSize(SideEnum.Sell, e.target.value)}
                onKeyPress={onKeyPress}
                onFocus={() =>
                  handleEditSize(SideEnum.Sell, format(size, { removeTrailingZeros: true, pretty: false }))
                }
                size={FormControlSizes.Small}
                value={editingBucketData.size !== null ? editingBucketData.size : abbreviate(size)}
              />
            </Flex>
          ) : (
            <SizeValue data-testid="offer-size" onClick={handleClickOffer}>
              {abbreviate(size)}
            </SizeValue>
          )}
        </Values>
      </RowWrapper>
    );
  }
);

type VolumeLadderRowProps = {
  index: number;
  isReadOnly?: boolean;
  step: Step;
  security: Security;
  maxSpreadBps?: string;
  midMarketPrice?: string;
  onClickRow: ClickRowCallback;
  onUpdateRow?: UpdateRowCallback;
  showSpread?: boolean;
  smartTruncate?: SmartTruncateEnum;
  bpsIncrement?: string;
};

function isNonZeroNumericString(value: string | undefined): value is string {
  return value != null && value !== '' && value !== '0';
}

/** Returns a string signifying the spread bar width if successful, undefined if unsuccessful */
function getSpreadBarWidth(price?: string, midMarketPrice?: string, maxSpreadBps?: string): string | undefined {
  if (price != null && isNonZeroNumericString(midMarketPrice) && isNonZeroNumericString(maxSpreadBps)) {
    return Big(midMarketPrice)
      .minus(price ?? '0')
      .abs()
      .div(midMarketPrice)
      .div(maxSpreadBps)
      .times(1_000_000)
      .toFixed();
  }

  // fail, return undefined
  return undefined;
}
