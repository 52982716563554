/**
 * For items using @dnd-kit to enable their drag and drop functionality,
 * @dnd-kit itself is a little bit greedy about how it handles on*Down
 * events in child elements. Its default behavior is to allow the user
 * to grab any portion of the wrapped element in order to start dragging
 * it around. That means that whichever sensor you're using (Keyboard,
 * Mouse, Pointer), the on*Down event (e.g. onKeyDown, onMouseDown,
 * onPointerDown) gets swallowed as part of the component's drag
 * initialization. Because some of our components may have various regions
 * where this is less than ideal (e.g. SymbolSelectors, Buttons, etc),
 * this function allows us to wrap those zones of a subcomponent, to
 * tell the @dnd-kit library that this particular portion of the DOM
 * tree is off limits to its event listeners, so that we can continue
 * to interact with these elements, rather than initiating a drag operation.
 */
export function shouldHandleEvent(element: HTMLElement | null) {
  let cur = element;

  while (cur) {
    if (cur.dataset?.noDnd) {
      return false;
    }

    cur = cur.parentElement;
  }

  return true;
}
