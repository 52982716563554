import type { Observable } from 'rxjs';
import type { MarketDataSnapshot } from '../../types/MarketDataSnapshot';
import type { OHLCData } from '../../types/OHLCData';
import type { ResolutionEnumNoPrefix } from '../../types/OrderAnalytic';
import type { Security } from '../../types/Security';
import type { BoxProps } from '../Core';
import { Sparkline } from './Sparkline';
import { TopOfBook } from './TopOfBook';
import { MarketViewWrapper } from './styles';
import type { ClickRowCallback } from './types';

export const MarketView = ({
  onClickRow,
  resolution,
  marketDataObservable,
  sparklineDataObservable,
  security,
  showSentiment = true,
  showSpread,
  showReferenceLines = true,
  width,
  bpsIncrement,
  ...boxProps
}: MarketViewProps) => {
  let description = '';
  let limit = 0;

  switch (resolution) {
    case '1H': {
      description = 'Last 24 hours';
      limit = 24;
      break;
    }
    case '5M': {
      description = 'Last 6 hours';
      limit = 72;
      break;
    }
    case '1M': {
      description = 'Last hour';
      limit = 60;
      break;
    }
    case '1S': {
      description = 'Last minute';
      limit = 60;
      break;
    }
    default: {
      description = '';
      limit = 0;
      break;
    }
  }

  return (
    <MarketViewWrapper {...boxProps}>
      <TopOfBook
        onClickRow={onClickRow}
        topOfBookObservable={marketDataObservable}
        security={security}
        showSentiment={showSentiment}
        showSpread={showSpread}
        width={width}
        bpsIncrement={bpsIncrement}
      />
      <Sparkline
        description={description}
        dataObservable={sparklineDataObservable}
        limit={limit}
        security={security}
        showReferenceLines={showReferenceLines}
      />
    </MarketViewWrapper>
  );
};

export type MarketViewProps = {
  resolution: ResolutionEnumNoPrefix;
  onClickRow: ClickRowCallback;
  security: Security | undefined;
  marketDataObservable: Observable<MarketDataSnapshot>;
  sparklineDataObservable: Observable<OHLCData[]>;
  showSentiment?: boolean;
  showSpread?: boolean;
  showReferenceLines?: boolean;
  width?: number;
  bpsIncrement?: string;
} & Omit<BoxProps, 'security'>;
