import { useCallback, useState, type MouseEventHandler, type ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { Box, Flex } from '../Core';

export const ExpandableText = ({ children }: { children: ReactNode }) => {
  const [showMore, setShowMore] = useState<boolean>(false);

  const onMouseOverText: MouseEventHandler<HTMLDivElement> = useCallback(e => {
    if (e.currentTarget.offsetWidth < e.currentTarget.scrollWidth) {
      setShowMore(true);
    } else {
      setShowMore(false);
    }
  }, []);

  return (
    <Flex w="100%">
      <ShowMoreText onMouseEnter={onMouseOverText} onMouseLeave={onMouseOverText} showMore={showMore}>
        {children}
      </ShowMoreText>
    </Flex>
  );
};

export const ShowMoreText = styled(Box)<{ showMore: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  ${({ showMore }) =>
    showMore &&
    css`
      overflow: visible;
      text-overflow: unset;
      width: fit-content;
      z-index: 1;
      background: ${({ theme }) => theme?.colors?.gray['010']};
      box-shadow: ${({ theme }) => `0 0 0.625em ${theme?.colors?.gray['010']}`};
      padding-left: ${({ theme }) => theme.spacingSmall}px;
      padding-right: ${({ theme }) => theme.spacingSmall}px;
      margin-left: ${({ theme }) => -theme.spacingSmall}px;
      margin-right: ${({ theme }) => -theme.spacingSmall}px;
      border-radius: ${({ theme }) => theme.borderRadiusSmall}px;
    `}
`;
