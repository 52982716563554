import { useTheme } from 'styled-components';
import { StepConnectionWrapper } from './styles';
import { ProcessStepSizes, ProcessStepState } from './types';

type StepConnectionProps = {
  hidden: boolean;
  state: ProcessStepState;
  size: ProcessStepSizes;
};

export const StepConnection = ({ state, hidden, size }: StepConnectionProps) => {
  const { baseSize, colorStepConnectionOpen, colorStepConnectionDone } = useTheme();
  const width = size * baseSize * 4;
  const height = size !== ProcessStepSizes.icon ? size * baseSize * 0.125 : 1;
  if (hidden) {
    return null;
  }

  return (
    <StepConnectionWrapper
      size={size}
      width={width}
      height={height}
      viewBox="0 0 64 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="8"
        y1="1"
        x2="56"
        y2="1"
        stroke={colorStepConnectionOpen}
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="3 6"
      />
      <line
        x1="8"
        y1="1"
        x2="56"
        y2="1"
        stroke={colorStepConnectionDone}
        strokeWidth="2"
        strokeDasharray="65 65"
        strokeDashoffset={state === ProcessStepState.Open ? 65 : 0}
        style={{
          transition: 'stroke-dashoffset 200ms ease',
        }}
      />
    </StepConnectionWrapper>
  );
};
