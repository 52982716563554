import {
  GroupCellRenderer,
  type ColDef,
  type GroupCellRendererParams,
  type HeaderValueGetterParams,
  type ValueGetterParams,
} from 'ag-grid-enterprise';
import { compact, startCase } from 'lodash';
import { defineMessages } from 'react-intl';

import { getCellDisplayValue, getCellFilterValue } from '../../AgGrid/agGridGetCellValue';
import { Text } from '../../Text';
import { AGGRID_AUTOCOLUMN_ID } from '../types';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

const messages = defineMessages({
  grouping: {
    defaultMessage: 'Grouping',
    id: 'BlotterTable.columns.grouping',
  },
});

/**
 * @module
 * # OVERVIEW #
 *
 * This file defines a "group" column type, useful for either an 'autoGroupColDef'
 * or a custom group column.
 * Here's a few things we're trying to address:
 *
 * 1. Ag-grid by default, will not render anything in the leaf nodes of a group.
 *    - Our fix for this is to ensure that we return a value for these nodes.
 * 2. We want ag-grid to render the leaf nodes with the same renderer etc. that
 *    it would use if rendering that column normally.
 *    - Our fix for this is to override `cellRendererSelector` and try to return
 *      the correct renderer
 * 3. Some cell renderers use the `valueFormatted` instead of the `value`
 *    - We override the valueFormatter as well, so that it executes the valueFormatter
 *      for the leaf column
 * 4. We don't want to have groups with only one child row, that doesn't look great
 *    - We fix this with the grid option `groupRemoveSingleChildren`
 *    - Note: ag-grid does filtering after grouping, so if there is a client-side filter,
 *      you may still see groups with only child node.
 *      To fix this, you'll need to apply the client-side filtering before ag-grid, e.g.
 *      in the data observable that you pass to ag-grid.
 * 5. Some columns don't have any valueGetter defined.
 *    - For this case we return the "key" of the group node, since that's where
 *      AgGrid stores the "value being used to perform the current grouping"
 * 6. Some columns have a filterValueGetter which is different than their valueGetter.
 *    - For this case we also define a filterValueGetter in the group column
 *
 * ## Assumptions ##
 * All the code here assumes the following facts.  These facts held true when the code
 * was originally written, but may perhaps become outdated with subsequent ag-grid updates.
 *
 * - `params.node.group` is true for group nodes, false for leaf nodes
 * - `params.node.key` where `params.node.group` is true will be the value of the cell we're working on
 * - `params.node.group` being false will only ever occurr within a parent where `params.node.group` is true.
 * --- Interestingly, when we do things like "groupRemoveSingleChildren", even though there visually is no parent to a leaf node, it still exists in the data model, so it works.
 * --- This relies on the fact that, when there are no `rowGroupColumns` defined, the `group` column is removed from the blotter.
 *     This is handled automatically by us using aggrid's auto group column mode.
 */

export const group: ColDefFactory<
  Column<
    Partial<GroupCellRendererParams> & { renderIconPrefix?: boolean; headerValueGetter?: ColDef['headerValueGetter'] }
  >
> = column => ({
  // we enforce the id to be the same as the aggrid default autogroup column id. This is assigned in the baseColumn factory function
  // this is because we rely on this being the id when we resolve things related to the autogroup column.
  ...baseColumn({ ...column, id: AGGRID_AUTOCOLUMN_ID }),
  /**
   * baseColumn adds in a default comparator to our columns. By default, aggrid has any group columns simply use the
   * defined comparators on the columns which are being grouped. By setting comparator to undefined here, we make sure to
   * utilize this default behavior to just use the sorting combined in the underlying columns.
   */
  comparator: undefined,
  showRowGroup: true, // Show all row groups in this column
  valueGetter(params) {
    if (!params.node) {
      return;
    }

    const groupedColumn = getGroupedColumn(params);
    if (!groupedColumn) {
      return;
    }

    // We get the grouped column and call api.getCellValue using that column
    return params.api.getCellValue({ rowNode: params.node, colKey: groupedColumn });
  },
  filterValueGetter(params) {
    const groupedColumn = getGroupedColumn(params);
    if (!groupedColumn) {
      return;
    }

    const groupedColumnHasNoValueGetters =
      groupedColumn.getColDef().filterValueGetter == null && groupedColumn.getColDef().valueGetter == null;

    // If the grouped column has no valueGetters, then the value is just the basic field resolved from the entity. When in the grouped column, this will be the grouping's "key".
    if (groupedColumnHasNoValueGetters && params.node) {
      return params.node.group ? params.node.key : params.node.parent?.key;
    }

    // Otherwise, the grouped column has either a valueGetter or a filterValueGetter. We invoke the "getCellFilterValue" func below
    // which will return the filterValue, or just value if there is no filter value to be resolved
    try {
      if (!params.node) {
        return undefined;
      }

      const value = params.api.getCellValue({ rowNode: params.node, colKey: groupedColumn });
      return getCellFilterValue({ ...params, value, column: groupedColumn });
    } catch {
      return;
    }
  },
  valueFormatter(params) {
    // Some of our cell renderers use the formatted value instead of the raw value
    // but ag-grid won't automatically call the correct value formatter for us.
    // So we need to call it manually instead.
    const groupedColumn = getGroupedColumn(params);

    if (groupedColumn) {
      return getCellDisplayValue({ ...params, column: groupedColumn });
    }
    return;
  },
  cellRendererSelector: params => {
    const joinedParams = {
      ...params,
      innerRendererSelector,
      ...(column.params ?? {}),
    };
    // If its a pinned row, we opt out of the default group cell renderer and just do basic text
    return params.node.rowPinned
      ? {
          params: joinedParams,
          component: (params: GroupCellRendererParams) => <Text>{params.data.groupColumnValue ?? params.value}</Text>,
        }
      : { params: joinedParams, component: GroupCellRenderer };
  },
  headerValueGetter:
    column.params?.headerValueGetter ??
    ((params: HeaderValueGetterParams) => {
      const groupedColumnHeaderNames = compact(
        params.api.getRowGroupColumns().map(c => {
          const colDef = c.getColDef();
          // If a headerValueGetter function is defined in the column definition,
          // it is used to get the value for the group column header.
          if (colDef.headerValueGetter && colDef.headerValueGetter instanceof Function) {
            return colDef.headerValueGetter(params);
          }
          if (colDef.field) {
            return startCase(colDef.field);
          }
          return null;
        })
      );

      return groupedColumnHeaderNames && groupedColumnHeaderNames.length > 0
        ? groupedColumnHeaderNames.join(' > ')
        : params.context.current?.intl.formatMessage(messages['grouping'], column.titleIntlKeyValues) ?? '';
    }),
});

/**
 * Gets the "actual" column for a grouped node.
 */
function getGroupedColumn(params: Pick<ValueGetterParams, 'api' | 'node'>) {
  // This function only runs within the `group` column. Within this column, there can be group nodes (rows) and non-group (leaf) nodes.
  // These are defined by the `node.group` property. When a node is grouped, it will have `rowGroupColumn` defined. If a node is not grouped,
  // It is a leaf node of a parent node which is grouped. The leaf node is always of the same column that its parent, and thus we can just grab
  // the parent's rowGroupColumn.
  return params.node?.group ? params.node?.rowGroupColumn : params.node?.parent?.rowGroupColumn;
}

const innerRendererSelector =
  /**
   * This function exists to make ag-grid render all values as they would be in their own column.
   * @param params Params object passed by ag-grid
   * @returns
   */
  function innerRendererSelector(params) {
    const colDef = getGroupedColumn(params)?.getColDef();
    return { component: colDef?.cellRenderer, params: colDef?.cellRendererParams };
  } satisfies GroupCellRendererParams['innerRendererSelector'];
