import Big from 'big.js';
import { useTheme } from 'styled-components';
import { DecisionStatusEnum, OrdStatusEnum, type PricingModeEnum } from '../../types';
import { getStatusColor } from '../../utils';
import { Meter, type MeterProps } from '../Meter';
import { getOrderStatusText } from '../Status';

export function FilledMeter({
  entity,
  ...props
}: {
  entity: {
    OrderQty: string;
    CumQty: string;
    OrdStatus: OrdStatusEnum;
    DecisionStatus?: DecisionStatusEnum;
    PricingMode?: PricingModeEnum;
  };
} & Omit<MeterProps, 'value'>) {
  const theme = useTheme();

  if (entity == null) {
    return null;
  }

  const { OrdStatus, OrderQty, CumQty, DecisionStatus, PricingMode } = entity;
  const status = getOrderStatusText({
    ordStatus: OrdStatus ?? OrdStatusEnum.PendingNew,
    orderQty: OrderQty ?? '',
    cumQty: CumQty ?? '',
    decisionStatus: DecisionStatus ?? DecisionStatusEnum.Active,
    pricingMode: PricingMode,
  });

  const filledShare = Big(OrderQty ?? '0').gt(0) ? Big(CumQty ?? '0').div(OrderQty) : Big(0);
  const progressBarColor = getStatusColor(status, theme);

  return <Meter color={progressBarColor} showLabel={false} {...props} value={filledShare.toNumber()} />;
}
