import {
  Box,
  Button,
  ButtonGroup,
  FormControlSizes,
  IconName,
  Menu,
  MenuItem,
  MixpanelEvent,
  MixpanelEventProperty,
  NavButton,
  SidebarContext,
  sidebarContextDefaultValues,
  SidebarCurrentFeeTier,
  SidebarThemeToggle,
  useAuthContext,
  useMixpanel,
  useWLLayoutType,
  useWLOrgConfigContext,
  useWLUser,
  type ThemeTypes,
} from '@talos/kyoko';
import { useCallback } from 'react';
import { LayoutType } from '../../../layouts/layout';

export function UserMenu({
  showLayoutToggle,
  showThemeToggle,
  themeType,
  onChangeThemeType,
}: {
  showThemeToggle: boolean;
  showLayoutToggle: boolean;
  themeType: ThemeTypes;
  onChangeThemeType: (themeType: ThemeTypes) => void;
}) {
  const { logout } = useAuthContext();
  const user = useWLUser();
  const { layoutType, setLayoutType, tradingLayout } = useWLLayoutType();
  const { config } = useWLOrgConfigContext();

  const mixpanel = useMixpanel();

  const handleChangeLayout = useCallback(
    e => {
      mixpanel.track(MixpanelEvent.ChangeLayout, { [MixpanelEventProperty.Type]: e.target.value });
      setLayoutType(e.target.value);
    },
    [setLayoutType, mixpanel]
  );

  return (
    <SidebarContext.Provider value={sidebarContextDefaultValues}>
      <Menu triggerIcon={IconName.User} triggerMinimal={false} triggerRound={false}>
        <Box px="spacingComfortable" py="spacingDefault">
          <Box fontSize="fontSizeDefault" color="colorTextImportant">
            {user.DisplayName}
          </Box>
          {!config.hideUserEmail && <Box fontSize="fontSizeDefault">{user.Email}</Box>}
        </Box>
        {config.menuItems?.map(item => (
          <NavButton
            ghost
            key={`${item.label}_${item.url}`}
            to={item.url}
            target="_blank"
            rel="noreferrer"
            endIcon={IconName.ExternalLink}
          >
            {item.label}
          </NavButton>
        ))}
        <SidebarCurrentFeeTier />
        {showThemeToggle && <SidebarThemeToggle themeType={themeType} onChangeThemeType={onChangeThemeType} />}
        {showLayoutToggle && (
          <ButtonGroup size={FormControlSizes.Small}>
            <Button
              disabled={layoutType === LayoutType.SimpleRFQLayout}
              value={LayoutType.SimpleRFQLayout}
              onClick={handleChangeLayout}
            >
              RFQ layout
            </Button>
            <Button disabled={layoutType === tradingLayout} value={tradingLayout} onClick={handleChangeLayout}>
              Trading layout
            </Button>
          </ButtonGroup>
        )}
        <MenuItem onClick={logout} endIcon={IconName.Logout}>
          Logout
        </MenuItem>
      </Menu>
    </SidebarContext.Provider>
  );
}
