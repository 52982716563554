import { useEffect, useRef, useState, type PropsWithChildren } from 'react';
import { usePageVisibility } from 'react-page-visibility';

import { Spinner } from '@talos/kyoko';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { useMarketDataCards } from '../../providers';
import { DroppableContainer } from './DroppableContainer';
import { useCards } from './hooks/useCards';

export function Securities({ children }: PropsWithChildren<any>) {
  const isVisible = usePageVisibility();
  const [renderCards, setRenderCards] = useState(true);
  const hideTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { createCard, cardFilter, setCardsAreDisplayed } = useMarketDataCards();

  // Since most browsers reduce the resources given to background tabs, we keep track
  // of the page visibility and when it's not visible, start a timer that once triggered,
  // prevents the rendering of all the cards. When the page becomes visible again the
  // cards are automatically rerendered. When removed all their subscriptions are
  // cancelled also reducing the server load.
  useEffect(() => {
    if (isVisible) {
      setRenderCards(true);
      if (hideTimer.current != null) {
        clearTimeout(hideTimer.current);
        hideTimer.current = null;
      }
    } else {
      if (hideTimer.current == null) {
        hideTimer.current = setTimeout(() => {
          setRenderCards(false);
        }, 5000);
      }
    }
  }, [isVisible]);

  useEffect(() => {
    setCardsAreDisplayed(true);

    return () => {
      setCardsAreDisplayed(false);
    };
  }, [setCardsAreDisplayed]);

  const cards = useCards();

  if (cards == null) {
    return <Spinner />;
  }

  return (
    <ErrorBoundary>
      {renderCards && (
        <DroppableContainer cardFilter={cardFilter} cards={cards} createCard={createCard} disableDrag={false}>
          {children}
        </DroppableContainer>
      )}
    </ErrorBoundary>
  );
}
