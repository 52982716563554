import styled, { css, keyframes } from 'styled-components';
import { Bullet, type BulletProps } from '../Bullet';
import { Icon, type IconProps } from '../Icons';
import { PopoverStyles } from '../Popover';

export const Text = styled.div`
  display: inline-block;
  white-space: nowrap;
  color: ${({ theme }) => theme.colorTextImportant};
`;

export const StatusWrapper = styled.div<{ iconRight?: boolean; isLoading?: boolean }>`
  ${({ theme, iconRight }) => css`
    display: flex;
    white-space: nowrap;
    height: ${theme.fontSizeDefault}em;
    line-height: ${theme.fontSizeDefault}em;
    align-items: center;
    gap: 0.5em;

    ${iconRight &&
    css`
      justify-content: flex-end;
    `}
    ${PopoverStyles.Content} span {
      max-width: 200px;
      display: block;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  `}
`;

export const BulletAnimation = keyframes`
    0% {
        opacity: 1
    }
    25% {
        opacity: 0.5;
        transform: scale(1);
    }
    50% {
        opacity: 0.25;
        transform: scale(0.65);
    }
    100% {
        opacity: 1
    }
`;

const BulletSpinnerAnimation = keyframes`
    0% {
        transform: rotate(-90deg);
        opacity: 0
    }
    25% {
        transform: rotate(-90deg);
        opacity: 0
    }
    50% {
        transform: rotate(-180deg);
        opacity: 0.8
    }
    75% {
        transform: rotate(-270deg);
        opacity: 0
    }
    100% {
        transform: rotate(-270deg);
        opacity: 0
    }
`;

export const BulletSpinner = styled(Icon)<IconProps>`
  position: absolute;
  transition: 300ms;
  animation: ${BulletSpinnerAnimation} 2s infinite forwards;
`;
export const BulletWithAnimation = styled(Bullet)<BulletProps & { isLoading?: boolean }>`
  ${({ isLoading }) =>
    isLoading &&
    css`
      animation: ${BulletAnimation} 2s infinite;
    `}
`;
