import {
  FormControlSizes,
  FormattedMessage,
  LocaleSelector,
  MixpanelEvent,
  MixpanelEventSource,
  SymbolDisplay,
  SymbolSelector,
  SymbolSelectorAppearance,
  ToggleButton,
  WL_USE_SYMBOL_SELECTOR,
  selectIsFormOpen,
  setOpenForm,
  themes,
  useIntlContext,
  useMixpanel,
  useWLOrderFormDispatch,
  useWLOrderFormSelector,
  useWLOrgConfigContext,
  useWLRoleAuth,
  useWLSymbol,
} from '@talos/kyoko';
import { useCallback } from 'react';
import { ThemeProvider, useTheme } from 'styled-components';

import { CreditUtilization } from 'components/CreditUtilization';
import { FundingSchedule } from 'components/FundingSchedule';
import { defineMessages } from 'react-intl';
import { useCurrentLayoutComponent } from '../useCurrentLayoutComponent';
import { EditLayoutButton } from './EditLayoutButton';
import { ExpandCollapseCardsButton } from './ExpandCollapseCardsButton';
import { HeaderContent, HeaderWrapper, Section } from './styles';

const messages = defineMessages({
  orderForm: {
    id: 'Layout.Header.orderForm',
    defaultMessage: 'Order Form',
  },
});

export const Header = ({ isTalosWLDemoEnv, showLocaleSelector, showSymbolSelector, maxWidth }: HeaderProps) => {
  const { locale, setLocale } = useIntlContext();
  const { symbol, setSymbol } = useWLSymbol();
  const { isAuthorized } = useWLRoleAuth();

  const formsOpen = useWLOrderFormSelector(selectIsFormOpen);
  const dispatch = useWLOrderFormDispatch();
  const setFormsOpen = useCallback(
    (isOpen: boolean) => {
      dispatch(setOpenForm(isOpen));
    },
    [dispatch]
  );
  const layoutComponent = useCurrentLayoutComponent();
  const { config } = useWLOrgConfigContext();

  const mixpanel = useMixpanel();

  const handleSymbolChange = useCallback(
    (symbol?: string) => {
      mixpanel.track(MixpanelEvent.ChangeSymbol, { source: MixpanelEventSource.Header });
      if (symbol) {
        setSymbol(symbol);
      }
    },
    [mixpanel, setSymbol]
  );

  const theme = useTheme();
  const headerTheme = (theme.headerThemeName && themes[theme.headerThemeName]) || theme;

  return (
    <ThemeProvider theme={headerTheme}>
      <HeaderWrapper>
        <HeaderContent maxWidth={maxWidth}>
          {layoutComponent.showOrderFormToggle === 'left' && (
            <Section>
              <ToggleButton size={FormControlSizes.Small} selected={formsOpen} onClick={() => setFormsOpen(!formsOpen)}>
                <FormattedMessage {...messages.orderForm} />
              </ToggleButton>
            </Section>
          )}
          {showSymbolSelector && (
            <Section data-testid="symbol-selector-section">
              {!isAuthorized(WL_USE_SYMBOL_SELECTOR) ? (
                <SymbolDisplay data-testid="symbol-display" symbol={symbol} />
              ) : (
                <SymbolSelector
                  appearance={SymbolSelectorAppearance.Ghost}
                  data-testid="symbol-selector"
                  symbol={symbol}
                  onSymbolChanged={handleSymbolChange}
                  dropdownWidth="200px"
                />
              )}
            </Section>
          )}
          <Section style={{ marginLeft: 'auto' }}>
            {showLocaleSelector && <LocaleSelector locale={locale} setLocale={setLocale} />}
            {isTalosWLDemoEnv && <EditLayoutButton />}
            {config.showCollapseCardButton && <ExpandCollapseCardsButton />}
            <FundingSchedule />
            <CreditUtilization />
          </Section>
          {(layoutComponent.showOrderFormToggle === 'right' || config.orderFormToggle) && (
            <Section>
              <ToggleButton size={FormControlSizes.Small} selected={formsOpen} onClick={() => setFormsOpen(!formsOpen)}>
                <FormattedMessage {...messages.orderForm} />
              </ToggleButton>
            </Section>
          )}
        </HeaderContent>
      </HeaderWrapper>
    </ThemeProvider>
  );
};

type HeaderProps = {
  isTalosWLDemoEnv?: boolean;
  showLocaleSelector?: boolean;
  showSymbolSelector: boolean;
  maxWidth?: number;
};
