import { useEffect, useRef } from 'react';

/**
 * Creates a countdown timer that will call the action when the countdown is finished.
 */
export function useCountdownAction({
  action,
  countdownTarget,
}: {
  countdownTarget: string | undefined;
  action: () => void;
}) {
  const startTimeCountdowRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  useEffect(() => {
    if (countdownTarget && typeof countdownTarget === 'string') {
      const timeLeft = new Date(countdownTarget).getTime() - Date.now();
      startTimeCountdowRef.current = setTimeout(() => {
        action();
      }, timeLeft);
      return () => {
        if (startTimeCountdowRef.current) {
          clearTimeout(startTimeCountdowRef.current);
        }
      };
    }
  }, [countdownTarget, action]);
}
