import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { freeze } from 'immer';
import type { WLOrderStrategy } from '../../../../types';
import type { MarketAccountsRefData, OMSReferenceDataState, SecuritiesReferenceData } from './types';

const initialRefDataState: OMSReferenceDataState = {
  securities: {
    securitiesList: [],
    securitiesBySymbol: new Map(),
  },
  marketAccounts: {
    customerMarketAccountsList: [],
    customerMarketAccountsBySourceAccountID: new Map(),
  },
  strategiesBySymbol: new Map(),
};

export const referenceDataSlice = createSlice({
  name: 'referenceData',
  initialState: initialRefDataState,
  reducers: {
    setStrategiesBySymbol: (state, action: PayloadAction<Map<string, WLOrderStrategy[]>>) => {
      state.strategiesBySymbol = freeze(action.payload);
    },

    setSecurities: (state, action: PayloadAction<SecuritiesReferenceData>) => {
      state.securities = freeze(action.payload);
    },

    setMarketAccounts: (state, action: PayloadAction<MarketAccountsRefData>) => {
      state.marketAccounts = freeze(action.payload);
    },

    __setReferenceData: (state, action: PayloadAction<OMSReferenceDataState>) => {
      /**
       * You should not be using this other than from tests
       */
      return action.payload;
    },
  },
});

export const { setSecurities, setMarketAccounts, __setReferenceData, setStrategiesBySymbol } =
  referenceDataSlice.actions;
