import type { ValueGetterParams } from 'ag-grid-community';
import { ProductTypeEnum } from '../../../types';
import type { Position } from '../../../types/Position';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

type PositionUnderlyingAssetValue = string | undefined;

export const positionUnderlyingAsset: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  cellRendererParams: {
    colorful: true,
    showDescription: false,
  },
  cellRenderer: 'currencyColumn',
  valueGetter: ({ data, context }: ValueGetterParams<Position>): PositionUnderlyingAssetValue => {
    if (data?.Asset == null) {
      return undefined;
    }

    if (data?.AssetType === ProductTypeEnum.Spot) {
      return data?.Asset;
    }

    return context.current.securitiesBySymbol?.get(data?.Asset)?.BaseCurrency;
  },
});
