import type { ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import type { AgGridContext } from 'ag-grid-community/dist/types/core/interfaces/iCommon';
import { Box, Flex } from 'components/Core';
import { Tooltip } from 'components/Tooltip';
import { get } from 'lodash';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

/**
 * The StringArrayColumnValue type contains both the "real" value in the type of a sorted markets array, but also
 * includes the effective formatted value. This is because we want to do sorting (comparator callback) on the formatted value.
 *
 * The comparator callback does not have access to the formatted value, only the value.
 */
export type StringArrayColumnValue = { arr: string[]; str: string };

export interface StringArrayColumnParams {
  getDisplayName: (value: string, context: AgGridContext) => string;
}

/**
 * The string array is a generic column factory for the case where you have an array of strings you want to represent in a  blotter column.
 *
 * If you just provide the column with how to resolve a display name for each string, the column will render a sorted csv-list
 * of display names. The column is also sortable (basic alphabetic sort on the concatenated rendered string).
 */
export const stringArray: ColDefFactory<Column<StringArrayColumnParams>> = column => ({
  ...baseColumn(column),
  valueGetter: ({ data, context }: ValueGetterParams<unknown>): StringArrayColumnValue => {
    if (!data || !column.field) {
      return { arr: [], str: '' };
    }

    const marketsArray = get(data, column.field);
    if (!(marketsArray instanceof Array)) {
      return { arr: [], str: '' };
    }

    const sortedArr = marketsArray.sort((a, b) => {
      if (typeof a !== 'string') {
        return 1;
      }

      if (typeof b !== 'string') {
        return -1;
      }

      const aName = column.params?.getDisplayName(a, context) ?? a;
      const bName = column.params?.getDisplayName(b, context) ?? b;
      return aName.localeCompare(bName);
    });

    const sortedStr = sortedArr.map(str => column.params?.getDisplayName(str, context) ?? str).join(', ');

    return {
      arr: sortedArr,
      str: sortedStr,
    };
  },
  valueFormatter: ({ value }: ValueFormatterParams<unknown, StringArrayColumnValue>): string => {
    return value?.str ?? '';
  },
  // When you want to know how to filter a string array column, we only return the raw data (the array of strings)
  filterValueGetter: ({ data }: ValueGetterParams<unknown>): string[] => {
    if (!data || !column.field) {
      return [];
    }

    const arr = get(data, column.field);
    if (!(arr instanceof Array)) {
      return [];
    }

    return arr;
  },
  cellRenderer: ({ value, valueFormatted, context }: ICellRendererParams<unknown, StringArrayColumnValue>) => {
    if (value == null) {
      return null;
    }

    return (
      <Box>
        <Tooltip
          usePortal
          placement="top-start"
          tooltip={
            <>
              {value.arr.map(str => (
                <Flex alignItems="center" key={str} justifyContent="flex-start">
                  <span>{column.params?.getDisplayName(str, context) ?? str}</span>
                </Flex>
              ))}
            </>
          }
        >
          {valueFormatted}
        </Tooltip>
      </Box>
    );
  },
  comparator: (valueA: StringArrayColumnValue, valueB: StringArrayColumnValue) => {
    return valueA.str.localeCompare(valueB.str);
  },
});
