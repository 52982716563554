import {
  FormattedMessage,
  HStack,
  Panel,
  PanelActions,
  PanelContent,
  PanelHeader,
  usePortal,
  useWLUser,
} from '@talos/kyoko';
import { defineMessages } from 'react-intl';
import { UserApiKeysFormTable } from './ApiKeys';

const PORTAL_ID = 'SETTINGS_GENERAL_API_KEYS';

const messages = defineMessages({
  apiSettings: {
    id: 'Settings.apiSettings',
    defaultMessage: 'API Settings',
  },
});

export function Settings() {
  const { CustomerUserID } = useWLUser();
  const { setPortalRef } = usePortal(PORTAL_ID);

  return (
    <HStack h="100%" w="100%" gap="spacingTiny" overflow="hidden">
      <Panel>
        <PanelHeader>
          <h2>
            <FormattedMessage {...messages.apiSettings} />
          </h2>
          <PanelActions ref={setPortalRef} />
        </PanelHeader>
        <PanelContent>
          <UserApiKeysFormTable portalID={PORTAL_ID} userID={CustomerUserID} />
        </PanelContent>
      </Panel>
    </HStack>
  );
}
