import type Big from 'big.js';
import { createContext, useContext } from 'react';
import type { Observable } from 'rxjs';

export interface SparklineValues {
  Volume: Big | undefined;
  Values: number[] | undefined;
  Close: Big | undefined;
  Open: Big | undefined;
  PercentChange: Big | undefined;
}

export interface SparklineContextProps {
  sparklinesBySymbol: Observable<Map<string, SparklineValues | undefined>>;
  registerSubscription: (symbol: string) => void;
  registerSubscriptions: (symbols: string[]) => void;
  unregisterSubscription: (symbol: string, persistRate?: boolean) => void;
  unregisterSubscriptions: (symbols: string[]) => void;
}
export const SparklineContext = createContext<SparklineContextProps | undefined>(undefined);
SparklineContext.displayName = 'SparklineContext';

export function useSparklineContext() {
  const context = useContext(SparklineContext);
  if (context === undefined) {
    throw new Error('Missing SparklineContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}
