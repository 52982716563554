import { useCombobox, useMultipleSelection } from 'downshift';
import { isNil } from 'lodash';
import type { RefObject } from 'react';
import type { UseAutocompleteProps } from '../Autocomplete/types';
import { useAutocomplete } from '../Autocomplete/useAutocomplete';

export type UseMultiSelectAutocompleteProps<T> = UseAutocompleteProps<T> & {
  selections: T[];
  onChange: (newSelections: T[]) => void;
  clearInputAfterSelection?: boolean;
  removeItemOnInputBackspace?: boolean;
  /** Highlights the text in the passed inputRef upon item selection */
  highlightInputTextAfterSelection?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  /**
   * A function used to get whether or not an item T is to be seen as "locked". Locked items, once selected, cannot be unselected.
   */
  isSelectionLocked?: (obj: T) => boolean;
};

export const useMultiSelectAutocomplete = <T,>({
  selections,
  items,
  onChange,
  getLabel,
  getDescription,
  isSelectionLocked,
  clearInputAfterSelection = true,
  removeItemOnInputBackspace = true,
  highlightInputTextAfterSelection = false,
  inputRef,
  ...props
}: UseMultiSelectAutocompleteProps<T>) => {
  const multipleSelection = useMultipleSelection({
    selectedItems: selections,
    onStateChange({ selectedItems }) {
      if (selectedItems != null) {
        onChange(selectedItems);
      }
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;

      if (type === useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace && !removeItemOnInputBackspace) {
        return state;
      }

      if (isSelectionLocked != null) {
        // Whenever a change happens and we have locked items, we double-check to make sure no locked items were accidentally removed
        const changedItemsSet = new Set(changes.selectedItems);
        const invalidRemovedItems = state.selectedItems.filter(
          oldItem => !changedItemsSet.has(oldItem) && isSelectionLocked(oldItem)
        );

        if (invalidRemovedItems.length > 0) {
          // if any of the removed items were locked the action was invalid and will be ignored / reverted
          return state;
        }
      }

      return changes;
    },
  });
  const autocomplete = useAutocomplete({
    items,
    getLabel: getLabel,
    getDescription: getDescription,
    stateReducer: (state, actionAndChanges) => {
      switch (actionAndChanges.type) {
        case useCombobox.stateChangeTypes.InputClick:
          return {
            ...actionAndChanges.changes,
            // For backward compatibility with earlier combobox behavior,
            // keep the highlighted index and open state when the input is clicked
            highlightedIndex: state.highlightedIndex,
            isOpen: state.isOpen,
          };
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick: {
          const item = actionAndChanges.changes.selectedItem;
          if (!isNil(item)) {
            if (multipleSelection.selectedItems.includes(item)) {
              multipleSelection.removeSelectedItem(item);
            } else {
              multipleSelection.addSelectedItem(item);
            }
          }

          if (highlightInputTextAfterSelection && inputRef?.current) {
            inputRef.current.select();
          }

          return {
            ...actionAndChanges.changes,
            selectedItem: undefined, // we are a multiselect, we don't have a "selectedItem", always undefine it
            highlightedIndex: state.highlightedIndex, // keep the highlighted index (otherwise it resets to 0)
            inputValue: clearInputAfterSelection ? '' : state.inputValue,
            isOpen: true,
          };
        }
        case useCombobox.stateChangeTypes.MenuMouseLeave: {
          return {
            ...actionAndChanges.changes,
            highlightedIndex: state.highlightedIndex, // Do not scroll to top if mouse leaves input
          };
        }
        case useCombobox.stateChangeTypes.InputBlur:
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
          return { ...actionAndChanges.changes, isOpen: false, inputValue: '' };
        default:
          return actionAndChanges.changes;
      }
    },
    ...props,
  });

  return { autocompleteOutput: autocomplete, multipleSelectionOutput: multipleSelection };
};
