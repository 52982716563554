import { createContext, useContext } from 'react';
import type { Observable } from 'rxjs';
import type { OrderStrategy } from '../types/OrderStrategy';

export const StrategiesContext = createContext<StrategiesContextProps | undefined>(undefined);
StrategiesContext.displayName = 'StrategiesContext';

export function useStrategiesContext() {
  const context = useContext(StrategiesContext);
  if (context === undefined) {
    throw new Error('Missing StrategiesContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}
export interface StrategiesContextProps {
  isLoaded: boolean;
  /**
   * Observable list of strategies with StrategyScope = "OrderStrategy"
   */
  listStrategiesObs: Observable<OrderStrategy[]>;
  /**
   * List of strategies with StrategyScope = "OrderStrategy"
   */
  strategiesList: OrderStrategy[];
  /**
   * List of strategies with StrategyScope = "AutoHedgingStrategy"
   */
  autoHedgingStrategiesList: OrderStrategy[];
  /**
   * List of strategies with StrategyScope = "DDHSubStrategy"
   */
  ddhStrategiesList: OrderStrategy[];
  /**
   * All strategies by name in observable form
   */
  strategiesByNameObs: Observable<Map<string, OrderStrategy>>;
  /**
   * All strategies by name (includes sub strategies and strategies with StrategyScope = "OrderStrategy" etc.)
   */
  strategiesByName: Map<string, OrderStrategy>;
}
