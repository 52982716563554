import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDynamicCallback } from '../hooks';
import type { DataResponse } from '../types';
import { GET, request } from '../utils';

export type WLUser = {
  CustomerUserID: string;
  DisplayName: string;
  Email: string;
  ExternalID: string;
};

export type WLUsersContextState = {
  isLoaded: boolean;
  users: WLUser[];
  userByEmail: Map<string, WLUser>;
  userByID: Map<string, WLUser>;
  refetchUsers: (includeDeleted?: boolean) => Promise<void>;
};
export const WLUsersContext = createContext<WLUsersContextState | undefined>(undefined);
WLUsersContext.displayName = 'WLUsersContext';

export function WLUsersContextProvider({ children }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [users, setUsers] = useState<WLUser[]>([]);

  const fetchUsers = useDynamicCallback(async () => {
    request<DataResponse<WLUser>>(GET, `v1/users`)
      .then(res => {
        setUsers(res.data);
        setIsLoaded(true);
      })
      .catch(() => {
        setIsLoaded(true);
        setUsers([]);
      });
  });

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const userByEmail = useMemo(() => new Map<string, WLUser>((users || []).map(user => [user.Email, user])), [users]);
  const userByID = useMemo(
    () => new Map<string, WLUser>((users || []).map(user => [user.CustomerUserID ?? '', user])),
    [users]
  );
  const refetchUsers = fetchUsers;

  const value: WLUsersContextState = useMemo(
    () => ({
      isLoaded,
      users,
      userByEmail,
      userByID,
      refetchUsers,
    }),
    [isLoaded, users, userByEmail, userByID, refetchUsers]
  );

  return <WLUsersContext.Provider value={value as any}>{children} </WLUsersContext.Provider>;
}

export const useWLUsersContext = (): WLUsersContextState => {
  const context = useContext(WLUsersContext);
  if (context == null) {
    throw new Error('Missing WLUsersContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};
