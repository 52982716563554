import { useContext, useRef, type MouseEvent, type PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Box, HStack, VStack, type BoxProps } from '../Core';
import { Icon, IconName } from '../Icons';
import { usePowerSearchContext } from './PowerSearchContext';
import { PowerSearchHighlightContext } from './PowerSearchHighlightContext';

export function PowerSearchItem({
  children,
  id,
  onSelect,
  ...props
}: PropsWithChildren<BoxProps & { id: string; onSelect?(id): void }>) {
  const highlightedID = useContext(PowerSearchHighlightContext);
  const isHighlighted = id === highlightedID;
  const {
    modal: { close },
  } = usePowerSearchContext();
  const ref = useRef<HTMLDivElement>(null);

  const handleClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    close();
    onSelect?.(id);
  };

  return (
    <Wrapper
      id={id}
      className="power-search-item"
      onClick={handleClick}
      isHighlighted={isHighlighted}
      ref={ref}
      {...props}
    >
      <HStack justifyContent="space-between">
        <VStack w="100%" alignItems="flex-start">
          {children}
        </VStack>
        {isHighlighted && <Icon icon={IconName.ChevronRight} />}
      </HStack>
    </Wrapper>
  );
}

const Wrapper = styled(Box).attrs({
  as: 'button',
})<{ isHighlighted: boolean }>`
  background: var(--colors-gray-020);
  appearance: none;
  border: 0;
  display: block;
  text-align: initial;
  background: var(--colors-gray-020);
  color: var(--colorTextDefault);
  outline: none;

  ${({ isHighlighted }) => isHighlighted && `background: var(--colors-gray-030)`};
  &:hover {
    background: var(--colors-gray-030);
  }
  &:hover {
    cursor: pointer;
  }
`;
Wrapper.defaultProps = {
  p: 'spacingDefault',
};
