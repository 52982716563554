import type { ExpectTrue } from '../tests';
import type { OptionalProperties } from '../utils';
import type { IIndex, UpdateActionEnum } from './types';

export class FixingIndex {
  Timestamp: string;
  Revision: number;
  UpdateUser?: string;
  Name: string;
  DisplayName: string;
  DefaultSpreadIncrement?: string;
  MinSpreadIncrement?: string;
  SpreadDisplaySpec: string;
  ValidNominalIndexTime: number;
  NominalIndexTimeZone: string;
  Description: string;
  IndexID: string;
  UpdateAction: UpdateActionEnum;

  constructor(fixingIndex: OptionalProperties<IIndex, 'UpdateUser'>) {
    this.Timestamp = fixingIndex.Timestamp;
    this.Revision = fixingIndex.Revision;
    this.UpdateUser = fixingIndex.UpdateUser;
    this.Name = fixingIndex.Name;
    this.DisplayName = fixingIndex.DisplayName;
    this.DefaultSpreadIncrement = fixingIndex.DefaultSpreadIncrement;
    this.MinSpreadIncrement = fixingIndex.MinSpreadIncrement;
    this.SpreadDisplaySpec = fixingIndex.SpreadDisplaySpec;
    this.ValidNominalIndexTime = fixingIndex.ValidNominalIndexTime;
    this.NominalIndexTimeZone = fixingIndex.NominalIndexTimeZone;
    this.Description = fixingIndex.Description;
    this.IndexID = fixingIndex.IndexID;
    this.UpdateAction = fixingIndex.UpdateAction;
  }
}

// TYPE LEVEL TESTS
type _Expect_FixingIndex_To_Only_Have_Keys_From_IIndex = ExpectTrue<
  {
    [K in keyof FixingIndex & string]: K extends keyof IIndex ? true : K;
  }[Exclude<keyof FixingIndex, never>]
>;
type _Expect_All_Keys_In_IIndex_To_Be_In_FixingIndex = ExpectTrue<
  {
    [K in keyof IIndex & string]: K extends keyof FixingIndex ? true : K;
  }[Exclude<keyof IIndex, never>]
>;
