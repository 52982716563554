import styled from 'styled-components';
import { Flex, type FlexProps } from './Flex';

/** Simple flex div with 'flex-direction: column'
 *
 * Set flex properties without creating a new component.
 *
 * Default Props:
 * - justifyContent: 'center'
 * - aligntItems: 'center'
 */
export const VStack = styled(Flex)<FlexProps>`
  flex-direction: column;
`;

VStack.defaultProps = {
  justifyContent: 'center',
  alignItems: 'center',
};
