import { hsl } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';

import { setAlpha } from '../utils';
import { darkColors, mapDarkTheme } from './DarkTheme';

export const colors: Colors = {
  black: {
    default: '#000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    main: hsl(220, 0, 0.11),

    '000': hsl(220, 0, 0.03),
    '010': hsl(220, 0, 0.08),
    '020': hsl(220, 0, 0.15),
    '030': hsl(220, 0, 0.17),
    '040': hsl(220, 0, 0.2),
    '050': hsl(220, 0, 0.23),
    '060': hsl(220, 0, 0.27),
    '070': hsl(220, 0, 0.34),
    '080': hsl(220, 0, 0.51),
    '090': hsl(220, 0, 0.85),
    '100': hsl(220, 0, 0.9),
  },

  primary: {
    mute: setAlpha(0.1, '#4f51b0'),
    dim: setAlpha(0.4, '#4f51b0'),
    default: '#4f51b0',
    lighten: '#6b6dc9',
  },

  green: {
    mute: setAlpha(0.1, '#228a6e'),
    dim: setAlpha(0.4, '#228a6e'),
    default: '#228a6e',
    lighten: '#259c7c',
  },

  red: {
    mute: setAlpha(0.1, '#b52b65'),
    dim: setAlpha(0.4, '#b52b65'),
    default: '#b52b65',
    lighten: '#db538c',
  },

  yellow: {
    mute: 'rgba(204, 172, 62, 0.1)',
    dim: 'rgba(204, 172, 62, 0.4)',
    default: '#CCAC3E',
    lighten: '#F2D264',
  },

  blue: {
    mute: setAlpha(0.1, '#1878d9'),
    dim: setAlpha(0.4, '#1878d9'),
    default: '#1878d9',
    lighten: '#42a0ff',
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: darkColors.purple,
};

export const DarkConservativeTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkConservative',

  fontUrl: 'https://fonts.googleapis.com/css2?family=News+Cycle&display=swap',
  fontFamily: "'News Cycle', sans-serif",

  backgroundHeader: colors.gray.main,
  borderColorHeader: colors.gray['020'],

  spacingLayout: 1,

  borderColorContent: colors.gray['020'],
  borderColorDivider: colors.gray['020'],

  backgroundDrawer: '#2b2c66',
  backgroundSidebar: '#2b2c66',
  backgroundSidebarItemActive: '#3d3e8a',
  backgroundSidebarItemHover: '#444596',
  borderColorSidebar: '#35367a',

  borderRadiusDefault: 2,
  borderRadiusSmall: 2,
  borderRadiusTiny: 2,

  borderRadiusButtonDefault: 2,
  borderRadiusButtonSmall: 2,
  borderRadiusButtonTiny: 2,

  borderColorInput: `transparent transparent ${colors.gray['060']} transparent`,
  borderColorInputHover: `transparent transparent ${colors.gray['080']} transparent`,
  borderColorInputFocus: `transparent transparent ${colors.gray['070']} transparent`,
  borderColorInputInvalid: `transparent transparent ${colors.red.default} transparent`,
  borderColorInputInvalidFocus: `transparent transparent ${colors.red.lighten} transparent`,

  borderColorDefaultButton: colors.gray['060'],
  borderColorDefaultButtonHover: colors.gray['060'],
  borderColorDefaultButtonFocus: colors.gray['060'],
  borderWidthButton: 1,

  backgroundDefaultButton: colors.gray.main,
  backgroundDefaultButtonHover: colors.gray['020'],

  backgroundInput: colors.gray.main,
  backgroundInputInvalid: colors.red.mute,

  backgroundSelect: colors.gray.main,
  backgroundSelectHover: colors.gray.main,
  backgroundSelectFocus: colors.gray.main,

  borderColorSelect: `transparent transparent ${colors.gray['060']} transparent`,
  borderColorSelectHover: `transparent transparent ${colors.gray['080']} transparent`,
  borderColorSelectFocus: `transparent transparent ${colors.gray['070']} transparent`,
  borderColorSelectInvalid: `transparent transparent ${colors.red.default} transparent`,

  borderColorPositiveButton: colors.green.lighten,

  borderColorNegativeButton: colors.red.lighten,

  borderColorPrimaryButton: colors.primary.lighten,

  borderColorBlotterTable: colors.gray['020'],
});
