import styled from 'styled-components';
import { Button } from '../Button';
import { HStack } from '../Core';
import { Icon } from '../Icons';

export const StandardSecurityButton = styled(Button)`
  padding: ${({ theme }) => theme.spacingSmall}px;
  height: auto;
  background: transparent;
  border-color: transparent;

  &:hover {
    background: transparent;
    border-color: transparent;
  }
`;

export const GroupsPrefixWrapper = styled(HStack)`
  padding-left: ${({ theme }) => theme.spacingSmall}px;
  border-bottom: ${({ theme }) => `1px solid ${theme.borderColorTab}`};
`;

export const FavoritesButton = styled(Button)`
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  padding: ${({ theme }) => theme.spacingSmall}px;

  ${Icon} {
    font-size: ${({ theme }) => theme.fontSizeTiny}em;
  }

  ${Icon}:first-child {
    font-size: ${({ theme }) => theme.fontSizeTiny}rem;
    color: ${({ theme }) => theme.colors.yellow.lighten};
    margin-top: -2px;
  }
`;
