import { createContext, useContext, useMemo } from 'react';
import { useDynamicCallback } from '../../hooks';
import type { TabProps } from './types';

export const DefaultTabsStateConfig = {
  tabs: {} as { [k: string]: TabsState },
};
const DefaultTabsStorageMethods: TabsStorageContextProps = {
  getState() {
    return null;
  },
  setSelectedIndex() {
    return;
  },
  setItems() {
    return;
  },
};
const DefaultTabsStorageContext = { /* ...DefaultTabsStateConfig, */ ...DefaultTabsStorageMethods };
export const TabsStorage = createContext<TabsStorageContextProps>(DefaultTabsStorageContext);
TabsStorage.displayName = 'TabsStorage';

/**
 * Returns a set of TabsStorage functions where all references are stable and will not cause re-renders
 * despite the underlying tab item data maybe changing
 */
export function useStaticTabsStorage() {
  const { getState, setSelectedIndex, setItems } = useContext(TabsStorage);

  const staticGetState = useDynamicCallback(getState);

  return useMemo(
    () => ({
      getState: staticGetState,
      setSelectedIndex,
      setItems,
    }),
    [staticGetState, setSelectedIndex, setItems]
  );
}

/**
 * Returns the same functions as useStaticTabsStorage, but allows the `getState` function
 * to be reactive, meaning that this hook will cause your component to re-render when the underlying persisted tab data changes
 */
export function useDynamicTabsStorage() {
  return useContext(TabsStorage);
}

export type TabsState<TTabProps = TabProps> = {
  selectedIndex: number;
  items: TTabProps[];
};

export interface TabsStorageContextProps<TTabProps = TabProps> {
  getState(tabsID: string): TabsState<TTabProps> | null;
  setSelectedIndex(tabsID: string, index: number): void;
  setItems(tabsID: string, items: TTabProps[]): void;
}
