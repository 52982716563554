import { useTheme } from 'styled-components';
import { getMarketColor } from '../../utils';
import { MarketColorDotWrapper } from './styles';
import { MarketColorDotVariant, type MarketColorDotProps } from './types';

export function MarketColorDot({ market, variant = MarketColorDotVariant.Default, borderColor }: MarketColorDotProps) {
  const theme = useTheme();
  const color = getMarketColor(market, theme);

  return <MarketColorDotWrapper color={color} borderColor={borderColor || theme.backgroundContent} variant={variant} />;
}
