import styled from 'styled-components';

export const Table = styled.table`
  border: none;
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    color: ${({ theme }) => theme.colorTextImportant};
    padding: ${({ theme }) => `${theme.spacingDefault}px`};
    white-space: nowrap;
    line-height: 1;
    font-size: ${({ theme }) => theme.fontSizeBlotterTableDefault}rem;
  }
`;

export const TableHead = styled.thead`
  th {
    font-weight: ${({ theme }) => theme.fontWeightRegular};
    text-align: left;
    color: ${({ theme }) => theme.colorTextDefault};
    vertical-align: bottom;
    text-transform: uppercase;
    border-bottom: solid 1px ${({ theme }) => theme.borderColorDivider};
    font-size: ${({ theme }) => theme.fontSizeBlotterTableHeader}rem;
  }
`;

export const TableRow = styled.tr<{ isSelected: boolean; isDisabled: boolean; rowHeight?: number }>`
  ${({ rowHeight }) => rowHeight && `height: ${rowHeight}px`};
  ${({ isSelected, theme }) => isSelected && `background: ${theme.backgroundBlotterTableRowSelected}`};

  &:hover td {
    background: ${({ theme, isDisabled }) => !isDisabled && theme.backgroundBlotterTableRowSelected};
  }

  td {
    ${({ isSelected, isDisabled, theme }) =>
      isSelected && !isDisabled && `background: ${theme.backgroundBlotterTableRowSelected}`};
    ${({ isDisabled, theme }) => isDisabled && `color: ${theme.colorTextSubtle}`};

    &:first-child {
      padding-left: ${({ theme }) => theme.spacingDefault}px;
    }

    > div {
      text-overflow: ellipsis;
    }
  }

  border-bottom: solid 1px ${({ theme }) => theme.borderColorDivider};
`;

export const CurrencyLabel = styled.span`
  line-height: 1;
  vertical-align: middle;
  margin-left: ${({ theme }) => theme.spacingSmall}px;
`;
