import styled from 'styled-components';
import { buttonStyle } from '../../Button/styles';
import { Box, HStack } from '../../Core';
import { INPUT_BORDER_WIDTH, formControlCSS } from '../styles';
import type { FormControlProps } from '../types';
import { FormControlSizes } from '../types';

export const InputWrapper = styled.label<FormControlProps<HTMLInputElement>>`
  ${formControlCSS}

  input:disabled {
    cursor: not-allowed;
  }

  input[type='file']::file-selector-button {
    ${({ theme, size = FormControlSizes.Default }) => buttonStyle(theme, size - 0.5)}
    line-height: 1;
    vertical-align: 2px;
  }

  // Disable Default Increment and Decrement Buttons from Input Type Number
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const Prefix = styled(Box)<{ isText?: boolean; size: FormControlSizes }>`
  order: -1;
  display: flex;
  ${({ isText, size, theme }) =>
    // Adjust to align buttons and text correctly, icons will need extra margin
    !isText && `margin-left: -${(theme.spacingDefault * size) / 2 - theme.spacingSmall + INPUT_BORDER_WIDTH}px`}
`;
Prefix.defaultProps = {
  color: 'colorTextSubtle',
};

export const Suffix = styled(HStack)<{ isText?: boolean; size: FormControlSizes }>`
  order: 1;
  ${({ isText, size, theme }) =>
    // Adjust to align buttons and text correctly, icons will need extra margin
    !isText && `margin-right: -${(theme.spacingDefault * size) / 2 - theme.spacingSmall + INPUT_BORDER_WIDTH}px`}
`;
