import { identity } from 'lodash';
import { filter, map, pipe, scan } from 'rxjs';
import type { MinimalSubscriptionResponse } from '../types/SubscriptionResponse';
import { UpdateActionEnum } from '../types/types';
import { compareTimestampsWithMicrosecondPrecision } from '../utils/microseconds';
export interface WSScanToMapParams<T, KeyType extends string | number, P> {
  /** The unique key to use for storing items. The type of the returned key must be `(string | number)` */
  getUniqueKey: (item: T) => KeyType;
  /** An optional function going from `T => P` where you determine what you would like stored as values in the map.
   * @example
   * // basic case: just store T. This will emit a map of type `Map<KeyType, T>`
   * getInsertable: item => item
   * // store a specific property of T, eg DisplayName. This will emit a map of type `Map<KeyType, string>`
   * getInsertable: item => item.DisplayName
   * @default lodash.identity
   */
  getInsertable?: (item: T) => P;
  /** Whether or not to create a new map on each update. Necessary in order to trigger change detection if this operator is
   * the last transformation operator before bringing the variable into React-land. For example, if this operator is last in a
   * `useObservableValue` usage, you should most likely set this to `true`. If it's in the middle of a longer array of pipe transformations, set it as `false`.
   * This option does not default to false as it is important that the developer is aware of the implications of this option.
   * This option also make us treat all the maps as immutable
   */
  newMapEachUpdate: boolean;
  /**
   * A callback which allows you to decide whether or not the given item should be deleted. Will be invoked for every entry in every update.
   * This callback takes full precendence -- if provided, you handle all deletion of entries and no other deletion will take place.
   * As in: if provided, the pipe won't check UpdateAction.Remove for you.
   */
  shouldDelete?: (item: T) => boolean;
  /**
   * If set to true, will only allow updates if the `Revision` property is greater on the updated entry compared to the existing entry in the map.
   * Assumes that there is a `Revision` property on the incoming type T and the inserted type P.
   */
  ensureRevisionOrdering?: boolean;
  /**
   * If set to true, will only allow updates if the `Timestamp` property is later on the updated entry compared to the existing entry in the map.
   * Assumes that there is a `Timestamp` property on the incoming type T and the inserted type P.
   */
  ensureTimestampOrdering?: boolean;
  /**
   * If true, will wait for all pages to load before emitting the map. If false, will emit the map as soon as the first page is loaded.
   */
  waitForAllPagesToLoad?: boolean;
}

/**
 * A pipe which scans received updates to a map.
 * Handles remove update actions
 * Uses the getUniqueKey function you pass in to get the key used for storing in the map
 * @returns a map with unique entries
 */
export function wsScanToMap<T, KeyType extends string | number, P = T>({
  getUniqueKey,
  getInsertable = identity,
  newMapEachUpdate,
  shouldDelete,
  ensureRevisionOrdering,
  ensureTimestampOrdering,
  waitForAllPagesToLoad = false,
}: WSScanToMapParams<T, KeyType, P>) {
  return pipe(
    scan(
      ({ map: acc }, json: MinimalSubscriptionResponse<T>) => {
        const map = newMapEachUpdate ? new Map(acc) : acc;

        if (json.initial) {
          map.clear();
        }

        // Safeguard here, return early.
        if (json.data == null) {
          return { map, isLoading: !!json.next };
        }

        for (const update of json.data) {
          const key = getUniqueKey(update);
          if (key === undefined) {
            continue;
          }

          const existingEntry = map.get(key);
          if (existingEntry) {
            if (ensureRevisionOrdering) {
              const existingRevision = existingEntry['Revision'];
              const updateRevision = update['Revision'];
              if (existingRevision != null && updateRevision != null && updateRevision < existingRevision) {
                continue;
              }
            }

            if (ensureTimestampOrdering) {
              const existingTimestamp = existingEntry['Timestamp'];
              const updateTimestamp = update['Timestamp'];
              if (
                existingTimestamp != null &&
                updateTimestamp != null &&
                // -1 indicates that updateTimestamp is earlier than time than existingTimestamp
                compareTimestampsWithMicrosecondPrecision(updateTimestamp, existingTimestamp) === -1
              ) {
                continue;
              }
            }
          }

          const maybeUpdateAction: UpdateActionEnum | undefined = update['UpdateAction'] ?? json.action;
          // If specified, the shouldDelete function has full control over _all_ deletion
          const shouldDeleteUpdate = shouldDelete
            ? shouldDelete(update)
            : maybeUpdateAction === UpdateActionEnum.Remove;
          if (shouldDeleteUpdate) {
            map.delete(key);
          } else {
            map.set(key, getInsertable(update));
          }
        }

        return { map, isLoading: !!json.next };
      },
      { map: new Map<KeyType, P>(), isLoading: false }
    ),
    filter(({ isLoading }) => !isLoading || !waitForAllPagesToLoad),
    map(({ map }) => map)
  );
}
