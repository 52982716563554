import { useEffect, useMemo, useState } from 'react';
import { useSecuritiesContext } from '../../../contexts';
import { InstrumentCompositionEnum } from '../../../types';
import type { WatchListRowData } from '../types';

function areSetsEqual<T>(left: Set<T>, right: Set<T>): boolean {
  return left.size === right.size && [...left].every(value => right.has(value));
}

export const useSecuritiesList = function useSecuritiesList({
  favoriteSecurities: favoriteSecuritiesList,
}: {
  favoriteSecurities: string[];
}): WatchListRowData[] {
  const { searchableSecurities } = useSecuritiesContext();
  const [favoriteSecurities, setFavoriteSecurities] = useState(() => new Set(favoriteSecuritiesList));

  const [data, setData] = useState<Map<string, WatchListRowData>>(new Map());

  useEffect(() => {
    setFavoriteSecurities(prevData => {
      const newData = new Set(favoriteSecuritiesList);

      if (!areSetsEqual(prevData, newData)) {
        return newData;
      }
      return prevData;
    });
  }, [favoriteSecuritiesList]);
  useEffect(() => {
    setData(
      prevData =>
        new Map(
          searchableSecurities
            .filter(s => s.Composition !== InstrumentCompositionEnum.Synthetic)
            .map(s => [
              s.Symbol,
              {
                ...((prevData.get(s.Symbol) ?? {}) as WatchListRowData),
                security: s,
                favorite: favoriteSecurities.has(s.Symbol),
              },
            ])
        )
    );
  }, [favoriteSecurities, searchableSecurities]);
  return useMemo(() => [...data.values()], [data]);
};
