import type { Security } from '../types/Security';
import { ProductTypeEnum, SyntheticProductTypeEnum } from '../types/types';
import { isOptionStrategy } from './security';

export const shouldDisplayReasonabilityCheckForProduct = (security: Security | undefined) => {
  if (!security) {
    return false;
  }
  if ([ProductTypeEnum.Basis, ProductTypeEnum.Option, ProductTypeEnum.CalendarSpread].includes(security.ProductType)) {
    return false;
  }
  if (isOptionStrategy(security)) {
    return false;
  }
  if (
    hasSyntheticProductType(security) &&
    security!.MultilegDetails!.SyntheticProductType === SyntheticProductTypeEnum.Delta1Spread
  ) {
    return false;
  }
  return true;
};

export const hasSyntheticProductType = (security: Security | undefined) => {
  if (!security) {
    return false;
  }
  if (security?.MultilegDetails && security?.MultilegDetails?.SyntheticProductType) {
    return true;
  }
  return false;
};

export const PRODUCT_TYPE_TOOLTIP_MAP = {
  [SyntheticProductTypeEnum.Unknown]: 'unknown',
  [SyntheticProductTypeEnum.Cross]: 'Synthetic Cross',
  [SyntheticProductTypeEnum.Delta1Spread]: 'Delta-1 Spread',
  [SyntheticProductTypeEnum.CallCalendarSpread]: 'Call Calendar',
  [SyntheticProductTypeEnum.PutCalendarSpread]: 'Put Calendar',
  [SyntheticProductTypeEnum.CallSpread]: 'Call Spread',
  [SyntheticProductTypeEnum.PutSpread]: 'Put Spread',
};
