import { forwardRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { Checkmark } from '../Checkmark';
import { FormControlSizes } from '../Form/types';
import { Icon } from '../Icons';
import { Spinner, SpinnerContainer } from '../Spinner';
import { ButtonVariants, ButtonWrapper, InternalButtonSpan, getIconSize, type ButtonProps } from './styles';

export const Button = styled(
  forwardRef<HTMLButtonElement, ButtonProps & { as?: Parameters<typeof ButtonWrapper>[0]['as'] }>(
    (
      {
        children,
        as,
        startIcon,
        endIcon,
        size = FormControlSizes.Default,
        loading,
        done,
        variant = ButtonVariants.Default,
        ...props
      },
      ref
    ) => {
      const { baseSize } = useTheme();
      return (
        <ButtonWrapper
          {...props}
          variant={variant}
          size={size}
          as={as}
          ref={ref}
          disabled={loading ? true : props.disabled}
        >
          {loading ? (
            <SpinnerContainer>
              <Spinner size={(size / 2) * baseSize} />
            </SpinnerContainer>
          ) : done ? (
            <SpinnerContainer>
              <Checkmark size={(size / 2) * baseSize} />
            </SpinnerContainer>
          ) : null}
          {!!startIcon && <Icon style={{ opacity: loading ? 0 : 1 }} icon={startIcon} size={getIconSize(size)} />}
          {children && <InternalButtonSpan style={{ opacity: loading ? 0 : 1 }}>{children}</InternalButtonSpan>}
          {!!endIcon && <Icon icon={endIcon} style={{ opacity: loading ? 0 : 1 }} size={getIconSize(size)} />}
        </ButtonWrapper>
      );
    }
  )
)``;
