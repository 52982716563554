import type { ICellRendererParams } from 'ag-grid-enterprise';
import { ProcessStepSizes, ProcessStepState, ProcessSteps, type ProcessStep } from '../ProcessSteps';

export type AgGridProcessStepProps = {
  steps: ProcessStep[];
};

export function AgGridProcessStep({ value, steps }: ICellRendererParams & AgGridProcessStepProps) {
  const activeIndex = steps.findIndex(step => step.statuses?.includes(value));
  const currentSteps = steps.map((step, index) => {
    return {
      ...step,
      state:
        index === activeIndex
          ? ProcessStepState.Active
          : index < activeIndex
          ? ProcessStepState.Done
          : ProcessStepState.Open,
    };
  });
  return <ProcessSteps steps={currentSteps} size={ProcessStepSizes.icon} showLabels={false} />;
}
