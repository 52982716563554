import { keys, pick } from 'lodash';
import { useMemo, useRef, type HTMLAttributes, type MouseEventHandler, type MutableRefObject } from 'react';
import { useDynamicCallback } from './useDynamicCallback';

type MouseHoverEvents = 'onMouseEnter' | 'onMouseLeave';
type MouseHoverConfig = {
  [key in MouseHoverEvents]?: number;
};
type useMouseEventHandlers = Pick<HTMLAttributes<HTMLElement>, MouseHoverEvents>;
interface useMouseEventsProps extends useMouseEventHandlers {
  delay?: MouseHoverConfig;
}

const DEFAULT_DELAY: Required<MouseHoverConfig> = {
  onMouseEnter: 0,
  onMouseLeave: 0,
};

const clearRefAndTimeout = (ref: MutableRefObject<NodeJS.Timeout | undefined>) => {
  if (ref.current) {
    clearTimeout(ref.current);
    ref.current = undefined;
  }
};
/**
 * Utility hook for handling onMouseEnter and onMouseLeave events with optional delays
 *
 * Usage:
 * ```tsx
 * const Example = () => {
 *  const onMouseEnter = () => {}
 *  const onMouseLeave = () => {}
 *  const hoverProps = useMouseHover({ onMouseEnter, onMouseLeave, delay: { onMouseEnter: 100 } });
 *
 *  return <Box {...hoverProps}/>;
 * }
 * ```
 */
export const useMouseHover = (props: useMouseEventsProps) => {
  const { delay: _delay = DEFAULT_DELAY, onMouseEnter, onMouseLeave } = props;
  const delay = useMemo(() => ({ ...DEFAULT_DELAY, ..._delay }), [_delay]);
  const onMouseEnterTimeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const onMouseLeaveTimeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);

  const handleOnMouseEnter: MouseEventHandler<HTMLElement> = useDynamicCallback(e => {
    clearRefAndTimeout(onMouseLeaveTimeoutRef);
    if (delay.onMouseEnter) {
      onMouseEnterTimeoutRef.current = setTimeout(() => {
        onMouseEnter?.(e);
      }, delay.onMouseEnter);
    } else {
      onMouseEnter?.(e);
    }
  });
  const handleOnMouseLeave: MouseEventHandler<HTMLElement> = useDynamicCallback(e => {
    clearRefAndTimeout(onMouseEnterTimeoutRef);
    if (delay.onMouseLeave) {
      onMouseLeaveTimeoutRef.current = setTimeout(() => {
        onMouseLeave?.(e);
      }, delay.onMouseLeave);
    } else {
      onMouseLeave?.(e);
    }
  });
  const definedHandlers = useMemo(() => keys(props), [props]);
  const returnProps: Pick<useMouseEventsProps, MouseHoverEvents> = useMemo(
    () => pick({ onMouseEnter: handleOnMouseEnter, onMouseLeave: handleOnMouseLeave }, definedHandlers),
    [definedHandlers, handleOnMouseEnter, handleOnMouseLeave]
  );

  return returnProps;
};
