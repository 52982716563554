import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { lightColors, mapLightTheme } from './LightTheme';

const colors: Colors = {
  ...lightColors,

  gray: {
    main: '#F5F2F0',

    // https://hihayk.github.io/scale/#8/2/90/95/0/139/20/0/F5F2F0/245/242/240/white
    '100': '#191818',
    '090': '#343333',
    '080': '#504F4E',
    '070': '#6B6A69',
    '060': '#878584',
    '050': '#A2A09F',
    '040': '#BEBCBA',
    '030': '#D9D7D5',
    '020': '#F5F2F0',
    '010': '#FAF7F9',
    '000': '#FEFEFF',
  },

  yellow: {
    default: '#F87700',
    lighten: '#ff9e45',
    mute: setAlpha(0.1, '#F87700'),
    dim: setAlpha(0.4, '#F87700'),
  },
  primary: {
    default: '#DCA950',
    lighten: '#e6bb70',
    mute: setAlpha(0.1, '#DCA950'),
    dim: setAlpha(0.4, '#DCA950'),
  },
};

export const LightOneIOTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightOIO',

  backgroundSidebar: colors.gray.main,
});
