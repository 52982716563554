import styled from 'styled-components';
import { Box } from '../Core';

export const Dl = styled(Box).attrs({
  as: 'dl',
})``;
Dl.defaultProps = {
  m: 0,
  display: 'flex',
  flexDirection: 'column',
};

export const Dt = styled(Box).attrs({
  as: 'dt',
})`
  color: ${({ theme }) => theme.colorTextSubtle};
`;
Dt.defaultProps = {};

export const Dd = styled(Box).attrs({
  as: 'dd',
})``;
Dd.defaultProps = {
  m: 0,
  mt: 'spacingDefault',
};
