import { animated } from '@react-spring/web';
import styled, { css } from 'styled-components';
import { Z_INDEX, parseDimension } from '../../styles';
import { Flex } from '../Core';

export const Overlay = styled(animated.div).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['isTransparent'].includes(prop) && defaultValidatorFn(prop),
})<{ isTransparent?: boolean; isCentered: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  ${({ isCentered }) =>
    isCentered &&
    css`
      align-items: center;
    `}
  z-index: ${Z_INDEX.modal};
  background: ${({ theme, isTransparent }) => (isTransparent ? 'transparent' : theme.backgroundOverlay)};

  .focus-lock {
    overflow: hidden;
  }
`;

export const DEFAULT_MODAL_MARGIN = 'spacingMedium';
export const Wrapper = styled(animated(Flex)).withConfig({
  shouldForwardProp: prop => !(typeof prop === 'string' && ['isControlled', 'open', 'toggle'].includes(prop)),
})`
  ${({ theme, width }) => width && `width: ${parseDimension(theme, width)}`};
  ${({ theme, maxWidth }) =>
    `max-width: ${maxWidth ? parseDimension(theme, maxWidth) : `calc(100% - ${theme[DEFAULT_MODAL_MARGIN] * 2}px)`}`};
`;
Wrapper.defaultProps = {
  m: DEFAULT_MODAL_MARGIN,
  borderRadius: 'borderRadiusDefault',
  borderColor: 'borderColorModal',
  background: 'backgroundModal',
  flexDirection: 'column',
  boxShadow: '0 1px 15px hsla(0, 0%, 0%, 0.25)',
};
