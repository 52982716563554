export const Logo = ({ fill }) => (
  <>
    <path
      d="M113.524 170.438L117.5 190H154L144 128.5L191 65H150L134.394 87.0221L143.5 128L113.524 170.438Z"
      fill={fill}
    />
    <path opacity="0.8" d="M134.394 87.022L143.5 128L113.524 170.438L105 128.5L134.394 87.022Z" fill={fill} />
    <path
      opacity="0.7"
      d="M92.5 65L105 128L60.5 191H99L113.524 170.438L105 128.5L134.394 87.0221L129.5 65H92.5Z"
      fill={fill}
    />
  </>
);
