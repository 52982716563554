import { defineMessages } from 'react-intl';
import { useMixpanel } from '../../../../contexts';
import { useDynamicCallback } from '../../../../hooks';
import { MixpanelEvent } from '../../../../tokens';
import { Button, ButtonVariants } from '../../../Button';
import { FormControlSizes } from '../../../Form';
import { IconName } from '../../../Icons';
import { IndicatorBadgeVariants, IndicatorBadgeWrapper } from '../../../IndicatorBadge';
import { FormattedMessage } from '../../../Intl';
import { Text } from '../../../Text';
import type { FilterBuilderProps } from '../FilterBuilder';

const messages = defineMessages({
  filters: {
    defaultMessage: 'Filters',
    id: 'Filters.FilterBuilder.filters',
  },
});

export type FilterBuilderToggleButtonProps = Pick<FilterBuilderProps, 'filterClauses'> & {
  isOpen: boolean;
  onClick: () => void;
  size?: FormControlSizes;
};

export const FilterBuilderToggleButton = ({
  filterClauses,
  isOpen,
  onClick,
  size = FormControlSizes.Small,
}: FilterBuilderToggleButtonProps) => {
  const mixpanel = useMixpanel();

  const handleOnClick = useDynamicCallback(e => {
    onClick();
    if (!isOpen) {
      mixpanel.track(MixpanelEvent.ClickOpenFilterBuilderButton);
    }
  });

  return (
    <Button
      variant={isOpen ? ButtonVariants.Primary : ButtonVariants.Default}
      size={size}
      startIcon={IconName.Filter}
      onClick={handleOnClick}
      data-testid="filter-builder-toggle-button"
    >
      <IndicatorBadgeWrapper
        show={filterClauses.length > 0}
        variant={isOpen ? IndicatorBadgeVariants.Default : IndicatorBadgeVariants.Notification}
        content={filterClauses.length}
      >
        <Text weight="500">
          <FormattedMessage {...messages.filters} />
        </Text>
      </IndicatorBadgeWrapper>
    </Button>
  );
};
