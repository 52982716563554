import { createContext, useContext, useMemo } from 'react';
import { useTheme, type DefaultTheme } from 'styled-components';
import type { MarketAccount } from '../../contexts/MarketAccountsContext';
import type { WLUser } from '../../providers/WLUsersContextProvider';
import type { Aggregation } from '../../types/Aggregation';
import type { Currency } from '../../types/Currency';
import type { Customer } from '../../types/Customer';
import type { CustomerMarketAccount } from '../../types/CustomerMarketAccount';
import type { Market } from '../../types/Market';
import type { StrategyLike } from '../../types/OrderStrategy';
import type { Participant } from '../../types/Participant';
import type { Security } from '../../types/Security';
import type { SubAccount } from '../../types/SubAccount';
import type { User } from '../../types/User';
import type { WLOrgConfig } from '../../types/WLOrgConfig';
import type { LoanSides } from '../../types/loanTypes';
import type { IExposureDefinition, ISubaccount, MarketTypeEnum } from '../../types/types';
import type { ColDefIntl } from './columns/types';

export interface BlotterTableContextProps {
  intl: ColDefIntl;
  marketDisplayNameByName?: Map<string, string>;
  marketTypeByName?: Map<string, MarketTypeEnum>;
  marketAccountsByName?: Map<string, MarketAccount>;
  marketAccountsByID?: Map<number, MarketAccount>;
  customers?: Customer[];
  customersByName?: Map<string, Customer>;
  strategiesByName?: Map<string, StrategyLike>;
  currenciesBySymbol?: Map<string, Currency>;
  securitiesBySymbol?: Map<string, Security>;
  loanParticipantsByName?: Map<string, Participant>;
  exposureDefinitionsByName?: Map<string, IExposureDefinition>;
  subAccountsByName?: Map<string, SubAccount>;
  subAccountsByID?: Map<number, SubAccount>;
  allSubAccounts?: ISubaccount[];
  theme?: DefaultTheme;
  userByEmail?: Map<string, User | WLUser>;
  userByID?: Map<string, User | WLUser>;
  currentUser?: User | WLUser;
  aggregationsByName?: Map<string, Aggregation>;

  // These props below are actually marketplace only, but we need to move the column functions to marketplace first
  user?: unknown;
  side?: LoanSides;
  loanTransactionsByLoanId?: unknown;
  marketsByName?: Map<string, Market>;

  // These props below are whitelabel only.
  customerMarketAccountsBySourceAccountID?: Map<string, CustomerMarketAccount>;
  wlOrgConfig?: WLOrgConfig;
}

export const BlotterTableContext = createContext<BlotterTableContextProps | undefined>(undefined);
BlotterTableContext.displayName = 'BlotterTableContext';

export function useBlotterTableContext() {
  const theme = useTheme();
  const context = useContext(BlotterTableContext);

  return useMemo(
    () => ({
      theme,
      ...context,
    }),
    [theme, context]
  );
}
