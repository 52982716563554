import { hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';

import { setAlpha } from '../utils';
import { base } from './BaseTheme';
import { lightColors, mapLightTheme } from './LightTheme';

export const colors: Colors = {
  black: {
    default: '#000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    '000': hsla(220, 0.25, 0.88, 1),
    '010': hsla(220, 0.25, 0.93, 1),
    main: hsla(220, 0.25, 1.0, 1),
    '020': hsla(220, 0.25, 0.96, 1),
    '030': hsla(220, 0.25, 0.91, 1),
    '040': hsla(220, 0.25, 0.85, 1),
    '050': hsla(220, 0.25, 0.81, 1),
    '060': hsla(220, 0.25, 0.77, 1),
    '070': hsla(220, 0.25, 0.73, 1),
    '080': hsla(220, 0.25, 0.61, 1),
    '090': hsla(220, 0.25, 0.42, 1),
    '100': hsla(220, 0.25, 0.14, 1),
  },

  primary: {
    mute: setAlpha(0.1, '#80BEF8'),
    dim: setAlpha(0.4, '#80BEF8'),
    default: '#49A4FA',
    lighten: '#80BEF8',
  },

  green: {
    mute: setAlpha(0.1, '#337e41'),
    dim: setAlpha(0.4, '#337e41'),
    default: '#337e53',
    lighten: '#49ab73',
  },

  red: {
    mute: setAlpha(0.1, '#9e2525'),
    dim: setAlpha(0.1, '#9e2525'),
    default: '#9e2525',
    lighten: '#d22626',
  },

  yellow: {
    mute: 'rgba(204, 172, 62, 0.1)',
    dim: 'rgba(204, 172, 62, 0.4)',
    default: '#CCAC3E',
    lighten: '#F2D264',
  },

  blue: {
    mute: setAlpha(0.1, '#80BEF8'),
    dim: setAlpha(0.4, '#80BEF8'),
    default: '#49A4FA',
    lighten: '#80BEF8',
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: lightColors.purple,
};

const baseSize = base.baseSize;

export const LightTransparencyTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightTransparency',

  fontUrl: 'https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,500;1,400;1,500&display=swap',
  fontFamily: "'Nunito', sans-serif",

  spacingLayout: baseSize * 2,
  borderRadiusContent: baseSize,
  backgroundContent: setAlpha(0.5, colors.gray.main),
  backgroundHeader: setAlpha(0.5, colors.gray.main),
  backdropFilterContent: 'blur(5px)',
  borderColorContent: colors.gray.main,
  borderColorHeader: colors.gray.main,
  backdropFilterHeader: 'blur(5px)',

  borderRadiusMedium: baseSize * 0.5,
  borderRadiusDefault: baseSize * 0.5,
  borderRadiusSmall: baseSize * 0.25,
  borderRadiusTiny: baseSize * 0.5,

  borderRadiusButtonLarge: baseSize * 0.5,
  borderRadiusButtonDefault: baseSize * 0.5,
  borderRadiusButtonSmall: baseSize * 0.25,
  borderRadiusButtonTiny: baseSize * 0.5,

  backgroundBody: `url(/customers/TransparencyLight.svg) 100%`,
  boxShadowContent: `0 2px 15px ${setAlpha(0.5, colors.gray['000'])}`,
  backgroundContentHover: setAlpha(0.05, colors.white.default),

  backgroundBlotterTableRow: 'transparent',

  backgroundChart: 'rgba(0, 0, 0, 0)',

  backgroundVolumeLadderBidSpread: setAlpha(0.75, colors.blue.default),
  backgroundVolumeLadderOfferSpread: setAlpha(0.75, colors.blue.default),

  backgroundSidebar: setAlpha(0.5, colors.gray.main),
  backdropFilterSidebar: 'blur(10px)',

  backgroundDrawer: setAlpha(0.5, colors.gray.main),
  backdropFilterDrawer: 'blur(10px)',

  borderColorDivider: colors.gray.main,

  backgroundInput: colors.white.default,
  backgroundSelect: colors.white.default,

  borderColorInputFocus: colors.primary.lighten,

  backgroundDefaultButton: `linear-gradient(${setAlpha(0.6, colors.gray['050'])}, ${colors.gray['050']})`,
  backgroundDefaultButtonHover: `linear-gradient(${setAlpha(0.7, colors.gray['050'])}, ${colors.gray['050']})`,
  backgroundDefaultButtonFocus: `linear-gradient(${setAlpha(0.7, colors.gray['050'])}, ${colors.gray['050']})`,
  backgroundDefaultButtonActive: `linear-gradient(${setAlpha(0.5, colors.gray['050'])}, ${colors.gray['050']})`,

  borderColorDefaultButtonFocus: colors.gray['050'],

  backgroundPositiveButton: `linear-gradient(${setAlpha(0.8, colors.green.lighten)}, ${colors.green.lighten})`,
  backgroundPositiveButtonHover: `linear-gradient(${setAlpha(0.9, colors.green.lighten)}, ${colors.green.lighten})`,
  backgroundPositiveButtonFocus: `linear-gradient(${setAlpha(0.9, colors.green.lighten)}, ${colors.green.lighten})`,
  backgroundPositiveButtonActive: `linear-gradient(${setAlpha(0.7, colors.green.lighten)}, ${colors.green.lighten})`,

  borderColorPositiveButtonFocus: colors.green.lighten,

  backgroundNegativeButton: `linear-gradient(${setAlpha(0.8, colors.red.lighten)}, ${colors.red.lighten})`,
  backgroundNegativeButtonHover: `linear-gradient(${setAlpha(0.9, colors.red.lighten)}, ${colors.red.lighten})`,
  backgroundNegativeButtonFocus: `linear-gradient(${setAlpha(0.9, colors.red.lighten)}, ${colors.red.lighten})`,
  backgroundNegativeButtonActive: `linear-gradient(${setAlpha(0.7, colors.red.lighten)}, ${colors.red.lighten})`,

  borderColorNegativeButtonFocus: colors.red.lighten,

  backgroundPrimaryButton: `linear-gradient(${setAlpha(0.8, colors.blue.lighten)}, ${colors.blue.lighten})`,
  backgroundPrimaryButtonHover: `linear-gradient(${setAlpha(0.9, colors.blue.lighten)}, ${colors.blue.lighten})`,
  backgroundPrimaryButtonFocus: `linear-gradient(${setAlpha(0.9, colors.blue.lighten)}, ${colors.blue.lighten})`,
  backgroundPrimaryButtonActive: `linear-gradient(${setAlpha(0.7, colors.blue.lighten)}, ${colors.blue.lighten})`,

  borderColorPrimaryButtonFocus: colors.blue.lighten,

  colorModeCurrencyIcon: 'colorful',
});
