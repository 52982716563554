import styled from 'styled-components';
import { modeColor } from '../../styles';
import { Box } from '../Core';
import { Icon } from '../Icons';
import { NotificationSizes } from './types';

export const NotificationWrapper = styled(Box)<{ size: NotificationSizes }>`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  min-width: min(20rem, 100%);
  color: ${({ theme }) => theme.colorTextImportant};

  ${({ theme, size }) => `
    gap: ${theme.spacingDefault}px;
    padding: ${size === NotificationSizes.Default ? theme.spacingMedium : theme.spacingDefault}px;
    background: ${modeColor(theme, theme.colors.gray.main, theme.colors.gray['040'])};

    font-size: ${theme.fontSizeDefault}rem;
  `}

  ${Icon} {
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => theme.colorTextDefault};
    }
  }
`;
