import { useState } from 'react';
import { useTheme } from 'styled-components';
import { v4 as uuid } from 'uuid';
import { ProcessStepSizes, ProcessStepState } from './types';

type StepIconProps = {
  state: ProcessStepState;
  size: ProcessStepSizes;
};

const ICON_SIZE_FACTOR = 2.66667;
export const StepIcon = ({ state, size }: StepIconProps) => {
  const [id] = useState(uuid());
  const { colorStepIconActive, colorStepIconOpen, baseSize } = useTheme();
  const iconSize = size * baseSize * ICON_SIZE_FACTOR;

  const css = `
    circle {
      transition: r 200ms ease;
    }
    path {
      transition: stroke-dashoffset 0ms ease;
    }
    path.active {
      transition: stroke-dashoffset 200ms 200ms ease;
    }
  `;

  const icon = () => (
    <>
      <style>{css}</style>
      <mask id={id}>
        <circle
          cx="16"
          cy="16"
          r={state === ProcessStepState.Active || state === ProcessStepState.Done ? 16 : 0}
          fill="white"
        />
        <circle cx="16" cy="16" r={state === ProcessStepState.Done ? 0 : 14} fill="black" />
        {size !== ProcessStepSizes.icon && (
          <path
            d="M11 16L15 20L22 12"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeDasharray="20 20"
            strokeDashoffset={state === ProcessStepState.Done ? 0 : 20}
            className={state === ProcessStepState.Done ? 'active' : undefined}
          />
        )}
        {size !== ProcessStepSizes.icon && (
          <circle cx="16" cy="16" r={state === ProcessStepState.Active ? 4 : 0} fill="white" />
        )}
      </mask>
      <circle cx="16" cy="16" r="15" fill="none" stroke={colorStepIconOpen} strokeWidth="2" />
      {size !== ProcessStepSizes.icon && (
        <circle cx="16" cy="16" r={state !== ProcessStepState.Done ? 4 : 0} fill={colorStepIconOpen} />
      )}
      <circle cx="16" cy="16" r="16" fill={colorStepIconActive} mask={`url(#${id})`} />
    </>
  );

  return (
    <svg width={iconSize} height={iconSize} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      {icon()}
    </svg>
  );
};
