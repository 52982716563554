import { useRef, type ReactNode } from 'react';
import { useOnKeyDown } from '../../hooks/useOnKeyPress';
import { JsonModalContent } from './styles';

export function JsonModal({ children, isVisible }: { children: ReactNode; isVisible: boolean }) {
  const textRef = useRef(null);
  useOnKeyDown({ key: 'a', metaKey: true }, e => {
    if (isVisible && textRef.current) {
      window.getSelection()?.selectAllChildren(textRef.current);
      e.preventDefault();
    }
  });

  return (
    <JsonModalContent>
      <pre ref={textRef}>{children}</pre>
    </JsonModalContent>
  );
}
