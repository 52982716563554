import type { CustomerOrder } from '../../../types/CustomerOrder';
import type { DateFnInput } from '../../../utils/date';
import { date } from './columns';
import type { ColDefFactory, Column } from './types';

export const orderStartTime: ColDefFactory<Column> = column => ({
  ...date(column),
  valueGetter: ({ data }: { data: CustomerOrder }): DateFnInput => {
    return data?.Parameters?.StartTime ?? data?.StartTime ?? null;
  },
});
