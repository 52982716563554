export enum ProcessStepSizes {
  icon = 0.1875,
  small = 0.75,
  default = 1,
  large = 1.125,
}

export type ProcessStep = {
  id?: string;
  statuses: string[];
  label: string;
  disabled?: boolean;
  state: ProcessStepState;
};

export enum ProcessStepState {
  Open,
  Active,
  Done,
}
