export const urlFromLocalOrAbsolute = (
  pathOrUrl,
  protocol = window.location.protocol,
  hostname = window.location.hostname
) => {
  const isAbsolute = pathOrUrl.startsWith('http') || pathOrUrl.startsWith('ws');
  if (isAbsolute) {
    return pathOrUrl;
  }
  return `${protocol}//${hostname}${pathOrUrl}`;
};
