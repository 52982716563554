import styled from 'styled-components';
import { IconButton } from '../Button';
import { InputSuffix, InputWrapper } from '../Form';

export const FilterInputWrapper = styled.div<{ isActive: boolean }>`
  ${InputWrapper} {
    transition: width 200ms ease;
    width: ${({ isActive }) => (isActive ? '8rem' : '1.5rem')};
    overflow: hidden;
    justify-content: flex-end;

    ${({ isActive }) =>
      isActive
        ? 'overflow: hidden;'
        : `
    overflow: visible;
    padding: 0;
    border-color: transparent;
    background: transparent;
  `}
    ${({ isActive }) =>
      !isActive &&
      `
    ${InputSuffix} > ${IconButton}:first-child {
      display: none;
    }
  `};
  }
`;
