import { createContext, useContext, useState } from 'react';
import { useDrawer, type DrawerProps } from '../components/Drawer';
import { useDynamicCallback } from '../hooks/useDynamicCallback';

export const AppwideDrawerContext = createContext<AppwideDrawerContextProps | undefined>(undefined);
AppwideDrawerContext.displayName = 'AppwideDrawerContext';

export enum AppwideDrawerContentType {
  TransferForm,
  ResolveReconMismatches,
  CustomerSettlement,
  AutoHedgePositionRule,
  EntityAdminPage,
}

export interface AppwideDrawerContentSpec {
  type: AppwideDrawerContentType;
  title: string;
  renderContent: () => JSX.Element;
  renderHeaderContent?: () => JSX.Element;
  /** Width of drawer, defaults to 450 */
  width?: number;
  /** CSS Position of drawer, defaults to relative. Determines if drawer is opened next to content ('relative') or on top of content ('absolute') */
  position?: 'relative' | 'absolute';
  placement?: 'right' | 'left';
}

export interface AppwideDrawerContextProps {
  drawer: DrawerProps;
  openDrawer: (spec: AppwideDrawerContentSpec) => void;
  closeDrawer: () => void;
  contentSpec: AppwideDrawerContentSpec | undefined;
}

export function useAppwideDrawerContext() {
  const context = useContext(AppwideDrawerContext);
  if (context === undefined) {
    throw new Error('Missing AppwideDrawerContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export const AppwideDrawerProvider = function AppwideDrawerProvider({ children }) {
  const [contentSpec, setContentSpec] = useState<AppwideDrawerContentSpec | undefined>(undefined);

  const drawer = useDrawer({
    closeOnEscape: true,
    width: contentSpec?.width ?? 450,
    position: contentSpec?.position ?? 'relative',
    placement: contentSpec?.placement,
  });

  const openDrawer = useDynamicCallback((spec: AppwideDrawerContentSpec) => {
    setContentSpec(spec);
    drawer.open();
  });

  return (
    <AppwideDrawerContext.Provider value={{ drawer, openDrawer, closeDrawer: drawer.close, contentSpec }}>
      {children}
    </AppwideDrawerContext.Provider>
  );
};
