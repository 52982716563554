import type { ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { get } from 'lodash';
import { equalityAggFunc } from '../aggFuncs';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

export interface CounterpartyFromMktAccColumnParams {
  marketAccountNameField: string;
}

/**
 * A column which resolves counterparty through the BlotterContext given a market account name.
 */
export const counterpartyFromMarketAccount: ColDefFactory<Column<CounterpartyFromMktAccColumnParams>> = column => ({
  ...baseColumn(column),
  valueGetter: (params: ValueGetterParams<unknown>) => {
    return internalValueGetter(params, column);
  },
  valueFormatter: ({ value, context }: ValueFormatterParams<unknown, string | undefined>) => {
    if (value == null) {
      return '';
    }

    const counterparty = context.current.customersByName?.get(value);
    return counterparty?.DisplayName ?? value;
  },
  filterValueGetter: (params: ValueGetterParams<unknown>) => {
    if (params.node?.group) {
      const marketAccountName = params.node.key;
      if (!marketAccountName) {
        return undefined;
      }
      return params.context.current.marketAccountsByName?.get(marketAccountName)?.Counterparty;
    }
    return internalValueGetter(params, column);
  },
  aggFunc: column.aggregate ? equalityAggFunc : undefined,
});

function internalValueGetter(
  params: ValueGetterParams<unknown>,
  column: Column<CounterpartyFromMktAccColumnParams>
): string | undefined {
  const data = params.node?.data;
  if (!data) {
    return undefined;
  }

  const marketAccountName = column.params?.marketAccountNameField
    ? get(data, column.params.marketAccountNameField)
    : undefined;

  if (!marketAccountName) {
    return undefined;
  }

  return params.context.current.marketAccountsByName?.get(marketAccountName)?.Counterparty;
}
