import type React from 'react';

import type { CardViewEnum } from '../tokens/card';
import type { ResolutionEnumNoPrefix } from './OrderAnalytic';
import type { UnifiedLiquidityEnum } from './types';

export interface MarketDataCardsContextProps {
  showPlaceholderCards: () => void;
  hidePlaceholderCards: () => void;
  saveCard: (nextCard: MarketDataCardConfig) => void;
  deleteCard: (card: MarketDataCardConfig) => void;
  cardFilter: string;
  setCardFilter: React.Dispatch<React.SetStateAction<string>>;
  isAddingCard: boolean;
  isShowingPlaceholders: boolean;
}

export const MarketDataCardState = {
  NEW_CARD: 'NEW_CARD',
  UPDATED_CARD: 'UPDATED_CARD',
} as const;
export interface MarketDataCardConfig {
  id: string;
  symbol?: string;
  view: CardViewEnum;
  state: keyof typeof MarketDataCardState;
  title?: string;
  activeSlideIndex: number;
  availableMarketAccounts: string[];
  selectedMarketAccounts: string[];
  availableMarkets: string[];
  selectedMarkets: string[];
  detailsExpanded?: boolean;
  topOfBookSizeBucket: string;
  sizeBuckets: string[];
  priceIncrement: string;
  unifiedLiquidity?: UnifiedLiquidityEnum;
  alwaysDisplayBestBidOffer?: boolean;
  resolution?: ResolutionEnumNoPrefix;
  orderPresetID?: string;
}

// For React Drag and Drop External Library with no typings
export interface RBDNDCard {
  droppableId: string;
  index: number;
}
export interface RBMoveCardResult {
  source: RBDNDCard;
  destination: RBDNDCard;
  draggableId: string;
}
