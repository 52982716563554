// Common re-usable field rules (should not depend on any field context since re-usable implies generic)

import { toBigWithDefault } from '../../utils';
import { FieldValidationLevel, type FieldValidationRule } from '../BaseField';
import type { NumericField } from '../NumericField';
import { fieldsMessages } from '../messages';

export const numberIsPositive: FieldValidationRule<NumericField> = (field, context) => {
  if (field.value !== '') {
    if (toBigWithDefault(field.value, 0).lte(0)) {
      return {
        message: context.intl.formatMessage(fieldsMessages.fieldNameMustBePositive, { fieldName: field.name }),
        level: FieldValidationLevel.Error,
      };
    }
  }
  return null;
};
