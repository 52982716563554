import { useTransition } from '@react-spring/web';
import Big from 'big.js';
import { useCallback } from 'react';
import { defineMessages } from 'react-intl';
import { useTheme } from 'styled-components';
import { useElementSize } from '../../hooks';
import type { ResolutionEnumNoPrefix, Security } from '../../types';
import { Button, ButtonGroup, ButtonVariants, IconButton } from '../Button';
import { Box } from '../Core';
import { FormControlSizes, FormGroup } from '../Form';
import { AmountInput } from '../Forms';
import { IconName } from '../Icons';
import { FormattedMessage } from '../Intl';
import {
  Bucket,
  BucketLabel,
  Labels,
  LadderSettings,
  LadderStepSettings,
  SectionLabel,
  SettingsContent,
  SettingsFooter,
  SettingsHeader,
  SettingsWrapper,
  SparklineSettings,
} from './styles';
import { validateBuckets } from './utils';

const messages = defineMessages({
  sparklineResolution: {
    defaultMessage: 'Sparkline Resolution',
    id: 'VolumeLadder.sparklineResolution',
  },
  oneMinute: {
    defaultMessage: '1 Minute',
    id: 'VolumeLadder.oneMinute',
  },
  oneHour: {
    defaultMessage: '1 Hour',
    id: 'VolumeLadder.oneHour',
  },
  resetBuckets: {
    defaultMessage: 'Reset Buckets',
    id: 'VolumeLadder.resetBuckets',
  },
  addSteps: {
    defaultMessage: 'Add step',
    id: 'VolumeLadder.addSteps',
  },
  buckets: {
    defaultMessage: 'Buckets',
    id: 'VolumeLadder.buckets',
  },
  volumeLadder: {
    defaultMessage: 'Volume Ladder',
    id: 'VolumeLadder.volumeLadder',
  },
});

const APPEAR_TRANSITION = {
  from: { opacity: 0.5, backdropFilter: 'blur(0px)' },
  enter: { opacity: 1, backdropFilter: 'blur(5px)' },
  leave: { opacity: 0.5, backdropFilter: 'blur(0px)' },
  config: { duration: 200 },
};

const HEIGHT_LIMIT = 28.5;
const SHORT_HEIGHT_LIMIT = 20;

type SettingsProps = {
  resolution?: ResolutionEnumNoPrefix;
  sizeBuckets: string[];
  showSettings: boolean;
  onChangeResolution(resolution: ResolutionEnumNoPrefix): void;
  onChangeSizeBuckets(sizeBuckets: string[]): void;
  onResetSizeBuckets(): void;
  security: Security;
  errors: boolean[];
  setErrors(errors: boolean[]): void;
};

export const Settings = ({
  resolution,
  sizeBuckets,
  showSettings,
  onChangeResolution,
  onChangeSizeBuckets,
  onResetSizeBuckets,
  security,
  errors,
  setErrors,
}: SettingsProps) => {
  const handleBucketChange = useCallback(
    (bucket, index) => {
      const newSizeBuckets = [...sizeBuckets];
      newSizeBuckets[index] = bucket;
      setErrors(validateBuckets(newSizeBuckets, errors, index));
      onChangeSizeBuckets(newSizeBuckets);
    },
    [onChangeSizeBuckets, sizeBuckets, setErrors, errors]
  );

  const handleAddBucket = useCallback(() => {
    if (sizeBuckets != null && sizeBuckets.length > 0) {
      const newValue = Big(sizeBuckets.slice(-1)?.[0]).times(2).toFixed();
      onChangeSizeBuckets([...sizeBuckets, newValue]);
    } else {
      onChangeSizeBuckets([security?.NormalSize ?? '1']);
    }
  }, [onChangeSizeBuckets, sizeBuckets, security]);

  const handleRemoveBucket = useCallback(
    index => {
      if (sizeBuckets != null) {
        const newSizeBuckets = [...sizeBuckets];
        newSizeBuckets.splice(index, 1);
        setErrors(validateBuckets(newSizeBuckets, errors));
        onChangeSizeBuckets(newSizeBuckets);
      }
    },
    [onChangeSizeBuckets, sizeBuckets, errors, setErrors]
  );

  const transition = useTransition(showSettings, APPEAR_TRANSITION);

  const { baseSize } = useTheme();
  const { elementRef, size } = useElementSize<HTMLDivElement>();

  const isTall = (size?.scrollHeight ?? 0) > HEIGHT_LIMIT * baseSize;
  const isShort = size?.scrollHeight ? size?.scrollHeight < SHORT_HEIGHT_LIMIT * baseSize : false;
  const inputSize = (size?.scrollHeight ?? 0) > 34.5 * baseSize ? FormControlSizes.Default : FormControlSizes.Small;

  return transition(
    (style, show: boolean) =>
      show && (
        <Box ref={elementRef} position="absolute" left={0} top={0} right={0} bottom={0}>
          <SettingsWrapper style={style} top={isShort ? '2rem' : undefined}>
            <SettingsContent>
              <SparklineSettings py={isTall ? undefined : 'spacingSmall'}>
                <Labels mb="spacingDefault">
                  <SectionLabel>
                    <FormattedMessage {...messages.sparklineResolution} />
                  </SectionLabel>
                </Labels>

                <FormGroup>
                  <ButtonGroup size={inputSize}>
                    <Button
                      onClick={() => onChangeResolution('1H')}
                      variant={resolution === '1H' ? ButtonVariants.Primary : ButtonVariants.Default}
                    >
                      <FormattedMessage {...messages.oneHour} />
                    </Button>
                    <Button
                      onClick={() => onChangeResolution('1M')}
                      variant={resolution === '1M' ? ButtonVariants.Primary : ButtonVariants.Default}
                    >
                      <FormattedMessage {...messages.oneMinute} />
                    </Button>
                  </ButtonGroup>
                </FormGroup>
              </SparklineSettings>
              <LadderSettings>
                <SettingsHeader
                  h={isTall ? undefined : '2.375rem'}
                  pt={isTall ? undefined : 'spacingSmall'}
                  pb={isTall ? undefined : 'spacingSmall'}
                >
                  <SectionLabel>
                    <FormattedMessage {...messages.volumeLadder} />
                  </SectionLabel>
                  <BucketLabel>
                    <FormattedMessage {...messages.buckets} />
                  </BucketLabel>
                </SettingsHeader>
                <LadderStepSettings>
                  {sizeBuckets.map((bucket, index) => (
                    <Bucket key={index}>
                      <AmountInput
                        value={bucket}
                        onChange={value => handleBucketChange(value, index)}
                        asset={security}
                        currency={security?.BaseCurrency}
                        size={inputSize}
                        invalid={errors[index]}
                      />
                      <IconButton
                        size={inputSize}
                        onClick={() => handleRemoveBucket(index)}
                        variant={ButtonVariants.Default}
                        icon={IconName.Trash}
                      />
                    </Bucket>
                  ))}
                </LadderStepSettings>
              </LadderSettings>
              <SettingsFooter h={isTall ? '4rem' : '2rem'} py={isTall ? undefined : 'spacingSmall'}>
                <Button
                  size={isTall ? FormControlSizes.Default : FormControlSizes.Small}
                  onClick={onResetSizeBuckets}
                  variant={ButtonVariants.Default}
                >
                  <FormattedMessage {...messages.resetBuckets} />
                </Button>
                <Button
                  size={isTall ? FormControlSizes.Default : FormControlSizes.Small}
                  disabled={(sizeBuckets?.length ?? 0) >= 8}
                  onClick={handleAddBucket}
                  variant={ButtonVariants.Positive}
                >
                  <FormattedMessage {...messages.addSteps} />
                </Button>
              </SettingsFooter>
            </SettingsContent>
          </SettingsWrapper>
        </Box>
      )
  );
};
