import { forwardRef, useCallback, useState, type MouseEvent } from 'react';
import type { LinkProps } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { IconButton } from '../Button';
import { FormControlSizes } from '../Form/types';
import { IconName } from '../Icons';
import { TabEditor } from './TabEditor';
import { NavTabWrapper } from './styles';
import type { TabProps } from './types';

export interface NavTabProps extends TabProps, Omit<LinkProps, 'prefix' | 'onClick' | 'className'> {}

export const NavTab = styled(
  forwardRef(
    (
      {
        label,
        prefix,
        suffix,
        editable = false,
        closable = false,
        reorderable = false,
        isSelected,
        onRename,
        onCancel,
        onRemove,
        isAddingTab = false,
        isDragging = false,
        to = '/',
        ...props
      }: NavTabProps,
      ref
    ) => {
      const [isEditing, setEditing] = useState(isAddingTab);
      const handleRename = useCallback(
        (value: string) => {
          if (value) {
            onRename?.(value);
            setEditing(false);
          }
        },
        [onRename]
      );
      const handleCancel = useCallback(() => {
        onCancel?.();
        setEditing(false);
        if (isAddingTab) {
          onRemove?.();
        }
      }, [onCancel, onRemove, isAddingTab]);

      const handleRemove = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();
          onRemove?.();
          return false;
        },
        [onRemove]
      );

      const theme = useTheme();

      return (
        <NavTabWrapper
          ref={ref}
          role="tab"
          aria-selected={isSelected}
          isSelected={isSelected}
          isDragging={isDragging}
          onDoubleClick={() => editable && setEditing(true)}
          to={to}
          {...props}
        >
          {isEditing ? (
            <TabEditor initialValue={label} onSubmit={handleRename} onCancel={handleCancel} />
          ) : (
            <>
              {!!prefix && prefix}
              {label && <span>{label}</span>}
              {!!suffix && suffix}
              {closable && (
                <IconButton
                  icon={IconName.Close}
                  onClick={handleRemove}
                  ghost={true}
                  size={FormControlSizes.Tiny}
                  style={{ width: 'auto', marginLeft: theme.spacingSmall }}
                  color={theme.colorTextDefault}
                />
              )}
            </>
          )}
        </NavTabWrapper>
      );
    }
  )
)``;
