import { useEffect } from 'react';
import { distinctUntilChanged, map, type Observable } from 'rxjs';
import { useMarketPriceContext, type MarketPrice } from '../contexts';
import { useObservable } from './useObservable';

/**
 * Hook to subscribe to the latest market price for a security.
 *
 * @param security Security name to subscribe to
 * @returns Observable of MarketPrice for the given symbol
 */
export function useMarketPrice(security: string): Observable<MarketPrice | undefined> {
  const { marketPricesBySymbol, registerSubscription, unregisterSubscription } = useMarketPriceContext();

  useEffect(() => {
    registerSubscription(security);
    return () => {
      unregisterSubscription(security);
    };
  }, [registerSubscription, security, unregisterSubscription]);

  const marketPricesObservable = useObservable<MarketPrice | undefined>(
    () => marketPricesBySymbol.pipe(map((prices: Map<string, MarketPrice | undefined>) => prices.get(security))),
    [marketPricesBySymbol, security]
  );
  return marketPricesObservable;
}

/**
 * Hook to subscribe to the latest market price for multiple securities.
 *
 * @param securities Array of security names to subscribe for
 * @returns Observable map of SecurityName => MarketPrice
 */
export function useMarketPrices(securities: string[]): Observable<Map<string, MarketPrice | undefined>> {
  const { marketPricesBySymbol, registerSubscriptions, unregisterSubscriptions } = useMarketPriceContext();

  useEffect(() => {
    registerSubscriptions(securities);
    return () => {
      unregisterSubscriptions(securities);
    };
  }, [registerSubscriptions, securities, unregisterSubscriptions]);

  const marketPricesObservable = useObservable<Map<string, MarketPrice | undefined>>(
    () =>
      marketPricesBySymbol.pipe(
        map(prices => new Map(securities.map(symbol => [symbol, prices.get(symbol)]))),
        distinctUntilChanged()
      ),
    [marketPricesBySymbol, securities]
  );
  return marketPricesObservable;
}
