import type { BaseTheme } from 'styled-components';

const BASE_SIZE = 16;

export const base: BaseTheme = {
  baseSize: BASE_SIZE,

  /** deprecated legacy spacings */
  spacingHuge: BASE_SIZE * 3,
  spacingBig: BASE_SIZE * 2,
  spacingLarge: BASE_SIZE * 1.5,
  spacingMedium: BASE_SIZE,
  spacingComfortable: BASE_SIZE * 0.75,
  spacingDefault: BASE_SIZE * 0.5,
  spacingSmall: BASE_SIZE * 0.25,
  spacingTiny: BASE_SIZE * 0.125,

  /** new setup tied to resulting target pixels */
  spacing48: BASE_SIZE * 3,
  spacing32: BASE_SIZE * 2,
  spacing24: BASE_SIZE * 1.5,
  spacing16: BASE_SIZE,
  spacing12: BASE_SIZE * 0.75,
  spacing10: BASE_SIZE * 0.625,
  spacing8: BASE_SIZE * 0.5,
  spacing6: BASE_SIZE * 0.375,
  spacing4: BASE_SIZE * 0.25,
  spacing2: BASE_SIZE * 0.125,

  fontSizeHuge: 1.5,
  fontSizeBig: 1.125,
  fontSizeLarge: 1,
  fontSizeDefault: 0.875,
  fontSizeSmall: 0.75,
  fontSizeTiny: 0.6875,

  fontWeightRegular: '400',
  fontWeightMedium: '500',
  fontWeightBold: '700',

  fontSizeTitleH1: 44 / BASE_SIZE,
  fontSizeTitleH2: 32 / BASE_SIZE,
  fontSizeTitleH3: 24 / BASE_SIZE,
  fontSizeTitleH4: 20 / BASE_SIZE,
  fontSizeTitleH5: 18 / BASE_SIZE,
  fontSizeTitleH6: 16 / BASE_SIZE,
  fontSizeXl: 18 / BASE_SIZE,
  fontSizeLg: 16 / BASE_SIZE,
  fontSizeMd: 14 / BASE_SIZE,
  fontSizeSm: 12 / BASE_SIZE,
  fontSizeXs: 11 / BASE_SIZE,
  lineHeightTitleH1: 56 / BASE_SIZE,
  lineHeightTitleH2: 40 / BASE_SIZE,
  lineHeightTitleH3: 32 / BASE_SIZE,
  lineHeightTitleH4: 24 / BASE_SIZE,
  lineHeightTitleH5: 24 / BASE_SIZE,
  lineHeightTitleH6: 20 / BASE_SIZE,
  lineHeightXl: 24 / BASE_SIZE,
  lineHeightLg: 20 / BASE_SIZE,
  lineHeightMd: 16 / BASE_SIZE,
  lineHeightSm: 16 / BASE_SIZE,
  lineHeightXs: 12 / BASE_SIZE,

  lineHeightHuge: 2,
  lineHeightBig: 1.5,
  lineHeightLarge: 1.25,
  lineHeightDefault: 1,
  lineHeightSmall: 1,
  lineHeightTiny: 0.75,

  fontSizeFormLabel: 0.75,
  fontSizeFormHelp: 0.75,

  fontSizeBlotterTableCompact: 0.75,
  fontSizeBlotterTableDefault: 0.75,
  fontSizeBlotterTableHeader: 0.6875,
  blotterTableHeaderFontWeight: '400',
  blotterTableHeaderTextTransform: 'uppercase',

  rowHeightBlotterTableCompact: 28 + 1, // + 1 for border
  rowHeightBlotterTableDefault: 32 + 1, // + 1 for border
  rowHeightBlotterTableComfortable: 40 + 1, // + 1 for border
  rowHeightBlotterTableVeryComfortable: 52 + 1, // + 1 for border

  fontFamily: 'Roboto, sans-serif',
  fontUrl: 'https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400&display=swap',

  borderRadiusTiny: 1,
  borderRadiusSmall: 2,
  borderRadiusDefault: 3,
  borderRadiusMedium: BASE_SIZE * 0.25,
  borderRadiusLarge: BASE_SIZE * 0.5,
  borderRadiusBig: BASE_SIZE,
  borderRadiusHuge: BASE_SIZE * 2.5,

  borderRadiusCard: 3,
  borderRadiusContent: 0,
  borderRadiusLayoutWrapper: 0,

  borderRadiusButtonTiny: 1,
  borderRadiusButtonSmall: 2,
  borderRadiusButtonDefault: 3,
  borderRadiusButtonLarge: BASE_SIZE * 0.25,

  spacingLayout: BASE_SIZE * 0.25,
  spacingContent: BASE_SIZE * 0.125,
  spacingLayoutWrapper: 0,

  scrollbarSize: 8,
  borderRadiusScrollbar: 4,

  borderWidthButton: 0,

  indicatorDotSizeDefault: BASE_SIZE * 0.375,
  indicatorDotSizeLarge: BASE_SIZE * 0.625,
};
