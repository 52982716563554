import { datadogRum } from '@datadog/browser-rum';
import type React from 'react';
import { createContext, memo, useContext } from 'react';
import { EMPTY, interval, of, throwError, type Observable } from 'rxjs';
import { catchError, map, share, switchMap, tap, timeout } from 'rxjs/operators';
import { useObservable } from '../hooks';
import { HeartBeatStatusEnum } from '../tokens/heartbeats';
import type { Response, SubscriptionResponse } from '../types';
import { useSocketClient } from './WebSocketClientProvider';

export const MEDIUM_LATENCY_THRESHOLD = 100;
export const HIGH_LATENCY_THRESHOLD = 200;

export const HEARTBEAT_INTERVAL = 3000;
export const HEARTBEAT_TIMEOUT = 2 * 60 * 1000;

const Heartbeats = createContext<
  | {
      heartbeats: Observable<Response<unknown>>;
      latency: Observable<number>;
      status: Observable<HeartBeatStatusEnum>;
    }
  | undefined
>(undefined);
Heartbeats.displayName = 'HeartbeatsContext';

export const useHeartbeats = () => {
  const context = useContext(Heartbeats);
  if (context === undefined) {
    throw new Error('Missing Heartbeats.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};

export const HeartbeatsProvider = memo(function HeartbeatsProvider(props: React.PropsWithChildren<unknown>) {
  const client = useSocketClient<SubscriptionResponse>();

  // Grab the internal client.isConnected variable so we can put it in the dependency array of heartbeats below without lint warnings.
  const isClientConnected = client.isConnected;

  const heartbeats = useObservable(() => {
    return client && isClientConnected
      ? interval(HEARTBEAT_INTERVAL).pipe(
          tap(() => {
            if (isClientConnected) {
              client.ping({
                ts: new Date().getTime(),
              });
            }
          }),
          switchMap(() => client.pongs()),
          timeout(HEARTBEAT_TIMEOUT),
          catchError(e => {
            if (isClientConnected) {
              client.closeAndReconnect();
            }
            return throwError(e);
          }),
          share()
        )
      : EMPTY;
  }, [client, isClientConnected]);

  const latency = useObservable(
    () =>
      heartbeats.pipe(
        map(json => {
          const duration = new Date().getTime() - json.data[0].ts;
          datadogRum.addDurationVital('heartbeatRTT', { startTime: json.data[0].ts, duration });
          return duration;
        }),
        catchError(() => of(Infinity)),
        share()
      ),
    [heartbeats]
  );

  const status = useObservable(
    () =>
      latency.pipe(
        map(latency => {
          if (latency === Infinity) {
            return HeartBeatStatusEnum.OFFLINE;
          } else if (latency < MEDIUM_LATENCY_THRESHOLD) {
            return HeartBeatStatusEnum.LOW_LATENCY;
          } else if (latency < HIGH_LATENCY_THRESHOLD) {
            return HeartBeatStatusEnum.MEDIUM_LATENCY;
          } else {
            return HeartBeatStatusEnum.HIGH_LATENCY;
          }
        })
      ),
    [latency]
  );
  return (
    <Heartbeats.Provider
      value={{
        heartbeats,
        latency,
        status,
      }}
    >
      {props.children}
    </Heartbeats.Provider>
  );
});
