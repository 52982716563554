import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { darkColors, mapDarkTheme } from './DarkTheme';

const colors: Colors = {
  black: {
    default: '#121212',
    dim: 'rgba(18, 18, 18, 0.4)',
    mute: 'rgba(18, 18, 18, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    main: '#010101',

    '000': '#000000',
    '010': '#121212',
    '020': '#232323',
    '030': '#343434',
    '040': '#454545',
    '050': '#565656',
    '060': '#676767',
    '070': '#787878',
    '080': '#898989',
    '090': '#9a9a9a',
    '100': '#ababab',
  },

  primary: {
    mute: setAlpha(0.1, '#7055F8'),
    dim: setAlpha(0.4, '#7055F8'),
    default: '#7055F8',
    lighten: '#8F79FF',
  },

  green: {
    mute: setAlpha(0.1, '#477722'),
    dim: setAlpha(0.4, '#477722'),
    default: '#477722',
    lighten: '#5AA61E',
  },

  red: {
    mute: setAlpha(0.1, '#8D483C'),
    dim: setAlpha(0.4, '#8D483C'),
    default: '#8D483C',
    lighten: '#CA5A47',
  },

  yellow: {
    mute: setAlpha(0.1, '#D6C043'),
    dim: setAlpha(0.4, '#D6C043'),
    default: '#D6C043',
    lighten: '#FFE646',
  },

  blue: {
    mute: setAlpha(0.1, '#2F5F9C'),
    dim: setAlpha(0.4, '#2F5F9C'),
    default: '#2F5F9C',
    lighten: '#43AAD6',
  },

  orange: {
    mute: setAlpha(0.1, '#D24500'),
    dim: setAlpha(0.4, '#D24500'),
    default: '#D24500',
    lighten: '#FF6900',
  },

  purple: darkColors.purple,
};

export const DarkJanuarTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkJR',

  backgroundBody: colors.black.default,
  backgroundHeader: colors.gray.main,
  borderColorHeader: colors.gray['020'],
  boxShadowHeader: colors.gray['020'],

  backgroundSidebar: colors.gray.main,
  borderColorSidebar: colors.gray['020'],
});
