import type { CSSProperties } from 'react';
import styled, { type DefaultTheme } from 'styled-components';
import { parseDimension } from '../../../styles';
import { ButtonWrapper } from '../../Button/styles';
import { Box, type BoxProps } from '../../Core';
import { DateTimeDurationPickerWrapper } from '../../DateTimeDurationPicker/styles';
import { DateTimePickerWrapper } from '../../DateTimePicker/styles';
import { AutocompleteWrapper } from '../Autocomplete/styles';
import { InputWrapper } from '../Input/styles';
import { MultiSelectInputWrapper } from '../MultiSelect/styles';
import { SearchSelectWrapper } from '../SearchSelect/styles';
import { SelectWrapper } from '../Select/styles';
import { TextAreaWrapper } from '../TextArea/styles';
import { INPUT_BORDER_WIDTH, Label } from '../styles';
import { FormControlSizes } from '../types';
import { getFontSize } from '../utils';

export const HelpText = styled(Box)`
  color: ${({ theme }) => theme.colorTextSubtle};
  font-size: ${({ theme }) => theme.fontSizeFormHelp}rem;
  padding: ${({ theme }) => theme.spacingTiny}px 0;
  line-height: 1;
`;

export const FormMessage = styled(Box)<{ error?: boolean; warning?: boolean }>`
  color: ${({ theme, warning, error }) =>
    // red error takes precedence, but is also the default
    error ? theme.colorTextNegative : warning ? theme.colorTextWarning : theme.colorTextNegative};
  font-size: ${({ theme }) => theme.fontSizeFormHelp}rem;
  padding-top: ${({ theme }) => theme.spacingTiny}px;
  line-height: 1;
  white-space: pre-wrap;
`;

// placeholder for styled component selector usage below
export const OverflowFormMessageWrapper = styled(Box)``;

interface FormGroupWrapperProps extends BoxProps {
  disabled?: boolean;
  inline?: boolean;
  size?: FormControlSizes;
  labelWidth?: CSSProperties['width'];
}

/**
 * Get the margin bottom for a form group.
 * Accounts for the help/error/warning text, and adds a bit of extra space.
 */
export const getFormGroupMarginBottom = (theme: DefaultTheme) => {
  return `${theme.spacingSmall + theme.spacingTiny + theme.fontSizeFormHelp * theme.baseSize}px`;
};

export const FormGroupWrapper = styled(Box)<FormGroupWrapperProps>`
  position: relative;
  text-align: ${({ textAlign }) => textAlign ?? 'left'};
  ${({ inline, alignItems }) => inline && `display: flex; align-items: ${alignItems ?? 'baseline'};`}
  ${({ theme, mb }) =>
    `margin-bottom: ${mb != null ? parseDimension(theme, mb) : getFormGroupMarginBottom(theme)};
          &:last-child {
            margin-bottom: ${mb != null ? parseDimension(theme, mb) : `${theme.spacingDefault}px`};
          }
        `};

  > ${Label}, > span > ${Label}, > div > ${Label} {
    display: inline-flex;
    margin-bottom: ${({ theme, inline }) => !inline && theme.spacingSmall}px;
    transition: color 200ms ease;
    font-size: ${({ theme }) => theme.fontSizeFormLabel}rem;
    align-self: flex-start;
    color: ${({ disabled, theme }) => (disabled ? theme.colorTextSubtle : 'inherit')};
  }

  > ${Box}:first-child {
    ${({ inline }) => inline && `flex: 0 0 auto;`}
  }

  > ${ButtonWrapper} {
    height: ${({ theme, size = FormControlSizes.Default }) => theme.baseSize * size}px;
    font-size: ${({ theme, size = FormControlSizes.Default }) => getFontSize(theme, size)}rem;
  }

  > ${InputWrapper},
    > ${SelectWrapper},
    > ${SearchSelectWrapper}
    > ${InputWrapper},
    > ${DateTimePickerWrapper}
    > ${InputWrapper},
    > ${AutocompleteWrapper}
    > ${InputWrapper},
    > ${DateTimeDurationPickerWrapper} {
    line-height: ${({ theme, size = FormControlSizes.Default }) => theme.baseSize * size - INPUT_BORDER_WIDTH * 2}px;
    height: ${({ theme, size = FormControlSizes.Default }) => theme.baseSize * size}px;
    min-height: ${({ theme, size = FormControlSizes.Default }) => theme.baseSize * size}px;
    font-size: ${({ theme, size = FormControlSizes.Default }) => getFontSize(theme, size)}rem;

    ${({ inline }) => inline && `flex: 1 1 auto;`}

    > input,
    > select {
      height: ${({ theme, size = FormControlSizes.Default }) => theme.baseSize * size - INPUT_BORDER_WIDTH * 2}px;
      min-height: ${({ theme, size = FormControlSizes.Default }) => theme.baseSize * size - INPUT_BORDER_WIDTH * 2}px;
      line-height: ${({ theme, size = FormControlSizes.Default }) => theme.baseSize * size - INPUT_BORDER_WIDTH * 2}px;
    }
  }

  /* Apply same as above but with min-height instead as multiselect must be allowed to grow in height */
  & ${MultiSelectInputWrapper} {
    line-height: ${({ theme, size = FormControlSizes.Default }) => theme.baseSize * size - INPUT_BORDER_WIDTH * 2}px;
    min-height: ${({ theme, size = FormControlSizes.Default }) => theme.baseSize * size}px;
    font-size: ${({ theme, size = FormControlSizes.Default }) => getFontSize(theme, size)}rem;
  }

  > ${SelectWrapper} {
    &:after {
      right: ${({ size = FormControlSizes.Default }) => (size / 2) * 0.5}rem;
      width: ${({ size = FormControlSizes.Default }) => (size / 2) * 0.5}rem;
    }
  }

  > ${TextAreaWrapper} {
    line-height: ${({ theme, size = FormControlSizes.Default }) => theme.baseSize * size - INPUT_BORDER_WIDTH * 2}px;
    font-size: ${({ theme, size = FormControlSizes.Default }) => getFontSize(theme, size)}rem;
    flex: 1;

    & > textarea {
      height: 100%;
    }
  }

  &:focus-within {
    ${Label} {
      color: ${({ theme }) => theme.colorTextImportant};
    }
    ${HelpText} {
      color: ${({ theme }) => theme.colorTextDefault};
    }
  }

  > ${FormMessage}, > ${HelpText}, > ${OverflowFormMessageWrapper} ${FormMessage} {
    height: ${({ theme }) => 1.4 * theme.fontSizeFormHelp}rem;
    font-size: ${({ theme }) => theme.fontSizeFormHelp}rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -${({ theme }) => 1.4 * theme.fontSizeFormHelp}rem;
  }

  > ${HelpText} {
    color: ${({ disabled, theme }) => (disabled ? theme.colorTextMuted : '')};
  }
`;

export const FormActions = styled(FormGroupWrapper)`
  display: flex;
  gap: ${({ theme, size = FormControlSizes.Default }) => (theme.spacingDefault * size) / 2}px;
  justify-content: flex-end;
`;
