import {
  Box,
  Button,
  ButtonVariants,
  CurrencySelect,
  CustomerAddressCreator,
  CustomerAddressTypeEnum,
  Dialog,
  FormControlSizes,
  FormGroup,
  FormattedMessage,
  HStack,
  IconName,
  InlineFormattedNumber,
  Input,
  ModeEnum,
  NotificationVariants,
  NumberInput,
  SearchSelect,
  Text,
  Toasts,
  Z_INDEX,
  getAddressRoutingTypeOfCurrency,
  toBigWithDefault,
  useCurrenciesContext,
  useCurrency,
  useGetCustomerMarketAccountLabel,
  useGlobalToasts,
  useIntl,
  useWLCustomerMarketAccountContext,
  useWLOrgConfigContext,
  type Currency,
  type DialogProps,
  type NewCustomerAddressFormState,
} from '@talos/kyoko';
import Big from 'big.js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { defineMessages } from 'react-intl';
import { ToastWrapper } from '../../components/OrderForms/RFQForm/styles';
import {
  useCustomerDepositWithdrawContext,
  type CustomerAddressWhitelabel,
  type CustomerWithdrawFormState,
} from '../../providers';

const messages = defineMessages({
  optional: {
    defaultMessage: 'Optional',
    id: 'Dialogs.optional',
  },
  account: {
    defaultMessage: 'Account',
    id: 'Dialogs.account',
  },
  addNew: {
    defaultMessage: 'Add New',
    id: 'Dialogs.addNew',
  },
  amount: {
    defaultMessage: 'Amount',
    id: 'Dialogs.amount',
  },
  availableToWithdraw: {
    defaultMessage: 'Available to Withdraw',
    id: 'Dialogs.availableToWithdraw',
  },
  currency: {
    defaultMessage: 'Currency',
    id: 'Dialogs.currency',
  },
  max: {
    defaultMessage: 'Max',
    id: 'Dialogs.max',
  },
  address: {
    defaultMessage: 'Address',
    id: 'Dialogs.address',
  },
  comment: {
    defaultMessage: 'Comment',
    id: 'Dialogs.comment',
  },
  unknownName: {
    defaultMessage: 'Unknown Name',
    id: 'Dialogs.unknownName',
  },
  quantityExceedsAvailableBalance: {
    defaultMessage: 'Quantity exceeds available balance.',
    id: 'Dialogs.quantityExceedsAvailableBalance',
  },
  cannotCreateAddressWithoutCurrency: {
    defaultMessage: 'Cannot create address without currency',
    id: 'Dialogs.cannotCreateAddressWithoutCurrency',
  },
  couldNotCreateAddress: {
    defaultMessage: 'Could not create address',
    id: 'Dialogs.couldNotCreateAddress',
  },
});

export const WithdrawDialog = (withdrawDialog: DialogProps) => {
  const { add: addToast, toasts, remove: removeToast } = useGlobalToasts();
  const { currenciesBySymbol: availableCurrenciesBySymbol } = useCurrenciesContext();
  const {
    withdrawCustomerBalance,
    customerBalances,
    customerAddressesByAddressID,
    decryptCustomerAddress,
    createCustomerAddress,
  } = useCustomerDepositWithdrawContext();
  const { customerMarketAccountsList } = useWLCustomerMarketAccountContext();
  const [form, setForm] = useState<Partial<CustomerWithdrawFormState>>({});
  const { formatMessage } = useIntl();

  const [addingNewAddress, setAddingNewAddress] = useState<boolean>(false);
  const [decryptedAddressCount, setDecryptedAddressCount] = useState<number>(0);

  const customerAddressesOfSelectedCurrency = useMemo(() => {
    if (form.marketAccount && customerAddressesByAddressID) {
      return [...customerAddressesByAddressID.values()].filter(ca => ca.Currency === form.currency);
    }
    return [];
  }, [customerAddressesByAddressID, form.currency, form.marketAccount]);

  const decryptedAddressesOfSelectedCurrency = useMemo(() => {
    setDecryptedAddressCount(0);
    const decryptedAddresses = [...customerAddressesOfSelectedCurrency];
    decryptedAddresses.forEach(account => {
      if (account.RoutingInfo == null) {
        decryptCustomerAddress(account.AddressID).then(({ data }) => {
          account.RoutingInfo = data[0].RoutingInfo;
          setDecryptedAddressCount(prev => prev + 1);
        });
      } else {
        setDecryptedAddressCount(prev => prev + 1);
      }
    });
    return decryptedAddresses;
  }, [customerAddressesOfSelectedCurrency, decryptCustomerAddress]);

  const { config } = useWLOrgConfigContext();
  const currencyInfo = useCurrency(form.currency);

  const addressRoutingType = getAddressRoutingTypeOfCurrency(currencyInfo);
  const getCustomerMarketAccountLabel = useGetCustomerMarketAccountLabel();
  const requireWithdrawAddress = config.requireWithdrawalAddress ?? false;
  const requireWithdrawComment = config.requireWithdrawalComment ?? false;

  const availableCurrencies = useMemo(
    () =>
      customerBalances &&
      [...availableCurrenciesBySymbol.values()].filter(
        curr => form.marketAccount != null && customerBalances.get(`${form.marketAccount}-${curr.Symbol}`) != null
      ),
    [availableCurrenciesBySymbol, customerBalances, form.marketAccount]
  );

  const updateForm = useCallback((form: Partial<CustomerWithdrawFormState>) => {
    setForm(prev => ({ ...prev, ...form }));
  }, []);

  const maxAvailableBalance: string = useMemo(() => {
    if (form.currency == null) {
      return '0';
    }
    const balance = customerBalances?.get(`${form.marketAccount}-${form.currency}`);
    if (balance == null) {
      return '0';
    }
    // [sc-55204] Logic: MIN(MAX(amount,0), available amount);
    // If Balance.Amount if negative, set Amount to 0
    const amount: string = Big(balance.Amount).gt(0) ? balance.Amount : '0';
    // Take the smaller between Amount(^) and Balance.AvailableAmount
    return Big(amount).gt(balance.AvailableAmount) ? balance.AvailableAmount : amount;
  }, [customerBalances, form.currency, form.marketAccount]);

  const amountWarning: string | undefined = useMemo(() => {
    if (toBigWithDefault(form.amount, 0).gt(maxAvailableBalance)) {
      return formatMessage(messages.quantityExceedsAvailableBalance);
    }
    return undefined;
  }, [form.amount, formatMessage, maxAvailableBalance]);

  const confirmDisabled = useMemo(() => {
    if (!form.currency || !form.marketAccount || !form.amount) {
      return true;
    }

    if (Big(form.amount).lte(0) || amountWarning != null) {
      return true;
    }

    if (requireWithdrawAddress && !form.address) {
      return true;
    }

    if (requireWithdrawComment && !form.externalComment) {
      return true;
    }

    if (addingNewAddress) {
      return true;
    }

    return false;
  }, [
    addingNewAddress,
    amountWarning,
    form.address,
    form.amount,
    form.currency,
    form.externalComment,
    form.marketAccount,
    requireWithdrawAddress,
    requireWithdrawComment,
  ]);

  const handleOnConfirm = useCallback(() => {
    withdrawCustomerBalance(form);
  }, [form, withdrawCustomerBalance]);

  useEffect(() => {
    // Reset form when dialog is closed
    if (!withdrawDialog.isOpen) {
      setForm({});
    }
  }, [withdrawDialog.isOpen]);

  const handleMax = useCallback(() => updateForm({ amount: maxAvailableBalance }), [maxAvailableBalance, updateForm]);

  useEffect(() => {
    // Select the market account automatically if there is only 1 option.
    // The market account selector will be disabled.
    if (customerMarketAccountsList?.length === 1) {
      updateForm({ marketAccount: customerMarketAccountsList[0].SourceAccountID });
    }
  }, [form.marketAccount, customerMarketAccountsList, updateForm]);

  const marketAccountOptions = useMemo(
    () => customerMarketAccountsList?.map(ma => ma.SourceAccountID),
    [customerMarketAccountsList]
  );

  const handleOnMarketAccountChange = useCallback(
    (accountID?: string) => updateForm({ marketAccount: accountID, currency: undefined, amount: undefined }),
    [updateForm]
  );
  const handleOnChangeCurrency = useCallback(
    (currency?: Currency) => {
      updateForm({ currency: currency?.Symbol, address: undefined, amount: undefined });
    },
    [updateForm]
  );

  const handleOnChangeExternalComment = useCallback(
    (externalComment: string) => updateForm({ externalComment }),
    [updateForm]
  );

  const handleOnChangeAmount = useCallback((amount: string) => updateForm({ amount }), [updateForm]);
  // [sc-55204] Don't send blank withdrawal address if one isn't specified
  const handleOnChangeAddress = useCallback(
    (address?: CustomerAddressWhitelabel) => updateForm({ address: address?.AddressID }),
    [updateForm]
  );

  const handleAddNewCustomerAddress = useCallback(() => {
    setAddingNewAddress(true);
  }, []);

  const handleCreateNewAddress = useCallback(
    (newAddressForm: NewCustomerAddressFormState) => {
      if (!form.currency) {
        return addToast({
          text: formatMessage(messages.cannotCreateAddressWithoutCurrency),
          variant: NotificationVariants.Negative,
          dismissable: true,
        });
      }
      createCustomerAddress({
        Currency: form.currency,
        AddressType: CustomerAddressTypeEnum.Withdrawal,
        AddressRoutingType: addressRoutingType,
        Mode: ModeEnum.Enabled,
        Name: newAddressForm.name,
        RoutingInfo: {
          WalletAddress: newAddressForm.address,
          Memo: newAddressForm.memo,
          DestinationTag: '',
        },
      })
        .then(({ data }) => {
          setAddingNewAddress(false);
          updateForm({ address: data.at(0)?.AddressID });
        })
        .catch(error => {
          addToast({
            text: error?.toString() || formatMessage(messages.couldNotCreateAddress),
            variant: NotificationVariants.Negative,
            dismissable: true,
          });
        });
    },
    [form.currency, createCustomerAddress, addressRoutingType, addToast, formatMessage, updateForm]
  );

  if (!availableCurrencies || !customerMarketAccountsList || !marketAccountOptions) {
    return null;
  }

  return (
    <Dialog {...withdrawDialog} onConfirm={handleOnConfirm} confirmDisabled={confirmDisabled}>
      {customerMarketAccountsList.length > 1 && (
        <FormGroup label={`${formatMessage(messages.account)}*`}>
          <SearchSelect
            options={marketAccountOptions}
            selection={form.marketAccount}
            onChange={handleOnMarketAccountChange}
            disabled={customerMarketAccountsList.length <= 1}
            getLabel={getCustomerMarketAccountLabel}
            data-testid="withdraw-dialog-market-account-select"
          />
        </FormGroup>
      )}

      <FormGroup label={`${formatMessage(messages.currency)}*`}>
        <CurrencySelect
          disabled={!form.marketAccount}
          options={availableCurrencies}
          onChange={handleOnChangeCurrency}
          value={availableCurrenciesBySymbol.get(form.currency || '')}
          showDescriptionInButton
          data-testid="withdraw-dialog-currency-select"
        />
      </FormGroup>

      <FormGroup label={`${formatMessage(messages.amount)}*`} warning={amountWarning}>
        <NumberInput
          value={form.amount || ''}
          onChange={handleOnChangeAmount}
          suffix={form.currency}
          disabled={!form.currency}
          min="0"
          prefix={
            <Button size={FormControlSizes.Small} variant={ButtonVariants.Default} onClick={handleMax}>
              <FormattedMessage {...messages.max} />
            </Button>
          }
          data-testid="withdraw-dialog-amount-input"
        />
      </FormGroup>

      <Box position="relative" mb="spacingMedium">
        <ToastWrapper>
          <Toasts toasts={toasts} remove={removeToast} />
        </ToastWrapper>
        {addingNewAddress ? (
          <CustomerAddressCreator
            enabledFields={{ name: true, address: true, memo: true }}
            addressRoutingType={addressRoutingType}
            onSubmit={handleCreateNewAddress}
            onCancel={() => setAddingNewAddress(false)}
            showBackground={true}
          />
        ) : (
          <FormGroup
            label={`${formatMessage(messages.address)}${requireWithdrawAddress ? '*' : ''}`}
            disabled={!form.currency}
          >
            <Box position="absolute" right={0} top={-5} zIndex={Z_INDEX.modal}>
              <Button
                ghost
                startIcon={IconName.Plus}
                size={FormControlSizes.Tiny}
                onClick={handleAddNewCustomerAddress}
                disabled={!form.currency}
                data-testid="withdraw-dialog-add-new-address-button"
              >
                <FormattedMessage {...messages.addNew} />
              </Button>
            </Box>
            <SearchSelect
              options={decryptedAddressesOfSelectedCurrency}
              selection={form.address ? customerAddressesByAddressID?.get(form.address) : undefined}
              onChange={handleOnChangeAddress}
              disabled={!form.currency || decryptedAddressCount < decryptedAddressesOfSelectedCurrency.length}
              getLabel={(ca: CustomerAddressWhitelabel) => ca.Name || formatMessage(messages.unknownName)}
              getDescription={(ca: CustomerAddressWhitelabel) => ca.RoutingInfo?.WalletAddress || ca.AddressID}
              placeholder={!requireWithdrawAddress ? formatMessage(messages.optional) : undefined}
              showClear={!requireWithdrawAddress}
              data-testid="withdraw-dialog-address-select"
            />
          </FormGroup>
        )}
      </Box>

      <FormGroup mb="spacingMedium" label={`${formatMessage(messages.comment)}${requireWithdrawComment ? '*' : ''}`}>
        <Input
          value={form.externalComment ?? ''}
          autoComplete="off"
          onChange={e => handleOnChangeExternalComment(e.target.value)}
          data-testid="withdraw-dialog-comment-input"
        />
      </FormGroup>

      <HStack justifyContent="space-between">
        <Text>
          <FormattedMessage {...messages.availableToWithdraw} />
        </Text>
        {form.currency ? (
          <InlineFormattedNumber
            number={maxAvailableBalance}
            currency={form.currency}
            increment={currencyInfo?.DefaultIncrement}
            align="right"
          />
        ) : (
          '-'
        )}
      </HStack>
    </Dialog>
  );
};
