import { hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { darkColors, mapDarkTheme } from './DarkTheme';

const colors: Colors = {
  black: {
    default: hsla(262, 0.31, 0.04, 1),
    dim: 'rgba(49, 37, 70, 0.4)',
    mute: 'rgba(49, 37, 70, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    main: '#37294F',

    '000': hsla(262, 0.31, 0.11, 1),
    '010': hsla(262, 0.31, 0.16, 1),
    '020': hsla(262, 0.31, 0.18, 1),
    '030': hsla(262, 0.31, 0.21, 1),
    '040': hsla(262, 0.31, 0.26, 1),
    '050': hsla(262, 0.31, 0.35, 1),
    '060': hsla(262, 0.31, 0.46, 1),
    '070': hsla(262, 0.31, 0.58, 1),
    '080': hsla(262, 0.31, 0.75, 1),
    '090': hsla(262, 0.31, 0.85, 1),
    '100': hsla(262, 0.31, 0.95, 1),
  },

  primary: {
    mute: setAlpha(0.1, '#3A2B53'),
    dim: setAlpha(0.4, '#3A2B53'),
    default: '#3A2B53',
    lighten: '#453561',
  },

  green: {
    mute: setAlpha(0.1, '#0AA049'),
    dim: setAlpha(0.4, '#0AA049'),
    default: '#0AA049',
    lighten: '#27B663',
  },

  red: {
    mute: setAlpha(0.1, '#8D483C'),
    dim: setAlpha(0.4, '#8D483C'),
    default: '#8D483C',
    lighten: '#CA5A47',
  },

  yellow: {
    mute: setAlpha(0.1, '#D6C043'),
    dim: setAlpha(0.4, '#D6C043'),
    default: '#D6C043',
    lighten: '#FFE646',
  },

  blue: {
    mute: setAlpha(0.1, '#43AAD6'),
    dim: setAlpha(0.4, '#43AAD6'),
    default: '#43AAD6',
    lighten: '#46C7FF',
  },

  orange: {
    mute: setAlpha(0.1, '#D24500'),
    dim: setAlpha(0.4, '#D24500'),
    default: '#D24500',
    lighten: '#FF6900',
  },

  purple: darkColors.purple,
};

export const DarkRainTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkRN',

  backgroundBody: '#312546',
  backgroundHeader: colors.gray.main,
  borderColorHeader: colors.gray['020'],
  boxShadowHeader: colors.gray['020'],

  backgroundSidebar: colors.gray.main,
  borderColorSidebar: colors.gray['020'],
});
