import { hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { darkColors, mapDarkTheme } from './DarkTheme';

const colors: Colors = {
  black: {
    default: '#000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    main: '#191919',

    '000': '#101010',
    '010': hsla(200, 0, 0.11, 1),
    '020': hsla(200, 0, 0.14, 1),
    '030': hsla(200, 0, 0.16, 1),
    '040': hsla(200, 0, 0.2, 1),
    '050': hsla(200, 0, 0.24, 1),
    '060': hsla(200, 0, 0.28, 1),
    '070': hsla(200, 0, 0.36, 1),
    '080': hsla(200, 0, 0.57, 1),
    '090': hsla(200, 0, 0.75, 1),
    '100': hsla(200, 0, 0.85, 1),
  },

  primary: {
    mute: setAlpha(0.1, '#0557FF'),
    dim: setAlpha(0.4, '#0557FF'),
    default: '#0557FF',
    lighten: '#4393F1',
  },

  green: {
    mute: setAlpha(0.1, '#5ec169'),
    dim: setAlpha(0.4, '#5ec169'),
    default: '#47a251',
    lighten: '#5ec169',
  },

  red: {
    mute: setAlpha(0.1, '#bf2230'),
    dim: setAlpha(0.4, '#bf2230'),
    default: '#bf2230',
    lighten: '#d42837',
  },

  yellow: {
    mute: setAlpha(0.1, '#F3D900'),
    dim: setAlpha(0.4, '#F3D900'),
    default: '#F3D900',
    lighten: '#F3D900',
  },

  blue: {
    mute: setAlpha(0.1, '#306fb8'),
    dim: setAlpha(0.4, '#306fb8'),
    default: '#306fb8',
    lighten: '#4898F4',
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: darkColors.purple,
};

export const DarkKeyrockTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkKR',

  fontFamily: "'Manrope', sans-serif",
  fontUrl:
    'https://fonts.googleapis.com/css2?family=Manrope:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',

  spacingLayout: 8,

  backgroundHeader: colors.gray.main,
  borderColorHeader: colors.gray.main,

  backgroundInput: colors.gray.main,
});
