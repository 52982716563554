import { MarketTypeEnum, TreasuryLinkDirectionEnum, TreasuryLinkTypeEnum, type UpdateActionEnum } from './types';

export enum TreasuryLinkStatusEnum {
  Active = 'Active',
  Inactive = 'Inactive',
}

export type TreasuryLinkSourceOrDestinationField = keyof Pick<
  TreasuryLink,
  'SourceMarketAccount' | 'DestinationMarketAccount' | 'DestinationAddress'
>;

export function getOppositeSourceOrDestinationField(
  sourceOrDestinationField: TreasuryLinkSourceOrDestinationField
): TreasuryLinkSourceOrDestinationField {
  return sourceOrDestinationField === 'DestinationMarketAccount' ? 'SourceMarketAccount' : 'DestinationMarketAccount';
}

export enum TreasuryLinkTransactionType {
  Withdrawals = 'Withdrawals',
  Deposits = 'Deposits',
}

/**
 * Given a link type (customer, exchange, otc) and a "transaction type", (withdrawal, deposit, or undefined),
 * deduces what direction should be placed on treasury links in this situation.
 */
export function getTreasuryLinkDirection(
  linkType: TreasuryLinkTypeEnum,
  transactionType: TreasuryLinkTransactionType | undefined
): TreasuryLinkDirectionEnum {
  // Customer transfers are both push and pull for now.
  if (linkType === TreasuryLinkTypeEnum.CustomerTransfer) {
    return transactionType === TreasuryLinkTransactionType.Deposits
      ? TreasuryLinkDirectionEnum.Inbound
      : TreasuryLinkDirectionEnum.Outbound;
  } else {
    // Non-customer transfers are only push for now (outbound), but will change later.
    return TreasuryLinkDirectionEnum.Outbound;
  }
}

export function marketTypeToTreasuryLinkType(marketType: MarketTypeEnum): TreasuryLinkTypeEnum | undefined {
  switch (marketType) {
    case MarketTypeEnum.Exchange:
      return TreasuryLinkTypeEnum.ExchangeRebalance;
    case MarketTypeEnum.Dealer:
      return TreasuryLinkTypeEnum.OTCSettlement;
    default:
      return undefined;
  }
}

/**
 * Creates a Treasury link specificness key.
 *
 * The treasury link specificness key is used to look up treasury links of certain levels of specificness
 * in a structured way.
 */
export function getTreasuryLinkSpecificnessKey({
  MarketAccount,
  Type,
  Currency,
  Direction,
}: Partial<TreasuryLink>): string {
  return `${MarketAccount}-${Type}-${Currency}-${Direction}`;
}

export enum TreasuryLinkSpecificness {
  Type, // least specific
  Currency,
  MarketAccount,
  MarketAccountAndCurrency, // most specific
}

export function getTreasuryLinkSpecificness(link: TreasuryLink): TreasuryLinkSpecificness {
  if (link.MarketAccount != null && link.Currency != null) {
    return TreasuryLinkSpecificness.MarketAccountAndCurrency;
  }

  if (link.MarketAccount != null) {
    return TreasuryLinkSpecificness.MarketAccount;
  }

  if (link.Currency != null) {
    return TreasuryLinkSpecificness.Currency;
  }

  return TreasuryLinkSpecificness.Type;
}

/**
 * The TreasuryLink class encapsulates a complex business model.
 *
 * We support three types - ExchangeRebalance, CustomerTransfer, OTCSettlement. Depending on the type on the link,
 * the semantics around the link's property will change. Logic inside and revolving the TreasuryLink class will change slightly
 * depending on what the type is. There is an argument to be made that the TreasuryLink monolith concept should really be split
 * into three distinct entities - CustomerTreasuryLink, OTCTreasuryLink, ExchangeTreasuryLink. But here we are.
 *
 * Key to the TreasuryLink model to understand right away is that there is an inherent fallback structure within. These are
 * conceptualized as "Specificness" levels in the frontend. There are four Specificness levels:
 * 1. Global (meaning all MarketAccounts)
 * 2. Global + Currency specific
 * 3. MarketAccount specific
 * 4. MarketAccount + Currency specific
 *
 * The unique key of a TreasuryLink is the LinkID logically. The SpecificnessKey we generate is _not_ a unique key. That said, it is also
 * important to at this stage understand that in the business model, the SpecificnessKey will in all case but one be a unique key. The only
 * case in which the SpecificnessKey is not a unique key is for Customer Deposits at the MarketAccount+Currency Specificness Level. There
 * can be several links defined in this case.
 *
 * In all other cases (as of 2.45.1), there will only be one TreasuryLink per SpecificnessKey. That said, we still want to
 * model the different specificness levels and types uniformly.
 */
export class TreasuryLink {
  Timestamp?: string;
  Status!: TreasuryLinkStatusEnum;
  Currency?: string;
  MarketAccount?: string;
  DestinationMarketAccount?: string;

  /**
   * A TreasuryLink's DestinationAddress is only relevant for Customer Deposits. A TreasuryLink is for Customer Deposits if it is of
   * direction Inbound and of type CustomerTransfer. Furthermore, DestinationAddress can only be set on a TreasuryLink at the
   * MarketAccount + Currency specificness level (which is the most specific level).
   */
  DestinationAddress?: string;
  SourceMarketAccount?: string;
  Type!: TreasuryLinkTypeEnum;
  Direction!: TreasuryLinkDirectionEnum;
  LinkID!: string;
  UpdateAction?: UpdateActionEnum;

  /**
   * The treasury link specificness key is used to look up treasury links of certain levels of specificness
   * in a structured way.
   */
  get specificnessKey() {
    return getTreasuryLinkSpecificnessKey({
      MarketAccount: this.MarketAccount,
      Type: this.Type,
      Currency: this.Currency,
      Direction: this.Direction,
    });
  }

  get transactionType() {
    return this.Direction === TreasuryLinkDirectionEnum.Outbound
      ? TreasuryLinkTransactionType.Withdrawals
      : this.Direction === TreasuryLinkDirectionEnum.Inbound
      ? TreasuryLinkTransactionType.Deposits
      : undefined;
  }

  get getMarketAccountOrWildcard() {
    return `${this.MarketAccount ?? '*'}`;
  }

  clone(): TreasuryLink {
    return new TreasuryLink(this);
  }

  constructor(data: RawTreasuryLink) {
    Object.assign(this, data);
  }
}

export type RawTreasuryLink = Omit<
  TreasuryLink,
  'specificnessKey' | 'getMarketAccountOrWildcard' | 'transactionType' | 'clone'
>;

/**
 * Given an array of RawTreasuryLink, build a 2-levels nested map of SpecificnessKey -> LinkID -> Link
 */
export function buildTreasuryLinksBySpecificnessKeyMap(links: RawTreasuryLink[]) {
  const linkInstances = links.map(link => new TreasuryLink(link));
  const map = new Map<string, Map<string, TreasuryLink>>();
  for (const link of linkInstances) {
    const specificnessMap = map.get(link.specificnessKey) ?? new Map();
    specificnessMap.set(link.LinkID, link);
    map.set(link.specificnessKey, specificnessMap);
  }

  return map;
}
