import type { AnchorHTMLAttributes } from 'react';
import type { LinkProps } from 'react-router-dom';
import styled from 'styled-components';

import { Icon } from '../../Icons';
import { NavButtonWrapper, type ButtonProps } from '../styles';

export const NavButton = styled(
  ({
    children,
    startIcon,
    endIcon,
    to,
    ...props
  }: ButtonProps<AnchorHTMLAttributes<HTMLAnchorElement>> & { to?: LinkProps['to'] }) => (
    <NavButtonWrapper to={props.disabled ? '#' : (to as string)} {...props}>
      {!!startIcon && <Icon icon={startIcon} />}
      {children && <span>{children}</span>}
      {!!endIcon && <Icon icon={endIcon} />}
    </NavButtonWrapper>
  )
)``;
