import type { PropsWithChildren } from 'react';
import { createContext, useContext } from 'react';
import { TabPanels, Tabs, TabSize, useTabs, type UseTabs } from '../Tabs';

export const EntityAdminPageTabsContext = createContext<{ tabs: UseTabs<{ id: string; label: string }> } | undefined>(
  undefined
);
EntityAdminPageTabsContext.displayName = 'EntityAdminPageTabsContext';

export function useEntityAdminPageTabsContext() {
  const context = useContext(EntityAdminPageTabsContext);
  // Okay for this context to return undefined.
  return context;
}

export const EntityAdminPageTabsWrapper = ({
  tabItems,
  children,
}: PropsWithChildren<{ tabItems: { id: string; label: string }[] }>) => {
  const tabs = useTabs({ initialItems: tabItems });

  return (
    <EntityAdminPageTabsContext.Provider value={{ tabs }}>
      <Tabs h="100%" {...tabs} size={TabSize.Large} background="backgroundContent">
        <TabPanels flex="1" overflow="hidden">
          {children}
        </TabPanels>
      </Tabs>
    </EntityAdminPageTabsContext.Provider>
  );
};
