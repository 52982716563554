import { forwardRef, type ReactNode } from 'react';
import { useElementId } from '../../../hooks';
import { Flex } from '../../Core';
import { FormControlSizes, type FormControlProps } from '../types';
import { MultiSelectInputWrapper } from './styles';

export const MultiSelectInput = forwardRef<HTMLInputElement, FormControlProps<HTMLInputElement> & { items: ReactNode }>(
  (
    {
      style,
      className,
      prefix,
      suffix,
      invalid,
      touched,
      disabled,
      value,
      type,
      inline,
      width,
      autoComplete = 'off',
      autoCorrect = 'off',
      autoCapitalize = 'off',
      spellCheck = false,
      wrapperRef,
      controlStyle,
      size = FormControlSizes.Default,
      placeholder,
      items,
      ...props
    },
    ref
  ) => {
    const id = useElementId(props.id ?? 'MultiSelect');
    return (
      <MultiSelectInputWrapper
        style={style}
        className={className}
        type={type}
        invalid={invalid}
        touched={touched}
        disabled={disabled}
        ref={wrapperRef}
        inline={inline}
        width={width}
        size={size}
        htmlFor={props.id ?? id('input')}
      >
        {prefix}
        <Flex gap="spacingTiny" flexWrap="wrap" flex={1}>
          {items}
          <input
            {...props}
            placeholder={placeholder}
            ref={ref}
            value={value}
            disabled={disabled}
            autoComplete={autoComplete}
            autoCorrect={autoCorrect}
            autoCapitalize={autoCapitalize}
            spellCheck={spellCheck}
            type="text"
            style={controlStyle}
            id={props.id ?? id('input')}
          />
        </Flex>
        {suffix}
      </MultiSelectInputWrapper>
    );
  }
);
