import type { ICellRendererParams, ValueFormatterParams } from 'ag-grid-enterprise';
import { transparentize } from 'polished';
import { MarketAccountStatusEnum } from '../../../types';
import { HStack } from '../../Core';
import { Icon, IconName } from '../../Icons';
import { IndicatorBadge } from '../../IndicatorBadge';
import { Text } from '../../Text';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

/** Render Market Account Trading status with:
 * - custom mapping of Active state to Enabled label
 * - custom cellRenderer for Active and Disabled state showing appropriate icons */
export const marketAccountTradingStatus: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  valueFormatter: ({ value }: ValueFormatterParams<unknown, MarketAccountStatusEnum>) => {
    if (value == null) {
      return '';
    }
    switch (value) {
      case MarketAccountStatusEnum.Active:
        return 'Enabled';
      default:
        return value;
    }
  },
  cellRenderer: ({ value, valueFormatted, context }: ICellRendererParams<unknown, MarketAccountStatusEnum>) => {
    const theme = context.current.theme!;

    switch (value) {
      case MarketAccountStatusEnum.Active:
        return (
          <IndicatorBadge
            background={theme.backgroundPrimaryButton}
            children={
              <HStack gap="spacingSmall">
                <Icon icon={IconName.CheckCheckbox} color={theme.colorTextPrimaryButton} />
                <Text color="colorTextPrimaryButton">{valueFormatted}</Text>
              </HStack>
            }
          />
        );
      case MarketAccountStatusEnum.Disabled:
        return (
          <IndicatorBadge
            background={transparentize(0.92, theme.colors.gray['100'])}
            children={
              <HStack gap="spacingSmall">
                <Icon icon={IconName.Close} color={theme.colors.gray['090']} />
                <Text color={theme.colors.gray['090']}>{valueFormatted}</Text>
              </HStack>
            }
          />
        );
      default:
        return valueFormatted;
    }
  },
});
