import type Fuse from 'fuse.js';
import { useMemo } from 'react';
import { useTheme } from 'styled-components';

import { defineMessages } from 'react-intl';
import { useBalancesByMarketAccountCurrency } from '../../contexts/BalancesContext';
import { useGetCustomerMarketAccountLabel } from '../../hooks/useGetCustomerMarketAccountLabel';
import type { CustomerMarketAccount, Security } from '../../types';
import { isCFD } from '../../utils/security';
import { Box, HStack, VStack } from '../Core';
import type { FuseSearchObject } from '../Form';
import { InlineFormattedNumber } from '../FormattedNumber';
import { FuseResultMatch, FuseResultMatchEnum } from '../FuseResultMatch';
import { FormattedMessage } from '../Intl';
import { Text } from '../Text';

const messages = defineMessages({
  maxSellAvailable: {
    defaultMessage: 'Max Sell Available',
    id: 'CustomerMarketAccountSelector.maxSellAvailable',
  },
  unableToRetrieveBalances: {
    defaultMessage: 'Unable to retrieve balances',
    id: 'CustomerMarketAccountSelector.unableToRetrieveBalances',
  },
});

export function useCustomerMarketAccountResults<T extends CustomerMarketAccount>(security: Security | null) {
  const { backgroundContent } = useTheme();
  const { BaseCurrency, DefaultPriceIncrement, DefaultSizeIncrement, QuoteCurrency } = security ?? {};
  const balancesByMarketAccountCurrency = useBalancesByMarketAccountCurrency();
  const getMarketAccountLabel = useGetCustomerMarketAccountLabel();

  return useMemo(() => {
    return function CustomerMarketAccountResult(searchResult: Fuse.FuseResult<FuseSearchObject<T>>, disabled: boolean) {
      const labelMatch = searchResult.matches && searchResult.matches.find(match => match.key === 'label');
      const anyMatches = labelMatch?.indices && labelMatch.indices.length > 0;
      // searchResult.item itself has an 'item' prop that is the CustomerMarketAccount used for this result.
      const { SourceAccountID } = searchResult.item.item;
      const label = getMarketAccountLabel(SourceAccountID);

      // [DEAL-3965] The label is no longer guaranteed to be the SourceAccountID and cannot be used to retrieve balances.
      const baseBalance = BaseCurrency
        ? balancesByMarketAccountCurrency?.get(SourceAccountID)?.get(BaseCurrency)
        : undefined;
      const quoteBalance = QuoteCurrency
        ? balancesByMarketAccountCurrency?.get(SourceAccountID)?.get(QuoteCurrency)
        : undefined;
      const hasBalances = baseBalance || quoteBalance;

      return (
        <VStack w="100%" justifyContent="space-between" gap="spacingSmall">
          <Box
            w="100%"
            data-testid={`Result-${SourceAccountID}`}
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
            title={label}
          >
            <FuseResultMatch
              label={label}
              indices={labelMatch?.indices}
              highlightAll={!anyMatches}
              type={FuseResultMatchEnum.Label}
              disabled={disabled}
            />
          </Box>
          {!isCFD(security!) && (
            <HStack
              justifyContent="space-between"
              w="100%"
              alignItems="flex-start"
              data-testid={`Balances-${SourceAccountID}`}
            >
              <Text whiteSpace="nowrap" color={hasBalances ? undefined : 'colorTextWarning'}>
                {hasBalances ? (
                  <FormattedMessage {...messages.maxSellAvailable} />
                ) : (
                  <FormattedMessage {...messages.unableToRetrieveBalances} />
                )}
              </Text>
              {hasBalances && (
                <HStack flexWrap="wrap" justifyContent="flex-end" gap="spacingSmall" fontSize="fontSizeSmall">
                  <Text whiteSpace="nowrap" data-testid={`BaseBalance-${SourceAccountID}`}>
                    {'~ '}
                    <InlineFormattedNumber
                      number={baseBalance?.AvailableAmount ?? '0'}
                      increment={DefaultSizeIncrement}
                      currency={BaseCurrency}
                      background={backgroundContent}
                      align="right"
                    />
                  </Text>
                  <Text whiteSpace="nowrap" data-testid={`QuoteBalance-${SourceAccountID}`}>
                    {'/ '}
                    <InlineFormattedNumber
                      number={quoteBalance?.AvailableAmount ?? '0'}
                      increment={DefaultPriceIncrement}
                      currency={QuoteCurrency}
                      background={backgroundContent}
                      align="right"
                    />
                  </Text>
                </HStack>
              )}
            </HStack>
          )}
        </VStack>
      );
    };
  }, [
    getMarketAccountLabel,
    BaseCurrency,
    balancesByMarketAccountCurrency,
    QuoteCurrency,
    security,
    DefaultSizeIncrement,
    backgroundContent,
    DefaultPriceIncrement,
  ]);
}
