import { defineMessages } from 'react-intl';
import styled, { css } from 'styled-components';
import type { IntlWithFormatter } from '../../contexts/IntlContext';
import { WARNING_SEVERITY_TO_TEXT, WarningSeverity } from '../../types/WarningSeverity';
import { Icon, type IconProps } from './Icon';
import { IconName } from './IconNames';

export const messages = defineMessages({
  warningSeverityTitle: {
    defaultMessage: '{severity, select, high {High} medium {Medium} other {Low}} warning severity',
    id: 'Icons.warningSeverityTitle',
  },
});

type WarningSeverityIconProps = { severity: WarningSeverity; title?: string } & Pick<IconProps, 'size' | 'spaceAfter'>;
export const WarningSeverityIcon = styled(({ title, severity, ...props }: WarningSeverityIconProps) => (
  <Icon {...props} title={title} icon={IconName.ExclamationSolid} />
))<WarningSeverityIconProps>`
  ${({ size, theme }) =>
    !size &&
    css`
      font-size: ${theme.fontSizeLg}rem;
    `}

  ${({ severity, theme }) => css`
    ${severity === WarningSeverity.HIGH &&
    css`
      color: ${theme.colorTextNegative};
    `}
    ${severity === WarningSeverity.MEDIUM &&
    css`
      color: ${theme.colorTextWarning};
    `}
    ${severity === WarningSeverity.LOW &&
    css`
      color: ${theme.colorTextDefault};
    `}
  `}
`;

export const prettyWarningSeverityTitle = (severity: WarningSeverity, intl: IntlWithFormatter): string | undefined => {
  if (severity > WarningSeverity.NONE) {
    return intl.formatMessage(messages.warningSeverityTitle, { severity: WARNING_SEVERITY_TO_TEXT[severity] });
  }
  return undefined;
};
