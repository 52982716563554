import { forwardRef, type MouseEventHandler, useCallback } from 'react';
import { defineMessages } from 'react-intl';
import { useTheme } from 'styled-components';
import { Button, ButtonVariants } from '../Button';
import { Box, HStack } from '../Core';
import { FormattedMessage } from '../Intl';
import { Modal } from '../Modal';
import { PopperHeader } from '../PopperHeader/PopperHeader';
import { Content } from './styles';
import type { DialogProps } from './types';

const messages = defineMessages({
  cancel: {
    defaultMessage: 'Cancel',
    id: 'Dialog.cancel',
  },
  confirm: {
    defaultMessage: 'OK',
    id: 'Dialog.confirm',
  },
});

export { Content, Header } from './styles';
export type { DialogProps } from './types';
const ACTION_BUTTON_MINWIDTH = '120px';

export const Dialog = forwardRef<HTMLDivElement | null, DialogProps>(
  (
    {
      onConfirm,
      onCancel,
      title,
      headerIcon,
      headerIconColor,
      dataTestId,
      children,
      cancelLabel,
      confirmLabel,
      customActions,
      showCancel = true,
      showConfirm = true,
      close,
      variant = ButtonVariants.Primary,
      confirmDisabled = false,
      confirmLoading = false,
      showClose,
      width,
      alignContent,
      contentOverflow = 'auto',
      onConfirmMouseOver,
      preventClosing = false,
      closeOnConfirm = true,
      closeOnCancel = true,
      stretchButtons = false,
      preview,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    const handleCancel: MouseEventHandler<HTMLButtonElement> = useCallback(
      e => {
        onCancel && onCancel(e);
        if (closeOnCancel) {
          close();
        }
      },
      [close, onCancel, closeOnCancel]
    );

    const handleConfirm: MouseEventHandler<HTMLButtonElement> = useCallback(
      async e => {
        try {
          onConfirm && (await onConfirm(e));
          if (closeOnConfirm) {
            close();
          }
        } catch {
          // Do nothing
        }
      },
      [close, onConfirm, closeOnConfirm]
    );

    const hasPreview = !!preview;
    const previewStyles: React.CSSProperties = hasPreview
      ? {
          overflow: 'hidden',
          position: 'relative',
        }
      : {};

    return (
      <Modal
        data-testid={dataTestId ?? 'dialog'}
        close={close}
        width={width}
        preventClosing={preventClosing}
        {...props}
        style={{ maxHeight: props.style?.maxHeight ?? '90vh', ...previewStyles }}
        ref={ref}
      >
        <Content style={{ order: 2 }} align={alignContent} overflow={contentOverflow}>
          {children}
        </Content>

        <Box style={{ order: 3 }}>
          {customActions
            ? customActions
            : (showCancel || showConfirm) && (
                <HStack
                  justifyContent="flex-end"
                  gap="spacingComfortable"
                  p="spacingMedium"
                  w="100%"
                  borderTop={`1px solid ${theme.backgroundDivider}`}
                >
                  {showCancel && (
                    <Button
                      data-testid="dialog-cancel"
                      disabled={preventClosing}
                      onClick={handleCancel}
                      flex={stretchButtons ? '1' : undefined}
                      style={{ minWidth: ACTION_BUTTON_MINWIDTH }}
                    >
                      {cancelLabel ?? <FormattedMessage {...messages.cancel} />}
                    </Button>
                  )}
                  {showConfirm && (
                    <Button
                      variant={variant}
                      data-testid="dialog-confirm"
                      onClick={handleConfirm}
                      disabled={confirmLoading || confirmDisabled || preventClosing}
                      loading={confirmLoading}
                      onMouseOver={onConfirmMouseOver}
                      flex={stretchButtons ? '1' : undefined}
                      style={{ minWidth: ACTION_BUTTON_MINWIDTH }}
                    >
                      {confirmLabel ?? <FormattedMessage {...messages.confirm} />}
                    </Button>
                  )}
                </HStack>
              )}
        </Box>

        {/* We choose to render the header at the bottom of the file, but then order it back to the top in css, in order to avoid
          the close button being auto-focused by the modal (because otherwise it'd be the first focusable element) */}
        {(title || showClose) && (
          <PopperHeader
            boxProps={{ style: { order: 1 } }}
            title={title}
            onCloseClicked={showClose ? close : undefined}
            closeDisabled={preventClosing}
            icon={headerIcon}
            iconColor={headerIconColor}
          />
        )}

        {preview}
      </Modal>
    );
  }
);
