import { useCallback, useEffect, useMemo, useState, type PropsWithChildren } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { eraseCookie, parseCookie, readCookie, urlFromLocalOrAbsolute } from '../utils';

export interface AuthSession {
  expires_at: number;
}

export interface AuthContextProviderProps {
  session?: AuthSession;
  loginUrl: string;
  logoutUrl: string;
  sessionCookieName: string;
}

export function AuthContextProvider({
  children,
  session: testSessionInstance,
  loginUrl,
  logoutUrl,
  sessionCookieName,
}: PropsWithChildren<AuthContextProviderProps>) {
  const [isAuthenticated, setAuthenticated] = useState(false);

  const login = useCallback(() => {
    const newUrl = new URL(urlFromLocalOrAbsolute(loginUrl));
    newUrl.searchParams.set('next', window.location.href);
    window.location.replace(urlFromLocalOrAbsolute(newUrl.toString()));
  }, [loginUrl]);

  const logout = useCallback(() => {
    eraseCookie(sessionCookieName);
    window.location.replace(urlFromLocalOrAbsolute(logoutUrl));
  }, [logoutUrl, sessionCookieName]);

  const canRenderChildren = useMemo(() => {
    return isAuthenticated || window.location.pathname === '/unauthorized';
  }, [isAuthenticated]);

  useEffect(() => {
    const cookieValue = readCookie(sessionCookieName);
    if (cookieValue == null && testSessionInstance == null) {
      if (window.location.pathname !== '/unauthorized') {
        login();
      }
    } else {
      const session: AuthSession = testSessionInstance ?? parseCookie(cookieValue);
      const expiresAt = session.expires_at * 1000;
      const isAuthenticated = expiresAt >= new Date().getTime();
      if (!isAuthenticated) {
        logout();
      } else {
        setAuthenticated(true);
      }
    }
  }, [login, logout, sessionCookieName, testSessionInstance]);

  const value = useMemo(() => ({ isAuthenticated, logout }), [isAuthenticated, logout]);

  return <AuthContext.Provider value={value}>{canRenderChildren && children}</AuthContext.Provider>;
}
