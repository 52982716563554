import { createContext, useContext, type PropsWithChildren } from 'react';

// By default, watchlist is assumed to be visible.
const WatchlistVisibilityContext = createContext<boolean>(true);

/**
 * Hook to get the current visibility state of the watchlist.
 * By default, the watchlist is assumed to be visible.
 */
export const useWatchlistVisibility = () => {
  return useContext(WatchlistVisibilityContext);
};

/**
 * Provider that returns state of whether the watchlist is visible or not, which can be used for
 * determining if subscriptions to watchlist data should be paused or active.
 */
export function WatchlistVisibilityProvider({ children, isVisible }: PropsWithChildren<{ isVisible: boolean }>) {
  return <WatchlistVisibilityContext.Provider value={isVisible}>{children}</WatchlistVisibilityContext.Provider>;
}
