import styled, { css } from 'styled-components';

import { animated } from '@react-spring/web';
import type { ReMap } from '../../utils/types';
import type { PopoverContentProps } from './types';
import { PopoverVariant } from './types';

type Props<Keys extends keyof PopoverContentProps> = ReMap<Pick<PopoverContentProps, Keys>>;
export const Target = styled.span`
  display: inherit;
  height: fit-content;
  &:focus,
  &:active {
    outline: none;
  }
`;

function getContentBackground(variant?: PopoverVariant) {
  switch (variant) {
    case PopoverVariant.Primary:
      return 'var(--colors-primary-default)';
    case PopoverVariant.Positive:
      return 'var(--colors-green-dim)';
    case PopoverVariant.Negative:
      return 'var(--colors-red-dim)';
    case PopoverVariant.Warning:
      return 'var(--colors-yellow-dim)';
    default:
      return 'var(--backgroundPopover)';
  }
}

export const Content = styled.div.withConfig<
  Props<'isSmall' | 'overflow' | 'placement' | 'overflowY' | 'noPaddingAndBorder' | 'variant'>
>({
  shouldForwardProp: (prop, validator) => prop !== 'isSmall' && validator(prop),
})`
  background: ${({ variant }) => getContentBackground(variant)};
  border: solid 1px ${({ theme }) => theme.borderColorPopover};
  padding: ${({ theme }) => theme.spacingDefault}px;
  font-weight: ${({ theme }) => theme.fontWeightRegular};
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  box-shadow: 0 1px 5px hsla(0, 0%, 0%, 0.1), 0 1px 15px hsla(0, 0%, 0%, 0.05);
  white-space: normal;
  text-align: left;
  line-height: normal;
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  ${({ overflow, overflowY = 'auto', placement, isSmall, theme }) => {
    return (
      overflow?.top &&
      `overflow-y: ${overflowY}; max-height: min(100vh, calc(100vh - ${Math.abs(overflow.top)}px ${
        placement?.startsWith('top') || placement?.startsWith('bottom') ? (isSmall ? `- 10px` : `- 12px`) : ''
      } - ${theme.spacingDefault * 2}px));`
    );
  }}
  ${({ noPaddingAndBorder }) =>
    noPaddingAndBorder &&
    css`
      border: unset; // this will overwrite above.
      padding: 0px; // this will overwrite above.
    `}
`;

export const Wrapper = styled(animated.div).withConfig<Props<'isSmall' | 'placement'>>({
  shouldForwardProp: (prop, validator) => prop !== 'isSmall' && validator(prop),
})`
  ${({ theme, isSmall, placement }) => {
    return isSmall
      ? `
    ${placement?.startsWith('top') && `padding-bottom: 10px`};
    ${placement?.startsWith('right') && `padding-left: 10px`};
    ${placement?.startsWith('bottom') && `padding-top: 10px`};
    ${placement?.startsWith('left') && `padding-right: 10px`};
    ${Content} {
      background: ${theme.backgroundPopover};
      padding: ${theme.spacingSmall}px;
    }
    `
      : `
    ${placement?.startsWith('top') && `padding-bottom: ${theme.spacingSmall}px`};
    ${placement?.startsWith('right') && `padding-left: ${theme.spacingSmall}px`};
    ${placement?.startsWith('bottom') && `padding-top: ${theme.spacingSmall}px`};
    ${placement?.startsWith('left') && `padding-right: ${theme.spacingSmall}px`};
    `;
  }}
`;
