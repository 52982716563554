// THIS FILE WAS GENERATED BY cryptoicons-parser.ts, DO NOT EDIT MANUALLY.
export const CURRENCY_COLORS = {
  $pac: '#F5EB16',
  '0xbtc': '#FF914D',
  '1inch': '#D82122',
  '2give': '#F1CB60',
  aave: '#2EBAC6',
  abt: '#3EFFFF',
  act: '#767DFF',
  actn: 'red',
  ada: '#0033AD',
  add: '#FEC807',
  adx: '#1B75BC',
  ae: '#DE3F6B',
  aeon: '#134451',
  aeur: '#051D2D',
  agi: '#6916ff',
  agrs: '#f49e00',
  aion: '#00BFEC',
  algo: '#000',
  amb: '#3c5be0',
  amp: '#2DAEE4',
  ampl: '#000',
  ankr: '#2E6BF6',
  ant: '#2CD3E1',
  ape: '#0054F9',
  apex: '#1F4C9F',
  appc: '#fd875e',
  ardr: '#3C87C7',
  arg: '#A71435',
  ark: '#F70000',
  arn: '#0092b5',
  arnx: '#436697',
  ary: '#343434',
  ast: '#0061ff',
  atlas: '#31FAFB',
  atm: '#346fce',
  atom: '#2E3148',
  audr: '#34318A',
  aury: '#E756CC',
  auto: '#FAB431',
  avax: '#E84142',
  aywa: '#3355B5',
  bab: '#F19F13',
  bal: '#1E1E1E',
  band: '#516AFF',
  bat: '#FF5000',
  bay: '#6356ab',
  bcbc: '#004ab5',
  bcc: '#F7931C',
  bcd: '#fcc339',
  bch: '#8dc351',
  bcio: '#3F43AD',
  bcn: '#F04086',
  bco: '#2c76b7',
  bcpt: '#404040',
  bdl: '#E54C40',
  beam: '#0B76FF',
  bela: '#13a0f6',
  bix: '#000',
  blcn: '#2aabe4',
  blk: '#181818',
  block: '#101341',
  blz: '#18578c',
  bnb: '#F0B90B',
  bnt: '#000D2B',
  bnty: '#fd7a3d',
  booty: '#00B4F4',
  bos: '#00A8D6',
  bpt: '#0f63d8',
  bq: '#1D1D1D',
  brd: '#fe5d86',
  bsd: '#000',
  bsv: '#EAB304',
  btc: '#F7931A',
  btcd: '#F60',
  btch: '#4700C2',
  btcp: '#272d63',
  btcz: '#F8C24A',
  btdx: '#0AF',
  btg: '#EBA809',
  btm: '#504C4C',
  bts: '#35BAEB',
  btt: '#000',
  btx: '#FB2EA3',
  burst: '#2d2d2d',
  bze: '#00AEEF',
  call: '#FBB413',
  cc: '#36B0F3',
  cdn: '#f70808',
  cdt: '#272731',
  cenz: '#3089F2',
  chain: '#00ACED',
  chat: '#1C98F7',
  chips: '#598182',
  chsb: '#01C38D',
  chz: '#CD0124',
  cix: '#0576B4',
  clam: '#20c5d3',
  cloak: '#ff3a00',
  cmm: '#2FD2E5',
  cmt: '#c1a05c',
  cnd: '#383939',
  cnx: '#4c6bae',
  cny: '#ff4314',
  cob: '#13bf99',
  colx: '#77C3B0',
  comp: '#00D395',
  coqui: '#71C800',
  cred: '#37e8a3',
  crpt: '#00bdcd',
  crv: '#40649F',
  crw: '#0F1529',
  cs: '#262626',
  ctr: '#fdde6c',
  ctxc: '#000',
  cvc: '#3AB03E',
  d: '#B8B8B8',
  dai: '#F4B731',
  dash: '#008CE7',
  dat: '#2d9cdb',
  data: '#E9570F',
  dbc: '#5bc1d4',
  dcn: '#136485',
  dcr: '#2ED6A1',
  deez: '#939393',
  dent: '#666',
  dew: '#fec907',
  dgb: '#006AD2',
  dgd: '#F4D029',
  dlt: '#f4ae95',
  dnt: '#2c398f',
  dock: '#786DBC',
  doge: '#C3A634',
  dot: '#E6007A',
  drgn: '#C91111',
  drop: '#242D3D',
  dta: '#74d269',
  dth: '#3C80F1',
  dtr: '#121747',
  ebst: '#1693d4',
  eca: '#AA15DD',
  edg: '#2B1544',
  edo: '#242424',
  edoge: '#0FACF3',
  ela: '#3FBADF',
  elec: '#F90',
  elf: '#2b5ebb',
  elix: '#00aded',
  ella: '#396a28',
  emb: '#F6C054',
  emc: '#B49FFC',
  emc2: '#0CF',
  eng: '#2f2f2f',
  enj: '#624dbf',
  entrp: '#FA5836',
  eon: '#443F54',
  eop: '#35A7DF',
  eos: '#000',
  eqli: '#C9A35E',
  equa: '#F68922',
  etc: '#328332',
  eth: '#454A75',
  ethos: '#00FFBA',
  etn: '#23BEE2',
  etp: '#00A5FF',
  eur: '#0f8ff8',
  evx: '#044aac',
  exmo: '#347FFB',
  exp: '#FFAA5C',
  fair: '#C99705',
  fct: '#417BA4',
  fida: '#7C7CFF',
  fil: '#42C1CA',
  fjc: '#00AFEC',
  fldc: '#c40e09',
  flo: '#2080a2',
  flux: '#2B61D1',
  fsn: '#1D9AD7',
  ftc: '#27323a',
  fuel: '#4096d0',
  fun: '#ed1968',
  game: '#2d475b',
  gas: '#58BF00',
  gbp: '#bc3fe0',
  gbx: '#1666af',
  gbyte: '#302C2C',
  generic: '#efb914',
  gin: '#008DDE',
  glxt: '#005396',
  gmr: '#372D2C',
  gmt: '#DBB358',
  gno: '#00A6C4',
  gnt: '#001D57',
  gold: '#F1B32B',
  grc: '#5411B3',
  grin: '#FFF300',
  grs: '#377E96',
  grt: '#5942CC',
  gsc: '#FF0060',
  gto: '#7f27ff',
  gup: '#37DCD8',
  gusd: '#00DCFA',
  gvt: '#16b9ad',
  gxs: '#35A5F3',
  gzr: '#56C9E9',
  hight: '#117FC0',
  hns: '#000',
  hodl: '#D59143',
  hot: '#8834FF',
  hpb: '#1591ca',
  hsr: '#56428E',
  ht: '#2A3069',
  html: '#cfa967',
  huc: '#ffc018',
  husd: '#005FFA',
  hush: '#292929',
  icn: '#4C6F8C',
  icp: '#292A2E',
  icx: '#1fc5c9',
  ignis: '#f9c011',
  ilk: '#98C23A',
  ink: '#df1a14',
  ins: '#b2a3f6',
  ion: '#57beea',
  iop: '#0CAFA5',
  iost: '#1c1c1c',
  iotx: '#00D4D5',
  iq: '#5DF',
  itc: '#102044',
  jnt: '#0050db',
  jpy: '#eac749',
  kcs: '#23AF91',
  kin: '#005fff',
  klown: '#EA0017',
  knc: '#31CB9E',
  krb: '#00AEEF',
  ksm: '#000',
  lbc: '#006149',
  lend: '#0fa9c9',
  leo: '#11021E',
  link: '#375BD2',
  lkk: '#9D01EB',
  loom: '#48BEFF',
  lpt: '#000',
  lrc: '#2ab6f6',
  lsk: '#0d4ea0',
  ltc: '#345D9D',
  lun: '#f55749',
  maid: '#5592D7',
  mana: '#FF2D55',
  matic: '#6F41D8',
  max: '#2D4692',
  mcap: '#033B4A',
  mco: '#103F68',
  mda: '#01a64f',
  mds: '#1e252c',
  med: '#00B0FF',
  meetone: '#000',
  mft: '#DA1157',
  miota: '#242424',
  mith: '#00316D',
  mkr: '#1AAB9B',
  mln: '#0B1529',
  mnx: '#00ADEF',
  mnz: '#7F368A',
  moac: '#000',
  mod: '#09547d',
  mona: '#DEC799',
  msr: '#47B95C',
  mth: '#104fca',
  mtl: '#1E1F25',
  music: '#FFF',
  mzc: '#FFAA05',
  nano: '#209CE9',
  nas: '#222',
  nav: '#7D59B5',
  ncash: '#36a9cf',
  ndz: '#622fba',
  nebl: '#50479E',
  neo: '#58BF00',
  neos: '#e5f300',
  neu: '#B3BA00',
  nexo: '#1A4199',
  ngc: '#f80000',
  nio: '#70C9C9',
  nkn: '#23336F',
  nlc2: '#f28f01',
  nlg: '#2AB0FD',
  nmc: '#186C9D',
  nmr: '#050708',
  npxs: '#F5D100',
  ntbc: '#EEC315',
  nuls: '#82bd39',
  nxs: '#4099CD',
  nxt: '#008FBB',
  oax: '#164b79',
  ok: '#000',
  omg: '#101010',
  omni: '#1c347a',
  one: '#00AEE9',
  ong: '#000',
  ont: '#32a4be',
  oot: '#25AAE1',
  ost: '#34445B',
  ox: '#4392cd',
  oxt: '#5F45BA',
  oxy: '#2261F6',
  part: '#65CB8D',
  pasc: '#F7931E',
  pasl: '#00acff',
  pax: '#398260',
  paxg: '#E4CE4D',
  pay: '#302C2C',
  payx: '#630',
  pink: '#ed79aa',
  pirl: '#96b73d',
  pivx: '#5E4778',
  plr: '#00bfff',
  poa: '#444fa1',
  poe: '#dcd6cc',
  polis: '#2C3E50',
  poly: '#4c5a95',
  pot: '#105B2F',
  powr: '#05BCA9',
  ppc: '#3CB054',
  ppp: '#348f8d',
  ppt: '#152743',
  pre: '#3A8CBD',
  prl: '#1061e3',
  pungo: '#22B573',
  pura: '#333',
  qash: '#1347E8',
  qiwi: '#FF8C00',
  qlc: '#610089',
  qnt: '#000',
  qrl: '#252525',
  qsp: '#454545',
  qtum: '#2E9AD0',
  r: '#771a4e',
  rads: '#9d4bef',
  rap: '#000',
  ray: '#6C45DC',
  rcn: '#3555f9',
  rdd: '#E30613',
  rdn: '#2A2A2A',
  ren: '#080817',
  rep: '#602A52',
  repv2: '#0E0E21',
  req: '#00E6A0',
  rhoc: '#CC1E46',
  ric: '#60e4dd',
  rise: '#f49352',
  rlc: '#FFD800',
  rpx: '#8d181b',
  rub: '#64d1ff',
  rvn: '#384182',
  ryo: '#3D58B0',
  safe: '#00688C',
  safemoon: '#00A79D',
  sai: '#B68900',
  salt: '#1BEEF4',
  san: '#2B77B3',
  sand: '#04ADEF',
  sbd: '#4ba2f2',
  sberbank: '#48B254',
  sc: '#20EE82',
  ser: '#6DC6C1',
  shift: '#964B9C',
  sib: '#057BC1',
  sin: '#F5342E',
  skl: '#000',
  sky: '#0072FF',
  slr: '#FDA616',
  sls: '#8e9495',
  smart: '#FEC60D',
  sngls: '#B30D23',
  snm: '#0b1c26',
  snt: '#5B6DEE',
  snx: '#5FCDF9',
  soc: '#199248',
  sol: '#9945FF',
  spacehbit: '#0971FE',
  spank: '#ff3b81',
  sphtx: '#00b098',
  srn: '#1c1c1c',
  stak: '#F2941B',
  start: '#01aef0',
  steem: '#4BA2F2',
  storj: '#2683FF',
  storm: '#080d98',
  stox: '#7324F0',
  stq: '#2DC4E7',
  strat: '#1387C9',
  stx: '#5546FF',
  sub: '#E53431',
  sumo: '#2D9CDB',
  sushi: '#D65892',
  sys: '#0082c6',
  taas: '#002342',
  tau: '#7b346e',
  tbx: '#5244D4',
  tel: '#14c8ff',
  ten: '#0899CD',
  tern: '#F4C257',
  tgch: '#434247',
  theta: '#2ab8e6',
  tix: '#ef494d',
  tkn: '#24DD7B',
  tks: '#895AF8',
  tnb: '#ffc04e',
  tnc: '#ff439b',
  tnt: '#ff4081',
  tomo: '#1A1F36',
  tpay: '#3058A6',
  trig: '#30c0f2',
  trtl: '#00843D',
  trx: '#EF0027',
  tusd: '#2B2E7F',
  tzc: '#374851',
  ubq: '#00EA90',
  uma: '#FF4A4A',
  uni: '#FF007A',
  unity: '#f58634',
  usd: '#6C9A44',
  usdc: '#3E73C4',
  usdt: '#009393',
  utk: '#30367a',
  veri: '#F93',
  vet: '#15BDFF',
  via: '#565656',
  vib: '#ff1f43',
  vibe: '#338be5',
  vivo: '#408af1',
  vrc: '#418bca',
  vrsc: '#3165D4',
  vtc: '#048657',
  vtho: '#2A5284',
  wabi: '#399b32',
  wan: '#136AAD',
  waves: '#0155ff',
  wax: '#f89022',
  wbtc: '#201A2D',
  wgr: '#b80000',
  wicc: '#5783CB',
  wings: '#0dc9f7',
  wpr: '#ffe600',
  wtc: '#8200FF',
  x: '#3B5998',
  xas: '#faa00d',
  xbc: '#f7931a',
  xbp: '#21AF67',
  xby: '#56f4f1',
  xcp: '#ED1650',
  xdn: '#4f7aa2',
  xem: '#67B2E8',
  xin: '#1EB5FA',
  xlm: '#480BE3',
  xmcc: '#DD0632',
  xmg: '#004a80',
  xmo: '#F60',
  xmr: '#FF6600',
  xmy: '#ec1076',
  xp: '#008200',
  xpa: '#4FA784',
  xpm: '#ffd81b',
  xpr: '#7543E3',
  xrp: '#23292F',
  xsg: '#D21E2B',
  xtz: '#0D61FF',
  xuc: '#25AAE3',
  xvc: '#b50126',
  xvg: '#00CBFF',
  xzc: '#23B852',
  yfi: '#006AE3',
  yoyow: '#21a5de',
  zcl: '#c87035',
  zec: '#ECB244',
  zel: '#183C87',
  zen: '#00EAAB',
  zest: '#07BC9C',
  zil: '#49c1bf',
  zilla: '#00004D',
  zrx: '#302C2C',
};
