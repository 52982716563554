import type { ICellRendererParams } from 'ag-grid-enterprise';
import { useCallback, useRef, type MouseEvent, type ReactNode } from 'react';
import styled from 'styled-components';
import { Button, IconButton } from '../Button';
import { IconName } from '../Icons';
import { Popover, usePopoverState } from '../Popover';

export interface AgGridHamburgerMenuProps {
  renderItems: (props: ICellRendererParams) => ReactNode;
  csvExport?: boolean;
}

export function AgGridHamburgerMenu({ renderItems, ...props }: ICellRendererParams & AgGridHamburgerMenuProps) {
  const popover = usePopoverState({
    trigger: 'click',
    usePortal: true,
    placement: 'bottom-end',
    closeOnClickOutside: true,
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top-end'],
        },
      },
    ],
  });

  const { onClickTarget } = popover;

  const itemsRef = useRef<HTMLDivElement>(null);

  const handleClickTarget = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (itemsRef && !itemsRef.current?.contains(e.target as Node)) {
        e.stopPropagation();
      }
      onClickTarget(e.nativeEvent);
    },
    [onClickTarget]
  );

  return (
    <Wrapper onClickCapture={handleClickTarget}>
      <Popover {...popover}>
        <IconButton icon={IconName.DotsVertical} ghost />
        <ItemsWrapper onClick={() => popover.close()} ref={itemsRef}>
          {renderItems?.(props)}
        </ItemsWrapper>
      </Popover>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacingSmall}px;

  ${Button} {
    justify-content: flex-start;
    width: 100%;
  }
`;
