import { Text } from '@talos/kyoko';
import type { ReactNode } from 'react';
import { SummaryLine } from '../SummaryLine';

interface OrderSummaryLineProps {
  title: ReactNode;
  value: ReactNode;
}

export const OrderSummaryLine = ({ title, value, ...rest }: OrderSummaryLineProps) => (
  <SummaryLine {...rest} title={<Text>{title}</Text>} value={value || '-'} />
);
