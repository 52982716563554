import type Big from 'big.js';
import type { ExpectTrue } from '../../tests';
import { ORDERS_SUMMARY_DATAPOINTS } from '../../tokens';
import { parseDate, toBig, type OptionalProperties } from '../../utils';
import type { AggregationWindowEnum, IOrdersSummaryDatapoint, UpdateActionEnum } from '../types';
import type { DatapointProps } from './types';

export class OrdersSummaryDatapoint {
  public static readonly Stream_Name = ORDERS_SUMMARY_DATAPOINTS;

  public readonly StartTime: Date;
  public readonly Asset: string;
  public readonly AvgOrderAmount: Big | undefined;
  public readonly TotalExchangeFees: Big | undefined;
  public readonly EffectiveFeeRate: Big | undefined;
  public readonly EquivalentCurrency: string;
  public readonly Strategy: string;
  public readonly UpdateAction: UpdateActionEnum | undefined;
  public readonly ReleaseRevision: string | undefined;
  public readonly AggregationWindow: AggregationWindowEnum | undefined;
  constructor(data: OrdersSummaryDatapoint) {
    this.StartTime = parseDate(data.StartTime);
    this.Asset = data.Asset;
    this.AvgOrderAmount = toBig(data.AvgOrderAmount);
    this.TotalExchangeFees = toBig(data.TotalExchangeFees);
    this.EffectiveFeeRate = toBig(data.EffectiveFeeRate);
    this.EquivalentCurrency = data.EquivalentCurrency;
    this.Strategy = data.Strategy;
    this.UpdateAction = data.UpdateAction;
    this.ReleaseRevision = data.ReleaseRevision;
    this.AggregationWindow = data.AggregationWindow;
  }
}

type _OrdersSummaryDatapoint = OptionalProperties<DatapointProps<IOrdersSummaryDatapoint>, 'UpdateAction'>;

// TYPE LEVEL TESTS
type _Expect_OrdersSummaryDatapoint_To_Only_Have_Keys_From_IOrdersSummaryDatapoint = ExpectTrue<
  {
    [K in keyof OrdersSummaryDatapoint & string]: K extends keyof _OrdersSummaryDatapoint ? true : K;
  }[Exclude<keyof OrdersSummaryDatapoint, never>]
>;
type _Expect_All_Keys_In_IOrdersSummaryDatapoint_To_Be_In_OrdersSummaryDatapoint = ExpectTrue<
  {
    [K in keyof _OrdersSummaryDatapoint & string]: K extends keyof OrdersSummaryDatapoint ? true : K;
  }[Exclude<keyof _OrdersSummaryDatapoint, never>]
>;
