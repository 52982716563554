import { useCallback, useDebugValue, useEffect, useMemo, useRef, useState } from 'react';
import { useUnmount } from 'react-use';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { WebSocketStatus } from '../tokens/socket';

import { v4 as uuid } from 'uuid';
import { useConstant } from './useConstant';
import { useDynamicCallback } from './useDynamicCallback';

import { useSocketClient, useSocketStatus } from '../providers/WebSocketClientProvider';
import type { RequestStream } from '../types/RequestStream';

import type { SubscriptionCallback } from '../providers';
import {
  isErrorSubscriptionResponse,
  type ErrorSubscriptionResponse,
  type SubscriptionResponse,
} from '../types/SubscriptionResponse';
import { usePageVisibility } from './usePageVisibility';
export interface NextPageOptions {
  loadAll?: boolean;
}

export interface UseSubscriptionReturn<T> {
  isLoading: boolean;
  data: Observable<T>;
  error: Observable<ErrorSubscriptionResponse | undefined>;
  /**
   * Push a manual update to the stream.
   * Can be used for example to perform kinds of optimistic updates, or just push in REST operation success results for immediate frontend feedback.
   * If you are pushing manual updates and co-mingling that with backend updates, make sure your pipeline is idempotent.
   */
  push: (message: T) => void;
  nextPage: (options?: NextPageOptions) => void;
}

export type UseSubscriptionRequest = RequestStream | RequestStream[] | null;

export interface UseSubscriptionOptions {
  replay?: boolean;
  loadAll?: boolean;
  overrideParticipant?: string;
  unsubscribeOnPageInvisibility?: boolean;
}

/** Subscribe to Ava Service endpoints via the given requests
 * @param request The request to subscribe to; if set to null, the subscription will be unsubscribed (or won't be started)
 * @param options Options for the subscription
 * @returns An object containing the subscription state
 */
export function useSubscription<TResponse extends SubscriptionResponse<any, any>>(
  request: UseSubscriptionRequest,
  options?: UseSubscriptionOptions
): UseSubscriptionReturn<TResponse>;
export function useSubscription<TData>(
  request: UseSubscriptionRequest,
  options?: UseSubscriptionOptions
): UseSubscriptionReturn<SubscriptionResponse<TData>>;
export function useSubscription<T = any>(
  request: UseSubscriptionRequest,
  { replay = true, ...options }: UseSubscriptionOptions = {}
): UseSubscriptionReturn<T> {
  const address = useConstant(uuid());

  // Must keep track of request so we know when to update subscription
  const activeRequest = useRef<RequestStream[] | null>();

  // Must also keep track of if we have an RxJS subscriber.
  // If not, there's no point in performing the socket subscription.
  const [subscriberCount, setSubscriberCount] = useState(0);
  const hasSubscriber = subscriberCount > 0;

  // Keep track of the current isLoading state.
  const [isLoading, setIsLoading] = useState(false);

  const client = useSocketClient();
  const status = useSocketStatus();

  const isPageVisible = usePageVisibility();

  const subject = useMemo(() => {
    return replay ? new ReplaySubject<SubscriptionResponse>(1) : new Subject<SubscriptionResponse>();
  }, [replay]);

  const errors = useMemo(() => {
    return replay
      ? new ReplaySubject<ErrorSubscriptionResponse | undefined>(1)
      : new Subject<ErrorSubscriptionResponse | undefined>();
  }, [replay]);

  const observable = useMemo(
    () =>
      new Observable(subscriber => {
        const subscription = subject.asObservable().subscribe(x => {
          // If we are loading all, and there is a next page, we're not done loading yet
          setIsLoading(!!options.loadAll && !!x.next);
          subscriber.next(x);
        });
        setSubscriberCount(curr => curr + 1);
        return () => {
          subscription.unsubscribe();
          setSubscriberCount(curr => curr - 1);
        };
      }),
    [subject, options.loadAll]
  );

  const errorObservable = useMemo(
    () =>
      new Observable(subscriber => {
        const subscription = errors.asObservable().subscribe(x => {
          subscriber.next(x);
        });
        return () => {
          subscription.unsubscribe();
        };
      }),
    [errors]
  );

  const callback: SubscriptionCallback<unknown> = useCallback(
    (err, json) => {
      if (err && isErrorSubscriptionResponse(err)) {
        errors.next(err);
      }
      if (json) {
        errors.next(undefined);
        subject.next(json);
      }
    },
    [subject, errors]
  );

  useEffect(() => {
    if (client == null || status !== WebSocketStatus.CONNECTED) {
      return;
    }
    let requestStream: RequestStream[] | null = request as RequestStream[];

    // null-check so we don't do `request = [undefined]`
    if (request != null && !Array.isArray(request)) {
      // Eslint tells us to store the request in a useRef, but this works just fine
      requestStream = [request];
    }

    // Set request to `null` if there are no streams specified
    if (request != null && (request as object[]).length === 0) {
      requestStream = null;
    }

    // amend: have active subscription, request is set (but changed), and component is mounted
    if (
      requestStream != null &&
      hasSubscriber &&
      activeRequest.current != null &&
      JSON.stringify(requestStream) !== JSON.stringify(activeRequest.current)
    ) {
      setIsLoading(true);
      client.updateSubscription(address, requestStream, callback);
      activeRequest.current = requestStream;
    }

    // register: have no active subscription, request is set and component is mounted
    else if (
      activeRequest.current == null &&
      requestStream != null &&
      hasSubscriber &&
      (!options.unsubscribeOnPageInvisibility || isPageVisible)
    ) {
      setIsLoading(true);
      client.registerSubscription(address, requestStream, callback, options);
      activeRequest.current = requestStream;
    }

    // cancel: have active subscription, but request is null
    else if (activeRequest.current != null && requestStream == null) {
      setIsLoading(false);
      client.unregisterSubscription(address);
      activeRequest.current = null;
    }

    // cancel: do not have any active subscriptions, but there is an active request
    else if (!hasSubscriber && activeRequest.current != null) {
      activeRequest.current = null;
      client.unregisterSubscription(address);
    }

    // unsubscribe on page invisibility
    else if (options.unsubscribeOnPageInvisibility && !isPageVisible && activeRequest.current != null) {
      activeRequest.current = null;
      client.unregisterSubscription(address);
    }
  }, [address, status, client, request, callback, hasSubscriber, options, isPageVisible]);

  useUnmount(() => {
    if (activeRequest.current != null) {
      activeRequest.current = null;
      client.unregisterSubscription(address);
    }
  });

  useDebugValue(address, () => ({ address, activeRequest: activeRequest.current, hasSubscriber }));

  const nextPage = useDynamicCallback((options: NextPageOptions = {}) => {
    client.pageSubscription(address, options);
  });

  const push = useDynamicCallback((message: T) => {
    subject.next(message as SubscriptionResponse<T>);
  });

  // Toggle the `isLoading` status when we receive an error message from the backend
  useEffect(() => {
    const errorSubscription = errorObservable.subscribe(() => setIsLoading(false));
    return () => errorSubscription.unsubscribe();
  }, [errorObservable, setIsLoading]);

  return {
    isLoading,
    data: observable as unknown as Observable<T>,
    error: errorObservable as unknown as Observable<ErrorSubscriptionResponse | undefined>,
    push,
    nextPage,
  };
}
