import type { IFilter, IFilterParams } from 'ag-grid-enterprise';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { dateIsBetween } from '../../utils';
import { DateRangePicker, isDateRange, type DateRange } from '../DateRangePicker';

export const AgGridDateFilter = forwardRef(({ filterChangedCallback, valueGetter }: IFilterParams, ref) => {
  const [dates, setDates] = useState<DateRange>({
    from: null,
    to: null,
  });

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      doesFilterPass(params) {
        // @ts-expect-error TODO looks like this should actually be `valueGetter(params.node)`, need to confirm
        const value = valueGetter(params);
        return value != null && dateIsBetween(dates.from!, Sugar.Date.create(value), dates.to!); // `isFilterActive` will ensure that these values are not null-ish
      },

      isFilterActive() {
        return dates.from != null && dates.to != null;
      },

      getModel() {
        return this.isFilterActive() ? { filterType: 'date', dateFrom: dates.from, dateTo: dates.to } : null;
      },

      setModel(model) {
        setDates({
          from: model?.dateFrom,
          to: model?.dateTo,
        });
      },
    } as IFilter;
  });

  useEffect(() => filterChangedCallback(), [filterChangedCallback, dates]);

  return (
    <DateRangePicker
      value={dates}
      onChange={dates => isDateRange(dates) && setDates({ from: dates.from, to: dates.to })}
      className="ag-custom-component-popup"
    />
  );
});
