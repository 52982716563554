import { useEffect, useState } from 'react';
import { useWLOrgConfigContext } from '../providers/WLOrgConfigProvider';
import type { RequestStream, UpdateActionEnum } from '../types';
import { useObservableValue } from './useObservable';
import { useSubscription } from './useSubscription';

/**
 * Hook to get the Current Fee Tier using the CurrentFeeTier subscription
 * @returns {string | undefined} The current name of the fee tier
 */
export const useCurrentFeeTier = (): string | undefined => {
  const { config } = useWLOrgConfigContext();
  const [request, setRequest] = useState<RequestStream | null>(null);

  useEffect(() => {
    // Only set the request to non-null if config.showUserFeeTier is true
    if (config.showUserFeeTier) {
      setRequest({ name: 'CurrentFeeTier', tag: 'USE_CURRENT_FEE_TIER' });
    }
  }, [config.showUserFeeTier]);

  const { data: currentFeeTierObs } = useSubscription<{
    FeeTier?: string;
    UpdateAction: UpdateActionEnum;
  }>(request);

  const response = useObservableValue(() => currentFeeTierObs, [currentFeeTierObs]);
  return response?.data?.at(0)?.FeeTier;
};
