import { stringArray } from './stringArray';
import type { ColDefFactory, Column } from './types';

export const markets: ColDefFactory<Column> = column => ({
  ...stringArray({
    ...column,
    params: {
      ...column.params,
      getDisplayName: (marketName, context) => context.current.marketDisplayNameByName?.get(marketName) ?? marketName,
    },
  }),
});
