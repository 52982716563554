import type { ColDef } from 'ag-grid-community';
import { logger } from '../../../utils';
import type { ColumnDef } from './types';

/**
 * This function can be used with all of our types of columns, and will resolve the identifier of that column used between our own code
 * and AgGrid.
 * @param column - a column-like object. Can be of type Column, ColDef, ColumnDef, for example.
 * @returns the id with highest precedence, or if nothing can be found, returns an empty string.
 */
export function getAgGridColId(column: Pick<ColumnDef<unknown>, 'id'> & Pick<ColDef, 'field' | 'colId'>): string {
  if (column.id != null) {
    return column.id;
  }
  if (column.colId != null) {
    return column.colId;
  }
  if (column.field != null) {
    return column.field;
  }
  logger.error(new Error('Unable to resolve identifier (id, field or colId) from a column'), {
    extra: column,
  });
  return '';
}
