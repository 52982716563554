import { isEqual, keys } from 'lodash';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';

import {
  cleanupInitialFilterDateRange,
  FilterClauseType,
  filterExistsAndExcludes,
  removeEmptyFilters,
  useDateRangeFilter,
  useSidesFilter,
  useSymbolsFilter,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type BlotterTableFiltersProps,
  type CustomerTrade,
  type DateRangeFilter,
  type FilterableProperty,
  type FilterClause,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';

import { useCustomerMarketAccountsFilter } from './filters';

export interface TradesTableFilter extends DateRangeFilter {
  MarketAccounts?: string[];
  Sides?: string[];
  Symbols?: string[];
}

interface UseTradesFilterParams<TData extends CustomerTrade> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
}

export function useTradesFilter<TData extends CustomerTrade>({ persistedBlotterTable }: UseTradesFilterParams<TData>) {
  const { onFilterChanged: saveFilter } = persistedBlotterTable;

  const [initialFilter] = useState<TradesTableFilter>(() =>
    cleanupInitialFilterDateRange(persistedBlotterTable.initialFilter)
  );
  const [filter, setFilter] = useState<TradesTableFilter>(() =>
    cleanupInitialFilterDateRange(persistedBlotterTable.initialFilter)
  );

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const prevFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;
      if (!isEqual(prevFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<TData>>(
    row => {
      const data = row.data;

      if (filterExistsAndExcludes(filter, 'Sides', data, 'Side')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Symbols', data, 'Symbol')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'MarketAccounts', data, 'MarketAccount')) {
        return false;
      }
      return true;
    },
    [filter]
  );

  const customerMarketAccountsFilter = useCustomerMarketAccountsFilter();
  const sidesFilter = useSidesFilter();
  const symbolFilter = useSymbolsFilter();

  const filterableProperties: FilterableProperty[] = useMemo(
    () => [customerMarketAccountsFilter, sidesFilter, symbolFilter].compact(),
    [customerMarketAccountsFilter, sidesFilter, symbolFilter]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof TradesTableFilter)[]).forEach((key: keyof TradesTableFilter) => {
        switch (key) {
          case '_start':
          case 'StartDate':
          case 'EndDate':
          case 'TimestampField':
            return;
          default:
            clauses.push({
              key: key,
              type: FilterClauseType.INCLUSIVE,
              selections: filter[key] as string[],
            });
        }
      });
    }
    return clauses;
  }, [filter]);
  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter: TradesTableFilter = removeEmptyFilters<TradesTableFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) as unknown as TradesTableFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );
  const dateRangeFilter = useDateRangeFilter(filter, changeFilter);

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    }),
    [filterableProperties, handleFilterClausesChanged, initialFilterClauses]
  ) satisfies UseFilterBuilderProps;
  const blotterTableFilterProps = useMemo(
    () => ({
      ...dateRangeFilter,
    }),
    [dateRangeFilter]
  ) satisfies Partial<BlotterTableFiltersProps>;
  return useMemo(
    () => ({
      initialFilter,
      clientSideFilter,
      filter,
      // shortcut to spread properties into useAccordionFilterBuilder.filterBuilderProps
      filterBuilderProps,
      // shortcut to spread props into the BlotterTableFilters component
      blotterTableFilterProps,
    }),
    [blotterTableFilterProps, clientSideFilter, filter, filterBuilderProps, initialFilter]
  );
}
