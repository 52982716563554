import { WARNING_SEVERITY_TO_ALERT_VARIANT } from '../../types';
import { Alert } from '../Alert';
import type { PMSWarningColumnValue } from '../BlotterTable/columns/pmsWarning';
import { HStack, VStack } from '../Core';
import { Text } from '../Text';

/** Warnings for Portfolio Management System rows */
export function PMSRowWarnings({ warningData }: { warningData: PMSWarningColumnValue }) {
  const alertVariant = WARNING_SEVERITY_TO_ALERT_VARIANT[warningData.severity];

  return (
    <VStack gap="spacingTiny" w="100%" p="spacingSmall" maxWidth="70vw">
      <Alert
        w="100%"
        variant={alertVariant}
        dismissable={false}
        justifyContent="space-between"
        alignItems="center"
        p="spacingDefault"
      >
        <VStack alignItems="flex-start" gap="spacingSmall" w="100%">
          {warningData.warnings.sort().map((warningItem, idx) => (
            <HStack key={idx + '::' + warningItem} gap="spacingSmall" justifyContent="space-between" w="100%">
              <Text size="fontSizeMd" color="gray.100">
                {warningItem}
              </Text>
            </HStack>
          ))}
        </VStack>
      </Alert>
    </VStack>
  );
}
