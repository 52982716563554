import type { Currency } from '../types/Currency';
import { AddressRoutingTypeEnum, CurrencyTagEnum } from '../types/types';

export const currencySymbols = {
  USD: '$',
  GBP: '£',
  EUR: '€',
  SGD: 'S$',
  BTC: '₿',
};

export const getAddressRoutingTypeOfCurrency = (currencyObj?: Currency) =>
  currencyObj?.Tags?.includes(CurrencyTagEnum.Fiat) ? AddressRoutingTypeEnum.Fiat : AddressRoutingTypeEnum.Crypto;
