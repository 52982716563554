export const AVAILABLE_TIME_UNITS = ['s', 'm', 'h'];
export const prettyTimeUnit = (unit: string) => {
  switch (unit) {
    case 's':
      return 'sec';
    case 'm':
      return 'min';
    case 'h':
      return 'hr';
    default:
      return unit;
  }
};
