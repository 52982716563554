import { createContext, useContext } from 'react';
import type { ITourContext } from './types';

export const TourContext = createContext<ITourContext | undefined>(undefined);

export function useTourContext() {
  const context = useContext(TourContext);
  if (context == null) {
    throw new Error('useTour must be used within a TourProvider');
  }
  return context;
}
