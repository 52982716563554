import {
  OrderFormSides,
  WL_INITIATE_ORDER,
  WL_INITIATE_RFQ,
  isPercentParameterValue,
  useSecuritiesContext,
  useWLRoleAuth,
  useWLSymbol,
  type CustomerOrder,
  type FormState,
  type IStrategyParameters,
  type WLOrderStrategy,
  type WhitelabelParametersFormState,
} from '@talos/kyoko';
import Big from 'big.js';
import { head, isFunction, sortBy, transform } from 'lodash';
import { createContext, memo, useContext, useEffect, useMemo, useState } from 'react';

export const ORDER_FORM = 'ORDER_FORM';
export const RFQ_FORM = 'RFQ_FORM';

const DEFAULT_ORDER_FORM: FormState = {
  Side: OrderFormSides.Buy,
  Parameters: {},
};

const DEFAULT_OMS_STATE: OMSState = {
  symbol: '',
  orderForm: DEFAULT_ORDER_FORM,
  view: ORDER_FORM,
  archivedOrderIDs: [],
};

const DEFAULT_OMS_UTILS: OMSUtils = {
  archiveOrder: () => {},
  clearOrderForm: () => {},
  setOrderView: () => {},
  setRFQView: () => {},
  setSymbol: () => {},
  setOrderForm: () => {},
  initModifyOrder: () => {},
  initResubmitOrder: () => {},
  setSelectedClOrdID: () => {},
};

const OMSStateContext = createContext(DEFAULT_OMS_STATE);
const OMSUtilsContext = createContext(DEFAULT_OMS_UTILS);

export function useOMSStateContext() {
  return useContext(OMSStateContext);
}

export function useOMSUtilsContext() {
  return useContext(OMSUtilsContext);
}

export const OMSStateProvider = memo(({ children }: React.PropsWithChildren<unknown>) => {
  const { isAuthorized } = useWLRoleAuth();
  const initialView: FormViews = isAuthorized(WL_INITIATE_ORDER)
    ? ORDER_FORM
    : isAuthorized(WL_INITIATE_RFQ)
    ? RFQ_FORM
    : ORDER_FORM;
  const [view, setView] = useState<FormViews>(initialView);
  const { symbol, setSymbol, isLoaded } = useWLSymbol();
  const [orderForm, setOrderForm] = useState<FormState>(DEFAULT_ORDER_FORM);
  const [archivedOrderIDs, setArchivedOrderIDs] = useState<string[]>([]);
  const [selectedClOrdID, setSelectedClOrdID] = useState<string>();
  const { securitiesBySymbol, securitiesList } = useSecuritiesContext();
  const security = symbol ? securitiesBySymbol.get(symbol) : undefined;

  const strategiesList = useMemo(() => {
    return security?.SupportedStrategies ?? [];
  }, [security]);

  const { Currency } = orderForm;

  useEffect(() => {
    if (!isLoaded) {
      return;
    }
    // Among other things, this will be triggered when symbol that is selected becomes disabled.
    const isSymbolInvalid = symbol == null || !securitiesBySymbol.has(symbol);
    const hasSecurities = securitiesList.length > 0;

    if (isSymbolInvalid && hasSecurities) {
      // The securitiesList could be a blank array when trading is disabled
      const firstSecurity = head(sortBy(securitiesList, 'index'));
      if (firstSecurity?.Symbol) {
        setSymbol(firstSecurity.Symbol);
      }
    }
  }, [symbol, setSymbol, securitiesBySymbol, isLoaded, securitiesList]);

  useEffect(() => {
    if (Currency == null && security != null) {
      setOrderForm(prev => ({ ...prev, Currency: security.BaseCurrency }));
    }
  }, [Currency, security]);

  // Set a default strategy in the order form based on strategiesList
  // strategiesList prioritizes security.SupportedStrategies over the default list of strategies
  useEffect(() => {
    if (orderForm.Strategy == null && strategiesList.length) {
      setOrderForm(prev => ({ ...prev, Strategy: strategiesList[0].Name }));
    }
  }, [orderForm.Strategy, strategiesList]);

  const utils = useMemo(() => {
    const updateOrderForm = (values: FormState | ((prev: FormState) => FormState)) => {
      setOrderForm(prev => {
        const newValues = isFunction(values) ? values(prev) : values;
        if (!!prev.OrderID && prev.OrderID === newValues.OrderID) {
          newValues.Side = prev.Side;
          newValues.Currency = prev.Currency;
        }
        return newValues;
      });
    };

    const updateSymbol = (symbol: string) => {
      setSymbol(symbol);
      const security = securitiesBySymbol.get(symbol);
      updateOrderForm(({ Strategy, Side }) => {
        // When changing the symbol update the strategy to one that is supported for the symbol if this property is specified
        const newStrategy =
          security?.SupportedStrategies && !security.SupportedStrategies.find(s => s.Name === Strategy)
            ? security.SupportedStrategies[0].Name
            : Strategy;
        return {
          Strategy: newStrategy,
          Side,
          Currency: security?.BaseCurrency,
          Parameters: {},
        };
      });
    };

    const setOrderView = () => {
      setView(ORDER_FORM);
      updateOrderForm(prev => {
        const Side = prev.Side === OrderFormSides.Twoway ? OrderFormSides.Buy : prev.Side;
        return { ...prev, Side };
      });
    };

    const setRFQView = (Side: OrderFormSides) => {
      setView(currentView => {
        if (currentView !== RFQ_FORM) {
          updateOrderForm(prev => ({ ...prev, Parameters: { ...prev, LimitPrice: undefined }, Side }));
          return RFQ_FORM;
        }

        return currentView;
      });
    };

    const initModifyOrder = ({ Price, Side, Strategy, Parameters, Symbol, ...order }: CustomerOrder) => {
      clearOrderForm();
      setOrderView();
      setSymbol(Symbol);
      const WLOrderStrategy = strategiesList?.find(strategy => strategy.Name === Strategy);
      const formattedParameters = cleanParametersFromOrder(Parameters, WLOrderStrategy);
      // The field LimitPrice is run inside of FormState>Parameters.
      // We set the initial value from the top level Price parameter.
      formattedParameters.LimitPrice = Price ?? '';

      updateOrderForm({
        ...order,
        Strategy: Strategy ?? 'Limit',
        Side: OrderFormSides[Side],
        Parameters: formattedParameters,
      });
    };

    const initResubmitOrder = ({
      Price,
      Side,
      RFQID,
      Strategy,
      Parameters,
      Symbol,
      OrderID,
      ...order
    }: CustomerOrder) => {
      clearOrderForm();
      setSymbol(Symbol);
      if (RFQID != null) {
        setView(RFQ_FORM);
        updateOrderForm(prev => ({
          Strategy: Strategy ?? 'Limit',
          Side: OrderFormSides[Side],
          Parameters: { ...prev.Parameters, LimitPrice: undefined },
        }));
      } else {
        setOrderView();
        const WLOrderStrategy = strategiesList?.find(strategy => strategy.Name === Strategy);
        const formattedParameters = cleanParametersFromOrder(Parameters, WLOrderStrategy);
        // The field LimitPrice is run inside of FormState>Parameters.
        // We set the initial value from the top level Price parameter.
        formattedParameters.LimitPrice = Price ?? '';

        updateOrderForm({
          ...order,
          Strategy: Strategy ?? 'Limit',
          Side: OrderFormSides[Side],
          Parameters: formattedParameters,
        });
      }
    };

    const archiveOrder = (orderId: string) => {
      setArchivedOrderIDs(prev => [...prev, orderId]);
    };

    const clearOrderForm = () => {
      updateOrderForm(prev => ({
        ...DEFAULT_ORDER_FORM,
        Currency: security?.BaseCurrency,
        Side: prev.Side,
        Strategy: prev.Strategy,
      }));
    };
    return {
      archiveOrder,
      clearOrderForm,
      setOrderView,
      setRFQView,
      setSymbol: updateSymbol,
      setOrderForm: updateOrderForm,
      initModifyOrder,
      initResubmitOrder,
      setSelectedClOrdID,
    };
  }, [setSymbol, securitiesBySymbol, strategiesList, security?.BaseCurrency]);

  const value: OMSState = useMemo(
    () => ({
      symbol,
      orderForm,
      view,
      archivedOrderIDs,
      selectedClOrdID,
    }),
    [symbol, orderForm, view, archivedOrderIDs, selectedClOrdID]
  );

  return (
    <OMSUtilsContext.Provider value={utils}>
      <OMSStateContext.Provider value={value}>{children}</OMSStateContext.Provider>
    </OMSUtilsContext.Provider>
  );
});

export type FormViews = typeof ORDER_FORM | typeof RFQ_FORM;

type OMSState = {
  symbol?: string;
  orderForm: FormState;
  view: FormViews;
  archivedOrderIDs: string[];
  selectedClOrdID?: string;
};

type OMSUtils = {
  archiveOrder: (orderID: string) => void;
  clearOrderForm: () => void;
  setRFQView: (side: OrderFormSides) => void;
  setOrderView: () => void;
  setSymbol: (symbol: string) => void;
  setOrderForm: React.Dispatch<React.SetStateAction<FormState>>;
  initModifyOrder: (order: CustomerOrder) => void;
  initResubmitOrder: (order: CustomerOrder) => void;
  setSelectedClOrdID: (clOrdID?: string) => void;
};

function cleanParametersFromOrder(
  parameters?: Partial<IStrategyParameters>,
  strategy?: WLOrderStrategy
): WhitelabelParametersFormState {
  if (strategy == null || parameters == null) {
    return {};
  }

  return transform<Partial<IStrategyParameters>, WhitelabelParametersFormState>(
    parameters,
    (modifiedParameters, value, key) => {
      const parameters = strategy.Parameters;
      const paramOptions = parameters.find(p => p.Name === key);
      if (isPercentParameterValue(value, paramOptions?.Type)) {
        modifiedParameters[key] = Big(value).times(100).toFixed();
      } else if (value) {
        modifiedParameters[key] = value.toString();
      }
    }
  );
}
