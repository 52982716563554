import { closestCenter, DndContext, useSensor, useSensors } from '@dnd-kit/core';
import { rectSortingStrategy, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { useCallback, useState, type PropsWithChildren } from 'react';
import { KeyboardSensor, PointerSensor } from '../../utils/dndKit';

import { MarketDataCard } from '../../components/MarketDataCard';

import {
  CardViewEnum,
  Dialog,
  IconName,
  MixpanelEventSource,
  MixpanelSourceContext,
  SymbolSelector,
  SymbolSelectorAppearance,
  useDisclosure,
  useSecuritiesContext,
  type MarketDataCardConfig,
  type Security,
} from '@talos/kyoko';
import type { UseComboboxStateChange } from 'downshift';
import { useMarketDataCards } from '../../providers';
import { AddButton, AddCard, Cards, CardWrapper, MarketDataCardWrapper } from './styles';

interface DroppableContainerProps {
  cardFilter: string;
  cards: MarketDataCardConfig[];
  createCard: (symbol: string) => void;
  disableDrag: boolean;
}

/**
 * dnd-kit aware DroppableContainer, specific to Whitelabel MarketDataCards - ideally this could be made into a generic
 * and put to kyoko or similar package
 */
export function DroppableContainer({
  cards,
  children,
  createCard,
  disableDrag,
}: PropsWithChildren<DroppableContainerProps>) {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const [deletingCardConfig, setDeletingCardConfig] = useState<MarketDataCardConfig | null>(null);
  const { cardsAreExpanded, deleteCard, isAddingCard, moveCard, setIsAddingCard } = useMarketDataCards();
  const { securitiesList } = useSecuritiesContext();
  const { open, ...confirmDeleteCardDialog } = useDisclosure();
  const [draggingCardId, setDraggingCardId] = useState<string | null>(null);

  const handleClose = useCallback(() => {
    setDeletingCardConfig(null);
  }, [setDeletingCardConfig]);

  const handleSymbolChanged = useCallback(
    (symbol?: string) => {
      if (symbol) {
        createCard(symbol);
        setIsAddingCard(false);
      }
    },
    [createCard, setIsAddingCard]
  );

  const handleOpenChange = useCallback(
    (changes: UseComboboxStateChange<Security>) => {
      if (changes.isOpen === false) {
        setIsAddingCard(false);
      }
    },
    [setIsAddingCard]
  );

  const handleDeleteCard = useCallback(
    config => {
      setDeletingCardConfig(config);
      open();
    },
    [open, setDeletingCardConfig]
  );

  const confirmDeleteCard = useCallback(() => {
    deleteCard(deletingCardConfig!);
    handleClose();
  }, [deleteCard, deletingCardConfig, handleClose]);

  const onDragEnd = useCallback(
    e => {
      moveCard(e);
      setDraggingCardId(null);
    },
    [moveCard, setDraggingCardId]
  );

  const onDragStart = useCallback(
    e => {
      const { active } = e;
      setDraggingCardId(active.id);
    },
    [setDraggingCardId]
  );

  return (
    <>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <SortableContext disabled={disableDrag} items={cards} strategy={rectSortingStrategy}>
          <Cards>
            {children}
            {cards.map(card => (
              <CardWrapper isDragged={card.id === draggingCardId} key={card.id} id={card.id}>
                <MixpanelSourceContext.Provider value={MixpanelEventSource.MarketCard}>
                  <MarketDataCard
                    config={card}
                    isDraggable={!disableDrag}
                    isDragged={card.id === draggingCardId}
                    onDelete={handleDeleteCard}
                  />
                </MixpanelSourceContext.Provider>
              </CardWrapper>
            ))}
            <MarketDataCardWrapper background="transparent" expanded={cardsAreExpanded} view={CardViewEnum.COMPACT}>
              <AddCard onClick={() => setIsAddingCard(true)}>
                {isAddingCard ? (
                  <SymbolSelector
                    appearance={SymbolSelectorAppearance.Ghost}
                    availableSecurities={securitiesList}
                    startOpen={true}
                    onIsOpenChange={handleOpenChange}
                    onSymbolChanged={handleSymbolChanged}
                    dropdownWidth={200}
                    dropdownPlacement="bottom"
                    portalize={true}
                  />
                ) : (
                  <AddButton icon={IconName.Plus} size={24} data-testid="add-card-button" />
                )}
              </AddCard>
            </MarketDataCardWrapper>
          </Cards>
        </SortableContext>
      </DndContext>
      <Dialog
        {...confirmDeleteCardDialog}
        onConfirm={confirmDeleteCard}
        title="Delete market data card"
        width={360}
        confirmLabel="Delete card"
      >
        You are about to delete the card for {deletingCardConfig?.symbol}. <br />
        Are you sure you want to proceed?
      </Dialog>
    </>
  );
}
