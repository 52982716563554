import { defineMessages } from 'react-intl';
import { useCurrencyUtils } from '../../../contexts';
import type { Security } from '../../../types';

import { HStack } from '../../Core';
import { FormattedMessage } from '../../Intl';
import { Text } from '../../Text';

const messages = defineMessages({
  availableAmountDetails: {
    defaultMessage: 'Available Amount Details',
    id: 'Forms.CustomerBalanceDetails.availableAmountDetails',
  },
});

interface CustomerBalanceDetailsHeaderProps {
  baseAmount?: string;
  isExpanded: boolean;
  marketAccount?: string;
  quoteAmount?: string;
  security?: Security;
}

export function CustomerBalanceDetailsHeader({
  baseAmount,
  isExpanded,
  marketAccount,
  quoteAmount,
  security,
}: CustomerBalanceDetailsHeaderProps) {
  const { abbreviateByCurrency } = useCurrencyUtils();
  const { BaseCurrency, QuoteCurrency } = security ?? {};

  return (
    <HStack
      flexBasis={marketAccount ? '100%' : undefined}
      gap={marketAccount ? 'spacingDefault' : undefined}
      justifyContent={marketAccount ? 'space-between' : 'flex-start'}
      maxWidth="calc(100% - 24px)"
      data-testid="customer-balance-details-header"
    >
      <Text color="colorTextAttention" whiteSpace="nowrap">
        <FormattedMessage {...messages.availableAmountDetails} />
      </Text>
      <HStack gap="spacingSmall" justifyContent="flex-end" minWidth="0">
        {!isExpanded && marketAccount ? (
          <>
            {baseAmount && BaseCurrency && (
              <Text>
                {abbreviateByCurrency(BaseCurrency, baseAmount)} {BaseCurrency}
              </Text>
            )}
            {baseAmount && BaseCurrency && quoteAmount && QuoteCurrency && <Text>/</Text>}
            {quoteAmount && QuoteCurrency && (
              <Text>
                {abbreviateByCurrency(QuoteCurrency, quoteAmount)} {QuoteCurrency}
              </Text>
            )}
          </>
        ) : (
          <Text
            color="colorTextAttention"
            overflow="hidden"
            textOverflow="ellipsis"
            title={marketAccount}
            whiteSpace="nowrap"
          >
            {marketAccount}
          </Text>
        )}
      </HStack>
    </HStack>
  );
}
