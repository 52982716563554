import { createContext, useContext, useEffect, useMemo, useState, type ReactNode } from 'react';
import { shareReplay } from 'rxjs/operators';
import { useObservable, useObservableValue } from '../hooks/useObservable';
import { useSubscription } from '../hooks/useSubscription';
import { wsScanToMap } from '../pipes/wsScanToMap';
import { UNIFIED_LIQUIDITY_TOKEN } from '../tokens';
import type { RequestStream } from '../types/RequestStream';
import type { IMarketAccount } from '../types/types';

interface UnifiedLiquidityTokenRequest extends RequestStream {
  name: typeof UNIFIED_LIQUIDITY_TOKEN;
  Symbols?: string[];
}

export interface UnifiedLiquidityTokenProps {
  symbol?: string;
}

export interface UnifiedLiquidityResponse {
  Symbol: string;
  Tokens: { Market: IMarketAccount['Market']; Symbol: string }[];
}
export const UnifiedLiquidityContext = createContext<UnifiedLiquidityContextProps>({
  unifiedLiquidityTokenBySymbol: new Map(),
  isLoaded: false,
});
UnifiedLiquidityContext.displayName = 'UnifiedLiquidityContext';

export interface UnifiedLiquidityContextProps {
  unifiedLiquidityTokenBySymbol: Map<string, UnifiedLiquidityResponse> | undefined;
  isLoaded: boolean;
}

export const useUnifiedLiquidityContext = () => {
  const context = useContext(UnifiedLiquidityContext);
  if (context === null) {
    throw new Error('Missing UnifiedLiquidityContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};

export const UnifiedLiquidityProvider = function UnifiedLiquidityProvider({ children }: { children: ReactNode }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const request: UnifiedLiquidityTokenRequest = useMemo(
    () => ({
      name: UNIFIED_LIQUIDITY_TOKEN,
      tag: 'UnifiedLiquidityProvider',
    }),
    []
  );
  const { data: subscription } = useSubscription<UnifiedLiquidityResponse>(request);
  const unifiedLiquidityTokenBySymbolObs = useObservable(
    () =>
      subscription.pipe(
        wsScanToMap({ getUniqueKey: d => d.Symbol, newMapEachUpdate: false }),
        shareReplay({
          bufferSize: 1,
          refCount: true,
        })
      ),
    [subscription]
  );

  const unifiedLiquidityTokenBySymbol = useObservableValue(
    () => unifiedLiquidityTokenBySymbolObs,
    [unifiedLiquidityTokenBySymbolObs]
  );

  useEffect(() => {
    if (unifiedLiquidityTokenBySymbol != null) {
      setIsLoaded(true);
    }
  }, [unifiedLiquidityTokenBySymbol]);

  const value = useMemo<UnifiedLiquidityContextProps>(
    () => ({
      unifiedLiquidityTokenBySymbol,
      isLoaded,
    }),
    [unifiedLiquidityTokenBySymbol, isLoaded]
  );

  return <UnifiedLiquidityContext.Provider value={value}>{children}</UnifiedLiquidityContext.Provider>;
};
