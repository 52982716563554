import { createContext, useCallback, useContext, useMemo } from 'react';
import type { Currency } from '../types/Currency';
import { abbreviate } from '../utils';

export const CurrenciesContext = createContext<CurrenciesContextProps | undefined>(undefined);
CurrenciesContext.displayName = 'CurrenciesContext';
export function useCurrenciesContext() {
  const context = useContext(CurrenciesContext);
  if (context === undefined) {
    throw new Error('Missing CurrenciesContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export interface CurrenciesContextProps {
  isLoaded: boolean;
  currenciesBySymbol: Map<string, Currency>;
  currenciesList: Currency[];
}

export function useCurrencyUtils() {
  const { currenciesBySymbol } = useCurrenciesContext();

  const abbreviateByCurrency = useCallback(
    (currency?: string, amount?: string) => {
      if (!currency) {
        return '0';
      }

      const { DefaultIncrement } = currenciesBySymbol.get(currency) ?? {};
      const [_, precision = ''] = DefaultIncrement?.split('.') ?? [];

      return abbreviate(amount ?? '0', { precision: precision.length }).toUpperCase();
    },
    [currenciesBySymbol]
  );

  return useMemo(
    () => ({
      abbreviateByCurrency,
    }),
    [abbreviateByCurrency]
  );
}
