import { addDays, isPast, isValid } from 'date-fns';
import type React from 'react';
import { useCallback, useMemo, useRef, type RefObject } from 'react';
import type { Modifier } from 'react-day-picker';

import { compact, keys } from 'lodash';
import { defineMessages } from 'react-intl';
import { getIntlKey } from '../../contexts';
import type { DateFnInput } from '../../utils/date';
import { addDuration } from '../../utils/date';
import { Button, ButtonVariants } from '../Button';
import { HStack, VStack, type BoxProps } from '../Core';
import { DayPicker } from '../DayPicker';
import { FormControlSizes } from '../Form/types';
import { FormattedMessage } from '../Intl';
import { Text } from '../Text';
import { TimePicker } from '../TimePicker';
import { TimePickerPrecision } from '../TimePicker/TimePickerPrecision';
import { TimeSelector } from '../TimeSelector';
import { DateTimeWrapper, DurationButtons, TimePickerWrapper } from './styles';
import { DEFAULT_DATE_PICKER_EOD, EOD, NOW, SOD } from './utils';

const messages = defineMessages({
  shortcuts: {
    defaultMessage: 'Shortcuts',
    id: 'DateTimePicker.shortcuts',
  },
  now: {
    defaultMessage: 'Now',
    id: 'DateTimePicker.now',
  },
  eod: {
    defaultMessage: 'EOD',
    id: 'DateTimePicker.eod',
  },
});

export type SelectionOrigin = 'shortcut' | 'daypicker' | 'timepicker' | 'timeselector';
export interface DateTimePickerContentProps {
  value: Date | null;
  /** Show the calendar */
  showCalendar?: boolean;
  /** Show the time picker */
  showTimePicker?: boolean;
  /** Show the shortcuts section (5m/10m/...) */
  showShortcuts?: boolean;
  /** Show milliseconds entry in the time picker */
  showMilliseconds?: boolean;
  shortcuts?: { [key: string]: string };
  /**
   * A ref which will be placed on the dropdown of the Time SearchSelect. This dropdown is portalized and needs to be
   * excepted from an useOnClickOutside-esque logic
   */
  timeSelectorDropdownContentRef?: RefObject<HTMLDivElement>;
  /** Time picker variant -- either time picker or time selector */
  timePickerVariant?: 'picker' | 'selector';
  /** Time selector time intervals in minutes */
  timeSelectorIntervalMinutes?: number;

  /**
   * If you have some very custom shortcuts where you want to translate a shortcut click into some specific new Date state,
   * you can pass in your own function here to do this resolution from shortcut click -> new date value
   * Note that this operation takes over all control of the onShortcutClick handler
   */
  customShortcutResolver?: (shortcutClicked: string) => Date | null;

  onSelection: (date: Date | null, from: SelectionOrigin) => void;
  /** Called when the user is focused somewhere within this component and presses tab, indicating that they want out */
  onTabOut?: () => void;
  /** Time to use as End-Of-Day
   *
   * @default {@link DEFAULT_DATE_PICKER_EOD}
   */
  customEOD?: { hours: number; minutes: number };
  useDaySelectCustomEOD?: boolean;
  minValue?: Date;
  maxValue?: Date;
}

export const DateTimePickerContent = ({
  value,
  showCalendar,
  showTimePicker,
  showShortcuts,
  showMilliseconds,
  shortcuts,
  timePickerVariant,
  timeSelectorIntervalMinutes = 60,
  timeSelectorDropdownContentRef,
  onSelection,
  customEOD = DEFAULT_DATE_PICKER_EOD,
  useDaySelectCustomEOD,
  onTabOut,
  minValue,
  maxValue,
  customShortcutResolver,
}: DateTimePickerContentProps) => {
  const timePickerRef = useRef<HTMLLabelElement>(null);

  const getEOD = useCallback(() => {
    const eod = new Date();
    eod.setHours(customEOD.hours);
    eod.setMinutes(customEOD.minutes);
    eod.setSeconds(0);
    eod.setMilliseconds(0);
    if (isPast(eod)) {
      addDays(eod, 1);
    }
    return eod;
  }, [customEOD]);

  const defaultShortcutResolver = useCallback(
    (option: string) => {
      let newDate: DateFnInput | null;
      if (option === EOD) {
        newDate = getEOD();
      } else if (option === SOD) {
        newDate = addDuration(getEOD(), '-24h');
      } else if (option === NOW) {
        newDate = new Date();
      } else {
        newDate = addDuration(new Date(), option);
      }
      return newDate;
    },
    [getEOD]
  );

  const handleSelectShortcut = useCallback(
    (option: string) => {
      const resolver = customShortcutResolver ?? defaultShortcutResolver;
      onSelection(resolver(option), 'shortcut');
    },
    [onSelection, defaultShortcutResolver, customShortcutResolver]
  );

  const handleSelectDay = useCallback(
    (date: Date) => {
      // Note that the DayPicker returns a date with time = 12:00:00.000
      if (value === null) {
        date.setHours(useDaySelectCustomEOD ? customEOD.hours : 0);
        date.setMinutes(useDaySelectCustomEOD ? customEOD.minutes : 0);
        date.setSeconds(0);
        date.setMilliseconds(0);
      } else {
        date.setHours(value.getHours());
        date.setMinutes(value.getMinutes());
        date.setSeconds(value.getSeconds());
        date.setMilliseconds(value.getMilliseconds());
      }

      onSelection(date, 'daypicker');
    },
    [value, customEOD, useDaySelectCustomEOD, onSelection]
  );

  const limits: Modifier[] = useMemo(() => {
    return compact([
      minValue ? { to: minValue, from: new Date(0) } : undefined,
      maxValue ? { from: maxValue, to: new Date(8_640_000_000_000_000) } : undefined,
    ]);
  }, [minValue, maxValue]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      // Always stop propagation, we expose tab propagation through the onTabOut callback
      event.stopPropagation();

      if (showTimePicker && timePickerRef.current && timePickerRef.current.contains(document.activeElement)) {
        // if we have a time picker and are currently focused within it, ignore tab events.
        return;
      }

      // Only prevent default behavior if we're not within the time picker where we want tab actions to be carried out
      event.preventDefault();
      onTabOut?.();
    }
  };

  const renderShortcutButton = useCallback(
    (shortcut: string, shortcuts: NonNullable<DateTimePickerContentProps['shortcuts']>, width?: BoxProps['w']) => {
      const intlKey = getIntlKey(shortcuts[shortcut]);
      return (
        <Button
          key={shortcut}
          data-testid={`DateTimePicker-${shortcut}`}
          onClick={() => handleSelectShortcut(shortcut)}
          size={FormControlSizes.Small}
          variant={ButtonVariants.Priority}
          width={width}
        >
          {intlKey in messages ? <FormattedMessage {...messages[intlKey]} /> : shortcuts[shortcut]}
        </Button>
      );
    },
    [handleSelectShortcut]
  );

  const shortcutKeys = keys(shortcuts);

  return (
    <HStack p="spacingSmall" alignItems="flex-start" gap="spacingDefault" onKeyDown={handleKeyDown}>
      {showCalendar && (
        <DateTimeWrapper gap="spacingSmall">
          {showCalendar && (
            <>
              <DayPicker
                disabledDays={limits}
                numberOfMonths={1}
                onDayClick={handleSelectDay}
                selectedDays={value && isValid(value) ? value : undefined}
                month={value && isValid(value) ? value : undefined}
              />
              {showTimePicker && (
                <TimePickerWrapper>
                  {timePickerVariant === 'picker' && (
                    <TimePicker
                      date={value}
                      onChange={date => onSelection(date, 'timepicker')}
                      maxPrecision={showMilliseconds ? TimePickerPrecision.MILLISECOND : TimePickerPrecision.SECOND}
                      ref={timePickerRef}
                    />
                  )}
                  {timePickerVariant === 'selector' && (
                    <TimeSelector
                      w="100%"
                      selection={value ?? undefined}
                      intervalMinutes={timeSelectorIntervalMinutes}
                      onChange={date => onSelection(date, 'timeselector')}
                      dropdownContentRef={timeSelectorDropdownContentRef}
                      portalize
                    />
                  )}
                </TimePickerWrapper>
              )}
            </>
          )}
        </DateTimeWrapper>
      )}
      {showShortcuts && shortcuts && (
        <VStack justifyContent="flex-start" alignItems="center" gap="spacingDefault" w="90px">
          <Text w="100%" textAlign="left">
            <FormattedMessage {...messages.shortcuts} />
          </Text>
          {shortcutKeys.length === 1 ? (
            renderShortcutButton(shortcutKeys[0], shortcuts, '100%')
          ) : (
            // Wrap in grid if there's more than one, otherwise we full-width the only shortcut
            <DurationButtons>
              {keys(shortcuts).map(shortcutKey => renderShortcutButton(shortcutKey, shortcuts))}
            </DurationButtons>
          )}
        </VStack>
      )}
    </HStack>
  );
};
