import type Big from 'big.js';
import type { ExpectTrue } from '../../tests';
import { TOTAL_TRADING_VOLUME } from '../../tokens';
import { parseDate, toBig, type OptionalProperties, type RequiredProperties } from '../../utils';
import type {
  AggregationWindowEnum,
  AggressorFlagEnum,
  ITotalTradingVolumeDatapoint,
  OrderMarketabilityEnum,
  StrategyTypeEnum,
  TradeTypeEnum,
  UpdateActionEnum,
} from '../types';
import type { DatapointProps, OptionalDatapointProperties } from './types';

export class TotalTradingVolumeStats {
  public static readonly Stream_Name = TOTAL_TRADING_VOLUME;

  public readonly AggregationWindow: AggregationWindowEnum;
  public readonly StartTime: Date;

  public readonly TotalVolume: Big | undefined;
  public readonly EquivalentCurrency: string | undefined;
  public readonly Market: string | undefined;
  public readonly Asset: string | undefined;
  public readonly TradeType: TradeTypeEnum | undefined;
  public readonly AggressorFlag: AggressorFlagEnum | undefined;
  public readonly Strategy: string | undefined;
  public readonly StrategyType: StrategyTypeEnum | undefined;
  public readonly OrderMarketability: OrderMarketabilityEnum | undefined;
  public readonly LowerVPLimit: Big | undefined;
  public readonly UpperVPLimit: Big | undefined;

  public readonly UpdateAction: UpdateActionEnum | undefined;
  public readonly ReleaseRevision: string | undefined;

  constructor(data: JSON_TotalTradingVolumeStats | TotalTradingVolumeStats) {
    this.StartTime = parseDate(data.StartTime);
    this.TotalVolume = toBig(data.TotalVolume);
    this.EquivalentCurrency = data.EquivalentCurrency;
    this.Market = data.Market;
    this.Asset = data.Asset;
    this.TradeType = data.TradeType;
    this.AggressorFlag = data.AggressorFlag;
    this.Strategy = data.Strategy;
    this.StrategyType = data.StrategyType;
    this.OrderMarketability = data.OrderMarketability;
    this.LowerVPLimit = toBig(data.LowerVPLimit);
    this.UpperVPLimit = toBig(data.UpperVPLimit);
    this.ReleaseRevision = data.ReleaseRevision;
    this.AggregationWindow = data.AggregationWindow;
    this.UpdateAction = data.UpdateAction;
  }
}

type JSON_TotalTradingVolumeStats = RequiredProperties<
  OptionalProperties<
    DatapointProps<ITotalTradingVolumeDatapoint>,
    OptionalDatapointProperties | 'TradeType' | 'AggressorFlag' | 'UpdateAction' | 'ReleaseRevision'
  >,
  'TotalVolume'
>;

// TYPE LEVEL TESTS
type _Expect_TotalTradingVolumeStats_To_Only_Have_Keys_From_ITotalTradingVolumeDatapoint = ExpectTrue<
  {
    [K in keyof TotalTradingVolumeStats & string]: K extends keyof JSON_TotalTradingVolumeStats ? true : K;
  }[Exclude<keyof TotalTradingVolumeStats, never>]
>;
type _Expect_All_Keys_In_ITotalTradingVolumeDatapoint_To_Be_In_TotalTradingVolumeStats = ExpectTrue<
  {
    [K in keyof JSON_TotalTradingVolumeStats & string]: K extends keyof TotalTradingVolumeStats ? true : K;
  }[Exclude<keyof JSON_TotalTradingVolumeStats, 'ReleaseRevision'>]
>;
