export enum MarketColorDotVariant {
  Default = 'DEFAULT',
  Bordered = 'BORDERED',
}

export type MarketColorDotProps = {
  market: string;
  variant?: MarketColorDotVariant;
  borderColor?: string;
};
