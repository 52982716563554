import type { GridApi, IRowNode } from 'ag-grid-community';
import type { ICellRendererParams } from 'ag-grid-enterprise';
import type { CustomCellEditorProps } from 'ag-grid-react';
import type { ReactNode } from 'react';
import type { Currency } from '../../types/Currency';
import type { RequiredProperties } from '../../utils';
import type { SizeValue } from '../BlotterTable/aggFuncs/types';
import type { SizeColumnParams } from '../BlotterTable/columns/size.types';
import type { WarningColumnValue } from '../BlotterTable/columns/warning.types';
import type { ButtonProps, IconButtonProps } from '../Button';
import type { MultiSelectProps } from '../Form';
import type { UseDropdownPopperProps } from '../Form/Dropdown';
import type { SearchSelectProps, UseSearchSelectProps } from '../Form/SearchSelect/types';
import type { InlineFormattedNumberProps, NumberVariants } from '../FormattedNumber';

export const WARNING_ROW_CLASSNAME = 'ag-row-warning-severity-high';

export type AgGridWarningProps = ICellRendererParams<unknown, WarningColumnValue | undefined> & {
  tooltip?: ReactNode;
  tooltipWidth?: string;
};

export type AgGridSearchSelectDropdownProps<T> = CustomCellEditorProps & {
  useSearchSelectParams: Omit<UseSearchSelectProps<T>, 'onChange' | 'selection' | 'inputRef'>;
  useDropdownParams?: Omit<UseDropdownPopperProps, 'isOpen' | 'referenceElement'>;
} & Pick<
    SearchSelectProps<T>,
    'maxHeight' | 'showClear' | 'showDropdownSearch' | 'searchPlaceholder' | 'portalize' | 'size'
  >;

export type AgGridMultiSelectDropdownProps<T> = CustomCellEditorProps & {
  multiSelectProps: Omit<MultiSelectProps<T>, 'onChange' | 'selection' | 'inputRef' | 'selections'>;
} & Pick<SearchSelectProps<T>, 'maxHeight' | 'showDropdownSearch' | 'searchPlaceholder' | 'portalize'>;

export type AgGridSizeProps = Pick<
  SizeColumnParams,
  'sentiment' | 'getSentiment' | 'highlightNegative' | 'round' | 'truncate' | 'trimTrailingZeroes'
> &
  ICellRendererParams<unknown, SizeValue> & {
    inlineFormattedNumberAlignment?: InlineFormattedNumberProps['align'];
    tooltip?: ReactNode;
  };

export type AgGridButtonProps = Omit<ButtonProps, 'onClick' | 'disabled'> & {
  onClick(params: { node: IRowNode; api: GridApi }): void;
  disabled?: boolean | ((params: { node: IRowNode; api: GridApi }) => boolean);
  hide?: (data: IRowNode['data']) => boolean;
};

export type AgGridCurrencyParams = Omit<AgGridCurrencyProps, 'value'>;

export type AgGridCurrencyProps = {
  value: string;
  currencies?: Map<string, RequiredProperties<Partial<Currency>, 'Symbol' | 'Description'>>;
  showDescription?: boolean;
  showIcon?: boolean;
  colorful?: boolean;
  iconSize?: number;

  // If the value is to be derived
  symbolField?: string;
  securityCurrencyField?: string;
};

export type AgGridFormattedNumberProps = {
  highlightNegative?: boolean;
  unit?: string;
  currencies?: Map<string, any>;
  currencyField?: string;
  sentiment?: NumberVariants;
  showCurrency?: boolean;
  round?: boolean;
  increment?: string;
};

export type AgGridIconButtonProps<TData = any> = Omit<IconButtonProps, 'onClick'> & {
  onClick?: (params: { node: IRowNode<TData>; api: GridApi<TData> }) => void;
  hide?: (params: { node: IRowNode<TData>; api: GridApi<TData> }) => boolean;
  /**
   * The data-value of the row for the icon button, useful for testing.
   */
  dataValue?: string;
};

export enum SecurityTooltipEnum {
  UNIFIED_LIQUIDITY = 'UNIFIED_LIQUIDITY',
}

export enum SecurityToursEnum {
  DDH_ENABLED_ORDER = 'DDH_ENABLED_ORDER',
}

export type AgGridSecurityProps = {
  showIcon?: boolean;
  showDescription?: boolean;
  tooltip?: SecurityTooltipEnum;
  colorful?: boolean;
  showAsteriskOnEmpty?: boolean;
  suffix?: ReactNode;
  tours?: SecurityToursEnum[];
};

export type AgGridUserProps = {
  /** Define by which property and in which map we should look for the user entry */
  valueFormatWithMap?: 'email' | 'id';
};
