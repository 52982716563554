import { IGNORED_AUTOCOMPLETE_GROUP } from './tokens';
import type { AutocompleteGroup } from './types';

export function getGroupsMetadata<T>(
  items: T[],
  getGroup?: (obj: T) => string | undefined
): Map<string, AutocompleteGroup<T>> {
  const shouldGroupFlatItems = getGroup !== undefined;
  if (shouldGroupFlatItems) {
    const groupMetadata = new Map<string, AutocompleteGroup<T>>();
    for (const item of items) {
      // If getGroup returns undefined, its the "empty" / "headerless" group
      const group = getGroup(item) ?? IGNORED_AUTOCOMPLETE_GROUP;
      if (groupMetadata.has(group)) {
        groupMetadata.get(group)?.items.push(item);
      } else {
        groupMetadata.set(group, {
          group,
          items: [item],
        });
      }
    }

    return groupMetadata;
  }

  return new Map<string, AutocompleteGroup<T>>([
    [IGNORED_AUTOCOMPLETE_GROUP, { group: IGNORED_AUTOCOMPLETE_GROUP, items: items as T[] }],
  ]);
}
