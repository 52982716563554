import type { FC } from 'react';
import { Loader, SpinnerWrapper, Timer } from './styles';

const STROKE_WIDTH = 4;
const RADIUS = 40;

export const Spinner: FC<{ size?: number }> = ({ size = 40, ...props }) => {
  return (
    <SpinnerWrapper size={size} data-testid="kyoko-spinner" {...props}>
      <Timer rotation={true} width={size} height={size} viewBox={`0 0 ${RADIUS} ${RADIUS}`} reverse={false}>
        <Loader
          r={(RADIUS - STROKE_WIDTH) / 2}
          cx={RADIUS / 2}
          cy={RADIUS / 2}
          strokeWidth={STROKE_WIDTH}
          fill="none"
          reverse={false}
        />
      </Timer>
    </SpinnerWrapper>
  );
};

export { SpinnerContainer } from './styles';
