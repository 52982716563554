import { useCallback, useContext, useMemo, useState, type ReactNode } from 'react';
import { PortalContext, type PortalContextProps } from '../contexts/PortalContext';

/**
 * If this provider is nested below another `PortalContextProvider`,
 * any calls to `getElement` will call `getElement` on the parent context
 * if that element is not defined in this context.
 *
 * This ensures that we can always access portals defined at the top level,
 * yet still allows us to contextually override a certain portal
 * (e.g. "scope" the definition of the blotter table filters portal to a given panel).
 */
export function PortalContextProvider({ children }: { children?: ReactNode | undefined }) {
  const parentProvider = useContext(PortalContext);
  const [portals, setPortals] = useState<Map<string, HTMLElement | null>>(new Map());

  const getElement = useCallback<PortalContextProps['getElement']>(
    <T extends HTMLElement>(portalId: string) =>
      (portals.get(portalId) ?? parentProvider?.getElement?.(portalId) ?? null) as T | null,
    [parentProvider, portals]
  );
  const setElement = useCallback<PortalContextProps['setElement']>(
    <T extends HTMLElement>(portalId: string, node: T | null) => {
      setPortals(prevPortals => new Map([...prevPortals.entries(), [portalId, node]]));
    },
    []
  );

  const value = useMemo<PortalContextProps>(
    () => ({
      getElement,
      setElement,
    }),
    [getElement, setElement]
  );
  return <PortalContext.Provider value={value}>{children}</PortalContext.Provider>;
}
