import styled from 'styled-components';
import { SideEnum } from '../../types/types';
import { Text } from '../Text';

export const TWO_WAY_SIDES_FILTER = '2-way' as const;

export const Side = styled(Text)<{ side?: SideEnum | null }>`
  color: ${({ side, theme }) => {
    if (side === SideEnum.Buy) {
      return theme.colorTextPositive;
    }
    if (side === SideEnum.Sell) {
      return theme.colorTextNegative;
    }
    return theme.colorTextPrimary;
  }};
  text-transform: uppercase;
`;

export function QuoteSide({ side }: { side?: SideEnum | null }) {
  return <Side side={side}>{side == null ? TWO_WAY_SIDES_FILTER : side}</Side>;
}
