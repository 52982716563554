import { cloneDeep, merge } from 'lodash';
import { hsl, hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { lightColors, mapLightTheme } from './LightTheme';

const customcolors: Partial<Colors> = {
  gray: {
    main: hsla(210, 1, 1.0, 1),
    '000': hsla(210, 0, 0.92, 1),
    '010': hsla(210, 0, 0.96, 1),
    '020': hsla(210, 0, 0.89, 1),
    '030': hsla(220, 0, 0.86, 1),
    '040': hsla(220, 0, 0.83, 1),
    '050': hsla(220, 0, 0.79, 1),
    '060': hsla(200, 0, 0.74, 1),
    '070': hsla(200, 0, 0.69, 1),
    '080': hsla(200, 0, 0.63, 1),
    '090': hsla(210, 0, 0.44, 1),
    '100': hsla(0, 0, 0.1, 1),
  },

  primary: {
    mute: hsla(46, 0.75, 0.6, 0.1),
    dim: hsla(46, 0.75, 0.6, 0.6),
    default: hsl(46, 0.75, 0.55),
    lighten: hsl(46, 0.94, 0.65),
  },

  green: {
    mute: hsla(130, 0.63, 0.32, 0.1),
    dim: hsla(130, 0.63, 0.32, 0.4),
    default: hsl(130, 0.63, 0.32),
    lighten: hsl(130, 0.61, 0.4),
  },

  red: {
    mute: hsla(350, 0.86, 0.38, 0.1),
    dim: hsla(350, 0.86, 0.38, 0.4),
    default: hsl(350, 0.86, 0.38),
    lighten: hsl(350, 0.87, 0.48),
  },
};
const colors: Colors = merge(cloneDeep(lightColors), customcolors);

export const LightSDMTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightSDM',

  backgroundSelectDropdown: colors.gray.main,

  backgroundInput: 'transparent',
  backgroundInputInvalid: 'transparent',
  backgroundInputDisabled: 'transparent',

  backgroundSelect: 'transparent',
  backgroundSelectHover: 'transparent',
  backgroundSelectFocus: 'transparent',
  backgroundSelectDisabled: 'transparent',
  backgroundSelectReadOnly: 'transparent',
  backgroundSelectInvalid: 'transparent',

  colorTextDefault: colors.gray['090'],
  colorTextImportant: colors.gray['100'],
  colorTextAttention: colors.gray['100'],

  borderColorPopover: colors.gray.main,

  // Make sure the right set of colors are used
  backgroundDrawer: colors.gray['020'],
  backgroundPopoverHighlight: colors.gray['030'],
  backgroundContentHover: colors.gray['020'],

  backgroundBlotterTableRowSelected: colors.gray['030'],
  backgroundBlotterTableRowHover: colors.gray['020'],
  borderColorBlotterTable: colors.gray['040'],

  backgroundScrollbar: 'transparent',
  colorScrollbar: colors.gray['030'],
  colorScrollbarHover: colors.gray['040'],

  backgroundPriorityButton: colors.gray['060'],
  backgroundPriorityButtonHover: colors.gray['070'],
  backgroundPriorityButtonFocus: colors.gray['070'],
  backgroundPriorityButtonActive: colors.gray['060'],

  backgroundDefaultButton: colors.gray['030'],
  backgroundDefaultButtonHover: colors.gray['040'],
  backgroundDefaultButtonFocus: colors.gray['040'],
  backgroundDefaultButtonActive: colors.gray['030'],

  backgroundToggle: colors.gray['050'],
  backgroundToggleChecked: colors.primary.default,
  backgroundToggleSwitch: colors.gray['090'],

  backgroundCheckbox: colors.gray['010'],
  backgroundCheckboxHover: colors.gray['020'],
  backgroundCheckboxChecked: colors.primary.default,

  borderColorCheckbox: colors.gray['050'],
  borderColorCheckboxFocus: colors.gray['070'],
  borderColorCheckboxHover: colors.gray['070'],
  borderColorCheckboxChecked: colors.gray['070'],
  borderColorCheckboxCheckedFocus: colors.gray['070'],

  backgroundRadiobutton: colors.gray['010'],
  backgroundRadiobuttonHover: colors.gray['020'],
  backgroundRadiobuttonChecked: colors.primary.default,

  borderColorRadiobutton: colors.gray['050'],
  borderColorRadiobuttonFocus: colors.gray['070'],
  borderColorRadiobuttonHover: colors.gray['070'],
  borderColorRadiobuttonChecked: colors.gray['070'],
  borderColorRadiobuttonCheckedFocus: colors.gray['070'],

  backgroundProgressBar: colors.gray['040'],
  backgroundProgressBarIndeterminate: colors.gray['050'],
  backgroundProgressBarMeter: colors.gray['060'],

  borderColorInput: colors.gray['060'],
  borderColorInputHover: colors.gray['070'],
  borderColorInputFocus: colors.gray['080'],
  borderColorInputDisabled: colors.gray['040'],

  borderColorSelect: colors.gray['060'],
  borderColorSelectHover: colors.gray['070'],
  borderColorSelectFocus: colors.gray['080'],
  borderColorSelectDisabled: colors.gray['040'],
  borderColorSelectReadOnly: colors.gray['040'],

  backgroundDropdownResults: colors.gray.main,
  backgroundDropdownInput: colors.gray.main,
  backgroundDropdownSelected: colors.gray['020'],
  backgroundDropdownHovered: colors.gray['020'],
  boxShadowDropdown: '0px 8px 32px rgba(0, 0, 0, 0.2)',
});
