import { useEffect, useRef, useState } from 'react';
import { isTextEllipsized } from './isTextEllipsized';

/**
 * This hook is used to determine if the content of a div is overflowing, based on
 * the scrollWidth and clientWidth of the div, via utilization of ResizeObserver and MutationObserver
 *
 * ```typescript
 * const { isOverflowing, ref } = useOverflowHandler();
 * const tooltipContent = <Box display="flex">Overlay Content to show if hovering over long message</Box>;
 * return (
 * <WrappingBox cursor="default">
 *   <Tooltip delay={500} tooltip={tooltipContent} placement="bottom" trigger={isOverflowing ? 'hover' : ''}>
 *     <FormMessage data-testid="overflow-test-message" {...props} ref={ref} />
 *   </Tooltip>
 * </WrappingBox>
 *```
 * @param {Object} options
 * @param {boolean} options.ignoreMutations - boolean value to determine if MutationObserver creation can be skipped, reducing resource alloc (default: false).  Useful for use in ag-grid where column resize would only trigger ResizeObserver
 * @returns {Object} { isOverflowing: boolean, ref: React.RefObject<HTMLDivElement> }
 * @returns {boolean} isOverflowing - true if the content is overflowing the space available
 * @returns {React.RefObject<HTMLDivElement>} ref - React Ref Object to be used on the div to check for overflow
 *
 */
export function useOverflowHandler({ ignoreMutations, skip }: { ignoreMutations?: boolean; skip?: boolean } = {}): {
  isOverflowing: boolean;
  ref: React.RefObject<HTMLDivElement>;
} {
  const ref = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState<boolean>(false);

  useEffect(() => {
    if (skip) {
      return;
    }
    if (!ref.current) {
      throw new Error('Ref provided to useOverflowHandler must be assigned');
    }
    const checkOverflow = () => {
      const div = ref.current;
      if (!div) {
        // div may be null on cleanup
        return;
      }
      const isOverflowing = isTextEllipsized(div);
      setIsOverflowing(isOverflowing);
    };

    const resizeObserver = new ResizeObserver(checkOverflow);
    resizeObserver.observe(ref.current!);

    let mutationObserver: MutationObserver | undefined;
    if (!ignoreMutations) {
      mutationObserver = new MutationObserver(checkOverflow);
      mutationObserver.observe(ref.current!, { childList: true, subtree: true, characterData: true });
    }

    return () => {
      resizeObserver.disconnect();
      mutationObserver?.disconnect();
    };
  }, [ignoreMutations, skip]);

  return { isOverflowing, ref };
}
