import type { Big } from 'big.js';
import styled, { type DefaultTheme } from 'styled-components';
import { MEDIA, getLoadingAnimation, maxWidth, parseColor, setAlpha } from '../../styles';
import { format } from '../../utils';
import { BlotterTableWrapper } from '../BlotterTable';
import { Box, Flex, HStack, VStack } from '../Core';
import { DropdownMenu } from '../Filters/DropdownFilter/styles';
import { ToggleButtonFilterWrapper } from '../Filters/ToggleButtonFilter/styles';
import { Text } from '../Text';

export const placeholder = <Text color="colors.gray.080">&mdash;&mdash;</Text>;
export const Placeholder = styled(Box)`
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  ${({ theme }) =>
    getLoadingAnimation(theme, true, 'backgroundCard', setAlpha(0.08, parseColor(theme, 'backgroundCard') ?? ''))}
  height: 16px;
  width: 32px;
`;

export const SparklineWrapper = styled(Box)`
  line {
    transition: stroke-opacity 200ms ease;
  }
  text {
    transition: opacity 200ms ease;
    fill: ${({ theme }) => theme.colorTextSubtle};
    opacity: 0;
  }

  &:hover {
    line,
    text {
      opacity: 1;
    }
  }
`;
SparklineWrapper.defaultProps = {
  h: '16px',
  w: '100%',
};

export const FavoriteCell = styled(Flex)``;
FavoriteCell.defaultProps = {
  pl: '1px',
};

export const CurrencyIconCell = styled(Flex)`
  color: ${({ theme }) => theme.colorTextImportant};
`;

export const SymbolCell = styled(HStack).attrs({
  justifyContent: 'flex-start',
})`
  cursor: pointer;

  &,
  & > ${VStack} {
    overflow: hidden;
  }
  & > ${VStack} > ${Text} {
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    width: 100%;
  }
`;

export const PriceCell = styled(Flex).attrs({
  justifyContent: 'flex-end',
  gap: 'spacingSmall',
})`
  cursor: pointer;
`;
PriceCell.defaultProps = {
  alignItems: 'center',
};

export const VolumeCell = styled(Flex).attrs({
  justifyContent: 'flex-end',
})``;

export const ChangeCell = styled(Flex)``;
ChangeCell.defaultProps = {
  justifyContent: 'flex-end',
  w: '100%',
  pl: '4px',
};

export const WatchListFiltersWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: ${({ theme }) => theme.spacingSmall}px;
  padding: ${({ theme }) => theme.spacingComfortable}px 0;
  border-bottom: ${({ theme }) => `${theme.borderColorTab} 1px solid`};
  position: relative;

  ${maxWidth(MEDIA.small)} {
    margin: 0;
  }

  ${DropdownMenu} {
    width: 120px;
  }
`;

export const WatchListWrapper = styled.div.attrs<{ showOnlyFavorites: boolean }>(({ showOnlyFavorites }) => ({
  className: showOnlyFavorites ? 'show-only-favorites' : undefined,
}))<{ showOnlyFavorites: boolean }>`
  display: flex;
  height: 100%;
  flex-direction: column;
  background: ${({ theme }) => theme.backgroundContent};
  padding-top: ${({ theme }) => theme.spacingComfortable}px;

  ${ToggleButtonFilterWrapper} {
    display: contents;
  }
  ${BlotterTableWrapper} {
    padding-left: ${({ theme }) => theme.spacingComfortable}px;

    ${maxWidth(MEDIA.small)} and (pointer:coarse) {
      padding: 0 ${({ theme }) => theme.spacingMedium}px;
    }
  }

  // copied from https://embed.plnkr.co/plunk/X4hCimLy6aL3j4eh
  /* Hide right header and spacer */
  .ag-pinned-right-header,
  .ag-horizontal-right-spacer {
    width: 0 !important;
    min-width: 0 !important;
  }

  /* Add absolute position so that action buttons column will appear on top of other columns. pointer-events: none to pass on mousemove event to behind columns */
  .ag-pinned-right-cols-container {
    position: absolute !important;
    right: ${({ theme }) => theme.spacingDefault}px;
    pointer-events: none;
  }
  /* Reset pointer-events so that click can happen on action buttons */
  .ag-pinned-right-cols-container * {
    pointer-events: initial;
  }

  /* Hide border of right-cols-container */
  .ag-pinned-right-cols-container .ag-cell {
    /* border: none !important; */
  }

  &:not(.show-only-favorites) {
    .drag-handle {
      display: none;
    }
  }

  .ag-header-cell {
    .ag-header-cell-comp-wrapper {
      overflow: visible;
    }
    &.bid-header {
      color: ${({ theme }) => theme.colorTextPositive};
      justify-content: center;

      .ag-header-cell-comp-wrapper {
        width: auto;
        overflow: visible;
      }
    }
    &.offer-header {
      color: ${({ theme }) => theme.colorTextNegative};
      justify-content: center;

      .ag-header-cell-comp-wrapper {
        width: auto;
        overflow: visible;
      }
    }
    &.slim-header {
      padding: 0;
      overflow: visible;
      justify-content: center;

      .ag-header-cell-comp-wrapper {
        width: auto;
        overflow: visible;
      }
    }
  }

  .ag-cell {
    &.slim-cell {
      padding: 0;
      border-width: 0;
    }
  }

  .ag-row,
  .ag-header {
    border-bottom: none;

    .ag-cell,
    .ag-header-cell {
      border-bottom: solid 1px ${({ theme }) => theme.borderColorBlotterTable};

      &.ag-cell-focus:focus-within {
        border-bottom: solid 1px ${({ theme }) => theme.borderColorBlotterTable};
      }

      &.spacer {
        border-bottom-color: transparent;
        padding: 0;
      }

      &.last-cell {
        padding-right: ${({ theme }) => theme.spacingDefault - 1}px;
      }
    }
  }

  /* Show action buttons only for row that is being hovered. For rows which are not being hovered, hide them by setting their width and padding to 0.*/
  .ag-pinned-right-cols-container .ag-row:not(.ag-row-hover),
  .ag-pinned-right-cols-container .ag-row:not(.ag-row-hover) .ag-cell {
    width: 0 !important;
    min-width: 0 !important;
    max-width: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
  }

  ${BlotterTableWrapper}.ag-theme-balham-dark .ag-pinned-right-cols-container {
    box-shadow: none;
  }
`;

export const SearchWrapper = styled(Box)`
  transition: 200ms right ease;
  overflow: hidden;
  flex: 0 0 auto;
`;

export const PercentChange = styled(({ value, theme }: { value: Big; theme: DefaultTheme }) => {
  return (
    <Text
      color={parseColor(theme, value.gt(0) ? 'green.lighten' : value.lt(0) ? 'red.lighten' : 'gray.080')}
      weight="fontWeightMedium"
      style={{ fontSize: '11px', lineHeight: '12px' }}
    >
      {value.gt(0) ? '+' : ''}
      {format(value, { spec: '1.00', round: true })}%
    </Text>
  );
})`
  position: absolute;
`;
