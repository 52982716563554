import Big from 'big.js';
import { addMilliseconds, differenceInMilliseconds, subMilliseconds } from 'date-fns';
import { parseDate, toBig } from '../utils';
import { ReconResolutionEnum, ReconStateEnum } from './types';

export const ReconResolutionEnumLabels = {
  [ReconResolutionEnum.MissedFees]: 'Missed Fees',
  [ReconResolutionEnum.MissedTrade]: 'Missed Trade',
  [ReconResolutionEnum.MissedTransfer]: 'Missed Transfer',
  [ReconResolutionEnum.Unknown]: 'Unknown',
  [ReconResolutionEnum.BelowThreshold]: 'Below Threshold',
  [ReconResolutionEnum.InitialPosition]: 'Initial Position',
};

export const ReconStateEnumLabels = {
  [ReconStateEnum.PendingReview]: 'Pending Review',
  [ReconStateEnum.ManualResolved]: 'Resolved',
  [ReconStateEnum.AutoResolved]: 'Auto Resolved',
  [ReconStateEnum.Ignored]: 'Ignored',
};

// either 5s or 20% is what we're going for in the buffering
const MIN_TIME_BUFFER_MS = 5000; // 5s
const RELATIVE_TIME_BUFFER_PCT = 0.2; // 20%

export class ReconMismatch {
  ID!: string;

  Asset!: string;

  MarketAccount!: string;

  MissingDelta!: string;

  ResultAmount!: string;

  StartAmount!: string;

  EventsDelta!: string;

  StartTime!: string;

  EndTime!: string;

  State!: ReconStateEnum;

  Resolution!: ReconResolutionEnum;

  Comments!: string;

  private startDate: Date;
  private endDate: Date;

  /** Returns the time in ms between the StartTime and EndTime */
  get msBetweenStartTimeEndTime(): number {
    return differenceInMilliseconds(this.startDate, this.endDate);
  }

  /**
   * Returns a time buffer which can be applied when working with the StartTime and EndTime for some margin of safety.
   */
  get timeBuffer(): number {
    return Math.max(MIN_TIME_BUFFER_MS, this.msBetweenStartTimeEndTime * RELATIVE_TIME_BUFFER_PCT);
  }

  /**
   * Returns a buffered StartTime, as in slightly earlier StartTime, which can be used to make certain requests with a greater margin of safety.
   */
  get bufferedStartTime(): Date {
    return subMilliseconds(this.startDate, this.timeBuffer);
  }

  /**
   * Returns a buffered EndTime, as in slightly later EndTime, which can be used to make certain requests with a greater margin of safety.
   */
  get bufferedEndTime(): Date {
    return addMilliseconds(this.endDate, this.timeBuffer);
  }

  get resolvable(): boolean {
    return this.State === ReconStateEnum.PendingReview;
  }

  get rowID() {
    return `${this.ID}`;
  }
  get sortKey() {
    return `${this.MarketAccount}-${this.Asset}`;
  }
  constructor(data: ReconMismatch) {
    // Should be the auto generated interface from ava.xml
    Object.assign(this, data);
    this.startDate = parseDate(data.StartTime);
    this.endDate = parseDate(data.EndTime);
  }
}

/**
 * Given an array of recon statuses, reduces them to either one status if they are all are the same, "No State" if
 * the reconStatuses array length is 0, or "Mixed States" if theres a mix of states in the array.
 */
export function reduceReconStatuses(reconStatuses: ReconStateEnum[]): ReconStateEnum | 'Mixed States' | 'No State' {
  if (reconStatuses.length === 0) {
    return 'No State';
  }

  if (reconStatuses.length === 1) {
    return reconStatuses[0];
  }

  const [first, ...rest] = reconStatuses;
  const allStatusesEqual = rest.every(status => status === first);
  return allStatusesEqual ? first : 'Mixed States';
}

/**
 * Given an array of recon mismatches, gets you the lowest StartTime and highest EndTime across all the mismatches.
 *
 * If the mismatches array length is 0, undefined is returned.
 */
export function reduceReconMismatchStartAndEndTimes(reconMismatches: ReconMismatch[]):
  | {
      StartTime: Date;
      EndTime: Date;
    }
  | undefined {
  if (reconMismatches.length === 0) {
    return undefined;
  }

  return {
    StartTime: reconMismatches.map(r => new Date(r.StartTime)).sort()[0],
    EndTime: reconMismatches
      .map(r => new Date(r.EndTime))
      .sort()
      .reverse()[0],
  };
}

/**
 * Given an array of recon mismatches, reduces the mismatches to one amount per currency.
 *
 * For each currency, we create an aggregate mismatch by summing all the amounts of mismatches within that currency.
 */
export function reduceReconMismatchAmounts(reconMismatches: ReconMismatch[]): { amount: Big; asset: string }[] {
  const amountsByCurrency = new Map<string, Big>();
  for (const mismatch of reconMismatches) {
    const bigAmount = toBig(mismatch.MissingDelta);
    if (!bigAmount) {
      continue;
    }
    const existingBigAmount = amountsByCurrency.get(mismatch.Asset) ?? Big(0);
    amountsByCurrency.set(mismatch.Asset, existingBigAmount.plus(bigAmount));
  }

  return [...amountsByCurrency.entries()].map(([asset, amount]) => ({ asset, amount: amount }));
}
