import Big from 'big.js';
import { EMPTY_OBJECT, bigMin } from '../utils';
import { SideEnum, type ExposureStatusEnum } from './types';

export class BuyingPower {
  readonly AvailableBuy?: string;
  readonly AvailableSell?: string;
  readonly Counterparty!: string;
  readonly Currency!: string;
  readonly MaxBuyOrderSize?: string;
  readonly MaxSellOrderSize?: string;
  readonly MarketAccount!: string;
  readonly Status!: ExposureStatusEnum;
  readonly Symbol!: string;
  readonly Timestamp!: string;
  readonly Text?: string;

  getMaxBuy({ useMaxOrderSize }: { useMaxOrderSize?: boolean } = EMPTY_OBJECT) {
    const buySizeBig = bigMin(this.MaxBuyOrderSize, this.AvailableBuy);
    return useMaxOrderSize ? buySizeBig?.toFixed() : this.AvailableBuy;
  }

  getMaxSell({ useMaxOrderSize }: { useMaxOrderSize?: boolean } = EMPTY_OBJECT) {
    const sellSizeBig = bigMin(this.MaxSellOrderSize, this.AvailableSell);
    return useMaxOrderSize ? sellSizeBig?.toFixed() : this.AvailableSell;
  }

  getMaxSize({ useMaxOrderSize, side }: { useMaxOrderSize?: boolean; side?: SideEnum | string } = EMPTY_OBJECT) {
    if (side === SideEnum.Buy) {
      return this.getMaxBuy({ useMaxOrderSize });
    } else if (side === SideEnum.Sell) {
      return this.getMaxSell({ useMaxOrderSize });
    } else {
      // Twoway
      const buySize = this.getMaxBuy({ useMaxOrderSize });
      const sellSize = this.getMaxSell({ useMaxOrderSize });
      return Big(buySize || '0').gt(sellSize || '0') ? buySize : sellSize;
    }
  }

  get rowID() {
    return `${this.MarketAccount}-${this.Currency}`;
  }

  constructor(data: BuyingPower) {
    Object.assign(this, data);
  }
}
