import styled from 'styled-components';
import { IconButtonWrapper } from '../Button/IconButton/styles';
import { VStack } from '../Core';
import { DayPicker } from '../DayPicker';

export const DateTimePickerWrapper = styled.span`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const DateTimeWrapper = styled(VStack)`
  ${DayPicker} {
    padding: 0;
  }
`;

export const DurationHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -${({ theme }) => theme.spacingDefault}px;
  ${IconButtonWrapper} {
    margin-right: -${({ theme }) => theme.spacingDefault}px;
  }
`;

export const DurationButtons = styled.div`
  display: grid;
  width: 90px;
  grid-gap: ${({ theme }) => theme.spacingTiny}px;
  grid-template-columns: auto auto;
`;

export const TimePickerWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacingSmall}px;
  width: 100%;
  display: flex;
  justify-content: center;
`;
