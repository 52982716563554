import type { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { batch } from 'react-redux';

// Rather than dispatching each time there is a change, which as the dependencies grow could harm performance,
// we can batch all actions and dispatching them as a single batch (single repaint) in frequent intervals
export class BufferedDispatcher {
  private queue: AnyAction[] = [];
  private intervalID: NodeJS.Timeout;

  // We don't want to buffer the initial dispatch of any reference data so there is no gap in having the data ready
  private processedTypes: Set<string> = new Set();

  public constructor(private _dispatch: Dispatch<AnyAction>) {
    this.intervalID = setInterval(() => {
      if (this.queue.length) {
        const copy = this.queue.slice();
        this.queue = [];
        batch(() => {
          copy.forEach(action => _dispatch(action));
        });
      }
    }, 2000);
  }

  // Once initialised and subsequent ref data update happens to be empty array/map then we should still accept it (clear what we have)
  public isTypeInitialised(type: string): boolean {
    return this.processedTypes.has(type);
  }

  public dispatch(action: AnyAction) {
    const actionType = action.type;
    if (this.processedTypes.has(actionType)) {
      // All reference data are state of world updates, if we dispatch a ref data update before it is propagated we are
      // safe to discard existing (outdated now) and simply keep the newest one
      this.queue = this.queue.filter(a => a.type !== action.type);
      this.queue.push(action);
    } else {
      this.processedTypes.add(actionType);
      this.dispatchImmediate(action);
    }
  }

  public dispatchImmediate(action: AnyAction) {
    this._dispatch(action);
  }

  public destroy() {
    clearInterval(this.intervalID);
  }
}
