import styled from 'styled-components';
import { formControlCSS } from '../styles';
import type { FormControlProps } from '../types';

export const TextAreaWrapper = styled.label<FormControlProps<HTMLTextAreaElement>>`
  ${formControlCSS}
  height: auto;
  padding: 0;

  textarea {
    padding: 0 ${({ theme }) => theme.spacingDefault}px;
  }
`;
