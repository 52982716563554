import styled from 'styled-components';
import { NumberInput } from '../../Form';

export const IntervalInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const StyledInput = styled(props => <NumberInput {...props} />)`
  &:after {
    pointer-events: none;
    content: ' ';
    position: absolute;
    right: 0.5rem;
    top: 0;
    bottom: 0;
    width: 0.5rem;
    display: block;
    ${({ disabled, theme }) =>
      `background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 5'%3E%3Cpath fill='${encodeURIComponent(
        disabled ? theme.colorTextSubtle : theme.colorTextDefault
      )}' fillRule='nonzero' d='M1 0L0 1l4 4 4-4-1-1-3 3z'/%3E%3C/svg%3E") no-repeat center center;`}
  }
  select {
    width: auto;
    flex: 0;
    padding: 0 1rem 0 0;
    background: none;
    border: none;
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -${({ theme }) => theme.spacingDefault}px;
`;

export const OptionButtons = styled.div`
  display: grid;
  width: 90px;
  grid-gap: ${({ theme }) => theme.spacingTiny}px;
  grid-template-columns: auto auto;
`;

export const OptionsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
