import type { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';

import { Flex, HStack, type BoxProps } from '../../Core';
import { Tooltip } from '../../Tooltip';
import { Label } from '../styles';
import { FormControlSizes } from '../types';
import { OverflowFormMessage } from './OverflowFormMessage';
import { FormGroupWrapper, HelpText } from './styles';

export { FormActions, FormMessage } from './styles';

export const FormGroup = styled(
  ({
    label,
    labelSuffix,
    labelWidth,
    controlId,
    children,
    help,
    error,
    warning,
    disabled,
    size = FormControlSizes.Default,
    inline = false,
    style,
    tooltip: _tooltip,
    portalizeTooltip,
    ...props
  }: FormGroupProps) => {
    const { gap = 0 } = props;

    const renderTooltip = _tooltip === '' ? false : !!_tooltip;
    const tooltip = renderTooltip ? _tooltip : null;

    return (
      <FormGroupWrapper
        disabled={disabled}
        size={size}
        style={style}
        inline={inline}
        data-testid="form-group"
        {...props}
      >
        {label && (
          <HStack justifyContent="space-between" mb={inline ? 0 : 'spacingSmall'} h={inline ? 0 : 'spacingComfortable'}>
            <Flex w={labelWidth}>
              <Label tooltip={renderTooltip} htmlFor={controlId}>
                <Tooltip tooltip={tooltip} usePortal={portalizeTooltip}>
                  {label}
                </Tooltip>
              </Label>
            </Flex>
            {labelSuffix && (
              <Flex>
                <Label htmlFor={controlId}>{labelSuffix}</Label>
              </Flex>
            )}
          </HStack>
        )}
        {children}
        {(error || warning) && (
          <OverflowFormMessage
            data-testid="form-message-error"
            pl={gap}
            ml={inline ? labelWidth : undefined}
            error={!!error}
            warning={!!warning}
          >
            {/* Ensure error takes precedence */}
            {error ? error : warning}
          </OverflowFormMessage>
        )}
        {help && !error && (
          <HelpText data-testid="form-group-help-text" ml={inline ? labelWidth : undefined}>
            {help}
          </HelpText>
        )}
      </FormGroupWrapper>
    );
  }
)``;

export interface FormGroupProps extends BoxProps {
  labelWidth?: CSSProperties['width'];
  controlId?: string;
  disabled?: boolean;
  label?: ReactNode;
  labelSuffix?: ReactNode;
  help?: ReactNode;
  error?: ReactNode;
  warning?: ReactNode;
  tooltip?: ReactNode;
  portalizeTooltip?: boolean;
  inline?: boolean;
  size?: FormControlSizes;
  children?: ReactNode;
  style?: CSSProperties;
}
