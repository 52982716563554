export enum FuseResultMatchEnum {
  Label,
  Description,
}

export interface FuseResultMatchProps {
  label?: string;
  indices?: readonly any[];
  highlightAll?: boolean;
  type?: FuseResultMatchEnum;
  disabled?: boolean;
}
