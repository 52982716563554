import { sortBy } from 'lodash';
import { memo, useCallback, useEffect, useState } from 'react';
import { defineMessages } from 'react-intl';
import { useCurrenciesContext } from '../../contexts/CurrenciesContext';
import { useSecuritiesContext } from '../../contexts/SecuritiesContext';
import { useIntl } from '../../hooks';
import type { Currency } from '../../types/Currency';
import { OrderFormSides } from '../../types/OrderFormSides';
import { CurrencySelect } from './CurrencySelect';
import { Preposition, SecuritySelectWrapper } from './styles';

const messages = defineMessages({
  with: {
    defaultMessage: 'with',
    id: 'CurrencyPairSelector.with',
  },
  for: {
    defaultMessage: 'for',
    id: 'CurrencyPairSelector.for',
  },
});

export const CurrencyPairSelector = memo(
  ({ disabled, side, symbol, homeCurrency = '', onChange }: CurrencyPairSelectorProps) => {
    const [baseCurrency, setBaseCurrency] = useState<string>();
    const [quoteCurrency, setQuoteCurrency] = useState<string>();
    const [invalid, setInvalid] = useState<boolean>(false);
    const [baseCurrencies, setBaseCurrencies] = useState<Currency[]>([]);
    const [quoteCurrencies, setCounterCurrencies] = useState<Currency[]>([]);
    const { securitiesList } = useSecuritiesContext();
    const { currenciesBySymbol } = useCurrenciesContext();
    const { formatMessage } = useIntl();

    const updateSecurity = useCallback(
      ({ quoteCurrency, baseCurrency }: { quoteCurrency?: string; baseCurrency?: string }) => {
        setBaseCurrency(baseCurrency);
        setQuoteCurrency(quoteCurrency);
        if (securitiesList != null) {
          const security = securitiesList.find(
            security => security.BaseCurrency === baseCurrency && security.QuoteCurrency === quoteCurrency
          );
          if (security != null) {
            onChange(security.Symbol);
          }
          setInvalid(security == null);
        }
      },
      [onChange, securitiesList]
    );

    useEffect(() => {
      if (securitiesList != null && currenciesBySymbol != null) {
        const baseCurrenciesMap = securitiesList.reduce((res, s) => {
          res.set(s.BaseCurrency, currenciesBySymbol?.get(s.BaseCurrency));
          return res;
        }, new Map<string, Currency | undefined>());
        setBaseCurrencies(sortBy(Array.from(baseCurrenciesMap.values()).compact<true>(), item => item.Symbol));
        const quoteCurrenciesMap = securitiesList
          .filter(s => s.BaseCurrency === baseCurrency)
          .reduce((res, s) => {
            res.set(s.QuoteCurrency, currenciesBySymbol?.get(s.QuoteCurrency));
            return res;
          }, new Map<string, Currency | undefined>());
        const quoteCurrencies = sortBy(Array.from(quoteCurrenciesMap.values()).compact<true>(), item => item.Symbol);
        setCounterCurrencies(quoteCurrencies);
        if (quoteCurrenciesMap != null && !quoteCurrenciesMap.has(quoteCurrency ?? '')) {
          const newQuoteCurrency = quoteCurrenciesMap.has(homeCurrency)
            ? homeCurrency
            : quoteCurrencies && quoteCurrencies[0]?.Symbol;
          setQuoteCurrency(newQuoteCurrency);
          updateSecurity({ baseCurrency, quoteCurrency: newQuoteCurrency });
        }
      }
    }, [securitiesList, baseCurrency, quoteCurrency, updateSecurity, homeCurrency, currenciesBySymbol]);

    useEffect(() => {
      if (securitiesList != null) {
        if (symbol != null) {
          const security = securitiesList.find(s => s.Symbol === symbol);
          setInvalid(security == null);
          if (security) {
            const { BaseCurrency, QuoteCurrency } = security;
            setBaseCurrency(BaseCurrency);
            setQuoteCurrency(QuoteCurrency);
          }
        }
      }
    }, [symbol, securitiesList]);

    const handleQuoteCurrency = useCallback(
      (quoteCurrency: string | undefined): void => updateSecurity({ baseCurrency, quoteCurrency }),
      [updateSecurity, baseCurrency]
    );
    const handleBaseCurrency = useCallback(
      (baseCurrency: string | undefined): void => updateSecurity({ baseCurrency, quoteCurrency }),
      [updateSecurity, quoteCurrency]
    );

    const preposition = side === OrderFormSides.Buy ? formatMessage(messages.with) : formatMessage(messages.for);

    return (
      <SecuritySelectWrapper>
        <CurrencySelect
          disabled={disabled}
          currencies={baseCurrencies}
          currency={baseCurrency}
          onChange={handleBaseCurrency}
          invalid={invalid}
        />
        <Preposition disabled={disabled}>{preposition}</Preposition>
        <CurrencySelect
          disabled={disabled}
          currencies={quoteCurrencies}
          currency={quoteCurrency}
          onChange={handleQuoteCurrency}
          invalid={invalid}
        />
      </SecuritySelectWrapper>
    );
  },
  (
    {
      disabled: prevDisabled,
      side: prevSide,
      symbol: prevSymbol,
      homeCurrency: prevHomeCurrency,
      onChange: prevOnChange,
    }: CurrencyPairSelectorProps,
    {
      disabled: nextDisabled,
      side: nextSide,
      symbol: nextSymbol,
      homeCurrency: nextHomeCurrency,
      onChange: nextOnChange,
    }: CurrencyPairSelectorProps
  ) => {
    return (
      Object.is(prevDisabled, nextDisabled) &&
      Object.is(prevSide, nextSide) &&
      Object.is(prevSymbol, nextSymbol) &&
      Object.is(prevHomeCurrency, nextHomeCurrency) &&
      Object.is(prevOnChange, nextOnChange)
    );
  }
);

interface CurrencyPairSelectorProps {
  disabled?: boolean;
  side?: OrderFormSides;
  symbol?: string;
  homeCurrency?: string;
  onChange: (symbol: string) => void;
}
