import { animated } from '@react-spring/web';
import type { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { parseColor } from '../../styles';
import { Box, Flex } from '../Core';
import type { PartPlacement } from './types';

export const SectionContainer = styled(Flex)`
  justify-content: flex-start;
  overflow: hidden;
  border-radius: 2px;
  height: 100%;
`;

export const PercentageText = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.spacingSmall}px;
  left: 50%;
  width: fit-content;
  transform: translateX(-50%);
  font-size: ${({ theme }) => theme.fontSizeTiny}rem;
`;

export const Section = styled(animated.div)<{
  background: CSSProperties['background'];
  isFocusMode: boolean;
  $isSectionFocused: boolean;
}>`
  position: relative;
  height: 100%;
  display: flex;
  ${({ theme, background }) =>
    background && `background: ${typeof background === 'string' ? parseColor(theme, background) : background}`};
  transition: opacity 0.2s;

  ${({ isFocusMode, $isSectionFocused }) =>
    isFocusMode &&
    !$isSectionFocused &&
    css`
      opacity: 0.2;
    `};
`;

export const DistributionSection = styled(Box)`
  flex-grow: 1;
  height: 100%;
  transition: opacity 0.2s;
`;

export const DistributionPart = styled(animated.div)<{
  background?: CSSProperties['background'];
  indicatorWidth: CSSProperties['minWidth'];
}>`
  position: relative;
  height: 100%;
  transition: opacity 0.2s, background-color 0.2s;
  z-index: 1;
  pointer-events: none;
  background: inherit;

  ${({ theme, background }) =>
    background &&
    css`
      background: ${typeof background === 'string' ? parseColor(theme, background) : background};
    `};

  &::after {
    content: '';
    position: absolute;
    left: calc(100% - 1px);
    background: inherit;
    height: 100%;
    min-width: ${({ indicatorWidth }) => indicatorWidth};
  }
`;

export const PartWrapper = styled(animated.div)<{ $order: number }>`
  position: relative;
  ${({ $order }) =>
    $order === 0 &&
    css`
      ${DistributionPart} {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
      }
    `}
`;

export const PartsWrapper = styled(Flex)<{ partPlacement: PartPlacement }>`
  position: absolute;
  ${({ partPlacement }) => css`
    ${partPlacement === 'above' &&
    css`
      bottom: 100%;
      ${DistributionPart}::after {
        top: 100%;
      }
    `}
    ${partPlacement === 'inside' &&
    css`
      ${DistributionPart}::after {
        top: 0;
      }
      ${PercentageText} {
        bottom: calc(100% + ${({ theme }) => theme.spacingSmall}px);
      }
    `}
    ${partPlacement === 'below' &&
    css`
      top: 100%;
      ${DistributionPart}::after {
        top: -100%;
      }
      ${PercentageText} {
        top: ${({ theme }) => theme.spacingSmall}px;
        bottom: unset;
      }
    `}
  `}
`;
