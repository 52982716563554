import { useEffect, useMemo, useRef, useState } from 'react';
import { Subject, type Observable } from 'rxjs';
import { IndexedDBCache, RDCStatus, type RDC_CACHEABLE_TYPE } from '../providers/RefDataCache';
import { useRefDataCache } from '../providers/RefDataCacheProvider';
import type { RequestStream, SubscriptionResponse } from '../types';
import { dateComparator } from '../utils';
import { useConstant } from './useConstant';
import { useSubscription } from './useSubscription';

const SUB_OPTIONS = {
  replay: false,
  loadAll: true,
} as const;

export function useCachedSubscription<TData extends AnyObject = AnyObject>(
  request: CacheableRequest
): {
  isLoading: boolean;
  data: Observable<SubscriptionResponse<TData>>;
} {
  // Tracks the underlying request we are going to make to the backend. This will be modified based on the latest record
  // we are able to read from the cache.
  const activeRequest = useRef<CacheableRequest | RequestStream | null>(null);

  // Tracks if the cache is currently in the process of reading data. This prevents us from triggering another read. It
  // also helps trigger the websocket subscription after the cache has been read.
  const [isCacheLoading, setIsCacheLoading] = useState(false);

  // Track if we have sent a response to the caller so we are careful to only pass the initial: true flag when consumers
  // should process the message with a cleared state.
  const hasSentFirstResponse = useRef(false);

  const [cachedRows, setCachedRows] = useState<TData[]>([]);

  // Underlying websocket subscription. This starts as null as we can only make the request once we have read from the
  // cache and know the correct StartDate for our subscription. We want to narrow the request to only load the data that
  // has changed since the last time we wrote to the cache.

  // We also intentionally only subscribe after passing back records that we found in the cache to the caller. This is
  // to prevent subcribers from getting updates to an entity followed by a prior revision of that entity which already
  // existed in the cache. By waiting until after the cache has been read, we ensure that the subscriber always gets
  // events in order.
  const { data: wsSubscription, isLoading: isWSSubLoading } = useSubscription<TData>(
    activeRequest.current,
    SUB_OPTIONS
  );
  const { cache, isEnabled } = useRefDataCache();

  // Combined subject that is written to both after reading from the cache as well as when receiving data from the websocket.
  const subject = useConstant(new Subject<SubscriptionResponse<TData>>());

  const isCachingEnabled = cache.status !== RDCStatus.Unsupported && isEnabled;

  useEffect(() => {
    if (request == null || request.name == null) {
      activeRequest.current = null;
      return;
    }

    if (activeRequest.current !== null) {
      return;
    }

    if (isCacheLoading === true) {
      return;
    }

    if (isCacheableRequest(request) && isCachingEnabled) {
      setIsCacheLoading(true);
      // Read all of the records from the cache.
      // FYI this ended up being faster then just reading the "latest" record first to start the backend subscription
      // and then reading the rest of the records.
      cache.readAll(request.name).then(records => {
        // eslint-disable-next-line no-console
        console.debug(`read ${records.length} ${request.name} from cache`);

        let latestRecordTimestamp: string | undefined = undefined;
        // If there are records, format them as if they were records we got from the websocket
        if (records.length > 0) {
          setCachedRows(records as TData[]);
          // Get the latest record from the cache and use it's timestamp to filter the subscription
          latestRecordTimestamp = records.reduce((prev, curr) => {
            return dateComparator(prev.Timestamp, curr.Timestamp) > 0 ? prev : curr;
          }).Timestamp;
        }
        activeRequest.current = {
          ...request,
          StartDate: latestRecordTimestamp,
        };
        setIsCacheLoading(false);
      });
      return;
    }

    // Fallback to subscribe if cache is not supported or request is not cacheable
    if (activeRequest.current === null) {
      activeRequest.current = request;
    }
  }, [cache, isCacheLoading, isCachingEnabled, request]);

  useEffect(() => {
    const wsSub = wsSubscription.subscribe(response => {
      // Include the cached response on first event
      const responseWithCacheRecords = response.data.concat(cachedRows);
      subject.next({
        ...response,
        data: responseWithCacheRecords,
        initial: !hasSentFirstResponse.current,
      });
      // Once cached response has been sent to caller clear state to prevent memory leak and resending of cached data
      setCachedRows([]);
      hasSentFirstResponse.current = true;
      if (IndexedDBCache.isCacheable(response.type) && isCachingEnabled) {
        // eslint-disable-next-line no-console
        console.debug(`writing ${response.data.length} ${response.type} to cache`);
        cache.write(response.type, response.data);
      }
    });
    return () => wsSub.unsubscribe();
  }, [cache, cachedRows, isCachingEnabled, subject, wsSubscription]);

  const observable = useMemo(() => subject.asObservable(), [subject]);

  // isLoading will be true until we have read from the cache and subscribed to the websocket. Technically we could set
  // isLoading to false if the request is just null and there is no intention of this hook making any request but I find
  // that harder for consumers to work with.
  const isLoading = isWSSubLoading || activeRequest.current === null || isCacheLoading;

  return {
    isLoading,
    data: observable,
  };
}

interface CacheableRequest extends RequestStream {
  name: RDC_CACHEABLE_TYPE;
  StartDate?: string | undefined;
}

function isCacheableRequest(request: any): request is CacheableRequest {
  if (request == null || request.name == null) {
    return false;
  }
  return IndexedDBCache.isCacheable(request.name);
}
