import type { UseMultipleSelectionReturnValue } from 'downshift';
import type { RefObject } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import {
  CheckboxFuseAutocompleteDropdown,
  type CheckboxFuseAutocompleteDropdownProps,
} from '../../../Form/AutocompleteDropdown';
import { Input } from '../../../Form/Input';
import type { FilterableProperty } from '../types';
import { DropdownResultsHeader } from './DropdownResultsHeader';

const messages = defineMessages({
  filterOptions: {
    defaultMessage: 'Filter options',
    id: 'Filters.FilterBuilder.RHS.filterOptions',
  },
});

type FilterCheckboxDropdownProps<T> = CheckboxFuseAutocompleteDropdownProps<T> & {
  inputRef?: RefObject<HTMLInputElement>;
  property: FilterableProperty<string, T>;
  onClearAll: () => void;
  onSelectAll: () => void;
} & Pick<
    UseMultipleSelectionReturnValue<T>,
    'getDropdownProps' | 'addSelectedItem' | 'removeSelectedItem' | 'selectedItems'
  >;

export const FilterCheckboxDropdown = <T,>({
  inputRef,
  property,
  onClearAll,
  onSelectAll,
  getDropdownProps,
  addSelectedItem,
  removeSelectedItem,
  selectedItems,
  ...props
}: FilterCheckboxDropdownProps<T>) => {
  const { getInputProps } = props;
  const { formatMessage } = useIntl();
  const showClearAll = selectedItems.length > 0;
  const showSelectAll = !showClearAll && property.control === 'multi-select' && property.allowSelectAll;

  return (
    <CheckboxFuseAutocompleteDropdown
      selectedItems={selectedItems}
      addSelectedItem={addSelectedItem}
      removeSelectedItem={removeSelectedItem}
      childrenAboveResults={
        <>
          <Input
            {...getInputProps(getDropdownProps({ ref: inputRef }))}
            placeholder={formatMessage(messages.filterOptions)}
          />

          <DropdownResultsHeader<T>
            property={property}
            showClearAll={showClearAll}
            showSelectAll={showSelectAll}
            onClearAll={onClearAll}
            onSelectAll={onSelectAll}
          />
        </>
      }
      {...props}
    />
  );
};
