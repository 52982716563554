import { desaturate } from 'polished';
import styled, { css } from 'styled-components';
import { Highlight } from '../FormattedNumber/styles';
import { StepType } from './types';

export const QTY_LIMIT = 280;

export const PriceLadderWrapper = styled.div<{ height?: number; width?: number }>`
  background: ${({ theme }) => theme.backgroundContent};
  color: ${({ theme }) => theme.colorTextDefault};
  height: ${({ height }) => (height ? `${height}px` : '100%')};
  width: ${({ width }) => (width ? `${width}px` : '100%')};
  display: flex;
  flex-direction: column;
`;

export const Labels = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacingDefault}px ${theme.spacingMedium}px`};
`;

export const Header = styled.div`
  display: flex;
  border-bottom: solid 1px ${({ theme }) => theme.borderColorDivider};
  padding: ${({ theme }) => `${theme.spacingDefault}px`};
`;

export const Ladder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 0;
`;

export const Footer = styled.div`
  display: flex;
  border-top: solid 1px ${({ theme }) => theme.borderColorDivider};
  padding: ${({ theme }) => `${theme.spacingDefault}px`};
`;

const label = css`
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSizeTiny}rem;
  color: ${({ theme }) => theme.colorTextDefault};
`;

export const BidLabel = styled.div`
  ${label};
  color: ${({ theme }) => theme.colorTextPositive};
  flex: 1;
`;

export const OfferLabel = styled.div`
  ${label}
  color: ${({ theme }) => theme.colorTextNegative};
  flex: 1;
  text-align: right;
`;

export const IncrementWrapper = styled.div`
  margin: -${({ theme }) => theme.spacingDefault}px;
  display: flex;
  align-items: center;
`;

export const LeftLabel = styled.div`
  ${label};
  width: 17.5%;
  text-align: left;
  padding: ${({ theme }) => `0 ${theme.spacingDefault}px`};
`;

export const PriceLabel = styled.div`
  ${label};
  width: 30%;
  flex: 1;
  text-align: center;
`;

export const RightLabel = styled.div`
  ${label};
  width: 17.5%;
  text-align: right;
  padding: ${({ theme }) => `0 ${theme.spacingDefault}px`};
`;

export const Bar = styled.div`
  width: calc(50% - ${({ theme }) => theme.spacingDefault}px);
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${({ theme }) => theme.spacingDefault}px;
  right: ${({ theme }) => theme.spacingDefault}px;
`;

export const SizeBar = styled.div.attrs<BarProps>(({ width }) => ({
  style: {
    transform: `scaleX(${width})`,
  },
}))<BarProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  transition: transform 100ms linear;
`;

export const TotalBar = styled.div.attrs<BarProps>(({ width }) => ({
  style: {
    transform: `scaleX(${width}) scaleY(1.05)`,
  },
}))<BarProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  transition: transform 100ms linear;
`;

export const Values = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: ${({ theme }) => `0 ${theme.spacingDefault}px`};
`;

export const PriceValue = styled.div<{ filler: boolean }>`
  width: 30%;
  text-align: center;
  opacity: ${({ filler }) => (filler ? 0.3 : 0.75)};
  padding: ${({ theme }) => `${theme.spacingSmall}px ${theme.spacingDefault}px`};
`;

export const SizeValue = styled.div<{ filler: boolean }>`
  flex: 1;
  width: 17.5%;
  color: ${({ theme }) => theme.colorTextImportant};
  opacity: ${({ filler }) => (filler ? 0.3 : 0.75)};
  padding: ${({ theme }) => `${theme.spacingSmall}px ${theme.spacingDefault}px`};
`;

export const RowWrapper = styled.div<{ type?: StepType; width?: number }>`
  flex: 1 0 0;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: ${({ theme }) => theme.fontSizeTiny}rem;
  transition: background-color 200ms ease;

  ${({ type, theme }) =>
    type != null &&
    `
    cursor: pointer;

    &:hover {
      background: ${theme.backgroundContentHover};
      color: ${theme.colorTextAttention};

      ${Highlight} {
        color: ${theme.colorTextAttention};
      }

      ${TotalBar} {
        background: ${desaturate(
          0.1,
          type === StepType.Bid ? theme.backgroundPositiveSubtle : theme.backgroundNegativeSubtle
        )};
      }

      ${SizeBar} {
        background: ${desaturate(0.1, type === StepType.Bid ? theme.backgroundPositive : theme.backgroundNegative)};
      }

      ${PriceValue}, ${SizeValue} {
        font-size: ${theme.fontSizeSmall}rem;
        opacity: 1;
      }
    }`}

  ${Values} {
    flex-direction: ${({ type }) => (type === StepType.Offer ? 'row' : 'row-reverse')};
  }

  ${Bar} {
    margin-left: ${({ type, theme }) => (type === StepType.Offer ? `calc(50% - ${theme.spacingDefault}px)` : '0')};
    flex-direction: ${({ type }) => (type === StepType.Offer ? 'row' : 'row-reverse')};
    margin-top: -1px;
    margin-bottom: -1px;
  }

  ${TotalBar} {
    background: ${({ theme, type }) =>
      type === StepType.Bid ? theme.backgroundPositiveSubtle : theme.backgroundNegativeSubtle};
    transform-origin: ${({ type }) => (type === StepType.Bid ? 'right' : 'left')} center;
  }

  ${SizeBar} {
    background: ${({ theme, type }) => (type === StepType.Bid ? theme.backgroundPositive : theme.backgroundNegative)};
    transform-origin: ${({ type }) => (type === StepType.Bid ? 'right' : 'left')} center;
  }

  ${SizeValue} {
    text-align: ${({ type }) => (type === StepType.Bid ? 'left' : 'right')};
  }

  ${PriceValue} {
    margin-left: ${({ type }) => (type === StepType.Offer ? '35%' : '0')};
    margin-right: ${({ type }) => (type === StepType.Bid ? '35%' : '0')};
  }
`;

export const SpreadRowWrapper = styled(RowWrapper)`
  border-top: solid 1px ${({ theme }) => theme.borderColorDivider};
  border-bottom: solid 1px ${({ theme }) => theme.borderColorDivider};
  justify-content: space-between;
  color: ${({ theme }) => theme.colorTextSubtle};
  align-items: center;
  font-size: ${({ theme }) => theme.fontSizeDefault}rem;
  min-height: 32px;
`;

export const TopPrice = styled.div`
  cursor: pointer;
  padding: ${({ theme }) => `${theme.spacingSmall}px ${theme.spacingMedium}px`};
`;

export const Spread = styled.div`
  font-size: ${({ theme }) => theme.fontSizeTiny}rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > * {
    padding: 0 ${({ theme }) => theme.spacingSmall}px;
  }
`;

export const Increment = styled.span`
  min-width: 64px;
  text-align: center;
`;

const side = css`
  width: 50%;
  position: absolute;
  height: 100%;
  border: none;
  background: transparent;
  z-index: 10;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const SellSide = styled.button`
  ${side}

  right: 0;
`;

export const BuySide = styled.button`
  ${side}

  left: 0;
`;

type BarProps = { type?: StepType; width: number; totalWidth?: number };
