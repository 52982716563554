import styled from 'styled-components';
import { parseColor } from '../../styles';

export const MeterWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const BarWrapper = styled.div<{
  background?: string;
  roundLeftEdge: boolean;
  roundRightEdge: boolean;
  height?: number;
}>`
  flex: 1;
  display: flex;
  gap: ${({ theme }) => theme.baseSize / 16}px;
  height: ${({ theme, height }) => height ?? theme.baseSize / 2}px;
  border-top-left-radius: ${({ theme, roundLeftEdge }) => (roundLeftEdge ? theme.baseSize / 2 / 2 : 0)}px;
  border-bottom-left-radius: ${({ theme, roundLeftEdge }) => (roundLeftEdge ? theme.baseSize / 2 / 2 : 0)}px;
  border-top-right-radius: ${({ theme, roundRightEdge }) => (roundRightEdge ? theme.baseSize / 2 / 2 : 0)}px;
  border-bottom-right-radius: ${({ theme, roundRightEdge }) => (roundRightEdge ? theme.baseSize / 2 / 2 : 0)}px;

  background: ${({ theme, background }) => (background ? parseColor(theme, background) : theme.backgroundProgressBar)};
  overflow: hidden;
`;

export interface MeterBarProps {
  width: number;
  color?: string;
  align?: 'left' | 'right';
  animate?: boolean;
  appearance?: 'filled' | 'dashed';
  minWidth?: number;
}

export const Bar = styled.div.attrs<Pick<MeterBarProps, 'width' | 'align' | 'animate'>>(
  ({ width, align = 'left' }) => ({
    style: { transform: `translateX(${align === 'left' ? -(100 - width) : 100 - width}%)` },
  })
)<MeterBarProps>`
  height: 100%;
  width: ${({ width }) => `${width}%`};
  &:only-child {
    width: 100%;
  }
  &:not(:only-child) {
    transform: none !important;
  }
  min-width: ${({ theme, minWidth }) => minWidth ?? theme.baseSize / 2}px;
  background: ${({ theme, color, appearance = 'filled' }) =>
    appearance === 'filled' ? (color ? parseColor(theme, color) : theme.backgroundProgressBarMeter) : undefined};
  border: ${({ theme, color, appearance = 'filled' }) =>
    appearance === 'filled'
      ? 'none'
      : `1px dashed ${color ? parseColor(theme, color) : theme.backgroundProgressBarMeter}`};
  transition: ${({ animate }) => (animate ? `200ms ease-out,` : ``)}} background 200ms ease;
  transform-origin: center ${({ align = 'left' }) => align};
`;
Bar.displayName = 'MeterBar';

export const Label = styled.div`
  min-width: ${({ theme }) => 3 * theme.baseSize}px;
  text-align: right;
  font-size: ${({ theme }) => theme.fontSizeTiny}rem;
  color: ${({ theme }) => theme.colorTextSubtle};
`;
