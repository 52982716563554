import type { CSSProperties } from 'react';
import styled from 'styled-components';

export interface StatProps {
  align?: CSSProperties['alignItems'];
}

export const Stat = styled.dl<StatProps>`
  display: flex;
  flex-direction: column;
  margin: 0;
  flex: 0;
  align-items: ${({ align }) => (align ? align : 'flex-start')};
`;

export const StatLabel = styled.dt`
  color: ${({ theme }) => theme.colorTextSubtle};
  text-transform: uppercase;
  white-space: nowrap;
  font-size: ${({ theme }) => theme.fontSizeSmall}rem;
  letter-spacing: 0.05em;
  margin-bottom: ${({ theme }) => theme.spacingSmall}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: ${({ theme }) => theme.fontWeightBold};
`;

export const StatValue = styled.dd`
  white-space: nowrap;
  color: ${({ theme }) => theme.colorTextImportant};
  margin: 0;
`;

export const StatGroup = styled.div`
  /*   display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
  gap: ${({ theme }) => theme.spacingSmall}px;

  > .${Stat} {
    flex: 1;
  } */

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > * {
    margin-left: ${({ theme }) => theme.spacingMedium}px;
    margin-right: ${({ theme }) => theme.spacingMedium}px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;
