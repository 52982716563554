import { useMemo, type CSSProperties } from 'react';
import styled, { useTheme } from 'styled-components';
import { UnifiedLiquidityEnum, type Security } from '../../types';
import { isDeribitLinearOption } from '../../utils';
import { CurrencyIcon } from '../CurrencyIcon';
import { Tooltip } from '../Tooltip';
import { UnifiedLiquidityTooltip } from '../UnifiedLiquidity';
import { Currency, DisplaySecurityWrapper, Wrapper } from './styles';

export const CurrencyPair = styled(
  ({
    security,
    quoteCurrencies,
    unifiedLiquidity,
    availableSymbols,
    showDisplaySymbol,
    ...props
  }: {
    security?: Security;
    quoteCurrencies?: string[];
    unifiedLiquidity?: UnifiedLiquidityEnum;
    availableSymbols?: string[];
    showDisplaySymbol?: boolean;
    style?: CSSProperties;
  }) => {
    if (security == null) {
      return null;
    }
    const showUnderlyingSymbols = unifiedLiquidity === UnifiedLiquidityEnum.Enabled;
    return (
      <Tooltip
        tooltip={security.Description ? <div>{security.Description}</div> : null}
        disabled={showUnderlyingSymbols}
        targetStyle={{ width: '100%', textAlign: 'center', display: 'inline-block' }}
      >
        {showDisplaySymbol ? (
          <DisplaySecurityWrapper {...props}>{security.DisplaySymbol}</DisplaySecurityWrapper>
        ) : (
          <Wrapper {...props}>
            <CurrencyPairPart security={security} currency={security.BaseCurrency} />
            <CurrencyPairPart
              security={security}
              currency={security.QuoteCurrency}
              currencies={quoteCurrencies}
              unifiedLiquidity={unifiedLiquidity}
              availableSymbols={availableSymbols}
            />
          </Wrapper>
        )}
      </Tooltip>
    );
  }
)``;

export const CurrencyPairPart = ({
  security,
  currency,
  currencies,
  unifiedLiquidity,
  availableSymbols,
}: {
  security: Security;
  currency: string;
  currencies?: string[];
  unifiedLiquidity?: UnifiedLiquidityEnum;
  availableSymbols?: string[];
}) => {
  const { fontSizeDefault, baseSize } = useTheme();
  const showUnifiedLiquidity = unifiedLiquidity === UnifiedLiquidityEnum.Enabled;

  // https://talostrading.atlassian.net/browse/UI-4062
  const currencyStr = useMemo(() => {
    return isDeribitLinearOption(security) ? [security.BaseCurrency, security.QuoteCurrency].join(' ') : currency;
  }, [security, currency]);

  return (
    <Tooltip
      disabled={!showUnifiedLiquidity || !currencies || currencies.length === 0}
      tooltip={<UnifiedLiquidityTooltip security={security} symbols={currencies} availableSymbols={availableSymbols} />}
    >
      <Currency>
        <CurrencyIcon stacked={showUnifiedLiquidity} currency={currency} size={fontSizeDefault * baseSize} />
        <span>
          {currencyStr}
          {showUnifiedLiquidity && '+'}
        </span>
      </Currency>
    </Tooltip>
  );
};
