import type Fuse from 'fuse.js';
import type { ReactNode } from 'react';
import type { FuseSearchObject } from '../../Form/Autocomplete/types';
import { FuseResultMatch } from '../../FuseResultMatch';
import { FuseResultMatchEnum } from '../../FuseResultMatch/types';
import { Description, TextWrapper, Title, Wrapper } from './styles';

export const FuseAutocompleteResult = <T,>(
  searchResult: Fuse.FuseResult<FuseSearchObject<T>>,
  disabled: boolean,
  renderPrefix?: ((item: T) => ReactNode) | undefined
) => {
  const labelMatch = searchResult.matches && searchResult.matches.find(match => match.key === 'label');
  const descriptionMatch = searchResult.matches && searchResult.matches.find(match => match.key === 'description');

  // checks if there are any matches between both the label and description
  const anyMatches =
    (labelMatch?.indices && labelMatch.indices.length > 0) ||
    (descriptionMatch?.indices && descriptionMatch.indices.length > 0);

  return (
    <Wrapper>
      {renderPrefix && renderPrefix(searchResult.item.item)}
      <TextWrapper>
        <Title disabled={disabled} data-testid="dropdown-result-label" title={searchResult.item.label}>
          <FuseResultMatch
            label={searchResult.item.label}
            indices={labelMatch?.indices}
            highlightAll={!anyMatches}
            type={FuseResultMatchEnum.Label}
            disabled={disabled}
          />
        </Title>
        {searchResult.item.description && (
          <Description disabled={disabled} data-testid="dropdown-result-description">
            <FuseResultMatch
              label={searchResult.item.description}
              indices={descriptionMatch?.indices}
              highlightAll={!anyMatches}
              type={FuseResultMatchEnum.Description}
              disabled={disabled}
            />
          </Description>
        )}
      </TextWrapper>
    </Wrapper>
  );
};
