import type { ICellRendererParams, ValueGetterParams } from 'ag-grid-community';
import { get, take, zip } from 'lodash';
import { useSecurity } from '../../../hooks';
import type { MarketDataSnapshot } from '../../../types';
import { Box, HStack, VStack } from '../../Core';
import { InlineFormattedNumber, NumberVariants } from '../../FormattedNumber';
import { Text } from '../../Text';
import { isRowHeightExpanded } from '../utils';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

export interface SecurityMarketDataColumnParams {
  /** The path where the column can find an object ("collection") of market data. Should be provided without a trailing dot (.) */
  marketDataCollectionPath: string;
}

export type SecurityMarketDataColumnValue = MarketDataSnapshot | undefined;

export const securityMarketData: ColDefFactory<Column<SecurityMarketDataColumnParams>> = column => ({
  ...baseColumn(column),
  valueGetter: ({ node }: ValueGetterParams<unknown>): SecurityMarketDataColumnValue => {
    const data = node?.data;
    const symbolField = column.field;
    const marketDataCollectionPath = column.params?.marketDataCollectionPath;
    if (!data || !symbolField || !marketDataCollectionPath) {
      return undefined;
    }

    const symbol = get(data, symbolField);
    const marketDataForSymbol = symbol ? get(data, `${marketDataCollectionPath}.${symbol}`) : undefined;
    return marketDataForSymbol;
  },
  cellRenderer: (params: ICellRendererParams<unknown, SecurityMarketDataColumnValue>) => {
    return <SecurityMarketData {...params} />;
  },
});

function SecurityMarketData({
  value: marketData,
  node,
  api,
}: ICellRendererParams<unknown, SecurityMarketDataColumnValue>) {
  const security = useSecurity(marketData?.Symbol);

  const isRowExpanded = isRowHeightExpanded(node, api);
  const bidsAndOffers = take(zip(marketData?.Bids, marketData?.Offers), isRowExpanded ? 5 : 1);

  return (
    <VStack gap="spacingDefault" w="100%">
      {bidsAndOffers.map(([bid, offer], index) => (
        <HStack w="100%" key={index}>
          <Text flex="1 0 0" textAlign="left">
            {bid?.Size ? `${bid?.Size}` : ''}
          </Text>
          <HStack justifyContent="center" gap="spacingDefault">
            <Box>
              {bid?.Price ? (
                <InlineFormattedNumber
                  number={bid?.Price}
                  increment={security?.DefaultPriceIncrement}
                  variant={NumberVariants.Positive}
                />
              ) : (
                '-'
              )}
            </Box>
            <Text>x</Text>
            <Box>
              {offer?.Price ? (
                <InlineFormattedNumber
                  number={offer?.Price}
                  increment={security?.DefaultPriceIncrement}
                  variant={NumberVariants.Negative}
                />
              ) : (
                '-'
              )}
            </Box>
          </HStack>
          <Text flex="1 0 0" textAlign="right">
            {offer?.Size ? `${offer?.Size}` : ''}
          </Text>
        </HStack>
      ))}
    </VStack>
  );
}
