import { useTheme, type DefaultTheme } from 'styled-components';
import type { TermSheetRecipient } from '../../types/LendingRelationship';

import { LoanSides } from '../../types/loanTypes';
import { ESignRecipientStatusEnum, LoanQuoteStatusEnum } from '../../types/types';
import { Icon, IconName } from '../Icons';
import { IndicatorDotVariants, IndicatorDotWrapper } from '../IndicatorDot';
import { Wrapper } from './styles';

export enum LoanQuoteStatusText {
  Review = 'Review',
  Canceled = 'Canceled',
  Rejected = 'Rejected',
  Error = 'Error',
  RequestSent = 'Request Sent',
  FirmRequestSent = 'Firm Request Sent',
  NewRequest = 'New Request',
  NewFirmRequest = 'New Firm Request',
  NeedToSign = 'Need to Sign',
  CptyToSign = 'Cpty to Sign',
  Accepted = 'Accepted',
  GeneratingTS = 'Generating TS',
  OfferSent = 'Offer Sent',
  CounterSent = 'Counter Sent',
  AllSigned = 'All Signed',
  Expired = 'Expired',
}

const getLoanStatusColor = (statusText: LoanQuoteStatusText | undefined, theme: DefaultTheme) => {
  const { colorTextPositive, colorTextPrimary, colorTextWarning } = theme;
  switch (statusText) {
    case LoanQuoteStatusText.NewRequest:
    case LoanQuoteStatusText.NewFirmRequest:
    case LoanQuoteStatusText.RequestSent:
    case LoanQuoteStatusText.FirmRequestSent:
      return colorTextPositive;
    case LoanQuoteStatusText.Review:
    case LoanQuoteStatusText.OfferSent:
    case LoanQuoteStatusText.CounterSent:
    case LoanQuoteStatusText.Accepted:
    case LoanQuoteStatusText.NeedToSign:
    case LoanQuoteStatusText.GeneratingTS:
    case LoanQuoteStatusText.CptyToSign:
      return colorTextPrimary;
    case LoanQuoteStatusText.Canceled:
    case LoanQuoteStatusText.Rejected:
    case LoanQuoteStatusText.Error:
    case LoanQuoteStatusText.Expired:
      return colorTextWarning;
    default:
      return colorTextPrimary;
  }
};

export const getLoanQuoteStatusText = ({
  status,
  side,
  revision,
  recipients,
  participant,
}: {
  status: LoanQuoteStatusEnum;
  side: LoanSides;
  revision: number;
  recipients: TermSheetRecipient[] | undefined;
  participant: string;
}) => {
  const borrowLoanQuoteStatusTextMapping: { readonly [key in LoanQuoteStatusEnum]: string } = {
    [LoanQuoteStatusEnum.PendingNew]: LoanQuoteStatusText.RequestSent,

    [LoanQuoteStatusEnum.LenderFirm]: LoanQuoteStatusText.Review,
    [LoanQuoteStatusEnum.BorrowerFirm]: LoanQuoteStatusText.CounterSent,
    [LoanQuoteStatusEnum.Filled]: LoanQuoteStatusText.Accepted,

    [LoanQuoteStatusEnum.TermSheetRequested]: LoanQuoteStatusText.GeneratingTS,
    [LoanQuoteStatusEnum.TermSheetSent]: LoanQuoteStatusText.NeedToSign,
    [LoanQuoteStatusEnum.TermSheetAllSigned]: LoanQuoteStatusText.AllSigned,

    [LoanQuoteStatusEnum.Canceled]: LoanQuoteStatusText.Canceled,
    [LoanQuoteStatusEnum.Rejected]: LoanQuoteStatusText.Rejected,
    [LoanQuoteStatusEnum.Error]: LoanQuoteStatusText.Error,
    [LoanQuoteStatusEnum.Expired]: LoanQuoteStatusText.Expired,
  };

  const lendLoanQuoteStatusTextMapping: { readonly [key in LoanQuoteStatusEnum]: string } = {
    [LoanQuoteStatusEnum.PendingNew]: LoanQuoteStatusText.NewRequest,

    [LoanQuoteStatusEnum.LenderFirm]: LoanQuoteStatusText.OfferSent,
    [LoanQuoteStatusEnum.BorrowerFirm]: LoanQuoteStatusText.Review,
    [LoanQuoteStatusEnum.Filled]: LoanQuoteStatusText.Accepted,

    [LoanQuoteStatusEnum.TermSheetRequested]: LoanQuoteStatusText.GeneratingTS,
    [LoanQuoteStatusEnum.TermSheetSent]: LoanQuoteStatusText.NeedToSign,
    [LoanQuoteStatusEnum.TermSheetAllSigned]: LoanQuoteStatusText.AllSigned,

    [LoanQuoteStatusEnum.Canceled]: LoanQuoteStatusText.Canceled,
    [LoanQuoteStatusEnum.Rejected]: LoanQuoteStatusText.Rejected,
    [LoanQuoteStatusEnum.Error]: LoanQuoteStatusText.Error,
    [LoanQuoteStatusEnum.Expired]: LoanQuoteStatusText.Expired,
  };

  const signer = recipients?.find(r => r.Participant === participant);

  let loanQuoteStatusText: string;
  if (signer?.Status === ESignRecipientStatusEnum.Signed && status === LoanQuoteStatusEnum.TermSheetSent) {
    return LoanQuoteStatusText.CptyToSign;
  } else if (side === LoanSides.Borrow) {
    if (revision === 0 && status === LoanQuoteStatusEnum.BorrowerFirm) {
      return LoanQuoteStatusText.FirmRequestSent;
    }
    loanQuoteStatusText = borrowLoanQuoteStatusTextMapping[status];
  } else {
    if (revision === 0 && status === LoanQuoteStatusEnum.BorrowerFirm) {
      return LoanQuoteStatusText.NewFirmRequest;
    }
    loanQuoteStatusText = lendLoanQuoteStatusTextMapping[status];
  }

  if (loanQuoteStatusText == null) {
    // TODO Sentry.captureMessage...
    return LoanQuoteStatusText.Review;
  }

  return loanQuoteStatusText;
};

const StatusIcon = ({ loanQuoteStatusText, ...props }) => {
  const defaultTheme = useTheme();
  const theme = props.theme ?? defaultTheme;
  const color = getLoanStatusColor(loanQuoteStatusText, useTheme() ?? theme);
  return <Icon icon={IconName.Circle} color={color} {...props} />;
};

type LoanQuoteStatusProps = {
  status: LoanQuoteStatusEnum;
  side: LoanSides;
  revision: number;
  recipients: TermSheetRecipient[] | undefined;
  participant: string;
  align?: 'right' | 'left';
  iconPlacement?: 'right' | 'left';
  text?: string;
  theme?: DefaultTheme;
  showLabel?: boolean;
};

export function LoanQuoteStatus({
  status,
  side,
  align = 'right',
  showLabel,
  text,
  revision,
  participant,
  recipients,
  ...props
}: LoanQuoteStatusProps) {
  const defaultTheme = useTheme();
  const theme = props.theme ?? defaultTheme;
  const loanQuoteStatusText = getLoanQuoteStatusText({ status: status, side, revision, participant, recipients });

  return (
    <Wrapper align={align} title={text} theme={theme} {...props}>
      {!showLabel ? loanQuoteStatusText : null}
      <IndicatorDotWrapper show={!!text} theme={theme} variant={IndicatorDotVariants.Warning}>
        <StatusIcon loanQuoteStatusText={loanQuoteStatusText} theme={theme} size={14} />
      </IndicatorDotWrapper>
    </Wrapper>
  );
}
