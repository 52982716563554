import styled from 'styled-components';
import { FuseResultMatchEnum } from './types';

export const ResultMatchHighlight = styled.span`
  color: ${({ theme }) => theme.colors.gray['100']};
  text-transform: none;
`;

export const ResultMatchHighlightAll = styled.span<{ type: FuseResultMatchEnum }>`
  color: ${({ theme, type }) => theme.colors.gray[type === FuseResultMatchEnum.Label ? '100' : '090']};
  text-transform: none;
`;
