import styled from 'styled-components';
import { Z_INDEX, parseColor } from '../../styles';
import { EXPANDABLE_HEADER_HEIGHT, EXPANDABLE_MIN_HEIGHT } from '../../styles/dimensions';
import { TabIndicator, TabListWrapper, TabWrapper } from '../Tabs';

export const DragHandle = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  background: transparent;
  transition: background 200ms ease, height 200ms ease;
  height: calc(var(--spacingSmall) * 1px);
  user-select: none;
  cursor: row-resize;
  z-index: 2;

  &.active,
  &:hover {
    height: 6px;
    background: ${({ theme }) => theme.colors.gray['070']};
  }

  &.hidden {
    display: none;
  }
`;

export const ExpandablePanelWrapper = styled.div.withConfig<{ height: number }>({
  shouldForwardProp: (prop, defaultValidatorFn) => prop !== 'height' && defaultValidatorFn(prop),
})`
  position: relative;
  z-index: ${Z_INDEX.blotters};
  transition: height 200ms ease;
  ${props => `height: ${props.height}px;`}
  width: 100%;
  flex: 0 0 auto;

  h2 {
    margin: 0;
  }
`;

export const ExpandablePanelHeader = styled.div<{ isMinimized: boolean }>`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: ${({ theme }) => `0 ${theme.spacingDefault}px 0 0`};
  background: ${({ theme }) => theme.backgroundFilledTabList};
  border-bottom: ${({ theme }) => `1px solid ${theme.backgroundBody}`};
  height: ${props => (props.isMinimized ? `${EXPANDABLE_MIN_HEIGHT}px` : `${EXPANDABLE_HEADER_HEIGHT}px`)};

  ${TabListWrapper},
  ${TabIndicator},
  ${TabWrapper} {
    height: ${props => (props.isMinimized ? `${EXPANDABLE_MIN_HEIGHT}px` : `${EXPANDABLE_HEADER_HEIGHT}px`)};
  }
`;

export const ExpandablePanel = styled.div.withConfig<{
  height: number;
  maxHeight: number;
  isContentExpanded?: boolean;
}>({
  shouldForwardProp: (prop, defaultValidatorFn) => prop !== 'height' && defaultValidatorFn(prop),
})`
  display: flex;
  flex-direction: column;
  transition: transform 300ms ease, height 300ms ease;
  background: ${({ theme }) => parseColor(theme, 'gray.main')};

  // Can't use % here, need to use height by vh to get animation to work correctly.
  // theme.spacingSmall refers to the gap between Header and Cards
  --transform-height: ${({ height, maxHeight }) => `calc(-${maxHeight}px + ${height}px)`};
  --available-height: ${({ maxHeight }) => `${maxHeight}px`};

  ${({ isContentExpanded }) => `
		transform: ${isContentExpanded ? `translateY(var(--transform-height))` : 'none'};
		height: ${isContentExpanded ? `var(--available-height)` : '100%'};
	`}
`;
