import { useCallback } from 'react';

import { Button, FormattedMessage, HStack, Icon, IconName, Text } from '@talos/kyoko';

import { defineMessages } from 'react-intl';
import { useMarketDataCards } from '../../../providers';

const messages = defineMessages({
  collapseCards: {
    defaultMessage: 'Collapse Cards',
    id: 'Layout.Header.collapseCards',
  },
  expandCards: {
    defaultMessage: 'Expand Cards',
    id: 'Layout.Header.expandCards',
  },
});

export function ExpandCollapseCardsButton() {
  const { cards, cardsAreDisplayed, setCards } = useMarketDataCards();
  const cardsExpanded = cards.some(card => !!card.detailsExpanded);

  const toggleCards = useCallback(() => {
    setCards(
      cards.map(card => ({
        ...card,
        detailsExpanded: !cardsExpanded,
      }))
    );
  }, [cards, cardsExpanded, setCards]);

  const expandCollapseCardsButton = (
    <Button onClick={toggleCards} data-testid="expand-collapse-cards-button">
      <HStack gap="spacingDefault">
        <Icon icon={cardsExpanded ? IconName.ListCollapse : IconName.ListExpand} />
        <Text>
          <FormattedMessage {...(cardsExpanded ? messages.collapseCards : messages.expandCards)} />
        </Text>
      </HStack>
    </Button>
  );

  return !cardsAreDisplayed || cards.length === 0 ? null : expandCollapseCardsButton;
}
