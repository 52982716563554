import { hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { darkColors, mapDarkTheme } from './DarkTheme';

const colors: Colors = {
  black: {
    default: '#000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    main: '#101010',

    '000': '#000000',
    '010': hsla(200, 0, 0.11, 1),
    '020': hsla(200, 0, 0.14, 1),
    '030': hsla(200, 0, 0.16, 1),
    '040': hsla(200, 0, 0.2, 1),
    '050': hsla(200, 0, 0.24, 1),
    '060': hsla(200, 0, 0.28, 1),
    '070': hsla(200, 0, 0.36, 1),
    '080': hsla(200, 0, 0.57, 1),
    '090': '#f0f3f4',
    '100': '#FFFFFF',
  },

  primary: {
    mute: setAlpha(0.1, '#29508B'),
    dim: setAlpha(0.4, '#29508B'),
    default: '#29508B',
    lighten: '#3e6196',
  },

  green: {
    mute: setAlpha(0.1, '#5ab548'),
    dim: setAlpha(0.4, '#5ab548'),
    default: '#5ab548',
    lighten: '#64d864',
  },

  red: {
    mute: setAlpha(0.1, '#ca3939'),
    dim: setAlpha(0.4, '#ca3939'),
    default: '#b53333',
    lighten: '#ca3939',
  },

  yellow: {
    mute: setAlpha(0.1, '#F3D900'),
    dim: setAlpha(0.4, '#F3D900'),
    default: '#F3D900',
    lighten: '#F3D900',
  },

  blue: {
    mute: setAlpha(0.1, '#306fb8'),
    dim: setAlpha(0.4, '#306fb8'),
    default: '#306fb8',
    lighten: '#4898F4',
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: darkColors.purple,
};

export const DarkEtanaTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkEC',

  fontFamily: "'Poppins', sans-serif",
  fontUrl:
    'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',

  spacingLayout: 8,

  backgroundHeader: colors.gray.main,
  borderColorHeader: colors.gray.main,

  backgroundInput: colors.gray.main,

  backgroundVolumeLadderBidSpread: colors.primary.default,
  backgroundVolumeLadderOfferSpread: colors.primary.default,
});
