import styled from 'styled-components';
import { Flex } from '../Core';

export const SymbolDisplayWrapper = styled(Flex)`
  border-color: transparent;
`;
SymbolDisplayWrapper.defaultProps = {
  alignItems: 'center',
  justifyContent: 'flexStart',
  p: 'spacingSmall',
  background: 'transparent',
  h: 'auto',
};
