import { defineMessages } from 'react-intl';
import { asyncScheduler, type Observable } from 'rxjs';
import { map, throttleTime } from 'rxjs/operators';
import { useObservable, useObservableValue } from '../../hooks/useObservable';
import type { MarketDataSnapshot } from '../../types/MarketDataSnapshot';
import type { Security } from '../../types/Security';
import type { BoxProps } from '../Core';
import { SmartTruncateEnum } from '../FormattedNumber';
import { FormattedMessage } from '../Intl';
import { Spinner } from '../Spinner';
import { Row } from './Row';
import {
  BidLabel,
  Footer,
  Header,
  Labels,
  Ladder,
  LadderWrapper,
  LeftLabel,
  MARKET_DATA_CARD_LADDER_INPUT_WIDTH,
  OfferLabel,
  RightLabel,
  SectionLabel,
  SpreadLabel,
} from './styles';
import type { ClickRowCallback, StepData, UpdateRowCallback } from './types';
import { formatMarketData } from './utils';

const TALL_LIMIT = 260;
const LADDER_HEADER_HEIGHT = 38;
const TALL_LADDER_HEADER_FOOTER_HEIGHT = 62 * 2;
const MIN_ROW_HEIGHT = 20;
const WIDTH_LIMIT = 260;

const messages = defineMessages({
  volumeLadder: {
    defaultMessage: 'Volume Ladder',
    id: 'VolumeLadder.volumeLadder',
  },
  spread: {
    defaultMessage: 'Spread',
    id: 'VolumeLadder.spread',
  },
  price: {
    defaultMessage: 'Price',
    id: 'VolumeLadder.price',
  },
  qty: {
    defaultMessage: 'Qty',
    id: 'VolumeLadder.qty',
  },
  bid: {
    defaultMessage: 'Bid',
    id: 'VolumeLadder.bid',
  },
  offer: {
    defaultMessage: 'Offer',
    id: 'VolumeLadder.offer',
  },
});

export const VolumeLadderSteps = ({
  marketDataObservable,
  sizeBuckets,
  onClickRow,
  onUpdateRow,
  security,
  showSpread,
  smartTruncate = SmartTruncateEnum.None,
  isCompact,
  height,
  width,
  bpsIncrement,
  ...boxProps
}: VolumeLadderStepsProps) => {
  const stepsObservable = useObservable<StepData>(
    () =>
      marketDataObservable.pipe(
        throttleTime(1000, asyncScheduler, { leading: true, trailing: true }),
        map(data => formatMarketData(data, sizeBuckets))
      ),
    [marketDataObservable, sizeBuckets]
  );
  const { steps, maxSpreadBps, midMarketPrice } = useObservableValue(() => stepsObservable, [stepsObservable]) ?? {};

  const isTall = (isCompact != null && !isCompact) || (height != null && height > TALL_LIMIT);
  const isNarrow = width != null && width < WIDTH_LIMIT;
  const maxSteps = Math.floor(
    ((height ?? 0) - (isTall ? TALL_LADDER_HEADER_FOOTER_HEIGHT : LADDER_HEADER_HEIGHT)) / MIN_ROW_HEIGHT
  );

  return (
    <LadderWrapper {...boxProps}>
      <Labels pb={isTall ? 'spacingDefault' : 'spacingSmall'} pt={isTall ? 'spacingMedium' : 'spacingSmall'}>
        <BidLabel pl={onUpdateRow ? 'spacingSmall' : undefined}>
          <FormattedMessage {...messages.bid} />
        </BidLabel>
        {isTall && !isNarrow && (
          <SectionLabel>
            <FormattedMessage {...messages.volumeLadder} />
          </SectionLabel>
        )}
        <OfferLabel pr={onUpdateRow ? 'spacingSmall' : undefined}>
          <FormattedMessage {...messages.offer} />
        </OfferLabel>
      </Labels>
      <Header py={isTall ? undefined : 'spacingSmall'}>
        <LeftLabel
          textAlign={onUpdateRow ? 'center' : 'left'}
          w={onUpdateRow ? MARKET_DATA_CARD_LADDER_INPUT_WIDTH : '15%'}
          flex={onUpdateRow ? `0 0 ${MARKET_DATA_CARD_LADDER_INPUT_WIDTH}px` : '1 1 15%'}
        >
          <FormattedMessage {...messages.qty} />
        </LeftLabel>
        <LeftLabel>
          <FormattedMessage {...messages.price} />
        </LeftLabel>
        {showSpread && !isNarrow && (
          <SpreadLabel>
            <FormattedMessage {...messages.spread} />
          </SpreadLabel>
        )}
        <RightLabel>
          <FormattedMessage {...messages.price} />
        </RightLabel>
        <RightLabel
          textAlign={onUpdateRow ? 'center' : 'right'}
          w={onUpdateRow ? MARKET_DATA_CARD_LADDER_INPUT_WIDTH : '15%'}
          flex={onUpdateRow ? `0 0 ${MARKET_DATA_CARD_LADDER_INPUT_WIDTH}px` : '1 1 15%'}
        >
          <FormattedMessage {...messages.qty} />
        </RightLabel>
      </Header>
      <Ladder borderBottom={isTall ? undefined : 'none'} borderTop={isTall ? undefined : 'none'}>
        {security == null || steps == null ? (
          <Spinner />
        ) : (
          <>
            {steps.slice(0, maxSteps).map((step, i) => (
              <Row
                smartTruncate={smartTruncate}
                showSpread={showSpread && !isNarrow}
                step={step}
                security={security}
                onClickRow={onClickRow}
                onUpdateRow={onUpdateRow}
                maxSpreadBps={maxSpreadBps}
                midMarketPrice={midMarketPrice}
                index={i}
                key={i}
                bpsIncrement={bpsIncrement}
              />
            ))}
          </>
        )}
      </Ladder>
      {isTall && (
        <>
          <Footer>
            <LeftLabel
              textAlign={onUpdateRow ? 'center' : 'left'}
              w={onUpdateRow ? MARKET_DATA_CARD_LADDER_INPUT_WIDTH : '15%'}
              flex={onUpdateRow ? `0 0 ${MARKET_DATA_CARD_LADDER_INPUT_WIDTH}px` : '1 1 15%'}
            >
              <FormattedMessage {...messages.qty} />
            </LeftLabel>
            <LeftLabel>
              <FormattedMessage {...messages.price} />
            </LeftLabel>
            {showSpread && !isNarrow && (
              <SpreadLabel>
                <FormattedMessage {...messages.spread} />
              </SpreadLabel>
            )}
            <RightLabel>
              <FormattedMessage {...messages.price} />
            </RightLabel>
            <RightLabel
              textAlign={onUpdateRow ? 'center' : 'right'}
              w={onUpdateRow ? MARKET_DATA_CARD_LADDER_INPUT_WIDTH : '15%'}
              flex={onUpdateRow ? `0 0 ${MARKET_DATA_CARD_LADDER_INPUT_WIDTH}px` : '1 1 15%'}
            >
              <FormattedMessage {...messages.qty} />
            </RightLabel>
          </Footer>
          <Labels pt={isTall ? 'spacingDefault' : '0px'} pb={isTall ? 'spacingMedium' : '0px'}>
            <BidLabel>
              <FormattedMessage {...messages.bid} />
            </BidLabel>
            <OfferLabel>
              <FormattedMessage {...messages.offer} />
            </OfferLabel>
          </Labels>
        </>
      )}
    </LadderWrapper>
  );
};

export type VolumeLadderStepsProps = {
  marketDataObservable: Observable<MarketDataSnapshot>;
  security?: Security;
  sizeBuckets: string[];
  onClickRow: ClickRowCallback;
  onUpdateRow?: UpdateRowCallback;
  isCompact?: boolean;
  showSpread?: boolean;
  smartTruncate?: SmartTruncateEnum;
  height?: number;
  width?: number;
  bpsIncrement?: string;
} & Omit<BoxProps, 'security'>;
