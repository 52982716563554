import type { LabelHTMLAttributes } from 'react';
import styled, { css, type DefaultTheme } from 'styled-components';
import { Box } from '../Core';
import { Text } from '../Text';
import type { FormControlProps } from './types';
import { FormControlSizes, InputStates, InputVariants } from './types';
import { getBorderRadius, getFontSize } from './utils';
const HEADER_HEIGHT = 4;
export const ControlPrefix = styled(Text)``;
ControlPrefix.defaultProps = {
  mr: 'spacingSmall',
  fontWeight: 'fontWeightMedium',
  whiteSpace: 'nowrap',
  color: 'colors.gray.080',
};

export const Label = styled(Box).attrs({ as: 'label' })<{ tooltip?: boolean } & LabelHTMLAttributes<any>>`
  font-size: ${({ theme }) => theme.fontSizeFormLabel}rem;
  line-height: 1;
  ${({ tooltip, theme }) => tooltip && `border-bottom: dotted 1px ${theme.colors.gray['080']}`};
`;

export const FormSection = styled(Box)<{ flex?: string }>`
  border-top: solid 1px ${({ theme }) => theme.borderColorDivider};
  padding: ${({ theme }) => theme.spacingMedium}px;
  flex: ${({ flex }) => flex ?? '0 0 auto'};
  transition: max-height 200ms ease;

  &:first-child {
    border-top: none;
  }
`;

export const OrderFormHeader = styled(FormSection)`
  height: ${({ theme }) => theme.baseSize * HEADER_HEIGHT}px;
  border-bottom: solid 1px ${({ theme }) => theme.borderColorDivider};
  display: flex;
  align-items: center;

  h2 {
    font-weight: ${({ theme }) => theme.fontWeightRegular};
    text-align: center;
    margin: ${({ theme }) => theme.spacingTiny}px 0;
    font-size: ${({ theme }) => theme.fontSizeLarge}rem;
    text-transform: uppercase;
  }
`;

// Add theme variable?
export const INPUT_BORDER_WIDTH = 1;

export const formControlCSS = css<FormControlProps<any>>`
  font-family: ${({ theme }) => theme.fontFamily};
  appearance: none;
  line-height: ${({ theme, size = FormControlSizes.Default }) => theme.baseSize * size - INPUT_BORDER_WIDTH * 2}px;
  border: solid ${INPUT_BORDER_WIDTH}px;
  border-color: ${({ theme, touched, invalid, disabled, readOnly }) =>
    disabled
      ? theme.borderColorInputDisabled
      : readOnly
      ? theme.borderColorInputReadOnly
      : invalid && (touched == null || touched)
      ? theme.borderColorInputInvalid
      : theme.borderColorInput};
  box-shadow: ${({ theme, touched, invalid, disabled, readOnly }) =>
    disabled
      ? theme.boxShadowInputDisabled
      : readOnly
      ? theme.boxShadowInputReadOnly
      : invalid && (touched == null || touched)
      ? theme.boxShadowInputInvalid
      : theme.boxShadowInput};
  padding: 0 ${({ theme, size = FormControlSizes.Default }) => (theme.spacingDefault * size) / 2}px;
  border-radius: ${({ theme, size }) => getBorderRadius(theme, size)}px;
  background: ${({ theme, disabled, invalid, touched, readOnly }) =>
    disabled
      ? theme.backgroundInputDisabled
      : readOnly
      ? theme.backgroundInputReadOnly
      : invalid && (touched == null || touched)
      ? theme.backgroundInputInvalid
      : theme.backgroundInput};
  ${({ theme, disabled, variant }) => inputWrapperColor(theme, variant, InputStates.Default, disabled)};
  transition: color 200ms ease, border-color 200ms ease;
  height: ${({ theme, size = FormControlSizes.Default }) => theme.baseSize * size}px;
  min-height: ${({ theme, size = FormControlSizes.Default }) => theme.baseSize * size}px;
  display: ${({ inline }) => (inline ? 'inline-flex' : 'flex')};
  width: ${({ inline, width }) => (inline ? 'auto' : width ?? '100%')};
  position: relative;
  align-items: center;
  font-size: ${({ theme, size = FormControlSizes.Default }) => getFontSize(theme, size)}rem;
  gap: ${({ theme }) => (theme.spacingSmall / FormControlSizes.Default) * 2}px;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}

  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};

  &:hover {
    border-color: ${({ theme, touched, invalid, disabled, readOnly }) =>
      disabled
        ? theme.borderColorInputDisabled
        : readOnly
        ? theme.borderColorInputReadOnly
        : invalid && (touched == null || touched)
        ? theme.borderColorInputInvalid
        : theme.borderColorInputHover};
    box-shadow: ${({ theme, touched, invalid, disabled, readOnly }) =>
      disabled
        ? theme.boxShadowInputDisabled
        : readOnly
        ? theme.boxShadowInputReadOnly
        : invalid && (touched == null || touched)
        ? theme.boxShadowInputInvalid
        : theme.boxShadowInputHover};
  }

  &:focus,
  &:focus-within {
    outline: none;
    ${({ theme, disabled, variant }) => inputWrapperColor(theme, variant, InputStates.Focus, disabled)};
    border-color: ${({ theme, invalid, touched, readOnly }) =>
      invalid && (touched == null || touched)
        ? theme.borderColorInputInvalidFocus
        : readOnly
        ? theme.borderColorInputReadOnly
        : theme.borderColorInputFocus};
    box-shadow: ${({ theme, invalid, touched, readOnly }) =>
      invalid && (touched == null || touched)
        ? theme.boxShadowInputInvalidFocus
        : readOnly
        ? theme.borderColorInputReadOnly
        : theme.boxShadowInputFocus};
  }

  select,
  textarea,
  input {
    appearance: none;
    background: transparent;
    border: none;
    flex: 1 1 0;
    width: 100%;
    ${({ theme, disabled, variant, readOnly }) => inputColor(theme, variant, InputStates.Default, disabled, readOnly)};
    line-height: ${({ theme, size = FormControlSizes.Default }) => theme.baseSize * size - INPUT_BORDER_WIDTH * 2}px;
    transition: color 200ms ease, border-color 200ms ease;
    text-align: ${({ type }) => (type === 'number' ? 'right' : 'left')};
    padding: 0;

    option {
      color: initial;
    }

    &::placeholder {
      color: ${({ theme }) => theme.colorTextMuted};
    }

    &:focus {
      outline: none;
      ${({ theme, disabled, readOnly, variant }) => inputColor(theme, variant, InputStates.Focus, disabled, readOnly)};
    }
  }
`;

export const inputWrapperColor = (
  theme: DefaultTheme,
  variant = InputVariants.Default,
  state: InputStates,
  disabled?: boolean
) => css`
  color: ${getInputWrapperColor(theme, variant, state, disabled)};
`;

// Gets colors for the wrapper, so will be applied to for example the suffix
const getInputWrapperColor = (
  theme: DefaultTheme,
  variant = InputVariants.Default,
  state: InputStates,
  disabled?: boolean
) => {
  switch (variant) {
    case InputVariants.Primary:
      return disabled ? theme.colorTextPrimaryMuted : theme.colorTextPrimarySubtle;
    case InputVariants.Negative:
      return disabled ? theme.colorTextNegativeMuted : theme.colorTextNegativeSubtle;
    case InputVariants.Positive:
      return disabled ? theme.colorTextPositiveMuted : theme.colorTextPositiveSubtle;
    case InputVariants.Warning:
      return disabled ? theme.colorTextWarningMuted : theme.colorTextWarningSubtle;
    default:
      switch (state) {
        case InputStates.Focus:
          return theme.colorTextDefault;
        default:
          return disabled ? theme.colorTextMuted : theme.colorTextDefault;
      }
  }
};

export const inputColor = (
  theme: DefaultTheme,
  variant = InputVariants.Default,
  state: InputStates,
  disabled?: boolean,
  readOnly?: boolean
) => css`
  color: ${getInputTextColor(theme, variant, state, disabled, readOnly)};
`;

// Gets color for the input element itself, so will be applied to the text
export const getInputTextColor = (
  theme: DefaultTheme,
  variant = InputVariants.Default,
  state: InputStates,
  disabled?: boolean,
  readOnly?: boolean
) => {
  switch (variant) {
    case InputVariants.Primary:
      return disabled ? theme.colorTextPrimarySubtle : theme.colorTextPrimary;
    case InputVariants.Negative:
      return disabled ? theme.colorTextNegativeSubtle : theme.colorTextNegative;
    case InputVariants.Positive:
      return disabled ? theme.colorTextPositiveSubtle : theme.colorTextPositive;
    case InputVariants.Warning:
      return disabled ? theme.colorTextWarningSubtle : theme.colorTextWarning;
    default:
      switch (state) {
        case InputStates.Focus:
          return disabled ? theme.colorTextSubtle : readOnly ? theme.colorTextDefault : theme.colorTextAttention;
        default:
          return disabled ? theme.colorTextSubtle : readOnly ? theme.colorTextDefault : theme.colorTextImportant;
      }
  }
};
