import { useCallback, type ChangeEvent } from 'react';
import { defineMessages } from 'react-intl';
import { useTheme } from 'styled-components';
import type { IntlWithFormatter } from '../../../contexts/IntlContext';
import { useIntl } from '../../../hooks';
import { Button, ButtonVariants, IconButton } from '../../Button';
import { parseDuration } from '../../DateTimeDurationPicker';
import { FormControlSizes, type FormControlProps } from '../../Form';
import { IconName } from '../../Icons';
import { FormattedMessage } from '../../Intl';
import { Popover, usePopoverState } from '../../Popover';
import { IntervalInputWrapper, OptionButtons, OptionsHeader, OptionsWrapper, StyledInput } from './styles';

const messages = defineMessages({
  durationAndUnit: {
    defaultMessage: '{duration}{minutesOrHours, select, minutes {m} other {h}}',
    id: 'Forms.IntervalInput.durationAndUnit',
  },
  hoursAbbreviated: {
    defaultMessage: 'hr',
    id: 'Forms.IntervalInput.hoursAbbreviated',
  },
  minutesAbbreviated: {
    defaultMessage: 'min',
    id: 'Forms.IntervalInput.minutesAbbreviated',
  },
  secondsAbbreviated: {
    defaultMessage: 'sec',
    id: 'Forms.IntervalInput.secondsAbbreviated',
  },
  shortcuts: {
    defaultMessage: 'Shortcuts',
    id: 'Forms.IntervalInput.shortcuts',
  },
});

const DEFAULT_SHORTCUTS = ['1m', '5m', '10m', '15m', '20m', '30m', '45m', '1h'];
const shortcutRegex = /^(\d+)([mh])$/;

// Convert the above DEFAULT_SHORTCUTS from an array of '1m', '5m', etc
// to an array of { duration: 1, minutesOrHours: 'minutes' }, etc, just
// so that our messageformat template up above is easier for translators
// to recognize that we're using an extreme shorthand for those words,
// just waiting to use the first character of each in the target language.
function generateDefaultShortcuts(intl: IntlWithFormatter) {
  return DEFAULT_SHORTCUTS.reduce<string[]>((acc, shortcut) => {
    const [_, duration, mh] = shortcutRegex.exec(shortcut)!;

    return acc.concat(
      intl.formatMessage(messages.durationAndUnit, {
        duration,
        minutesOrHours: mh === 'm' ? 'minutes' : 'hours',
      })
    );
  }, []);
}

export const IntervalInput = ({ shortcuts: _shortcuts, value, onChange, ...props }: IntervalInputProps) => {
  const intl = useIntl();
  const shortcuts = _shortcuts === undefined ? generateDefaultShortcuts(intl) : _shortcuts;
  const { value: number, unit } = parseValue(value);
  const { spacingDefault } = useTheme();

  const { close, open, ...popover } = usePopoverState({
    closeOnClickOutside: true,
    placement: 'bottom-end',
    delay: 0,
    trigger: '',
  });

  const handleUnitChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      const value = e.target.value;
      onChange(`${number}${value}`);
    },
    [onChange, number]
  );

  const handleNumberChange = useCallback(
    (value: string) => {
      // Remove the unit if there is no number so we can catch this in validation as just the string having a length.
      if (value === '') {
        onChange('');
      } else {
        onChange(`${value}${unit}`);
      }
    },
    [onChange, unit]
  );

  return (
    <IntervalInputWrapper onBlur={close}>
      <Popover {...popover} tabIndex={-1}>
        <StyledInput
          onChange={handleNumberChange}
          onFocus={open}
          value={number}
          min="0"
          defaultIncrement="1"
          largeIncrement="10"
          minIncrement="1"
          suffix={
            <select value={unit} onChange={handleUnitChange}>
              <option value="s">
                <FormattedMessage {...messages.secondsAbbreviated} />
              </option>
              <option value="m">
                <FormattedMessage {...messages.minutesAbbreviated} />
              </option>
              <option value="h">
                <FormattedMessage {...messages.hoursAbbreviated} />
              </option>
            </select>
          }
          type="number"
          {...props}
        />
        <OptionsWrapper>
          <OptionsHeader>
            <span>
              <FormattedMessage {...messages.shortcuts} />
            </span>
            <IconButton ghost icon={IconName.Close} onClick={close} style={{ marginRight: -spacingDefault }} />
          </OptionsHeader>
          <OptionButtons>
            {shortcuts.map((shortcut, i) => (
              <Button
                variant={value === shortcut ? ButtonVariants.Primary : ButtonVariants.Priority}
                key={`${shortcut}-${i}`}
                onClick={() => onChange(shortcut)}
                size={FormControlSizes.Small}
              >
                {shortcut}
              </Button>
            ))}
          </OptionButtons>
        </OptionsWrapper>
      </Popover>
    </IntervalInputWrapper>
  );
};

function parseValue(value: string | null): { unit: string; value: string } {
  if (!value) {
    return {
      unit: 's',
      value: '',
    };
  }
  const duration = parseDuration(value);
  const fallbackUnit = ['s', 'm', 'h'].some(u => u === value.at(-1)) ? value.at(-1)! : 's';
  const unit = duration.seconds !== 0 ? 's' : duration.minutes !== 0 ? 'm' : duration.hours !== 0 ? 'h' : fallbackUnit;
  let result = '';
  if (unit === 'h') {
    result = duration.hours.toString();
  } else if (unit === 'm') {
    result = (duration.hours * 60 + duration.minutes).toString();
  } else if (unit === 's') {
    result = (duration.hours * 3600 + duration.minutes * 60 + duration.seconds).toString();
  }
  return {
    unit,
    value: result,
  };
}

type IntervalInputProps = {
  shortcuts?: string[];
  value: string | null;
  onChange: (value: string) => void;
} & Omit<FormControlProps<HTMLInputElement>, 'suffix' | 'onChange' | 'value'>;
