import type { MouseEventHandler } from 'react';
import type { BoxProps } from '../Core';
import type { FormControlSizes } from '../Form/types';
import { Icon, type IconProps } from '../Icons/Icon';
import { IconName } from '../Icons/IconNames';
import { AlertWrapper } from './styles';
import { AlertVariants } from './types';

export { AlertVariants };
export interface AlertProps extends BoxProps {
  dismissable?: boolean;
  onDismiss?: MouseEventHandler<HTMLDivElement>;
  size?: FormControlSizes;
  variant?: AlertVariants;
  isBordered?: boolean;
  showIcon?: boolean;
}

export const Alert = ({
  variant = AlertVariants.Default,
  onDismiss,
  dismissable = true,
  children,
  isBordered = true,
  showIcon = true,
  alignItems = 'flex-start',
  ...props
}: AlertProps) => {
  const iconProps = getAlertIconProps(variant);

  return (
    <AlertWrapper textAlign="left" isBordered={isBordered} alignItems={alignItems} variant={variant} {...props}>
      {showIcon && <Icon {...iconProps} />}
      {children}
      {dismissable && (
        <Icon
          onClick={onDismiss}
          icon={IconName.Close}
          color="colors.gray['070']"
          size="fontSizeLarge"
          spaceBefore="auto"
        />
      )}
    </AlertWrapper>
  );
};

const getAlertIconProps = (variant: AlertVariants): Pick<IconProps, 'icon'> & { color: string } => {
  switch (variant) {
    case AlertVariants.Warning:
      return { icon: IconName.ExclamationSolid, color: 'colors.yellow.lighten' };
    case AlertVariants.Negative:
      return { icon: IconName.ExclamationSolid, color: 'colors.red.lighten' };
    case AlertVariants.Notice:
      return { icon: IconName.ExclamationSolid, color: 'colors.gray[090]' };
    case AlertVariants.Positive:
      return { icon: IconName.CheckCircleSolid, color: 'colors.green.lighten' };
    case AlertVariants.Guide:
      return { icon: IconName.LightBulb, color: 'colors.blue.lighten' };
    case AlertVariants.Info:
    default:
      return { icon: IconName.InformationCircleSolid, color: 'colors.gray[090]' };
  }
};
