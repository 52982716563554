import { get } from 'lodash';
import { createContext, useContext } from 'react';
import type {
  IOMSExecutionReport4203Markets,
  MarketAccountStatusEnum,
  MarketAccountTypeEnum,
  UpdateActionEnum,
} from '../types/types';

export const MarketAccountsContext = createContext<MarketAccountsContextProps | undefined>(undefined);
MarketAccountsContext.displayName = 'MarketAccountsContext';

export function useMarketAccountsContext() {
  const context = useContext(MarketAccountsContext);
  if (context === undefined) {
    throw new Error('Missing MarketAccountContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export interface MarketAccountsContextProps {
  isLoaded: boolean;
  marketAccountsByID: Map<number, MarketAccount>;
  marketAccountsByCredentialIDByName: Map<number, Map<string, MarketAccount>>;
  marketAccountsByName: Map<string, MarketAccount>;
  marketAccountsBySourceAccountID: Map<string, MarketAccount>;
  marketAccountsByMarket: Map<string, MarketAccount[]>;
  marketAccountsByCounterparty: Map<string, MarketAccount[]>;
  marketAccountsList: MarketAccount[];
  activeMarketAccountsList?: MarketAccount[];
  activeCustomerMarketAccountsList?: MarketAccount[];
  activeCustomerMarketAccountsByCounterpartyAndName?: Map<string, Map<string, MarketAccount>>;
  isMarketAccountActive: (ma?: MarketAccount) => boolean;
  isMarketStatusError: (m: Partial<IOMSExecutionReport4203Markets>) => boolean;
  marketAccountDisplayNameByName: Map<string, string>;
}

export interface MarketAccount {
  MarketAccountID: number;
  SourceAccountID: string;
  Name: string;
  Market: string;
  CredentialID: number;
  Timestamp?: string;
  Ownership: string;
  Status: MarketAccountStatusEnum;
  Description: string;
  DisplayName: string;
  Type: MarketAccountTypeEnum;
  TypeDescription: string;
  Group: string;
  Counterparty: string;
  UpdateAction?: UpdateActionEnum;
  ReferenceData?: string;
  Capabilities?: {
    TransferOutgoingSource?: boolean;
    TransferIncomingDest?: boolean;
    TransferOutgoingDest?: boolean;
    AcceptAllCurrencies?: boolean;
  };
  BlockchainAddresses?: {
    [key: string]: {
      Address: string;
      Name?: string;
      AddressType: 'Permanent' | 'Deposit';
    }[];
  };
}

/**
 * A utility function to help you grab BlockchainAddresses from a MarketAccount given an asset.
 * If no BlockchainAddresses can be found for the given currency, returns undefined.
 */
export function getBlockchainAddressesForCurrency(ma: MarketAccount, currency: string) {
  if (ma.BlockchainAddresses == null) {
    return undefined;
  }

  const blockchainAddressesForCurrency = get(ma.BlockchainAddresses, currency);
  if (blockchainAddressesForCurrency == null) {
    return undefined;
  }

  // [DEAL-2851] Filter out Permanent Addresses
  return blockchainAddressesForCurrency?.filter(ba => ba.AddressType !== 'Permanent');
}
