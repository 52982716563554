import type { DefaultTheme } from 'styled-components';
import { FORM_CONTROL_SIZES, FormControlSizes } from './types';

export const getFontSize = (theme: DefaultTheme, size = FormControlSizes.Default) => {
  switch (size) {
    case FormControlSizes.Xxs: {
      return theme.fontSizeTiny;
    }
    case FormControlSizes.Tiny: {
      return theme.fontSizeTiny;
    }
    case FormControlSizes.Small: {
      return theme.fontSizeSmall;
    }
    case FormControlSizes.Default: {
      return theme.fontSizeDefault;
    }
    case FormControlSizes.Large: {
      return theme.fontSizeLarge;
    }
  }
};

export const getLineHeight = (theme: DefaultTheme, size = FormControlSizes.Default) => {
  switch (size) {
    case FormControlSizes.Xxs: {
      return theme.lineHeightTiny;
    }
    case FormControlSizes.Tiny: {
      return theme.lineHeightTiny;
    }
    case FormControlSizes.Small: {
      return theme.lineHeightSmall;
    }
    case FormControlSizes.Default: {
      return theme.lineHeightDefault;
    }
    case FormControlSizes.Large: {
      return theme.lineHeightLarge;
    }
  }
};

export const getBorderRadius = (theme: DefaultTheme, size = FormControlSizes.Default) => {
  switch (size) {
    case FormControlSizes.Xxs: {
      return theme.borderRadiusTiny;
    }
    case FormControlSizes.Tiny: {
      return theme.borderRadiusTiny;
    }
    case FormControlSizes.Small: {
      return theme.borderRadiusSmall;
    }
    case FormControlSizes.Large: {
      return theme.borderRadiusMedium;
    }
    case FormControlSizes.Default:
    default: {
      return theme.borderRadiusDefault;
    }
  }
};

/**
 * Gets one size smaller of the provided size.
 * If you pass in the smallest size, returns the same size.
 * If the for whatever reason pass in an unsupported size, returns the passed in size.
 */
export function getOneFormControlSizeSmaller(inputSize: FormControlSizes) {
  const sizeIndex = FORM_CONTROL_SIZES.findIndex(size => size === inputSize);

  if (sizeIndex === -1) {
    return inputSize;
  }

  if (sizeIndex === 0) {
    return FORM_CONTROL_SIZES[sizeIndex];
  }

  return FORM_CONTROL_SIZES[sizeIndex - 1];
}
