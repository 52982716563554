import HighchartsReact from 'highcharts-react-official';
import highchartsMore from 'highcharts/highcharts-more';
import * as Highcharts from 'highcharts/highstock';
import boost from 'highcharts/modules/boost';
import coloraxis from 'highcharts/modules/coloraxis';
import seriesLabel from 'highcharts/modules/series-label';
import sunburst from 'highcharts/modules/sunburst';
import treemap from 'highcharts/modules/treemap';
import { toNumber } from 'lodash';
import { forwardRef, useEffect, useLayoutEffect, useMemo, useState, type ReactNode } from 'react';
import { asyncScheduler, throttleTime } from 'rxjs';
import { useDynamicCallback, useForwardedRef } from '../../hooks';
import { useResizeObservable } from '../../hooks/useResizeObservable';
import { EMPTY_OBJECT } from '../../utils';
import { IconButton } from '../Button';
import { Box, Flex, VStack, type BoxProps } from '../Core';

import { useTheme } from 'styled-components';
import { FormControlSizes } from '../Form/';
import { IconName } from '../Icons';
import { LoaderSizes, LoaderTalos } from '../LoaderTalos';
import { Text } from '../Text';
import { addHighchartsTreemapLayoutSquarifiedWithPadding } from './HighChartLayout';
import { useChartOptionsOverrides, useDefaultChartOptions } from './hooks';
import { useAxisZoomHandler, type UseAxisZoomHandlerParams } from './hooks/useAxisZoomHandler';
import { ChartBlockingLoadingOverlay, HighchartsWrapper } from './styles';
import type { HighchartsReactProps } from './types';

export const BASECHART_OVERLAY_BUTTON_SPACING = 16;

seriesLabel(Highcharts);
sunburst(Highcharts);
highchartsMore(Highcharts);
boost(Highcharts);
coloraxis(Highcharts);
treemap(Highcharts);
addHighchartsTreemapLayoutSquarifiedWithPadding(Highcharts);

Highcharts.AST.allowedAttributes.push('data-testid');

// We disable the default ShowResetZoom invocation and handle it ourselves below.
// The default Reset Zoom button is not sufficiently customizable.
Highcharts.Chart.prototype.showResetZoom = function () {};

// the HighchartsReact options prop doesn't seem to be able to set the lang property - https://github.com/highcharts/highcharts-react/issues/316
Highcharts.setOptions({
  lang: {
    numericSymbols: ['k', 'M', 'B', 'T', 'P', 'E'],
  },
});

export interface BaseChartProps extends HighchartsReactProps {
  /** When true, will display a spinner loading overlay on top of the chart. */
  isLoading?: boolean;

  /** When true, will show a somewhat transparent, gray overlay on top of the entire chart to indicate its not interactive while loading */
  showOverlayWhenLoading?: boolean;

  /** When true, displays a small, non-intrusive, loading icon in the top left corner of the chart */
  showLoadingCornerIcon?: boolean;

  /** When provided, will render your passed ReactNode on top of the chart, within its position:relative full width and height container */
  customOverlay?: ReactNode;

  /** Whether or not to show a No Data text if there seems to be no data in any series in the chart. Default: false. */
  showNoData?: boolean;

  /** If showNoData is set to true you can pass in a custom ReactNode to show when there isn't any data. */
  customNoDataText?: ReactNode;

  /** Specify your own throttle (in ms) to the throttle operator. */
  throttleResizing?: number;

  /** Should chart resize to fit inside its parent container */
  sizeAware?: boolean;

  wrapperProps?: BoxProps;

  /** Called when the HighchartsReact callback is called with the chart itself, letting you know that the chart is created. */
  onChartCreated?: (chartRefObject: Highcharts.Chart) => void;

  onXAxisAfterSetExtremes?: UseAxisZoomHandlerParams['onAxisAfterSetExtremes'];
  onYAxisAfterSetExtremes?: UseAxisZoomHandlerParams['onAxisAfterSetExtremes'];

  /** Called when the user clicks the "Reset Zoom" button. */
  onResetZoom?: (chartRefObject: Highcharts.Chart) => void;

  /** Whether or not to show the reset zoom button in the top right when the chart is zoomed in. Defaults to true.*/
  showResetZoomButton?: boolean;

  /** Custom overrides for the default Highcharts options.
   * The embedded default highcharts options merge is a merge all, while this allows a more complete override of settings */
  defaultOverrides?: Highcharts.Options;
}

export const BaseChart = forwardRef<HighchartsReact.RefObject, BaseChartProps>(function BaseChart(
  {
    options = EMPTY_OBJECT,
    wrapperProps = EMPTY_OBJECT,
    sizeAware = true,
    isLoading,
    showOverlayWhenLoading,
    showLoadingCornerIcon,
    customOverlay,
    showNoData,
    customNoDataText,
    throttleResizing = 300,
    onXAxisAfterSetExtremes,
    onYAxisAfterSetExtremes,
    onChartCreated,
    showResetZoomButton = true,
    onResetZoom,
    defaultOverrides,
    ...props
  },
  ref
) {
  const theme = useTheme();
  const [loaded, setLoaded] = useState(false);
  const [noData, setNoData] = useState(false);
  const { elementRef, sizeObservable } = useResizeObservable<HTMLDivElement>();

  // Don't render chart until wrapper was rendered
  useEffect(() => {
    setLoaded(elementRef.current != null);
  }, [elementRef]);

  const chartRef = useForwardedRef(ref);

  useLayoutEffect(() => {
    if (sizeAware) {
      const sub = sizeObservable
        .pipe(throttleTime(throttleResizing, asyncScheduler, { trailing: true, leading: true }))
        .subscribe((entry: ResizeObserverEntry) => {
          if (chartRef && chartRef.current) {
            const target = entry.target as HTMLDivElement;
            // TODO: Our Highcharts .chart typings fall back to any's here due to the kyoko highcharts.d.ts override
            // Until that's fixed, take care when adjusting this code
            chartRef.current.chart.setSize(target.clientWidth, target.clientHeight, false);
            chartRef.current.chart.reflow();
          }
        });
      return () => sub.unsubscribe();
    }
  }, [chartRef, sizeObservable, sizeAware, throttleResizing]);

  function resetZoom() {
    if (chartRef && chartRef.current) {
      chartRef.current.chart.zoomOut();
      const firstSeries = chartRef.current.chart.series[0];
      if ('drillUp' in firstSeries && typeof firstSeries.drillUp === 'function') {
        firstSeries.drillUp();
      }
      // We add this call, because otherwise there's no way to detect that the zoom
      // is supposed to be "reset"
      onResetZoom?.(chartRef.current.chart);
    }
  }

  const { axisZoomed: xAxisZoomed, handleAxisAfterSetExtremes: handleXAxisAfterSetExtremes } = useAxisZoomHandler({
    onAxisAfterSetExtremes: onXAxisAfterSetExtremes,
  });

  const { axisZoomed: yAxisZoomed, handleAxisAfterSetExtremes: handleYAxisAfterSetExtremes } = useAxisZoomHandler({
    onAxisAfterSetExtremes: onYAxisAfterSetExtremes,
  });

  // In some cases, like the chart height and for hooking in to certain events, we need to connect the default chart options
  // with our dynamic functions and state in this BaseChart component. We define those connections here and memoize before
  // combining with the much more "static" set of default Highcharts options.
  const dynamicOptions = useMemo(() => {
    return {
      // We include loaded here and in the deps array to make sure we re-calculate this after the wrapper element has rendered (and thus the ref has been populated)
      chart: {
        height: loaded ? elementRef.current?.clientHeight : undefined,
        events: {
          render: function (event) {
            setNoData(!doesAnySeriesHaveData(this));
          },
        },
      },
      xAxis: {
        events: {
          afterSetExtremes: function (event) {
            handleXAxisAfterSetExtremes(this, event);
          },
        },
      },
      yAxis: {
        events: {
          afterSetExtremes: function (event) {
            handleYAxisAfterSetExtremes(this, event);
          },
        },
      },
    } satisfies Highcharts.Options;
  }, [elementRef, handleXAxisAfterSetExtremes, handleYAxisAfterSetExtremes, loaded]);

  const defaultChartOptions = useDefaultChartOptions();
  const mergedOptions = useChartOptionsOverrides(defaultOverrides ?? defaultChartOptions, dynamicOptions, options);

  /**
   * For all our LineCharts, we want to be able to show a non-intrusive loading spinner signifying that more data is being loaded, or what have you
   * This memo returns a spinner anchored to the top-left corner of the BaseChart when showLoadingCornerIcon is true, and an empty ReactNode otherwise (no-op)
   */
  const cornerLoadingIconOverlay: ReactNode = useMemo(() => {
    if (!showLoadingCornerIcon) {
      return <></>;
    }

    const element = chartRef.current?.container.current?.querySelector('.highcharts-plot-background');
    const x = element?.getAttribute('x');
    const y = element?.getAttribute('y');

    if (x == null || y == null) {
      return <></>;
    }

    return (
      <Box
        position="absolute"
        left={`${toNumber(x) + theme.spacingDefault}px`}
        top={`${toNumber(y) + theme.spacingDefault}px`}
      >
        <LoaderTalos size={LoaderSizes.TINY} />
      </Box>
    );
  }, [showLoadingCornerIcon, theme, chartRef]);

  const isZoomed = xAxisZoomed || yAxisZoomed;

  const handleCallback = useDynamicCallback((chart: Highcharts.Chart) => {
    onChartCreated?.(chart);
  });

  return (
    <HighchartsWrapper ref={elementRef} {...wrapperProps}>
      {loaded && elementRef.current && mergedOptions.series ? (
        <>
          {mergedOptions.series.length > 0 ? (
            <HighchartsReact
              ref={chartRef}
              highcharts={Highcharts}
              options={mergedOptions}
              callback={handleCallback}
              {...props}
            />
          ) : (
            <Flex
              top="0"
              bottom="0"
              left="0"
              right="0"
              justifyContent="center"
              position="absolute"
              alignItems="center"
              color="colors.gray.070"
            >
              No available data
            </Flex>
          )}
          {isZoomed && showResetZoomButton && (
            /* The spacings here place the reset zoom button nicely within the boundaries of the chart */
            <Box
              position="absolute"
              top={`${BASECHART_OVERLAY_BUTTON_SPACING}px`}
              right={`${BASECHART_OVERLAY_BUTTON_SPACING}px`}
            >
              <IconButton size={FormControlSizes.Small} onClick={resetZoom} icon={IconName.ZoomReset} />
            </Box>
          )}
          {isLoading && (
            <ChartBlockingLoadingOverlay showOverlay={showOverlayWhenLoading}>
              <LoaderTalos />
            </ChartBlockingLoadingOverlay>
          )}
          {!isLoading && noData && showNoData && (
            <VStack position="absolute" top="0" right="0" bottom="0" left="0">
              {customNoDataText ?? <Text>No data found</Text>}
            </VStack>
          )}
          {customOverlay}
          {showLoadingCornerIcon && cornerLoadingIconOverlay}
        </>
      ) : (
        <LoaderTalos />
      )}
    </HighchartsWrapper>
  );
});

function doesAnySeriesHaveData(chart: Highcharts.Chart): boolean {
  return !!chart.series?.find(s => s.points != null && s.points.length > 0);
}
