import { useState, type DependencyList } from 'react';
import { useUpdateEffect } from 'react-use';
import { BehaviorSubject } from 'rxjs';

/**
 * Converts your value into an observable stream of value changes. Emits a new value each time the deps array contents changes.
 * @param {Function} factory function calculating the value to emit in the observable
 * @param dependencies the deps to trigger new emissions on
 * @returns both a subject and an observable
 */
export const useBehaviorSubject = <T,>(factory: () => T, dependencies: DependencyList) => {
  const [subjectAndObservable] = useState(() => {
    const subject = new BehaviorSubject(factory());
    return {
      subject,
      observable: subject.asObservable(),
    };
  });

  // On each non-first update of value, next the subject
  useUpdateEffect(() => {
    subjectAndObservable.subject.next(factory());
  }, dependencies);

  return subjectAndObservable;
};
