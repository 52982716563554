import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  &:not(:only-child) {
    margin-bottom: ${({ theme }) => theme.spacingDefault}px;
  }

  div:first-child {
    display: inline-flex;
    gap: 5px;
  }
`;
