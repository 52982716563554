import {
  BlotterTable,
  BlotterTableFilters,
  Button,
  Divider,
  EmptyState,
  EmptyStateContent,
  EmptyStateWrapper,
  FormControlSizes,
  FormattedMessage,
  MixpanelEvent,
  createCSVFileName,
  useMixpanel,
} from '@talos/kyoko';
import { useCallback } from 'react';
import { defineMessages } from 'react-intl';
import { useTheme } from 'styled-components';
import { BlotterActions } from '../styles';
import type { TabbedBlotterProps } from '../types';
import { useCreditExposuresBlotter } from './hooks/useCreditExposuresBlotter';

const messages = defineMessages({
  exportCredit: {
    defaultMessage: 'Export Credit',
    id: 'Blotters.CreditExposuresBlotter.exportCredit',
  },
});

export type CreditExposuresBlotterProps = Required<Pick<TabbedBlotterProps, 'renderInBackground'>>;

export function CreditExposuresBlotter({ renderInBackground: hidden }: CreditExposuresBlotterProps) {
  const theme = useTheme();
  const { baseSize } = theme;
  const mixpanel = useMixpanel();

  const renderEmpty = useCallback(
    () => (
      <EmptyStateWrapper>
        <EmptyStateContent minHeight={baseSize * 7}>
          <EmptyState>
            <h2>There are no credits to show</h2>
          </EmptyState>
        </EmptyStateContent>
      </EmptyStateWrapper>
    ),
    [baseSize]
  );

  const { blotterTable, blotterTableFilterProps, filterBuilderAccordion } = useCreditExposuresBlotter({ renderEmpty });

  const handleExportExposures = useCallback(() => {
    blotterTable.exportDataAsCSV({
      includeHiddenColumns: true,
      fileName: createCSVFileName({
        name: 'CreditExposures',
      }),
    });
    mixpanel.track(MixpanelEvent.ExportCredit);
  }, [blotterTable, mixpanel]);

  return (
    <>
      {!hidden && (
        <BlotterTableFilters
          {...filterBuilderAccordion}
          {...blotterTableFilterProps}
          {...blotterTable.blotterTableFiltersProps}
          showFilterBuilder={false}
          prefix={
            <BlotterActions>
              <Button size={FormControlSizes.Small} onClick={handleExportExposures}>
                <FormattedMessage {...messages.exportCredit} />
              </Button>
              <Divider orientation="vertical" my="spacingSmall" />
            </BlotterActions>
          }
        />
      )}
      <BlotterTable {...blotterTable!} />
    </>
  );
}
