import { useSpring } from '@react-spring/web';
import { useMemo, useState, type ReactNode } from 'react';
import { useTheme } from 'styled-components';
import { useDynamicCallback } from '../../hooks/useDynamicCallback';
import { useElementSize } from '../../hooks/useElementSize';
import type { BoxProps } from '../Core';
import { useAccordionContext } from './AccordionContext';
import { AccordionContent, ContentWrapper } from './styles';

const DEFAULT_ACCORDION_BODY_DURATION = 200;

export const AccordionBody = ({
  children,
  animate = true,
  /** If set to true, will add a border-top to the AccordionBody when it is considered open (visible height > 0) */
  borderTopWhenOpened = false,
  ...props
}: BoxProps & { children: ReactNode; animate?: boolean; borderTopWhenOpened?: boolean }) => {
  const theme = useTheme();
  const { isOpen, initialOpen, ghost, onOpened, onClosed } = useAccordionContext();
  const [overflow, setOverflow] = useState<React.CSSProperties['overflow']>(initialOpen ? 'visible' : 'hidden');

  // This piece of state tracks whether we last completed a closing or opening
  const [latestCompletedState, setLatestCompletedState] = useState<'opened' | 'closed'>(
    initialOpen ? 'opened' : 'closed'
  );

  const {
    elementRef,
    size: { scrollHeight },
  } = useElementSize<HTMLDivElement>();
  // If initialOpen is set to true, we should not animate the first opening of accordion.
  const [animateOpening, setAnimateOpening] = useState(!initialOpen);
  const onStart = useDynamicCallback((opened: boolean) => {
    if (!opened) {
      setOverflow('hidden');
    }
  });
  const onRest = useDynamicCallback((opened: boolean) => {
    // if we're not animating, don't do anything onRest
    if (animateOpening) {
      if (opened) {
        onOpened?.();
        setOverflow('visible');
        setLatestCompletedState('opened');
      } else {
        onClosed?.();
        setLatestCompletedState('closed');
      }
    }
    setAnimateOpening(true);
  });

  const [{ maxHeight }] = useSpring(
    {
      maxHeight: !animateOpening && isOpen ? scrollHeight : (isOpen && scrollHeight) || 0,
      onRest: () => onRest(isOpen),
      onStart: () => onStart(isOpen),
      config: {
        duration: animate && (animateOpening || !isOpen) ? DEFAULT_ACCORDION_BODY_DURATION : 0,
      },
    },
    [isOpen, animateOpening, onRest, scrollHeight, onStart]
  );

  const style = useMemo(() => ({ maxHeight, overflow }), [maxHeight, overflow]);

  // Kinda tricky logic. If latest completed state is opened, we know that we are open. Easy
  // If latest completed state is closed, then we are either fully closed or going from closed -> opened, so check if "isOpen" is true too since that tells us we're animating towards fully opened
  const showBorderTop = borderTopWhenOpened
    ? latestCompletedState === 'opened' || (latestCompletedState === 'closed' && isOpen)
    : false;

  return (
    <AccordionContent style={style} ghost={ghost}>
      <ContentWrapper
        ref={elementRef}
        style={{ borderTop: showBorderTop ? `1px solid ${theme.colors.gray['000']}` : 'none' }}
        {...props}
      >
        {children}
      </ContentWrapper>
    </AccordionContent>
  );
};
