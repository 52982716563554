import { noop } from 'lodash';
import { createContext, useContext } from 'react';
import type { SidebarContextProps } from './types';

export const sidebarContextDefaultValues = {
  isExpanded: true,
  isPinned: false,
  showSubNavItems: false,
  setExpanded: noop,
  setPinned: noop,
  setShowSubNavItems: noop,
};

export const SidebarContext = createContext<SidebarContextProps | undefined>(undefined);
SidebarContext.displayName = 'SidebarContext';

export function useSidebarContext() {
  const context = useContext(SidebarContext);
  if (context == null) {
    throw new Error('Missing SidebarContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}
