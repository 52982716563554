import styled from 'styled-components';
import { Logo } from '../../components/Logo';

export const SupportWrapper = styled.div`
  padding: ${({ theme }) => theme.spacingBig}px;
  display: flex;
  flex-direction: column;
  min-width: ${({ theme }) => theme.baseSize * 20}px;

  ${Logo} {
    align-self: center;
    margin-top: ${({ theme }) => theme.spacingMedium}px;
  }

  h2 {
    text-align: center;
    margin-top: 0;
    color: ${({ theme }) => theme.colorTextImportant};
  }

  > div {
    display: flex;
    gap: ${({ theme }) => theme.spacingBig}px;
    margin: ${({ theme }) => theme.spacingSmall}px 0;
    color: ${({ theme }) => theme.colorTextDefault};

    > div a {
      text-align: right;
      display: block;
      color: ${({ theme }) => theme.colorTextImportant};
    }
  }

  button {
    position: absolute;
    top: ${({ theme }) => theme.spacingDefault}px;
    right: ${({ theme }) => theme.spacingDefault}px;
  }
`;
