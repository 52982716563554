import type { ICellRendererParams } from 'ag-grid-enterprise';
import { useCallback } from 'react';
import type { DefaultTheme } from 'styled-components';
import { useDeviceType } from '../../hooks';
import { parseColor } from '../../styles';
import type { Security } from '../../types';
import { Icon, IconName } from '../Icons';
import { FavoriteCell } from './styles';
import type { WatchListRowData } from './types';

export interface FavoriteCellRendererParams extends ICellRendererParams {
  theme: DefaultTheme;
  toggleFavorite: (s: Security) => void;
  value: boolean;
  showOnlyFavorites?: boolean;
  data: WatchListRowData;
}

export const FavoriteCellRenderer = function FavoriteCellRenderer({
  data,
  value,
  theme,
  toggleFavorite,
  showOnlyFavorites,
}: FavoriteCellRendererParams) {
  const isFavorite = value ?? showOnlyFavorites;
  const security = data?.security;

  const isMobile = useDeviceType() === 'mobile';

  const handleToggleFavorite = useCallback(
    (e: React.MouseEvent | React.TouchEvent) => {
      e.stopPropagation();
      toggleFavorite(security);
    },
    [toggleFavorite, security]
  );

  return (
    <FavoriteCell
      data-testid={`favorite-cell-${security?.Symbol}`}
      onTouchEnd={e => {
        // Unless we do this check, the click is canceled out by the touchEnd event
        if (!isMobile) {
          handleToggleFavorite(e);
        }
      }}
      onClick={handleToggleFavorite}
      style={{ marginTop: '-1px', cursor: 'pointer' }}
    >
      <Icon
        icon={isFavorite ? IconName.StarSolid : IconName.Star}
        color={parseColor(theme, isFavorite ? 'yellow.lighten' : 'gray.080')}
        size="fontSizeLarge"
      />
    </FavoriteCell>
  );
};
