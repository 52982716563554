// THIS FILE WAS GENERATED BY cryptoicons-parser.ts, DO NOT EDIT MANUALLY.
export const currencyIconMap = {
  $PAC: {
    symbol: '$PAC',
  },
  '0XBTC': {
    symbol: '0XBTC',
  },
  '1INCH': {
    symbol: '1INCH',
  },
  '2GIVE': {
    symbol: '2GIVE',
  },
  AAVE: {
    symbol: 'AAVE',
  },
  ABT: {
    symbol: 'ABT',
  },
  ACT: {
    symbol: 'ACT',
  },
  ACTN: {
    symbol: 'ACTN',
  },
  ADA: {
    symbol: 'ADA',
  },
  ADD: {
    symbol: 'ADD',
  },
  ADX: {
    symbol: 'ADX',
  },
  AE: {
    symbol: 'AE',
  },
  AEON: {
    symbol: 'AEON',
  },
  AEUR: {
    symbol: 'AEUR',
  },
  AGI: {
    symbol: 'AGI',
  },
  AGRS: {
    symbol: 'AGRS',
  },
  AION: {
    symbol: 'AION',
  },
  ALGO: {
    symbol: 'ALGO',
  },
  AMB: {
    symbol: 'AMB',
  },
  AMP: {
    symbol: 'AMP',
  },
  AMPL: {
    symbol: 'AMPL',
  },
  ANKR: {
    symbol: 'ANKR',
  },
  ANT: {
    symbol: 'ANT',
  },
  APE: {
    symbol: 'APE',
  },
  APEX: {
    symbol: 'APEX',
  },
  APPC: {
    symbol: 'APPC',
  },
  ARDR: {
    symbol: 'ARDR',
  },
  ARG: {
    symbol: 'ARG',
  },
  ARK: {
    symbol: 'ARK',
  },
  ARN: {
    symbol: 'ARN',
  },
  ARNX: {
    symbol: 'ARNX',
  },
  ARY: {
    symbol: 'ARY',
  },
  AST: {
    symbol: 'AST',
  },
  ATLAS: {
    symbol: 'ATLAS',
  },
  ATM: {
    symbol: 'ATM',
  },
  ATOM: {
    symbol: 'ATOM',
  },
  AUDR: {
    symbol: 'AUDR',
  },
  AURY: {
    symbol: 'AURY',
  },
  AUTO: {
    symbol: 'AUTO',
  },
  AVAX: {
    symbol: 'AVAX',
  },
  AYWA: {
    symbol: 'AYWA',
  },
  BAB: {
    symbol: 'BAB',
  },
  BAL: {
    symbol: 'BAL',
  },
  BAND: {
    symbol: 'BAND',
  },
  BAT: {
    symbol: 'BAT',
  },
  BAY: {
    symbol: 'BAY',
  },
  BCBC: {
    symbol: 'BCBC',
  },
  BCC: {
    symbol: 'BCC',
  },
  BCD: {
    symbol: 'BCD',
  },
  BCH: {
    symbol: 'BCH',
  },
  BCIO: {
    symbol: 'BCIO',
  },
  BCN: {
    symbol: 'BCN',
  },
  BCO: {
    symbol: 'BCO',
  },
  BCPT: {
    symbol: 'BCPT',
  },
  BDL: {
    symbol: 'BDL',
  },
  BEAM: {
    symbol: 'BEAM',
  },
  BELA: {
    symbol: 'BELA',
  },
  BIX: {
    symbol: 'BIX',
  },
  BLCN: {
    symbol: 'BLCN',
  },
  BLK: {
    symbol: 'BLK',
  },
  BLOCK: {
    symbol: 'BLOCK',
  },
  BLZ: {
    symbol: 'BLZ',
  },
  BNB: {
    symbol: 'BNB',
  },
  BNT: {
    symbol: 'BNT',
  },
  BNTY: {
    symbol: 'BNTY',
  },
  BOOTY: {
    symbol: 'BOOTY',
  },
  BOS: {
    symbol: 'BOS',
  },
  BPT: {
    symbol: 'BPT',
  },
  BQ: {
    symbol: 'BQ',
  },
  BRD: {
    symbol: 'BRD',
  },
  BSD: {
    symbol: 'BSD',
  },
  BSV: {
    symbol: 'BSV',
  },
  BTC: {
    symbol: 'BTC',
  },
  BTCD: {
    symbol: 'BTCD',
  },
  BTCH: {
    symbol: 'BTCH',
  },
  BTCP: {
    symbol: 'BTCP',
  },
  BTCZ: {
    symbol: 'BTCZ',
  },
  BTDX: {
    symbol: 'BTDX',
  },
  BTG: {
    symbol: 'BTG',
  },
  BTM: {
    symbol: 'BTM',
  },
  BTS: {
    symbol: 'BTS',
  },
  BTT: {
    symbol: 'BTT',
  },
  BTX: {
    symbol: 'BTX',
  },
  BURST: {
    symbol: 'BURST',
  },
  BZE: {
    symbol: 'BZE',
  },
  CALL: {
    symbol: 'CALL',
  },
  CC: {
    symbol: 'CC',
  },
  CDN: {
    symbol: 'CDN',
  },
  CDT: {
    symbol: 'CDT',
  },
  CENZ: {
    symbol: 'CENZ',
  },
  CHAIN: {
    symbol: 'CHAIN',
  },
  CHAT: {
    symbol: 'CHAT',
  },
  CHIPS: {
    symbol: 'CHIPS',
  },
  CHSB: {
    symbol: 'CHSB',
  },
  CHZ: {
    symbol: 'CHZ',
  },
  CIX: {
    symbol: 'CIX',
  },
  CLAM: {
    symbol: 'CLAM',
  },
  CLOAK: {
    symbol: 'CLOAK',
  },
  CMM: {
    symbol: 'CMM',
  },
  CMT: {
    symbol: 'CMT',
  },
  CND: {
    symbol: 'CND',
  },
  CNX: {
    symbol: 'CNX',
  },
  CNY: {
    symbol: 'CNY',
  },
  COB: {
    symbol: 'COB',
  },
  COLX: {
    symbol: 'COLX',
  },
  COMP: {
    symbol: 'COMP',
  },
  COQUI: {
    symbol: 'COQUI',
  },
  CRED: {
    symbol: 'CRED',
  },
  CRPT: {
    symbol: 'CRPT',
  },
  CRV: {
    symbol: 'CRV',
  },
  CRW: {
    symbol: 'CRW',
  },
  CS: {
    symbol: 'CS',
  },
  CTR: {
    symbol: 'CTR',
  },
  CTXC: {
    symbol: 'CTXC',
  },
  CVC: {
    symbol: 'CVC',
  },
  D: {
    symbol: 'D',
  },
  DAI: {
    symbol: 'DAI',
  },
  DASH: {
    symbol: 'DASH',
  },
  DAT: {
    symbol: 'DAT',
  },
  DATA: {
    symbol: 'DATA',
  },
  DBC: {
    symbol: 'DBC',
  },
  DCN: {
    symbol: 'DCN',
  },
  DCR: {
    symbol: 'DCR',
  },
  DEEZ: {
    symbol: 'DEEZ',
  },
  DENT: {
    symbol: 'DENT',
  },
  DEW: {
    symbol: 'DEW',
  },
  DGB: {
    symbol: 'DGB',
  },
  DGD: {
    symbol: 'DGD',
  },
  DLT: {
    symbol: 'DLT',
  },
  DNT: {
    symbol: 'DNT',
  },
  DOCK: {
    symbol: 'DOCK',
  },
  DOGE: {
    symbol: 'DOGE',
  },
  DOT: {
    symbol: 'DOT',
  },
  DRGN: {
    symbol: 'DRGN',
  },
  DROP: {
    symbol: 'DROP',
  },
  DTA: {
    symbol: 'DTA',
  },
  DTH: {
    symbol: 'DTH',
  },
  DTR: {
    symbol: 'DTR',
  },
  EBST: {
    symbol: 'EBST',
  },
  ECA: {
    symbol: 'ECA',
  },
  EDG: {
    symbol: 'EDG',
  },
  EDO: {
    symbol: 'EDO',
  },
  EDOGE: {
    symbol: 'EDOGE',
  },
  ELA: {
    symbol: 'ELA',
  },
  ELEC: {
    symbol: 'ELEC',
  },
  ELF: {
    symbol: 'ELF',
  },
  ELIX: {
    symbol: 'ELIX',
  },
  ELLA: {
    symbol: 'ELLA',
  },
  EMB: {
    symbol: 'EMB',
  },
  EMC: {
    symbol: 'EMC',
  },
  EMC2: {
    symbol: 'EMC2',
  },
  ENG: {
    symbol: 'ENG',
  },
  ENJ: {
    symbol: 'ENJ',
  },
  ENTRP: {
    symbol: 'ENTRP',
  },
  EON: {
    symbol: 'EON',
  },
  EOP: {
    symbol: 'EOP',
  },
  EOS: {
    symbol: 'EOS',
  },
  EQLI: {
    symbol: 'EQLI',
  },
  EQUA: {
    symbol: 'EQUA',
  },
  ETC: {
    symbol: 'ETC',
  },
  ETH: {
    symbol: 'ETH',
  },
  ETHOS: {
    symbol: 'ETHOS',
  },
  ETN: {
    symbol: 'ETN',
  },
  ETP: {
    symbol: 'ETP',
  },
  EUR: {
    symbol: 'EUR',
  },
  EVX: {
    symbol: 'EVX',
  },
  EXMO: {
    symbol: 'EXMO',
  },
  EXP: {
    symbol: 'EXP',
  },
  FAIR: {
    symbol: 'FAIR',
  },
  FCT: {
    symbol: 'FCT',
  },
  FIDA: {
    symbol: 'FIDA',
  },
  FIL: {
    symbol: 'FIL',
  },
  FJC: {
    symbol: 'FJC',
  },
  FLDC: {
    symbol: 'FLDC',
  },
  FLO: {
    symbol: 'FLO',
  },
  FLUX: {
    symbol: 'FLUX',
  },
  FSN: {
    symbol: 'FSN',
  },
  FTC: {
    symbol: 'FTC',
  },
  FUEL: {
    symbol: 'FUEL',
  },
  FUN: {
    symbol: 'FUN',
  },
  GAME: {
    symbol: 'GAME',
  },
  GAS: {
    symbol: 'GAS',
  },
  GBP: {
    symbol: 'GBP',
  },
  GBX: {
    symbol: 'GBX',
  },
  GBYTE: {
    symbol: 'GBYTE',
  },
  GENERIC: {
    symbol: 'GENERIC',
  },
  GIN: {
    symbol: 'GIN',
  },
  GLXT: {
    symbol: 'GLXT',
  },
  GMR: {
    symbol: 'GMR',
  },
  GMT: {
    symbol: 'GMT',
  },
  GNO: {
    symbol: 'GNO',
  },
  GNT: {
    symbol: 'GNT',
  },
  GOLD: {
    symbol: 'GOLD',
  },
  GRC: {
    symbol: 'GRC',
  },
  GRIN: {
    symbol: 'GRIN',
  },
  GRS: {
    symbol: 'GRS',
  },
  GRT: {
    symbol: 'GRT',
  },
  GSC: {
    symbol: 'GSC',
  },
  GTO: {
    symbol: 'GTO',
  },
  GUP: {
    symbol: 'GUP',
  },
  GUSD: {
    symbol: 'GUSD',
  },
  GVT: {
    symbol: 'GVT',
  },
  GXS: {
    symbol: 'GXS',
  },
  GZR: {
    symbol: 'GZR',
  },
  HIGHT: {
    symbol: 'HIGHT',
  },
  HNS: {
    symbol: 'HNS',
  },
  HODL: {
    symbol: 'HODL',
  },
  HOT: {
    symbol: 'HOT',
  },
  HPB: {
    symbol: 'HPB',
  },
  HSR: {
    symbol: 'HSR',
  },
  HT: {
    symbol: 'HT',
  },
  HTML: {
    symbol: 'HTML',
  },
  HUC: {
    symbol: 'HUC',
  },
  HUSD: {
    symbol: 'HUSD',
  },
  HUSH: {
    symbol: 'HUSH',
  },
  ICN: {
    symbol: 'ICN',
  },
  ICP: {
    symbol: 'ICP',
  },
  ICX: {
    symbol: 'ICX',
  },
  IGNIS: {
    symbol: 'IGNIS',
  },
  ILK: {
    symbol: 'ILK',
  },
  INK: {
    symbol: 'INK',
  },
  INS: {
    symbol: 'INS',
  },
  ION: {
    symbol: 'ION',
  },
  IOP: {
    symbol: 'IOP',
  },
  IOST: {
    symbol: 'IOST',
  },
  IOTX: {
    symbol: 'IOTX',
  },
  IQ: {
    symbol: 'IQ',
  },
  ITC: {
    symbol: 'ITC',
  },
  JNT: {
    symbol: 'JNT',
  },
  JPY: {
    symbol: 'JPY',
  },
  KCS: {
    symbol: 'KCS',
  },
  KIN: {
    symbol: 'KIN',
  },
  KLOWN: {
    symbol: 'KLOWN',
  },
  KMD: {
    symbol: 'KMD',
  },
  KNC: {
    symbol: 'KNC',
  },
  KRB: {
    symbol: 'KRB',
  },
  KSM: {
    symbol: 'KSM',
  },
  LBC: {
    symbol: 'LBC',
  },
  LEND: {
    symbol: 'LEND',
  },
  LEO: {
    symbol: 'LEO',
  },
  LINK: {
    symbol: 'LINK',
  },
  LKK: {
    symbol: 'LKK',
  },
  LOOM: {
    symbol: 'LOOM',
  },
  LPT: {
    symbol: 'LPT',
  },
  LRC: {
    symbol: 'LRC',
  },
  LSK: {
    symbol: 'LSK',
  },
  LTC: {
    symbol: 'LTC',
  },
  LUN: {
    symbol: 'LUN',
  },
  MAID: {
    symbol: 'MAID',
  },
  MANA: {
    symbol: 'MANA',
  },
  MATIC: {
    symbol: 'MATIC',
  },
  MAX: {
    symbol: 'MAX',
  },
  MCAP: {
    symbol: 'MCAP',
  },
  MCO: {
    symbol: 'MCO',
  },
  MDA: {
    symbol: 'MDA',
  },
  MDS: {
    symbol: 'MDS',
  },
  MED: {
    symbol: 'MED',
  },
  MEETONE: {
    symbol: 'MEETONE',
  },
  MFT: {
    symbol: 'MFT',
  },
  MIOTA: {
    symbol: 'MIOTA',
  },
  MITH: {
    symbol: 'MITH',
  },
  MKR: {
    symbol: 'MKR',
  },
  MLN: {
    symbol: 'MLN',
  },
  MNX: {
    symbol: 'MNX',
  },
  MNZ: {
    symbol: 'MNZ',
  },
  MOAC: {
    symbol: 'MOAC',
  },
  MOD: {
    symbol: 'MOD',
  },
  MONA: {
    symbol: 'MONA',
  },
  MSR: {
    symbol: 'MSR',
  },
  MTH: {
    symbol: 'MTH',
  },
  MTL: {
    symbol: 'MTL',
  },
  MUSIC: {
    symbol: 'MUSIC',
  },
  MZC: {
    symbol: 'MZC',
  },
  NANO: {
    symbol: 'NANO',
  },
  NAS: {
    symbol: 'NAS',
  },
  NAV: {
    symbol: 'NAV',
  },
  NCASH: {
    symbol: 'NCASH',
  },
  NDZ: {
    symbol: 'NDZ',
  },
  NEBL: {
    symbol: 'NEBL',
  },
  NEO: {
    symbol: 'NEO',
  },
  NEOS: {
    symbol: 'NEOS',
  },
  NEU: {
    symbol: 'NEU',
  },
  NEXO: {
    symbol: 'NEXO',
  },
  NGC: {
    symbol: 'NGC',
  },
  NIO: {
    symbol: 'NIO',
  },
  NKN: {
    symbol: 'NKN',
  },
  NLC2: {
    symbol: 'NLC2',
  },
  NLG: {
    symbol: 'NLG',
  },
  NMC: {
    symbol: 'NMC',
  },
  NMR: {
    symbol: 'NMR',
  },
  NPXS: {
    symbol: 'NPXS',
  },
  NTBC: {
    symbol: 'NTBC',
  },
  NULS: {
    symbol: 'NULS',
  },
  NXS: {
    symbol: 'NXS',
  },
  NXT: {
    symbol: 'NXT',
  },
  OAX: {
    symbol: 'OAX',
  },
  OK: {
    symbol: 'OK',
  },
  OMG: {
    symbol: 'OMG',
  },
  OMNI: {
    symbol: 'OMNI',
  },
  ONE: {
    symbol: 'ONE',
  },
  ONG: {
    symbol: 'ONG',
  },
  ONT: {
    symbol: 'ONT',
  },
  OOT: {
    symbol: 'OOT',
  },
  OST: {
    symbol: 'OST',
  },
  OX: {
    symbol: 'OX',
  },
  OXT: {
    symbol: 'OXT',
  },
  OXY: {
    symbol: 'OXY',
  },
  PART: {
    symbol: 'PART',
  },
  PASC: {
    symbol: 'PASC',
  },
  PASL: {
    symbol: 'PASL',
  },
  PAX: {
    symbol: 'PAX',
  },
  PAXG: {
    symbol: 'PAXG',
  },
  PAY: {
    symbol: 'PAY',
  },
  PAYX: {
    symbol: 'PAYX',
  },
  PINK: {
    symbol: 'PINK',
  },
  PIRL: {
    symbol: 'PIRL',
  },
  PIVX: {
    symbol: 'PIVX',
  },
  PLR: {
    symbol: 'PLR',
  },
  POA: {
    symbol: 'POA',
  },
  POE: {
    symbol: 'POE',
  },
  POLIS: {
    symbol: 'POLIS',
  },
  POLY: {
    symbol: 'POLY',
  },
  POT: {
    symbol: 'POT',
  },
  POWR: {
    symbol: 'POWR',
  },
  PPC: {
    symbol: 'PPC',
  },
  PPP: {
    symbol: 'PPP',
  },
  PPT: {
    symbol: 'PPT',
  },
  PRE: {
    symbol: 'PRE',
  },
  PRL: {
    symbol: 'PRL',
  },
  PUNGO: {
    symbol: 'PUNGO',
  },
  PURA: {
    symbol: 'PURA',
  },
  QASH: {
    symbol: 'QASH',
  },
  QIWI: {
    symbol: 'QIWI',
  },
  QLC: {
    symbol: 'QLC',
  },
  QNT: {
    symbol: 'QNT',
  },
  QRL: {
    symbol: 'QRL',
  },
  QSP: {
    symbol: 'QSP',
  },
  QTUM: {
    symbol: 'QTUM',
  },
  R: {
    symbol: 'R',
  },
  RADS: {
    symbol: 'RADS',
  },
  RAP: {
    symbol: 'RAP',
  },
  RAY: {
    symbol: 'RAY',
  },
  RCN: {
    symbol: 'RCN',
  },
  RDD: {
    symbol: 'RDD',
  },
  RDN: {
    symbol: 'RDN',
  },
  REN: {
    symbol: 'REN',
  },
  REP: {
    symbol: 'REP',
  },
  REPV2: {
    symbol: 'REPV2',
  },
  REQ: {
    symbol: 'REQ',
  },
  RHOC: {
    symbol: 'RHOC',
  },
  RIC: {
    symbol: 'RIC',
  },
  RISE: {
    symbol: 'RISE',
  },
  RLC: {
    symbol: 'RLC',
  },
  RPX: {
    symbol: 'RPX',
  },
  RUB: {
    symbol: 'RUB',
  },
  RVN: {
    symbol: 'RVN',
  },
  RYO: {
    symbol: 'RYO',
  },
  SAFE: {
    symbol: 'SAFE',
  },
  SAFEMOON: {
    symbol: 'SAFEMOON',
  },
  SAI: {
    symbol: 'SAI',
  },
  SALT: {
    symbol: 'SALT',
  },
  SAN: {
    symbol: 'SAN',
  },
  SAND: {
    symbol: 'SAND',
  },
  SBD: {
    symbol: 'SBD',
  },
  SBERBANK: {
    symbol: 'SBERBANK',
  },
  SC: {
    symbol: 'SC',
  },
  SER: {
    symbol: 'SER',
  },
  SHIFT: {
    symbol: 'SHIFT',
  },
  SIB: {
    symbol: 'SIB',
  },
  SIN: {
    symbol: 'SIN',
  },
  SKL: {
    symbol: 'SKL',
  },
  SKY: {
    symbol: 'SKY',
  },
  SLR: {
    symbol: 'SLR',
  },
  SLS: {
    symbol: 'SLS',
  },
  SMART: {
    symbol: 'SMART',
  },
  SNGLS: {
    symbol: 'SNGLS',
  },
  SNM: {
    symbol: 'SNM',
  },
  SNT: {
    symbol: 'SNT',
  },
  SNX: {
    symbol: 'SNX',
  },
  SOC: {
    symbol: 'SOC',
  },
  SOL: {
    symbol: 'SOL',
  },
  SPACEHBIT: {
    symbol: 'SPACEHBIT',
  },
  SPANK: {
    symbol: 'SPANK',
  },
  SPHTX: {
    symbol: 'SPHTX',
  },
  SRN: {
    symbol: 'SRN',
  },
  STAK: {
    symbol: 'STAK',
  },
  START: {
    symbol: 'START',
  },
  STEEM: {
    symbol: 'STEEM',
  },
  STORJ: {
    symbol: 'STORJ',
  },
  STORM: {
    symbol: 'STORM',
  },
  STOX: {
    symbol: 'STOX',
  },
  STQ: {
    symbol: 'STQ',
  },
  STRAT: {
    symbol: 'STRAT',
  },
  STX: {
    symbol: 'STX',
  },
  SUB: {
    symbol: 'SUB',
  },
  SUMO: {
    symbol: 'SUMO',
  },
  SUSHI: {
    symbol: 'SUSHI',
  },
  SYS: {
    symbol: 'SYS',
  },
  TAAS: {
    symbol: 'TAAS',
  },
  TAU: {
    symbol: 'TAU',
  },
  TBX: {
    symbol: 'TBX',
  },
  TEL: {
    symbol: 'TEL',
  },
  TEN: {
    symbol: 'TEN',
  },
  TERN: {
    symbol: 'TERN',
  },
  TGCH: {
    symbol: 'TGCH',
  },
  THETA: {
    symbol: 'THETA',
  },
  TIX: {
    symbol: 'TIX',
  },
  TKN: {
    symbol: 'TKN',
  },
  TKS: {
    symbol: 'TKS',
  },
  TNB: {
    symbol: 'TNB',
  },
  TNC: {
    symbol: 'TNC',
  },
  TNT: {
    symbol: 'TNT',
  },
  TOMO: {
    symbol: 'TOMO',
  },
  TPAY: {
    symbol: 'TPAY',
  },
  TRIG: {
    symbol: 'TRIG',
  },
  TRTL: {
    symbol: 'TRTL',
  },
  TRX: {
    symbol: 'TRX',
  },
  TUSD: {
    symbol: 'TUSD',
  },
  TZC: {
    symbol: 'TZC',
  },
  UBQ: {
    symbol: 'UBQ',
  },
  UMA: {
    symbol: 'UMA',
  },
  UNI: {
    symbol: 'UNI',
  },
  UNITY: {
    symbol: 'UNITY',
  },
  USD: {
    symbol: 'USD',
  },
  USDC: {
    symbol: 'USDC',
  },
  USDT: {
    symbol: 'USDT',
  },
  UTK: {
    symbol: 'UTK',
  },
  VERI: {
    symbol: 'VERI',
  },
  VET: {
    symbol: 'VET',
  },
  VIA: {
    symbol: 'VIA',
  },
  VIB: {
    symbol: 'VIB',
  },
  VIBE: {
    symbol: 'VIBE',
  },
  VIVO: {
    symbol: 'VIVO',
  },
  VRC: {
    symbol: 'VRC',
  },
  VRSC: {
    symbol: 'VRSC',
  },
  VTC: {
    symbol: 'VTC',
  },
  VTHO: {
    symbol: 'VTHO',
  },
  WABI: {
    symbol: 'WABI',
  },
  WAN: {
    symbol: 'WAN',
  },
  WAVES: {
    symbol: 'WAVES',
  },
  WAX: {
    symbol: 'WAX',
  },
  WBTC: {
    symbol: 'WBTC',
  },
  WGR: {
    symbol: 'WGR',
  },
  WICC: {
    symbol: 'WICC',
  },
  WINGS: {
    symbol: 'WINGS',
  },
  WPR: {
    symbol: 'WPR',
  },
  WTC: {
    symbol: 'WTC',
  },
  X: {
    symbol: 'X',
  },
  XAS: {
    symbol: 'XAS',
  },
  XBC: {
    symbol: 'XBC',
  },
  XBP: {
    symbol: 'XBP',
  },
  XBY: {
    symbol: 'XBY',
  },
  XCP: {
    symbol: 'XCP',
  },
  XDN: {
    symbol: 'XDN',
  },
  XEM: {
    symbol: 'XEM',
  },
  XIN: {
    symbol: 'XIN',
  },
  XLM: {
    symbol: 'XLM',
  },
  XMCC: {
    symbol: 'XMCC',
  },
  XMG: {
    symbol: 'XMG',
  },
  XMO: {
    symbol: 'XMO',
  },
  XMR: {
    symbol: 'XMR',
  },
  XMY: {
    symbol: 'XMY',
  },
  XP: {
    symbol: 'XP',
  },
  XPA: {
    symbol: 'XPA',
  },
  XPM: {
    symbol: 'XPM',
  },
  XPR: {
    symbol: 'XPR',
  },
  XRP: {
    symbol: 'XRP',
  },
  XSG: {
    symbol: 'XSG',
  },
  XTZ: {
    symbol: 'XTZ',
  },
  XUC: {
    symbol: 'XUC',
  },
  XVC: {
    symbol: 'XVC',
  },
  XVG: {
    symbol: 'XVG',
  },
  XZC: {
    symbol: 'XZC',
  },
  YFI: {
    symbol: 'YFI',
  },
  YOYOW: {
    symbol: 'YOYOW',
  },
  ZCL: {
    symbol: 'ZCL',
  },
  ZEC: {
    symbol: 'ZEC',
  },
  ZEL: {
    symbol: 'ZEL',
  },
  ZEN: {
    symbol: 'ZEN',
  },
  ZEST: {
    symbol: 'ZEST',
  },
  ZIL: {
    symbol: 'ZIL',
  },
  ZILLA: {
    symbol: 'ZILLA',
  },
  ZRX: {
    symbol: 'ZRX',
  },
};
