import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { lightColors, mapLightTheme } from './LightTheme';

const colors: Colors = {
  primary: {
    mute: 'rgba(49, 74, 140, 0.1)',
    dim: 'rgba(49, 74, 140, 0.4)',
    default: '#314A8C',
    lighten: '#4B64A6',
  },

  black: {
    default: '#282828',
    dim: 'rgba(40, 40, 40, 0.4)',
    mute: 'rgba(40, 40, 40, 0.1)',
  },

  white: {
    default: '#eaeaea',
    dim: 'rgba(234, 234, 234, 0.4)',
    mute: 'rgba(234, 234, 234, 0.05)',
  },

  green: {
    default: '#2CEC9A',
    mute: 'rgba(44, 236, 154, 0.1)',
    dim: 'rgba(44, 236, 154, 0.4)',
    lighten: '#56f0ae',
  },

  red: {
    default: '#FF7171',
    mute: 'rgb(255, 113, 113, 0.1)',
    dim: 'rgb(255, 113, 113, 0.4)',
    lighten: '#ff8d8d',
  },

  blue: {
    default: '#2B6FD5',
    mute: 'rgb(43, 111, 213, 0.1)',
    dim: 'rgb(43, 111, 213, 0.4)',
    lighten: '#558cdd',
  },
  orange: {
    default: '#FF9426',
    mute: 'rgb(255, 148, 38, 0.1)',
    dim: 'rgb(255, 148, 38, 0.4)',
    lighten: '#ffa951',
  },

  yellow: {
    default: '#FF9426',
    mute: 'rgb(255, 148, 38, 0.1)',
    dim: 'rgb(255, 148, 38, 0.4)',
    lighten: '#ffa951',
  },

  gray: lightColors.gray,
  purple: lightColors.purple,
};

export const LightBitpandaTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightBP',

  fontFamily: "'Inter', sans-serif",
  fontUrl: 'https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap',

  backgroundHeader: colors.gray.main,
  borderColorHeader: 'transparent',

  backgroundSidebar: colors.gray.main,
  colorOrderStatusFilled: '#16764D',

  backgroundVolumeLadderBidSpread: colors.green.lighten,
  backgroundVolumeLadderOfferSpread: colors.green.lighten,

  backgroundChartUpColor: colors.green.default,
  borderColorChartUpColor: colors.green.default,
  backgroundChartDownColor: colors.red.default,
  borderColorChartDownColor: colors.red.default,

  colorTextPrimary: colors.black.default,
  colorTextPrimaryButton: colors.white.default,
  colorTextPrimaryButtonHover: colors.white.default,
  colorTextPrimaryButtonFocus: colors.white.default,
  colorTextPrimaryButtonActive: colors.white.default,
  colorTextPrimaryButtonDisabled: colors.white.default,

  colorTextPositive: '#16764D',
  colorTextPositiveButton: colors.black.default,
  colorTextPositiveButtonHover: colors.black.default,
  colorTextPositiveButtonFocus: colors.black.default,
  colorTextPositiveButtonActive: colors.black.default,
  colorTextPositiveButtonDisabled: colors.black.default,

  colorTextNegative: '#FF6060',
  colorTextNegativeButton: colors.black.default,
  colorTextNegativeButtonHover: colors.black.default,
  colorTextNegativeButtonFocus: colors.black.default,
  colorTextNegativeButtonActive: colors.black.default,
  colorTextNegativeButtonDisabled: colors.black.default,

  backgroundPrimaryButton: colors.black.default,
  backgroundPrimaryButtonHover: setAlpha(0.9, colors.black.default),
  backgroundPrimaryButtonActive: setAlpha(0.9, colors.black.default),
  backgroundPrimaryButtonFocus: setAlpha(0.8, colors.black.default),
  backgroundPrimaryButtonDisabled: setAlpha(0.6, colors.black.default),

  colorTextWarning: '#995917',
  colorTextWarningButton: colors.black.default,
  colorTextWarningButtonHover: colors.black.default,
  colorTextWarningButtonFocus: colors.black.default,
  colorTextWarningButtonActive: colors.black.default,
  colorTextWarningButtonDisabled: colors.black.default,
});
