import { Children, cloneElement, isValidElement, useContext, type CSSProperties, type ReactNode } from 'react';
import styled from 'styled-components';
import type { BoxProps } from '../Core';
import { TabsContext } from './Tabs';
import { TabPanelsWrapper } from './styles';

export interface TabPanelsProps extends BoxProps {
  children?: ReactNode;
  style?: CSSProperties;
  unmountUnselected?: boolean;
}

export const TabPanels = styled(function TabPanels({ children, unmountUnselected = true, ...props }: TabPanelsProps) {
  const { selectedIndex } = useContext(TabsContext)!;
  return (
    <TabPanelsWrapper {...props}>
      {Children.map(children, (child, i) => {
        const isSelected = isValidElement(child) && selectedIndex === i;

        if (unmountUnselected) {
          return isSelected ? child : null;
        }

        if (isValidElement(child)) {
          return cloneElement(child, {
            position: isSelected ? 'static' : 'absolute',
            zIndex: isSelected ? 'initial' : '-1',
            opacity: isSelected ? '1' : '0',
          } as any);
        } else {
          return null;
        }
      })}
    </TabPanelsWrapper>
  );
})``;
