import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { lightColors, mapLightTheme } from './LightTheme';

const colors: Colors = {
  black: {
    default: '#121212',
    dim: 'rgba(18, 18, 18, 0.4)',
    mute: 'rgba(18, 18, 18, 0.1)',
  },

  white: {
    default: '#ffffff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    main: '#999999',

    '000': '#ababab',
    '010': '#9a9a9a',
    '020': '#898989',
    '030': '#787878',
    '040': '#676767',
    '050': '#565656',
    '060': '#454545',
    '070': '#343434',
    '080': '#232323',
    '090': '#121212',
    '100': '#000000',
  },

  primary: {
    mute: setAlpha(0.1, '#7055F8'),
    dim: setAlpha(0.4, '#7055F8'),
    default: '#7055F8',
    lighten: '#8F79FF',
  },

  green: {
    mute: setAlpha(0.1, '#477722'),
    dim: setAlpha(0.4, '#477722'),
    default: '#477722',
    lighten: '#5AA61E',
  },

  red: {
    mute: setAlpha(0.1, '#8D483C'),
    dim: setAlpha(0.4, '#8D483C'),
    default: '#8D483C',
    lighten: '#CA5A47',
  },

  yellow: {
    mute: setAlpha(0.1, '#D6C043'),
    dim: setAlpha(0.4, '#D6C043'),
    default: '#D6C043',
    lighten: '#FFE646',
  },

  blue: {
    mute: setAlpha(0.1, '#2F5F9C'),
    dim: setAlpha(0.4, '#2F5F9C'),
    default: '#2F5F9C',
    lighten: '#43AAD6',
  },

  orange: {
    mute: setAlpha(0.1, '#D24500'),
    dim: setAlpha(0.4, '#D24500'),
    default: '#D24500',
    lighten: '#FF6900',
  },

  purple: lightColors.purple,
};

export const LightJanuarTheme: DefaultTheme = mapLightTheme(colors, {
  name: 'LightJR',

  backgroundBody: colors.gray.main,
  backgroundContent: colors.white.default,
  boxShadowContent: colors.gray['000'],
  borderColorContent: colors.gray['000'],
  backgroundLayoutWrapper: colors.gray['000'],

  backgroundHeader: colors.white.default,

  backgroundSidebar: colors.white.default,
  colorTextSidebarItemActive: colors.white.default,
  backgroundSidebarItemHover: colors.black.mute,

  backgroundChart: colors.white.default,
  backgroundBlotterTableRow: colors.white.default,

  colorCurrencyIcon: colors.white.default,
  colorModeCurrencyIcon: 'monochrome',

  backgroundDropdownResults: colors.white.default,
  backgroundDropdownGroupHeader: colors.white.default,
  backgroundDropdownHovered: colors.black.mute,
  backgroundDropdownSelected: colors.black.mute,
  boxShadowDropdown: '0px 8px 32px rgba(0, 0, 0, 0.2)',

  backgroundModal: colors.white.default,

  backgroundInput: colors.black.mute,
});
