import type { ExpectTrue } from '../tests';
import type {
  IAllocation,
  ITradeSettleReport,
  SideEnum,
  TradeSettleActionEnum,
  TradeSettleSourceEnum,
  TradeSettleStatusEnum,
} from './types';

export class TradeSettleReport {
  ReportID!: string;
  Timestamp!: string;
  TradeID!: string;
  TradeTransactTime!: string;
  TradeSide!: SideEnum;
  Revision!: number;
  Status!: TradeSettleStatusEnum;
  DueAsset!: string;
  Due!: string;
  CumSettledDue!: string;
  ProceedsAsset!: string;
  Proceeds!: string;
  CumSettledProceeds!: string;
  Price!: string;
  LastSettledDue!: string;
  LastSettledProceeds!: string;
  LastSettledSource!: TradeSettleSourceEnum;
  LastSourceID!: string;
  MarketAccount!: string;
  SubAccount?: string;
  TransactTime!: string;
  LastAction!: TradeSettleActionEnum;
  Counterparty!: string;
  InitialProceeds!: number;
  Allocation?: IAllocation;

  constructor(tsr: ITradeSettleReport) {
    Object.assign(this, tsr);
  }
}

// TYPE LEVEL TESTS
type _Expect_TradeSettleReport_To_Only_Have_Keys_From_ITradeSettleReport = ExpectTrue<
  {
    [K in keyof TradeSettleReport & string]: K extends keyof ITradeSettleReport ? true : K;
  }[Exclude<keyof TradeSettleReport, never>]
>;
type _Expect_All_Keys_In_ITradeSettleReport_To_Be_In_TradeSettleReport = ExpectTrue<
  {
    [K in keyof ITradeSettleReport & string]: K extends keyof TradeSettleReport ? true : K;
  }[Exclude<keyof ITradeSettleReport, never>]
>;
