import { OrdStatusEnum } from '@talos/kyoko';
export const ACTIVE_ORDER_STATUSES = [
  OrdStatusEnum.New,
  OrdStatusEnum.PendingNew,
  OrdStatusEnum.PendingCancel,
  OrdStatusEnum.PendingReplace,
  OrdStatusEnum.PartiallyFilled,
  OrdStatusEnum.Holding,
  OrdStatusEnum.Replaced,
];
