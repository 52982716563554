import { useMemo, type PropsWithChildren } from 'react';

import { useTheme, type DefaultTheme } from 'styled-components';
import type { BoxProps } from '../Core';
import { IndicatorBadgeSizes, IndicatorBadgeVariants, IndicatorBadgeWrapper } from './styles';
export { IndicatorBadgeSizes, IndicatorBadgeVariants } from './styles';

type IndicatorBadgeProps = PropsWithChildren<{
  show?: boolean;
  size?: IndicatorBadgeSizes;
  variant?: IndicatorBadgeVariants;
  theme?: DefaultTheme;
  noWrap?: boolean;
  square?: boolean;
}> &
  BoxProps;

export function IndicatorBadge({
  children,
  show = true,
  size = IndicatorBadgeSizes.Small,
  variant = IndicatorBadgeVariants.Default,
  noWrap = false,
  ...props
}: IndicatorBadgeProps) {
  const defaultTheme = useTheme();
  const theme = props.theme ?? defaultTheme;

  const badgeStyle = useMemo(
    () =>
      noWrap
        ? {
            whiteSpace: 'nowrap' as const,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }
        : {},
    [noWrap]
  );

  return show && children != null ? (
    <IndicatorBadgeWrapper {...props} {...badgeStyle} size={size} variant={variant} theme={theme}>
      {children}
    </IndicatorBadgeWrapper>
  ) : null;
}
