import { useCombobox } from 'downshift';
import { memo, useEffect, useMemo, useState } from 'react';
import type { Currency } from '../../types';
import { CurrencyIcon } from '../CurrencyIcon';
import { CurrencyIconWrapper, CurrencySelectWrapper, DropdownMenu, InputWrapper, MenuItem } from './styles';

export const CurrencySelect = memo(
  ({ disabled, currencies, currency, invalid, onChange, ...props }: CurrencySelectProps) => {
    const [inputItems, setInputItems] = useState(currencies);
    const [inputValue, setInputValue] = useState(currency ?? '');
    useEffect(() => setInputItems(currencies), [currencies]);
    useEffect(() => {
      if (currency != null) {
        setInputValue(currency);
      }
    }, [currency]);

    const selectedCurrency = useMemo(() => {
      return currencies.find(ccy => ccy.Symbol === currency) ?? null;
    }, [currencies, currency]);

    const { isOpen, getMenuProps, getInputProps, highlightedIndex, getItemProps } = useCombobox({
      selectedItem: selectedCurrency,
      items: inputItems,
      itemToString: item => (item ? item?.Symbol : ''),
      inputValue,
      onInputValueChange: ({ inputValue }) => {
        setInputValue(inputValue ?? '');
        setInputItems(currencies.filter(item => item.Symbol.toLowerCase().startsWith(inputValue?.toLowerCase() ?? '')));
      },
      onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem?.Symbol ?? undefined),
      stateReducer: (state, { changes, type }) => {
        switch (type) {
          case useCombobox.stateChangeTypes.InputBlur:
            return {
              ...changes,
              inputValue: '', // reset search box, makes sure placeholder is showing the selected value
            };
          case useCombobox.stateChangeTypes.InputChange:
            return {
              ...changes,
              highlightedIndex: 0,
            };
          case useCombobox.stateChangeTypes.FunctionOpenMenu:
            return {
              ...changes,
              inputValue: '',
              highlightedIndex: 0,
            };
          case useCombobox.stateChangeTypes.InputClick:
            return {
              ...changes,
              isOpen: true,
            };
          default:
            return changes;
        }
      },
    });

    return (
      <CurrencySelectWrapper data-testid="currency-select">
        <InputWrapper disabled={disabled} invalid={invalid} isOpen={isOpen}>
          <CurrencyIconWrapper>
            {currency != null && <CurrencyIcon size={16} currency={currency} />}
          </CurrencyIconWrapper>
          <input {...props} {...getInputProps()} disabled={disabled} placeholder={currency} />
        </InputWrapper>
        <DropdownMenu {...getMenuProps()} isOpen={isOpen}>
          {isOpen && (
            <>
              {inputItems.map((item, index) => (
                <MenuItem highlighted={highlightedIndex === index} key={item.Symbol} {...getItemProps({ item, index })}>
                  <CurrencyIcon size={16} currency={item.Symbol} />
                  <label>
                    {item.Symbol}
                    <div>{item.Description}</div>
                  </label>
                </MenuItem>
              ))}
              {(!inputItems || inputItems.length === 0) && <MenuItem highlighted={false}>Not found</MenuItem>}
            </>
          )}
        </DropdownMenu>
      </CurrencySelectWrapper>
    );
  }
);

interface CurrencySelectProps {
  disabled?: boolean;
  currencies: Currency[];
  currency?: string;
  onChange: (currency?: string) => void;
  invalid: boolean;
}
