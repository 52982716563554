import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { SearchSelect } from '../Form/SearchSelect';
import type { SearchSelectProps } from '../Form/SearchSelect/types';
import { useTimeSelectorValidation } from './useTimeSelectorValidation';
import { getDatesEveryXMinutes } from './utils';

export type TimeSelectorProps = {
  selection?: Date;
  intervalMinutes: number;
  onChange: (date: Date) => void;
} & Pick<SearchSelectProps<any>, 'portalize' | 'dropdownContentRef' | 'w' | 'size' | 'disabled'>;

const getLabel = (date: Date) => format(date, 'HH:mm');

export const TimeSelector = ({ intervalMinutes, selection, onChange, ...props }: TimeSelectorProps) => {
  const options = useMemo(() => {
    return getDatesEveryXMinutes(intervalMinutes);
  }, [intervalMinutes]);

  // Before we emit a change to our parent, let's see
  const handleChange = useCallback(
    (date?: Date) => {
      if (date == null) {
        return;
      }
      if (selection) {
        const copiedSelection = new Date(selection);
        copiedSelection.setHours(date.getHours());
        copiedSelection.setMinutes(date.getMinutes());
        copiedSelection.setSeconds(date.getSeconds());
        copiedSelection.setMilliseconds(date.getMilliseconds());
        onChange(copiedSelection);
      } else {
        onChange(date);
      }
    },
    [selection, onChange]
  );

  const valid = useTimeSelectorValidation(selection ?? new Date(), intervalMinutes);

  return (
    <SearchSelect
      options={options}
      selection={selection}
      onChange={handleChange}
      getLabel={getLabel}
      invalid={selection ? !valid : false} // only apply invalid state if we have a selection.
      {...props}
    />
  );
};
