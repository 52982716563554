import type { CreditUsageWarning } from '../../types';
import { format } from '../../utils';
import { VStack } from '../Core';
import { Text } from '../Text';

interface CreditUsageWarningsProps {
  warning: CreditUsageWarning;
  isWarningAggregatedFromChild: boolean;
}

export function CreditUsageWarnings({ warning, isWarningAggregatedFromChild }: CreditUsageWarningsProps) {
  const { currency, usage } = warning;

  return (
    <VStack gap="spacingSmall" alignItems="flex-start" data-testid="credit-usage-warning-tooltip-content">
      {isWarningAggregatedFromChild && (
        <Text fontWeight="fontWeightMedium" color="colorTextSubtle">
          Within Group
        </Text>
      )}
      <Text size="fontSizeMd" color="gray.100">
        {currency} at {format(usage.times(100), { spec: '0', round: true })}% of limit
      </Text>
    </VStack>
  );
}
