import type { CustomCellEditorProps } from 'ag-grid-react';
import { useDynamicCallback } from 'hooks/useDynamicCallback';
import { useEffect, useRef } from 'react';
import type { FormControlSizes } from '../Form/types';
import { AmountInput, type AmountInputProps } from '../Forms/AmountInput';

export interface AgGridAmountInputProps extends Pick<AmountInputProps, 'style'> {
  currencyField?: string;
  size?: FormControlSizes;
}

export const AgGridAmountInput = function AgGridAmountInput({
  size,
  node,
  colDef,
  parseValue,
  currencyField = 'Currency',
  data,
  value,
  onValueChange,
  ...props
}: CustomCellEditorProps & AgGridAmountInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { currenciesBySymbol } = props.context.current;

  const handleChange = useDynamicCallback((value: string | undefined) => {
    onValueChange(value ? parseValue(value) : undefined);

    // Weird cursor behavior if user types the negative '-' sign
    // Similar to packages/kyoko/src/components/Form/NumberInput/useNumberInput.tsx
    // So we purposely move the cursor to the end
    if (value === '-') {
      setTimeout(() => inputRef.current?.setSelectionRange(100, 100), 0);
    }
  });

  useEffect(() => {
    setTimeout(() => {
      if (inputRef?.current != null) {
        inputRef.current.focus();
      }
    });
  }, []);

  const asset = currenciesBySymbol?.get(data?.[currencyField]);

  return (
    <AmountInput
      ref={inputRef}
      onChange={handleChange}
      value={value}
      currency={data?.[currencyField]}
      asset={asset}
      style={{
        width: '100%',
        height: `${node.rowHeight}px`,
        background: props.context.current.theme?.backgroundBody,
        ...props.style,
      }}
    />
  );
};
