import styled from 'styled-components';
import { FormControlSizes } from '../types';
import { getFontSize } from '../utils';

function getRadioButtonSize(size = FormControlSizes.Default): number {
  switch (size) {
    case FormControlSizes.Tiny:
    case FormControlSizes.Small:
    case FormControlSizes.Default: {
      return 16;
    }
    default: {
      return 20;
    }
  }
}

export const RadioButton = styled.span<{ checked?: boolean; disabled?: boolean; size?: FormControlSizes }>`
  vertical-align: text-top;
  position: relative;
  display: inline-block;
  width: ${({ size }) => getRadioButtonSize(size)}px;
  height: ${({ size }) => getRadioButtonSize(size)}px;
  border-radius: 999em;
  flex: 0 0 auto;

  div {
    background: ${({ checked, theme }) => (checked ? theme.backgroundRadiobuttonChecked : theme.backgroundRadiobutton)};
    position: absolute;
    border: 1px solid
      ${({ checked, theme }) => (checked ? theme.borderColorRadiobuttonChecked : theme.borderColorRadiobutton)};
    color: white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    width: ${({ size }) => getRadioButtonSize(size)}px;
    height: ${({ size }) => getRadioButtonSize(size)}px;
    transition: border 0.15s ease, background 0.15s ease;
    border-radius: 999em;
  }

  input {
    opacity: 0;
    width: 100%;
    height: 100%;
    &:focus ~ div {
      border-width: 2px;
      border-color: ${({ theme }) => theme.borderColorRadiobuttonFocus};
    }
  }
`;

export const RadioButtonLabel = styled.label<{ checked?: boolean; disabled?: boolean; size?: FormControlSizes }>`
  display: inline-block;
  margin-left: ${({ theme, size = FormControlSizes.Default }) => (theme.spacingDefault * size) / 2}px;
  color: ${({ theme }) => theme.colorTextImportant};
  font-size: ${({ size, theme }) => getFontSize(theme, size)}rem;
  transition: color 0.25s ease;
  white-space: normal;
  line-height: 1;
  vertical-align: middle;
  flex: 1 1 auto;
`;

export const RadiobuttonWrapper = styled.label<{ disabled?: boolean; checked?: boolean }>`
  ${p =>
    p.disabled &&
    `
    opacity: 0.4;
    &:hover,
    &:hover input:disabled {
      cursor: not-allowed
    }
  `}
  white-space: nowrap;
  position: relative;
  display: flex;
  align-items: center;

  // thicker radio button border on element hover
  &:hover {
    span > div {
      border-width: 2px;
      background: ${({ theme, checked }) =>
        checked ? theme.backgroundRadiobuttonCheckedHover : theme.backgroundRadiobuttonHover};
    }
  }
`;

export const RadioButtonBullet = styled.span<{ active?: boolean; size?: FormControlSizes }>`
  width: ${({ theme, size = FormControlSizes.Default }) => getRadioButtonSize(size) - theme.spacingSmall * size}px;
  height: ${({ theme, size = FormControlSizes.Default }) => getRadioButtonSize(size) - theme.spacingSmall * size}px;
  margin: ${({ theme, size = FormControlSizes.Default }) => (theme.spacingSmall * size) / 2}px;
  position: absolute;
  top: 0;
  left: 0;
  background: ${({ theme }) => theme.colorRadiobuttonDot};
  border-radius: 50%;
  display: inline-block;
  transition: opacity 300ms ease;
  opacity: ${({ active }) => (active ? 1 : 0)};
`;
