import {
  CustomerOrderStrategiesEnum,
  OrdTypeEnum,
  ParameterTypeEnum,
  PresenceEnum,
  useSecuritiesContext,
  type WLOrderStrategy,
} from '@talos/kyoko';
import React, { memo, useContext, useMemo } from 'react';

export const StrategiesContext = React.createContext<StrategiesContextProps | undefined>(undefined);

export function useStrategiesContext() {
  const context = useContext(StrategiesContext);
  if (context === undefined) {
    throw new Error('Missing StrategiesContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export interface StrategiesContextProps {
  strategiesByName: Map<string, WLOrderStrategy>;
}

export const StrategiesProvider = memo((props: React.PropsWithChildren<unknown>) => {
  const { securitiesList } = useSecuritiesContext();
  const strategiesByName = useMemo(() => {
    const byNameMap = new Map();
    // Add Default and Deprecated Strategies to Strategy Map Resolver.
    [...DEPRECATED_ORDER_STRATEGIES, ...DEFAULT_ORDER_STRATEGIES].forEach(strategy => {
      byNameMap.set(strategy.Name, strategy);
    });
    securitiesList.forEach(security => {
      if (security.SupportedStrategies) {
        security.SupportedStrategies.forEach(strategy => {
          byNameMap.set(strategy.Name, strategy);
        });
      }
    });
    return byNameMap;
  }, [securitiesList]);

  return <StrategiesContext.Provider value={{ strategiesByName }}>{props.children}</StrategiesContext.Provider>;
});

const DEFAULT_ORDER_STRATEGIES: WLOrderStrategy[] = [
  {
    Name: CustomerOrderStrategiesEnum.Market,
    Group: 'All',
    Parameters: [],
    Description: 'Buy quantity at current market price',
    DisplayName: 'Market',
    OrdType: OrdTypeEnum.Market,
  },
  {
    Name: CustomerOrderStrategiesEnum.Limit,
    Group: 'All',
    OrdType: OrdTypeEnum.Limit,
    Parameters: [
      {
        Name: 'LimitPrice',
        Type: ParameterTypeEnum.Price,
        Presence: PresenceEnum.Required,
        Description: 'Limit Price for the order.',
        DisplayName: 'Limit Price',
      },
      {
        Name: 'StartTime',
        Type: ParameterTypeEnum.Date,
        Presence: PresenceEnum.Optional,
        Description: 'Time at which order will be placed on the market.',
        DisplayName: 'Start Time',
      },
      {
        Name: 'EndTime',
        Type: ParameterTypeEnum.Date,
        Presence: PresenceEnum.Optional,
        Description: 'Expire time for the order.',
        DisplayName: 'End Time',
      },
    ],
    Description:
      'For marketable orders, the algorithm will sweep the available liquidity up to the limit price by routing to those market(s) offering the best prices. The remaining quantity will be split equally among the selected market(s) at the limit price.',
    DisplayName: 'Limit',
  },
  {
    Name: CustomerOrderStrategiesEnum.SteadyPace,
    OrdType: OrdTypeEnum.Limit,
    Group: 'All',
    Parameters: [
      {
        Name: 'LimitPrice',
        Type: ParameterTypeEnum.Price,
        Presence: PresenceEnum.Required,
        Description: 'Limit Price for the order.',
        DisplayName: 'Limit Price',
      },
      {
        Name: 'ClipSize',
        Type: ParameterTypeEnum.Qty,
        Presence: PresenceEnum.Required,
        Description: 'A quantity (subset of the total order size) to send on each execution of the schedule.',
        DisplayName: 'Clip Size',
      },
      {
        Name: 'ClipInterval',
        Type: ParameterTypeEnum.Interval,
        Presence: PresenceEnum.Required,
        Description: 'Time interval between individual clips.',
        DisplayName: 'Clip Interval',
      },
      {
        Name: 'Variance',
        Type: ParameterTypeEnum.Percent,
        Presence: PresenceEnum.Optional,
        Description: 'Degree of randomization for clip sizes.',
        DisplayName: 'Variance',
      },
      {
        Name: 'StartTime',
        Type: ParameterTypeEnum.Date,
        Presence: PresenceEnum.Optional,
        Description: 'Time at which this order will activate and begin sending orders to the market.',
        DisplayName: 'Start Time',
      },
      {
        Name: 'EndTime',
        Type: ParameterTypeEnum.Date,
        Presence: PresenceEnum.Optional,
        Description: 'Expire time for the order.',
        DisplayName: 'End Time',
      },
    ],
    Description:
      'A schedule-based algorithm that divides an order into suborders (clips) and submits them to the market at equal, user defined intervals.',
    DisplayName: 'Steady Pace',
  },
  {
    Name: CustomerOrderStrategiesEnum.StopMarket,
    DisplayName: 'Stop Market',
    Description: 'This strategy will only place the market order once the specified stop price has been met.',
    OrdType: OrdTypeEnum.Market,
    Group: 'All',
    Parameters: [
      {
        Name: 'TriggerPrice',
        DisplayName: 'Stop Price',
        Type: ParameterTypeEnum.Price,
        Presence: PresenceEnum.Required,
        Description: 'Once this price is reached, the order will be executed as market order.',
      },
      {
        Name: 'StartTime',
        DisplayName: 'Start Time',
        Type: ParameterTypeEnum.Date,
        Presence: PresenceEnum.Optional,
        Description: 'Time at which order will be placed on the market.',
      },
      {
        Name: 'EndTime',
        DisplayName: 'End Time',
        Type: ParameterTypeEnum.Date,
        Presence: PresenceEnum.Optional,
        Description: 'Expire time for the order.',
      },
    ],
  },
  {
    Name: CustomerOrderStrategiesEnum.StopLimit,
    OrdType: OrdTypeEnum.Limit,
    Group: 'All',
    Parameters: [
      {
        Name: 'LimitPrice',
        Type: ParameterTypeEnum.Price,
        Presence: PresenceEnum.Required,
        Description: 'Limit Price for the order.',
        DisplayName: 'Limit Price',
      },
      {
        Name: 'TriggerPrice',
        Type: ParameterTypeEnum.Price,
        Presence: PresenceEnum.Required,
        Description: 'Once this price is reached, the order will be executed with the configured limit price.',
        DisplayName: 'Stop Price',
      },
      {
        Name: 'StartTime',
        Type: ParameterTypeEnum.Date,
        Presence: PresenceEnum.Optional,
        Description: 'Time at which order will be placed on the market.',
        DisplayName: 'Start Time',
      },
      {
        Name: 'EndTime',
        Type: ParameterTypeEnum.Date,
        Presence: PresenceEnum.Optional,
        Description: 'Expire time for the order.',
        DisplayName: 'End Time',
      },
    ],
    Description: 'This strategy will only place the order once the specified stop price has been met.',
    DisplayName: 'Stop Limit',
  },
];

/* [DEAL-2581] WL UI backwards compatibility for renamed Strategies
   DEPRECATED_ORDER_STRATEGIES doesn't come up as selectable in the strategy list, but is a part of strategiesByName
   When modifying or resubmitting old orders that use a Deprecated Strategy, their info will be taken from below */
const DEPRECATED_ORDER_STRATEGIES: WLOrderStrategy[] = [
  // MultilegAggressiveLimit is the exact same as Limit, except for its name.
  {
    ...(DEFAULT_ORDER_STRATEGIES.find(s => s.Name === CustomerOrderStrategiesEnum.Limit) as WLOrderStrategy),
    Name: CustomerOrderStrategiesEnum.MultilegAggressiveLimit,
  },
];
