export enum NotificationVariants {
  Default = 'DEFAULT',
  Primary = 'PRIMARY',
  Positive = 'POSITIVE',
  Negative = 'NEGATIVE',
  Warning = 'WARNING',
  Attention = 'ATTENTION',
}

export enum NotificationSizes {
  Small,
  Default,
}
