import type { LayoutRequiredPermissions } from '@talos/kyoko';

export const checkRequiredPermissions = (
  permissions: LayoutRequiredPermissions | undefined,
  isAuthorized: (permission: string) => boolean
): boolean => {
  if (!permissions) {
    return true;
  }

  return permissions.every(permission => {
    if (Array.isArray(permission)) {
      return permission.some(p => isAuthorized(p));
    }
    return isAuthorized(permission);
  });
};
