export enum CustomerUserConfigLayoutType {
  TradingLayout = 'TradingLayout',
  TradingLadderLayout = 'TradingLadderLayout',
  WatchListTradingLayout = 'WatchListTradingLayout',
  SimpleRFQLayout = 'SimpleRFQLayout',
  SimpleRFQLayoutWithExposureBlotter = 'SimpleRFQLayoutWithExposureBlotter',
  SettingsLayout = 'SettingsLayout',
  PortfolioLayout = 'PortfolioLayout',
  AddressesLayout = 'AddressesLayout',
  FeeTiersLayout = 'FeeTiersLayout',
}

/**
 * TODO: Figure out a little more elegant way of mapping these things as either
 * global or protected layouts, as well as to specific Layouts, so that downstream,
 * we can easily generate types with keyof typeof and ensure that we provide some
 * guardrails to our key lookups, no matter which particular Layout arrangements
 * we're dealing with.
 */

// Global Demo Layouts
export enum GlobalCustomerUserConfigTradingLayoutArrangements {
  Default = 'Default', // Default Layout Arrangement (unsets user config value and inherits from site config)
  SingleSymbolView = 'SingleSymbolView', // Default Layout Arrangement
  WatchlistAndMarketDataCards = 'WatchlistAndMarketDataCards',
  CandlesticksAndMarketDataCards = 'CandlesticksAndMarketDataCards',
  MarketDataCards = 'MarketDataCards',
  WatchlistCandlesticksMarketDataCards = 'WatchlistCandlesticksMarketDataCards',
}

// WM Layouts
export enum ProtectedCustomerUserConfigTradingLayoutArrangements {
  WMLayout1 = 'WMLayout1',
  WMLayout2 = 'WMLayout2',
}

export const CustomerUserConfigTradingLayoutArrangements = {
  // WM Layouts
  [ProtectedCustomerUserConfigTradingLayoutArrangements.WMLayout1]: 'WM Layout 1',
  [ProtectedCustomerUserConfigTradingLayoutArrangements.WMLayout2]: 'WM Layout 2',

  // Global Demo Layouts
  [GlobalCustomerUserConfigTradingLayoutArrangements.SingleSymbolView]: 'Single Symbol View',
  [GlobalCustomerUserConfigTradingLayoutArrangements.WatchlistAndMarketDataCards]: 'Watchlist + Market Data Cards',
  [GlobalCustomerUserConfigTradingLayoutArrangements.CandlesticksAndMarketDataCards]:
    'Candlesticks + Market Data Cards',
  [GlobalCustomerUserConfigTradingLayoutArrangements.MarketDataCards]: 'Market Data Cards',
  [GlobalCustomerUserConfigTradingLayoutArrangements.WatchlistCandlesticksMarketDataCards]:
    'Watchlist, Candlesticks, Market Data Cards',
};

export type CustomerUserConfigLayoutArrangements<T> = Map<CustomerUserConfigLayoutType, T>;

export enum CustomerUserConfigLayoutNavigationTypes {
  Sidebar = 'Sidebar',
  Header = 'Header',
  SidebarHeaderToggle = 'SidebarHeaderToggle',
  CompactHeader = 'CompactHeader',
  None = 'None',
}

export const CustomerUserConfigLayoutTypesAndArrangements: CustomerUserConfigLayoutArrangements<
  typeof CustomerUserConfigTradingLayoutArrangements
> = new Map([[CustomerUserConfigLayoutType.TradingLayout, CustomerUserConfigTradingLayoutArrangements]]);
