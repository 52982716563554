import styled, { css } from 'styled-components';

import { Flex } from '../../Core';
import type { FormControlSizes } from '../../Form/types';
import { getFontSize } from '../../Form/utils';
import { ButtonWrapper, getButtonSpacing } from '../styles';
import { getButtonBorderRadius } from '../utils';

export const ButtonGroup = styled(Flex)<{
  size?: FormControlSizes;
  orientation?: 'vertical' | 'horizontal';
  rounded?: boolean;
}>`
  flex-direction: ${({ orientation }) => (orientation === 'vertical' ? 'column' : 'row')};

  ${ButtonWrapper} {
    flex: 1 1 0;
    border-radius: 0;
    padding: ${({ size, theme }) => getButtonSpacing(theme, size)};
    font-size: ${({ size, theme }) => getFontSize(theme, size)}rem;

    & + ${ButtonWrapper} {
      gap: 1px;
    }

    ${({ rounded, orientation, size, theme }) =>
      rounded
        ? css`
            border-radius: ${getButtonBorderRadius(theme, size)}px;
          `
        : css`
            &:first-child {
              ${orientation === 'vertical'
                ? css`
                    border-radius: ${getButtonBorderRadius(theme, size)}px ${getButtonBorderRadius(theme, size)}px 0 0;
                  `
                : css`
                    border-radius: ${getButtonBorderRadius(theme, size)}px 0 0 ${getButtonBorderRadius(theme, size)}px;
                  `};
            }

            &:last-child {
              ${orientation === 'vertical'
                ? css`
                    border-radius: 0 0 ${getButtonBorderRadius(theme, size)}px ${getButtonBorderRadius(theme, size)}px;
                  `
                : css`
                    border-radius: 0 ${getButtonBorderRadius(theme, size)}px ${getButtonBorderRadius(theme, size)}px 0;
                  `};
            }

            &:first-child:last-child {
              border-radius: ${getButtonBorderRadius(theme, size)}px;
            }
          `}
  }
`;
ButtonGroup.defaultProps = {
  gap: '1px',
};
