import { ThresholdTypeEnum, type ModeEnum, type PositionSideTypeEnum, type UpdateActionEnum } from './types';

export interface SubAccountWindowLimit {
  Timestamp: string;
  LimitID: string;
  Direction: PositionSideTypeEnum;
  ThresholdType: ThresholdTypeEnum;
  ThresholdValue: string;
  WindowDuration: string;
  ThresholdAsset?: string;
  SubAccount?: string;
  MatchAsset?: string;
  MatchUser?: string;
  Mode: ModeEnum;
  UpdateAction?: UpdateActionEnum;
  Revision: number;
  UpdateUser: string;
  Description?: string;
}

export function prettifyThresholdTypeEnum(thresholdType: ThresholdTypeEnum): string {
  switch (thresholdType) {
    case ThresholdTypeEnum.NewOrderCount:
      return 'New Order Count';
    case ThresholdTypeEnum.NewOrderVolume:
      return 'New Order Volume';
    case ThresholdTypeEnum.TotalActiveVolume:
      return 'Total Active Volume';
    default:
      return thresholdType;
  }
}

export function getThresholdTypeDescription(thresholdType: ThresholdTypeEnum): string {
  switch (thresholdType) {
    case ThresholdTypeEnum.NewOrderCount:
      return 'New Order Count - limits the number of new orders that can be submitted within a specific rolling look-back period.';
    case ThresholdTypeEnum.NewOrderVolume:
      return 'New Order Volume - limits total volume of new orders that can be submitted within a specific rolling look-back period.';
    case ThresholdTypeEnum.TotalActiveVolume:
      return 'Total Active Volume - limits combined volume of new orders, existing open orders, and filled orders within a specific rolling look-back period.';
    default:
      return '';
  }
}
