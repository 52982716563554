import styled from 'styled-components';
import { FormControlSizes } from '../types';
import { getFontSize } from '../utils';

export const DropdownContentWrapper = styled.div<{ size?: FormControlSizes }>`
  position: absolute;
  box-shadow: ${({ theme }) => theme.boxShadowDropdown};

  background: ${({ theme }) => theme.backgroundDropdownResults};
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  overflow: hidden;
  font-size: ${({ theme, size = FormControlSizes.Default }) => getFontSize(theme, size)}rem;
`;
