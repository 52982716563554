import { createContext, useContext } from 'react';

export const AuthContext = createContext<AuthContextProps | undefined>(undefined);
AuthContext.displayName = 'AuthContext';

export function useAuthContext() {
  const context = useContext(AuthContext);
  if (context == null) {
    throw new Error('Missing AuthContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export interface AuthContextProps {
  isAuthenticated: boolean;
  logout(): void;
  login?(): void;
  /**
   * For mobile, we attempt auto login by checking the cookies on mount. In order to prevent rendering the LogIn
   * page in cases where we successfully logged in automatically, we keep track of whether we've attempted to.
   *
   * Hence,
   *
   * if `isAuthenticated` is false, and `hasAttemptedAutoLogin` is true, we should render the LogIn page.
   *
   * if `isAuthenticated` is false, and `hasAttemptedAutoLogin` is false, we should not render the LogIn page.
   */
  hasAttemptedAutoLogin?: boolean;
}
