import {
  Box,
  Button,
  ButtonGroup,
  ButtonVariants,
  CustomerUserConfigLayoutType,
  Divider,
  Flex,
  FormControlSizes,
  FormGroup,
  FormattedMessage,
  IconButton,
  IconName,
  MixpanelEvent,
  MixpanelEventProperty,
  Sidebar,
  SidebarCurrentFeeTier,
  SidebarHeader,
  SidebarItem,
  SidebarPinToggle,
  SidebarSection,
  SidebarThemeToggle,
  StreamDiagnosticsDialog,
  ThemeTypes,
  Tooltip,
  getIntlKey,
  themes,
  useAuthContext,
  useGlobalDialog,
  useMixpanel,
  useSidebarContext,
  useUserContext,
  useWLLayoutType,
  useWLOrgConfigContext,
  useWLPinSidebar,
  type CustomerUserConfigNavigationItem,
} from '@talos/kyoko';
import { useCallback, useMemo, useState } from 'react';
import { defineMessages } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { ThemeProvider, useTheme } from 'styled-components';
import { Logo } from '../../components/Logo';

import { useNavigationItems } from '../../hooks/useNavigationItems';
import { ALL_NAV_PATHS } from './Nav';

export const navSidebarMessages = defineMessages({
  demoSettings: {
    id: 'NavigationSidebar.demoSettings',
    defaultMessage: 'Demo Settings',
  },
  logOut: {
    id: 'NavigationSidebar.logOut',
    defaultMessage: 'Log Out',
  },
  rfqLayout: {
    id: 'NavigationSidebar.rfqLayout',
    defaultMessage: 'RFQ Layout',
  },
  support: {
    id: 'NavigationSidebar.support',
    defaultMessage: 'Support',
  },
  tradingLayout: {
    id: 'NavigationSidebar.tradingLayout',
    defaultMessage: 'Trading Layout',
  },
  market: {
    id: 'NavigationSidebar.market',
    defaultMessage: 'Market',
  },
  apiSettings: {
    id: 'NavigationSidebar.apiSettings',
    defaultMessage: 'API Settings',
  },
  addresses: {
    id: 'NavigationSidebar.addresses',
    defaultMessage: 'Addresses',
  },
  feeTiers: {
    id: 'NavigationSidebar.feeTiers',
    defaultMessage: 'Fee Tiers',
  },
});

const NAVIGATION_SIDEBAR_WIDTH = 220;

export const NavigationSidebar = ({
  themeType,
  isTalosWLDemoEnv,
  onChangeThemeType,
  openDemoModal,
  openSupportModal,
}: {
  isTalosWLDemoEnv: boolean;
  openDemoModal: () => void;
  openSupportModal: () => void;
  themeType: ThemeTypes;
  onChangeThemeType(themeType: ThemeTypes): void;
}) => {
  const { pinSidebar } = useWLPinSidebar();

  // On initial render, we should check the route and provide the initial state to the Sidebar context
  const location = useLocation();
  const [initialPinned] = useState<boolean>(() => {
    const mainPath = getMainPath(location.pathname);

    // We might not find a pathData object here, this is then "undefined", and we just go by whatever
    // "pinSidebar" is set to
    const pathData = ALL_NAV_PATHS.find(path => path.path === mainPath);

    // If either of these are true, the sidebar should start pinned
    return (pinSidebar || pathData?.hasSubNavItems) ?? false;
  });

  const theme = useTheme();
  const sidebarTheme = (theme.sidebarThemeName && themes[theme.sidebarThemeName]) || theme;

  return (
    <>
      <ThemeProvider theme={sidebarTheme}>
        <Sidebar expandedWidth={NAVIGATION_SIDEBAR_WIDTH} initialPinned={initialPinned}>
          <NavigationBody
            isTalosWLDemoEnv={isTalosWLDemoEnv}
            openDemoModal={openDemoModal}
            themeType={themeType}
            openSupportModal={openSupportModal}
            onChangeThemeType={onChangeThemeType}
          />
        </Sidebar>
      </ThemeProvider>
    </>
  );
};

export const NavigationBody = ({
  themeType,
  isTalosWLDemoEnv,
  openDemoModal,
  openSupportModal,
  onChangeThemeType,
}: {
  themeType: ThemeTypes;
  isTalosWLDemoEnv: boolean;
  openDemoModal: () => void;
  openSupportModal: () => void;
  onChangeThemeType(themeType: ThemeTypes): void;
}) => {
  const { user } = useUserContext();
  const { setPinSidebar } = useWLPinSidebar();
  const { layoutType, setLayoutType, tradingLayout } = useWLLayoutType();
  const theme = useTheme();

  const mixpanel = useMixpanel();

  const handleOnPinChange = useCallback(
    (pinned: boolean) => {
      mixpanel.track(MixpanelEvent.ChangeSidebarPin, { pinned });

      // Store selection in display settings
      setPinSidebar(pinned);
    },
    [mixpanel, setPinSidebar]
  );

  const { config: customerConfig } = useWLOrgConfigContext();

  const { setExpanded, isExpanded, isPinned } = useSidebarContext();

  const handleOpenSupportModal = useCallback(() => {
    openSupportModal();
    setExpanded(isPinned);
    mixpanel.track(MixpanelEvent.OpenSupportModal);
  }, [openSupportModal, mixpanel, isPinned, setExpanded]);

  const { logout } = useAuthContext();
  const { type } = useTheme();

  const collapsedIcon: string = useMemo(() => {
    if (customerConfig?.collapsedLogo) {
      switch (theme.type) {
        case ThemeTypes.dark:
          return customerConfig.collapsedLogo.dark;
        case ThemeTypes.light:
          return customerConfig.collapsedLogo.light;
      }
    }
    return customerConfig.favicon;
  }, [customerConfig.collapsedLogo, customerConfig.favicon, theme]);

  const handleChangeLayout = useCallback(
    (layoutType: CustomerUserConfigLayoutType) => {
      mixpanel.track(MixpanelEvent.ChangeLayout, { [MixpanelEventProperty.Type]: layoutType });
      setLayoutType(layoutType);
    },
    [setLayoutType, mixpanel]
  );

  const handleThemeType = useCallback(
    themeType => {
      mixpanel.track(MixpanelEvent.ChangeThemeType, { type: themeType });
      onChangeThemeType(themeType);
    },
    [mixpanel, onChangeThemeType]
  );

  const navItems = useNavigationItems();

  const isProd = import.meta.env.VITE_AVA_ENV === 'prod';

  const { open } = useGlobalDialog();

  const showStreamDiagnostics = useCallback(() => {
    if (isTalosWLDemoEnv) {
      open({
        onConfirm: async () => {},
        title: 'Stream Diagnostics',
        showClose: true,
        showCancel: false,
        showConfirm: false,
        closeOnClickOutside: false,
        content: <StreamDiagnosticsDialog />,
      });
    }
  }, [open, isTalosWLDemoEnv]);

  const displayName = (
    <SidebarItem
      disableHoverStyles={!isTalosWLDemoEnv && customerConfig.hideUserEmail}
      icon={IconName.UserCircle}
      onClick={showStreamDiagnostics}
    >
      {user.DisplayName}
    </SidebarItem>
  );
  const userInfo = customerConfig.hideUserEmail ? displayName : <Tooltip tooltip={user.Email}>{displayName}</Tooltip>;

  return (
    <>
      <SidebarSection>
        <Box mt="spacingSmall">
          <SidebarHeader>
            {isExpanded ? (
              <Box w="100%" justifyContent="flex-start">
                <Logo themeType={type} height="32px" />
              </Box>
            ) : (
              <Flex flex={isExpanded ? '1' : '0'} justifyContent="center" m="0 auto">
                <img width={20} height={20} src={`/customers/${collapsedIcon}`} alt="Logo" />
              </Flex>
            )}
          </SidebarHeader>
        </Box>
        <NavPaths navPaths={navItems} />
      </SidebarSection>
      <SidebarSection mt="auto">
        {(customerConfig.menuItems?.length ?? 0) > 0 && (
          <>
            {isExpanded ? (
              customerConfig.menuItems?.map(item => (
                <a key={`${item.label}_${item.url}`} href={item.url} target="_blank" rel="noreferrer">
                  <SidebarItem icon={IconName.Exit}>{item.label}</SidebarItem>
                </a>
              ))
            ) : (
              // Show only 1 external item when the sidebar is collapsed
              <SidebarItem icon={IconName.Exit} />
            )}
            <Divider />
          </>
        )}
      </SidebarSection>
      <SidebarSection>
        <SidebarItem icon={IconName.Support} onClick={handleOpenSupportModal}>
          <FormattedMessage {...navSidebarMessages.support} />
        </SidebarItem>
        <SidebarItem icon={IconName.Logout} onClick={logout}>
          <FormattedMessage {...navSidebarMessages.logOut} />
        </SidebarItem>
      </SidebarSection>
      <SidebarSection>
        <Divider />
        <SidebarPinToggle onChange={handleOnPinChange} />
        <SidebarCurrentFeeTier />
        {customerConfig?.allowThemeChange !== false && (
          <SidebarThemeToggle themeType={themeType} onChangeThemeType={handleThemeType} />
        )}
        {isTalosWLDemoEnv && (
          <SidebarItem onClick={openDemoModal} icon={IconName.PresentationChartBar}>
            <FormattedMessage {...navSidebarMessages.demoSettings} />
          </SidebarItem>
        )}
      </SidebarSection>
      <SidebarSection>
        <Divider />
        {userInfo}
      </SidebarSection>
      {(!isProd || customerConfig?.allowLayoutChange) && (
        <SidebarSection style={{ height: '24px' }}>
          {isExpanded ? (
            <FormGroup>
              <ButtonGroup size={FormControlSizes.Small}>
                <Button
                  disabled={layoutType === CustomerUserConfigLayoutType.SimpleRFQLayout}
                  value={CustomerUserConfigLayoutType.SimpleRFQLayout}
                  onClick={() => handleChangeLayout(CustomerUserConfigLayoutType.SimpleRFQLayout)}
                >
                  <FormattedMessage {...navSidebarMessages.rfqLayout} />
                </Button>
                <Button
                  disabled={layoutType === tradingLayout}
                  value={tradingLayout}
                  onClick={() => handleChangeLayout(CustomerUserConfigLayoutType.TradingLayout)}
                >
                  <FormattedMessage {...navSidebarMessages.tradingLayout} />
                </Button>
              </ButtonGroup>
            </FormGroup>
          ) : (
            <IconButton
              variant={ButtonVariants.Default}
              size={FormControlSizes.Small}
              icon={IconName.DeviceDesktop}
              style={{ width: 'auto' }}
            />
          )}
        </SidebarSection>
      )}
    </>
  );
};

function getMainPath(pathname: string): string {
  return pathname.split('/')[1];
}

function NavPaths({ navPaths }: { navPaths: CustomerUserConfigNavigationItem[] }) {
  return (
    <>
      {navPaths.map(({ path, icon, hasSubNavItems, label }) => {
        const intlKey = getIntlKey(label);
        return (
          <SidebarItem key={path} to={path} icon={icon} hasSubNavItems={hasSubNavItems}>
            {intlKey in navSidebarMessages ? <FormattedMessage {...navSidebarMessages[intlKey]} /> : label}
          </SidebarItem>
        );
      })}
    </>
  );
}
