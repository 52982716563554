import { ParameterTypeEnum } from '../../types/OrderStrategy';

export { AmountInput } from './AmountInput';
export * from './BalanceDetails';
export * from './CustomerBalanceDetails';
export * from './FormBalances';
export { IntervalInput } from './IntervalInput';
export * from './NewOrderForm';
export * from './NewOrderForm/types';
export { PriceInput } from './PriceInput';
export * from './PriceInput/usePriceInput';
export * from './QuantityInput';
export { RFQForm } from './RFQForm';
export { RemainingTime } from './RFQForm/RemainingTime';
export { QuoteButtons } from './RFQForm/styles';
export * from './types';

export const isPercentParameterValue = (value: unknown, type?: ParameterTypeEnum): value is string | number => {
  return value != null && type === ParameterTypeEnum.Percent;
};
export const isDateParameterValue = (value: unknown, type?: ParameterTypeEnum): value is string | Date | null => {
  return value != null && type === ParameterTypeEnum.Date;
};
