import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';

import {
  Box,
  MixpanelEvent,
  MixpanelEventProperty,
  SmartTruncateEnum,
  VolumeLadderSteps,
  isCFD,
  primeOrderForm,
  useMarketDataSnapshot,
  useMixpanel,
  useSecurity,
  useWLOrderFormDispatch,
  useWLOrgConfigContext,
  type Security,
  type SideEnum,
  type VolumeLadderMarketViewProps,
  type VolumeLadderStepsProps,
  type VolumeLadderUpdateRowCallback,
} from '@talos/kyoko';
import Big from 'big.js';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { useMarketDataCards } from '../../providers';
import { MarketDataCardHeader } from './MarketDataCardHeader';
import { MarketDataCard as SharedMarketDataCard } from './styles';
import type { MarketDataCardProps } from './types';

const ensureNumberOfBuckets = (sizeBuckets: string[], numberOfBuckets: number) => {
  return new Array(numberOfBuckets).fill(true).reduce(
    (acc, _, index) => {
      const { returnedBuckets } = acc;
      let { lastValidBucketSize } = acc;

      // Reassign lastValidBucketSize to either the current iteration's defined bucket size, or back to itself if undefined.
      // This way, as the volume ladder extends, we just show duplicate bucket sizes for any that are missing, instead of 0's.
      const newBucketSize =
        sizeBuckets[index] ?? Number(lastValidBucketSize ?? 0) + Number(returnedBuckets[index - 1] ?? '1');
      lastValidBucketSize = sizeBuckets[index] ?? lastValidBucketSize;
      returnedBuckets[index] = newBucketSize;
      return {
        lastValidBucketSize,
        returnedBuckets,
      };
    },
    { lastValidBucketSize: '0', returnedBuckets: [] }
  ).returnedBuckets;
};

export const MarketDataCard = memo(function MarketDataCard({
  config,
  isDraggable,
  isDragged,
  onDelete,
}: MarketDataCardProps) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: config.id });
  const mixpanel = useMixpanel();
  const { saveCard } = useMarketDataCards();
  const { detailsExpanded, symbol, view } = config;
  const { config: customerConfig } = useWLOrgConfigContext();
  const { showSpreadOnVolumeLadder, volumeLadderDecimalPrecision } = customerConfig;
  const security: Security | undefined = useSecurity(symbol);
  const defaultSizeBuckets = useMemo(
    () => (security?.SizeBuckets ?? []).map(bucket => (typeof bucket === 'string' ? bucket : bucket.Size)) ?? [],
    [security?.SizeBuckets]
  );
  const [sizeBuckets, setSizeBuckets] = useState<string[]>(
    ensureNumberOfBuckets(config.sizeBuckets ?? defaultSizeBuckets, 5)
  );
  const style = useMemo(
    () => ({
      transform: CSS.Transform.toString(transform),
      transition,
    }),
    [transform, transition]
  );

  const { marketDataSnapshots: marketDataObservable } = useMarketDataSnapshot({
    clearOnSymbolChange: true,
    sizeBuckets,
    symbol: security!.Symbol,
    tag: `SECURITY_CARD_MARKET_DATA`,
    priceIncrement: security?.MinPriceIncrement,
    includeTradeDirection: false,
  });

  const orderFormDispatch = useWLOrderFormDispatch();

  const handleClickRow = useCallback(
    ({ price, side, size }) => {
      mixpanel.track(MixpanelEvent.ClickLadderRow, { [MixpanelEventProperty.Side]: side });
      orderFormDispatch(
        primeOrderForm({
          Symbol: security!.Symbol,
          Side: side as SideEnum,
          Price: price,
          OrderQty: size != null ? Big(size).toFixed() : security?.NormalSize,
        })
      );
    },
    [orderFormDispatch, mixpanel, security]
  ) satisfies VolumeLadderMarketViewProps['onClickRow'];

  const handleUpdateRow = useCallback<VolumeLadderUpdateRowCallback>(
    ({ index, size }) => {
      if (sizeBuckets[index] !== size) {
        const newBuckets = [...sizeBuckets];
        newBuckets[index] = size;
        setSizeBuckets(newBuckets);
        saveCard({
          ...config,
          sizeBuckets: newBuckets,
        });
      }
    },
    [config, saveCard, sizeBuckets]
  ) satisfies VolumeLadderStepsProps['onUpdateRow'];

  useEffect(() => {
    setSizeBuckets(prev =>
      ensureNumberOfBuckets(
        ((config.sizeBuckets?.length ?? 0) > 0 ? config.sizeBuckets : undefined) ?? defaultSizeBuckets ?? prev,
        5
      )
    );
  }, [defaultSizeBuckets, config.sizeBuckets]);

  return (
    <Box ref={setNodeRef} style={style}>
      <SharedMarketDataCard
        ErrorBoundary={ErrorBoundary}
        isDragged={isDragged}
        isExpanded={!!detailsExpanded}
        view={view}
      >
        <MarketDataCardHeader
          config={config}
          draggableAttributes={attributes}
          draggableListeners={listeners}
          isDraggable={isDraggable}
          onDelete={onDelete}
          security={security}
          onSaveCard={saveCard}
        />
        {detailsExpanded && (
          <VolumeLadderSteps
            sizeBuckets={sizeBuckets}
            marketDataObservable={marketDataObservable}
            security={security}
            onClickRow={handleClickRow}
            onUpdateRow={handleUpdateRow}
            showSpread={showSpreadOnVolumeLadder}
            bpsIncrement={volumeLadderDecimalPrecision}
            height={208}
            isCompact={true}
            pb="spacingMedium"
            smartTruncate={isCFD(security) ? SmartTruncateEnum.Compact : SmartTruncateEnum.None}
          />
        )}
      </SharedMarketDataCard>
    </Box>
  );
});
