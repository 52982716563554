import styled from 'styled-components';
import { MAIN_HEADER_HEIGHT } from '../../../styles/dimensions';

export const HeaderContent = styled.div<{ maxWidth?: number }>`
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : '')}
  display: flex;
  align-items: center;
  margin: 0 auto;
  gap: 0 ${({ theme }) => theme.spacingDefault}px;
  height: 100%;
`;

export const HeaderWrapper = styled.div`
  background: ${({ theme }) => theme.backgroundHeader};
  border: solid 1px ${({ theme }) => theme.borderColorHeader};
  flex: 0 0 auto;
  height: ${MAIN_HEADER_HEIGHT}px;
  padding: 0 ${({ theme }) => theme.spacingMedium}px;
  width: 100%;
  border-radius: 0 0 ${({ theme }) => `${theme.borderRadiusContent}px ${theme.borderRadiusContent}px`};
  box-shadow: ${({ theme }) => theme.boxShadowHeader};
  backdrop-filter: ${({ theme }) => theme.backdropFilterHeader};
  margin-bottom: ${({ theme }) => theme.spacingLayout}px;
`;

export const Section = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: ${({ theme }) => theme.spacingDefault}px;
`;
