import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { DarkTheme, darkColors, mapDarkTheme } from './DarkTheme';
import { getInputStylesAppliedToSelect } from './utils';

const colors: Colors = {
  black: {
    default: '#000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    main: '#00092E',

    '000': '#00020d',
    '010': '#11172e',
    '020': '#192142',
    '030': '#2f3754',
    '040': '#2f3754',
    '050': '#37383F',
    '060': '#323a57',
    '070': '#5d637a',
    '080': '#818598',
    '090': '#a5a8b5',
    '100': '#ededf0',
  },

  primary: {
    mute: setAlpha(0.1, '#7B61FF'),
    dim: setAlpha(0.4, '#7B61FF'),
    default: '#7B61FF',
    lighten: '#7B61FF',
  },

  green: {
    mute: setAlpha(0.1, '#7B61FF'),
    dim: setAlpha(0.4, '#7B61FF'),
    default: '#7B61FF',
    lighten: '#7B61FF',
  },

  red: {
    mute: 'rgba(146, 46, 67, 0.1)',
    dim: 'rgba(146, 46, 67, 0.4)',
    default: '#ed423d',
    lighten: '#ed423d',
  },

  yellow: {
    mute: 'rgba(204, 172, 62, 0.1)',
    dim: 'rgba(204, 172, 62, 0.4)',
    default: '#CCAC3E',
    lighten: '#F2D264',
  },

  blue: {
    mute: 'rgba(69, 140, 193, 0.1)',
    dim: 'rgba(69, 140, 193, 0.4)',
    default: '#458CC1',
    lighten: '#53AFF8',
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: darkColors.purple,
};

export const DarkOneLiquidityTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkOL',

  fontUrl: 'https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap',
  fontFamily: "'Inter', sans-serif",

  backgroundSidebar: colors.gray.main,
  borderColorSidebar: colors.gray.main,

  backgroundHeader: colors.gray.main,
  borderColorHeader: colors.gray.main,

  backgroundDefaultButton: colors.gray.main,
  backgroundDefaultButtonHover: setAlpha(0.08, colors.gray['100']),
  backgroundDefaultButtonFocus: setAlpha(0.08, colors.gray['100']),
  backgroundDefaultButtonActive: colors.gray.main,

  backgroundPriorityButton: colors.gray.main,
  backgroundPriorityButtonHover: setAlpha(0.08, colors.gray['100']),
  backgroundPriorityButtonFocus: setAlpha(0.08, colors.gray['100']),
  backgroundPriorityButtonActive: colors.gray.main,

  borderWidthButton: 1,

  borderColorDefaultButton: colors.gray['060'],
  borderColorDefaultButtonHover: colors.gray['060'],
  borderColorDefaultButtonFocus: colors.gray['060'],
  borderColorDefaultButtonActive: colors.gray['060'],

  borderColorPriorityButton: colors.gray['060'],
  borderColorPriorityButtonHover: colors.gray['060'],
  borderColorPriorityButtonFocus: colors.gray['060'],
  borderColorPriorityButtonActive: colors.gray['060'],

  ...getInputStylesAppliedToSelect(DarkTheme),

  backgroundChartUpColor: '#009d70',
  borderColorChartUpColor: '#009d70',
});
