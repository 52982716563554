import { createContext, useContext } from 'react';

export const dropdownContextDefaultValue: DropdownContextProps = { withinDropdown: true };
export const DropdownContext = createContext<DropdownContextProps | undefined>(undefined);

export interface DropdownContextProps {
  withinDropdown: boolean;
}

export function useDropdownContext() {
  return useContext(DropdownContext);
}
