import styled, { type CSSProperties, type DefaultTheme } from 'styled-components';
import { parseDimension } from '../../styles';
import { Box, type BoxProps } from './Box';

export interface FlexProps extends BoxProps {
  /** Justify Content */
  justifyContent?: CSSProperties['justifyContent'];
  /** Align Items */
  alignItems?: CSSProperties['alignItems'];
  /** Flex */
  flex?: CSSProperties['flex'];
  /** Flex Direction */
  flexDirection?: CSSProperties['flexDirection'];
  /** Gap */
  gap?: CSSProperties['gap'] | keyof DefaultTheme | string | number;
}

/** Simple flex div
 *
 * Set flex properties without creating a new component.
 */
export const Flex = styled(Box)<FlexProps>`
  display: flex;
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection}`};
  ${({ justifyContent }) => justifyContent && `justify-content: ${justifyContent}`};
  ${({ alignItems }) => alignItems && `align-items: ${alignItems}`};
  ${({ flex }) => flex && `flex: ${flex}`};
  ${({ theme, gap }) => gap && `gap: ${parseDimension(theme, gap)}`};
`;
