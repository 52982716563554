import styled from 'styled-components';
import { Button, getButtonSpacing } from '../../Button';
import { Box } from '../../Core';
import { FormControlSizes } from '../../Form';

export const DropdownMenu = styled.ul<{ isOpen: boolean }>`
  background: ${({ theme }) => theme.backgroundContent};
  border-radius: 0 0 ${({ theme }) => theme.borderRadiusDefault}px;
  /* border: solid 1px ${({ theme }) => theme.borderColorInputFocus}; */
  /* border-top: none; */
  left: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0;
  z-index: 2;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transform: ${({ isOpen }) => (isOpen ? `scaleY(1)` : `scaleY(0.9)`)};
  transform-origin: top center;
  transition: transform 200ms ease-out;
  max-height: 60vh;
  overflow-y: auto;
`;

export const MenuItem = styled.li<{ highlighted: boolean; size?: FormControlSizes }>`
  padding: ${({ theme, size }) => getButtonSpacing(theme, size ?? FormControlSizes.Default)};
  transition: background 200ms ease;
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    flex: 0 0 auto;
  }

  color: ${({ theme }) => theme.colors.gray['090']};
  background: ${({ highlighted, theme }) => (highlighted ? theme.colors.gray['060'] : theme.colors.gray['040'])};

  label {
    line-height: 1;
    padding-left: ${({ theme }) => theme.spacingDefault * 2 + 1}px;
    color: ${({ theme }) => theme.colorTextDefault};
    pointer-events: none;

    div {
      font-size: ${({ theme }) => theme.fontSizeTiny}rem;
      color: ${({ theme }) => theme.colorTextSubtle};
    }
  }
`;

export const DropdownFilterWrapper = styled(Box)`
  flex: 1 1 0;
  position: relative;

  ${Button} {
    width: 100%;
  }
`;
