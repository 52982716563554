import { useMemo } from 'react';

import { useSecuritiesContext } from '@talos/kyoko';

import { useMarketDataCards } from '../../../providers';

export function useCards() {
  const { securitiesBySymbol } = useSecuritiesContext();
  const { cards } = useMarketDataCards();

  return useMemo(() => {
    return cards.filter(card => securitiesBySymbol.get(card.symbol ?? ''));
  }, [cards, securitiesBySymbol]);
}
