import { useWLOrgConfigContext } from '@talos/kyoko';
import type { HTMLAttributes } from 'react';
import styled, { css, type CSSProperties } from 'styled-components';

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  color: ${({ theme }) => theme.colorTextAttention};
  font-size: ${({ theme }) => theme.fontSizeLarge}rem;
`;

const LogoImg = styled.img`
  max-height: 32px;
  min-width: 32px;
  height: 100%;
  max-width: 100%;
  ${({ width }) =>
    width !== undefined &&
    css`
      width: ${width};
    `}
  ${({ height }) =>
    height !== undefined &&
    css`
      height: ${height};
    `}

  object-fit: contain;
`;

/**
 * Logos are hosted on this url (specifically only defined for localhost), any other environment
 * will use relative paths (/path-to).
 */
const AssetsURL =
  import.meta.env.VITE_AVA_ENV === 'local' ? window.location.origin.replace('localhost:3100', 'com') : '';

export const Logo = styled(
  ({
    themeType,
    width,
    height,
    ...props
  }: {
    width?: CSSProperties['width'];
    height?: CSSProperties['height'];
    themeType: 'dark' | 'light';
  } & HTMLAttributes<HTMLDivElement>) => {
    const { config } = useWLOrgConfigContext();
    const logoUrl = AssetsURL + `/customers/${config.logo?.[themeType]}`;

    return (
      <LogoWrapper {...props}>
        {config.logo?.[themeType] ? (
          <LogoImg height={height} width={width} src={logoUrl ?? ''} alt="" />
        ) : (
          'Company Name'
        )}
      </LogoWrapper>
    );
  }
)``;
