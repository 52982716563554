import { createContext, useContext } from 'react';
import type { Observable } from 'rxjs';
import type { OptionSecurity, RepresentativeOptionSecurity } from '../types';
import type { FutureSecurity } from '../types/FutureSecurity';
import type { PerpSecurity } from '../types/PerpSecurity';
import type { Security } from '../types/Security';

export const SecuritiesContext = createContext<SecuritiesContextProps | undefined>(undefined);
SecuritiesContext.displayName = 'SecuritiesContext';

export function useSecuritiesContext() {
  const context = useContext(SecuritiesContext);
  if (context === undefined) {
    throw new Error('Missing SecuritiesContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export interface SecuritiesContextProps {
  isLoaded: boolean;
  securitiesBySymbolObs: Observable<Map<string, Security>>;
  securitiesBySymbol: Map<string, Security>;
  securitiesList: Security[];
  /**
   * A sorted list of securities based on their `Rank` value.
   */
  securitiesListSortedByRank: Security[];
  /** The searchable securities is a sorted list of all securities by DisplayName, with any recent selections weighted to be sorted to the top as well.
   * Note that this list has securities with EndTime (indicates it was soft deleted) and all expired securities filtered out.
   * This data set is used for the SymbolSelectors in the app, for example.
   */
  searchableSecurities: Security[];
  perps: PerpSecurity[];
  perpsByCurrencyPair: Map<string, PerpSecurity[]>;
  futures: FutureSecurity[];
  futuresByCurrencyPair: Map<string, PerpSecurity[]>;
  futuresByMarketByCurrency: Map<string, Map<string, Set<FutureSecurity>>>;

  options: OptionSecurity[];
  expirationByMarketByCurrencyIdentity: Map<string, Map<string, Set<string>>>;
  optionSecurityBySymbol: Map<string, OptionSecurity>;
  optionSecurityBySymbolObs: Observable<Map<string, OptionSecurity>>;
  /** Given a compound key of BaseCurrency, UnderlyingCode, and Markets gives a Security which is representative
   * of the basket of Securities with that combination. This is to allow callers to look up properties
   * like SettlementCurrency which will be the same for all strike prices and expirations given the compound key
   */
  representativeOptionSecurityByKey: Map<string, RepresentativeOptionSecurity>;
  strikesByOptionKey: Map<string, string[]>;
  /** A mapping from Currency to all spot trading pairs where the indexed currency is the Base Currency of the trading pair. */
  spotTradingPairsByBaseCurrency: Map<string, Security[]>;
  /** A mapping from Currency to all spot trading pairs where the indexed currency is the Quote Currency of the trading pair. */
  spotTradingPairsByQuoteCurrency: Map<string, Security[]>;
}
