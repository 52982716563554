import {
  ErrorActionEnum,
  formattedDateForSubscription,
  isDateParameterValue,
  isPercentParameterValue,
  type WhitelabelParametersFormState,
  type WLOrderStrategy,
  type WLOrderStrategyParameter,
} from '@talos/kyoko';
import Big from 'big.js';

const removeUnusedParameters = (
  parameters: WhitelabelParametersFormState,
  selectedParameters: WLOrderStrategyParameter[]
) => {
  for (const key in parameters) {
    if (parameters[key] == null || parameters[key] === '' || !selectedParameters?.map(p => p.Name)?.includes(key)) {
      delete parameters[key];
    }
  }
  return parameters;
};

const dividePercentParameters = (
  parameters: WhitelabelParametersFormState,
  selectedParameters: WLOrderStrategyParameter[]
) => {
  for (const key in parameters) {
    const param = selectedParameters?.find(p => p.Name === key);
    const percentValue = parameters[key];
    if (isPercentParameterValue(percentValue, param?.Type)) {
      parameters[key] = Big(percentValue ?? 0)
        .div(100)
        .toFixed();
    }
  }
};

const formatDateParameters = (
  parameters: WhitelabelParametersFormState,
  selectedParameters: WLOrderStrategyParameter[]
) => {
  for (const key in parameters) {
    const param = selectedParameters?.find(p => p.Name === key);
    const dateValue = parameters[key];
    if (isDateParameterValue(dateValue, param?.Type)) {
      parameters[key] = param != null ? formattedDateForSubscription(dateValue) : parameters[key];
    }
  }
};

export const cleanParametersForOrder = (
  currParameters: WhitelabelParametersFormState,
  selectedStrategy: WLOrderStrategy | undefined
): WhitelabelParametersFormState => {
  // Don't modify original parameters obj
  const parameters = { ...currParameters };

  const selectedParameters = selectedStrategy?.Parameters ?? [];

  // Delete Incorrect and Unused Strategy Parameters
  removeUnusedParameters(parameters, selectedParameters);
  dividePercentParameters(parameters, selectedParameters);
  formatDateParameters(parameters, selectedParameters);

  // This will later be removed and set from the WLOrderStrategy subscription
  return { ...parameters, ErrorAction: ErrorActionEnum.Pause };
};
