import { animated } from '@react-spring/web';
import styled from 'styled-components';

export const Container = styled.div``;

export const Bar = styled.div<{ roundLeft: boolean; roundRight: boolean; direction: 'ltr' | 'rtl' }>`
  display: flex;
  justify-content: flex-start;
  flex-direction: ${({ direction }) => (direction === 'ltr' ? 'row' : 'row-reverse')};

  overflow: hidden;
  ${({ theme, roundLeft }) =>
    roundLeft &&
    `
      border-top-left-radius: ${theme.borderRadiusDefault}px;
      border-bottom-left-radius: ${theme.borderRadiusDefault}px;
    `};

  ${({ theme, roundRight }) =>
    roundRight &&
    `
      border-top-right-radius: ${theme.borderRadiusDefault}px;
      border-bottom-right-radius: ${theme.borderRadiusDefault}px;
    `};
`;

export const BarPart = styled(animated.div)`
  height: 18px;
`;

export const SectionContainer = styled.div`
  display: flex;
`;

export const SectionWrapper = styled(animated.div)`
  flex-grow: 1;
`;

export const SectionHeader = styled.div``;

export const Headers = styled.div<{ direction: 'ltr' | 'rtl' }>`
  display: flex;
  flex-direction: ${({ direction }) => (direction === 'ltr' ? 'row' : 'row-reverse')};
  gap: ${({ theme }) => theme.spacingDefault}px;
`;
