import type { ICellRendererParams } from 'ag-grid-community';
import { isPositionWarning } from '../../../types/Position';
import type { AgGridWarningProps } from '../../AgGrid/types';
import { PositionWarnings } from '../../PositionWarnings';
import type { ColDefFactory, Column } from './types';
import { warning } from './warning';
import type { WarningColumnValue } from './warning.types';

export const reconWarning: ColDefFactory<Column> = column => ({
  ...warning(column),
  colId: 'reconWarning',
  cellRendererParams: (params: ICellRendererParams<any, WarningColumnValue | undefined>): AgGridWarningProps => {
    if (isPositionWarning(params.data?.reconWarning)) {
      return {
        ...params,
        tooltip: <PositionWarnings warning={params.data.reconWarning} />,
      };
    }

    // The base case where we just have a severity and no data specific data type
    if (params.value?.severity != null) {
      return params;
    }

    // If there exists no severity on the value, explicitly set value to completely undefined
    return {
      ...params,
      value: undefined,
    };
  },
});
