export type RestResponse<T> = ErrorResponse | DataResponse<T>;

export interface ErrorResponse {
  ts: string;
  type: 'error';
  message?: string;
  error_msg?: string;
}

export interface DataResponse<T> {
  ts: string;
  type: string;
  data: T[];
  next?: string;
  page?: string;
}

export function isErrorResponse<T>(e: RestResponse<T>): e is ErrorResponse {
  return e?.type === 'error';
}

export function getErrorMessage(e: ErrorResponse | undefined): string | undefined {
  return e?.message ?? e?.error_msg;
}
