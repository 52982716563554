import Sugar from 'sugar';
import * as Yup from 'yup';

export const prettyName = (value?: string) => (value ? Sugar.String.titleize(value).replace(/\bId\b/g, 'ID') : '');

export const isValidEmail = (email: string): boolean => {
  const schema = Yup.object().shape({ email: Yup.string().email().required() });
  try {
    schema.validateSync({ email });
  } catch {
    return false;
  }
  return true;
};

export const getKeysOfMissingValues = (parameters: { [key: string]: unknown }): string[] => {
  return Object.entries(parameters)
    .map(([key, val]) => (val != null ? undefined : key))
    .compact();
};

/**
 * Compact multiple spaces in `input` into a single space, and trim whitespace from the ends of the string.
 *
 * @param input Input string
 * @returns Compacted string
 */
export function compactString(input: string): string {
  return input.replaceAll(/[ ]+/g, ' ').trim();
}
