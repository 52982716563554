import Big from 'big.js';
import { memo, useMemo } from 'react';
import { MarketPriceContext, type MarketPrice, type MarketPriceContextProps } from '../contexts';
import { useMultipleStreamsSubscription } from '../hooks';
import { MARKET_DATA_SNAPSHOT } from '../tokens';
import type { MarketDataSnapshot } from '../types/MarketDataSnapshot';
import type { RequestStream } from '../types/RequestStream';
import type { SubscriptionResponse } from '../types/SubscriptionResponse';
import { ConnectionStatusEnum } from '../types/types';

interface MarketPriceSnapshotRequest extends RequestStream {
  name: typeof MARKET_DATA_SNAPSHOT;
  Symbol: string;
  Throttle: string;
  Depth: number;
}

function getMarketPriceStreamRequestForSymbol(symbol: string): MarketPriceSnapshotRequest {
  return {
    name: MARKET_DATA_SNAPSHOT,
    Symbol: symbol,
    Throttle: '500ms',
    // https://talostrading.atlassian.net/browse/DEAL-2884
    Depth: 1,
    tag: `MarketPriceContextProvider`,
  };
}
function getMarketPriceStreamRequestForAllSymbols(
  requestMap: Map<string, MarketPriceSnapshotRequest>
): MarketPriceSnapshotRequest[] {
  return Array.from(requestMap.values());
}

function subscriptionCallback(
  memo: Map<string, MarketPrice | undefined>,
  json: SubscriptionResponse<MarketDataSnapshot, string>
) {
  for (const d of json.data) {
    // For ava, status is not top level available
    // for whitelabel, status is top level
    if (d.Status === ConnectionStatusEnum.Online) {
      const prev = memo.get(d.Symbol);
      const bidPrice = d.Bids.at(0)?.Price;
      const bid = bidPrice != null ? Big(bidPrice) : prev?.bid;

      const offerPrice = d.Offers.at(0)?.Price;
      const offer = offerPrice != null ? Big(offerPrice) : prev?.offer;

      const spread = bid && offer ? offer.minus(bid).div(offer.plus(bid).div(2)) : undefined;
      memo.set(d.Symbol, { bid, offer, spread });
    } else {
      memo.set(d.Symbol, { bid: null, offer: null, spread: null });
    }
  }
  return memo;
}

export const MarketPriceContextProvider = memo(function MarketPriceContextProvider({
  children,
}: React.PropsWithChildren<unknown>) {
  const {
    valueMapObservable: marketPricesBySymbol,
    registerSubscription,
    registerSubscriptions,
    unregisterSubscription,
    unregisterSubscriptions,
  } = useMultipleStreamsSubscription({
    getRequest: getMarketPriceStreamRequestForSymbol,
    getAllRequests: getMarketPriceStreamRequestForAllSymbols,
    subscriptionCallback: subscriptionCallback,
  });

  const value = useMemo<MarketPriceContextProps>(
    () => ({
      marketPricesBySymbol,
      registerSubscription,
      registerSubscriptions,
      unregisterSubscription,
      unregisterSubscriptions,
    }),
    [marketPricesBySymbol, registerSubscription, unregisterSubscription, registerSubscriptions, unregisterSubscriptions]
  );
  return <MarketPriceContext.Provider value={value}>{children}</MarketPriceContext.Provider>;
});
