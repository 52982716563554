import type { ICellRendererParams } from 'ag-grid-community';
import { useMixpanel, useUnderlyingPriceContext } from '../../../contexts';
import { MixpanelEvent } from '../../../tokens';
import { Box } from '../../Core';
import { InlineFormattedNumber } from '../../FormattedNumber';
import { Tooltip } from '../../Tooltip';
import type { AgGridOptionContext, AgGridOptionsData } from './types';

export const AG_GRID_OPTION_STRIKE_PRICE_KEY = 'optionStrike';

export function AgGridOptionStrikePrice(
  params: ICellRendererParams<AgGridOptionsData, string> & {
    context: { current: AgGridOptionContext };
  }
) {
  const { valueFormatted: formattedStrikePrice, value: strikePrice, data, context } = params;
  if (!data) {
    return null;
  }
  const { Expiration } = data;

  const strikePriceOfExpiration = context.current.crossStrikeByExpiry?.get(Expiration);
  const isCrossStrike = strikePriceOfExpiration === strikePrice;

  const strikePriceToShow = formattedStrikePrice ?? strikePrice;
  if (strikePriceToShow == null) {
    return null;
  }

  return isCrossStrike ? (
    <HighlightedStrikePrice strikePrice={strikePriceToShow} expiration={Expiration} isCrossStrike={isCrossStrike} />
  ) : (
    <>{strikePriceToShow}</>
  );
}

function HighlightedStrikePrice({
  strikePrice,
  expiration,
  isCrossStrike,
}: {
  strikePrice: string;
  expiration: string;
  isCrossStrike: boolean;
}) {
  const mixpanel = useMixpanel();
  const { underlyingAmountRecord } = useUnderlyingPriceContext();
  const underlying = underlyingAmountRecord?.[expiration];

  return (
    <Tooltip
      onOpen={() => mixpanel.track(MixpanelEvent.HoverUnderlyingPrice)}
      usePortal
      tooltip={<InlineFormattedNumber number={underlying?.value} currency={underlying?.currency} round />}
    >
      <Box
        py="spacingSmall"
        px="spacingDefault"
        background={isCrossStrike ? 'gray.060' : undefined}
        borderRadius="spacingTiny"
      >
        {strikePrice}
      </Box>
    </Tooltip>
  );
}
