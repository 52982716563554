import styled from 'styled-components';

export const CandleSticksWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
`;

// Intentional pixel precision to overlap the 3rd party chart widget
export const CandleSticksWrapperWithSymbolSelector = styled.div`
  align-items: center;
  display: flex;
  height: 41px;
  justify-content: flex-start;
  right: ${({ theme }) => theme.spacingDefault}px;
  position: absolute;
  z-index: 1;
`;
