import Big from 'big.js';
import type React from 'react';
import { useCallback, useRef } from 'react';
import { useRifm } from 'rifm';

import { DECIMAL } from '../../../tokens/number';
import { format, parseNumber } from '../../../utils/number';

const REGEX = RegExp(`((?=^-)|[^0-9${DECIMAL}])`, 'g');
const POSITIVE_REGEX = RegExp(`[^0-9${DECIMAL}]`, 'g');

const FORMAT_OPTIONS = {
  spec: '0',
  round: false,
  pretty: true,
};

export function useNumberInput({
  value,
  onChange,
  min,
  max,
  minIncrement = '0.01',
  defaultIncrement = '0.1',
  largeIncrement = '1',
  ...props
}: {
  value?: string | null;
  min?: string;
  max?: string;
  minIncrement?: string;
  defaultIncrement?: string;
  largeIncrement?: string;
  onChange: (value: string) => void;
}) {
  const ref = useRef<HTMLInputElement | null>(null);

  const onValueChange = useCallback(
    formattedValue => {
      onChange(formattedValue.replace(REGEX, ''));
    },
    [onChange]
  );

  const internalFormat = useCallback(
    (value: string) => {
      const regex = min != null && Big(min).gte('0') ? POSITIVE_REGEX : REGEX;
      const cleanValue = restrictToOneDecimal(value.replace(regex, ''));
      if (cleanValue === DECIMAL) {
        return DECIMAL;
      } else if (cleanValue === '-') {
        return '-';
      } else if (cleanValue === `-${DECIMAL}`) {
        return `-${DECIMAL}`;
      } else if (value.includes(DECIMAL)) {
        if (value.endsWith(DECIMAL)) {
          const sign = value.startsWith('-') ? '-' : '';
          return restrictToOneDecimal(`${sign}${format(cleanValue, FORMAT_OPTIONS)}${DECIMAL}`);
        }
        const [integers, decimals] = cleanValue.split(DECIMAL);
        let sign = Big(cleanValue).lt(0) && Big(cleanValue).gt(-1) ? '-' : '';
        if (value.startsWith('-0.')) {
          // due to number manipation logic if we don't do this and user type -0.00 (in order to achieve -0.005)
          // the negative sign would be lost since negative zero is considered same as zero, which is positive
          sign = '-';
        }
        return restrictToOneDecimal(
          `${sign}${format(integers, { ...FORMAT_OPTIONS, spec: '0' })}${DECIMAL}${decimals}`
        );
      } else if (cleanValue.startsWith('-')) {
        const sign = Big(cleanValue).lte(0) && Big(cleanValue).gt(-1) ? '-' : '';
        return `${sign}${format(cleanValue, FORMAT_OPTIONS)}`;
      } else if (cleanValue && Big(cleanValue).eq(0)) {
        return cleanValue;
      } else {
        return format(cleanValue, FORMAT_OPTIONS);
      }
    },
    [min]
  );

  const rifm = useRifm({
    value: value ?? '',
    onChange: onValueChange,
    accept: /[\d.]+/g,
    format: internalFormat,
    replace: (value: string) => {
      if (value === DECIMAL) {
        setTimeout(() => ref.current?.setSelectionRange(2, 2), 0);
        return `0${DECIMAL}`;
      } else if (value === '-') {
        // We seem to catch the replace action one render cycle behind sometimes
        // Causing input "-" and then '12345' very fast to have weird cursor selection
        // -123|45
        // If we set the selectionRange to something comically large, it'd ensure the cursor is at the end.
        setTimeout(() => ref.current?.setSelectionRange(100, 100), 0);
        return '-';
      } else if (value.startsWith(`-${DECIMAL}`)) {
        const parts = value.split(DECIMAL);
        const decimals = parts[1];
        const newValue = `-0${DECIMAL}${decimals}`;
        setTimeout(() => ref.current?.setSelectionRange(newValue.length, newValue.length), 0);
        return newValue;
      }

      return clampValue(value, min, max);
    },
  });

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const { target, key, altKey, shiftKey } = e;
      const input = target as HTMLInputElement;
      if (key === 'ArrowDown' || key === 'ArrowUp') {
        e.preventDefault();
        const value = Big(parseNumber(input.value) || '0');
        const increment = shiftKey ? largeIncrement : altKey ? minIncrement : defaultIncrement;
        const newValue = key === 'ArrowDown' ? value.sub(increment) : value.add(increment);
        // If min is defined, and new value is less than min, set value to min.
        if (min !== undefined && newValue.lt(min)) {
          onValueChange(format(min, FORMAT_OPTIONS));
          return;
        }
        onValueChange(format(clampValue(newValue.toFixed(), min, max), FORMAT_OPTIONS));
      }
    },
    [onValueChange, minIncrement, defaultIncrement, largeIncrement, min, max]
  );
  return { onKeyDown, ref, step: defaultIncrement, ...rifm, ...props };
}

function clampValue(value: string, min?: string, max?: string) {
  const regex = min != null && Big(min).gte('0') ? POSITIVE_REGEX : REGEX;
  const cleanValue = restrictToOneDecimal(value.replace(regex, ''));
  if (value != null && value !== '') {
    if (min != null && min !== '' && Big(cleanValue).lt(min)) {
      return format(min, FORMAT_OPTIONS);
    }
    if (max != null && max !== '' && Big(cleanValue).gt(max)) {
      return format(max, FORMAT_OPTIONS);
    }
  }
  return value;
}

function restrictToOneDecimal(value: string): string {
  if (value.includes(DECIMAL)) {
    const [integers, decimals] = value.split(DECIMAL, 2);
    // Decimals might include additional decimals, lets remove them.
    const cleanDecimals = decimals.replace(DECIMAL, '');
    return `${integers}${DECIMAL}${cleanDecimals}`;
  }
  return value;
}
