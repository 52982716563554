import {
  WatchList as BaseWatchList,
  MixpanelEvent,
  MixpanelEventSource,
  SideEnum,
  primeOrderForm,
  useDynamicCallback,
  useFavoriteSecurities,
  useMixpanel,
  useWLOrderFormDispatch,
  useWLOrgConfigContext,
  useWLSymbol,
  useWatchlistSettings,
  type WatchListColumnTypes,
  type WatchListRowNode,
} from '@talos/kyoko';
import { toBigWithDefault } from '@talos/kyoko/src/utils';
import type { CellClickedEvent } from 'ag-grid-community';
import { useMemo } from 'react';

export function WatchList() {
  const mixpanel = useMixpanel(MixpanelEventSource.Watchlist);
  const { config } = useWLOrgConfigContext();
  const { setSymbol } = useWLSymbol();

  const orderFormDispatch = useWLOrderFormDispatch();

  const onCellClicked = useDynamicCallback((event: CellClickedEvent) => {
    mixpanel.track(MixpanelEvent.ChangeSymbol);
    const node = event.node as WatchListRowNode;
    const colId = event.column.getColId();
    const security = node.data.security;

    if (['bid', 'offer'].includes(colId)) {
      const value = event.value;
      const { DefaultPriceIncrement } = security;
      const [, precision] = DefaultPriceIncrement.split('.');

      orderFormDispatch(
        primeOrderForm({
          Side: colId === 'bid' ? SideEnum.Sell : SideEnum.Buy,
          Price: value != null ? toBigWithDefault(value, 0).toFixed(precision.length) : undefined,
          Symbol: security.Symbol,
        })
      );
    } else {
      setSymbol(security.Symbol);
    }
  });

  const columns = useMemo(() => {
    return COLUMNS.filter(c => config.showSpreadOnVolumeLadder || c !== 'spread').map(c => ({
      type: c,
      onClick: CLICKABLE_COLUMNS.includes(c) ? onCellClicked : undefined,
    }));
  }, [config.showSpreadOnVolumeLadder, onCellClicked]);

  const { favoriteSecurities, setFavoriteSecurities } = useFavoriteSecurities();

  const watchlistSettings = useWatchlistSettings();

  return (
    <BaseWatchList
      enabledColumns={columns}
      selectableCurrencies={config.watchListFilterCurrencies ?? DEFAULT_CURRENCIES}
      favoriteSecurities={favoriteSecurities}
      setFavoriteSecurities={setFavoriteSecurities}
      {...watchlistSettings}
    />
  );
}

export const DEFAULT_CURRENCIES = ['BTC', 'ETH', 'USD', 'USDT', 'EUR'];

const COLUMNS: WatchListColumnTypes[] = [
  'rank',
  'favorite',
  'symbol',
  'bid',
  'spread',
  'offer',
  // 'sparkline',
  'reorder',
  'spacer',
];
const CLICKABLE_COLUMNS: WatchListColumnTypes[] = ['symbol', 'bid', 'offer', 'sparkline'];
