import { hsl } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';

import { setAlpha } from '../utils';
import { base } from './BaseTheme';
import { darkColors, mapDarkTheme } from './DarkTheme';

export const colors: Colors = {
  black: {
    default: '#000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    main: hsl(220, 0.25, 0.11),

    '000': hsl(220, 0.25, 0.03),
    '010': hsl(220, 0.25, 0.08),
    '020': hsl(220, 0.25, 0.15),
    '030': hsl(220, 0.25, 0.17),
    '040': hsl(220, 0.25, 0.2),
    '050': hsl(220, 0.25, 0.23),
    '060': hsl(220, 0.25, 0.27),
    '070': hsl(220, 0.25, 0.34),
    '080': hsl(220, 0.25, 0.51),
    '090': hsl(220, 0.25, 0.65),
    '100': hsl(220, 0.25, 0.9),
  },

  primary: {
    mute: 'rgba(69, 140, 193, 0.1)',
    dim: 'rgba(69, 140, 193, 0.4)',
    default: '#458CC1',
    lighten: '#53AFF8',
  },

  green: {
    mute: setAlpha(0.1, '#337e41'),
    dim: setAlpha(0.4, '#337e41'),
    default: '#337e53',
    lighten: '#49ab73',
  },

  red: {
    mute: setAlpha(0.1, '#9e2525'),
    dim: setAlpha(0.1, '#9e2525'),
    default: '#9e2525',
    lighten: '#b81f1f',
  },

  yellow: {
    mute: 'rgba(204, 172, 62, 0.1)',
    dim: 'rgba(204, 172, 62, 0.4)',
    default: '#CCAC3E',
    lighten: '#F2D264',
  },

  blue: {
    mute: 'rgba(69, 140, 193, 0.1)',
    dim: 'rgba(69, 140, 193, 0.4)',
    default: '#458CC1',
    lighten: '#53AFF8',
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: darkColors.purple,
};

const baseSize = base.baseSize;

export const DarkTransparencyTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkTransparency',

  fontUrl: 'https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,500;1,400;1,500&display=swap',
  fontFamily: "'Nunito', sans-serif",

  spacingLayout: baseSize * 2,
  borderRadiusContent: baseSize,
  backgroundContent: setAlpha(0.5, colors.gray.main),
  backgroundHeader: setAlpha(0.5, colors.gray.main),
  backdropFilterContent: 'blur(5px)',
  borderColorContent: colors.gray.main,
  borderColorHeader: colors.gray.main,
  backdropFilterHeader: 'blur(5px)',

  backgroundDrawer: setAlpha(0.5, colors.gray.main),
  backdropFilterDrawer: 'blur(10px)',

  borderRadiusMedium: baseSize * 0.5,
  borderRadiusDefault: baseSize * 0.5,
  borderRadiusSmall: baseSize * 0.25,
  borderRadiusTiny: baseSize * 0.5,

  borderRadiusButtonLarge: baseSize * 0.5,
  borderRadiusButtonDefault: baseSize * 0.5,
  borderRadiusButtonSmall: baseSize * 0.25,
  borderRadiusButtonTiny: baseSize * 0.5,

  backgroundBody: `url(/customers/TransparencyDark.svg) 100% 100%, linear-gradient(${colors.gray['010']}, ${colors.gray['000']})`,
  boxShadowContent: `0 1px 10px ${setAlpha(0.5, colors.gray['000'])}`,
  backgroundContentHover: setAlpha(0.05, colors.white.default),
  backgroundPopover: colors.gray.main,

  backgroundVolumeLadderBidSpread: setAlpha(0.75, colors.blue.default),
  backgroundVolumeLadderOfferSpread: setAlpha(0.75, colors.blue.default),

  backgroundChart: 'rgba(0, 0, 0, 0)',

  borderColorDivider: colors.gray.main,

  backgroundInput: colors.gray['010'],
  backgroundSelect: colors.gray['010'],

  borderColorInputFocus: colors.primary.lighten,

  backgroundDefaultButton: `linear-gradient(${setAlpha(0.75, colors.gray['050'])}, ${setAlpha(
    0.5,
    colors.gray['050']
  )})`,
  backgroundDefaultButtonHover: `linear-gradient(${setAlpha(0.85, colors.gray['050'])}, ${setAlpha(
    0.6,
    colors.gray['050']
  )})`,
  backgroundDefaultButtonFocus: `linear-gradient(${setAlpha(0.85, colors.gray['050'])}, ${setAlpha(
    0.6,
    colors.gray['050']
  )})`,
  backgroundDefaultButtonActive: `linear-gradient(${setAlpha(0.65, colors.gray['050'])}, ${setAlpha(
    0.4,
    colors.gray['050']
  )})`,

  borderColorDefaultButtonFocus: colors.gray['050'],

  backgroundPositiveButton: `linear-gradient(${setAlpha(0.75, colors.green.lighten)}, ${setAlpha(
    0.5,
    colors.green.lighten
  )})`,
  backgroundPositiveButtonHover: `linear-gradient(${setAlpha(0.85, colors.green.lighten)}, ${setAlpha(
    0.6,
    colors.green.lighten
  )})`,
  backgroundPositiveButtonFocus: `linear-gradient(${setAlpha(0.85, colors.green.lighten)}, ${setAlpha(
    0.6,
    colors.green.lighten
  )})`,
  backgroundPositiveButtonActive: `linear-gradient(${setAlpha(0.65, colors.green.lighten)}, ${setAlpha(
    0.4,
    colors.green.lighten
  )})`,

  borderColorPositiveButtonFocus: colors.green.lighten,

  backgroundNegativeButton: `linear-gradient(${setAlpha(0.75, colors.red.lighten)}, ${setAlpha(
    0.5,
    colors.red.lighten
  )})`,
  backgroundNegativeButtonHover: `linear-gradient(${setAlpha(0.85, colors.red.lighten)}, ${setAlpha(
    0.6,
    colors.red.lighten
  )})`,
  backgroundNegativeButtonFocus: `linear-gradient(${setAlpha(0.85, colors.red.lighten)}, ${setAlpha(
    0.6,
    colors.red.lighten
  )})`,
  backgroundNegativeButtonActive: `linear-gradient(${setAlpha(0.65, colors.red.lighten)}, ${setAlpha(
    0.4,
    colors.red.lighten
  )})`,

  borderColorNegativeButtonFocus: colors.red.lighten,

  backgroundPrimaryButton: `linear-gradient(${setAlpha(0.75, colors.blue.lighten)}, ${setAlpha(
    0.5,
    colors.blue.lighten
  )})`,
  backgroundPrimaryButtonHover: `linear-gradient(${setAlpha(0.85, colors.blue.lighten)}, ${setAlpha(
    0.6,
    colors.blue.lighten
  )})`,
  backgroundPrimaryButtonFocus: `linear-gradient(${setAlpha(0.85, colors.blue.lighten)}, ${setAlpha(
    0.6,
    colors.blue.lighten
  )})`,
  backgroundPrimaryButtonActive: `linear-gradient(${setAlpha(0.65, colors.blue.lighten)}, ${setAlpha(
    0.4,
    colors.blue.lighten
  )})`,

  borderColorPrimaryButtonFocus: colors.blue.lighten,

  colorModeCurrencyIcon: 'colorful',
});
