import type { IHeaderParams } from 'ag-grid-enterprise';
import { forwardRef } from 'react';

export const VolumeHeader = forwardRef(function VolumeHeader(params: IHeaderParams & { volumeWindow: string }, ref) {
  return (
    <div className="ag-cell-label-container" role="presentation">
      <div className="ag-header-cell-label" role="presentation">
        <span>
          {params.volumeWindow}
          &nbsp;
          {params.displayName}
        </span>
      </div>
    </div>
  );
});
