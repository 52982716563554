import type { ITooltipParams } from 'ag-grid-community';
import type { ReactNode } from 'react';
import styled, { css, useTheme } from 'styled-components';

// Common styling used for ag-grid tooltips
// - TODO: The !important's (from prior impl's) appear to enforce style overrides from ag-grid theme
export const AgTooltipSimpleStyle = css`
  background: ${({ theme }) => theme.backgroundPopover} !important;
  padding: ${({ theme }) => theme.spacingDefault}px !important;
  margin-left: ${({ theme }) => theme.spacingDefault}px !important;
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px !important;
`;

const AgTooltipSimple = styled.div`
  max-width: 20rem;
  ${AgTooltipSimpleStyle}
`;

/** Fallback Ag Grid Header component for use inside custom tooltip components to fall back to to show the colDef tooltip (if defined)
 * - the colDef tooltipComponent param defines a component that applies to the entire column INCLUDING the header, so once it's set
 * we need to handle the header ourselves, and this is a decent fallback
 * */
export const AgGridDefaultHeaderTooltip = ({ params }: { params: ITooltipParams }): ReactNode => {
  const theme = useTheme();
  const fallbackTooltipValue = params.colDef?.headerTooltip;
  return fallbackTooltipValue ? <AgTooltipSimple theme={theme}>{fallbackTooltipValue}</AgTooltipSimple> : null;
};
