import { hashTheme } from './hashTheme';

/**
 * A wrapper around `hashTheme` that is used at runtime.
 * This function is used to allow theme overriding in local development, since
 * the themes are not hashed in local development.
 */
export function hashThemeRunTime(theme: string): string {
  if (import.meta.env.VITE_AVA_ENV === 'local') {
    return theme;
  }

  return hashTheme(theme);
}
