import type { MouseEventHandler } from 'react';
import styled from 'styled-components';

import { Box, type BoxProps } from '../Core';
import { Icon, IconName } from '../Icons';

import { IndicatorDot, IndicatorDotVariants } from '../IndicatorDot';
import { NotificationWrapper } from './styles';
import { NotificationSizes, NotificationVariants } from './types';

export { NotificationSizes, NotificationVariants } from './types';

export interface NotificationProps extends BoxProps {
  dismissable?: boolean;
  variant?: NotificationVariants;
  onRemove?: MouseEventHandler<HTMLDivElement>;
  size?: NotificationSizes;
}

export const Notification = styled(
  ({
    children,
    variant,
    dismissable,
    size = NotificationSizes.Default,
    onRemove = () => {},
    ...props
  }: NotificationProps) => {
    return (
      <NotificationWrapper size={size} data-testid="notification" {...props}>
        <IndicatorDot halo variant={fromNotificationVariantToIndicatorDotVariant(variant)} />
        <Box flex="1">{children}</Box>
        {dismissable && (
          <Icon
            onClick={onRemove}
            icon={IconName.Close}
            color="colors.gray['070']"
            size="fontSizeLarge"
            spaceBefore="auto"
          />
        )}
      </NotificationWrapper>
    );
  }
)``;

const fromNotificationVariantToIndicatorDotVariant = (
  notification: NotificationVariants = NotificationVariants.Default
): IndicatorDotVariants => {
  switch (notification) {
    case NotificationVariants.Negative:
      return IndicatorDotVariants.Negative;
    case NotificationVariants.Positive:
      return IndicatorDotVariants.Positive;
    case NotificationVariants.Warning:
      return IndicatorDotVariants.Warning;
    case NotificationVariants.Primary:
      return IndicatorDotVariants.Primary;
    case NotificationVariants.Attention:
      return IndicatorDotVariants.Notification;
    case NotificationVariants.Default:
    default:
      return IndicatorDotVariants.Default;
  }
};
