import {
  useCallback,
  useEffect,
  useRef,
  useState,
  type FocusEvent,
  type KeyboardEvent,
  type MouseEvent,
  type RefObject,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Box } from '../../../Core';
import { AutocompleteDropdown, FuseAutocompleteResult } from '../../../Form/AutocompleteDropdown';
import { useDropdownPopper } from '../../../Form/Dropdown';
import { Input } from '../../../Form/Input';
import { useSearchSelect } from '../../../Form/SearchSelect';
import { DEFAULT_FILTER_BUILDER_DROPDOWN_WIDTH } from '../tokens';
import type { FilterableSelectProperty, PropertyRefs, UseFilterBuilderRefsOutput } from '../types';
import { DropdownResultsHeader } from './DropdownResultsHeader';
import { SelectionListButtons } from './SelectionListButtons';

const messages = defineMessages({
  search: {
    defaultMessage: 'Search...',
    id: 'Filters.FilterBuilder.RHS.search',
  },
});

type PropertySingleSelectListProps = {
  property: FilterableSelectProperty;
  selections: string[];
  onSelectionsChange: (newSelections: string[]) => void;
  onDropdownTabOut?: (event: KeyboardEvent<HTMLElement>) => void;
  onRemove: () => void;
  refs: PropertyRefs;
} & Pick<UseFilterBuilderRefsOutput, 'updateSelectionRefs'>;

export const PropertySingleSelectList = ({
  property,
  selections,
  onSelectionsChange,
  onRemove,
  refs,
  updateSelectionRefs,
  onDropdownTabOut,
}: PropertySingleSelectListProps) => {
  const { getOptionLabel } = property;
  const { formatMessage } = useIntl();

  // this reference element will be switched between the currently selected button. Its the dropdown's anchor point
  const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(refs.empty.current);

  // this useeffect keeps the reference element set correctly (as in, the anchor point for the dropdown)
  useEffect(() => {
    if (refs.selections.size === 0) {
      setReferenceElement(refs.empty.current);
    } else if (refs.selections.size === 1) {
      // this is a single select, so there will only be one element here
      const onlyRef = [...refs.selections.values()][0]!;
      setReferenceElement(onlyRef.current);
    }
  }, [refs, referenceElement]);

  useEffect(() => {
    updateSelectionRefs(property.key, selections);
  }, [selections, updateSelectionRefs, property.key]);

  const handleSelectionChange = useCallback(
    (newSelection: string | undefined) => {
      const newSelections = newSelection ? [newSelection] : [];
      onSelectionsChange(newSelections);
    },
    [onSelectionsChange]
  );

  const inputRef = useRef<HTMLInputElement>(null);
  const searchSelect = useSearchSelect({
    selection: selections.length > 0 ? selections[0] : undefined,
    items: property.options,
    inputRef,
    onChange: handleSelectionChange,
    getLabel: getOptionLabel,
    getDescription: property.getOptionDescription,
    getGroup: property.getOptionGroup,
    initialSortByLabel: false,
    initialIsOpen: selections.length === 0, // start open if we're brand new
    closeDropdownOnItemSelection: false,
    clearInputOnSelection: true,
    enableTabSelect: false,
    matchThreshold: property.matchThreshold,
  });
  const { getInputProps, isOpen, openMenu, closeMenu } = searchSelect;

  const dropdownPopper = useDropdownPopper({
    isOpen,
    referenceElement,
    dropdownWidth: property?.dropdownOptions?.dropdownWidth ?? DEFAULT_FILTER_BUILDER_DROPDOWN_WIDTH,
    dropdownPlacement: 'bottom-start',
  });

  const updatePopperPosition = dropdownPopper.popper.update;

  useEffect(() => {
    if (selections) {
      // every time the clauses change, we update the popper position
      updatePopperPosition && updatePopperPosition();
    }
  }, [selections, updatePopperPosition]);

  const handleOpenClick = useCallback(
    (e: MouseEvent<HTMLButtonElement> | FocusEvent<HTMLButtonElement>, ref: RefObject<HTMLButtonElement>) => {
      e.preventDefault();
      setReferenceElement(ref.current);
      updatePopperPosition && updatePopperPosition();
      openMenu();
      setTimeout(() => inputRef.current?.focus(), 0);
    },
    [openMenu, updatePopperPosition, setReferenceElement]
  );

  const handleRemoveSelectionClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      onSelectionsChange([]);
      e.stopPropagation();
    },
    [onSelectionsChange]
  );

  const handleDropdownTabOut = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      if (onDropdownTabOut) {
        event.stopPropagation();
        event.preventDefault();
        closeMenu();
        onDropdownTabOut && onDropdownTabOut(event);
      }
    },
    [closeMenu, onDropdownTabOut]
  );

  return (
    <Box position="relative">
      <div>
        <SelectionListButtons
          visibleSelections={selections}
          refs={refs}
          onOpenClick={handleOpenClick}
          onRemovePropertyClick={onRemove}
          onRemoveSelectionClick={handleRemoveSelectionClick}
          getOptionLabel={getOptionLabel}
        />
      </div>
      <AutocompleteDropdown
        {...searchSelect}
        {...dropdownPopper}
        data-testid="selection-dropdown"
        onTabOut={handleDropdownTabOut}
        renderResult={(item, disabled) => FuseAutocompleteResult(item, disabled)}
        maxHeight={property.dropdownOptions?.maxHeight}
        groupMaxHeight={property.dropdownOptions?.groupMaxHeight}
        childrenAboveResults={
          <>
            <Input {...getInputProps({ ref: inputRef })} placeholder={formatMessage(messages.search)} />
            <DropdownResultsHeader property={property} showClearAll={false} />
          </>
        }
      />
    </Box>
  );
};
