import type { IAggFunc, IAggFuncParams } from 'ag-grid-community';
import Big from 'big.js';

export function sumAggFunc({ values }: IAggFuncParams): ReturnType<IAggFunc> {
  let sum: Big | undefined = undefined; // start it as undefined
  for (const value of values) {
    try {
      if (value == null) {
        continue;
      }

      const bigValue = Big(value);
      if (sum === undefined) {
        sum = bigValue;
      } else {
        sum = sum.add(bigValue);
      }
    } catch (e) {
      return '';
    }
  }

  return sum?.toFixed() ?? '';
}
