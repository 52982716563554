import styled from 'styled-components';
import { StatusWrapper } from '../../components/StatusIndicator';
import { maxWidth, MEDIA } from '../../styles';

const GUIDE_MAX_WIDTH = 1100;

export const Indexes = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin: 0 auto ${({ theme }) => theme.spacingLarge}px;

  > div {
    display: flex;
    align-items: center;
  }
`;

export const Index = styled.button`
  width: 16px;
  height: 16px;
  border: ${({ theme }) => `1px solid ${theme.colors.gray['060']}`};
  border-radius: 50%;
  margin: ${({ theme }) => theme.spacingSmall}px;
  padding: 0;
  background: transparent;
  position: relative;
  outline: none;
  cursor: pointer;

  i {
    line-height: 1;
  }

  &:focus {
    box-shadow: 0 0 0px 2px ${({ theme }) => theme.colors.gray['070']};
  }
`;

export const GuideWrapper = styled.div`
  flex: 1 1 0;
  overflow: hidden;
  padding: 0 ${({ theme }) => theme.spacingLarge}px 0 0;
`;

export const Guide = styled.div`
  display: flex;
  scroll-snap-type: x mandatory;
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    height: 0;
  }
`;

export const GuideStep = styled.div`
  min-height: 400px;
  scroll-snap-align: start;
  display: flex;
  gap: ${({ theme }) => theme.spacingLarge * 2}px;
  width: 100%;
  flex: 1 0 auto;
`;

export const GuideContent = styled.div`
  margin: 0 auto;
  max-width: ${GUIDE_MAX_WIDTH}px;
  gap: ${({ theme }) => theme.spacingLarge}px;
`;

export const GuideText = styled.div`
  max-width: ${GUIDE_MAX_WIDTH}px;

  h2 {
    font-size: ${({ theme }) => theme.fontSizeHuge}rem;
  }

  p {
    font-size: ${({ theme }) => theme.fontSizeLarge}rem;
    margin-top: 0;
  }

  ${maxWidth(MEDIA.medium)} {
    h2 {
      font-size: ${({ theme }) => theme.fontSizeLarge}rem;
    }

    p {
      font-size: ${({ theme }) => theme.fontSizeDefault}rem;
      margin-top: 0;
    }
  }

  ${maxWidth(MEDIA.default)} {
    width: 100%;
  }
`;

export const GuideImage = styled.div`
  img {
    object-fit: contain;
    width: 100%;
    border: ${({ theme }) => `1px solid ${theme.colors.gray['060']}`};
    min-height: 100%;
    /* max-height: 400px; */
    max-width: ${GUIDE_MAX_WIDTH}px;
  }

  ${maxWidth(MEDIA.default)} {
    display: none;
  }
`;

export const FormWrapper = styled.form`
  font-size: ${({ theme }) => theme.fontSizeLarge}rem;

  h2 {
    font-size: ${({ theme }) => theme.fontSizeHuge}rem;
    margin-top: 0;
  }

  flex: 1 0 200px;

  ${StatusWrapper} {
    margin-top: ${({ theme }) => theme.spacingSmall}px;
    margin-bottom: ${({ theme }) => theme.spacingLarge}px;
  }

  ${maxWidth(MEDIA.medium)} {
    font-size: ${({ theme }) => theme.fontSizeDefault}rem;

    h2 {
      font-size: ${({ theme }) => theme.fontSizeLarge}rem;
    }
  }
`;

export const FormTitle = styled.div`
  display: flex;

  h2 {
    margin: 0 auto ${({ theme }) => theme.spacingMedium}px 0;
  }
`;
