import { useMemo } from 'react';
import { defineMessages } from 'react-intl';

import { useCurrency } from '../../../hooks/useCurrency';
import { OrderFormSides } from '../../../types/OrderFormSides';
import type { Security } from '../../../types/Security';
import { InlineFormattedNumber } from '../../FormattedNumber/InlineFormattedNumber';
import { FormattedMessage } from '../../Intl';
import { type MaxOrderAmountProps, useMaxOrderQuantity } from './hooks';
import { BalanceLabel, MaxAvailableWrapper } from './styles';

const messages = defineMessages({
  maxAvailable: {
    defaultMessage: 'Max Available',
    id: 'Forms.FormBalances.maxAvailable',
  },
});

export interface MaxAvailableProps {
  maxBalance?: string;
  formCurrency?: string;
  security: Security;
  side?: OrderFormSides;
  marketAccountID?: string;
  limitPrice?: string;
}

export const MaxAvailable = ({
  maxBalance: estimatedMaxBalance,
  formCurrency,
  security,
  side,
  marketAccountID,
  limitPrice,
}: MaxAvailableProps) => {
  // Prepare parameters for useMaxOrderQuantity hook
  const maxAvailableProps = useMemo(() => {
    return {
      MarketAccount: marketAccountID,
      Symbol: security?.Symbol,
      Currency: formCurrency,
      Side: side,
      Price: limitPrice,
    } satisfies MaxOrderAmountProps;
  }, [formCurrency, limitPrice, marketAccountID, security?.Symbol, side]);

  // Fetch max order amount from the API
  const { maxOrderAmountResponse, loaded } = useMaxOrderQuantity(maxAvailableProps);

  const { maxAvailable, effectiveCurrency, estimate } = useMemo(() => {
    if (maxOrderAmountResponse?.MaxOrderAmount) {
      return {
        maxAvailable: maxOrderAmountResponse?.MaxOrderAmount,
        effectiveCurrency: maxOrderAmountResponse?.Currency,
      };
    } else if (estimatedMaxBalance != null && loaded) {
      // Fall back to estimated max balance
      return { maxAvailable: estimatedMaxBalance, effectiveCurrency: formCurrency, estimate: true };
    } else {
      return { maxAvailable: undefined, effectiveCurrency: formCurrency };
    }
  }, [
    estimatedMaxBalance,
    formCurrency,
    loaded,
    maxOrderAmountResponse?.Currency,
    maxOrderAmountResponse?.MaxOrderAmount,
  ]);

  const currencyInfo = useCurrency(effectiveCurrency);

  if (side === OrderFormSides.Twoway || !maxAvailable) {
    return null;
  }

  return (
    <MaxAvailableWrapper data-testid="form-balances-max-available">
      <BalanceLabel>
        <FormattedMessage {...messages.maxAvailable} />
      </BalanceLabel>
      {formCurrency != null ? (
        <InlineFormattedNumber
          number={maxAvailable}
          increment={currencyInfo?.DefaultIncrement}
          currency={effectiveCurrency}
          estimate={estimate}
          align="right"
        />
      ) : (
        ''
      )}
    </MaxAvailableWrapper>
  );
};
