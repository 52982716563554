import Big from 'big.js';
import { useEffect, useMemo, useState } from 'react';
import { of, type Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { useCurrenciesContext } from '../contexts/CurrenciesContext';
import { useRecentSymbols } from '../contexts/RecentSymbolsContext';
import { SecuritiesContext } from '../contexts/SecuritiesContext';
import { useCachedSubscription } from '../hooks/useCachedSubscription';
import { useObservable, useObservableValue } from '../hooks/useObservable';
import { wsScanToMap } from '../pipes/wsScanToMap';
import { SECURITY } from '../tokens/requestTypes';
import { isOptionSecurity, type OptionSecurity, type RepresentativeOptionSecurity } from '../types';
import { isFutureSecurity, type FutureSecurity } from '../types/FutureSecurity';
import { isPerpSecurity, type PerpSecurity } from '../types/PerpSecurity';
import type { Security } from '../types/Security';
import { isExpired, isSpot } from '../utils';
import { EMPTY_ARRAY } from '../utils/empty';

export const getCurrencyPairFromSecurity = (security: Security) => `${security.BaseCurrency}-${security.QuoteCurrency}`;

const SECURITY_REQUEST = { name: SECURITY, tag: 'SecuritiesProvider' } as const;

export const SecuritiesProvider = function SecuritiesProvider({ children }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const { data: subscription, isLoading } = useCachedSubscription<Security>(SECURITY_REQUEST);

  // Please discuss with wider team if the below newMapEachUpdate: true needs changing to false
  const securitiesBySymbolObs = useObservable(
    () =>
      subscription.pipe(
        wsScanToMap({ getUniqueKey: d => d.Symbol, newMapEachUpdate: true }),
        shareReplay({ refCount: false, bufferSize: 1 })
      ),
    [subscription]
  );

  const securitiesBySymbol = useObservableValue(() => securitiesBySymbolObs, [securitiesBySymbolObs], new Map());

  const securitiesList = useObservableValue(
    () => securitiesBySymbolObs.pipe(map(securitiesBySymbol => [...securitiesBySymbol.values()])),
    [securitiesBySymbolObs],
    []
  );

  const { recentSymbolsList } = useRecentSymbols();
  const { currenciesBySymbol } = useCurrenciesContext();
  const searchableSecuritiesObs: Observable<Security[]> = useObservable(
    () =>
      securitiesBySymbolObs.pipe(
        map(securitiesMap => [...securitiesMap.values()]),
        map(securitiesList => {
          // Grab the top 5 most recent symbols, reverse them, then put into a map of symbol -> index (importance)
          const recentSymbolsIndexMap = new Map<string, number>(
            recentSymbolsList
              .slice(0, 5)
              .reverse()
              .map((symbol, i) => [symbol, i])
          );
          return securitiesList
            .filter(sec => {
              // filter out expired securities
              if (isExpired(sec)) {
                return false;
              }
              // filter out securities with EndTime (indicates it was soft deleted)
              if (sec?.EndTime && new Date(sec?.EndTime) < new Date()) {
                return false;
              }
              return true;
            })
            .map(({ Symbol, BaseCurrency, QuoteCurrency, Description, ...s }) => ({
              ...s,
              Symbol,
              BaseCurrency,
              QuoteCurrency,
              searchSymbol: Symbol.replace('-', ''),
              Description:
                Description ??
                (currenciesBySymbol != null
                  ? `${currenciesBySymbol.get(BaseCurrency)?.Description} - ${
                      currenciesBySymbol.get(QuoteCurrency)?.Description
                    }`
                  : ''),
            }))
            .sort((a, b) => {
              const aIndex = recentSymbolsIndexMap.get(a.DisplaySymbol) ?? -1;
              const aRecencyWeighting = 1 / (aIndex + 2);
              const bIndex = recentSymbolsIndexMap.get(b.DisplaySymbol) ?? -1;
              const bRecencyWeighting = 1 / (bIndex + 2);

              if (aRecencyWeighting === bRecencyWeighting) {
                // Neither of the two securities have a recency weighting, so just go off DisplayName
                return (a.DisplaySymbol ?? a.Symbol).localeCompare(b.DisplaySymbol ?? b.Symbol);
              } else {
                return aRecencyWeighting - bRecencyWeighting;
              }
            });
        })
      ),
    [securitiesBySymbolObs, currenciesBySymbol, recentSymbolsList]
  );

  const searchableSecurities = useObservableValue(() => searchableSecuritiesObs, [searchableSecuritiesObs]);

  useEffect(() => {
    if (!isLoaded) {
      const hasLoadedNow = [securitiesList, securitiesBySymbol, searchableSecurities].every(dataset => dataset != null);
      if (hasLoadedNow) {
        setIsLoaded(hasLoadedNow);
      }
    }
  }, [securitiesList, securitiesBySymbol, searchableSecurities, isLoaded, isLoading]);

  const securitiesListSortedByRank = useMemo(
    () => securitiesList?.sort((a, b) => ((a.Rank ?? Infinity) < (b.Rank ?? Infinity) ? -1 : 1)) ?? [],
    [securitiesList]
  );

  const perps = useMemo(() => getPerpsFromSecurities(securitiesList), [securitiesList]);
  const perpsByCurrencyPair = useMemo(() => getPerpsByCurrencyPair(perps), [perps]);

  const futures = useMemo(() => getFuturesFromSecurities(securitiesList), [securitiesList]);
  const futuresByCurrencyPair = useMemo(() => getFuturesByCurrencyPair(futures), [futures]);

  const futuresByMarketByCurrency = useMemo(() => getFuturesByMarketByCurrencyPair(futures), [futures]);

  const options = useMemo(() => {
    const now = new Date();
    return securitiesList
      .filter<OptionSecurity>((sec): sec is OptionSecurity => {
        // if not option, filter it out
        if (!isOptionSecurity(sec)) {
          return false;
        }
        // filter out securities with EndTime (indicates it was soft deleted)
        if (sec?.EndTime && new Date(sec?.EndTime) < now) {
          return false;
        }
        // If expired filter it out
        if (new Date(sec.Expiration) < now) {
          return false;
        }

        return true;
      })
      .sort((a, b) => Big(a.StrikePrice).cmp(b.StrikePrice));
  }, [securitiesList]);

  const expirationByMarketByCurrencyIdentity = useMemo(
    () => getexpirationByMarketByCurrencyIdentity(options),
    [options]
  );

  const optionSecurityBySymbol = useMemo(() => new Map(options.map(o => [o.Symbol, o])), [options]);
  const optionSecurityBySymbolObs = useObservable(() => of(optionSecurityBySymbol), [optionSecurityBySymbol]);
  const representativeOptionSecurityByKey: Map<string, RepresentativeOptionSecurity> = useMemo(
    () =>
      new Map(
        options.map(o => [
          getRepresentativeOptionSecurityKey(o.BaseCurrency, o.Markets, o.UnderlyingCode),
          {
            SettlementCurrency: o.SettlementCurrency,
          },
        ])
      ),
    [options]
  );

  const strikesByOptionKey = useMemo(() => getStrikesByOptionKey(options), [options]);

  const { baseMap: spotTradingPairsByBaseCurrency, quoteMap: spotTradingPairsByQuoteCurrency } = useMemo(
    () => getSpotTradingPairsByCurrency(securitiesList),
    [securitiesList]
  );

  return (
    <SecuritiesContext.Provider
      value={{
        isLoaded,
        securitiesBySymbol: securitiesBySymbol!,
        securitiesBySymbolObs,
        securitiesList: securitiesList!,
        searchableSecurities: searchableSecurities!,
        securitiesListSortedByRank,
        perps,
        perpsByCurrencyPair,
        futures,
        futuresByCurrencyPair,
        futuresByMarketByCurrency,
        options,
        expirationByMarketByCurrencyIdentity,
        optionSecurityBySymbol,
        optionSecurityBySymbolObs,
        representativeOptionSecurityByKey,
        strikesByOptionKey,
        spotTradingPairsByBaseCurrency,
        spotTradingPairsByQuoteCurrency,
      }}
    >
      {children}
    </SecuritiesContext.Provider>
  );
};

export function getPerpsFromSecurities(securitiesList: Security[]) {
  const now = new Date();
  return securitiesList.filter<PerpSecurity>((sec): sec is PerpSecurity => {
    if (!isPerpSecurity(sec)) {
      return false;
    }
    // filter out securities with EndTime (indicates it was soft deleted)
    if (sec?.EndTime && new Date(sec?.EndTime) < now) {
      return false;
    }
    return true;
  });
}

export function getPerpsByCurrencyPair(perps: PerpSecurity[]) {
  const lookup = new Map<string, PerpSecurity[]>();

  for (const perp of perps) {
    const currencyPair = getCurrencyPairFromSecurity(perp);

    const list = lookup.get(currencyPair)!;

    if (list) {
      list.push(perp);
    } else {
      lookup.set(currencyPair, [perp]);
    }
  }

  return lookup;
}

export function getFuturesFromSecurities(securitiesList: Security[]) {
  const now = new Date();
  return securitiesList.filter<FutureSecurity>((sec): sec is FutureSecurity => {
    if (!isFutureSecurity(sec)) {
      return false;
    }
    // filter out securities with EndTime (indicates it was soft deleted)
    if (sec?.EndTime && new Date(sec?.EndTime) < now) {
      return false;
    }
    // If expired filter it out
    if (new Date(sec.Expiration) < now) {
      return false;
    }
    return true;
  });
}

export function getFuturesByCurrencyPair(futures: FutureSecurity[]) {
  const lookup = new Map<string, FutureSecurity[]>();

  for (const future of futures) {
    const currencyPair = getCurrencyPairFromSecurity(future);

    const list = lookup.get(currencyPair)!;

    if (list) {
      list.push(future);
    } else {
      lookup.set(currencyPair, [future]);
    }
  }

  return lookup;
}

export function getFuturesByMarketByCurrencyPair(futures: FutureSecurity[]) {
  const futuresByMarketByCurrencyPair = new Map<string, Map<string, Set<FutureSecurity>>>();

  for (const future of futures) {
    const { Markets = EMPTY_ARRAY } = future;
    const currencyPair = getCurrencyPairFromSecurity(future);

    if (!futuresByMarketByCurrencyPair.has(currencyPair)) {
      futuresByMarketByCurrencyPair.set(currencyPair, new Map());
    }
    const expirationByMarket = futuresByMarketByCurrencyPair.get(currencyPair)!;

    for (const market of Markets) {
      const expirationSet = expirationByMarket.get(market);

      if (expirationSet) {
        expirationSet.add(future);
      } else {
        expirationByMarket.set(market, new Set<FutureSecurity>([future]));
      }
    }
  }

  return futuresByMarketByCurrencyPair;
}

// Maybe bad naming, assuming Key = Coin / Exchange / Expiry identifies, where Strike + Type are just parameters on that option
export function getStrikesByOptionKey(options: OptionSecurity[]) {
  const strikesByOptionKey = new Map<string, string[]>();
  for (const option of options) {
    const { UnderlyingCode, BaseCurrency, Markets = EMPTY_ARRAY, Expiration, StrikePrice } = option;
    const key = `${UnderlyingCode ?? BaseCurrency}/${Markets}/${Expiration}`;

    if (strikesByOptionKey.has(key)) {
      const copy = strikesByOptionKey.get(key)!.slice();
      copy.push(StrikePrice);
      strikesByOptionKey.set(key, copy);
    } else {
      strikesByOptionKey.set(key, [StrikePrice]);
    }
  }

  return strikesByOptionKey;
}

export function getexpirationByMarketByCurrencyIdentity(options: OptionSecurity[]) {
  const expirationByMarketByCurrencyIdentity = new Map<string, Map<string, Set<string>>>();

  for (const option of options) {
    const { UnderlyingCode, BaseCurrency, Expiration, Markets = EMPTY_ARRAY } = option;
    const key = UnderlyingCode ?? BaseCurrency;

    if (!expirationByMarketByCurrencyIdentity.has(key)) {
      expirationByMarketByCurrencyIdentity.set(key, new Map());
    }
    const expirationByMarket = expirationByMarketByCurrencyIdentity.get(key)!;

    for (const market of Markets) {
      const expirationSet = expirationByMarket.get(market);

      if (expirationSet) {
        expirationSet.add(Expiration);
      } else {
        expirationByMarket.set(market, new Set<string>([Expiration]));
      }
    }
  }

  return expirationByMarketByCurrencyIdentity;
}

export function getRepresentativeOptionSecurityKey(
  baseCurrency: string,
  markets: string[] | undefined,
  underlyingCode: string | undefined
) {
  return [underlyingCode ?? baseCurrency, (markets ?? []).join('_')].join('_');
}

/**
 * Builds two maps where every trading pair is indexed by their base currency and quote currency.
 * Filters the incoming securities list to only spot trading pairs so you can just send in the entire non-spot list too
 */
export function getSpotTradingPairsByCurrency(securitiesList: Security[]) {
  const baseMap = new Map<string, Security[]>();
  const quoteMap = new Map<string, Security[]>();
  const spotSecurities = securitiesList.filter(isSpot);

  for (const security of spotSecurities) {
    const baseList = baseMap.get(security.BaseCurrency) ?? [];
    const quoteList = quoteMap.get(security.QuoteCurrency) ?? [];
    baseList.push(security);
    quoteList.push(security);
    baseMap.set(security.BaseCurrency, baseList);
    quoteMap.set(security.QuoteCurrency, quoteList);
  }

  return { baseMap, quoteMap };
}
