import { createContext, useContext } from 'react';
import type { Observable } from 'rxjs';
import type { Aggregation } from '../types/Aggregation';

export const AggregationsContext = createContext<AggregationsContextProps | undefined>(undefined);
AggregationsContext.displayName = 'AggregationsContext';

export function useAggregationsContext() {
  const context = useContext(AggregationsContext);
  if (context === undefined) {
    throw new Error('Missing AggregationsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

export interface AggregationsContextProps {
  aggregationsListObs: Observable<Aggregation[]>;
  aggregationsList: Aggregation[];
  aggregationsByNameObs: Observable<Map<string, Aggregation>>;
  aggregationsByName: Map<string, Aggregation>;
}
