import type { BoxProps } from 'components/Core';
import { useTheme, type DefaultTheme } from 'styled-components';
import { IndicatorBadge } from './IndicatorBadge';
import { IndicatorBadgeSizes, IndicatorBadgeVariants, Wrapper } from './styles';
export { IndicatorBadgeSizes, IndicatorBadgeVariants } from './styles';

export type IndicatorBadgeProps = {
  show?: boolean;
  size?: IndicatorBadgeSizes;
  variant?: IndicatorBadgeVariants;
  children?: React.ReactNode;
  content?: React.ReactNode;
  theme?: DefaultTheme;
  noWrap?: boolean;
} & BoxProps;

export function IndicatorBadgeWrapper({
  children,
  content = null,
  show = true,
  size = IndicatorBadgeSizes.Small,
  variant = IndicatorBadgeVariants.Default,
  noWrap = false,
  ...props
}: IndicatorBadgeProps) {
  const defaultTheme = useTheme();
  const theme = props.theme ?? defaultTheme;

  return (
    <Wrapper alignItems="center" {...props}>
      {children}
      {show && content !== null && (
        <IndicatorBadge noWrap={noWrap} size={size} variant={variant} theme={theme} children={content} />
      )}
    </Wrapper>
  );
}
