// Taken from Ava/gocode/ws/clientwsmodel.go
export enum WebsocketErrorCodeEnum {
  WebsocketErrorInvalidRequest = 1,
  WebsocketErrorDuplicateReqID = 2,
  WebsocketErrorInvalidStream = 3,
  WebsocketErrorUnknownReqID = 4,
  WebsocketErrorDownstreamCommunicationProblem = 5,
  WebsocketErrorPermissionDenied = 6,
  WebsocketErrorSubscriptionLimit = 7,
  WebsocketErrorInternalServerError = 50,
}
