import type { OrdTypeEnum } from '../../types/types';
import { Table, TableSize, Tbody, Td, Th, Tr, type TableProps } from '../Table';
import { Text } from '../Text';
import { useParameterRows } from './useParameterRows';

export { useParameterRows } from './useParameterRows';
export type { ParameterRow } from './useParameterRows';
export interface WithStrategy {
  Strategy?: string;
  OrdType?: OrdTypeEnum;
  Parameters?: { [key: string]: any };
}

export function ParametersTable({
  order,
  showEmptyParams = false,
  ...props
}: {
  order?: WithStrategy;
  showEmptyParams?: boolean;
} & Omit<TableProps, 'border'>) {
  const rows = useParameterRows(order?.Strategy, order?.Parameters, showEmptyParams);

  if (rows.length === 0) {
    return <Text color="colorTextSubtle">No parameters</Text>;
  }

  return (
    <Table size={TableSize.Small} {...props}>
      <Tbody>
        {rows.map(row => (
          <Tr key={row.title}>
            <Th>{row.title}</Th>
            <Td align="right">{row.value}</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
