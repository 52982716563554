import type { PropsWithChildren } from 'react';
import styled, { useTheme } from 'styled-components';
import { HStack, type BoxProps } from '../Core';
import { Icon, type IconName } from '../Icons';
import { Text } from '../Text';

export const SidebarTitle = styled(function SidebarTitle({
  children,
  icon,
  ...props
}: PropsWithChildren<
  {
    icon?: IconName;
  } & BoxProps
>) {
  const theme = useTheme();
  return (
    <HStack justifyContent="flex-start" gap="spacingDefault" mt="spacingDefault" ml="spacingDefault" {...props}>
      {icon && <Icon icon={icon} size={theme.baseSize} />}
      <Text size="fontSizeTiny" weight="fontWeightMedium" color="colors.gray.080" transform="uppercase">
        {children}
      </Text>
    </HStack>
  );
})`
  white-space: nowrap;
`;
