export enum QuoteStatusEnum {
  PendingNew = 'PendingNew',
  Open = 'Open',
  PendingCancel = 'PendingCancel',
  Canceled = 'Canceled',
  PendingFill = 'PendingFill',
  Filled = 'Filled',
  Closed = 'Closed',
  Rejected = 'Rejected',
  Excluded = 'Excluded',
  Holding = 'Holding',
}

export enum TradeStatusEnum {
  Confirmed = 'Confirmed',
  Pending = 'Pending',
  Canceled = 'Canceled',
}

export enum ExposureStatusEnum {
  Online = 'Online',
  Offline = 'Offline',
}
