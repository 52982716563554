import { hsla } from 'polished';
import type { Colors, DefaultTheme } from 'styled-components';
import { setAlpha } from '../utils';
import { darkColors, mapDarkTheme } from './DarkTheme';

const colors: Colors = {
  black: {
    default: '#000',
    dim: 'rgba(0, 0, 0, 0.4)',
    mute: 'rgba(0, 0, 0, 0.1)',
  },

  white: {
    default: '#fff',
    dim: 'rgba(255, 255, 255, 0.4)',
    mute: 'rgba(255, 255, 255, 0.05)',
  },

  gray: {
    main: '#101010',

    '000': '#010101',
    '010': '#101010',
    '020': '#202020',
    '030': '#272727',
    '040': '#2F2F2F',
    '050': '#373737',
    '060': '#414141',
    '070': '#535353',
    '080': '#808080',
    '090': '#A3A3A3',
    '100': '#E5E5E5',
  },

  primary: {
    mute: hsla(217, 1, 0.42, 0.1),
    dim: hsla(217, 1, 0.42, 0.7),
    default: '#0053D8',
    lighten: '#0045B2',
  },

  green: {
    mute: hsla(157, 0.83, 0.43, 0.1),
    dim: hsla(157, 0.83, 0.43, 0.4),
    default: '#1CCB21',
    lighten: '#19B31D',
  },

  red: {
    mute: hsla(348, 0.55, 0.49, 0.1),
    dim: hsla(348, 0.55, 0.49, 0.4),
    default: '#FF5A5A',
    lighten: '#FF4242',
  },

  yellow: {
    mute: 'rgba(204, 172, 62, 0.1)',
    dim: 'rgba(204, 172, 62, 0.4)',
    default: '#CCAC3E',
    lighten: '#F2D264',
  },

  blue: {
    mute: 'rgba(69, 140, 193, 0.1)',
    dim: 'rgba(69, 140, 193, 0.4)',
    default: '#458CC1',
    lighten: '#53AFF8',
  },

  orange: {
    mute: 'rgba(230, 82, 0, 0.1)',
    dim: 'rgba(230, 82, 0, 0.4)',
    default: '#E65200',
    lighten: '#FF6B00',
  },

  purple: darkColors.purple,
};

export const DarkPintuTheme: DefaultTheme = mapDarkTheme(colors, {
  name: 'DarkPintu',

  fontFamily: "'Inter', sans-serif",
  fontUrl:
    'https://fonts.googleapis.com/css2?family=Inter&family=Mulish:ital,wght@0,400;0,500;0,600;1,400&display=swap',

  spacingLayout: 4,

  backgroundHeader: '#181818',
  borderColorHeader: '#181818',

  backgroundPriorityButton: colors.gray['060'],
  backgroundPriorityButtonHover: colors.gray['070'],
  backgroundPriorityButtonFocus: colors.gray['070'],
  backgroundPriorityButtonActive: colors.gray['060'],

  backgroundDefaultButton: colors.gray['030'],
  backgroundDefaultButtonHover: colors.gray['040'],
  backgroundDefaultButtonFocus: colors.gray['040'],
  backgroundDefaultButtonActive: colors.gray['030'],

  backgroundInput: colors.gray.main,
  backgroundInputDisabled: colors.gray['020'],
  backgroundInputInvalid: colors.gray.main,

  backgroundSelect: colors.gray.main,
  backgroundSelectHover: colors.gray.main,
  backgroundSelectFocus: colors.gray.main,
  backgroundSelectDisabled: colors.gray['020'],
  backgroundSelectInvalid: colors.gray.main,
  backgroundSelectDropdown: colors.gray.main,

  borderColorSelect: colors.gray['030'],
  borderColorSelectHover: colors.gray['040'],
  borderColorSelectFocus: colors.gray['050'],
  borderColorSelectDisabled: colors.gray['040'],
  borderColorSelectReadOnly: colors.gray['040'],

  backgroundToggle: colors.gray['050'],
  backgroundToggleSwitch: setAlpha(0.12, colors.gray[100]),

  backgroundCheckbox: colors.gray['010'],
  backgroundCheckboxHover: colors.gray['020'],

  borderColorCheckbox: colors.gray['050'],
  borderColorCheckboxFocus: colors.gray['070'],
  borderColorCheckboxHover: colors.gray['070'],
  borderColorCheckboxChecked: colors.gray['070'],
  borderColorCheckboxCheckedFocus: colors.gray['070'],

  backgroundRadiobutton: colors.gray['010'],
  backgroundRadiobuttonHover: colors.gray['020'],

  borderColorRadiobutton: colors.gray['050'],
  borderColorRadiobuttonFocus: colors.gray['070'],
  borderColorRadiobuttonHover: colors.gray['070'],
  borderColorRadiobuttonChecked: colors.gray['070'],
  borderColorRadiobuttonCheckedFocus: colors.gray['070'],

  borderColorInput: colors.gray['030'],
  borderColorInputHover: colors.gray['040'],
  borderColorInputFocus: colors.gray['050'],

  borderColorInputDisabled: colors.gray['040'],

  backgroundDropdownResults: colors.gray.main,
  backgroundDropdownInput: colors.gray.main,
  backgroundDropdownSelected: colors.gray['010'],
  backgroundDropdownHovered: colors.gray['020'],
});
