import styled from 'styled-components';
import { OrderStatusSize } from './types';

export const Wrapper = styled.div<{
  align: 'right' | 'left';
  iconPlacement?: 'right' | 'left';
  size?: OrderStatusSize;
}>`
  display: inline-flex;
  align-items: center;
  text-align: right;
  color: ${({ theme }) => theme.colorTextAttention};
  font-size: ${({ theme, size }) => (size === OrderStatusSize.Small ? theme.fontSizeTiny : theme.fontSizeSmall)}rem;
  ${({ align }) => align === 'right' && `justify-content: flex-end;`}
  ${({ iconPlacement }) => iconPlacement === 'left' && `flex-direction: row-reverse;`}
  overflow: visible;

  > div {
    margin: ${({ iconPlacement, theme }) =>
      iconPlacement === 'left' ? `0 ${theme.spacingSmall}px 0 0` : `0 0 0 ${theme.spacingSmall}px`};
    display: block;
    flex-shrink: 0;
  }
`;
