import styled from 'styled-components';
import type { FormControlProps } from '../Form';
import { formControlCSS } from '../Form/styles';

export const TimePickerInputWrapper = styled.label.withConfig({
  shouldForwardProp: (prop, validator) => prop !== 'prefix' && validator(prop),
})<FormControlProps<HTMLInputElement>>`
  ${formControlCSS}
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const TimePickerInput = styled.input`
  text-align: center !important;
`;

export const Delimiter = styled.span``;

export const PrecisionWrapper = styled.div`
  display: flex;
  max-width: 40px;
`;
