import type { Observable } from 'rxjs';
import type {
  CustomerExecutionReport,
  CustomerQuote,
  CustomerTrade,
  CustomerTransaction,
  ExecutionReport,
  Loan,
  LoanQuote,
  LoanTransaction,
  Quote,
} from '../../types';
import type { TimelineItemProps } from '../Timeline';

export interface ExecutionTimelineProps {
  /** Automatically groups similar items (such as trades) in a TimelineGroup. */
  groupSimilarItems?: boolean;

  /** Shows who's the initiator of the event (e.g. "Customer", "Dealer", "Lender", "Borrower", etc.) */
  showInitiatorLabels?: boolean;

  /** Show items in reversed order, newest first */
  reverse?: boolean;

  /** Statuses to filter out entries */
  statuses?: string[];

  customerExecutionReports?: Observable<CustomerExecutionReport[]>;
  customerQuotes?: Observable<CustomerQuote[]>;
  executionReports?: Observable<ExecutionReport[]>;
  quotes?: Observable<Quote[]>;
  loanQuotesBorrower?: Observable<LoanQuote[]>;
  loanQuotesLender?: Observable<LoanQuote[]>;
  loans?: Observable<Loan[]>;
  loanTransactions?: Observable<LoanTransaction[]>;
  customerTrades?: Observable<CustomerTrade[]>;
  customerTransactions?: Observable<CustomerTransaction[]>;
}

export type ExecutionTimelineEntry<RawData> =
  | ExecutionTimelineItemEntry<RawData>
  | ExecutionTimelineGroupEntry<RawData>;

export interface ExecutionTimelineItemEntry<RawData> {
  props: TimelineItemProps;
  rawData: RawData;
  type: 'item';
}

export interface ExecutionTimelineGroupEntry<RawData> {
  items: ExecutionTimelineItemEntry<RawData>[];
  type: 'group';
}

export interface WithTimestamp {
  Timestamp: string;
}

export enum TimelineStatusEnum {
  Canceled = 'Canceled',
  Rejected = 'Rejected',
  PendingNew = 'Submitted',
  PendingPause = 'Pause requested',
  Paused = 'Paused',
  PendingResume = 'Resume requested',
  Resumed = 'Resumed',
  Replaced = 'Modified',
  ReplaceRejected = 'Modification rejected',
  Trade = 'Traded',
  Triggered = 'Triggered',
}
