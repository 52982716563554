import type { ICellRendererParams } from 'ag-grid-enterprise';
import Big from 'big.js';
import { compact, isEmpty } from 'lodash';
import { VStack } from '../Core';
import { NumberVariants } from '../FormattedNumber';
import { Sparkline } from './Sparkline';
import { ChangeCell, PercentChange, Placeholder, placeholder } from './styles';
import type { WatchListRowData } from './types';

export interface ChangeCellRendererParams extends ICellRendererParams {
  data: WatchListRowData;
  /**
   * Percent change
   */
  value: Big;
}

export const ChangeCellRenderer = function ChangeCellRenderer({ data }: ChangeCellRendererParams) {
  const isLoaded = data?.sparklineData?.Values != null;
  const hasData = !isEmpty(compact(data?.sparklineData?.Values));
  const sparklineData = data?.sparklineData?.Values ?? [];
  const percentChange = data?.sparklineData?.PercentChange ?? Big(0);

  return (
    <ChangeCell>
      {isLoaded && hasData ? (
        <VStack position="relative" w="100%">
          <PercentChange value={percentChange} />
          <Sparkline
            data={sparklineData}
            variant={
              percentChange.gt(0) ? NumberVariants.Positive : percentChange.lt(0) ? NumberVariants.Negative : undefined
            }
          />
        </VStack>
      ) : isLoaded ? (
        placeholder
      ) : (
        <Placeholder />
      )}
    </ChangeCell>
  );
};
