import { memo, useEffect, useMemo, useRef, type CSSProperties, type Ref } from 'react';
import { ICON_SIZES, Icon, IconName } from '../../Icons';
import { AutocompleteDropdown, FuseAutocompleteResult } from '../AutocompleteDropdown';
import { useDropdownPopper } from '../Dropdown/useDropdownPopper';
import { FormControlSizes } from '../types';
import { MultiSelectInput } from './MultiSelectInput';
import { MultiSelectWrapper } from './styles';
import { useMultiSelectAutocomplete } from './useMultiselectAutocomplete';

import type { ExposedAutocompleteDropdownProps } from '../AutocompleteDropdown/types';
import { useRenderMultiSelectSelection } from './useRenderMultiSelectSelection';

/**
 * Takes a list of objects of some type T as well as initial selections.
 * The labelKey and descriptionKey fields must map to a string when used to get the field from type T.
 * descriptionKey is optional.
 * The lookup function is used to, given an object of type T, get some string representation which is used in searching.
 * The onChange emits the new selections when they change, they are also of type T.
 */
export type MultiSelectProps<T> = {
  options: T[];
  selections: T[];
  disabled?: boolean;
  style?: CSSProperties;
  placeholder?: string;
  size?: FormControlSizes;
  autoOpen?: boolean;
  getLabel: (obj: T) => string;
  getDescription?: (obj: T) => string;
  isSelectionLocked?: (obj: T) => boolean;
  onChange(selections: T[]): void;
} & ExposedAutocompleteDropdownProps;

// forwardRef and generic components do not play nice together, so we replace the forwardRef
// with our own custom ref. See here: https://stackoverflow.com/a/58473012
const MultiSelectInner = <T,>(props: MultiSelectProps<T> & { myRef?: Ref<HTMLDivElement> }) => {
  const {
    options,
    selections,
    disabled = false,
    getLabel,
    getDescription,
    isSelectionLocked,
    onChange,
    myRef,
    size = FormControlSizes.Default,
    placeholder,
    dropdownWidth,
    portalize,
    maxHeight,
    autoOpen,
    ...rest
  } = props;

  const selectableOptions = useMemo(() => {
    const selectionsSet = new Set(selections);
    return options.filter(option => !selectionsSet.has(option));
  }, [options, selections]);

  const { autocompleteOutput, multipleSelectionOutput } = useMultiSelectAutocomplete({
    selections,
    items: selectableOptions,
    getLabel,
    getDescription,
    onChange,
    isSelectionLocked,
  });

  const { getSelectedItemProps, getDropdownProps, selectedItems, removeSelectedItem } = multipleSelectionOutput;
  const { getInputProps, isOpen, openMenu } = autocompleteOutput;

  const dropdownReferenceElement = useRef<HTMLLabelElement | null>(null);
  const dropdownPopper = useDropdownPopper({
    isOpen,
    referenceElement: dropdownReferenceElement.current ?? null,
  });

  const renderSelection = useRenderMultiSelectSelection({
    disabled,
    getLabel,
    getSelectedItemProps,
    isSelectionLocked,
    removeSelectedItem,
  });

  useEffect(() => {
    if (autoOpen) {
      dropdownReferenceElement.current?.focus();
    }
  }, [autoOpen]);

  return (
    <MultiSelectWrapper ref={myRef} {...rest}>
      <MultiSelectInput
        size={size}
        wrapperRef={dropdownReferenceElement}
        disabled={disabled}
        placeholder={selectedItems.length === 0 ? placeholder : ''}
        {...getInputProps(
          getDropdownProps({
            onFocus: () => !isOpen && openMenu(),
            onKeyDown: e => {
              if (e.key === 'Escape') {
                // if this multi select control is within a drawer that closes with the escape key, we need to stop this from happening here and just close the dropdown instead
                e.stopPropagation();
              }
            },
          })
        )}
        items={selectedItems.map((selectedItem, index) => renderSelection(selectedItem, index, size))}
        suffix={<Icon color="gray.100" icon={IconName.ChevronDown} size={ICON_SIZES.MEDIUM} />}
      />

      <AutocompleteDropdown
        {...autocompleteOutput}
        {...dropdownPopper}
        maxHeight={maxHeight}
        renderResult={FuseAutocompleteResult}
        dropdownWidth={dropdownWidth}
        portalize={portalize}
      />
    </MultiSelectWrapper>
  );
};

// Memoized components and generics do not play nice together, see:
// https://github.com/DefinitelyTyped/DefinitelyTyped/issues/37087#issuecomment-656596623
export const MultiSelect = memo(MultiSelectInner) as typeof MultiSelectInner;
