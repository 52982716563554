import { addMilliseconds } from 'date-fns';
import { useEffect, useState } from 'react';
import { isOrderComplete } from '../utils/isOrderComplete';

export const useCountdownTime = (
  completionTime: Date,
  status?: Parameters<typeof isOrderComplete>[0],
  refreshRate = 16
): number | null => {
  const [time, setTime] = useState<number | null>(null);

  useEffect(() => {
    if (completionTime instanceof Date && !isNaN(completionTime.getTime())) {
      if (status && !isOrderComplete(status)) {
        // Define handler to be run once every [refreshRate] ms
        const timeLeft = completionTime.getTime() - new Date(Date.now()).getTime();
        const ltime = addMilliseconds(new Date(), timeLeft);
        const handler = () => {
          const remaining = ltime.getTime() - new Date().getTime();
          if (remaining <= 0) {
            clearInterval(interval);
          }
          setTime(Math.max(remaining, 0));
        };
        const interval = setInterval(handler, refreshRate);

        return () => {
          clearInterval(interval);
        };
      } else {
        setTime(null);
      }
    }
  }, [completionTime, status, refreshRate]);

  return time;
};
