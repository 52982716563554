import styled from 'styled-components';

const HANDLE_SIZE = '16px';

export const ResizeHandle = styled.div<{ orientation: 'vertical' | 'horizontal' }>`
  display: flex;
  flex-direction: ${props => (props.orientation === 'vertical' ? 'column' : 'row')};
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: move; // fallback
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }

  ${props => props.orientation === 'vertical' && `width: ${HANDLE_SIZE}`}
  ${props => props.orientation === 'horizontal' && `height: ${HANDLE_SIZE}`}
`;

export const HandleDot = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 100%;
  background: ${({ theme }) => theme.backgroundResizeHandle};
  margin: 2px;
`;
