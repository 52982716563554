import { themes, ThemeTypes, usePreferredColorScheme, useWLThemeType } from '@talos/kyoko';
import type { DefaultTheme } from 'styled-components';

export const usePreferredTheme = (themeName: string): DefaultTheme => {
  const { themeType } = useWLThemeType();
  const preferredType = usePreferredColorScheme();

  const preferredThemeType = themeType === ThemeTypes.auto ? preferredType : themeType;

  return getThemeFromType(themeName, preferredThemeType);
};

export function getThemeFromType(themeName: string, themeType?: ThemeTypes) {
  const preferredTheme =
    themeType != null
      ? themeType === ThemeTypes.light
        ? themeName.replace('Dark', 'Light')
        : themeName.replace('Light', 'Dark')
      : themeName;
  return themes[preferredTheme] ?? themes[themeName];
}
