import { createContext, useContext } from 'react';
import { of, type Observable } from 'rxjs';
import type { CurrencyConversionRate } from '../types/CurrencyConversionRate';

export const CurrencyConversionContext = createContext<CurrencyConversionContextProps>({
  currencyRatesByCurrencyPair: of(new Map()),
  homeCurrencyRatesByCurrency: of(new Map()),
  registerSubscription: (currency?: string, equivalentCurrency?: string) => {},
  registerSubscriptions: (currency: string[], equivalentCurrency?: string) => {},
  unregisterSubscription: (currency?: string, equivalentCurrency?: string) => {},
  unregisterSubscriptions: (currency: string[], equivalentCurrency?: string) => {},
});
CurrencyConversionContext.displayName = 'CurrencyConversionContext';

export const useCurrencyConversionContext = () => {
  return useContext(CurrencyConversionContext);
};

export interface CurrencyConversionContextProps {
  currencyRatesByCurrencyPair: Observable<Map<string, CurrencyConversionRate>>;
  homeCurrencyRatesByCurrency: Observable<Map<string, CurrencyConversionRate>>;
  registerSubscription: (currency?: string, equivalentCurrency?: string) => void;
  registerSubscriptions: (currency: string[], equivalentCurrency?: string) => void;
  unregisterSubscription: (currency?: string, equivalentCurrency?: string, persistRate?: boolean) => void;
  unregisterSubscriptions: (currency: string[], equivalentCurrency?: string) => void;
}
