export enum OrderStatusText {
  Paused = 'Paused',
  Waiting = 'Waiting',
  Staged = 'Staged',
  Filled = 'Filled',
  FilledAndCanceled = 'Filled & Canceled',
  Canceled = 'Canceled',
  PendingCancel = 'Pending Cancel',
  PendingReplace = 'Pending Replace',
  Rejected = 'Rejected',
  Open = 'Open',
  SystemPaused = 'System Paused',
  PendingFix = 'Pending Fix',
}

export enum OrderStatusSize {
  Small,
  Default,
}

export enum QuoteStatusText {
  Filled = 'Filled',
  Canceled = 'Canceled',
  PendingNew = 'Pending New',
  PendingCancel = 'Pending Cancel',
  Rejected = 'Rejected',
  Open = 'Open',
  PendingFix = 'Pending Fix',
}
