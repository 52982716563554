import { useMemo, useState } from 'react';
import { useDynamicCallback } from './useDynamicCallback';

export interface UseDisclosureProps {
  isOpen?: boolean;
  initialIsOpen?: boolean;
  onClose?(): void;
  onOpen?(): void;
}

/**
 * `useDisclosure` is a custom hook used to help handle common open, close, or toggle scenarios.
 * It can be used to control feedback components such as `Modal`, `Dialog`, `Drawer`, etc.
 */
export function useDisclosure(props: UseDisclosureProps = {}) {
  const [isOpenState, setIsOpen] = useState(props.initialIsOpen || false);
  const isOpen = props.isOpen === undefined ? isOpenState : props.isOpen;
  const isControlled = props.isOpen !== undefined;

  const close = useDynamicCallback(() => {
    if (!isControlled) {
      setIsOpen(false);
    }
    props.onClose?.();
  });

  const open = useDynamicCallback(() => {
    if (!isControlled) {
      setIsOpen(true);
    }
    props.onOpen?.();
  });

  const toggle = useDynamicCallback(() => (isOpen ? close() : open()));

  return useMemo(
    () => ({
      open,
      close,
      toggle,

      isOpen,
      isControlled,
    }),
    [open, close, toggle, isOpen, isControlled]
  );
}

export type UseDisclosureReturn = ReturnType<typeof useDisclosure>;
