import type { LocaleItem } from './types';

export const supportedLocales: LocaleItem[] = [
  {
    displayName: 'English',
    flag: '🇺🇸',
    value: 'en',
  },
  {
    displayName: 'Deutsch',
    flag: '🇩🇪',
    value: 'de',
  },
  {
    displayName: 'French',
    flag: '🇫🇷',
    value: 'fr',
  },
  {
    displayName: 'Spanish',
    flag: '🇪🇸',
    value: 'es',
  },
  {
    displayName: 'Italian',
    flag: '🇮🇹',
    value: 'it',
  },
  {
    displayName: 'Polish',
    flag: '🇵🇱',
    value: 'pl',
  },
  {
    displayName: 'Testing',
    flag: '✅',
    value: 'testing',
  },
];
