import { memo } from 'react';
import { Box } from '../Core';
import { ResultMatchHighlight, ResultMatchHighlightAll } from './styles';
import type { FuseResultMatchProps } from './types';
import { FuseResultMatchEnum } from './types';

export { FuseResultMatchEnum } from './types';

export const FuseResultMatch = memo(
  ({
    label,
    indices = [],
    highlightAll = false,
    type = FuseResultMatchEnum.Label,
    disabled = false,
  }: FuseResultMatchProps) => {
    let matchIndex = 0;
    const result = [''];
    for (let i = 0; i < (label ?? '').length; i++) {
      if (indices[matchIndex] && indices[matchIndex][0] === i) {
        result.push('');
      }
      result[result.length - 1] = result[result.length - 1].concat(label != null ? label[i] : '');
      if (indices[matchIndex] && indices[matchIndex][1] === i) {
        result.push('');
        matchIndex++;
      }
    }

    if (highlightAll && !disabled) {
      return <ResultMatchHighlightAll type={type}>{label}</ResultMatchHighlightAll>;
    }

    return (
      <Box>
        {result.map((part, index) =>
          part !== '' ? (
            index % 2 && !disabled ? (
              <ResultMatchHighlight key={index}>{part}</ResultMatchHighlight>
            ) : (
              <span key={index}>{part}</span>
            )
          ) : null
        )}
      </Box>
    );
  }
);
