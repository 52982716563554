import type Big from 'big.js';
import type { ExpectTrue } from '../../tests';
import { SLIPPAGE_DATAPOINTS } from '../../tokens';
import { parseDate, toBig, type OptionalProperties } from '../../utils';
import type {
  AggregationWindowEnum,
  AnalyticsOrderDepthEnum,
  ISlippageDatapoint,
  OrderMarketabilityEnum,
  SlippageReferencePriceEnum,
  StrategyTypeEnum,
  UpdateActionEnum,
} from '../types';
import type { DatapointProps, OptionalDatapointProperties } from './types';

export class SlippageDatapoint {
  public static readonly Stream_Name = SLIPPAGE_DATAPOINTS;

  public readonly StartTime: Date;
  public readonly Asset: string | undefined;

  public readonly Market: string | undefined;
  public readonly AnalyticsOrderDepth: AnalyticsOrderDepthEnum | undefined;
  public readonly Strategy: string | undefined;
  public readonly StrategyType: StrategyTypeEnum | undefined;
  public readonly OrderMarketability: OrderMarketabilityEnum | undefined;
  public readonly SlippageBps: Big | undefined;
  public readonly ReferencePrice: SlippageReferencePriceEnum;
  public readonly UpdateAction: UpdateActionEnum | undefined;
  public readonly ReleaseRevision: string | undefined;
  public readonly AggregationWindow: AggregationWindowEnum | undefined;
  constructor(data: SlippageDatapoint) {
    this.StartTime = parseDate(data.StartTime);
    this.Asset = data.Asset;
    this.Market = data.Market;
    this.AnalyticsOrderDepth = data.AnalyticsOrderDepth;
    this.Strategy = data.Strategy;
    this.StrategyType = data.StrategyType;
    this.OrderMarketability = data.OrderMarketability;
    this.SlippageBps = toBig(data.SlippageBps);
    this.ReferencePrice = data.ReferencePrice;
    this.UpdateAction = data.UpdateAction;
    this.ReleaseRevision = data.ReleaseRevision;
    this.AggregationWindow = data.AggregationWindow;
  }
}

type _SlippageDatapoints = OptionalProperties<
  DatapointProps<ISlippageDatapoint> & { Asset?: string },
  OptionalDatapointProperties | 'ReferencePrice' | 'AnalyticsOrderDepth' | 'UpdateAction'
>;

// TYPE LEVEL TESTS
type _Expect_SlippageDatapoint_To_Only_Have_Keys_From_ISlippageDatapoint = ExpectTrue<
  {
    [K in keyof SlippageDatapoint & string]: K extends keyof _SlippageDatapoints ? true : K;
  }[Exclude<keyof SlippageDatapoint, never>]
>;
type _Expect_All_Keys_In_ISlippageDatapoint_To_Be_In_SlippageDatapoint = ExpectTrue<
  {
    [K in keyof _SlippageDatapoints & string]: K extends keyof SlippageDatapoint ? true : K;
  }[Exclude<keyof _SlippageDatapoints, never>]
>;
