import type { ColDef } from 'ag-grid-enterprise';
import { useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { useTheme } from 'styled-components';
import { useMixpanel } from '../../contexts';
import { useDynamicCallback, useIntl } from '../../hooks';
import { MixpanelEvent, MixpanelEventProperty } from '../../tokens';
import { ChangeCellRenderer } from './ChangeCellRenderer';
import { DeepDiveButtonCellRenderer } from './DeepDiveButtonCellRenderer';
import { FavoriteCellRenderer } from './FavoriteCellRenderer';
import { MarketPriceButtonCellRenderer } from './MarketPriceButtonCellRenderer';
import { MarketPriceCellRenderer } from './MarketPriceCellRenderer';
import { RefRateCellRenderer } from './RefRateCellRenderer';
import { ReorderCellRenderer } from './ReorderCellRenderer';
import { SecurityCellRenderer } from './SecurityCellRenderer';
import { SpreadCellRenderer } from './SpreadCellRenderer';
import { SymbolCellRenderer } from './SymbolCellRenderer';
import { VolumeCellRenderer } from './VolumeCellRenderer';
import { VolumeHeader } from './VolumeHeader';
import { FilterableProperties } from './WatchListFilters';
import type { UseColumnsParams } from './types';

const messages = defineMessages({
  symbol: {
    id: 'Watchlist.symbol',
    defaultMessage: 'Symbol',
  },
  bid: {
    id: 'Watchlist.bid',
    defaultMessage: 'Bid',
  },
  offer: {
    id: 'Watchlist.offer',
    defaultMessage: 'Offer',
  },
});

export const useColumns = function useColumns({
  isFavorite,
  toggleFavorite,
  onShowOnlyFavorites,
  volumeWindow,
  getRank,
  rankComparator,
  enabledColumns,
  showOnlyFavorites,
  isMobile,
}: UseColumnsParams) {
  const mixpanel = useMixpanel();
  const theme = useTheme();
  const { formatMessage } = useIntl();

  const toggleOnlyFavorites = useDynamicCallback(() => {
    mixpanel.track(MixpanelEvent.FilterWatchlist, {
      [MixpanelEventProperty.Filter]: FilterableProperties.FAVORITES,
    });
    onShowOnlyFavorites(!showOnlyFavorites);
  });

  const availableColumns = useMemo(() => {
    const columns: ColDef[] = [
      {
        colId: 'rank',
        hide: true,
        initialSort: 'asc' as const,
        valueGetter: getRank,
        comparator: rankComparator,
      },
      {
        colId: 'favorite',
        headerName: '',
        width: 24,
        headerComponent: isMobile ? FavoriteCellRenderer : undefined,
        headerComponentParams: {
          toggleFavorite: toggleOnlyFavorites,
          showOnlyFavorites,
        },
        cellRenderer: FavoriteCellRenderer,
        valueGetter: params => isFavorite(params.data.security),
        cellRendererParams: {
          toggleFavorite,
          theme,
        },
      },
      {
        colId: 'symbol',
        headerName: formatMessage(messages.symbol),
        minWidth: 98,
        width: 136,
        flex: 1,
        cellRenderer: SymbolCellRenderer,
        sortable: true,
        valueGetter: ({ data }) => {
          return data.security.Symbol;
        },
        comparator: (valueA: string, valueB: string) => {
          if (valueA === valueB) {
            return 0;
          }
          return valueA > valueB ? 1 : -1;
        },
      },
      {
        colId: 'security',
        headerName: formatMessage(messages.symbol),
        width: 136,
        flex: 1,
        cellRenderer: SecurityCellRenderer,
        sortable: true,
        cellStyle: { paddingRight: 0 },
        valueGetter: ({ data }) => {
          return { symbol: data.security.Symbol, sparklineData: data.sparklineData };
        },
        comparator: (valueA: { symbol: string }, valueB: { symbol: string }) => {
          if (valueA.symbol === valueB.symbol) {
            return 0;
          }
          return valueA.symbol > valueB.symbol ? 1 : -1;
        },
      },
      {
        colId: 'refRate',
        headerName: 'Price',
        type: 'rightAligned',
        width: 100,
        field: 'refRate',
        cellStyle: { overflow: 'visible' },
        cellRenderer: RefRateCellRenderer,
      },
      {
        colId: 'volume',
        headerName: 'Vol',
        field: 'volume',
        type: 'rightAligned',
        headerComponent: VolumeHeader,
        headerComponentParams: { volumeWindow },
        menuTabs: ['generalMenuTab'],
        columnChooserParams: {
          suppressColumnFilter: true,
          suppressColumnSelectAll: true,
          suppressColumnExpandAll: true,
        },
        width: 76,
        cellRenderer: VolumeCellRenderer,
        suppressHeaderMenuButton: false,
      },
      {
        colId: 'sparkline',
        headerName: 'Change',
        type: 'rightAligned',
        field: 'sparklineData',
        headerClass: 'last-cell ag-right-aligned-header',
        cellClass: 'last-cell',
        width: 68,
        cellRenderer: ChangeCellRenderer,
      },
      {
        colId: 'bid',
        headerName: formatMessage(messages.bid),
        type: 'rightAligned',
        headerClass: 'bid-header',
        cellClass: 'slim-cell',
        width: 88,
        field: 'bid',
        cellStyle: { overflow: 'visible', paddingRight: theme.spacingTiny },
        cellRenderer: MarketPriceCellRenderer,
      },
      {
        colId: 'bidButton',
        headerName: formatMessage(messages.bid),
        type: 'rightAligned',
        headerClass: 'bid-header',
        cellClass: 'slim-cell',
        width: 88,
        field: 'bid',
        cellStyle: { overflow: 'visible', paddingRight: theme.spacingTiny },
        cellRenderer: MarketPriceButtonCellRenderer,
      },
      {
        colId: 'spread',
        headerName: '',
        type: 'rightAligned',
        headerClass: 'slim-header',
        cellClass: 'slim-cell',
        width: 0,
        minWidth: 0,
        field: 'spread',
        cellStyle: { overflow: 'visible' },
        cellRenderer: SpreadCellRenderer,
      },
      {
        colId: 'offer',
        headerName: formatMessage(messages.offer),
        type: 'rightAligned',
        headerClass: 'offer-header',
        cellClass: 'slim-cell',
        width: 88,
        field: 'offer',
        cellStyle: { overflow: 'visible', paddingLeft: theme.spacingTiny },
        cellRenderer: MarketPriceCellRenderer,
      },
      {
        colId: 'offerButton',
        headerName: formatMessage(messages.offer),
        type: 'rightAligned',
        headerClass: 'offer-header',
        cellClass: 'slim-cell',
        width: 88,
        field: 'offer',
        cellStyle: { overflow: 'visible', paddingLeft: theme.spacingTiny },
        cellRenderer: MarketPriceButtonCellRenderer,
      },
      {
        colId: 'deepdive',
        headerName: 'Action Buttons',
        type: 'rightAligned',
        pinned: 'right',
        width: 120,
        valueGetter: params => params.data?.security?.DisplaySymbol,
        cellRenderer: DeepDiveButtonCellRenderer,
      },
      {
        colId: 'reorder',
        headerName: '',
        type: 'rightAligned',
        pinned: 'right',
        cellStyle: { overflow: 'visible' },
        width: 24,
        minWidth: 24,
        cellClass: 'drag-handle',
        valueGetter: params => params.data?.security?.DisplaySymbol,
        cellRenderer: ReorderCellRenderer,
      },
      {
        colId: 'spacer',
        width: 4,
        minWidth: 4,
        cellClass: 'spacer',
        headerClass: 'spacer',
      },
    ] satisfies ColDef[];
    return new Map<string, ColDef>(columns.map(c => [c.colId!, c]));
  }, [
    getRank,
    rankComparator,
    isMobile,
    toggleOnlyFavorites,
    showOnlyFavorites,
    toggleFavorite,
    theme,
    formatMessage,
    volumeWindow,
    isFavorite,
  ]);

  const filteredColumns = useMemo(() => {
    return enabledColumns
      ? (enabledColumns
          .filter(c => c.type !== 'reorder' || showOnlyFavorites)
          .map(
            c =>
              ({
                ...availableColumns.get(c.type),
                onCellClicked: c.onClick,
              } as const)
          ) satisfies ColDef[])
      : [];
  }, [availableColumns, enabledColumns, showOnlyFavorites]);

  return filteredColumns;
};
