import type { ICellRendererParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import type { IHedgePositionStatus } from '../../../types';
import { PositionThresholdMeter } from '../../PositionThresholdMeter';

import type { PositionThresholdMeterValuesArgs } from '../../PositionThresholdMeter/getPositionThresholdMeterPercents';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

export const positionThresholdMeter: ColDefFactory<Column> = column => ({
  ...baseColumn(column),
  width: column.width ?? 200,
  minWidth: 200,
  valueGetter: ({ data }: ValueGetterParams<IHedgePositionStatus>): PositionThresholdMeterValuesArgs | null => {
    return data ?? null;
  },
  valueFormatter: ({ data }: ValueFormatterParams<IHedgePositionStatus>) => {
    return data?.CurrentPosition ?? '';
  },
  cellRenderer: ({ value }: ICellRendererParams<unknown, PositionThresholdMeterValuesArgs | null>) => {
    if (!value) {
      return null;
    }
    return <PositionThresholdMeter value={value} />;
  },
});
