import { createContext, useContext } from 'react';
import type { Setters } from '../types/Setters';

export enum SecurityType {
  All = 'All',
  Spot = 'Spot',
  Perp = 'Perp',
  Future = 'Future',
  CalendarSpread = 'Cal Spread',
}
export const DefaultWatchlistSettingsConfig = {
  showOnlyFavorites: false,
  securityTypes: SecurityType.Spot,
  isVisible: true,
  filteredCurrencies: [] as string[],
};
export const DefaultWatchlistSettingsMethods = {
  setShowOnlyFavorites: (onlyFavorites: boolean) => {},
  setSecurityTypes: (securityTypes: SecurityType) => {},
  setIsVisible: (isVisible: boolean) => {},
  setFilteredCurrencies: (filteredCurrencies: string[]) => {},
} satisfies Setters<typeof DefaultWatchlistSettingsConfig>;

export const DefaultWatchlistSettingsContext = {
  ...DefaultWatchlistSettingsConfig,
  ...DefaultWatchlistSettingsMethods,
};

export type WatchlistSettingsConfig = typeof DefaultWatchlistSettingsConfig;
export type WatchlistSettingsMethods = typeof DefaultWatchlistSettingsMethods;
export type WatchlistSettingsContextProps = Omit<typeof DefaultWatchlistSettingsContext, 'setSecurityTypes'> & {
  /**
   * This prop is now serving as an indicator, to the WatchList component itself,
   * as to whether or not to render its security types selector. The reasoning was
   * that we have a current desire to suppress rendering this within the whitelabel
   * product, but not within principal, SO, we can either add an extra prop to tell
   * the component specifically to suppressRenderingSecurityTypesSelector, OR, we
   * can slightly modify this type definition to make the setter function optional,
   * and then infer whether or not to render that dropdown based on whether we have
   * a defined callback handler as a setter, or whether that setter is undefined.
   */
  setSecurityTypes?: typeof DefaultWatchlistSettingsContext.setSecurityTypes;
};

export const WatchlistSettingsContext = createContext<WatchlistSettingsContextProps>(DefaultWatchlistSettingsContext);
WatchlistSettingsContext.displayName = 'WatchlistSettingsContext';

export function useWatchlistSettings() {
  const context = useContext(WatchlistSettingsContext);
  if (context === undefined) {
    throw new Error('Missing WatchlistSettingsContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}
